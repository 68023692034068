import styled, { css } from 'styled-components';

interface ButtonProps {
  contactValue: string;
  selected: string;
}

export const Container = styled.button<ButtonProps>`
  border: none;
  border-radius: 0.25rem;

  width: fit-content;
  min-width: 5.6rem;
  height: 1.875rem;
  padding: 0 0.5rem;

  background: #efefef;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.75rem;
  color: #3d3d3d;

  border: 0.031rem solid #063966;

  cursor: pointer;
  transition: 0.5s;

  text-align: center;

  &:hover {
    background-color: #1665d8;
    color: #fff;
  }

  .scheduleHour {
    border: none;
  }

  ${props =>
    props.contactValue.includes(props.selected)
      ? css`
          background-color: #1665d8;
          color: #fff;
          border: none;
        `
      : css``};
`;
