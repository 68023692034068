import styled from 'styled-components';

export const ModalClosers = {
  content: {
    width: '62.625rem',
    minHeight: '25.313rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
  },
};

export const ModalHeader = styled.div`
  width: 100%;
  height: 2.313rem;
  /* text-align: center; */
  justify-content: space-between;

  padding: 0 0.688rem;

  position: sticky;
  top: 0;

  background: #cfdef3;
  border-radius: 0.625rem 0.625rem 0 0;

  display: flex;
  align-items: center;

  p {
    display: flex;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    color: #05233e;
    width: 95%;
    padding-left: 2%;
  }
`;

export const CloseModalButton = styled.button`
  background-color: transparent;
  color: #1665d8;
  border: 0;
  width: 2%;

  img {
    width: 0.938rem;
  }
`;

export const ContactsContainer = styled.div`
  width: 90%;
  margin-bottom: 0.25rem;
`;

export const ModalBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.938rem 0.688rem 0.688rem 0.938rem;
`;

export const ContactContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 1.875rem;

  background: #e5e5e5;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  color: #041524;
`;

export const AnswersBackgroud = styled.div`
  width: 100%;
  height: 19.25rem;
  background: #e5e5e5;
  padding: 0 1.25rem 1.25rem 1.25rem;
`;

export const AnswersContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fafafa;
`;

export const QuestionAnswersContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 0.25rem;

  b {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.813rem;
    padding-left: 0.875rem;
  }
  p {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.813rem;
    padding-left: 0.875rem;
  }
`;
