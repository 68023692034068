import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 27%;
  height: 44.5rem;

  gap: ${pxToRem(24)};
`;

export const TitleSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 1.875rem;

  border-radius: ${pxToRem(2)};
  background: var(--gray-100);

  font-weight: 600;
  font-size: ${pxToRem(14)};
  color: var(--blue-800);
`;

export const ProvidencesContainer = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  border-radius: ${pxToRem(2)};
  background: rgba(22, 101, 216, 0.05);
  padding: 0 ${pxToRem(17)};

  overflow: auto;
`;

export const SearchByNameContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1.25rem;
  margin-bottom: 1.875rem;

  width: 100%;
  height: 2.125rem;
  text-align: center;
  padding: 0 0.688rem;

  background: var(--gray-200);
  border-radius: 1.25rem;
`;

export const InputSearch = styled.input`
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--blue-800);
  border-radius: 1.25rem;
  background: var(--gray-200);
  border: none;

  height: 2.125rem;
  width: 95%;

  ::placeholder {
    color: var(--gray-600);
  }
`;

interface ProvidenceProps {
  isBefore: boolean;
  statusTask: string;
  checklist_concluded: boolean;
}

export const Providence = styled.div<ProvidenceProps>`
  width: 100%;
  height: ${pxToRem(75)};

  padding: ${pxToRem(6)};
  border-top-right-radius: ${pxToRem(4)};
  border-bottom-right-radius: ${pxToRem(4)};
  background: rgba(1, 64, 119, 0.1);
  box-shadow: inset 3px 0 0 var(--blue-500);
  margin-bottom: ${pxToRem(24)};

  color: var(--gray-800);

  cursor: pointer;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--green-400),
            0 0.25rem 0.25rem var(--overlay);
        `
      : props.isBefore &&
        props.statusTask === 'Pendente' &&
        !props.checklist_concluded
      ? css`
          background: rgba(236, 68, 68, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--red-700),
            0 0.25rem 0.25rem var(--overlay);
        `
      : props.statusTask === 'Pendente' && props.checklist_concluded
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--yellow-500),
            0 0.25rem 0.25rem var(--overlay);
        `
      : css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: inset 0.188rem 0 0 var(--blue-500),
            0 0.25rem 0.25rem var(--overlay);
        `}
`;

export const NameStageContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

export const ProvidenceName = styled.span`
  width: 50%;

  font-weight: 500;
  font-size: ${pxToRem(12)};
`;

export const StageChecklist = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${pxToRem(7)};
`;

export const Stage = styled.span`
  width: fit-content;
  height: ${pxToRem(18)};

  border-radius: ${pxToRem(4)};
  border: 0.5px solid var(--gray-800);
  padding: ${pxToRem(2)};

  font-weight: 600;
  font-size: ${pxToRem(10)};
`;

export const Checklist = styled.span`
  font-weight: 700;
  font-size: ${pxToRem(16)};
`;

export const CredorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};

  span {
    font-weight: 600;
    font-size: ${pxToRem(8)};
  }
`;
