import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FileInputContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  padding-top: 18px;

  width: 26.438rem;
  height: 5rem;

  background: var(--blue-100);
  border-radius: 0.313rem;
  box-shadow: 0.313rem 0.313rem 0.313rem 0.313rem var(--blue-100);
`;

export const SpanInput = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
`;

export const SendFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8rem;
      height: 1.625rem;
      border-radius: 0.313rem;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 0.688rem;
      line-height: 1rem;
      text-align: center;

      cursor: pointer;
      color: #4e4e4e;
      background: #c4c4c4;
    }
    span {
      color: #666666;
      height: 1.313rem;
      width: 7.438rem;
      left: 41.688rem;
      top: 20.625rem;

      font-family: 'Poppins';
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.938rem;
      letter-spacing: -0.015em;
      text-align: center;
    }

    input {
      display: none;
    }
  }
`;

interface SendButtonContainerProps {
  isActive: boolean;
}

export const SendButtonContainer = styled.div<SendButtonContainerProps>`
  display: flex;
  flex-direction: column;

  button {
    margin-left: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 8rem;
    height: 1.625rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 1rem;
    text-align: center;
    letter-spacing: -0.015em;

    background: ${props =>
      props.isActive ? 'var(--blue-700)' : 'var(--gray-300)'};
    color: #fff;
    border: 0;
    border-radius: 0.313rem;
  }

  span {
    color: #666666;
    height: 1.313rem;
    width: 10.5rem;
    left: 41.688rem;
    top: 20.625rem;

    font-family: 'Poppins';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.938rem;

    letter-spacing: -0.015em;
    text-align: center;
  }
`;
