import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.938rem;
  background: #f4f4f5;
  border: 0.031rem solid #05233e;
  border-radius: 0.125rem;
  padding: 0.375rem;
  margin-top: 6.25rem;

  width: 20rem;
`;

export const Select = styled.select`
  width: 10.5rem;
  height: 1.563rem;

  border: none;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const Text = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;

  color: #05233e;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;

  :disabled {
    display: none;
  }
`;

export const Table = styled.table`
  margin: auto;
  margin-top: 4.625rem;
  width: 100%;

  text-align: center;
`;

export const THead = styled.thead``;

export const TRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;

  height: 2.5rem;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  color: #05233e;
`;

export const TCellHead = styled.td`
  width: 100%;

  height: 2rem;
  border-right: 0.063rem solid #9d9d9d;
  border-collapse: collapse;

  :last-child {
    border: 0;
  }
`;

export const TBody = styled.tbody`
  margin-top: 0.125rem;

  .in {
    margin-right: 0.688rem;
    border: 1px solid #0d901a;
    color: #0d901a;
  }

  .out {
    border: 1px solid #de0c0c;
    color: #de0c0c;
  }
`;

export const TRowBody = styled.tr`
  margin-top: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #efefef;
  border-radius: 0.25rem;

  min-height: 3.5rem;
  height: auto;

  padding: 0.5rem;

  word-break: break-all;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;

  color: #041524;

  :nth-child(odd) {
    background: #e5e5e5;
  }

  :nth-child(even) {
    background: #efefef;
  }
`;

export const TCell = styled.td`
  width: 100%;
`;

export const Button = styled.button`
  width: 3rem;
  height: 1.75rem;
  font-weight: 600;
  font-size: 0.8rem;

  border-radius: 0.25rem;
  padding: 0.25rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.625rem;

  margin-bottom: 1rem;
`;

export const ButtonCreate = styled.button`
  border: none;
  width: 14.063rem;
  height: 2.25rem;

  background: #063966;
  border-radius: 0.5rem;
  padding: 0.25rem;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.813rem;

  color: #ffffff;

  :hover {
    background: #041524;
  }
`;
