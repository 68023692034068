import styled from 'styled-components';

export const ModalContactsStyle = {
  content: {
    display: 'flex',
    width: '50.625rem',
    height: '33.813rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
    border: 'none',
    boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.25)',
  },
};

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  height: 3.125rem;
  text-align: center;
  color: #ffffff;
  background: #014077;
  border-radius: 0.625rem 0.625rem 0 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.5rem;

  button {
    border: none;
    background: transparent;
    height: 1.25rem;
  }

  #close-modal {
    margin-left: 1.5rem;
  }

  #dealname-span {
    font-weight: 400;
  }
`;

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  flex: 1;

  #select-contact-span {
    height: 4.375rem;
    color: var(--blue-700);
    align-self: flex-start;
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

export const PrefixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36.375rem;
`;

export const PrefixOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 1rem;

  input[type='radio'] {
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: var(--gray-200);
    display: grid;
    place-content: center;
    margin-right: 0.5rem;
  }

  input[type='radio']::before {
    content: '';
    width: 0.625em;
    height: 0.625em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--blue-700);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 36.375rem;
`;

export const ContactsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-100);
  width: 33.5rem;
  height: 2.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin: 0.25rem;

  span {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  #middle-title-span {
    border-right-width: 1px;
    border-left-width: 1px;
    border-right-style: solid;
    border-left-style: solid;
  }

  #end-title-span {
    flex: 0.7;
  }
`;

export const ButtonCall = styled.button<IButtonCallProps>`
  display: flex;
  justify-content: center;
  width: 10.5rem;
  height: 2.563rem;
  color: #ffffff;
  align-items: center;
  border: none;
  /* padding: 0.7rem; */
  font-weight: 700;
  font-size: 1.25rem;
  background-color: ${props =>
    props.isContactSelected ? '#1479DE' : '#D9D9D9'};
  border-radius: 0.625rem;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const AddContactContainer = styled.div`
  display: flex;
  align-items: center;
  input[type='radio'] {
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: var(--gray-200);
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 0.625em;
    height: 0.625em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--blue-700);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;

export const AddContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-100);
  width: 33.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  margin: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;

  span {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  input {
    align-self: center;
    text-align: center;
    border: none;
    width: 10rem;
    height: 1.625rem;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.688rem;
  }

  #end-span {
    flex: 0.7;
  }
`;

export interface IButtonCallProps {
  isContactSelected: boolean;
}
