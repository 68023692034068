import { shade } from 'polished';
import styled from 'styled-components';

export const RegionAndProcessContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const RegionContainer = styled.div`
  margin-top: 1.875rem;
  width: 48.75rem;
  height: 8rem;
  border: 0.188rem solid #d7e4f5;
  box-shadow: 0.125rem 0.125rem 0.25rem -1.25rem #d7e4f5;
  border-radius: 0.625rem;
`;
export const InlineSeparator = styled.span`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: var(--blue-800);
  font-size: 0.9rem;
  grid-column: col 2;
  grid-row: row 1, row 3;
`;
export const Line = styled.div`
  display: flex;
`;
export const RegionSelectTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-100);

  height: 2rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #3d3d3d;
`;

export const RegionContentContainer = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    width: 19.375rem;
    height: 2.5rem;

    & + select {
      margin-left: 1.25rem;
    }
  }
`;

export const ProcessFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 4.375rem;
  width: 48.75rem;
  height: 7.5rem;

  background: #f0f0f0bd;
  border-radius: 0.313rem;
`;

export const SelectFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 24.375rem;
  border-right: 0.125rem dashed #9d9d9d;
`;

export const TextContainer = styled.div`
  margin: 0.625rem 0;

  strong {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;

    /* identical to box height */
    text-align: center;
    letter-spacing: -0.015em;

    color: #041524;
  }
`;

export const SendFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const InputFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 1.75rem;

  label {
    cursor: pointer;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    background: #c4c4c4;
    border-radius: 0.313rem;
    width: 9.375rem;
    height: 1.875rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #4e4e4e;

    transition: background-color 0.2s ease;

    &:hover {
      background: ${shade(0.2, '#c4c4c4')};
    }
  }

  span {
    margin-top: 0.5rem;
    color: #666666;
    height: 1.875rem;
    width: 9.375rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 0.625rem;
    line-height: 0.938rem;
    text-align: center;
    letter-spacing: -0.015em;

    color: #666666;
  }

  input {
    display: none;
  }
`;

export const SendFileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: 0;
    width: 9.375rem;
    height: 1.875rem;
    background: #063966;
    border-radius: 0.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 1rem;

    letter-spacing: -0.015em;

    color: #ffffff;

    transition: background-color 0.2s ease;

    &:hover {
      background: ${shade(0.2, '#063966')};
    }
  }

  span {
    margin-top: 0.5rem;
    color: #666666;
    height: 1.875rem;
    width: 9.375rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 0.625rem;
    line-height: 0.938rem;
    text-align: center;
    letter-spacing: -0.015em;

    color: #666666;
  }
`;

export const ModelFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.6rem;

  strong {
    margin-bottom: 0.625rem;
    width: 24.375rem;
    height: 1.563rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;

    text-align: center;
    letter-spacing: -0.015em;

    color: #041524;
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 0.813rem;
    line-height: 1.188rem;

    text-align: center;
    letter-spacing: -0.015em;

    color: #3d3d3d;
  }

  hr {
    margin: 0.25rem 0;
  }
`;

export const FilesHistoryContainer = styled.div`
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.5rem;
  @media (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const TitleHistory = styled.h1`
  width: 58%;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;

  color: #05233e;

  @media (max-width: 1350px) {
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ClearHitoryButton = styled.button`
  border: 0;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: -0.015em;

  width: 8rem;
  height: 2rem;
  padding: 0.3rem;

  background: #676768;
  border-radius: 0.313rem;
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Thead = styled.thead`
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
`;

export const TableRowHead = styled.tr`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background: var(--blue-100);
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;
`;

export const CellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: #05233e;
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25rem;
  text-align: center;
`;

export const TableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;

  :nth-child(odd) {
    background: #efefef;
    &:hover {
      background: ${shade(0.1, '#CFDEF3')};
    }
  }

  :nth-child(even) {
    background: #e5e5e5;
    &:hover {
      background: ${shade(0.1, '#CFDEF3')};
    }
  }
`;

export const CellBody = styled.td`
  word-break: break-all;
  width: 100%;
  background: transparent;
  padding: 0.5rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  :nth-child(2) {
    cursor: pointer;
    color: #1665d8;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonZip = styled.button`
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 0.313rem;
  border: none;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  color: #1665d8;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  :disabled {
    cursor: initial;
    color: #05233e;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Button = styled.button`
  width: 7rem;
  height: 1.75rem;

  background: #063966;
  border-radius: 0.313rem;

  color: #fff;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;
  border: none;

  :disabled {
    background: ${shade(0.1, '#817f7f')};
    cursor: initial;
  }
`;
