import { AiOutlineCalculator } from 'react-icons/ai';
import { IHubspotDeal } from '../../dtos/IHubspotDeal';
import { Form, Div, Label, Input, Button } from '../../styles';

interface IProps {
  deal: IHubspotDeal;
  openLoan: () => void;
  brokerClient: boolean;
}
export const InformationLender = ({
  deal,
  openLoan,
  brokerClient,
}: IProps): JSX.Element => {
  return (
    <Form>
      <Div className="container">
        <Div className="one">
          <Label>
            Nome do credor
            <Input id="name" name="name" value={deal.dealname} disabled />
          </Label>
        </Div>
        <Div className="two">
          <Label>
            CPF
            <Input id="CPF" name="cpf" value={deal.cpf} disabled />
          </Label>
          <Label>
            Nascimento
            <Input id="birth" name="birth" value={deal.birth_date} disabled />
          </Label>
        </Div>
        <Div className="two">
          <Label>
            Idade <Input id="age" name="age" value={deal.age} disabled />
          </Label>
          <Label>
            Cidade
            <Input id="income" name="income" value={deal.city} disabled />
          </Label>
        </Div>
      </Div>
      <Div className="container">
        <Div className="one">
          <Label>
            Filiação
            <Input id="name" name="name" value={deal.filiation} disabled />
          </Label>
        </Div>

        <Div className="one">
          {!brokerClient && (
            <Label>
              Possui empréstimo consignado?
              <Input
                id="income"
                name="income"
                value={
                  deal.hasLoan === true
                    ? 'SIM - CONFERIR VALORES'
                    : deal.hasLoan === false
                    ? 'NÃO'
                    : undefined
                }
                disabled
              />
            </Label>
          )}
        </Div>
        <Div className="two">
          {!brokerClient && (
            <Label>
              Renda
              <Input id="income" name="income" value={deal.income} disabled />
            </Label>
          )}
        </Div>
      </Div>
    </Form>
  );
};
