import { add } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { gql, useSubscription } from '@apollo/client';
import { Body } from '../../../../components/Styles/Body';
import { DayPicker } from '../../../../components/DayPicker';
import { Container } from '../../../../components/Container';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow_right.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_blue.svg';
import { ReactComponent as HistoryArrow } from '../../../../assets/icons/history_arrow.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import api from '../../../../services/api';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';

import {
  TitleContainer,
  StatusAndImportContainer,
  OpenModalContainer,
  ButtonModalContainer,
  Separator,
  Main,
  CalendarAndFilterContainer,
  DaySelectedContainer,
  PreviousButton,
  ContainerInput,
  ContainerInputDate,
  NextButton,
  SchedulingsStatusContainer,
  StatusBox,
  QueuesButton,
  TitleSchedulingStatus,
  FilterSelectContainer,
  ListClosedAndRequestButton,
  ApprovalRequestButton,
  ListClosedDealsButton,
  SelectContainer,
} from './styles';

import { ClosersHistory } from '../ClosersHistory';
import { HuntersHistory } from '../HuntersHistory';
import { ModalImportPriority } from './ModalImportPriority';
import { ModalHoursAvailable } from './ModalHoursAvailable';
import { ModalCloserRequest } from './ModalClosersRequest';
import { ModalApprovalRequest } from './ModalApprovalRequest';
import { IProposalFormDTO } from '../../dtos/IProposalFormDTO';
import { useAuth } from '../../../../hooks/auth';

export const History: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [selectHistory, setSelectHistory] = useState<string>('Closers');
  const [selectHunterTeam, setSelectHunterTeam] = useState<string>('');
  const [proposals, setProposals] = useState<IProposalFormDTO[]>(
    [] as IProposalFormDTO[],
  );
  const [proposal, setProposal] = useState<IProposalFormDTO>(
    {} as IProposalFormDTO,
  );

  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [openImportPriorityModal, setOpenImportPriorityModal] = useState(false);
  const [openModalClosersRequest, setOpenModalClosersRequest] = useState(false);
  const [openModalApprovalRequest, setOpenModalApprovalRequest] =
    useState(false);
  const [filterHuntersHistory, setFilterHuntersHistory] =
    useState<string>('Individual');

  const NOTIFICATIONS_PROPOSAL_FORM = gql`
    subscription Subscription($args: String!) {
      proposalFormNotification(args: $args)
    }
  `;

  const { data: proposalFormData, loading: proposalFormLoading } =
    useSubscription(NOTIFICATIONS_PROPOSAL_FORM, {
      variables: {
        args: user.id,
      },
    });

  useEffect(() => {
    api
      .get('/negociations/calculations/proposal-form')
      .then(response => setProposals(response.data));
  }, []);

  useEffect(() => {
    if (proposalFormData) {
      api
        .get('/negociations/calculations/proposal-form')
        .then(response => setProposals(response.data));
    }
  }, [proposalFormData, proposalFormLoading]);

  const handleCloseImportPriorityModal = useCallback(() => {
    setOpenImportPriorityModal(false);
  }, []);

  const handleCloseHoursModal = useCallback(() => {
    setOpenHoursModal(false);
  }, []);

  const handleCloseModalClosersRequest = useCallback(() => {
    setOpenModalClosersRequest(false);
  }, []);

  const handleCloseModalApprovalRequest = useCallback(() => {
    setOpenModalApprovalRequest(false);
  }, []);

  const handleDateInputChange = useCallback((day: Date) => {
    setScheduleDate(day);
  }, []);

  const previousDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: -1,
    });
    setScheduleDate(newDate);
  }, [scheduleDate]);

  const nextDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: 1,
    });

    setScheduleDate(newDate);
  }, [scheduleDate]);

  const handleRedirectToListingClosedDeals = useCallback(() => {
    history.push('/negociations/schedule/manager/history/closed-deals');
  }, [history]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1Navigation>Gestor {'>'} </H1Navigation>
        {selectHistory === 'Closers' ? (
          <H1NavigationNow>Histórico do closer</H1NavigationNow>
        ) : (
          <H1NavigationNow>Histórico do hunter</H1NavigationNow>
        )}
      </Navegation>
      <Body>
        <TitleContainer selectHistory={selectHistory}>
          <button
            type="button"
            className="closer-history"
            onClick={() => setSelectHistory('Closers')}
          >
            Closer
            <Separator />
          </button>
          <button
            type="button"
            className="hunter-history"
            onClick={() => setSelectHistory('Hunters')}
          >
            Hunter
            <Separator />
          </button>
        </TitleContainer>

        <StatusAndImportContainer>
          <OpenModalContainer>
            <span>Queues:</span>

            <ButtonModalContainer>
              <button
                style={{ width: '100%', height: '100%' }}
                type="button"
                onClick={() => setOpenImportPriorityModal(true)}
              >
                Importar o <br /> arquivo <HistoryArrow />
              </button>
            </ButtonModalContainer>
          </OpenModalContainer>

          <SchedulingsStatusContainer>
            <TitleSchedulingStatus>Status dos horários:</TitleSchedulingStatus>
            <StatusBox>
              <QueuesButton onClick={() => setOpenHoursModal(true)}>
                Visualizar os horários <HistoryArrow />
              </QueuesButton>
            </StatusBox>
          </SchedulingsStatusContainer>
        </StatusAndImportContainer>

        <Main>
          <CalendarAndFilterContainer>
            <DaySelectedContainer>
              <PreviousButton onClick={previousDate}>
                <ArrowLeftIcon />
              </PreviousButton>
              <ContainerInput>
                <ContainerInputDate>
                  <CalendarIcon />
                  <DayPicker
                    handleChange={day => handleDateInputChange(day)}
                    value={scheduleDate}
                  />
                </ContainerInputDate>
              </ContainerInput>
              <NextButton onClick={nextDate}>
                <ArrowRightIcon />
              </NextButton>
            </DaySelectedContainer>

            {selectHistory === 'Hunters' ? (
              <FilterSelectContainer>
                <span>Filtre o histórico por: </span>
                {selectHunterTeam === 'Todos' ? (
                  <SelectContainer
                    defaultValue="Todos"
                    onChange={e => setFilterHuntersHistory(e.target.value)}
                  >
                    <option value="Todos">Todos</option>
                    <option value="Pendente">Pendente</option>
                    <option value="Concluída">Concluído</option>
                    <option value="Tentou">Tentou</option>
                  </SelectContainer>
                ) : (
                  <SelectContainer
                    defaultValue="Individual"
                    onChange={e => setFilterHuntersHistory(e.target.value)}
                  >
                    <option value="Individual">Individual</option>
                    <option value="Todos">Todos</option>
                  </SelectContainer>
                )}
                <SearchIcon />
              </FilterSelectContainer>
            ) : (
              <ListClosedAndRequestButton>
                {(user.profile === 'Administrador' ||
                  user.profile === 'Liderança Comercial' ||
                  user.profile === 'Gestor Operação Comercial') && (
                  <ApprovalRequestButton
                    onClick={() => setOpenModalClosersRequest(true)}
                  >
                    <span> Solicitações de aprovação</span>
                    <span className="notification">
                      {proposals && proposals.length}
                    </span>
                  </ApprovalRequestButton>
                )}

                <ListClosedDealsButton
                  type="button"
                  onClick={handleRedirectToListingClosedDeals}
                >
                  Listar passagem de bastão &nbsp; <HistoryArrow />
                </ListClosedDealsButton>
              </ListClosedAndRequestButton>
            )}
          </CalendarAndFilterContainer>

          {selectHistory === 'Closers' ? (
            <ClosersHistory scheduleDate={scheduleDate} />
          ) : (
            <HuntersHistory
              scheduleDate={scheduleDate}
              filterHuntersHistory={filterHuntersHistory}
              setSelectHunterTeam={setSelectHunterTeam}
              setFilterHuntersHistory={setFilterHuntersHistory}
            />
          )}

          <ModalHoursAvailable
            isOpen={openHoursModal}
            onRequestClose={handleCloseHoursModal}
          />

          <ModalImportPriority
            isOpen={openImportPriorityModal}
            onRequestClose={handleCloseImportPriorityModal}
          />

          <ModalCloserRequest
            isOpen={openModalClosersRequest}
            onRequestClose={handleCloseModalClosersRequest}
            proposals={proposals}
            setProposal={setProposal}
            setOpenModalApprovalRequest={setOpenModalApprovalRequest}
          />

          <ModalApprovalRequest
            isOpen={openModalApprovalRequest}
            onRequestClose={handleCloseModalApprovalRequest}
            proposal={proposal}
            proposals={proposals}
            setProposals={setProposals}
          />
        </Main>
      </Body>
    </Container>
  );
};
