import styled from 'styled-components';

export const CloserRequestContainer = {
  content: {
    width: '18rem',
    minHeight: '10rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
  },
};

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 1rem;
  color: var(--blue-800);

  width: 100%;
  height: 2.25rem;

  background: var(--blue-100);
`;

export const ClosersContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0.938rem 0.688rem;
  gap: 0.25rem;
`;

export const CloserName = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 1.875rem;

  background: var(--gray-100);
  border-radius: 0.25rem;
  border: none;
  transition: filter 0.2s;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-800);

  :hover {
    filter: brightness(0.9);
  }
`;
