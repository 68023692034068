import { InputSLA } from '../components/InputSLA';
import { SelectSLA } from '../components/SelectSLA';
import { ISLABase } from '../interface';
import {
  connection_types,
  contactPreferences,
  decisionMaker,
} from '../../../Hunter/utils/noteAmountByInput';
import {
  whoContacted,
  meansConnection,
  optIn,
  contact_competition,
  objections,
  yesOrNo,
  profileOptions,
  plans,
  thermometer,
  yesOrNoOrNA,
} from '../utils/optionsSLA';
import { SelectedData } from '../components/InputData';
import { CheckBoxSLA } from '../components/CheckBoxSLA';

interface ISLABaseProps {
  typemodal: string;
  SLA: ISLABase;
  handleChange: (e: any) => void;
  handleChangeCurrency: (e: any) => void;
  data: string[];
}

export function SLABase({
  SLA,
  handleChange,
  typemodal,
  data,
  handleChangeCurrency,
}: ISLABaseProps): JSX.Element {
  return (
    <div>
      {typemodal !== 'N5' && (
        <InputSLA
          type="text"
          title="Id do Ticket: *"
          name="ticket_id"
          value={SLA.ticket_id || ''}
          handleChange={handleChange}
          isCurrency={false}
          placeholder=""
        />
      )}

      <InputSLA
        type="text"
        title="Contato válido: *"
        name="valid_contact"
        value={SLA.valid_contact || ''}
        handleChange={handleChange}
        isCurrency={false}
        placeholder="Ex: 5531999998888"
      />

      <SelectSLA
        title="Com quem foi o contato? *"
        name="who_contacted"
        value={SLA.who_contacted || ''}
        handleChange={handleChange}
        options={whoContacted}
      />
      <SelectSLA
        title="Tomador de decisão/influência: *"
        name="decision_maker"
        value={SLA.decision_maker || ''}
        handleChange={handleChange}
        options={decisionMaker}
      />

      <InputSLA
        type="text"
        title="Contato válido (tomador de decisão):"
        name="valid_contact_decision_maker"
        value={SLA.valid_contact_decision_maker || ''}
        handleChange={handleChange}
        isCurrency={false}
        placeholder="Ex: 5531999998888"
      />

      {/*    <InputSLA
        type="text"
        title="Contato válido WPP:"
        name="valid_contact_wpp_opt_in"
        value={SLA.valid_contact_wpp_opt_in || ''}
        handleChange={handleChange}
        isCurrency={false}
        placeholder=""
      />
      <InputSLA
        type="text"
        title="E-mail válido:"
        name="valid_email"
        value={SLA.valid_email || ''}
        handleChange={handleChange}
        isCurrency={false}
        placeholder=""
      /> */}

      <SelectSLA
        title="Tipo de conexão: *"
        name="connection_type"
        value={SLA.connection_type || ''}
        handleChange={handleChange}
        options={connection_types}
      />
      <SelectSLA
        title="Meio de conexão: *"
        name="connection_method"
        value={SLA.connection_method || ''}
        handleChange={handleChange}
        options={meansConnection}
      />
      <SelectSLA
        title="Opt In: *"
        name="opt_in"
        value={SLA.opt_in || ''}
        handleChange={handleChange}
        options={optIn}
      />

      {typemodal !== 'N5' && (
        <SelectSLA
          title={`Objeções levantadas ${typemodal} *`}
          name="objection"
          value={SLA.objection || ''}
          handleChange={handleChange}
          options={objections}
        />
      )}

      <CheckBoxSLA
        title="Perfil: *"
        handleChange={handleChange}
        options={profileOptions}
        profile={SLA.profile || []}
      />
      <SelectSLA
        title="Tinha conhecimento do precatório? *"
        name="was_aware_precatory"
        value={SLA.was_aware_precatory || ''}
        handleChange={handleChange}
        options={yesOrNoOrNA}
      />

      <SelectSLA
        title="Tinha conhecimento da previsão de pagamento? *"
        name="was_aware_PEC"
        value={SLA.was_aware_PEC || ''}
        handleChange={handleChange}
        options={yesOrNoOrNA}
      />

      <SelectSLA
        title="O atraso do pagamento atrasa a vida do cliente? *"
        name="late_payment_delays_the_customer"
        value={SLA.late_payment_delays_the_customer || ''}
        handleChange={handleChange}
        options={yesOrNoOrNA}
      />

      <SelectSLA
        title="Teve interesse em saber os valores? *"
        name="Interested_in_knowing_the_values"
        value={SLA.Interested_in_knowing_the_values || ''}
        handleChange={handleChange}
        options={yesOrNoOrNA}
      />

      <SelectSLA
        title="Teve contato com a concorrência: *"
        name="contact_competition"
        value={SLA.contact_competition || ''}
        handleChange={handleChange}
        options={contact_competition}
      />

      <InputSLA
        type="text"
        title="Valor que considera negociar: "
        name="value_to_negociate"
        value={SLA.value_to_negociate || ''}
        handleChange={handleChangeCurrency}
        isCurrency
        placeholder="Digite apenas números"
      />

      <InputSLA
        type="text"
        title="Valor de proposta da concorrência:"
        name="value_proposal_competition"
        value={SLA.value_proposal_competition || ''}
        handleChange={handleChangeCurrency}
        isCurrency
        placeholder="Digite apenas números"
      />

      <SelectSLA
        title="Qual plano do cliente?"
        name="plans"
        value={SLA.plans || ''}
        handleChange={handleChange}
        options={plans}
      />

      {typemodal !== 'N5' ? (
        <SelectSLA
          title="Foi enviado mensagem para o cliente? *"
          name="send_message_to_client"
          value={SLA.send_message_to_client || ''}
          handleChange={handleChange}
          options={yesOrNo}
        />
      ) : (
        <SelectSLA
          title="Foi enviada mensagem de confirmação? *"
          name="confirmation_message_sent"
          value={SLA.confirmation_message_sent || ''}
          handleChange={handleChange}
          options={yesOrNo}
        />
      )}

      {typemodal !== 'N5' && (
        <>
          <SelectSLA
            title={`Agendamos um novo contato ${typemodal}? *`}
            name="we_schedule_a_new_contact"
            value={SLA.we_schedule_a_new_contact || ''}
            handleChange={handleChange}
            options={yesOrNo}
          />

          {SLA.we_schedule_a_new_contact === 'Sim' && (
            <SelectedData
              availableHours={data}
              handleChange={handleChange}
              data={SLA.day}
              time={SLA.hours}
            />
          )}

          <SelectSLA
            title="Termômetro/potencial *"
            name="thermometer"
            value={SLA.thermometer || ''}
            handleChange={handleChange}
            options={thermometer}
          />
        </>
      )}

      <SelectSLA
        title="Preferência de contato:"
        name="contact_preferences"
        value={SLA.contact_preferences || ''}
        handleChange={handleChange}
        options={contactPreferences}
      />
    </div>
  );
}
