import { useState } from 'react';

import { VisualizationCalendar } from './VisualizationCalendar';
import { VisualizationList } from './VisualizationList';

import { ReactComponent as BoxTimeIcon } from '../../../../assets/icons/box_time.svg';
import { ReactComponent as CalendarSelectedIcon } from '../../../../assets/icons/calendar_selected.svg';
import { ReactComponent as ListSelectedIcon } from '../../../../assets/icons/list_selected.svg';
import { ReactComponent as CalendarNotSelectedIcon } from '../../../../assets/icons/calendar_not_selected.svg';
import { ReactComponent as ListNotSelectedIcon } from '../../../../assets/icons/list_not_selected.svg';

import {
  CalendarContainer,
  ScheduleLine,
  WatchIconContent,
  LineContent,
  DateContainer,
  ButtonsContainer,
  VisualizationButton,
} from './styles';

type ScheduleHunterContainer = {
  date: Date;
};

export function ScheduleHunterContainer({
  date,
}: ScheduleHunterContainer): JSX.Element {
  const weekdays = [
    'DOMINGO',
    'SEGUNDA',
    'TERÇA',
    'QUARTA',
    'QUINTA',
    'SEXTA',
    'SÁBADO',
  ];

  const [selectedVisualization, setSelectedVisualization] =
    useState<string>('calendar');

  return (
    <CalendarContainer>
      <ScheduleLine>
        <WatchIconContent>
          <BoxTimeIcon />
        </WatchIconContent>

        <LineContent>
          <div id="empty" />
          <DateContainer>
            <p id="title">{date.getDate()}</p>
            <p id="subtitle">{weekdays[date.getDay()]}</p>
          </DateContainer>

          <ButtonsContainer>
            <VisualizationButton
              type="button"
              onClick={() => setSelectedVisualization('calendar')}
            >
              {selectedVisualization === 'calendar' ? (
                <CalendarSelectedIcon />
              ) : (
                <CalendarNotSelectedIcon />
              )}
            </VisualizationButton>

            <VisualizationButton
              type="button"
              onClick={() => setSelectedVisualization('listing')}
            >
              {selectedVisualization === 'listing' ? (
                <ListSelectedIcon />
              ) : (
                <ListNotSelectedIcon />
              )}
            </VisualizationButton>
          </ButtonsContainer>
        </LineContent>
      </ScheduleLine>

      {selectedVisualization === 'calendar' ? (
        <VisualizationCalendar />
      ) : (
        <VisualizationList />
      )}
    </CalendarContainer>
  );
}
