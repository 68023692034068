import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const Container = {
  content: {
    width: `${pxToRem(456)}`,
    height: `${pxToRem(220)}`,
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
    alignItems: 'center',
    boxShadow: '5px 5px 20px 0px #0000004D',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const ContainerModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  margin-top: ${pxToRem(10)};
  font-family: 'Poppins';
  font-weight: 400;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(30)};
  letter-spacing: -1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #05233e;
`;

export const Line = styled.div`
  display: flex;
  width: ${pxToRem(290)};
  border: 1px solid #9d9d9d;
`;

export const ContainerInputs = styled.div`
  margin-top: ${pxToRem(10)};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${pxToRem(5)};
`;

export const ExitButton = styled.button`
  width: ${pxToRem(100)};
  height: ${pxToRem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pxToRem(5)};
  border: 1px solid #1665d8;
  color: #1665d8;
  background-color: #ffffff;

  font-family: 'Poppins';
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  letter-spacing: -1.5%;
  cursor: pointer;

  :hover {
    background-color: #cfdef3;
  }
`;

export const ConfirmButton = styled.button`
  width: ${pxToRem(100)};
  height: ${pxToRem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pxToRem(5)};
  border: none;
  color: #ffffff;
  background-color: #1665d8;

  font-family: 'Poppins';
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  letter-spacing: -1.5%;
  cursor: pointer;

  :hover {
    background-color: #05233e;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(20)};
  margin-top: ${pxToRem(20)};
`;
