import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../utils/pxToRem';

export const Container = {
  content: {
    width: '49rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
    alignItems: 'center',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

interface HeaderProps {
  isBefore: boolean;
  status: string;
  try_times: number;
}

export const Header = styled.div<HeaderProps>`
  width: 100%;
  height: 3.125rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.625rem 0.625rem 0 0;
  padding: 0 1.5rem;

  font-weight: 600;
  font-size: 1.25rem;
  color: var(--white);

  ${props =>
    props.status === 'Concluída'
      ? css`
          background: var(--green-400);
        `
      : props.status === 'Pendente' && props.isBefore
      ? css`
          background: var(--red-500);
        `
      : props.status === 'Pendente' && props.try_times > 0
      ? css`
          background: var(--yellow-500);
        `
      : css`
          background: var(--blue-500);
        `}
`;

export const ButtonsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

interface EditButtonProps {
  editFields?: boolean;
}

export const EditDeleteButton = styled.button<EditButtonProps>`
  display: flex;

  border: none;
  background: none;

  ${props =>
    props.editFields &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      width: 10.375rem;
      height: 1.75rem;

      border-radius: 0.25rem;
      background: var(--white);

      font-weight: 600;
      font-size: 1rem;
      color: var(--blue-400);
    `}
`;

export const CloseButton = styled.button`
  display: flex;
  border: none;
  background: none;
`;

export const Main = styled.div`
  display: flex;
  height: fit-content;
  max-height: ${pxToRem(550)};

  overflow: auto;

  flex-direction: column;

  padding: 1rem 1.5rem;

  .observation-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .hour-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .editInputsContainer {
    display: grid;
    grid-template-columns: repeat(5, 5.6rem);
    gap: 0.5rem;
  }

  #tryTimesInput {
    background: #efefef;
    border: 0.031rem solid #063966;
    border-radius: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.875rem;

    height: 1.625rem;
    width: 5.1rem;
  }
`;

interface LabelInputContainerProps {
  editFields?: boolean;
}

export const LabelInputContainer = styled.div<LabelInputContainerProps>`
  display: column;
  align-items: center;

  margin-top: 1rem;
  gap: 0.125rem;

  ${props =>
    props.editFields
      ? css`
          margin: 0;

          textarea {
            border: 0.5px solid var(--blue-600);
            resize: vertical;

            max-height: 10rem;
          }
        `
      : css`
          textarea {
            resize: true;
          }
        `}
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-800);
`;

export const Input = styled.input`
  word-break: break-word;
  flex: 1;
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray-800);
  border: none;
  background: none;
`;

export const Link = styled.a`
  font-weight: 500;
  font-size: 1rem;
  color: var(--blue-300);
`;

export const LineSeparator = styled.div`
  border: 1px solid var(--gray-400);
  width: 100%;
  margin-top: 0.85rem;
  margin-bottom: 0.5rem;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 6.625rem;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-800);
  text-align: justify;

  border: 0.5px solid var(--blue-600);
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: var(--gray-100);
`;

export const Select = styled.select`
  width: 8.438rem;
  height: 1.875rem;

  background: var(--gray-100);
  border: 0.031rem solid var(--blue-600);
  box-sizing: border-box;
  border-radius: 0.25rem;

  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--gray-800);
`;

export const HoursContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.5rem;
`;
