import stopwatch from '../../../../assets/icons/stopwatch.svg';

import {
  InfosWorkImpactContainer,
  DateContainer,
  ColumnContainer,
  TitleColumnContainer,
  LineSeparator,
  ValueContainer,
  PriorizationCredorContainer,
} from './styles';
import { IInfoCloserInADayDTO } from '../../dtos/IInfoCloserInADayDTO';
import { INextActivityDTO } from '../../dtos/INextActivityDTO';
import { useAuth } from '../../../../hooks/auth';

interface IInfosWorkImpact {
  schedulesDate: Date;
  closerInfos: IInfoCloserInADayDTO;
  spokenTime: string;
  nextActivity?: INextActivityDTO;
  formattedTime: (timestamp: Date) => string;
  handleOpenModal?: (nextActivity: INextActivityDTO) => void;
}

export const InfosWorkImpactPanel: React.FC<IInfosWorkImpact> = ({
  schedulesDate,
  closerInfos,
  spokenTime,
  nextActivity,
  formattedTime,
  handleOpenModal,
}) => {
  const { user } = useAuth();

  return (
    <>
      <InfosWorkImpactContainer>
        <DateContainer>
          <p>{`${schedulesDate.getDate()},`}</p>
          <p>{schedulesDate.toLocaleString('default', { month: 'short' })}</p>
          <p>{`${schedulesDate.getFullYear()}`}</p>
        </DateContainer>

        <LineSeparator />

        <ColumnContainer>
          <TitleColumnContainer>
            <p>Atendimentos:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{closerInfos && closerInfos.attendance}</p>
          </ValueContainer>

          <TitleColumnContainer>
            <p>Tempo falado:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{spokenTime}</p>
          </ValueContainer>
        </ColumnContainer>

        <LineSeparator />

        <ColumnContainer>
          <TitleColumnContainer>
            <p>Ticket Médio:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{closerInfos && closerInfos.ticket}</p>
          </ValueContainer>
        </ColumnContainer>

        <LineSeparator />

        <ColumnContainer>
          <TitleColumnContainer>
            <p>Agendamentos:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{closerInfos && closerInfos.schedulings}</p>
          </ValueContainer>

          <TitleColumnContainer>
            <p>Priorização diária:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{closerInfos && closerInfos.priority}</p>
          </ValueContainer>
        </ColumnContainer>

        <LineSeparator />

        <ColumnContainer>
          <TitleColumnContainer>
            <p>Tentou:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{closerInfos && closerInfos.try_times}</p>
          </ValueContainer>

          <TitleColumnContainer>
            <p>Falado:</p>
          </TitleColumnContainer>

          <ValueContainer>
            <p>{closerInfos && closerInfos.talk}</p>
          </ValueContainer>
        </ColumnContainer>

        {user.profile === 'Closers' || user.profile === 'Closer Linha 1' ? (
          <>
            <LineSeparator />
            <ColumnContainer>
              {nextActivity && nextActivity.id ? (
                nextActivity.notes_next_activity_date ? (
                  <>
                    <TitleColumnContainer>
                      <p>Próximo horário:</p>
                    </TitleColumnContainer>

                    <ValueContainer>
                      <img src={stopwatch} alt="Relógio" />
                      <p>
                        {formattedTime(
                          new Date(nextActivity.notes_next_activity_date),
                        )}
                      </p>
                    </ValueContainer>

                    <PriorizationCredorContainer>
                      <p>Priorização</p>
                      <p>Credor: {nextActivity.dealname}</p>
                    </PriorizationCredorContainer>

                    <button
                      id="infos"
                      type="button"
                      onClick={() => handleOpenModal(nextActivity)}
                    >
                      + INFORMAÇÕES
                    </button>
                  </>
                ) : nextActivity?.every_day !== undefined ? (
                  <>
                    <TitleColumnContainer>
                      <p>Próximo horário:</p>
                    </TitleColumnContainer>

                    <ValueContainer>
                      <img src={stopwatch} alt="Relógio" />
                      <p>
                        {nextActivity?.time_init
                          ? formattedTime(nextActivity.time_init)
                          : ''}
                      </p>
                    </ValueContainer>

                    <PriorizationCredorContainer>
                      <p>{nextActivity.name}</p>
                    </PriorizationCredorContainer>

                    <button
                      id="infos"
                      type="button"
                      onClick={() => handleOpenModal(nextActivity)}
                    >
                      + INFORMAÇÕES
                    </button>
                  </>
                ) : (
                  <>
                    <TitleColumnContainer>
                      <p>Próximo horário:</p>
                    </TitleColumnContainer>

                    <ValueContainer>
                      <img src={stopwatch} alt="Relógio" />
                      <p>
                        {nextActivity?.timestamp
                          ? formattedTime(nextActivity.timestamp)
                          : ''}
                      </p>
                    </ValueContainer>

                    <PriorizationCredorContainer>
                      <p>{nextActivity.name}</p>
                      <p>Credor: {nextActivity.dealname}</p>
                    </PriorizationCredorContainer>

                    <button
                      id="infos"
                      type="button"
                      onClick={() => handleOpenModal(nextActivity)}
                    >
                      + INFORMAÇÕES
                    </button>
                  </>
                )
              ) : (
                <TitleColumnContainer>
                  <p>Não há próxima</p>
                  <p>atividade definida</p>
                </TitleColumnContainer>
              )}
            </ColumnContainer>
          </>
        ) : (
          ''
        )}
      </InfosWorkImpactContainer>
    </>
  );
};

export default InfosWorkImpactPanel;
