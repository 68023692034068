import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { format, isBefore } from 'date-fns';

import editIcon from '../../../../assets/icons/edit.svg';
import closeIcon from '../../../../assets/icons/cancel.svg';
import dropdownChecklistIcon from '../../../../assets/icons/dropdown_checklist_icon.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_blue.svg';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { formatAmount } from '../../../../utils/formatAmount';
import { DayPicker } from '../../../../components/DayPicker';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';

import {
  Container,
  Header,
  ButtonsHeaderContainer,
  EditButton,
  CloseButton,
  Main,
  ProvidenceAvailableHours,
  TypeActivity,
  LabelInputContainer,
  Label,
  Input,
  Span,
  Link,
  Textarea,
  LineSeparator,
  ContainerInput,
  ContainerInputDate,
  Select,
  CheckboxContainer,
  CheckboxInput,
  LabelChecklist,
  SaveEditButton,
  SaveEditButtonContainer,
} from './styles';

import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';
import { IActivityCSDTO } from '../dtos/IActivityCSDTO';
import { confirmModal } from '../../../../components/ModalConfirm';
import { Loading } from '../../../../components/Loading';
import { ModalCreateActivityFromActivity } from '../ModalCreateActivityFromProvidence';
import { ModalConclusionConfirmation } from '../ModalConclusionConfirmation';

Modal.setAppElement('#root');

interface IIntervals {
  intervalInit: Date[];
  intervalEnd: Date[];
}

interface FormData {
  time_init: string;
  time_end: string;
  title: string;
  observation: string;
  situation: string;
}

interface IProvidenceChecklist {
  id: string;
  providence_id: string;
  checklist_name: string;
}

interface ModalActivityProps {
  isOpen: boolean;
  isProvidence: boolean;
  onRequestClose: () => void;
  activity: IActivityCSDTO;
  activitiesInADay: IActivitiesInADayDTO | undefined;
  setActivitiesInADay: ((activity: IActivitiesInADayDTO) => void) | undefined;
  updateListAcitivity: () => void;
}

export function ModalUpdateActivity({
  isOpen,
  onRequestClose,
  activity,
  activitiesInADay,
  setActivitiesInADay,
  isProvidence,
  updateListAcitivity,
}: ModalActivityProps): JSX.Element {
  const { user } = useAuth();

  const [hourOption, setHourOption] = useState<string>('');
  const [providenceChecklist, setProvidenceChecklist] = useState<
    IProvidenceChecklist[]
  >([] as IProvidenceChecklist[]);
  const [openConclusionConfirmationModal, setConclusionConfirmationModal] =
    useState(false);
  const [conclusionVariableConfirmation, setConclusionVariableConfirmation] =
    useState<boolean>();
  const [editFields, setEditFields] = useState(false);
  const [activitySituationSelected, setActivitySituationSelected] = useState(
    activity.situation,
  );
  const [date, setDate] = useState(
    activity.time_init
      ? new Date(activity.time_init)
      : activity.timestamp
      ? new Date(activity.timestamp)
      : new Date(),
  );
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [intervals, setIntervals] = useState<IIntervals>({} as IIntervals);

  const activitySituations = ['Pendente', 'Concluída'];
  const [activityTypeToCreate, setActivityTypeToCreate] = useState('');

  const [checklist, setChecklist] = useState<string[]>([] as string[]);
  const [isEnableChecklist, setIsEnableChecklist] = useState<boolean>(false);

  const [openCreateActivityModal, setOpenCreateActivityModal] = useState(false);
  const [showCreateActivityButton, setShowCreateActivityButton] =
    useState(false);

  useEffect(() => {
    if (
      isOpen &&
      (user.profile === 'Customer Success' ||
        user.profile === 'Customer Success Calculator')
    ) {
      api
        .get(
          `/schedule-cs/available-hours?date=${format(
            date,
            'yyyy-MM-dd',
          )}&user_id=${user.id}`,
        )
        .then(response => {
          setIntervals(response.data);
        });
    }
  }, [date, isOpen, user]);

  useEffect(() => {
    if (
      isOpen &&
      !activity.time_init &&
      (user.profile === 'Customer Success' ||
        user.profile === 'Customer Success Calculator')
    ) {
      api
        .get(`/schedule-cs/providence-checklist/${activity.id}`)
        .then(response => {
          setProvidenceChecklist(response.data);
        });
    }
  }, [activity, isOpen, user.profile]);

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setEditFields(false);
      setIsEnableChecklist(true);
      setChecklist([] as string[]);
      setFormData({} as FormData);
      setDate(
        activity.time_init
          ? new Date(activity.time_init)
          : activity.timestamp
          ? new Date(activity.timestamp)
          : new Date(),
      );
      setActivitySituationSelected(activity.situation);
      setHourOption('');
    }
  }, [activity, isOpen, onRequestClose]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const handleCloseUpdateActivity = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleUpdateChecklist = useCallback(
    async checked_value => {
      const index = checklist.findIndex(
        foundChecklist => foundChecklist === checked_value,
      );

      if (index < 0) {
        checklist.push(checked_value);
        setChecklist(checklist);
      } else {
        checklist.splice(index, 1);
        setChecklist(checklist);
      }
    },
    [checklist],
  );
  const handleCloseConclusionConfirmationModal = useCallback(() => {
    setConclusionConfirmationModal(false);
  }, []);

  const handleSaveActivityChanges = useCallback(async () => {
    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      if (activity.time_init) {
        const timeInit = formData.time_init
          ? `${format(date, 'yyyy-MM-dd')} ${formData.time_init}`
          : undefined;

        const timeEnd = formData.time_end
          ? `${format(date, 'yyyy-MM-dd')} ${formData.time_end}`
          : undefined;

        await api.patch('/schedule-cs', {
          id: activity.id,
          user_id: user.id,
          title: formData.title || activity.title,
          observation: formData.observation || undefined,
          time_init: timeInit,
          time_end: timeEnd,
          situation: activitySituationSelected,
        });

        setDate(new Date(activity.time_init));

        setActivitySituationSelected('');

        toast.success('Atividade atualizada com sucesso!');
        updateListAcitivity();
        // onRequestClose();
      }
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      Swal.fire('Atenção!', error.response.data.message, 'warning');
    }
  }, [
    activity.id,
    activity.time_init,
    activity.title,
    activitySituationSelected,
    date,
    formData.observation,
    formData.time_end,
    formData.time_init,
    formData.title,
    user.id,
    updateListAcitivity,
  ]);

  const handleConclusionVariableConfirmation = useCallback(
    aux => {
      setConclusionVariableConfirmation(aux);

      if (activitySituationSelected === 'Pendente') {
        handleSaveActivityChanges();
        onRequestClose();
      } else if (
        activitySituationSelected === 'Concluída' &&
        activity.providence_id != null
      ) {
        setConclusionConfirmationModal(true);

        if (aux === false) {
          setActivityTypeToCreate('Providência');
          handleSaveActivityChanges();
          setOpenCreateActivityModal(true);
        } else if (aux === true) {
          handleSaveActivityChanges();
          onRequestClose();
        }
      } else if (
        activitySituationSelected === 'Concluída' &&
        activity.type === 'Retorno credor'
      ) {
        setActivityTypeToCreate('Retorno credor');
        handleSaveActivityChanges();
        setOpenCreateActivityModal(true);
      } else {
        handleSaveActivityChanges();
        onRequestClose();
      }
    },
    [
      activity.providence_id,
      activity.type,
      activitySituationSelected,
      handleSaveActivityChanges,
      onRequestClose,
    ],
  );

  const handleSaveProvidenceChanges = useCallback(async () => {
    setShowCreateActivityButton(true);

    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      const timestamp = hourOption
        ? `${format(date, 'yyyy-MM-dd')} ${hourOption}`
        : null;

      await api.patch('/schedule-cs/providence', {
        providence_id: activity.id,
        observation: formData.observation || undefined,
        timestamp,
        checklists: checklist,
        situation: activitySituationSelected,
        title: formData.title,
      });

      setActivitySituationSelected('');

      toast.success('Providência atualizada com sucesso!');
      if (updateListAcitivity !== undefined) {
        updateListAcitivity();
      }
      onRequestClose();
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      Swal.fire('Atenção!', error.response.data.message, 'warning');
    }
  }, [
    activity,
    onRequestClose,
    formData,
    date,
    activitySituationSelected,
    hourOption,
    checklist,
    updateListAcitivity,
  ]);

  const handleDescartProvidence = useCallback(
    async (activity_id: string) => {
      try {
        const confirm = await confirmModal(
          'Tem certeza que deseja descartar a providência?',
        );

        if (confirm) {
          await api.patch('/schedule-cs/providence', {
            providence_id: activity_id,
            situation: 'Descartada',
          });

          onRequestClose();
        }
      } catch (error) {
        Swal.fire('Atenção!', error.response.data.message, 'warning');
      }
    },
    [onRequestClose],
  );

  const handleCloseCreateActivityModal = useCallback(() => {
    setOpenCreateActivityModal(false);
  }, []);

  useEffect(() => {
    if (isProvidence && user.profile === 'Customer Success') {
      setEditFields(true);
    }
  }, [isProvidence, user]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={Container}>
      <Loading />

      {isProvidence &&
      (user.profile === 'Customer Success' ||
        user.profile === 'Customer Success Calculator') ? (
        <>
          <Header
            isBefore={
              activity.type !== 'Reunião'
                ? activity.time_init
                  ? verifyIsBeforeNow(new Date(activity.time_init))
                  : verifyIsBeforeNow(new Date(activity.timestamp))
                : false
            }
            activityType={activity.type}
            isProvidence={isProvidence}
            status={activity.situation}
            checklist_concluded={
              activity.checklist_concluded &&
              Number(activity.checklist_concluded) > 0
            }
          >
            <span>Providência</span>

            <ButtonsHeaderContainer>
              {editFields ? (
                <EditButton
                  editFields={editFields}
                  onClick={handleSaveProvidenceChanges}
                >
                  Salvar alterações
                </EditButton>
              ) : (
                <EditButton onClick={() => setEditFields(true)}>
                  <img src={editIcon} alt="Editar informações" />
                </EditButton>
              )}

              <CloseButton onClick={onRequestClose}>
                <img src={closeIcon} alt="Fechar modal" />
              </CloseButton>
            </ButtonsHeaderContainer>
          </Header>

          <Main>
            <TypeActivity
              type="text"
              name="title"
              defaultValue={activity.title || activity.name}
              onChange={handleInputChange}
              disabled={!editFields}
              isEditable={editFields}
            />

            {activity.type !== 'Reunião' && (
              <>
                <LabelInputContainer>
                  <Label>Closer:</Label>
                  <Input type="text" value={activity.closer_name} disabled />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Link Hubspot:</Label>
                  <Link
                    href={`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                  </Link>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Nome do credor:</Label>
                  <Input type="text" value={activity.dealname} disabled />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Valor:</Label>
                  <Input
                    type="text"
                    value={formatAmount(Number(activity.amount))}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Valor de proposta:</Label>
                  <Input
                    type="text"
                    value={formatAmount(Number(activity.proposal_amount))}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Etapa:</Label>
                  <Span className="stage-input">{activity.stage_deal}</Span>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Etapa do ticket:</Label>
                  <Span className="stage-input">{activity.stage_ticket}</Span>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Contato válido:</Label>
                  <Input
                    type="text"
                    value={activity.valid_contact || ''}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Número da operação:</Label>
                  <Input
                    type="text"
                    value={activity.operation_number || ''}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Responsável:</Label>
                  <Input type="text" value={activity.user_name} disabled />
                </LabelInputContainer>
              </>
            )}

            {editFields && <LineSeparator />}

            <LabelInputContainer status={activity.situation}>
              <Label>Situação da atividade:</Label>
              {editFields ? (
                <>
                  {activitySituations.map(situation => (
                    <ScheduleOptionsButton
                      key={situation}
                      selectedValue={activitySituationSelected === situation}
                      type="button"
                      onClick={() => setActivitySituationSelected(situation)}
                    >
                      {situation}
                    </ScheduleOptionsButton>
                  ))}
                </>
              ) : (
                <Span className="stage-input">{activity.situation}</Span>
              )}
            </LabelInputContainer>
            <LabelInputContainer
              editFields={editFields}
              className="observation-container"
            >
              <Label>Observações:</Label>
              <Textarea
                name="observation"
                defaultValue={activity.observation || ''}
                onChange={handleInputChange}
                disabled={!editFields}
              />
            </LabelInputContainer>
            <>
              <LineSeparator />
              <LabelInputContainer className="checklist">
                <Label>Pop Providencia: {activity.name}</Label>
                {activity.checklist.description.map((description, index) => (
                  <CheckboxContainer key={description}>
                    <Label>PASSO {index + 1}</Label>
                    <Label>- {description}</Label>
                  </CheckboxContainer>
                ))}
              </LabelInputContainer>
            </>

            {isProvidence &&
              (user.profile === 'Customer Success' ||
                user.profile === 'Customer Success Calculator') && (
                <SaveEditButtonContainer
                  onClick={() => setOpenCreateActivityModal(true)}
                >
                  <SaveEditButton>Criar atividade</SaveEditButton>
                </SaveEditButtonContainer>
              )}

            {openCreateActivityModal && isProvidence && (
              <ModalCreateActivityFromActivity
                isOpen={openCreateActivityModal}
                onRequestClose={handleCloseCreateActivityModal}
                activity={activity}
                observation={activity.observation}
                activitiesInADay={activitiesInADay}
                setActivitiesInADay={setActivitiesInADay}
                updateActivityClose={handleCloseUpdateActivity}
                activityType={activityTypeToCreate}
              />
            )}
          </Main>
        </>
      ) : (
        <>
          <Header
            isBefore={
              activity.type !== 'Reunião'
                ? activity.time_init
                  ? verifyIsBeforeNow(new Date(activity.time_init))
                  : verifyIsBeforeNow(new Date(activity.timestamp))
                : false
            }
            activityType={activity.type}
            isProvidence={isProvidence}
            status={activity.situation}
            checklist_concluded={
              activity.checklist_concluded &&
              Number(activity.checklist_concluded) > 0
            }
          >
            {isProvidence ? (
              <span>Providência</span>
            ) : activity.time_init && activity.type !== 'Lembrete' ? (
              <span>
                {format(new Date(activity.time_init), 'dd/MM/yyyy')} -{' '}
                {format(new Date(activity.time_init), 'HH:mm')} às{' '}
                {format(new Date(activity.time_end), 'HH:mm')}
              </span>
            ) : activity.timestamp && activity.type !== 'Lembrete' ? (
              <span>
                {format(new Date(activity.timestamp), 'dd/MM/yyyy')} -{' '}
                {format(new Date(activity.timestamp), 'HH:mm')}
              </span>
            ) : (
              <span>Lembrete</span>
            )}

            <ButtonsHeaderContainer>
              {(user.profile === 'Customer Success' ||
                user.profile === 'Customer Success Calculator') &&
                (editFields ? (
                  <EditButton
                    editFields={editFields}
                    onClick={handleConclusionVariableConfirmation}
                  >
                    Salvar alterações
                  </EditButton>
                ) : (
                  <EditButton onClick={() => setEditFields(true)}>
                    <img src={editIcon} alt="Editar informações" />
                  </EditButton>
                ))}

              {user.profile === 'Gestor Customer Success' ||
                (user.profile === 'Administrador' && (
                  <EditButton
                    isManager
                    editFields={editFields}
                    onClick={() => handleDescartProvidence(activity.id)}
                  >
                    Descartar
                  </EditButton>
                ))}
              <CloseButton onClick={onRequestClose}>
                <img src={closeIcon} alt="Fechar modal" />
              </CloseButton>
            </ButtonsHeaderContainer>
          </Header>

          <Main>
            <TypeActivity
              type="text"
              name="title"
              isEditable={editFields && !!activity.time_init}
              defaultValue={activity.title || activity.name}
              disabled={!editFields && !!activity.time_init}
              onChange={handleInputChange}
            />

            {activity.type !== 'Reunião' && (
              <>
                <LabelInputContainer>
                  <Label>Closer:</Label>
                  <Input type="text" value={activity.closer_name} disabled />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Link Hubspot:</Label>
                  <Link
                    href={`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                  </Link>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Nome do credor:</Label>
                  <Input type="text" value={activity.dealname} disabled />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Valor:</Label>
                  <Input
                    type="text"
                    value={formatAmount(Number(activity.amount))}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Valor de proposta:</Label>
                  <Input
                    type="text"
                    value={formatAmount(Number(activity.proposal_amount))}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Etapa do deal:</Label>
                  <Span className="stage-input">{activity.stage_deal}</Span>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Etapa do ticket:</Label>
                  <Span className="stage-input">{activity.stage_ticket}</Span>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Contato válido:</Label>
                  <Input
                    type="text"
                    value={activity.valid_contact || ''}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Número da operação:</Label>
                  <Input
                    type="text"
                    value={activity.operation_number || ''}
                    disabled
                  />
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Responsável:</Label>
                  <Input type="text" value={activity.user_name} disabled />
                </LabelInputContainer>
              </>
            )}

            {editFields && <LineSeparator />}

            <LabelInputContainer
              editFields={editFields}
              className="observation-container"
            >
              <Label>Observações:</Label>
              <Textarea
                name="observation"
                defaultValue={activity.observation || ''}
                onChange={handleInputChange}
                disabled={!editFields}
              />
            </LabelInputContainer>

            {editFields ? (
              <>
                {!activity.time_init && (
                  <>
                    <LineSeparator />
                    <LabelInputContainer className="checklist">
                      <Label>
                        Checklist {providenceChecklist.length}/
                        {activity.checklist && activity.checklist.description
                          ? activity.checklist.description.length
                          : 0}
                        :
                      </Label>
                      {activity.checklist.description.map(description => (
                        <CheckboxContainer key={description}>
                          <CheckboxInput
                            type="checkbox"
                            id={description}
                            defaultChecked={
                              !!providenceChecklist.find(
                                check => check.checklist_name === description,
                              )
                            }
                            onChange={() => handleUpdateChecklist(description)}
                          />
                          <label htmlFor="description">{description}</label>
                        </CheckboxContainer>
                      ))}
                    </LabelInputContainer>
                  </>
                )}

                <LineSeparator />

                <LabelInputContainer editFields={editFields}>
                  <Label>Reagendar:</Label>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Data:</Label>
                  <ContainerInput>
                    <ContainerInputDate>
                      <CalendarIcon />
                      <DayPicker
                        value={date}
                        handleChange={day => setDate(day)}
                        isEdit
                      />
                    </ContainerInputDate>
                  </ContainerInput>
                </LabelInputContainer>

                {activity.time_init ? (
                  <LabelInputContainer className="hour-container">
                    <Label>Horário:</Label>

                    <div>
                      <Select
                        id="time_init"
                        name="time_init"
                        defaultValue="Horário inicial"
                        onChange={handleInputChange}
                      >
                        <option value="Horário inicial" disabled>
                          Horário inicial
                        </option>

                        {intervals.intervalInit &&
                          intervals.intervalInit.map(hour => (
                            <option
                              key={String(hour)}
                              value={format(new Date(hour), 'HH:mm')}
                            >
                              {format(new Date(hour), 'HH:mm')}
                            </option>
                          ))}
                      </Select>

                      <span id="intervalText">às</span>

                      <Select
                        id="time_end"
                        name="time_end"
                        defaultValue="Horário final"
                        onChange={handleInputChange}
                      >
                        <option value="Horário final" disabled>
                          Horário final
                        </option>

                        {intervals.intervalEnd &&
                          intervals.intervalEnd.map(hour => (
                            <option
                              key={String(hour)}
                              value={format(new Date(hour), 'HH:mm')}
                            >
                              {format(new Date(hour), 'HH:mm')}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </LabelInputContainer>
                ) : (
                  <>
                    <LabelInputContainer className="hour-container">
                      <Label>Agendar:</Label>
                      <ProvidenceAvailableHours>
                        {intervals.intervalInit &&
                          intervals.intervalInit.map(hour => (
                            <ScheduleOptionsButton
                              key={String(hour)}
                              selectedValue={
                                hourOption === format(new Date(hour), 'HH:mm')
                              }
                              type="button"
                              onClick={() =>
                                setHourOption(
                                  hourOption === format(new Date(hour), 'HH:mm')
                                    ? ''
                                    : format(new Date(hour), 'HH:mm'),
                                )
                              }
                            >
                              {format(new Date(hour), 'HH:mm')}
                            </ScheduleOptionsButton>
                          ))}
                      </ProvidenceAvailableHours>
                    </LabelInputContainer>
                  </>
                )}

                {activity.type !== 'Reunião' && (
                  <>
                    <LineSeparator />

                    <LabelInputContainer status={activity.situation}>
                      <Label>Situação da atividade:</Label>
                      {activitySituations.map(situation => (
                        <ScheduleOptionsButton
                          key={situation}
                          selectedValue={
                            activitySituationSelected === situation
                          }
                          type="button"
                          onClick={() =>
                            setActivitySituationSelected(situation)
                          }
                        >
                          {situation}
                        </ScheduleOptionsButton>
                      ))}
                    </LabelInputContainer>
                  </>
                )}
              </>
            ) : (
              <>
                {activity.type !== 'Reunião' && (
                  <LabelInputContainer status={activity.situation}>
                    <Label>Situação da atividade:</Label>
                    <Span id="status">{activity.situation}</Span>
                  </LabelInputContainer>
                )}
                {isProvidence && (
                  <LabelInputContainer
                    stateChecklist={isEnableChecklist}
                    onClick={() => setIsEnableChecklist(!isEnableChecklist)}
                  >
                    <LabelChecklist>{`Checklist ${providenceChecklist.length}/${activity.checklist.description.length}`}</LabelChecklist>
                    <img src={dropdownChecklistIcon} alt="Dropdown" />
                  </LabelInputContainer>
                )}

                {isEnableChecklist &&
                  isProvidence &&
                  activity.checklist.description.map(description => (
                    <CheckboxContainer key={description}>
                      <CheckboxInput
                        type="checkbox"
                        id={description}
                        defaultChecked={
                          !!providenceChecklist.find(
                            check => check.checklist_name === description,
                          )
                        }
                        disabled
                        style={{ cursor: 'default' }}
                      />
                      <label htmlFor="description">{description}</label>
                    </CheckboxContainer>
                  ))}
              </>
            )}
            {openCreateActivityModal && (
              <ModalCreateActivityFromActivity
                isOpen={openCreateActivityModal}
                onRequestClose={handleCloseCreateActivityModal}
                activity={activity}
                observation={activity.observation}
                activitiesInADay={activitiesInADay}
                setActivitiesInADay={setActivitiesInADay}
                updateActivityClose={handleCloseUpdateActivity}
                activityType={activityTypeToCreate}
              />
            )}

            <ModalConclusionConfirmation
              isOpen={openConclusionConfirmationModal}
              conclusionConfirmation={handleConclusionVariableConfirmation}
              onRequestClose={handleCloseConclusionConfirmationModal}
              type={activityTypeToCreate}
            />
          </Main>
        </>
      )}
    </Modal>
  );
}
