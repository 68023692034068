import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';

import { add, format } from 'date-fns';
import axios from 'axios';
import { Container } from '../../components/Container';
import { NavigationBar } from '../../components/NavigationBar';
import { Body } from '../../components/Styles/Body';

import { Title } from '../../components/Title';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_blue.svg';
import {
  RegionAndProcessContainer,
  RegionSelectTitleContainer,
  RegionContentContainer,
  ProcessFileContainer,
  RegionContainer,
  SelectFileContainer,
  TextContainer,
  SendFileContainer,
  InputFileContainer,
  SendFileButtonContainer,
  FilesHistoryContainer,
  TitleHistory,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
  ContainerInputDate,
  ContainerInput,
  DateContainer,
  TitleContainer,
  Separator,
  Button,
} from './styles';

import apiAssignments from '../../services/apiAssignments';
import { DayPicker } from '../../components/DayPicker';

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface ICount {
  count: number;
  date?: string;
  trf?: string;
}

export const Assignments: React.FC = () => {
  const [loa, setloa] = useState<string>('');
  const [selectHistory, setSelectHistory] = useState<string>('Login');
  const [count, setCount] = useState<ICount[]>([]);
  const [date, setDate] = useState<Date>(new Date());

  const [trf, setTrf] = useState<string>('');

  const [file, setFile] = useState<FileProps>({} as FileProps);

  const handleSelectRegion = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value.includes('LOA')) {
        setloa(value);
      } else {
        setTrf(value);
      }
    },
    [],
  );

  useEffect(() => {
    if (selectHistory === 'Login') {
      const api = apiAssignments(localStorage.getItem('@Precato:token'));

      api
        .get(
          `/db/search/count?date=${format(
            date,
            'yyyy-MM-dd',
          )}&loa=${loa}&trf=${trf}`,
        )
        .then(response => {
          setCount(response.data);
        });
    } else if (selectHistory === 'Estadual') {
      const api = apiAssignments(localStorage.getItem('@Precato:token'));

      api
        .get(
          `/db/search/count-state?date=${format(
            date,
            'yyyy-MM-dd',
          )}&loa=${loa}&tj=TJSP`,
        )
        .then(response => {
          setCount(response.data);
        });
    } else {
      const api = apiAssignments(localStorage.getItem('@Precato:token'));

      api.get(`/db/search/count-public`).then(response => {
        setCount(response.data);
      });
    }
  }, [loa, trf, date, selectHistory]);

  const handleRunPublicAssignment = useCallback(async (currentTrf: string) => {
    const url =
      process.env.REACT_APP_API_URL === 'dev'
        ? ''
        : currentTrf.toLowerCase() === 'trf4'
        ? `https://api-${currentTrf.toLowerCase()}-assignments.precato.com.br/eproc`
        : `https://api-${currentTrf.toLowerCase()}-assignments.precato.com.br/pje`;

    await axios.post(`${url}/periodic-public`);
  }, []);

  const handleSubmitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: FileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };

      setFile(newFile);

      event.target.value = null;
    },
    [],
  );

  const handleDateInputChange = useCallback((day: Date) => {
    setDate(day);
  }, []);

  const handleUploadFile = useCallback(async () => {
    const data = new FormData();

    const api = apiAssignments(localStorage.getItem('@Precato:token'));

    try {
      data.append('file', file.file);

      if (selectHistory === 'Login') {
        await api.post(`/db/file`, data);
      } else if (selectHistory === 'Estadual') {
        await api.post(`/db/state-file`, data);
      } else {
        await api.post(`/db/public-file`, data);
      }

      setFile({} as FileProps);

      Swal.fire(
        'Sucesso!',
        'Processos criados, verifique no banco de dados',
        'success',
      );
    } catch (error) {
      Swal.fire('Erro!', 'Falha ao enviar.', 'error');
    }
  }, [file.file, selectHistory]);

  return (
    <Container>
      <NavigationBar past_screens=" > " active_screen="Cessão" />

      <Body>
        <Title name="Processamento de Cessão" />
        <TitleContainer selectHistory={selectHistory}>
          <button
            type="button"
            className="login-history"
            onClick={() => setSelectHistory('Login')}
          >
            Login
            <Separator />
          </button>
          <button
            type="button"
            className="public-history"
            onClick={() => setSelectHistory('Publica')}
          >
            Publica
            <Separator />
          </button>
          <button
            type="button"
            className="state-history"
            onClick={() => setSelectHistory('Estadual')}
          >
            Estadual
            <Separator />
          </button>
        </TitleContainer>

        {selectHistory === 'Login' ? (
          <RegionAndProcessContainer>
            <RegionContainer>
              <RegionSelectTitleContainer>
                Selecione o TRF e a LOA:
              </RegionSelectTitleContainer>
              <RegionContentContainer>
                <select defaultValue="TRF" onChange={handleSelectRegion}>
                  <option value="TRF">TRF</option>
                  <option value="TRF1">TRF1</option>
                  <option value="TRF2">TRF2</option>
                  <option value="TRF3">TRF3</option>
                  <option value="TRF4">TRF4</option>
                  <option value="TRF5">TRF5</option>
                  <option value="TRF6">TRF6</option>
                </select>
                <select defaultValue="LOA" onChange={handleSelectRegion}>
                  <option value="LOA">LOA</option>
                  <option value="LOA 2024">LOA 2024</option>
                  <option value="LOA 2025">LOA 2025</option>
                  <option value="LOA 2026">LOA 2026</option>
                </select>
              </RegionContentContainer>
            </RegionContainer>

            <ProcessFileContainer>
              <SelectFileContainer>
                <TextContainer>
                  <strong>Arquivo:</strong>
                </TextContainer>
                <SendFileContainer>
                  <InputFileContainer>
                    <label htmlFor="file">Selecione um arquivo</label>
                    <input
                      id="file"
                      type="file"
                      name="file"
                      onChange={handleSubmitFile}
                    />
                    {file && file.name ? (
                      <span>{file.name}</span>
                    ) : (
                      <span>Nenhum arquivo selecionado</span>
                    )}
                  </InputFileContainer>

                  <SendFileButtonContainer>
                    <button type="button" onClick={handleUploadFile}>
                      Enviar
                    </button>
                    <span>Arquivos serão processados no proximo intervalo</span>
                  </SendFileButtonContainer>
                </SendFileContainer>
              </SelectFileContainer>
            </ProcessFileContainer>
          </RegionAndProcessContainer>
        ) : selectHistory === 'Estadual' ? (
          <RegionAndProcessContainer>
            <RegionContainer>
              <RegionSelectTitleContainer>
                Selecione a LOA:
              </RegionSelectTitleContainer>
              <RegionContentContainer>
                <select defaultValue="LOA" onChange={handleSelectRegion}>
                  <option value="LOA">LOA</option>
                  <option value="LOA 2024">LOA 2024</option>
                  <option value="LOA 2025">LOA 2025</option>
                  <option value="LOA 2026">LOA 2026</option>
                </select>
              </RegionContentContainer>
            </RegionContainer>
            <ProcessFileContainer>
              <SelectFileContainer>
                <TextContainer>
                  <strong>Arquivo:</strong>
                </TextContainer>
                <SendFileContainer>
                  <InputFileContainer>
                    <label htmlFor="file">Selecione um arquivo</label>
                    <input
                      id="file"
                      type="file"
                      name="file"
                      onChange={handleSubmitFile}
                    />
                    {file && file.name ? (
                      <span>{file.name}</span>
                    ) : (
                      <span>Nenhum arquivo selecionado</span>
                    )}
                  </InputFileContainer>

                  <SendFileButtonContainer>
                    <button type="button" onClick={handleUploadFile}>
                      Enviar
                    </button>
                    <span>Arquivos serão processados no proximo intervalo</span>
                  </SendFileButtonContainer>
                </SendFileContainer>
              </SelectFileContainer>
            </ProcessFileContainer>
          </RegionAndProcessContainer>
        ) : (
          <RegionAndProcessContainer>
            <ProcessFileContainer>
              <SelectFileContainer>
                <TextContainer>
                  <strong>Arquivo:</strong>
                </TextContainer>
                <SendFileContainer>
                  <InputFileContainer>
                    <label htmlFor="file">Selecione um arquivo</label>
                    <input
                      id="file"
                      type="file"
                      name="file"
                      onChange={handleSubmitFile}
                    />
                    {file && file.name ? (
                      <span>{file.name}</span>
                    ) : (
                      <span>Nenhum arquivo selecionado</span>
                    )}
                  </InputFileContainer>

                  <SendFileButtonContainer>
                    <button type="button" onClick={handleUploadFile}>
                      Enviar
                    </button>
                    <span>Arquivos serão processados no proximo intervalo</span>
                  </SendFileButtonContainer>
                </SendFileContainer>
              </SelectFileContainer>
            </ProcessFileContainer>
          </RegionAndProcessContainer>
        )}

        <FilesHistoryContainer>
          <TitleHistory>Processamento</TitleHistory>
        </FilesHistoryContainer>
        {selectHistory === 'Login' || selectHistory === 'Estadual' ? (
          <DateContainer>
            <ContainerInput>
              <ContainerInputDate>
                <CalendarIcon />
                <DayPicker
                  value={date}
                  handleChange={day => handleDateInputChange(day)}
                />
              </ContainerInputDate>
            </ContainerInput>
          </DateContainer>
        ) : (
          ''
        )}

        {selectHistory === 'Login' ? (
          <Table>
            <Thead>
              <TableRowHead>
                <CellHeader>
                  <TitleTable>Intervalo</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Quantidade</TitleTable>
                  <Divisor />
                </CellHeader>
              </TableRowHead>
            </Thead>
            <Tbody>
              {count.length > 0 &&
                count.map(currentItem => (
                  <>
                    <TableRowBody
                      key={currentItem.count}
                      item-id={currentItem.count}
                    >
                      <CellBody>{currentItem.date}</CellBody>
                      <CellBody>{currentItem.count}</CellBody>
                    </TableRowBody>
                  </>
                ))}
            </Tbody>
          </Table>
        ) : selectHistory === 'Estadual' ? (
          <Table>
            <Thead>
              <TableRowHead>
                <CellHeader>
                  <TitleTable>Intervalo</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Quantidade</TitleTable>
                  <Divisor />
                </CellHeader>
              </TableRowHead>
            </Thead>
            <Tbody>
              {count.length > 0 &&
                count.map(currentItem => (
                  <>
                    <TableRowBody
                      key={currentItem.count}
                      item-id={currentItem.count}
                    >
                      <CellBody>{currentItem.date}</CellBody>
                      <CellBody>{currentItem.count}</CellBody>
                    </TableRowBody>
                  </>
                ))}
            </Tbody>
          </Table>
        ) : (
          <Table>
            <Thead>
              <TableRowHead>
                <CellHeader>
                  <TitleTable>TRF</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Quantidade</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader />
              </TableRowHead>
            </Thead>
            <Tbody>
              {count.length > 0 &&
                count.map(currentItem => (
                  <>
                    <TableRowBody
                      key={currentItem.count}
                      item-id={currentItem.count}
                    >
                      <CellBody>{currentItem.trf}</CellBody>
                      <CellBody>{currentItem.count}</CellBody>
                      <CellBody>
                        <Button
                          type="button"
                          onClick={() =>
                            handleRunPublicAssignment(currentItem.trf)
                          }
                        >
                          Rodar
                        </Button>
                      </CellBody>
                    </TableRowBody>
                  </>
                ))}
            </Tbody>
          </Table>
        )}
      </Body>
    </Container>
  );
};
