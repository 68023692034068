import styled, { css } from 'styled-components';

interface IProps {
  isCloser: boolean;
}

export const ContentPage = styled.div<IProps>`
  display: flex;
  align-items: flex-start;

  ${props =>
    props.isCloser &&
    css`
      margin-top: 4rem;
    `}
`;

export const ContainerLoader = styled.div`
  width: 100vw;
  height: 100vh;

  display: none;
  position: fixed;

  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.25);
`;

export const BoxAccompanimentResult = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-right: 2rem;
`;

export const ContentAccopanimentResult = styled.div`
  display: flex;

  border: solid 2px var(--blue-100);

  border-radius: 0 0 0.5rem 0.5rem;

  margin-bottom: 1rem;

  .accompaniment-item-convertion {
    margin-top: 1rem;
  }

  .last-item-convertion {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
`;

export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.375rem;
  margin-top: 3.1875rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .select {
    width: 20rem;
    height: 2rem;

    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid var(--blue-300);

    &:focus {
      border: 2px solid var(--blue-400);
    }
  }
`;

export const Label = styled.label`
  font-weight: 300;
  font-size: 0.813rem;

  color: var(--blue-700);
`;
