import Swal from 'sweetalert2';
import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';

import { ChangeEvent, useCallback, useState } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

import toast from 'react-hot-toast';
import { gql, useMutation } from '@apollo/client';
import api from '../../../services/api';

import {
  ProposalModal,
  ModalHeader,
  Separator,
  DivSeparator,
  Main,
  LabelInputContainer,
  H1,
  Textarea,
  InputFileContainer,
  LabelFile,
  InputFile,
  TextFile,
  ButtonContainer,
  SolicitationButton,
} from './styles';
import { useAuth } from '../../../hooks/auth';

Modal.setAppElement('#root');

interface IApprovalRequest {
  hubspot_deal_id: string;
  closer_id: string;
  started_value: number;
  expectation_value: number;
  description: string;
  finish_value: number;
  creditor_plans: string;
  interference: string;
  concurrence: string;
}

interface ICreditorPlans {
  debts: string;
  health_problems: string;
  buy_house: string;
  help_family: string;
  open_business: string;
  buy_car: string;
  travel: string;
  resource_security: string;
  investment: string;
  not_shared: string;
}

interface ICreditorInterferences {
  proceeding_lawyer: string;
  known_lawyer: string;
  spouse: string;
  child: string;
  other_interferences: string;
}

interface ICreditorConcurrences {
  matri: string;
  pjus: string;
  ridolf: string;
  ori_assets: string;
  ln_precatorios: string;
  prec_app: string;
  easy_prec: string;
  precatorios_ja: string;
  precpago: string;
  conexcred: string;
  precatorios_brasil: string;
  delpin: string;
  bs2: string;
  valor_precatorios: string;
  primo_capital: string;
  wsul: string;
  ccmg_invest: string;
  hurst: string;
  mv_prec: string;
  advogado: string;
  other: string;
  not_mentioned: string;
  not_have: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface ISelectedItem {
  id: string;
  value: string;
}

interface ModalFormInformationProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalFormInformation({
  isOpen,
  onRequestClose,
}: ModalFormInformationProps): JSX.Element {
  const { user } = useAuth();

  const [file, setFile] = useState<FileProps>({} as FileProps);
  const [formData, setFormData] = useState<IApprovalRequest>(
    {} as IApprovalRequest,
  );
  const [creditorPlans, setCreditorPlans] = useState<ICreditorPlans>(
    {} as ICreditorPlans,
  );
  const [creditorInterferences, setCreditorInterferences] =
    useState<ICreditorInterferences>({} as ICreditorInterferences);
  const [creditorConcurrences, setCreditorConcurrences] =
    useState<ICreditorConcurrences>({} as ICreditorConcurrences);

  const handleSetFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: FileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };
      setFile(newFile);

      event.target.value = null;
    },
    [setFile],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const onSelect = useCallback(
    (selectedList, selectedItem: ISelectedItem, question: string) => {
      if (question === 'plans') {
        setCreditorPlans({
          ...creditorPlans,
          [selectedItem.id]: selectedItem.value,
        });
      } else if (question === 'interferences') {
        setCreditorInterferences({
          ...creditorInterferences,
          [selectedItem.id]: selectedItem.value,
        });
      } else {
        setCreditorConcurrences({
          ...creditorConcurrences,
          [selectedItem.id]: selectedItem.value,
        });
      }
    },
    [creditorConcurrences, creditorInterferences, creditorPlans],
  );

  const onRemove = useCallback(
    (selectedList, removedItem: ISelectedItem, question: string) => {
      if (question === 'plans') {
        const items = creditorPlans;

        delete items[removedItem.id];

        setCreditorPlans(items);
      } else if (question === 'interferences') {
        const items = creditorInterferences;

        delete items[removedItem.id];

        setCreditorInterferences(items);
      } else {
        const items = creditorConcurrences;

        delete items[removedItem.id];

        setCreditorConcurrences(items);
      }
    },
    [creditorConcurrences, creditorInterferences, creditorPlans],
  );

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (
    value,
    _,
    values,
  ): void => {
    setFormData({ ...formData, [_]: values.float });
  };

  const SEND_NOTIFICATION = gql`
    mutation SendProposalFormNotification(
      $sendProposalFormNotificationId: String!
    ) {
      sendProposalFormNotification(id: $sendProposalFormNotificationId)
    }
  `;

  const [createProposal] = useMutation(SEND_NOTIFICATION, {
    variables: {
      sendProposalFormNotificationId: 'f482d9cf-d9f6-4ddd-a297-ae11845ba97a',
    },
  });

  const handleSubmit = useCallback(async () => {
    try {
      const formattedCreditorPlans = JSON.stringify(creditorPlans);
      const formattedCreditorInterferences = JSON.stringify(
        creditorInterferences,
      );
      const formattedCreditorConcurrences =
        JSON.stringify(creditorConcurrences);

      formData.creditor_plans = formattedCreditorPlans;
      formData.interference = formattedCreditorInterferences;
      formData.concurrence = formattedCreditorConcurrences;

      if (Object.keys(formData).length < 8) {
        Swal.fire('Atenção!', 'Preencha todos os campos.', 'warning');
      } else {
        const dataFile = new FormData();
        dataFile.append('file', file.file);

        await api.post(
          `/negociations/calculations/proposal-form?hubspot_deal_id=${formData.hubspot_deal_id}&closer_id=${user.id}&started_value=${formData.started_value}&expectation_value=${formData.expectation_value}&description=${formData.description}&creditor_plans=${formData.creditor_plans}&interference=${formData.interference}&finish_value=${formData.finish_value}&concurrence=${formData.concurrence}`,
          dataFile,
        );

        await createProposal();

        toast.success('Proposta solicitada com sucesso.');
        onRequestClose();
      }
    } catch (error) {
      Swal.fire('Erro!', 'Não foi possível solicitar a proposta.', 'error');
    }
  }, [
    createProposal,
    creditorConcurrences,
    creditorInterferences,
    creditorPlans,
    file,
    formData,
    user,
    onRequestClose,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ProposalModal}
    >
      <ModalHeader>Solicitação de Aprovação</ModalHeader>
      <DivSeparator>
        <Separator />
      </DivSeparator>

      <Main>
        <LabelInputContainer>
          <label>ID do Hubspot</label>
          <input
            type="text"
            name="hubspot_deal_id"
            onChange={handleInputChange}
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <label>Valores iniciados?</label>
          <CurrencyInput
            name="started_value"
            allowDecimals
            onValueChange={handleOnValueChange}
            placeholder="Digite apenas números"
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <label>Valores de expectativa?</label>
          <CurrencyInput
            name="expectation_value"
            allowDecimals
            onValueChange={handleOnValueChange}
            placeholder="Digite apenas números"
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <label>Valores para finalizar?</label>
          <CurrencyInput
            name="finish_value"
            allowDecimals
            onValueChange={handleOnValueChange}
            placeholder="Digite apenas números"
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <label className="question-label">
            Quais os planos do credor com o recurso?
          </label>
          <Multiselect
            displayValue="value"
            options={[
              {
                value: 'Dívida',
                id: 'debts',
              },
              {
                value: 'Problemas de saúde',
                id: 'health_problems',
              },
              {
                value: 'Comprar casa',
                id: 'buy_house',
              },
              {
                value: 'Ajudar familiares',
                id: 'help_family',
              },
              {
                value: 'Abrir um negócio',
                id: 'open_business',
              },
              {
                value: 'Comprar carro',
                id: 'buy_car',
              },
              {
                value: 'Viagem',
                id: 'travel',
              },
              {
                value: 'Segurança em ter o recurso',
                id: 'resource_security',
              },
              {
                value: 'Investimento',
                id: 'investment',
              },
              {
                value: 'Não compartilhou',
                id: 'not_shared',
              },
            ]}
            onSelect={(selectedList, selectedItem) =>
              onSelect(selectedList, selectedItem, 'plans')
            }
            onRemove={(selectedList, removedItem) =>
              onRemove(selectedList, removedItem, 'plans')
            }
            showArrow
            placeholder="Selecione a(s) opções"
            showCheckbox
            style={{
              multiselectContainer: {
                fontSize: '0.875rem',
              },
              inputField: {
                width: '16rem',
              },
              searchBox: {
                maxWidth: '30rem',
              },
              optionContainer: {
                width: '25rem',
              },
            }}
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <label className="question-label">Possui interferências?</label>
          <Multiselect
            displayValue="value"
            options={[
              {
                value: 'Advogado do processo',
                id: 'proceeding_lawyer',
              },
              {
                value: 'Advogado conhecido',
                id: 'known_lawyer',
              },
              {
                value: 'Cônjugue',
                id: 'spouse',
              },
              {
                value: 'Filho(a)',
                id: 'child',
              },
              {
                value: 'Outro',
                id: 'other_interferences',
              },
            ]}
            onSelect={(selectedList, selectedItem) =>
              onSelect(selectedList, selectedItem, 'interferences')
            }
            onRemove={(selectedList, removedItem) =>
              onRemove(selectedList, removedItem, 'interferences')
            }
            showArrow
            placeholder="Selecione a(s) opções"
            showCheckbox
            style={{
              multiselectContainer: {
                fontSize: '0.875rem',
              },
              inputField: {
                width: '25rem',
              },
              searchBox: {
                maxWidth: '30rem',
              },
              optionContainer: {
                width: '20rem',
              },
            }}
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <label className="question-label">
            Qual a concorrência envolvida?
          </label>
          <Multiselect
            displayValue="value"
            options={[
              {
                value: 'Matri',
                id: 'matri',
              },
              {
                value: 'Pjus',
                id: 'pjus',
              },
              {
                value: 'Ridolf',
                id: 'ridolf',
              },
              {
                value: 'Ori Assets',
                id: 'ori_assets',
              },
              {
                value: 'Ln Precatórios',
                id: 'ln_precatorios',
              },
              {
                value: 'Prec app',
                id: 'prec_app',
              },
              {
                value: 'Easy prec',
                id: 'easy_prec',
              },
              {
                value: 'Precatórios já',
                id: 'precatorios_ja',
              },
              {
                value: 'Precpago',
                id: 'precpago',
              },
              {
                value: 'Conexcred',
                id: 'conexcred',
              },
              {
                value: 'Precatórios Brasil',
                id: 'precatorios_brasil',
              },
              {
                value: 'Delpin',
                id: 'delpin',
              },
              {
                value: 'Bs2',
                id: 'bs2',
              },
              {
                value: 'Valor precatórios',
                id: 'valor_precatorios',
              },
              {
                value: 'Primo capital',
                id: 'primo_capital',
              },
              {
                value: 'Wsul',
                id: 'wsul',
              },
              {
                value: 'Ccmg invest',
                id: 'ccmg_invest',
              },
              {
                value: 'Hurst',
                id: 'hurst',
              },
              {
                value: 'Mv prec',
                id: 'mv_prec',
              },
              {
                value: 'Advogado',
                id: 'advogado',
              },
              {
                value: 'Outro',
                id: 'other',
              },
              {
                value: 'Não mencionado',
                id: 'not_mentioned',
              },
              {
                value: 'Não possui',
                id: 'not_have',
              },
            ]}
            onSelect={(selectedList, selectedItem) =>
              onSelect(selectedList, selectedItem, 'concurrence')
            }
            onRemove={(selectedList, removedItem) =>
              onRemove(selectedList, removedItem, 'concurrence')
            }
            showArrow
            placeholder="Selecione a(s) opções"
            showCheckbox
            style={{
              multiselectContainer: {
                fontSize: '0.875rem',
              },
              inputField: {
                width: '20rem',
              },
              searchBox: {
                maxWidth: '30rem',
              },
              optionContainer: {
                width: '25rem',
              },
            }}
          />
        </LabelInputContainer>

        <H1>Descrição da negociação</H1>
        <Textarea
          name="description"
          placeholder="Digite aqui a descrição da negociação"
          onChange={handleInputChange}
        />

        <H1>Proposta formalizada da concorrência</H1>

        <InputFileContainer>
          <LabelFile htmlFor="file">Selecione um arquivo</LabelFile>
          <InputFile
            onChange={event => handleSetFile(event)}
            id="file"
            type="file"
            name="file"
          />
          {file && file.name ? (
            <TextFile>{file.name}</TextFile>
          ) : (
            <TextFile>Nenhum arquivo selecionado</TextFile>
          )}
        </InputFileContainer>

        <ButtonContainer>
          <SolicitationButton type="button" onClick={handleSubmit}>
            Solicitar
          </SolicitationButton>
        </ButtonContainer>
      </Main>
    </Modal>
  );
}
