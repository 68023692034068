import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ICredorDisplayProps {
  displayConnection?: boolean;
  displayCredorInfos?: boolean;
}

interface IMainDisplayProps {
  displayDatetime: boolean;
  displayHoursAvailable?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  font-family: Poppins;
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 20rem;
  margin-bottom: 2.438rem;
  @media print {
    display: none;
  }
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const TitleBackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 5%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;

    color: #1665d8;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Main = styled.div<IMainDisplayProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  max-width: 90rem;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p.title {
    background: rgba(207, 222, 243, 0.83);
    border-radius: 4px;

    width: 22rem;
    height: 2.375rem;

    font-weight: 600;
    color: #05233e;
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 0.625rem;
  }

  .schedule_info {
    ${props =>
      props.displayDatetime
        ? css`
            visibility: visible;
          `
        : css`
            visibility: hidden;
          `}
  }

  .datetime {
    ${props =>
      props.displayHoursAvailable
        ? css`
            visibility: visible;
          `
        : css`
            visibility: hidden;
          `}
  }
`;

export const CredorInfos = styled.div<ICredorDisplayProps>`
  display: flex;
  align-items: center;
  flex-direction: column;

  p.infoTagP {
    background: #efefef;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    width: 19.813rem;
    height: 1.875rem;

    font-weight: 600;
    color: #05233e;
    font-size: 0.875rem;
    text-align: center;

    margin-bottom: 0.438rem;
  }

  input {
    width: 19.813rem;
    height: 2.5rem;
    border-radius: 0.25rem;

    background: #efefef;
    border: 0.5px solid #063966;

    padding-left: 0.25rem;

    font-family: Poppins;
    font-size: 0.75rem;
    color: #3d3d3d;

    border: none;
  }

  input#ticket_id {
    border: 0.5px solid #063966;
  }

  .connection {
    ${props =>
      props.displayConnection
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
  }

  /* .credorInfos {
    ${props =>
    props.displayCredorInfos
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
  } */
`;

export const InfoLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
`;

export const ScheduleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1.875rem;

  width: 19.5rem;

  display: flex;
  flex-direction: column;

  background: #ffffff;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 1.875rem;

    background: #efefef;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    margin-bottom: 0.875rem;

    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    color: #05233e;
  }
`;

export const ButtonsWeekdayContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 8.625rem);
  gap: 0.625rem;
`;

export const HoursContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PeriodContainer = styled.div`
  p {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #efefef;
    width: 17.938rem;
    height: 1.875rem;

    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: #05233e;

    margin-bottom: 0.875rem;
  }

  p.recommendedHour {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #d8d8d8;
    width: 14.0625rem;
    height: 1.625rem;

    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: #05233e;

    /* margin-bottom: 0.875rem; */
  }
  margin-bottom: 1.25rem;
`;

export const Hour = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 4rem);
  grid-gap: 0.688rem;

  .btnHour {
    border: none;
    border-radius: 0.25rem;

    width: 4rem;
    height: 1.875rem;

    background: #efefef;

    font-family: Poppins;
    font-weight: 500;
    font-size: 1rem;
    color: #3d3d3d;

    cursor: pointer;
    transition: 0.5s;

    text-align: center;

    &:hover,
    &:focus {
      background-color: #1665d8;
      color: #fff;
    }
  }
`;

export const ButtonsContainerSchedule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 930px) {
    justify-content: center;
    border: none;
  }
`;

export const ButtonSchedule = styled.button`
  width: 9.688rem;
  height: 2rem;
  border: none;

  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9rem;

  background: #063966;
  border-radius: 0.5rem;

  color: #ffffff;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.3, '#063966')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;
