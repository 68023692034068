export function sumAmounts(allValues: string[]): number {
  let sumAmount = 0;
  let numberFormated = 0;

  for (const value of allValues) {
    const [, splitNumber] = value.split('R$');

    numberFormated = Number(splitNumber.replace(/\./g, '').replace(',', '.'));

    sumAmount += numberFormated;
  }

  return sumAmount;
}
