import React, { useCallback } from 'react';
import Modal from 'react-modal';
import api from '../../services/api';
import {
  Header,
  Content,
  Title,
  Notification,
  Separator,
  StyleModal,
  NotificationBody,
  NotificationFooter,
  Circle,
} from './styles';

interface IScheduleNotification {
  id: string;
  hunter_name?: string;
  time_ago: string;
  read: boolean;
  user_id: string;
  activity_name?: string;
  type?: string;
  activity_id?: string;
}

interface INotificationProps {
  isOpen: boolean;
  onRequestClose: () => void;
  userNotifications: IScheduleNotification[];
  handleUserNotifications: () => void;
  setUserNotifications: (notifications: IScheduleNotification[]) => void;
}

const NotificationModal: React.FC<INotificationProps> = ({
  isOpen,
  onRequestClose,
  userNotifications,
  handleUserNotifications,
  setUserNotifications,
}) => {
  const unreadNotifications = userNotifications.filter(
    notification => !notification.read,
  );

  const readNotifications = userNotifications.filter(
    notification => notification.read,
  );

  const closeModal = useCallback(() => {
    handleUserNotifications();
    onRequestClose();
  }, [onRequestClose, handleUserNotifications]);

  const handleClearNotifications = useCallback(async () => {
    if (userNotifications.length > 0) {
      setUserNotifications([] as IScheduleNotification[]);
      const body = {
        notifications: userNotifications,
      };

      await api.patch('schedule/notifications/clear', body);
    }
  }, [setUserNotifications, userNotifications]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={StyleModal}>
      <Header>
        <strong>Notificação</strong>
        <button type="button" onClick={handleClearNotifications}>
          Limpar tudo
        </button>
      </Header>

      <Content>
        {unreadNotifications.length > 0 ? (
          <Title style={{ color: '#1665d8' }}>Novas</Title>
        ) : (
          ''
        )}

        {unreadNotifications.map(notification => {
          return notification.type === 'Scheduling' ? (
            <Notification key={notification.id}>
              <NotificationBody>
                <Circle>A</Circle>
                <p>
                  Você foi <strong>reservado</strong> para um novo agendamento
                  pelo hunter {notification.hunter_name}
                </p>
              </NotificationBody>

              <NotificationFooter>{notification.time_ago}</NotificationFooter>
            </Notification>
          ) : (
            <Notification key={notification.id}>
              <NotificationBody>
                <Circle>A</Circle>
                <p>
                  <strong>Faltam 5 minutos</strong> para sua próxima atividade.
                </p>
              </NotificationBody>

              <NotificationFooter>{notification.time_ago}</NotificationFooter>
            </Notification>
          );
        })}

        {unreadNotifications.length > 0 && readNotifications.length > 0 ? (
          <Separator />
        ) : (
          ''
        )}

        {readNotifications.length > 0 ? (
          <Title style={{ color: '#595959' }}>Lidas</Title>
        ) : (
          ''
        )}

        {readNotifications.map(notification => {
          return notification.type === 'Scheduling' ? (
            <Notification key={notification.id}>
              <NotificationBody>
                <Circle>A</Circle>
                <p>
                  Você foi <strong>reservado</strong> para um novo agendamento
                  pelo hunter {notification.hunter_name}
                </p>
              </NotificationBody>

              <NotificationFooter>{notification.time_ago}</NotificationFooter>
            </Notification>
          ) : (
            <Notification key={notification.id}>
              <NotificationBody>
                <Circle>A</Circle>
                <p>
                  <strong>Faltam 5 minutos</strong> para sua próxima atividade.
                </p>
              </NotificationBody>

              <NotificationFooter>{notification.time_ago}</NotificationFooter>
            </Notification>
          );
        })}
      </Content>
    </Modal>
  );
};

export { NotificationModal };
