import styled, { css } from 'styled-components';

interface IContainerProps {
  isNotMargin: boolean;
}

export const BackButtonContainer = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  width: 20%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 0.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.3rem;

    color: var(--blue-400);
  }

  ${props =>
    props.isNotMargin &&
    css`
      margin-top: 0;
      margin-bottom: 0;
    `}
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  svg {
    width: 1.25rem;
  }
`;
