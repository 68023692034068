import styled from 'styled-components';
import checked_icon from '../../../../assets/icons/checked.svg';

export const TableRow = styled.tr`
  width: 52.75rem;
  height: 3.5rem;
  margin-top: 0.3125rem;
  border-radius: 0.25rem;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface ITableCellProps {
  width: string;
  'font-weight': number;
  'font-size': string;
  'line-height': string;
}

export const TableCell = styled.td<ITableCellProps>`
  width: ${props => props.width};

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => props['font-weight']};
  font-size: ${props => props['font-size']};
  line-height: ${props => props['line-height']};
  text-align: center;
  letter-spacing: -0.015em;
  color: #041524;
`;

interface ITimeInputProps {
  valid: boolean;
}

export const TimeInput = styled.input<ITimeInputProps>`
  width: 6.0625rem;
  height: 2.25rem;
  border: 0.0625rem solid
    ${props =>
      props.disabled ? '#6D6D6D' : props.valid ? '#51A7FC' : '#DE0C0C'};
  border-radius: 0.3125rem;
  background-color: ${props => (props.disabled ? '#E5E5E5' : '#ffffff')};

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: ${props => (props.disabled ? '#595959' : '#041524')};
`;

interface IHolidayLabel {
  checked: boolean;
}

export const HolidayLabel = styled.label<IHolidayLabel>`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.0625rem solid #51a7fc;
  border-radius: 0.3125rem;
  background: #ffffff;
  background-image: ${props =>
    props.checked ? `url(${checked_icon})` : 'none'};
  background-position: center;
  background-repeat: no-repeat;
`;

export const HolidayCheckbox = styled.input`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.3125rem;

  display: none;
`;
