import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const IconForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.p`
  font-family: Poppins;
  font-weight: 700;
  font-size: 2.25rem;
  color: #3d3d3d;
  margin-left: 0.2rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 26rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  background-color: #d7e4f5;
  border-radius: 0.625rem 0.625rem 0px 0px;
`;

export const BoxBody = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 26rem;

  border: 0.188rem solid #d7e4f5;
  border-radius: 0px 0px 0.625rem 0.625rem;

  box-shadow: 2px 2px 4px -2px #d7e4f5;
`;

export const BoxRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const BoxItem = styled.a`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.125rem;
  color: #3d3d3d;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: blue;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 8.875rem;
  min-width: 78vw;

  background: #f9f8f8;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid #041524;
  border-radius: 0.5rem;

  margin-bottom: 1.75rem;

  .columnImpact {
    margin-bottom: 3rem;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 35%;
  height: 100%;
`;

export const TitleColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const LineSeparator = styled.div`
  border-right: 1px solid #9d9d9d;
  height: 8.438rem;
`;
