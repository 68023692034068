import { differenceInDays, format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { formatAmount } from '../../../../utils/formatAmount';

import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';
import { IActivityCSDTO } from '../dtos/IActivityCSDTO';
import { ModalUpdateActivity } from '../ModalUpdateActivity';

import {
  Content,
  TitleSpan,
  ProvidencesContainer,
  SearchByNameContainer,
  InputSearch,
  Providence,
  NameStageContainer,
  ProvidenceName,
  StageChecklist,
  Stage,
  Checklist,
  CredorContainer,
} from './styles';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

export interface ProvidencesComponentProps {
  providences: IActivityCSDTO[];
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (activity: IActivitiesInADayDTO) => void;
}

export function ProvidencesComponent({
  providences,
  activitiesInADay,
  setActivitiesInADay,
}: ProvidencesComponentProps): JSX.Element {
  const { user } = useAuth();

  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<IActivityCSDTO>(
    {} as IActivityCSDTO,
  );

  const [currentProvidences, setCurrentProvidences] = useState<
    IActivityCSDTO[]
  >([] as IActivityCSDTO[]);

  useEffect(() => {
    setCurrentProvidences(providences);
  }, [providences]);

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback((activity: IActivityCSDTO) => {
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  }, []);

  const searchProvidence = useCallback(
    (value: string) => {
      const filterProvidences = providences.filter(
        activity =>
          activity.dealname.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setCurrentProvidences(filterProvidences);
    },
    [providences],
  );

  const handleUpdateListAcitivity = useCallback(async () => {
    api
      .get(
        `/schedule-cs?start_date=${format(
          new Date(),
          'yyyy-MM-dd',
        )}&end_date&user_id=${user.id}`,
      )
      .then(response => {
        setActivitiesInADay(response.data);
      });
  }, [setActivitiesInADay, user.id]);

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === ''
        ? setCurrentProvidences(providences)
        : searchProvidence(value);
    },
    [providences, searchProvidence],
  );

  return (
    <Content>
      <TitleSpan>Providências</TitleSpan>

      <ProvidencesContainer>
        <SearchByNameContainer>
          <InputSearch
            type="text"
            placeholder="Digite o nome do credor"
            onChange={handleSearchInputChange}
          />
          <AiOutlineSearch />
        </SearchByNameContainer>

        {currentProvidences &&
          currentProvidences.map(providence => (
            <Providence
              key={providence.id}
              onClick={() => handleOpenActivityModal(providence)}
              isBefore={
                providence.manager_id
                  ? false
                  : differenceInDays(
                      new Date(),
                      new Date(providence.created_at),
                    ) > 0
              }
              statusTask={providence.situation}
              checklist_concluded={
                providence.manager_id
                  ? false
                  : providence.checklist_concluded &&
                    Number(providence.checklist_concluded) > 0
              }
            >
              <NameStageContainer>
                <ProvidenceName>
                  {providence.manager_id ? 'Lembrete' : providence.name}
                </ProvidenceName>

                <StageChecklist>
                  <Stage>{providence.stage_deal}</Stage>
                  <Checklist>
                    {providence.manager_id
                      ? ''
                      : providence.checklist_concluded ||
                        (`${0} / ${providence.checklist}` &&
                          providence.checklist.mean_time)}
                  </Checklist>
                </StageChecklist>
              </NameStageContainer>

              <CredorContainer>
                <span>Credor: {providence.dealname}</span>
                <span>
                  Valor: {formatAmount(Number(providence.amount))} | Contato:
                  {providence.valid_contact}
                </span>
              </CredorContainer>
            </Providence>
          ))}
      </ProvidencesContainer>

      {Object.keys(selectedActivity).length > 0 && (
        <ModalUpdateActivity
          isOpen={openActivityModal}
          isProvidence={!selectedActivity.manager_id}
          onRequestClose={handleCloseActivityModal}
          activity={selectedActivity}
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
          updateListAcitivity={handleUpdateListAcitivity}
        />
      )}
    </Content>
  );
}
