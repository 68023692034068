import { ChangeEvent, useCallback } from 'react';

import { ITeamDTO } from '../../dtos/ITeamDTO';
import { IProfileDTO } from '../../dtos/IProfileDTO';
import { IUserFormDataDTO } from '../../dtos/IUserFormDataDTO';

import {
  FormContainer,
  FormGroup,
  TitleContainer,
  TitleHeader,
  Label,
  InputContainer,
  Input,
  BorderFormGroup,
} from './styles';
import {
  InputLabelContainer,
  RowInputContainer,
  TimeInput,
} from '../../../CloserSettings/CloserFormGroup/styles';
import { IWorkHourDTO } from '../../dtos/IWorkHourDTO';

interface CreateUserFormProps {
  profiles: IProfileDTO[];
  teams: ITeamDTO[];
  formData: IUserFormDataDTO;
  setFormData: (formdata: IUserFormDataDTO) => void;
  setWorkHourArray: (workHourArray: IWorkHourDTO) => void;
  workHourArray: IWorkHourDTO;
  closerProfileId: string;
  hunterProfileId: string;
}

export function CreateUserForm({
  profiles,
  teams,
  formData,
  setFormData,
  setWorkHourArray,
  workHourArray,
  closerProfileId,
  hunterProfileId,
}: CreateUserFormProps): JSX.Element {
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (formData.profile_id === closerProfileId) {
        setWorkHourArray({ ...workHourArray, [name]: value });
      }

      setFormData({ ...formData, [name]: value });
    },
    [closerProfileId, formData, setFormData, setWorkHourArray, workHourArray],
  );

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData, setFormData],
  );

  return (
    <>
      <TitleContainer>
        <TitleHeader>Dados pessoais</TitleHeader>
      </TitleContainer>

      <FormContainer>
        <BorderFormGroup />
        <FormGroup>
          <InputContainer>
            <Label>Nome: *</Label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={handleInputChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Email: *</Label>
            <Input
              id="email"
              name="email"
              type="text"
              placeholder="email@precato.com.br"
              onChange={handleInputChange}
            />
          </InputContainer>

          <InputContainer>
            <Label>Senha: *</Label>
            <Input
              id="password"
              name="password"
              type="password"
              onChange={handleInputChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Digite a senha novamente: *</Label>
            <Input
              id="password_confirm"
              name="password_confirm"
              type="password"
              onChange={handleInputChange}
            />
          </InputContainer>
        </FormGroup>
      </FormContainer>

      <TitleContainer>
        <TitleHeader>Dados de acesso</TitleHeader>
      </TitleContainer>

      <FormContainer>
        <BorderFormGroup />
        <FormGroup>
          <InputContainer>
            <Label>Perfil do usuário: *</Label>
            <select
              className="select"
              name="profile_id"
              onChange={handleSelectChange}
            >
              <option>Selecione uma opção</option>
              {profiles.length > 0 &&
                profiles.map(profile => (
                  <option key={profile.id} id="team" value={profile.id}>
                    {profile.name}
                  </option>
                ))}
            </select>
          </InputContainer>

          {formData.profile_id === hunterProfileId && (
            <InputContainer>
              <Label>Time do hunter:</Label>
              <select
                className="select"
                name="team_id"
                onChange={handleSelectChange}
              >
                <option>Selecione uma opção</option>
                {teams.length > 0 &&
                  teams.map(team => (
                    <option key={team.id} id="team" value={team.id}>
                      {team.name}
                    </option>
                  ))}
              </select>
            </InputContainer>
          )}

          {formData.profile_id === closerProfileId && (
            <>
              <InputContainer>
                <Label>Ramal: </Label>
                <Input
                  min={0}
                  id="ramal"
                  name="ramal"
                  type="number"
                  placeholder="9999"
                  onChange={handleInputChange}
                />
              </InputContainer>

              <InputLabelContainer>
                <Label>Segunda-feira:</Label>
                <RowInputContainer>
                  <TimeInput
                    id="work_hour_init_1"
                    name="work_hour_init_1"
                    type="time"
                    onChange={handleInputChange}
                  />
                  <TimeInput
                    id="work_hour_end_1"
                    name="work_hour_end_1"
                    type="time"
                    onChange={handleInputChange}
                  />
                </RowInputContainer>
              </InputLabelContainer>

              <InputLabelContainer>
                <Label>Terça-feira:</Label>
                <RowInputContainer>
                  <TimeInput
                    id="work_hour_init_2"
                    name="work_hour_init_2"
                    type="time"
                    onChange={handleInputChange}
                  />
                  <TimeInput
                    id="work_hour_end_2"
                    name="work_hour_end_2"
                    type="time"
                    onChange={handleInputChange}
                  />
                </RowInputContainer>
              </InputLabelContainer>

              <InputLabelContainer>
                <Label>Quarta-feira:</Label>
                <RowInputContainer>
                  <TimeInput
                    id="work_hour_init_3"
                    name="work_hour_init_3"
                    type="time"
                    onChange={handleInputChange}
                  />
                  <TimeInput
                    id="work_hour_end_3"
                    name="work_hour_end_3"
                    type="time"
                    onChange={handleInputChange}
                  />
                </RowInputContainer>
              </InputLabelContainer>

              <InputLabelContainer>
                <Label>Quinta-feira:</Label>
                <RowInputContainer>
                  <TimeInput
                    id="work_hour_init_4"
                    name="work_hour_init_4"
                    type="time"
                    onChange={handleInputChange}
                  />
                  <TimeInput
                    id="work_hour_end_4"
                    name="work_hour_end_4"
                    type="time"
                    onChange={handleInputChange}
                  />
                </RowInputContainer>
              </InputLabelContainer>

              <InputLabelContainer>
                <Label>Sexta-feira:</Label>
                <RowInputContainer>
                  <TimeInput
                    id="work_hour_init_5"
                    name="work_hour_init_5"
                    type="time"
                    onChange={handleInputChange}
                  />
                  <TimeInput
                    id="work_hour_end_5"
                    name="work_hour_end_5"
                    type="time"
                    onChange={handleInputChange}
                  />
                </RowInputContainer>
              </InputLabelContainer>
            </>
          )}
        </FormGroup>
      </FormContainer>
    </>
  );
}
