import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../utils/pxToRem';

interface CadenceProps {
  priority: string;
}

export const TableContainer = styled.table`
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  width: 150%;
  height: 32rem;

  overflow: auto;

  font-family: Poppins;
`;

export const HeadContainer = styled.thead`
  width: 100%;
  height: 2.375rem;

  border-bottom: 1px solid rgba(194, 194, 194, 0.5);
  background: rgba(22, 101, 216, 0.05);
  border-radius: 0.125rem;
`;

export const RowContainerProps = styled.div<CadenceProps>`
  width: 100%;
  margin-bottom: 0.25rem;
  border-radius: 4px;

  .body {
    border-radius: 4px;
    height: 100%;

    background: rgba(1, 64, 119, 0.1);
    box-shadow: -2px 0 0 #014077;

    :hover {
      background: rgba(1, 64, 119, 0.2);
    }
  }

  ${props =>
    props.priority === 'Baixa'
      ? css`
          .body {
            background: rgba(60, 166, 71, 0.25);
            border-left: 2px solid rgba(60, 166, 71, 1);
            box-shadow: -2px 0 0 #3ca647;

            :hover {
              background: rgba(60, 166, 71, 0.4);
            }

            select {
              background: rgba(60, 166, 71, 0.4);
              border-radius: 4px;
              border: none;
              width: 130px;
              height: 26px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: -0.015em;

              color: #041524;
            }
          }
        `
      : props.priority === 'Média'
      ? css`
          .body {
            background: rgba(180, 173, 9, 0.15);
            border-left: 2px solid rgba(180, 173, 9, 1);
            box-shadow: -2px 0 0 #de0c0c;

            :hover {
              background: rgba(180, 173, 9, 0.4);
            }

            select {
              background: rgba(180, 173, 9, 0.4);
              border-radius: 4px;
              border: none;
              width: 130px;
              height: 26px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: -0.015em;

              color: #041524;
            }
          }
        `
      : props.priority === 'Alta'
      ? css`
          .body {
            background: rgba(234, 63, 63, 0.15);
            border-left: 2px solid rgba(234, 63, 63, 1);
            box-shadow: -2px 0 0 #de0c0c;

            :hover {
              background: rgba(234, 63, 63, 0.4);
            }

            select {
              background: rgba(234, 63, 63, 0.4);
              border-radius: 4px;
              border: none;
              width: 130px;
              height: 26px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: -0.015em;

              color: #041524;
            }
          }
        `
      : css`
          .body {
            background: rgba(1, 64, 119, 0.15);
            border-left: 2px solid rgba(1, 64, 119, 1);
            box-shadow: -2px 0 0 #de0c0c;

            :hover {
              background: rgba(1, 64, 119, 0.4);
            }

            select {
              background: rgba(1, 64, 119, 0.4);
              border-radius: 4px;
              border: none;
              width: 130px;
              height: 26px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: -0.015em;

              color: #041524;
            }
          }
        `}
`;

export const RowContainer = styled.tr`
  display: grid;
  align-items: center;
  grid-template-columns: 18% 12% 30% 15% 22%;
  border-radius: 4px;

  width: 100%;
  height: 150%;
  cursor: pointer;

  th:nth-child(1) {
    height: 100%;
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(2) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(3) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(4) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(5) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }
`;

export const ColumnHeadContainer = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 0.813rem;
  color: #041524;

  height: 1.875rem;
`;

export const BodyContainer = styled.tbody`
  width: 100%;
  height: 2.375rem;

  border-radius: 0.125rem;

  .row-props-body {
    height: 3.063rem;
  }

  .header {
    height: 2.375rem;
  }
`;

export const ColumnBodyContainer = styled.td`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: center;
  color: #05233e;
  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #595959;
  }

  p.cadence-priority {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.015em;
    text-align: center;
    color: #05233e;

    width: 100%;
    border-radius: 4px;
  }
`;

export const Dropdown = styled.button`
  width: ${pxToRem(155)};
  height: ${pxToRem(37)};
  color: var(--white);
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 600;
  font-size: ${pxToRem(13)};

  background: var(--blue-600);
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.3, '#063966')};
  }
`;

export const UpdateCadenceOptions = styled.div`
  width: ${pxToRem(155)};
  height: fit-content;
  margin-top: ${pxToRem(37)};

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  position: absolute;

  background: var(--white);
  border: 1.5px solid var(--blue-800);
  border-radius: 0.5rem;
  padding: 0;

  #first-option {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  #last-option {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const UpdateCadence = styled.button`
  width: ${pxToRem(155)};
  height: ${pxToRem(25)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  border: none;
  background: none;

  font-weight: 600;
  font-size: ${pxToRem(13)};
  color: var(--blue-800);

  transition: 0.2s;

  :hover {
    background: var(--blue-800);
    color: var(--white);
  }
`;
