import Loader from 'react-loader-spinner';

import {
  BodyCell,
  HeadCell,
  Table,
  TableContainer,
  TableRow,
  Tbody,
  Thead,
  StickInfoContainer,
  StickInfoItem,
} from './styles';
import { ICloserPanelData } from '..';
import { ContainerLoader } from '../styles';
import { formatAmount } from '../../../utils/formatAmount';
import { sumAmounts } from '../../../utils/sumAmounts';

interface StickTableProps {
  closerPanelData: ICloserPanelData[];
}

export function StickTable({ closerPanelData }: StickTableProps): JSX.Element {
  const amounts: string[] = [];

  for (const itemCloserPanelData of closerPanelData) {
    amounts.push(itemCloserPanelData.amount);
  }

  return (
    <>
      <ContainerLoader id="loader">
        <Loader type="Oval" color="#05233e" height={50} width={50} />
      </ContainerLoader>

      <TableContainer>
        <Table>
          <Thead>
            <TableRow className="head-row">
              <HeadCell className="stick-cell">BASTÕES MÊS</HeadCell>
              <HeadCell className="value-cell">VALOR</HeadCell>
              <HeadCell className="percentage-cell">PERCENTUAL</HeadCell>
              <HeadCell className="status-cell">STATUS</HeadCell>
            </TableRow>
          </Thead>
          <Tbody>
            {closerPanelData &&
              closerPanelData.map(closerData => (
                <TableRow key={closerData.id} className="body-row">
                  <BodyCell className="stick-cell">
                    {closerData.hubspotDealId}
                  </BodyCell>
                  <BodyCell className="value-cell">
                    {closerData.amount}
                  </BodyCell>
                  <BodyCell className="percentage-cell">{`${closerData.percentage}%`}</BodyCell>
                  <BodyCell className="status-cell">
                    {closerData.status}
                  </BodyCell>
                </TableRow>
              ))}
            {closerPanelData.length === 0 && (
              <TableRow className="body-row">
                <BodyCell className="stick-cell">-</BodyCell>
                <BodyCell className="value-cell">-</BodyCell>
                <BodyCell className="percentage-cell">-</BodyCell>
                <BodyCell className="status-cell">-</BodyCell>
              </TableRow>
            )}
          </Tbody>
        </Table>
        <StickInfoContainer>
          <StickInfoItem>Bastões: {closerPanelData.length}</StickInfoItem>
          <StickInfoItem>
            Total: {formatAmount(sumAmounts(amounts))}
          </StickInfoItem>
        </StickInfoContainer>
      </TableContainer>
    </>
  );
}
