import styled, { css } from 'styled-components';

interface ScheduleProps {
  statusTaskProps?: string;
  situation?: string;
  isBefore?: boolean;
  talkProps?: string;
  tryTimesProps?: string;
  existTryTimesProps?: boolean;
  statusBusinessTask?: string;
  quantityActivitiesInLine?: boolean;
  connection_method?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  overflow: auto;

  padding-left: 0.05rem;
`;

export const ScheduleLineContainer = styled.div<ScheduleProps>`
  display: flex;
  align-items: center;
  flex-direction: row;

  ${props =>
    props.quantityActivitiesInLine
      ? css`
          min-height: 11.125rem;
          justify-content: space-between;
        `
      : css`
          min-height: 6.625rem;
        `}

  p {
    font-weight: 600;
    font-size: 0.938rem;
    color: #05233e;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.5%;
`;

export const LineTableContent = styled.div<ScheduleProps>`
  display: flex;
  align-items: initial;
  justify-content: center;
  flex-direction: column;
  padding: 0 2.5rem;

  width: 92.4%;
  height: 100%;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  #restrictedTime {
    align-items: flex-start;
  }

  @media (min-width: 1400px) {
    padding: 0 4rem;
    gap: 3rem;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #1665d8;
  }

  .slick-track {
    margin: 0;
  }

  ${props =>
    props.quantityActivitiesInLine
      ? css`
          .infos-task {
            width: 16rem !important;
            min-height: 9.875rem;
            flex-direction: column;
            align-items: flex-start;
          }

          .status-try-times {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 0.375rem;
          }

          #status-task-stage {
            align-items: flex-start;
          }
        `
      : css`
          .infos-task {
            min-width: 34rem;
            min-height: 4.75rem;
            flex-direction: row;

            @media (min-width: 1400px) {
              min-width: 41rem;
            }
          }

          .status-try-times {
            display: flex;
            flex-direction: row;

            p#business-situation {
              margin-left: 0.5rem;
            }
          }
        `}
`;

export const InfosSchedulingPreparationContainer = styled.div<ScheduleProps>`
  display: flex !important;

  margin: 0;
  cursor: pointer;

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  background: rgba(1, 64, 119, 0.1);
  box-shadow: inset 0.188rem 0 0 #3ca647,
    0 0.25rem 0.25rem rgba(188, 154, 207, 0.8);
`;

export const InfosTasksContainer = styled.div<ScheduleProps>`
  display: flex !important;
  justify-content: space-between;

  margin: 0;
  cursor: pointer;

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  ${props =>
    props.statusTaskProps === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 #3ca647,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.isBefore &&
        props.statusTaskProps === 'Pendente' &&
        (!props.tryTimesProps ||
          props.tryTimesProps === '0' ||
          props.tryTimesProps === 'null')
      ? css`
          background: rgba(236, 68, 68, 0.25);
          box-shadow: inset 0.188rem 0 0 #de0c0c,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.tryTimesProps > '0' && props.tryTimesProps !== 'null'
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 #b4ad09,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.connection_method === 'inbound digital'
      ? css`
          background: rgba(20, 121, 222, 0.5);
          box-shadow: inset 0.188rem 0 0 #014077,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: inset 0.188rem 0 0 #014077,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `}

  ${props =>
    props.statusBusinessTask === 'Perdido'
      ? css`
          opacity: 0.8;

          #hour-type-closer {
            .hour-name-activity {
              p {
                opacity: 0.8;
              }
            }
          }

          #hour-type-closer,
          #status-task-stage {
            p,
            span {
              opacity: 0.5;
            }
          }
        `
      : css`
          color: #ea3f3f;
        `}
`;

export const HourTypeCloserPreparationContainer = styled.div<ScheduleProps>`
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin: 0.25rem 0 0.25rem 0.625rem;

  p {
    font-family: 500;
    line-height: 1.125rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .hour-name-activity,
  .priority,
  .schedules,
  .transfer,
  .restrictedTime {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }

  p:nth-child(1) {
    display: flex;
    flex-direction: row;
  }

  p.credor {
    font-size: 0.65rem;
    color: #3d3d3d;
  }

  img {
    margin-left: 0.23rem;
  }

  p {
    color: #014077;
  }
`;

export const HourTypeCloserContainer = styled.div<ScheduleProps>`
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.25rem 0 0.25rem 0.625rem;

  p {
    font-family: 500;
    line-height: 1.125rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .hour-name-activity,
  .priority,
  .schedules,
  .transfer,
  .restrictedTime {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }

  ${props =>
    props.statusTaskProps === 'Concluída'
      ? css`
          p {
            color: #3ca647;
          }
        `
      : props.isBefore &&
        props.statusTaskProps === 'Pendente' &&
        (!props.tryTimesProps ||
          props.tryTimesProps === '0' ||
          props.tryTimesProps === 'null')
      ? css`
          p {
            color: #de0c0c;
          }
        `
      : props.tryTimesProps > '0' && props.tryTimesProps !== 'null'
      ? css`
          p {
            color: #b4ad09;
          }
        `
      : css`
          p {
            color: #014077;
          }
        `}

  p:nth-child(1) {
    display: flex;
    flex-direction: row;
  }

  p.credor {
    font-size: 0.65rem;
    color: #3d3d3d;
  }

  img {
    margin-left: 0.23rem;
  }
`;

export const StatusTaskStageContainer = styled.div<ScheduleProps>`
  margin-right: 0.938rem;
  margin-left: 0.625rem;

  display: flex;
  height: 4.625rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 0.3rem 0;

  p {
    font-weight: 600;
    font-size: 0.75rem;

    width: 5.125rem;
    height: 1.625rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
  }

  p#stage {
    background: rgba(60, 166, 71, 0.08);
    border: 0.125rem solid #3d3d3d;
    width: fit-content;
    padding: 0 0.25rem 0 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
  }

  p#connection_method {
    color: white;
    background: #51a7fc;
    width: fit-content;
    padding: 0 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
  }

  p#situation {
    background: rgba(255, 255, 255, 0.8);
    width: auto;
    padding: 0 0.25rem 0 0.25rem;
    margin-right: 0.5rem;

    ${props =>
      props.situation === 'Em aberto'
        ? css`
            color: #3ca647;
          `
        : css`
            color: #ea3f3f;
          `}
  }

  p#status {
    ${props =>
      props.statusTaskProps === 'Concluída'
        ? css`
            background: #3ca647;
          `
        : props.statusTaskProps === 'Pendente'
        ? css`
            background: #ea3f3f;
          `
        : css`
            background: #b4ad09;
          `}
  }

  span#try_times {
    background: none;

    width: 1.25rem;
    height: 1.625rem;

    margin: 0 0 0 0.5rem;

    display: flex;
    justify-content: space-between;

    font-weight: 700;
    font-size: 1rem;

    ${props =>
      props.statusTaskProps === 'Concluída'
        ? css`
            color: #3ca647;
          `
        : props.statusTaskProps === 'Pendente' && props.existTryTimesProps
        ? css`
            color: #b4ad09;
          `
        : css`
            color: #ea3f3f;
          `}
  }

  p#business-situation {
    width: 5.125rem;
    height: 1.625rem;
    background: rgba(255, 255, 255, 0.8);
    margin-right: 0.5rem;

    ${props =>
      props.statusBusinessTask === 'Em aberto'
        ? css`
            color: #3ca647;
          `
        : css`
            color: #ea3f3f;
          `}
  }

  .stage-business-situation {
    display: flex;
    flex-direction: row;
  }
`;
