export const whoContacted = [
  {
    label: 'Credor',
    amount: 5,
  },
  {
    label: 'Advogado',
    amount: 3,
  },
  {
    label: 'Cônjuge',
    amount: 3,
  },
  {
    label: 'Filhos',
    amount: 2,
  },
  {
    label: 'Responsável pelo credor',
    amount: 4,
  },
  {
    label: 'Outros',
    amount: 1,
  },
];

export const whoContactedBeam = [
  {
    label: 'Credor',
    amount: 5,
  },
  {
    label: 'Advogado',
    amount: 5,
  },
  {
    label: 'Cônjuge',
    amount: 3,
  },
  {
    label: 'Filhos',
    amount: 2,
  },
  {
    label: 'Responsável pelo credor',
    amount: 4,
  },
  {
    label: 'Outros (Nota)',
    amount: 1,
  },
];

export const contactPreferences = [
  {
    label: 'WPP',
    amount: 3,
  },
  {
    label: 'E-mail',
    amount: 4,
  },
  {
    label: 'Telefone',
    amount: 2,
  },
  {
    label: 'Pessoalmente',
    amount: 2,
  },
];

export const connection_types = [
  {
    label: 'Inbound (0800)',
    amount: 0,
  },
  {
    label: 'Inbound (virtual)',
    amount: 1,
  },
  {
    label: 'Outbound',
    amount: 2,
  },
];

export const connectionMethod = [
  {
    label: 'SMS',
    amount: 3,
  },
  {
    label: 'RCS',
    amount: 3,
  },
  {
    label: 'Bing',
    amount: 3,
  },
  {
    label: 'E-mail',
    amount: 4,
  },
  {
    label: 'Facebook',
    amount: 4,
  },
  {
    label: 'Site',
    amount: 5,
  },
  {
    label: 'WPP',
    amount: 3,
  },
  {
    label: 'Whatsapp Automático',
    amount: 3,
  },
  {
    label: 'Instagram',
    amount: 3,
  },
  {
    label: 'Google',
    amount: 5,
  },
  {
    label: 'Youtube',
    amount: 0,
  },
  {
    label: 'Telefone',
    amount: 2,
  },
  {
    label: 'Indicação',
    amount: 0,
  },
  {
    label: 'Broker',
    amount: 0,
  },
  {
    label: 'Advogado Parceiro',
    amount: 0,
  },
  {
    label: 'Outros',
    amount: 1,
  },
];

export const decisionMaker = [
  {
    label: 'Credor',
    amount: 5,
  },
  {
    label: 'Advogado',
    amount: 3,
  },
  {
    label: 'Cônjuge',
    amount: 2,
  },
  {
    label: 'Filhos',
    amount: 1,
  },
  {
    label: 'Responsável pelo credor',
    amount: 4,
  },
  {
    label: 'Outros (nota)',
    amount: 0,
  },
];

export const requestedReturn = [
  {
    label: 'Não consegue conversar no momento',
    amount: 0,
  },
  {
    label: 'Quer conversar em outro horário',
    amount: 0,
  },
  {
    label: 'Não solicitou - ligação caiu/parou de responder mensagem',
    amount: 0,
  },
];

export const autority = [
  {
    label: 'Sim',
    amount: 1,
  },
  {
    label: 'Não',
    amount: 0,
  },
];

export const reactions = [
  {
    label: 'Sim, mas adv contradiz',
    amount: 3,
  },
  {
    label: 'Sim, ficou incomodado',
    amount: 0,
  },
  {
    label: 'Sim, indiferente',
    amount: 3,
  },
  {
    label: 'Não',
    amount: 0,
  },
  {
    label: 'Gerei incerteza, mas adv disse que vai receber esse ano',
    amount: 0,
  },
  {
    label: 'Gerei incerteza, mas o adv é contra a antecipação',
    amount: 0,
  },
  {
    label: 'Gerei incerteza, e o credor ficou incomodado',
    amount: 0,
  },
  {
    label: 'Gerei incerteza, e o credor ficou indiferente',
    amount: 0,
  },
  {
    label: 'Não falei sobre incerteza',
    amount: 0,
  },
];

export const plans = [
  {
    label: 'Não perguntei',
    amount: 0,
  },
  {
    label: 'Sim, dívida',
    amount: 5,
  },
  {
    label: 'Sim, investir',
    amount: 3,
  },
  {
    label: 'Sim, saúde',
    amount: 5,
  },
  {
    label: 'Sim, comprar coisas',
    amount: 4,
  },
  {
    label: 'Não quis dividir o plano',
    amount: 0,
  },
  {
    label: 'Não tem plano',
    amount: 0,
  },
];

export const profiles = [
  {
    label: 'Direto',
    amount: 1,
  },
  {
    label: 'Educado',
    amount: 1,
  },
  {
    label: 'Grosso',
    amount: 1,
  },
  {
    label: 'Instruído',
    amount: 1,
  },
  {
    label: 'Simples',
    amount: 1,
  },
  {
    label: 'Conversador',
    amount: 1,
  },
];

export const influences = [
  {
    label: 'Advogado',
    amount: 0,
  },
  {
    label: 'Familiar',
    amount: 3,
  },
  {
    label: 'Terceiros',
    amount: 3,
  },
  {
    label: 'Concorrência',
    amount: 0,
  },
  {
    label: 'Sem influência',
    amount: 2,
  },
  {
    label: 'Não mencionou',
    amount: 1,
  },
  {
    label: 'Não identificou',
    amount: 1,
  },
  {
    label: 'Disse que acompanha tudo com o adv',
    amount: 1,
  },
  {
    label: 'Tinha um familiar perto na hora da conversa',
    amount: 1,
  },
  {
    label: 'Mencionou que algum familiar era contra a antecipação',
    amount: 1,
  },
  {
    label: 'Disse que já ouviu proposta da concorrência',
    amount: 1,
  },
  {
    label: 'Sem influência',
    amount: 1,
  },
  {
    label: 'Não mencionado',
    amount: 1,
  },
];

export const objections = [
  {
    label: 'Não teve objeção',
    amount: 1,
  },
  {
    label: 'Deságio',
    amount: 0,
  },
  {
    label: 'Desconfiança',
    amount: 0,
  },
  {
    label: 'Interferência',
    amount: 0,
  },
  {
    label: 'Acesso aos dados',
    amount: 0,
  },
  {
    label: 'Vida financeira estável',
    amount: 0,
  },
];

export const doubts = [
  {
    label: 'Proposta',
    amount: 2,
  },
  {
    label: 'Procedimento',
    amount: 4,
  },
  {
    label: 'Empresa',
    amount: 4,
  },
  {
    label: 'Dados',
    amount: 1,
  },
  {
    label: 'Previsão de pagamento',
    amount: 1,
  },
  {
    label: 'Como temos informações antes do adv',
    amount: 1,
  },
  {
    label: 'Não teve dúvida',
    amount: 0,
  },
];

export const reasonForScheduling = [
  {
    label: 'Dirigindo',
    value: 'Dirigindo',
    amount: 0,
  },
  {
    label: 'Está na rua',
    value: 'Está na rua - não consegue conversar agora',
    amount: 0,
  },
  {
    label: 'Falha de transferência',
    value: 'Falha de Transferência',
    amount: 0,
  },
  {
    label: 'Familiar ajuda',
    value: 'Familiar ajuda - ligar no horário que o mesmo está em casa',
    amount: 0,
  },
  {
    label: 'Inbound virtual',
    value: 'Inbound Virtual',
    amount: 0,
  },
  {
    label: 'Mandar por escrito para ter tudo relatado',
    value: 'Mandar por escrito para ter tudo relatado',
    amount: 0,
  },
  {
    label: 'Médico',
    value: 'Médico',
    amount: 0,
  },
  {
    label: 'Trabalhando',
    value: 'Trabalhando (não consegue conversar no momento)',
    amount: 0,
  },
  {
    label: 'Estadual',
    value: 'Estadual',
    amount: 0,
  },
  {
    label: 'Caso de valor alto',
    value: 'Caso de valor alto',
    amount: 0,
  },
  {
    label: 'Procedimento interno',
    value: 'Procedimento interno',
    amount: 0,
  },
];

export const lostCategoryAttendance = [
  {
    label: 'Abaixo do target',
    value: 'Abaixo do target',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2022 - interferência de advogado',
    value: 'Acredita no recebimento em 2022 - interferência de advogado',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2022 - interferência de familiares',
    value: 'Acredita no recebimento em 2022 - interferência de familiares',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2022 - interferência de terceiros',
    value: 'Acredita no recebimento em 2022 - interferência de terceiros',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2023 - interferência de advogado',
    value: 'Acredita no recebimento em 2023 - interferência de advogado',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2023 - interferência de familiares',
    value: 'Acredita no recebimento em 2023 - interferência de familiares',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2023 - interferência de terceiros',
    value: 'Acredita no recebimento em 2023 - interferência de terceiros',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2024 - interferência de advogado',
    value: 'Acredita no recebimento em 2024 - interferência de advogado',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2024 - interferência de familiares',
    value: 'Acredita no recebimento em 2024 - interferência de familiares',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2024 - interferência de terceiros',
    value: 'Acredita no recebimento em 2024 - interferência de terceiros',
    amount: 0,
  },
  {
    label: 'Credor incapaz / Benefício Assistencial',
    value: 'Credor incapaz / Benefício Assistencial',
    amount: 0,
  },
  {
    label: 'Credor realizou acordo',
    value: 'Credor realizou acordo',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de advogado',
    value: 'Falta de confiança na empresa - Interferência de advogado',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de concorrentes',
    value: 'Falta de confiança na empresa - Interferência de concorrente',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de familiares',
    value: 'Falta de confiança na empresa - Interferência de familiares',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de terceiros',
    value: 'Falta de confiança na empresa - Interferência de terceiros',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Processos internos',
    value: 'Falta de confiança na empresa - Processos internos',
    amount: 0,
  },
  {
    label: 'LGPD - Credor não deseja mais receber contato',
    value: 'LGPD - Credor não deseja mais receber contato',
    amount: 0,
  },
  {
    label: 'Não compreendeu',
    value: 'Não compreendeu',
    amount: 0,
  },
  {
    label: 'Não deseja tratar do assunto',
    value: 'Não deseja tratar do assunto',
    amount: 0,
  },
  {
    label: 'Não vende com deságio',
    value: 'Não vende com deságio',
    amount: 0,
  },
  {
    label: 'Não é o momento - luto',
    value: 'Não é o momento - luto',
    amount: 0,
  },
  {
    label: 'Não é o momento - motivo de saúde',
    value: 'Não é o momento - motivo de saúde',
    amount: 0,
  },
  {
    label: 'Não é o momento - Pediu retorno no futuro',
    value: 'Não é o momento - Pediu retorno no futuro',
    amount: 0,
  },
  {
    label: 'Não é o momento - viagem',
    value: 'Não é o momento - viagem',
    amount: 0,
  },
  {
    label: 'Precatório comum - Erro de conexão',
    value: 'Precatório comum - Erro de conexão',
    amount: 0,
  },
  {
    label: 'Precatório já negociado',
    value: 'Precatório já negociado',
    amount: 0,
  },
  {
    label: 'Precatório não negociável (procedimento interno)',
    value: 'Precatório não negociável (procedimento interno)',
    amount: 0,
  },
  {
    label: 'Recebeu super preferência',
    value: 'Recebeu super preferência',
    amount: 0,
  },
  {
    label: 'Somente advogado',
    value: 'Somente advogado',
    amount: 0,
  },
  {
    label: 'Somente sindicato',
    value: 'Somente sindicato',
    amount: 0,
  },
  {
    label: 'Óbito',
    value: 'Óbito',
    amount: 0,
  },
  {
    label: 'Sem conexão',
    value: 'Sem conexão',
    amount: 0,
  },
  {
    label: 'Fim de fluxo',
    value: 'Fim de fluxo',
    amount: 0,
  },
];

export const lostCategoryRecapture = [
  {
    label: 'Abaixo do target',
    value: 'Abaixo do target',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2022 - Advogado / Sindicato',
    value: 'Acredita no recebimento em 2022 - Advogado / Sindicato',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2022 - Familiar',
    value: 'Acredita no recebimento em 2022 - Familiar',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2022 - Terceiros',
    value: 'Acredita no recebimento em 2022 - Terceiros',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2023 - Advogado / Sindicato',
    value: 'Acredita no recebimento em 2023 - Advogado / Sindicato',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2023 - Familiar',
    value: 'Acredita no recebimento em 2023 - Familiar',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2023 - Terceiros',
    value: 'Acredita no recebimento em 2023 - Terceiros',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2024 - Advogado / Sindicato',
    value: 'Acredita no recebimento em 2024 - Advogado / Sindicato',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2024 - Familiar',
    value: 'Acredita no recebimento em 2024 - Familiar',
    amount: 0,
  },
  {
    label: 'Acredita no recebimento em 2024 - Terceiros',
    value: 'Acredita no recebimento em 2024 - Terceiros',
    amount: 0,
  },
  {
    label: 'Credor incapaz',
    value: 'Credor incapaz / Benefício Assistencial',
    amount: 0,
  },
  {
    label: 'Credor realizou acordo',
    value: 'Credor realizou acordo',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de advogado',
    value: 'Falta de confiança na empresa - Interferência de advogado',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de concorrentes',
    value: 'Falta de confiança na empresa - Interferência de concorrentes',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de familiares',
    value: 'Falta de confiança na empresa - Interferência de familiares',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Interferência de terceiros',
    value: 'Falta de confiança na empresa - Interferência de terceiros',
    amount: 0,
  },
  {
    label: 'Falta de confiança na empresa - Processos internos',
    value: 'Falta de confiança na empresa - Processos internos',
    amount: 0,
  },
  {
    label: 'LGPD - Credor não deseja mais receber contato',
    value: 'LGPD - Credor não deseja mais receber contato',
    amount: 0,
  },
  {
    label: 'Não compreendeu',
    value: 'Não compreendeu',
    amount: 0,
  },
  {
    label: 'Não deseja tratar do assunto',
    value:
      'Não deseja tratar do assunto (o credor se identificou, sabe do que se trata ((processo))',
    amount: 0,
  },
  {
    label: 'Não vende com deságio',
    value: 'Não vende com deságio',
    amount: 0,
  },
  {
    label: 'Não é o momento - luto',
    value: 'Não é o momento - luto',
    amount: 0,
  },
  {
    label: 'Não é o momento - problemas de saúde',
    value: 'Não é o momento - problemas de saúde',
    amount: 0,
  },
  {
    label: 'Não é o momento - viagem',
    value: 'Não é o momento - viagem',
    amount: 0,
  },
  {
    label: 'Não é o momento - pediu retorno no futuro',
    value: 'Não é o momento - pediu retorno no futuro',
    amount: 0,
  },
  {
    label: 'Precatório já negociado',
    value: 'Precatório já negociado',
    amount: 0,
  },
  {
    label: 'Precatório não negociável (procedimento interno)',
    value: 'Precatório não negociável (procedimento interno)',
    amount: 0,
  },
  {
    label: 'Recebeu super preferência',
    value: 'Recebeu super preferência',
    amount: 0,
  },
  {
    label: 'Somente advogado',
    value: 'Somente advogado',
    amount: 0,
  },
  {
    label: 'Somente sindicato',
    value: 'Somente sindicato',
    amount: 0,
  },
  {
    label: 'Óbito',
    value: 'Óbito',
    amount: 0,
  },
  {
    label: 'Sem conexão',
    value: 'Sem conexão',
    amount: 0,
  },
  {
    label: 'Fim de fluxo',
    value: 'Fim de fluxo',
    amount: 0,
  },
];

export const lostReason = [
  {
    label: 'Opt out',
    value: 'Opt out',
    amount: 0,
  },
  {
    label: 'Óbito',
    value: 'Óbito',
    amount: 0,
  },
  {
    label: 'Já negociado',
    value: 'Já negociado',
    amount: 0,
  },
  {
    label: 'Credor incapaz',
    value: 'Credor incapaz',
    amount: 0,
  },
  {
    label: 'Erro de conexão',
    value: 'Erro de conexão',
    amount: 0,
  },
  {
    label: 'Solicitou acordo',
    value: 'Solicitou acordo',
    amount: 0,
  },
  {
    label: 'Não negociável',
    value: 'Não negociável',
    amount: 0,
  },
];

export const processKnowledge = [
  {
    label: 'Já sabia, reforcei as informações da ação',
    amount: 1,
  },
  {
    label: 'Já sabia, não reforcei',
    amount: 1,
  },
  {
    label: 'Não sabia, relembrei informações da ação',
    amount: 2,
  },
  {
    label: 'Não sabia e eu não expliquei',
    amount: 2,
  },
];

export const proposalExplanation = [
  {
    label: 'Já conhecia, reforcei',
    amount: 1,
  },
  {
    label: 'Não conhecia, expliquei',
    amount: 3,
  },
  {
    label: 'Já conhecia, não reforcei',
    amount: 0,
  },
  {
    label: 'Não expliquei',
    amount: 0,
  },
];

export const motivesForNotAdvancing = [
  {
    label: 'Não tem interesse em antecipar no momento',
    amount: 2,
  },
  {
    label: 'Não precisa do dinheiro',
    amount: 0,
  },
  {
    label: 'Pode esperar pelo tempo que for necessário',
    amount: 0,
  },
  {
    label: 'Acredita que receberá no próximo ano e quer esperar para ver',
    amount: 3,
  },
  {
    label: 'Deseja apenas saber os valores (curiosidade)',
    amount: 1,
  },
  {
    label: 'Falha de transferência',
    amount: 5,
  },
  {
    label: 'Proposta pelo Whatsapp',
    amount: 3,
  },
  {
    label: 'Proposta pelo E-mail',
    amount: 3,
  },
];

export const motivesOfOpportunity = [
  {
    label: 'Já ouviu proposta de outras empresas e quer comparar',
    amount: 2,
  },
  {
    label: 'Quer apenas conhecer a oportunidade',
    amount: 1,
  },
  {
    label: 'Caso o cenário não mude, considera a opção de antecipar',
    amount: 4,
  },
  {
    label: 'Já sabe de tudo, quer ir direto para a proposta',
    amount: 3,
  },
];

export const returnType = [
  {
    label: 'Retorno Frio',
    amount: 1,
  },
  {
    label: 'Retorno Pré Qualificado',
    amount: 2,
  },
];
