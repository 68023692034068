import { format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import activityIcon from '../../../../assets/icons/activity_common.svg';
import { formatAmount } from '../../../../utils/formatAmount';

import {
  Content,
  Title,
  ActivitiesContainer,
  SearchByNameContainer,
  InputSearch,
  ActivityCard,
  InfosActivity,
  CloserAvatar,
} from './styles';

interface IActivity {
  id: string;
  name: string;
  closer_name?: string;
  closer_avatar?: string;
  amount: string;
  contact_preference?: string;
  connection_method?: string;
  status: string;
  try_times?: number;
  talk?: string;
  dealname: string;
  hubspot_deal_id: string;
  business_situation?: string;
  timestamp: Date;
  deleted?: boolean;
}

interface ActivitisHunterToCloserComponentProps {
  activitiesToCloser: IActivity[];
}

export function ActivitisHunterToCloserComponent({
  activitiesToCloser,
}: ActivitisHunterToCloserComponentProps): JSX.Element {
  const [currentActivities, setCurrentActivities] = useState<IActivity[]>(
    [] as IActivity[],
  );

  useEffect(() => {
    setCurrentActivities(activitiesToCloser);
  }, [activitiesToCloser]);

  const getCloserActivityInADay = useCallback(() => {
    setCurrentActivities(activitiesToCloser);
  }, [activitiesToCloser]);

  const searchNotDefinedActivity = useCallback(
    (value: string) => {
      const filterActivity = activitiesToCloser.filter(
        activity =>
          activity.dealname.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setCurrentActivities(filterActivity);
    },
    [activitiesToCloser],
  );

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === ''
        ? getCloserActivityInADay()
        : searchNotDefinedActivity(value);
    },
    [getCloserActivityInADay, searchNotDefinedActivity],
  );

  return (
    <Content>
      <Title>Atividades agendadas</Title>

      <ActivitiesContainer>
        <SearchByNameContainer>
          <InputSearch
            type="text"
            placeholder="Digite o nome do credor"
            onChange={handleSearchInputChange}
          />
          <AiOutlineSearch />
        </SearchByNameContainer>

        {currentActivities &&
          currentActivities.map(activity => (
            <ActivityCard key={activity.id}>
              <InfosActivity>
                <span className="bigger">
                  {format(new Date(activity.timestamp), 'HH:mm')}{' '}
                  <img src={activityIcon} alt="Horário atividade" />
                </span>
                <span className="bigger">Closer: {activity.closer_name}</span>

                <span className="smaller">Credor: {activity.dealname}</span>
                <span className="smaller">
                  {formatAmount(Number(activity.amount))}
                </span>
              </InfosActivity>

              <CloserAvatar>
                <img src={activity.closer_avatar} alt="Closer avatar" />
              </CloserAvatar>
            </ActivityCard>
          ))}
      </ActivitiesContainer>
    </Content>
  );
}
