import styled from 'styled-components';

export const DateAndFilterContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  @media (max-width: 1440px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

export const DateFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.938rem;
  background: #f4f4f5;
  border: 0.031rem solid #05233e;
  border-radius: 0.25rem;
  padding: 0.375rem;
`;

export const Text = styled.p`
  white-space: nowrap;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;

  color: #05233e;
`;

export const Select = styled.select`
  width: 10.5rem;
  height: 1.563rem;

  border: none;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
`;

export const Label = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #595959;
`;

export const FilterInput = styled.input`
  width: 3rem;
  height: 1.563rem;
  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #ffffff;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const FormFilterInput = styled.input`
  width: 8rem;
  height: 1.563rem;
  padding: 0.2rem;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #ffffff;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const Divisor = styled.div`
  height: 1.625rem;
  border-right: 0.063rem solid #817f7f;
`;

export const ClearDateButton = styled.button`
  border: none;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  font-weight: 600;
  font-size: 0.625rem;
  color: #6d6d6d;

  width: 5.25rem;
  height: 1.188rem;

  margin-left: 0.5rem;
`;

export const ChartContainer = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
`;

export const LoaderContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BussinessHoursContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const SubmitButton = styled.div`
  cursor: pointer;
`;
