import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 26rem;
  height: 6.75rem;
  border-radius: 0.625rem;

  border: 0.188rem solid var(--blue-100);
  box-shadow: 0.125rem 0.125rem 0.25rem var(--blue-100);
`;

export const TitleFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 2rem;

  background: var(--blue-100);

  font-weight: 400;
  font-size: 1.25rem;
  color: var(--blue-700);
`;

export const InputFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 4rem;

  gap: 0.313rem;
`;

export const LabelFile = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 9.375rem;
  height: 1.625rem;
  border-radius: 0.313rem;
  background: var(--gray-300);

  font-weight: 600;
  font-size: 0.688rem;
  color: var(--gray-800);

  cursor: pointer;
`;

export const InputFile = styled.input`
  display: none;
`;

export const TextFile = styled.span`
  width: 10rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.625rem;
  color: var(--gray-900);
`;
