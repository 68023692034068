import axios from 'axios';

const url =
  process.env.REACT_APP_API_URL === 'dev'
    ? 'http://localhost:3333'
    : process.env.REACT_APP_API_URL === 'hom'
    ? 'https://api-dev.precato.com.br'
    : 'https://api.precato.com.br';

const token = localStorage.getItem('@Precato:token');

const api = axios.create({
  baseURL: url,
  headers: {
    authorization: `Bearer ${token}`,
    'Content-type': 'application/json',
  },
});

export default api;
