import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ButtonContainerProps {
  manyButtons: number;
}

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 16rem;

  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsDateContainer = styled.div<ButtonContainerProps>`
  width: 100%;

  margin-bottom: 2.188rem;

  ${props =>
    props.manyButtons <= 2
      ? css`
          .buttons {
            display: flex;
            justify-content: space-between;
            width: 17rem;
          }
        `
      : css`
          .buttons {
            display: flex;
            justify-content: space-between;
            width: 25.5rem;
          }
        `}
`;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonCreate = styled.button`
  width: 12rem;
  height: 2rem;
  border: none;

  padding: 0.7rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;

  background: #089b63;
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  color: var(--white);
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.3, '#089B63')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;

export const IconCreate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateActivityContainerActivity = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
