import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react';

import { Form } from '@unform/web';
import { AiOutlineArrowLeft, AiOutlineCheck } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { Container } from '../../../components/Container';
import {
  Line,
  Icon,
  FormContainer,
  FormGroup,
  BackButtonContainer,
  Label,
  TitleHeader,
  TitleContainer,
  Input,
  InputContainer,
  ButtonsContainerEditTeam,
  ButtonEditTeam,
  P,
  H1,
  DivSeparator,
  Separator,
} from './styles';
import api from '../../../services/api';
import { Title } from '../../../components/Styles/Title';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';

interface Team {
  id: string;
  name: string;
  leader_id: string;
}

interface User {
  id: string;
  name: string;
}

const EditTeam: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [findTeam, setFindTeam] = useState<Team>();
  const [users, setUsers] = useState<User[]>([]);

  const location = useLocation();

  const team = location.state as Team;

  useEffect(() => {
    api.get(`/people/teams/${team.id}`).then(response => {
      setFindTeam(response.data);
    });
  }, [team]);

  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);
    });
  }, []);

  const [formData, setFormData] = useState({
    nameTeam: '',
    team_delete: '',
    leader_id: '',
    team_name: '',
    team_id: '',
  });

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function teamError() {
    Swal.fire(
      'Erro!',
      'Verifique os dados preenchidos e tente novamente.',
      'error',
    );
  }

  function editTeamSuccess() {
    toast.success('Time editado com sucesso');
  }

  async function editTeam() {
    try {
      const { data } = await api.put(`/people/teams/${team.id}`, {
        name: formData.team_name,
        leader_id: formData.leader_id,
      });

      const updatedTeam = teams.filter(teamUser => teamUser.id !== team.id);

      editTeamSuccess();
      setTeams([...updatedTeam, data]);
    } catch (err) {
      teamError();
    }
  }

  document
    .getElementById('team_name')
    ?.setAttribute('value', `${findTeam?.name}`);

  return (
    <Container>
      <Navegation>
        <H1Navigation>
          Administração {'>'} Times {'>'}{' '}
        </H1Navigation>
        <H1NavigationNow>Editar time</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Times</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <BackButtonContainer>
          <Link to="/administration/teams">
            <Icon>
              <AiOutlineArrowLeft />
            </Icon>
            Voltar
          </Link>
        </BackButtonContainer>

        <TitleContainer>
          <P>Editar time</P>
        </TitleContainer>

        <TitleContainer>
          <H1>* Preencha somente os dados que deseja alterar</H1>
        </TitleContainer>

        <TitleContainer>
          <TitleHeader>Dados de acesso</TitleHeader>
        </TitleContainer>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={() => {
              // eslint-disable-next-line no-console
              console.log('Teste');
            }}
          >
            <Line />
            <FormGroup>
              <InputContainer>
                <Label>Informe o nome do time</Label>
                <Input
                  id="team_name"
                  name="team_name"
                  type="text"
                  onChange={handleInputChange}
                />
              </InputContainer>
              <InputContainer>
                <Label>Selecione o líder</Label>
                <select
                  className="select"
                  name="leader_id"
                  onChange={handleSelectChange}
                >
                  <option>Selecione uma opção</option>
                  {users.length > 0 &&
                    users.map(user => (
                      <option
                        value={user.id}
                        selected={findTeam?.leader_id === user.id && true}
                      >
                        {user.name}
                      </option>
                    ))}
                </select>
              </InputContainer>
            </FormGroup>
          </Form>
        </FormContainer>
        <ButtonsContainerEditTeam>
          <Link to="/administration/teams">
            <ButtonEditTeam type="button" onClick={editTeam}>
              <Icon>
                <AiOutlineCheck />
              </Icon>
              Salvar
            </ButtonEditTeam>
          </Link>
        </ButtonsContainerEditTeam>
      </Body>
    </Container>
  );
};

export default EditTeam;
