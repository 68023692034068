import styled from 'styled-components';

export const DeleteConfirmationModalContainer = {
  content: {
    width: 'fit-content',
    'min-width': '20.0625rem',
    height: 'fit-content',
    'min-height': '7.375rem',
    border: '0.0625rem solid #51A7FC',
    'border-radius': '0.3125rem',
    margin: 'auto',

    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
  },
};

export const ConfirmationMessage = styled.span`
  width: 11rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const ButtonsDiv = styled.div`
  width: 16.4375rem;
  margin-top: 0.9375rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    width: 7.9375rem;
    height: 1.625rem;
    border: none;
    border-radius: 0.3125rem;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #ffffff;
  }
`;

export const NegativeButton = styled.button`
  background-color: #ea3f3f;
`;

export const AffirmativeButton = styled.button`
  background-color: #014077;
`;
