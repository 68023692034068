import { IoIosArrowDown } from 'react-icons/io';

import { useAuth } from '../../../hooks/auth';
import { handleGetPageIcon } from '../utils/handleGetPageIcon';

import { PermissionItens } from '../PermissionItens';

import { NavigateContainer, IconLabel, DropdownButton } from '../styles';

interface PermissionNavigateProps {
  permission: {
    id: string;
    name: string;
    description: string;
  };
  openPermission: string;
  handleOpenDropdown: (description: string) => void;
}

export function PermissionNavigate({
  permission,
  openPermission,
  handleOpenDropdown,
}: PermissionNavigateProps): JSX.Element {
  const { user } = useAuth();

  let permissions = user.permissions.sort((a, b) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  });

  permissions = user.permissions.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <NavigateContainer
      key={permission.id}
      className="navigate-container"
      style={
        openPermission === permission.description
          ? {
              boxShadow: 'inset -3px 0 0 #1665d8',
            }
          : {}
      }
    >
      <DropdownButton
        onClick={() => handleOpenDropdown(permission.description)}
      >
        <IconLabel>
          <img
            src={handleGetPageIcon(permission.description)}
            alt={permission.description}
          />
          <span className="title">{permission.description}</span>
        </IconLabel>

        <IoIosArrowDown color="#1665d8" />
      </DropdownButton>

      <PermissionItens
        key={permission.id}
        openPermission={openPermission}
        permission={permission}
        permissions={permissions}
      />
    </NavigateContainer>
  );
}
