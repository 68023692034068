import styled from 'styled-components';

export const TableContainer = styled.div`
  margin-top: 4.875rem;
`;

export const Title = styled.h1`
  margin-top: 2rem;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.875rem;
  color: var(--blue-800);
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  concluded: boolean;
}

export const TableRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 18.563rem 22rem 24.687rem 6.313rem;
  align-items: center;
  border-radius: 0.25rem;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-top: 1.188rem;
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 1rem;
  text-align: center;

  color: var(--blue-700);

  border-right: 0.063rem solid var(--blue-600);

  &:nth-child(4) {
    border-right: none;
  }
  &:nth-child(3) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  .tr-body {
    height: 3.125rem;
    border-radius: 0.25rem;
    margin-top: 0.375rem;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
  }

  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }

  button {
    border: none;
    background: none;
  }
`;

export const BodyCell = styled.td<IProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;
  cursor: pointer;
  color: ${props => (props.concluded ? 'var(--gray-500)' : 'var(--blue-800)')};
`;

export const BodyCellButton = styled.td`
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;
`;
