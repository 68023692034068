/* eslint-disable @typescript-eslint/no-unused-vars */
import { ICloserHistory } from '..';

import { AccompanimentHead, TitleBox } from './styles';

interface HeaderAccompanimentResultProps {
  accompanimentButtonSelected: string;
  monthlyCloserHistoryMonthOne: ICloserHistory;
  dailyCloserHistoryDay: ICloserHistory;
  setCurrentFirstTableData: (current: ICloserHistory) => void;
  setToogleButtonSelected: (toogleButton: string) => void;
  setAccompanimentButtonSelected: (acompanimentButton: string) => void;
}

export function HeaderAccompanimentResult({
  accompanimentButtonSelected,
  monthlyCloserHistoryMonthOne,
  dailyCloserHistoryDay,
  setAccompanimentButtonSelected,
  setCurrentFirstTableData,
  setToogleButtonSelected,
}: HeaderAccompanimentResultProps): JSX.Element {
  return (
    <AccompanimentHead>
      <TitleBox>ACOMPANHAMENTO DO RESULTADO DIÁRIO E SEMANAL</TitleBox>
      {/* <ContainerButtons
        accompanimentButtonSelected={accompanimentButtonSelected}
      >
        <ButtonAccompaniment
          className="diary-button"
          onClick={() => {
            setAccompanimentButtonSelected('Diario');
            setToogleButtonSelected('DIA');
            setCurrentFirstTableData(dailyCloserHistoryDay);
          }}
        >
          Diário
        </ButtonAccompaniment>
        <ButtonAccompaniment
          className="monthly-button"
          onClick={() => {
            setAccompanimentButtonSelected('Mensal');
            setToogleButtonSelected('1');
            setCurrentFirstTableData(monthlyCloserHistoryMonthOne);
          }}
        >
          Mensal
        </ButtonAccompaniment>
      </ContainerButtons> */}
    </AccompanimentHead>
  );
}
