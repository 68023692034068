import Modal from 'react-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  ModalCampaignsContainer,
  Title,
  DivSeparator,
  Separator,
  DivContainer,
  NameDiv,
  ButtonSalved,
} from '../RCSModalCampaigns/style';

interface ModalCompaignsRequestProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleUpload: (templateName: string) => void;
}

export function RequestTemplateNameModal({
  isOpen,
  onRequestClose,
  handleUpload,
}: ModalCompaignsRequestProps): JSX.Element {
  const [templateName, setTemplateName] = useState<string>();

  const handleInputChange = useCallback(
    async (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      setTemplateName(value);
    },
    [setTemplateName],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ModalCampaignsContainer}
    >
      <Title>Tempalte</Title>
      <DivSeparator>
        <Separator />
      </DivSeparator>
      <DivContainer>
        <NameDiv>
          <p>Nome do template:</p>
          <input type="text" onChange={handleInputChange} />
        </NameDiv>
        <ButtonSalved onClick={() => handleUpload(templateName)} type="button">
          Enviar
        </ButtonSalved>
      </DivContainer>
    </Modal>
  );
}
