import axios, { AxiosInstance } from 'axios';

const url =
  process.env.REACT_APP_API_URL === 'prod'
    ? 'https://api-assignments.precato.com.br'
    : 'http://localhost:12010';

const apiAssignments = (token: string): AxiosInstance => {
  return axios.create({
    baseURL: url,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
};

export default apiAssignments;
