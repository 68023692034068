import { useCallback, useState } from 'react';
import { format, isBefore } from 'date-fns';

import { IActivityDTO } from '../../dtos/IActivityDTO';
import { ICloserRestrictedTimeDTO } from '../../dtos/ICloserRestrictedTimeDTO';
import { IActivitiesCloserInADayDTO } from '../../dtos/IActivitiesCloserInADayDTO';

import {
  TableContainer,
  HeadContainer,
  RowContainerProps,
  RowContainer,
  ColumnHeadContainer,
  BodyContainer,
  ColumnBodyContainer,
  RowPrepareContainerProps,
} from './styles';
import { PreparationModal } from '../../Closer/ModalSchedulingPreparation';
import { IPreparationPropertiesDTO } from '../../Closer/dtos/IPreparationPropertiesDTO';

interface IListingActivitiesCloserContainer {
  schedulesDate?: Date;
  closerActivityInADay?: IActivitiesCloserInADayDTO;
  formattedTime?: (timestamp: Date) => string;
  handleOpenRestrictedTimeModal?: (
    restrictedTime: ICloserRestrictedTimeDTO,
  ) => void;
  handleOpenActivityModal?: (activity: IActivityDTO) => void;
}

export const ListingActivitiesCloserContainer: React.FC<IListingActivitiesCloserContainer> =
  ({
    schedulesDate,
    closerActivityInADay,
    formattedTime,
    handleOpenRestrictedTimeModal,
    handleOpenActivityModal,
  }) => {
    const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
      return isBefore(new Date(timestamp), new Date());
    }, []);

    const [openPreparationModal, setOpenPreparationModal] = useState(false);
    const [PreparationId, setPreparationId] = useState({});
    const [
      schedulingPreparationConnection,
      setSchedulingPreparationConnection,
    ] = useState('');
    const handleClosePreparationModal = useCallback(() => {
      setOpenPreparationModal(false);
    }, []);

    const handleSubmit = useCallback(
      (preparation: IPreparationPropertiesDTO) => {
        setOpenPreparationModal(true);
        setPreparationId(preparation);

        if (closerActivityInADay.defined) {
          closerActivityInADay.defined.dayHours.forEach(dayHour => {
            dayHour.activity.scheduling.some(scheduling => {
              if (scheduling.id === preparation.scheduling_id) {
                setSchedulingPreparationConnection(
                  scheduling.connection_method,
                );
                return true;
              }
              return false;
            });
          });
        }
      },
      [closerActivityInADay.defined],
    );

    const formattedDate = useCallback((date: Date) => {
      return format(date, 'yyyy-MM-dd');
    }, []);

    const handleShowRestrictedTime = useCallback(
      (restrictedTime: ICloserRestrictedTimeDTO) => {
        const formattedSchedulesDate = formattedDate(schedulesDate);
        const verifyRestrictedTime =
          !restrictedTime.deleted &&
          ((restrictedTime.deleted_today &&
            formattedDate(new Date(restrictedTime.deleted_date)) !==
              formattedSchedulesDate) ||
            !restrictedTime.deleted_today) &&
          (restrictedTime.frequency === 'every_day' ||
            formattedSchedulesDate ===
              formattedDate(new Date(restrictedTime.time_init)) ||
            (restrictedTime.frequency === 'weekly' &&
              new Date(restrictedTime.time_init).getDay() ===
                schedulesDate.getDay()));

        return verifyRestrictedTime;
      },
      [schedulesDate, formattedDate],
    );

    return (
      <TableContainer>
        <HeadContainer>
          <RowContainer className="header">
            <ColumnHeadContainer>HORÁRIO</ColumnHeadContainer>
            <ColumnHeadContainer>ATIVIDADE</ColumnHeadContainer>
            <ColumnHeadContainer>CREDOR</ColumnHeadContainer>
            <ColumnHeadContainer>VALOR</ColumnHeadContainer>
            <ColumnHeadContainer>ETAPA</ColumnHeadContainer>
            <ColumnHeadContainer>STATUS</ColumnHeadContainer>
          </RowContainer>
        </HeadContainer>

        <BodyContainer>
          {closerActivityInADay.defined &&
            closerActivityInADay.defined.dayHours.map(dayHour => (
              <>
                {dayHour.activity.restrictedTime.length > 0 &&
                  dayHour.activity.restrictedTime.map(restrictedTime =>
                    handleShowRestrictedTime(restrictedTime) &&
                    restrictedTime.name !== 'Passagem de bastão' ? (
                      <RowContainerProps className="row-props-body">
                        <RowContainer
                          key={restrictedTime.id}
                          className="body"
                          onClick={() =>
                            handleOpenRestrictedTimeModal(restrictedTime)
                          }
                        >
                          <ColumnBodyContainer className="time">
                            {formattedTime(restrictedTime.time_init)}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {restrictedTime.name}
                          </ColumnBodyContainer>
                        </RowContainer>
                      </RowContainerProps>
                    ) : restrictedTime.name === 'Passagem de bastão' &&
                      !restrictedTime.deleted ? (
                      <RowContainerProps
                        className="row-props-body"
                        isBefore={verifyIsBeforeNow(restrictedTime.time_init)}
                        statusTask={restrictedTime.status === 'Concluída'}
                      >
                        <RowContainer
                          className="body"
                          key={restrictedTime.id}
                          onClick={() =>
                            handleOpenRestrictedTimeModal(restrictedTime)
                          }
                        >
                          <ColumnBodyContainer className="time">
                            {formattedTime(restrictedTime.time_init)}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {restrictedTime.name}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {restrictedTime.dealname}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {restrictedTime.amount}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {restrictedTime.stage_name}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {restrictedTime.status}
                          </ColumnBodyContainer>
                        </RowContainer>
                      </RowContainerProps>
                    ) : (
                      ''
                    ),
                  )}

                {dayHour.activity.schedulingPreparation.length > 0 &&
                  dayHour.activity.schedulingPreparation.map(preparation =>
                    !preparation.deleted &&
                    format(schedulesDate, 'yyyy-MM-dd') ===
                      format(new Date(preparation.timestamp), 'yyyy-MM-dd') ? (
                      <RowPrepareContainerProps
                        className="row-props-body"
                        isBefore={verifyIsBeforeNow(preparation.timestamp)}
                      >
                        <RowContainer
                          className="body"
                          key={preparation.id}
                          onClick={() => handleSubmit(preparation)}
                        >
                          <ColumnBodyContainer className="time">
                            {formattedTime(preparation.timestamp)}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            Preparo de Agendamento
                          </ColumnBodyContainer>
                        </RowContainer>
                      </RowPrepareContainerProps>
                    ) : (
                      ''
                    ),
                  )}

                {dayHour.activity.scheduling.length > 0 &&
                  dayHour.activity.scheduling.map(scheduling =>
                    !scheduling.deleted &&
                    format(schedulesDate, 'yyyy-MM-dd') ===
                      format(new Date(scheduling.timestamp), 'yyyy-MM-dd') ? (
                      <RowContainerProps
                        className="row-props-body"
                        isBefore={verifyIsBeforeNow(scheduling.timestamp)}
                        statusTask={scheduling.status === 'Concluída'}
                        tryTimes={String(scheduling.try_times)}
                      >
                        <RowContainer
                          className="body"
                          key={scheduling.id}
                          onClick={() => handleOpenActivityModal(scheduling)}
                        >
                          <ColumnBodyContainer className="time">
                            {formattedTime(scheduling.timestamp)}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>Agendamento</ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {scheduling.dealname}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {scheduling.amount}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {scheduling.stage_name}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {scheduling.status}
                          </ColumnBodyContainer>
                        </RowContainer>
                      </RowContainerProps>
                    ) : (
                      ''
                    ),
                  )}

                {dayHour.activity.transfer.length > 0 &&
                  dayHour.activity.transfer.map(transfer =>
                    format(schedulesDate, 'yyyy-MM-dd') ===
                    format(new Date(transfer.timestamp), 'yyyy-MM-dd') ? (
                      <RowContainerProps
                        className="row-props-body"
                        isBefore={verifyIsBeforeNow(transfer.timestamp)}
                        statusTask={transfer.status === 'Concluída'}
                        tryTimes={String(transfer.try_times)}
                      >
                        <RowContainer
                          className="body"
                          key={transfer.id}
                          onClick={() => handleOpenActivityModal(transfer)}
                        >
                          <ColumnBodyContainer className="time">
                            {formattedTime(transfer.timestamp)}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            Transferência
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {transfer.dealname}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {transfer.amount}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {transfer.stage_name}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {transfer.status}
                          </ColumnBodyContainer>
                        </RowContainer>
                      </RowContainerProps>
                    ) : (
                      ''
                    ),
                  )}

                {dayHour.activity.priority.length > 0 &&
                  dayHour.activity.priority.map(priority =>
                    !priority.deleted &&
                    format(schedulesDate, 'yyyy-MM-dd') ===
                      format(
                        new Date(priority.notes_next_activity_date),
                        'yyyy-MM-dd',
                      ) ? (
                      <RowContainerProps
                        className="row-props-body"
                        isBefore={verifyIsBeforeNow(
                          priority.notes_next_activity_date,
                        )}
                        statusTask={priority.status === 'Concluída'}
                        tryTimes={String(priority.try_times)}
                      >
                        <RowContainer
                          className="body"
                          key={priority.id}
                          onClick={() => handleOpenActivityModal(priority)}
                        >
                          <ColumnBodyContainer className="time">
                            {formattedTime(
                              new Date(priority.notes_next_activity_date),
                            )}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>Priorização</ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {priority.dealname}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {priority.amount}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {priority.stage_name}
                          </ColumnBodyContainer>

                          <ColumnBodyContainer>
                            {priority.status}
                          </ColumnBodyContainer>
                        </RowContainer>
                      </RowContainerProps>
                    ) : (
                      ''
                    ),
                  )}
              </>
            ))}
          {openPreparationModal && (
            <PreparationModal
              isOpen={openPreparationModal}
              onRequestClose={handleClosePreparationModal}
              preparation={PreparationId}
              scheduling_connection={schedulingPreparationConnection}
            />
          )}
        </BodyContainer>
      </TableContainer>
    );
  };
