import styled from 'styled-components';

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageBox = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1144px;
  height: 732px;
  left: 227px;
  top: 350px;

  background: #ffffff;

  border: 1px solid #05233e;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
`;

export const InputDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  .sendButton {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 1.625rem;
    border: none;
    border-radius: 0.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 1rem;
    text-align: center;

    cursor: pointer;
    color: #ffff;
    background: #05233e;
    transition: 0.1s all;

    &:hover {
      background: #1665d8;
    }
  }
`;

export const DivSpace = styled.div`
  margin-left: 1.5rem;
`;
