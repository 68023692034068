import React, { ChangeEvent, useCallback, useState } from 'react';

import Swal from 'sweetalert2';
import api from '../../services/api';
import { Container } from '../../components/Container';
import {
  Separator,
  DivSeparator,
  DivInputHubSpot,
  InputID,
  H2,
  DivHeader,
  LabelHubspot,
  DivIdCnx,
} from '../Calculator/styles';

import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';
import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../components/Styles/Navigation';
import { ModalFormInformation } from '../Calculator/ModalFormInformation';
import { useAuth } from '../../hooks/auth';
import { InformationLender } from '../Calculator/components/InformationLender';
import { InformationProcess } from '../Calculator/components/InformationProcess/index';
import { BaseValues } from '../Calculator/components/BaseValues';
import { ReturnValuesComponent } from '../Calculator/components/ReturnValuesComponent';
import { IResponse, IResponseLoan } from '../Calculator/dtos/IResponse';
import { IStaggeredProposal } from '../Calculator/dtos/IStaggeredProposal';
import { IHubspotDeal } from '../Calculator/dtos/IHubspotDeal';
import { LoanModal } from '../Calculator/components/LoanModal';

const CalculatorClient: React.FC = () => {
  const { user } = useAuth();
  const [openFormInformationModal, setOpenFormInformationModal] =
    useState(false);
  const [deal, setDeal] = useState<IHubspotDeal>({} as IHubspotDeal);

  const [hubspotDealId, setHubspotDealId] = useState<string>('');
  const [openLoanModal, setOpenLoanModal] = useState<boolean>(false);

  const [returnValues, setReturnValues] = useState<IResponse>({} as IResponse);

  const [staggeredValues, setStaggeredValues] = useState<IStaggeredProposal>(
    {} as IStaggeredProposal,
  );

  const [proposalPercentage, setProposalPercentage] = useState('');

  const handleCloseFormInformationModal = useCallback(() => {
    setOpenFormInformationModal(false);
  }, []);

  const handleOpenLoanModa = useCallback(() => {
    setOpenLoanModal(true);
  }, []);

  const handleCloseLoanModa = useCallback(() => {
    setOpenLoanModal(false);
  }, []);

  const handleResetDeal = useCallback(() => {
    setProposalPercentage('');

    setDeal({
      age: '',
      birth_date: '',
      cpf: '',
      date_base: '',
      dealname: '',
      debitor: '',
      expedition_date: '',
      highlighted_honorary: '',
      honorary: '',
      income: '',
      judicial_district: '',
      lawyer_name: '',
      laywer_type: '',
      loa: '',
      nature: '',
      number_of_months: '',
      pss: '',
      quantity_in_main_process: '',
      reason: '',
      sms_type: '',
      start_of_process: '',
      system_of_process: '',
      trf: '',
      tj: '',
      updated_pss: '',
      updated_value: '',
      updated_value_ipcae: '',
      value: '',
      value_currency: '',
      customer_main_value: '',
      lawyer_main_value: '',
      total_purchase: '',
      city: '',
      connection_method: '',
      filiation: '',
      loa_value: '',
      possible_buy: '',
      product: '',
      proposalVariation: '',
      main_value: '',
    } as IHubspotDeal);
  }, []);

  const createScheduleError = useCallback((message: string) => {
    Swal.fire('Atenção!', message, 'warning');
  }, []);

  const handleAlertPossibleBuy = useCallback(
    (possibleBuy: string) => {
      if (possibleBuy === 'Não') {
        createScheduleError(
          'CAMPO PASSÍVEL DE COMPRA PREENCHIDO COMO NÃO. CONFERIR NO HUBSPOT - NÃO É POSSIVEL A COMPRA!',
        );
      }
    },
    [createScheduleError],
  );

  // engloba a handleDealChange e a handlePercentageInputChange
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'honorary') {
      deal.honorary = value;
      setDeal({ ...deal });
    }
    if (name === 'porcentagem') {
      setProposalPercentage(value);
    }
  };

  const handleHubspotDealIdChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value.length === 10 || value.length === 11) {
        setHubspotDealId(value);

        await api
          .get(`/negociations/calculations/new?hubspot_deal_id=${value}`)
          .then(response => {
            handleResetDeal();

            setDeal(response.data);

            setReturnValues({
              grossValue: '',
              feesValue: '',
              irraValue: '',
              percentageIncomeTax: '',
              pssValue: '',
              liquidValue: '',
              proposalValue: '',
              economyComission: '',
              desagyComission: '',
              totalComission: '',
              proposalVariation: '',
              superpreference: '',
              sucbential_honorary: '',
              special_table: '',
              irraValueRRA: '',
              liquidValueRRA: '',
              percentageIncomeTaxRRA: '',
              proposalValueRRA: '',
              proposalPercentageRRA: '',
              loan: { loanData: [] } as IResponseLoan,
              lastProposalValue: '',
              percentLastProposal: '',
              percentProposalInput: '',
              rraCelling: '',
              tableCelling: '',
            });

            setStaggeredValues({
              proposalDez: '',
              proposalJun: '',
              splitDez: '',
              splitJun: '',
            });

            handleAlertPossibleBuy(response.data.possible_buy);
          });
      }
    },
    [handleAlertPossibleBuy, handleResetDeal],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'}</H1Navigation>
        <H1NavigationNow>Calculadora</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Sistema de Cálculos</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <DivHeader className="two">
          <DivIdCnx>
            <DivInputHubSpot>
              <LabelHubspot>
                ID Hubspot
                <InputID
                  id="text-id"
                  name="textId"
                  onChange={handleHubspotDealIdChange}
                  placeholder="ID Hubspot"
                />
              </LabelHubspot>
            </DivInputHubSpot>
          </DivIdCnx>
        </DivHeader>

        <H2>Informações sobre o credor</H2>

        <InformationLender
          deal={deal}
          openLoan={handleOpenLoanModa}
          brokerClient
        />

        <H2>Informações sobre o processo</H2>

        <InformationProcess deal={deal} />

        <H2>Valores base</H2>

        <BaseValues
          deal={deal}
          returnValues={returnValues}
          userProfile={user.profile}
          brokerClient
        />

        <H2>Valor do Retorno</H2>

        <ReturnValuesComponent
          dealId={hubspotDealId}
          setReturnValues={setReturnValues}
          deal={deal}
          proposalPercentage={proposalPercentage}
          handleChange={handleChange}
          returnValues={returnValues}
          openLoan={handleOpenLoanModa}
          brokerClient
        />

        <ModalFormInformation
          isOpen={openFormInformationModal}
          onRequestClose={handleCloseFormInformationModal}
        />
        <LoanModal
          isOpen={openLoanModal}
          onRequestClose={handleCloseLoanModa}
          loanData={returnValues.loan}
        />
      </Body>
    </Container>
  );
};

export default CalculatorClient;
