import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';

import { AiOutlineDownload } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { Container } from '../../components/Container';
import {
  IconForm,
  Separator,
  DivSeparator,
  InputSearch,
  Table,
  Thead,
  Tbody,
  ContainerHeader,
  SearchContaner,
  ButtonNewTeam,
  ButtonDelete,
  ButtonEdit,
  ContainerTitle,
  Export,
  ContainerExport,
} from './styles';
import api from '../../services/api';
import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';

import search_icon from '../../assets/icons/search.svg';
import star_icon from '../../assets/icons/star.svg';
import user_icon from '../../assets/icons/user.svg';

interface Team {
  id: string;
  name: string;
  leader_id: string;
}

interface User {
  id: string;
  name: string;
}

const TeamList: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    api.get('/people/teams').then(response => {
      setTeams(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);
    });
  }, []);

  const getTeams = useCallback(() => {
    api.get('/people/teams').then(response => {
      setTeams(response.data);
    });
  }, []);

  const searchTeams = useCallback(
    (value: string) => {
      const filterTeams = teams.filter(
        team => team.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setTeams(filterTeams);
    },
    [teams],
  );

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getTeams() : searchTeams(value);
    },
    [getTeams, searchTeams],
  );

  function deleteTeamSuccess() {
    toast.success('Time deletado com sucesso');
  }

  function deleteTeamError() {
    Swal.fire('Erro!', 'Selecione uma opção.', 'error');
  }

  async function deleteTeam(id: string) {
    try {
      await api.delete(`/people/teams/${id}`);
      const updatedTeam = teams.filter(team => team.id !== id);

      setTeams(updatedTeam);
      deleteTeamSuccess();
    } catch (err) {
      deleteTeamError();
    }
  }

  function pdf() {
    window.print();
  }

  return (
    <Container>
      <Navegation>
        <H1Navigation>Administração {'>'} </H1Navigation>
        <H1NavigationNow>Times</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Times</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <ContainerHeader>
          <SearchContaner>
            <InputSearch
              type="text"
              placeholder="Pesquise por time"
              onChange={handleSearchInputChange}
            />
            <img src={search_icon} alt="Search icon" />
          </SearchContaner>

          <Link to="/administration/teams/new">
            <ButtonNewTeam type="submit">Novo time</ButtonNewTeam>
          </Link>
        </ContainerHeader>

        <Table>
          <Thead>
            <tr>
              <th>
                <IconForm className="icon-head">
                  <img src={user_icon} alt="User icon" />
                </IconForm>
                <ContainerTitle>Nome</ContainerTitle>
              </th>
              <th>
                <IconForm className="icon-head">
                  <img src={star_icon} alt="Team icon" />
                </IconForm>
                <ContainerTitle>Líder</ContainerTitle>
              </th>
            </tr>
          </Thead>
          <Tbody>
            {teams.length > 0 &&
              teams.map(team => (
                <tr key={team.id}>
                  <td>{team.name}</td>
                  <td>
                    {users.length > 0 &&
                      users.map(user => (
                        <>
                          {user.id === team.leader_id ? <>{user.name}</> : null}
                        </>
                      ))}
                    <IconForm className="icon-body">
                      <ButtonDelete
                        type="button"
                        onClick={() => deleteTeam(team.id)}
                      >
                        <DeleteIcon />
                      </ButtonDelete>

                      <Link
                        to={{
                          pathname: '/administration/teams/edit',
                          state: {
                            id: team.id,
                          },
                        }}
                      >
                        <ButtonEdit type="button">
                          <EditIcon />
                        </ButtonEdit>
                      </Link>
                    </IconForm>
                  </td>
                </tr>
              ))}
          </Tbody>
        </Table>

        <ContainerExport>
          <Export type="button" onClick={pdf}>
            <AiOutlineDownload />
            Exportar
          </Export>
        </ContainerExport>
      </Body>
    </Container>
  );
};

export default TeamList;
