import { Container, FieldContainer, CreditorContainer } from './styles';

interface IClosedDeal {
  id: string;
  deal_name: string;
  closer_name: string;
  stage_name: string;
  status: string;
  link: string;
  amount: string;
}
interface ClosedDealsProps {
  closedDeal: IClosedDeal;
}

export function ClosedDeals({ closedDeal }: ClosedDealsProps): JSX.Element {
  return (
    <Container
      key={closedDeal.id}
      className="closed-deals-container"
      stage={closedDeal.stage_name.toLocaleLowerCase()}
      status={closedDeal.status.toLocaleLowerCase()}
    >
      <FieldContainer className="field-column">
        <label htmlFor="closer">Closer</label>
        <p id="closer">{closedDeal.closer_name}</p>
      </FieldContainer>

      <FieldContainer className="field-column">
        <label htmlFor="stage">Etapa</label>
        <p id="stage">{closedDeal.stage_name}</p>
      </FieldContainer>

      <FieldContainer className="field-column">
        <label htmlFor="amount">Valor</label>
        <p id="amount">{closedDeal.amount}</p>
      </FieldContainer>

      <CreditorContainer className="field-column">
        <FieldContainer>
          <label htmlFor="creditor_name">Credor:&nbsp;</label>
          <p id="creditor_name">{closedDeal.deal_name}</p>
        </FieldContainer>

        <FieldContainer>
          <label htmlFor="link_hubspot">Link Hubspot:&nbsp;</label>
          <a
            id="link_hubspot"
            href={closedDeal.link}
            target="_blank"
            rel="noreferrer"
          >
            {closedDeal.link ? `${closedDeal.link.slice(0, 23)}...` : ''}
          </a>
        </FieldContainer>
      </CreditorContainer>

      <FieldContainer className="field-column">
        <p id="status">
          {closedDeal.status === 'Concluído Atrasado'
            ? 'Concluído'
            : closedDeal.status}
        </p>
      </FieldContainer>
    </Container>
  );
}
