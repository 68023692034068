import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import { PermissionNavigate } from './PermissionNavigate';
import { handleGetPageLink } from './utils/handleGetPageLink';
import { handleGetPageIcon } from './utils/handleGetPageIcon';

import dashboardIcon from '../../assets/dashboard.svg';

import {
  Container,
  ButtonSidebar,
  Navigation,
  IconLabel,
  NavigateContainer,
} from './styles';

export function SideBar(): JSX.Element {
  const [closed, setClosed] = useState(false);

  const [openPermission, setOpenPermission] = useState('');

  const { user } = useAuth();

  let permissions = user.permissions.sort((a, b) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  });

  permissions = permissions.filter(
    permission =>
      permission.name !== 'Forms Blacklist' &&
      permission.name !== 'Calculadora Linha 1',
  );

  const descriptions = permissions.map(permission => permission.description);

  const uniquePermissions = permissions
    .filter(
      (permission, index) =>
        descriptions.indexOf(permission.description) === index,
    )
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

  const handleOpenDropdown = useCallback(
    (description: string) => {
      if (description === openPermission) {
        setOpenPermission('');
      } else {
        setOpenPermission(description);
      }
    },
    [openPermission],
  );

  return (
    <Container id="menu-container" isClosed={closed}>
      <ButtonSidebar onClick={() => setClosed(!closed)}>
        <FiMenu size={25} color="#05233E" />
      </ButtonSidebar>

      <Navigation id="menu-navigation">
        <NavigateContainer
          className="navigate-container"
          onClick={() => setOpenPermission('dashboard')}
          style={
            openPermission === 'dashboard'
              ? {
                  boxShadow: 'inset -3px 0 0 #1665d8',
                }
              : {}
          }
        >
          <ul>
            <li>
              <Link to="/dashboard">
                <IconLabel>
                  <img src={dashboardIcon} alt="Página inicial" />
                  <span className="title">Página inicial</span>
                </IconLabel>
              </Link>
            </li>
          </ul>
        </NavigateContainer>

        {uniquePermissions.length > 0 &&
          uniquePermissions.map(permission =>
            permission.description !== 'Nenhum' ? (
              <PermissionNavigate
                key={permission.id}
                permission={permission}
                openPermission={openPermission}
                handleOpenDropdown={handleOpenDropdown}
              />
            ) : (
              <NavigateContainer
                className="navigate-container"
                onClick={() => setOpenPermission(permission.name)}
                key={permission.id}
                style={
                  openPermission === permission.name
                    ? {
                        boxShadow: 'inset -3px 0 0 #1665d8',
                      }
                    : {}
                }
              >
                <ul>
                  <li>
                    <Link
                      to={handleGetPageLink(
                        permission.name,
                        permission.description,
                      )}
                    >
                      <IconLabel>
                        <img
                          src={handleGetPageIcon(permission.name)}
                          alt={permission.name}
                        />
                        <span className="title">{permission.name}</span>
                      </IconLabel>
                    </Link>
                  </li>
                </ul>
              </NavigateContainer>
            ),
          )}
      </Navigation>
    </Container>
  );
}
