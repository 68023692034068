import styled from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const IndicatorsNumbers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 9.125rem;
  height: 4rem;

  background: #f4f4f5;
  box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;

  font-weight: 600;
  font-size: ${pxToRem(36)};
  color: #1665d8;
`;

export const BoxIndicatorsNumbers = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background: rgba(215, 227, 245, 0.3);

  border-radius: ${pxToRem(8)};

  width: ${pxToRem(198)};
  height: ${pxToRem(113)};

  margin-right: ${pxToRem(2)};
  margin-left: ${pxToRem(2)};
`;

export const Separator = styled.div`
  margin: 4rem;
`;

export const TextIndicators = styled.span`
  font-size: ${pxToRem(14)};
  font-weight: 600;

  color: var(--gray-700);

  padding-bottom: ${pxToRem(8)};
`;

export const ContainerBoxIndicators = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${pxToRem(38)};
  margin-bottom: ${pxToRem(20)};
`;
