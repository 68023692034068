import { shade } from 'polished';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem 0;
`;
export const TitleHeader = styled.h1`
  width: 16rem;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--blue-100);
  border-radius: 0.25rem;

  font-size: 1.25rem;
  font-weight: 400;

  color: var(--blue-700);
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
`;

export const BorderFormGroup = styled.div`
  width: 0.313rem;
  height: auto;
  max-height: 12.5rem;

  background: var(--gradient);
  border-radius: 5.938rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem 0;
`;

export const ButtonSubmit = styled.button`
  width: fit-content;
  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: none;
  padding: 0 0.7rem;
  border-radius: 0.25rem;

  color: var(--white);
  font-weight: 600;
  font-size: 0.9rem;

  background: var(--green-600);
  transition: background-color 0.2ms;

  &:hover {
    background: ${shade(0.3, '#089B63')};
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
