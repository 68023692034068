import styled, { css } from 'styled-components';

interface ContainerProps {
  isClosed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  height: 100%;

  background: var(--blue-50);
  margin-top: 4.5rem;

  #menu-navigation {
    transition: 0.3s linear;
  }

  ${props =>
    props.isClosed &&
    css`
      #menu-navigation {
        width: 2rem;
      }

      .navigate-container {
        display: none;
      }
    `}
`;

export const ButtonSidebar = styled.button`
  background: none;
  border: none;

  margin: 0.8rem 0.625rem 0 0.8rem;
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;

  width: 12rem;
  height: 100%;

  padding: 0.625rem 0 0.625rem 0.625rem;
`;

export const IconLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;

  span.title {
    font-weight: 500;
  }

  span {
    font-weight: 400;
    font-size: 0.875rem;
    color: var(--blue-800);
  }
`;

export const NavigateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 1.375rem;
  padding-right: 0.625rem;
  border-radius: 0.25rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export const NavigateChildContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-left: 1rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-top: 0.8rem;

      img {
        width: 0.85rem;
        height: 0.85rem;
      }
    }
  }
`;

export const DropdownButton = styled.button`
  background: none;
  border: none;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
