import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { ContainerLoader } from './styles';

type LoadngProps = {
  loader_up: boolean;
};

export function Loading({ loader_up }: LoadngProps): JSX.Element {
  return (
    <ContainerLoader id={loader_up ? 'loader_up' : 'loader'}>
      <Loader type="Oval" color="#05233e" height={50} width={50} />
    </ContainerLoader>
  );
}

Loading.propTypes = {
  loader_up: PropTypes.bool,
};

Loading.defaultProps = {
  loader_up: false,
};
