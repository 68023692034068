import { IHubspotDeal } from '../../dtos/IHubspotDeal';
import { Form, Div, Label, Input } from '../../styles';

interface IProps {
  deal: IHubspotDeal;
}
export const ConectionInformation = ({ deal }: IProps): JSX.Element => {
  return (
    <Form>
      <Div className="container">
        <Div className="two">
          <Label>
            Somente fundo compra?
            <Input
              id="only_purchase_fund"
              name="only_purchase_fund"
              value={deal.only_purchase_fund}
              disabled
            />
          </Label>
          <Label>
            Meio de conexão
            <Input
              id="connection_method"
              name="connection_method"
              value={deal.connection_method}
              disabled
            />
          </Label>
        </Div>
        <Div className="two">
          {deal.nutrition && (
            <Label>
              Nutrição
              <Input id="nutrition" name="nutrition" value="SIM" disabled />
            </Label>
          )}
          {deal.retake && (
            <Label>
              Retomada <Input id="retake" name="retake" value="SIM" disabled />
            </Label>
          )}
        </Div>
      </Div>
    </Form>
  );
};
