/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback } from 'react';
import Modal from 'react-modal';

import close_modal from '../../../../../../assets/icons/close_modal.svg';

import {
  ModalClosers,
  ModalHeader,
  CloseModalButton,
  ModalBody,
} from './styles';

Modal.setAppElement('#root');

interface IAvailableClosers {
  isOpen: boolean;
  onRequestClose: () => void;
  closers: string[];
}

export function ModalAvailableClosers({
  isOpen,
  onRequestClose,
  closers,
}: IAvailableClosers) {
  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalClosers}>
      <ModalHeader>
        <p>Closers disponíveis:</p>
        <CloseModalButton id="closeModal" type="button" onClick={closeModal}>
          <img src={close_modal} alt="Fechar modal" />
        </CloseModalButton>
      </ModalHeader>

      <ModalBody>
        {closers && closers.map(closer => <span>{closer}</span>)}
      </ModalBody>
    </Modal>
  );
}
