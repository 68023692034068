/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { ToggleSwitch } from 'react-dragswitch';
import toast, { Toaster } from 'react-hot-toast';
import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';
import { InputInitialAndFinalDayPicker } from '../components/InputInitialAndFinalDayPicker';
import { AttendanceChart } from '../components/AttendanceChart';
import apiChatbot from '../../../services/apiChatbot';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import { sortDateVector } from '../../../utils/sortDateVector';
import { IAttendanceReportDTO } from '../dtos/IAttendanceReportDTO';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import {
  DateFilter,
  Option,
  ClearDateButton,
  ChartContainer,
  LoaderContainer,
  DateAndFilterContainer,
  FiltersContainer,
  Label,
  FormFilterInput,
  Divisor,
  Text,
  BussinessHoursContainer,
  Select,
  SubmitButton,
} from './styles';
import { removeRepeatedWithHoursDatesInString } from '../utils/removeRepeatedDateWithHoursInString';
import { formatDate } from '../../../utils/formatDate';

interface IAgent {
  name: string;
  id: string;
}

export function Report(): JSX.Element {
  const [businessHourChecked, setBusinessHourChecked] = useState(false);
  const [agentSelected, setAgentSelected] = useState<string>(undefined);
  const [agents, setAgents] = useState<IAgent[]>([]);
  const [statusConnectionSelected, setStatusConnectionSelected] =
    useState<string>(undefined);
  const [channelSelected, setChannelSelected] = useState<string>(undefined);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [dateRangeAverageResponseTime, setDateRangeAverageResponseTime] =
    useState<string[]>([]);
  const [attendances, setAttendances] = useState<IAttendanceReportDTO[]>([]);
  const [connected, setConnected] = useState<number[]>([]);
  const [costCenter, setCostCenter] = useState('FEDERAL');
  const [loading, setLoading] = useState(true);
  const [averageResponseTimes, setAverageResponseTimes] = useState<number[]>(
    [],
  );
  const [to, setTo] = useState<Date>(new Date(new Date().setHours(12, 0, 0)));
  const [from, setFrom] = useState<Date>(
    new Date(new Date().setHours(12, 0, 0)),
  );

  const channels = [
    { id: '1', label: 'SMS', value: 'SMS' },
    { id: '2', label: 'Messenger', value: 'MESSENGER' },
    { id: '3', label: 'E-mail', value: 'EMAIL' },
    { id: '4', label: 'Site', value: 'SITE' },
    { id: '5', label: 'Facebook', value: 'FACEBOOK' },
    { id: '6', label: 'Whatsapp', value: 'WPP' },
    { id: '7', label: 'Google', value: 'GOOGLE' },
    { id: '8', label: 'Todos', value: ' ' },
  ];

  const statusConnections = [
    { id: '1', label: 'É O CREDOR', value: 'É O CREDOR' },
    { id: '2', label: 'NÃO É O CREDOR', value: 'NÃO É O CREDOR' },
    {
      id: '3',
      label: 'FALA EM NOME DO CREDOR',
      value: 'FALA EM NOME DO CREDOR',
    },
  ];

  useEffect(() => {
    const api = apiChatbot(localStorage.getItem('@Precato:token'));

    api.get<IAgent[]>('/attendance/agents').then(response => {
      setAgents(response.data);
    });
  }, []);

  const costCenters = [
    { id: '1', label: 'Federal', value: 'FEDERAL' },
    { id: '2', label: 'Estadual', value: 'ESTADUAL' },
  ];

  const calculateDateRange = useCallback(
    (data: IAttendanceReportDTO[]) => {
      const connectedAttendances = data.filter(
        attendance => attendance.connection === true,
      );

      const attendanceByHours: IAttendanceReportDTO[] = [];

      for (const attendance of connectedAttendances) {
        const foundAttendance =
          attendanceByHours.length > 0
            ? attendanceByHours.find(
                findAttendance =>
                  findAttendance.attendance_id !== attendance.attendance_id &&
                  findAttendance.transferred_at.getHours() ===
                    attendance.transferred_at.getHours() &&
                  findAttendance.transferred_at.getDate() ===
                    attendance.transferred_at.getDate() &&
                  findAttendance.transferred_at.getMonth() ===
                    attendance.transferred_at.getMonth() &&
                  findAttendance.transferred_at.getFullYear() ===
                    attendance.transferred_at.getFullYear(),
              )
            : null;

        if (!foundAttendance) {
          attendanceByHours.push(attendance);
        }
      }

      const dateIntervalFormattedInDate = attendanceByHours.map(attendance => {
        attendance.transferred_at.setMinutes(0);
        attendance.transferred_at.setMilliseconds(0);

        return attendance.transferred_at;
      });

      const dateIntervalInHoursAndDaysOrder = sortDateVector(
        dateIntervalFormattedInDate,
      ).map(date => date.toString());

      setDateRange(dateIntervalInHoursAndDaysOrder);

      return dateIntervalInHoursAndDaysOrder;
    },
    [from, to],
  );

  const calculateDateRangeAverageResponseTime = useCallback(
    (start_date, end_date) => {
      const oneDayInMs = 24 * 60 * 60 * 1000;

      const differenceInTime = end_date.getTime() - start_date.getTime();

      const differenceInDays = Math.round(differenceInTime / oneDayInMs);

      const dateRangesAvgResponse: string[] = [];

      for (let i = 0; i <= differenceInDays; i++) {
        for (let j = 0; j < 24; j++) {
          const day = new Date(start_date.getTime() + i * oneDayInMs);
          day.setHours(j);

          day.setMinutes(0);

          day.setMilliseconds(0);

          dateRangesAvgResponse.push(day.toString());
        }
      }

      return dateRangesAvgResponse;
    },
    [],
  );

  const getConnectionData = useCallback(
    (
      attendancesRequest: IAttendanceReportDTO[],

      dateRangeRequest: string[],
    ) => {
      const connectedAttendances = attendancesRequest.filter(
        attendance => attendance.connection === true,
      );

      const connectionFilter = dateRangeRequest.map(date => {
        const connectedByDay = removeRepeatedWithHoursDatesInString(
          connectedAttendances,
          date,
        );

        const attendancesByDay = removeRepeatedWithHoursDatesInString(
          attendancesRequest,
          date,
        );

        return attendancesByDay.length === 0
          ? 0
          : Number(
              (connectedByDay.length / attendancesByDay.length).toFixed(2),
            );
      });

      return connectionFilter;
    },
    [],
  );

  useEffect(() => {
    const dateInterval = calculateDateRange(attendances);

    const connecteds = getConnectionData(attendances, dateInterval);

    setConnected(connecteds);

    setLoading(false);
  }, [attendances]);

  useEffect(() => {
    setDateRangeAverageResponseTime(
      calculateDateRangeAverageResponseTime(from, to),
    );
  }, [averageResponseTimes]);

  const handleUpdateCharts = useCallback(() => {
    setLoading(true);

    let route = from ? `?initial_date=${formatDate(from)}` : '';

    if (to) {
      route += from ? '&' : '?';

      route += `final_date=${formatDate(to)}`;
    }

    try {
      const api = apiChatbot(localStorage.getItem('@Precato:token'));

      api
        .get<IAttendanceReportDTO[]>(
          `/attendance/connection-status/${costCenter}${route}`,
        )
        .then(response => {
          const formattedAttendances = response.data.map(attendance => {
            return {
              ...attendance,

              transferred_at: new Date(attendance.transferred_at),
            };
          });

          setAttendances(formattedAttendances);
        });

      api
        .post<number[]>(`/attendance/average-response-time`, {
          start_date: from,
          end_date: to,
          agent: agentSelected,
          is_business_hours: businessHourChecked,
          channel: channelSelected,
          status_connection: statusConnectionSelected,
        })
        .then(response => {
          const formatData = response.data.map(time =>
            Number((time / 60000).toFixed(2)),
          );

          setAverageResponseTimes(formatData);
        });
    } catch (error) {
      setLoading(false);
      toast.error('Ocorreu um erro ao buscar informações!');
    }
  }, [to, from]);

  useEffect(() => {
    handleUpdateCharts();
  }, []);

  const handleResetDate = useCallback(() => {
    setFrom(new Date(new Date().setHours(12, 0, 0)));

    setTo(new Date(new Date().setHours(12, 0, 0)));
  }, []);

  const handleCostCenterSelected = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCostCenter(event.target.value);
    },
    [],
  );

  return (
    <Container>
      <NavigationBar past_screens="Conexão > " active_screen="Relatório" />
      <Toaster position="top-right" reverseOrder={false} />
      <Body>
        <Title name="Relatório" />
        <DateAndFilterContainer>
          <DateFilter>
            <InputInitialAndFinalDayPicker
              initialDate={from}
              finalDate={to}
              dayClickFrom={value => setFrom(value)}
              dayClickTo={value => setTo(value)}
            />

            <ClearDateButton onClick={handleResetDate}>
              <ClearIcon />
              Limpar data
            </ClearDateButton>
          </DateFilter>

          <FiltersContainer>
            <Text>Filtre por:</Text>

            <Label htmlFor="status">Status</Label>
            <Select
              name="status"
              id="status"
              value={statusConnectionSelected}
              defaultValue="Selecione um status"
              onChange={e => setStatusConnectionSelected(e.target.value)}
            >
              <Option value="Selecione um status" disabled>
                Selecione um status
              </Option>
              {statusConnections.map(status => (
                <Option key={status.id} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </Select>

            <Divisor />

            <Label htmlFor="channel">Canal</Label>
            <Select
              name="channel"
              id="channel"
              value={channelSelected}
              defaultValue="Selecione um canal"
              onChange={e => setChannelSelected(e.target.value)}
            >
              <Option value="Selecione um canal" disabled>
                Selecione um canal
              </Option>
              {channels.map(channel => (
                <Option key={channel.id} value={channel.value}>
                  {channel.label}
                </Option>
              ))}
            </Select>

            <Divisor />

            <Label htmlFor="agent">Operador</Label>
            <Select
              name="agent"
              id="agent"
              value={agentSelected}
              defaultValue="Selecione um operador"
              onChange={e => setAgentSelected(e.target.value)}
            >
              <Option value="Selecione um operador" disabled>
                Selecione um operador
              </Option>
              {agents.map(agent => (
                <Option key={agent.id} value={agent.id}>
                  {agent.name}
                </Option>
              ))}
            </Select>

            <Divisor />

            <Label htmlFor="endingReason">Finalização</Label>
            <FormFilterInput name="endingReason" id="endingReason" />
            <SubmitButton>
              <SearchIcon onClick={handleUpdateCharts} />
            </SubmitButton>
          </FiltersContainer>
        </DateAndFilterContainer>
        <BussinessHoursContainer>
          <Text>Horário comercial?</Text>
          <ToggleSwitch
            onColor="rgba(22, 101, 216, 0.15)"
            offColor="rgba(0,0,0,0.15)"
            className="toggle"
            handleColor={businessHourChecked ? '#1665D8' : '#9D9D9D'}
            checked={businessHourChecked}
            onChange={() => {
              setBusinessHourChecked(!businessHourChecked);
            }}
          />

          <Select
            name="cost_center"
            id="cost_center"
            value={costCenter}
            onChange={handleCostCenterSelected}
          >
            <Option disabled />
            {costCenters.map(cost_center => (
              <Option key={cost_center.id} value={cost_center.value}>
                {cost_center.label}
              </Option>
            ))}
          </Select>
        </BussinessHoursContainer>

        {loading ? (
          <LoaderContainer>
            <Loader type="Oval" color="#05233e" height={50} width={50} />
          </LoaderContainer>
        ) : (
          <>
            <ChartContainer>
              <AttendanceChart
                chartName="Conexão/atendimento"
                chartTitle="Conexão por atendimento"
                dateRange={dateRange}
                data={connected}
              />
            </ChartContainer>
            <ChartContainer>
              <AttendanceChart
                chartName="Tempo Resposta Medio"
                chartTitle="Tempo de resposta médio"
                dateRange={dateRangeAverageResponseTime}
                data={averageResponseTimes}
              />
            </ChartContainer>
          </>
        )}
      </Body>
    </Container>
  );
}
