import { useCallback, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { add, format } from 'date-fns';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import { Body } from '../../../../../components/Styles/Body';
import { Title } from '../../../../../components/Styles/Title';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../../components/Styles/Navigation';
import { Container } from '../../../../../components/Container';
import { DayPicker } from '../../../../../components/DayPicker';
import { ScheduleHunterContainer } from '../../../components/ScheduleHunterContainer';
import { ActivitisHunterToCloserComponent } from '../../../components/ActivitiesHunterToCloserComponent';

import api from '../../../../../services/api';
import { ScheduleHunterProvider } from '../../../../../hooks/useHunterSchedule';

import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar_blue.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../../assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow_right.svg';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg';

import {
  DivSeparator,
  Separator,
  Main,
  BackButtonContainer,
  Icon,
  FilterManagerContainer,
  ButtonsContainer,
  PreviousButton,
  ContainerInput,
  ContainerInputDate,
  NextButton,
  FiltersContainer,
  FilterSelectContainer,
  Text,
  Label,
  Select,
  Option,
  SearchButton,
  VerticalDivisor,
  ActivitiesContainer,
} from './styles';
import { ScheduleOptionsButton } from '../../../../../components/ScheduleOptionsButton';
import { NutriticionCases } from '../../../Hunter/NutritionCases';

interface IUserLocation {
  user_id: string;
  hunter_id: string;
  date: string;
}

interface IUser {
  name: string;
}

interface IActivity {
  id: string;
  name: string;
  closer_name?: string;
  closer_avatar?: string;
  amount: string;
  contact_preference?: string;
  connection_method?: string;
  status: string;
  try_times?: number;
  talk?: string;
  dealname: string;
  hubspot_deal_id: string;
  business_situation?: string;
  timestamp: Date;
  deleted?: boolean;
}

export const IndividualHunterHistory: React.FC = () => {
  const location = useLocation();

  const connections = ['Inbound', 'Outbound', 'Retomada'];
  const situations = ['Concluída', 'Tentou', 'Pendente'];
  const [connectionSelected, setConnectionSelected] = useState<string>();
  const [selectedDay, setSelectedDay] = useState<string>();
  const [situationSelected, setSituationSelected] = useState<string>();
  const [user, setUser] = useState<IUser>({} as IUser);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [schedulesByDay, setSchedulesByDay] = useState<IActivity[]>(
    [] as IActivity[],
  );

  const locationUser = location.state as IUserLocation;

  useEffect(() => {
    setScheduleDate(new Date(locationUser.date));
  }, [locationUser]);

  useEffect(() => {
    api
      .get(
        `schedule/list-hunter-schedulings?hunter_id=${
          locationUser.hunter_id
        }&date=${format(scheduleDate, 'yyyy-MM-dd')}`,
      )
      .then(response => {
        setSchedulesByDay(response.data);
      });
  }, [locationUser.hunter_id, scheduleDate, user]);

  useEffect(() => {
    api.get(`users/${locationUser.hunter_id}`).then(response => {
      setUser(response.data);
    });
  }, [locationUser]);

  const handleDateInputChange = useCallback((day: Date) => {
    setScheduleDate(day);
  }, []);

  const previousDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: -1,
    });
    setScheduleDate(newDate);
  }, [scheduleDate]);

  const nextDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: 1,
    });

    setScheduleDate(newDate);
  }, [scheduleDate]);

  return (
    <ScheduleHunterProvider
      date={scheduleDate}
      user_id={locationUser.hunter_id}
    >
      <Container>
        <Navegation>
          <H1Navigation>Negociação {'>'} </H1Navigation>
          <H1Navigation>Agenda {'>'} </H1Navigation>
          <H1NavigationNow>Histórico do hunter</H1NavigationNow>
        </Navegation>
        <Body>
          <Title>
            Agenda - <strong>{user.name}</strong>
          </Title>
          <DivSeparator>
            <Separator />
          </DivSeparator>

          <BackButtonContainer>
            <Link to="/negociations/schedule/manager/history">
              <Icon>
                <AiOutlineArrowLeft />
              </Icon>
              Voltar
            </Link>
          </BackButtonContainer>

          <Main>
            <FilterManagerContainer>
              <ButtonsContainer>
                <PreviousButton onClick={previousDate}>
                  <ArrowLeftIcon />
                </PreviousButton>
                <ContainerInput>
                  <ContainerInputDate>
                    <CalendarIcon />
                    <DayPicker
                      value={scheduleDate}
                      handleChange={day => handleDateInputChange(day)}
                    />
                  </ContainerInputDate>
                </ContainerInput>
                <NextButton onClick={nextDate}>
                  <ArrowRightIcon />
                </NextButton>
                <ScheduleOptionsButton
                  key="Nutrição"
                  selectedValue={selectedDay === 'Nutrição'}
                  weekdaySchedule
                  type="button"
                  onClick={() => setSelectedDay('Nutrição')}
                >
                  Nutrição
                </ScheduleOptionsButton>
              </ButtonsContainer>

              <FiltersContainer>
                <Text>Filtre o histórico por:</Text>

                <FilterSelectContainer>
                  <Label htmlFor="stage">Conexão</Label>
                  <Select
                    name="stage"
                    id="stage"
                    value={connectionSelected}
                    defaultValue=""
                    onChange={e => setConnectionSelected(e.target.value)}
                  >
                    <Option value="" />
                    {connections.map(stage => (
                      <Option key={stage} value={stage}>
                        {stage}
                      </Option>
                    ))}
                  </Select>
                </FilterSelectContainer>

                <VerticalDivisor />

                <FilterSelectContainer>
                  <Label htmlFor="situation">Situação</Label>
                  <Select
                    name="situation"
                    id="situation"
                    value={situationSelected}
                    defaultValue=""
                    onChange={e => setSituationSelected(e.target.value)}
                  >
                    <Option value="" />
                    {situations.map(situation => (
                      <Option key={situation} value={situation}>
                        {situation}
                      </Option>
                    ))}
                  </Select>
                </FilterSelectContainer>

                <SearchButton>
                  <SearchIcon />
                </SearchButton>
              </FiltersContainer>
            </FilterManagerContainer>

            <ActivitiesContainer>
              {selectedDay === 'Nutrição' ? (
                <NutriticionCases hunterId={locationUser.hunter_id} />
              ) : (
                <>
                  {' '}
                  <ScheduleHunterContainer date={scheduleDate} />
                  <ActivitisHunterToCloserComponent
                    activitiesToCloser={schedulesByDay}
                  />
                </>
              )}
            </ActivitiesContainer>
          </Main>
        </Body>
      </Container>
    </ScheduleHunterProvider>
  );
};
