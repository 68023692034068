import { useState, useEffect } from 'react';
import { crawlerFinishTimeForecast } from '../../../../utils/crawlerFinishTimeForecast';
import millisecondsToMinutesAndSecondsAndDays from '../../../../utils/millisecondsToMinutesAndSecondsAndDays';
import { TimeBox } from './styles';

interface TimeRemainingProps {
  processed: number;
  lines: number;
  createdAt: string;
}
export const TimeRemainingTJS: React.FC<TimeRemainingProps> = (
  props: TimeRemainingProps,
) => {
  const [time, setTime] = useState(0);
  const [timeString, setTimeString] = useState('');

  useEffect(() => {
    const { processed, lines, createdAt } = props;
    const ms = new Date(createdAt).getTime();
    const timeMs = crawlerFinishTimeForecast(processed, lines, ms);
    setTime(timeMs);
    setTimeString(millisecondsToMinutesAndSecondsAndDays(timeMs));
    if (timeMs < 1000) {
      setTimeString('Finalizado');
    }
  }, [props]);

  useEffect(() => {
    if (time > 1000) {
      const interval = setInterval(() => {
        setTime(time - 1000);
        setTimeString(millisecondsToMinutesAndSecondsAndDays(time));
      }, 1000);
      return () => clearInterval(interval);
    }

    setTime(0);
    setTimeString('Finalizado');

    return null;
  }, [time]);
  return <TimeBox>{timeString}</TimeBox>;
};
