import { useAuth } from '../../../../hooks/auth';
import { IResponse } from '../../dtos/IResponse';
import { Form, Div, Label, Input } from '../../styles';

interface IProps {
  returnValues: IResponse;
}
export const Commission = ({ returnValues }: IProps): JSX.Element => {
  const { user } = useAuth();

  return user.profile !== 'Calculadora broker' &&
    user.profile !== 'Calculadora' ? (
    <Form>
      <Div className="container">
        <Label>
          Receita
          <Input
            name="valuePrecatory"
            value={returnValues.economyComission}
            disabled
          />
        </Label>
        <Label>
          Variável
          <Input
            name="valuePrecatory"
            value={returnValues.desagyComission}
            disabled
          />
        </Label>
        <Label>
          Comissão
          <Input
            name="valuePrecatory"
            value={returnValues.totalComission}
            disabled
          />
        </Label>
      </Div>
    </Form>
  ) : (
    user.profile === 'Calculadora broker' && (
      <Form>
        <Div className="container">
          <Label>
            Comissão
            <Input
              name="valuePrecatory"
              value={returnValues.comissionBroker}
              disabled
            />
          </Label>
        </Div>
      </Form>
    )
  );
};
