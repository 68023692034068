import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Container, TitleGraphic } from './styles';

interface IAttendanceChartProps {
  dateRange: string[];
  data: number[];
  chartName: string;
  chartTitle: string;
}

export function AttendanceChart({
  dateRange,
  data,
  chartName,
  chartTitle,
}: IAttendanceChartProps): JSX.Element {
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
      foreColor: '#585757',
      locales: [
        {
          name: 'pt',
          options: {
            months: [
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ],
            shortMonths: [
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ag',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            days: [
              'Domingo',
              'Segunda-feira',
              'Terça-feira',
              'Quarta-feira',
              'Quinta-feira',
              'Sexta-feira',
              'Sábado',
            ],
            shortDays: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Sx', 'Sa'],
            toolbar: {
              exportToSVG: 'Baixar SVG',
              exportToPNG: 'Baixar PNG',
              exportToCSV: 'Baixar CSV',
              selection: 'Selecionar',
              selectionZoom: 'Zoom: Selecionar',
              zoomIn: 'Zoom: Aumentar',
              zoomOut: 'Zoom: Diminuir',
              pan: 'Deslocamento',
              reset: 'Redefinir',
            },
          },
        },
      ],
      defaultLocale: 'pt',
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        color: '#383636',
      },
      axisTicks: {
        color: '#383636',
      },
      categories: dateRange,
    },
    fill: {
      opacity: 0.6,
      type: 'gradient',
      gradient: {
        shade: 'dark',
        opacityFrom: 1,
        opacityTo: 1,
      },
    },
  };

  const series = [
    {
      name: chartName,
      data,
    },
  ];

  return (
    <Container>
      <TitleGraphic>{chartTitle}</TitleGraphic>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </Container>
  );
}
