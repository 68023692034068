import { format, isBefore } from 'date-fns';
import { useCallback, useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import { gql, useSubscription } from '@apollo/client';
import api from '../../../../../services/api';
import { useAuth } from '../../../../../hooks/auth';

import { ReactComponent as PinSymbol } from '../../../../../assets/icons/pin-symbol.svg';

import { formatAmount } from '../../../../../utils/formatAmount';
import { IActivitiesInADayDTO } from '../../dtos/IActivitiesInADayDTO';
import { IActivityCSDTO } from '../../dtos/IActivityCSDTO';
import { ModalUpdateActivity } from '../../ModalUpdateActivity';

import {
  Content,
  ScheduleLineContainer,
  IconContainer,
  LineTableContent,
  InfosTasksContainer,
  HourTypeCloserContainer,
  HourNameActivity,
  IntervalTime,
  StatusTaskStageContainer,
  StatusConcludeTask,
  ButtonConcludeTask,
  StatusButton,
  InfosReminderContainer,
  HourTypeCSReminderContainer,
  LineTableReminderContent,
  ScheduleLineReminderContainer,
  StatusReminderStageContainer,
} from './styles';

interface VisualizationCalendarProps {
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (activity: IActivitiesInADayDTO) => void;
}

export function VisualizationCalendar({
  activitiesInADay,
  setActivitiesInADay,
}: VisualizationCalendarProps): JSX.Element {
  const { user } = useAuth();

  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<IActivityCSDTO>(
    {} as IActivityCSDTO,
  );

  const [updatedStatusActivity, setUpdatedStatusActivity] = useState({
    id: '',
    situation: '',
  });

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback((activity: IActivityCSDTO) => {
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  }, []);

  const REFRESH_ACTIVITIES_CS = gql`
    subscription ListAllActivitiesCS {
      listAllActivitiesCS {
        quantity_activities
        quantity_cases
        formatted_amount
        activities {
          hour
          activities {
            id
            name
            type
            time_init
            time_end
            timestamp
            hubspot_deal_id
            dealname
            closer_name
            user_name
            amount
            proposal_amount
            stage_deal
            stage_ticket
            valid_contact
            operation_number
            observation
            situation
            checklist_concluded
            closer_id
          }
        }
        providences {
          id
          name
          type
          time_init
          time_end
          timestamp
          hubspot_deal_id
          dealname
          closer_name
          user_name
          amount
          proposal_amount
          stage_deal
          stage_ticket
          valid_contact
          operation_number
          observation
          situation
          checklist_concluded
          closer_id
        }
        reminders {
          id
          name
          type
          time_init
          time_end
          timestamp
          hubspot_deal_id
          dealname
          closer_name
          user_name
          amount
          proposal_amount
          stage_deal
          stage_ticket
          valid_contact
          operation_number
          observation
          situation
          checklist_concluded
          closer_id
        }
      }
    }
  `;

  const refresh = useSubscription(REFRESH_ACTIVITIES_CS);

  useEffect(() => {
    if (
      refresh.data &&
      (user.profile === 'Customer Success' ||
        user.profile === 'Customer Success Calculator')
    ) {
      api
        .get(
          `/schedule-cs?start_date=${format(
            new Date(),
            'yyyy-MM-dd',
          )}&end_date&user_id=${user.id}`,
        )
        .then(response => {
          setActivitiesInADay(response.data);
        });
    }
  }, [refresh, setActivitiesInADay, user]);

  const handleUpdateListAcitivity = useCallback(async () => {
    api
      .get(
        `/schedule-cs?start_date=${format(
          new Date(),
          'yyyy-MM-dd',
        )}&end_date&user_id=${user.id}`,
      )
      .then(response => {
        setActivitiesInADay(response.data);
      });
  }, [setActivitiesInADay, user.id]);

  const handleChangeActivityStatus = useCallback(
    async (activity: IActivityCSDTO) => {
      try {
        activity.situation =
          activity.situation === 'Concluída' ? 'Pendente' : 'Concluída';

        setUpdatedStatusActivity({
          id: '',
          situation: '',
        });

        if (activity.time_init) {
          await api.patch('/schedule-cs', {
            id: activity.id,
            user_id: user.id,
            situation: activity.situation,
          });
        } else {
          await api.patch('/schedule-cs/providence', {
            providence_id: activity.id,
            situation: activity.situation,
          });
        }

        toast.success('Atividade atualizada com sucesso!');
      } catch {
        toast.error('Falha ao atualizar a tarefa!');
      }
    },
    [user.id],
  );

  return (
    <Content>
      {activitiesInADay.reminders &&
        activitiesInADay.reminders.map(reminder => (
          <ScheduleLineReminderContainer
            moreThanOne={activitiesInADay.reminders.length > 1}
          >
            <IconContainer>
              <PinSymbol
                style={{ width: '20px', height: '20px', fill: '#8696A0' }}
              />
            </IconContainer>
            <LineTableReminderContent
              moreThanOne={activitiesInADay.reminders.length > 1}
            >
              <InfosReminderContainer
                key={reminder.id}
                isBefore={
                  reminder.type !== 'Reunião'
                    ? reminder.time_init
                      ? verifyIsBeforeNow(reminder.time_init)
                      : verifyIsBeforeNow(reminder.timestamp)
                    : false
                }
                statusTask={
                  reminder.type !== 'Reunião' ? reminder.situation : ''
                }
                checklist_concluded={
                  reminder.checklist_concluded &&
                  Number(reminder.checklist_concluded) > 0
                }
                moreThanOne={activitiesInADay.reminders.length > 1}
              >
                <HourTypeCSReminderContainer
                  isBefore={
                    reminder.type !== 'Reunião'
                      ? reminder.time_init
                        ? verifyIsBeforeNow(reminder.time_init)
                        : verifyIsBeforeNow(reminder.timestamp)
                      : false
                  }
                  statusTask={
                    reminder.type !== 'Reunião' ? reminder.situation : ''
                  }
                  checklist_concluded={
                    reminder.checklist_concluded &&
                    Number(reminder.checklist_concluded) > 0
                  }
                  onClick={() => handleOpenActivityModal(reminder)}
                >
                  <HourNameActivity>
                    <p>{reminder.type || reminder.name}</p>
                  </HourNameActivity>
                  <>
                    <p className="credor">Credor: {reminder.dealname}</p>
                    <p className="credor">
                      Valor: {formatAmount(Number(reminder.amount))}
                    </p>
                  </>
                </HourTypeCSReminderContainer>

                {reminder.type !== 'Reunião' && (
                  <StatusReminderStageContainer
                    user_profile={user.profile}
                    isBefore={verifyIsBeforeNow(reminder.time_init)}
                    statusTask={reminder.situation}
                    moreThanOne={activitiesInADay.reminders.length > 1}
                  >
                    <p id="stage">{reminder.stage_deal}</p>
                    <StatusConcludeTask>
                      <StatusButton
                        id="status"
                        type="button"
                        disabled={user.profile === 'Gestor Customer Success'}
                        onClick={() =>
                          setUpdatedStatusActivity({
                            id:
                              updatedStatusActivity.id === reminder.id
                                ? ''
                                : reminder.id,
                            situation:
                              updatedStatusActivity.id === reminder.situation
                                ? ''
                                : reminder.situation,
                          })
                        }
                      >
                        {reminder.situation}
                      </StatusButton>
                      {updatedStatusActivity.id === reminder.id && (
                        <ButtonConcludeTask
                          onClick={() => handleChangeActivityStatus(reminder)}
                          disabled={user.profile === 'Gestor Customer Success'}
                        >
                          Concluir
                        </ButtonConcludeTask>
                      )}
                    </StatusConcludeTask>
                  </StatusReminderStageContainer>
                )}
              </InfosReminderContainer>
            </LineTableReminderContent>
          </ScheduleLineReminderContainer>
        ))}

      {activitiesInADay.activities &&
        activitiesInADay.activities.map(activityInADay => (
          <ScheduleLineContainer
            key={String(activityInADay.hour)}
            moreThanOne={activityInADay.activities.length > 1}
          >
            <IconContainer>
              <p>{format(new Date(activityInADay.hour), 'HH:mm')}</p>
            </IconContainer>

            <LineTableContent
              moreThanOne={activityInADay.activities.length > 1}
            >
              {activityInADay.activities &&
                Object.keys(activityInADay.activities).length > 0 &&
                activityInADay.activities.map(activity => (
                  <InfosTasksContainer
                    key={activity.id}
                    isBefore={
                      activity.type !== 'Reunião'
                        ? activity.time_init
                          ? verifyIsBeforeNow(activity.time_init)
                          : verifyIsBeforeNow(activity.timestamp)
                        : false
                    }
                    statusTask={
                      activity.type !== 'Reunião' ? activity.situation : ''
                    }
                    checklist_concluded={
                      activity.checklist_concluded &&
                      Number(activity.checklist_concluded) > 0
                    }
                    moreThanOne={activityInADay.activities.length > 1}
                  >
                    <HourTypeCloserContainer
                      isBefore={
                        activity.type !== 'Reunião'
                          ? activity.time_init
                            ? verifyIsBeforeNow(activity.time_init)
                            : verifyIsBeforeNow(activity.timestamp)
                          : false
                      }
                      statusTask={
                        activity.type !== 'Reunião' ? activity.situation : ''
                      }
                      checklist_concluded={
                        activity.checklist_concluded &&
                        Number(activity.checklist_concluded) > 0
                      }
                      onClick={() => handleOpenActivityModal(activity)}
                    >
                      <HourNameActivity>
                        {activity.time_init ? (
                          <IntervalTime>
                            {format(new Date(activity.time_init), 'HH:mm')} -{' '}
                            {format(new Date(activity.time_end), 'HH:mm')}
                          </IntervalTime>
                        ) : (
                          <IntervalTime>
                            {format(new Date(activity.timestamp), 'HH:mm')}
                          </IntervalTime>
                        )}

                        <p>{activity.type || activity.name}</p>
                      </HourNameActivity>
                      {activity.type !== 'Reunião' ? (
                        <>
                          <p className="credor">Credor: {activity.dealname}</p>
                          <p className="credor">
                            Valor: {formatAmount(Number(activity.amount))}
                          </p>
                        </>
                      ) : (
                        <p className="credor">{activity.title}</p>
                      )}
                    </HourTypeCloserContainer>

                    {activity.type !== 'Reunião' && (
                      <StatusTaskStageContainer
                        statusTask={activity.situation}
                        isBefore={verifyIsBeforeNow(activity.time_init)}
                        moreThanOne={activityInADay.activities.length > 1}
                      >
                        <p id="stage">{activity.stage_deal}</p>
                        <StatusConcludeTask>
                          <StatusButton
                            id="status"
                            type="button"
                            disabled={
                              user.profile === 'Gestor Customer Success'
                            }
                            onClick={() =>
                              setUpdatedStatusActivity({
                                id:
                                  updatedStatusActivity.id === activity.id
                                    ? ''
                                    : activity.id,
                                situation:
                                  updatedStatusActivity.id ===
                                  activity.situation
                                    ? ''
                                    : activity.situation,
                              })
                            }
                          >
                            {activity.situation}
                          </StatusButton>
                          {updatedStatusActivity.id === activity.id && (
                            <ButtonConcludeTask
                              onClick={() =>
                                handleChangeActivityStatus(activity)
                              }
                              disabled={
                                user.profile === 'Gestor Customer Success'
                              }
                            >
                              Concluir
                            </ButtonConcludeTask>
                          )}
                        </StatusConcludeTask>
                      </StatusTaskStageContainer>
                    )}
                  </InfosTasksContainer>
                ))}
            </LineTableContent>
          </ScheduleLineContainer>
        ))}

      {selectedActivity && (
        <ModalUpdateActivity
          isOpen={openActivityModal}
          isProvidence={false}
          onRequestClose={handleCloseActivityModal}
          activity={selectedActivity}
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
          updateListAcitivity={handleUpdateListAcitivity}
        />
      )}
    </Content>
  );
}
