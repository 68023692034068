import axios from 'axios';
import { ChangeEvent, useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal';
import {
  DeleteButton,
  EditButton,
  Input,
  SaveButton,
  Select,
  SelectCell,
  SelectOption,
  SelectOptions,
  TableCell,
  TableRow,
} from './styles';

interface IWppConfigTableRowProps {
  name: string;
  phone: string;
  id: string;
  integration_id: string;
  connection_source: string;
  getSunshinePhones: () => void;
}

export function WppConfigTableRow({
  connection_source,
  integration_id,
  name,
  phone,
  getSunshinePhones,
  id,
}: IWppConfigTableRowProps): JSX.Element {
  const [editorMode, setEditorMode] = useState(false);
  const [newName, setNewName] = useState(name);
  const [newPhone, setNewPhone] = useState(phone);
  const [newIntegrationId, setNewIntegrationId] = useState(integration_id);
  const [newConnectionSource, setNewConnectionSource] =
    useState(connection_source);
  const [enableSelect, setEnableSelect] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const deletePhone = useCallback(async () => {
    try {
      const { status } = await axios.delete(
        `${process.env.REACT_APP_MARKETING_URL}/sunshine/phone/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      Swal.fire(`${status}!`, 'Successfully deleted phone!', 'success');

      getSunshinePhones();
    } catch (error) {
      Swal.fire(
        `${error.response.status}!`,
        error.response.body.message,
        'error',
      );
    }

    setEditorMode(false);
  }, [getSunshinePhones, id]);

  const editorModeSwitcher = useCallback(() => {
    setEditorMode(!editorMode);
  }, [editorMode]);

  const editName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  }, []);

  const editPhone = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewPhone(event.target.value);
  }, []);

  const editIntegrationId = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewIntegrationId(event.target.value);
    },
    [],
  );

  const editConnectionSource = useCallback(
    (connectionSource: string) => {
      setNewConnectionSource(connectionSource);

      setEnableSelect(!enableSelect);
    },
    [enableSelect],
  );

  const changeSelectStatus = useCallback(() => {
    setEnableSelect(!enableSelect);
  }, [enableSelect]);

  const updatePhone = useCallback(async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_MARKETING_URL}/sunshine/phone/${id}`,
        {
          name: newName,
          phone: newPhone,
          integration_id: newIntegrationId,
          connection_source: newConnectionSource,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      Swal.fire('Success!', 'Successfully updated Sunshine phone!', 'success');

      getSunshinePhones();
    } catch (error) {
      Swal.fire(
        `${error.response.status}!`,
        error.response.data.message,
        'error',
      );
    }

    setEditorMode(false);
    setEnableSelect(false);
  }, [
    getSunshinePhones,
    id,
    newConnectionSource,
    newIntegrationId,
    newName,
    newPhone,
  ]);

  const openModalSwitcher = useCallback(() => {
    setOpenConfirmationModal(!openConfirmationModal);
  }, [openConfirmationModal]);

  return editorMode ? (
    <TableRow className="tr-body">
      <TableCell width="17.1875rem">
        <Input width="14.4375rem" defaultValue={name} onChange={editName} />
      </TableCell>

      <TableCell width="15.3125rem">
        <Input width="11.4375rem" defaultValue={phone} onChange={editPhone} />
      </TableCell>

      <TableCell width="17.375rem">
        <Input
          width="15.25rem"
          defaultValue={integration_id}
          onChange={editIntegrationId}
        />
      </TableCell>

      <SelectCell>
        <Select disabled={enableSelect} onClick={changeSelectStatus}>
          {newConnectionSource}
        </Select>

        <SelectOptions enabled={enableSelect}>
          <SelectOption
            onClick={() => editConnectionSource('EMAIL')}
            className="first-child"
          >
            EMAIL
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('FACEBOOK')}>
            FACEBOOK
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('GOOGLE')}>
            GOOGLE
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('INDICAÇÃO (TAG)')}>
            INDICAÇÃO (TAG)
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('SITE')}>
            SITE
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('SMS')}>
            SMS
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('WPP')}>
            WPP
          </SelectOption>

          <SelectOption
            onClick={() => editConnectionSource('RCS')}
            className="last-child"
          >
            RCS
          </SelectOption>
          <SelectOption
            onClick={() => editConnectionSource('BING')}
            className="last-child"
          >
            BING
          </SelectOption>
        </SelectOptions>
      </SelectCell>

      <TableCell width="6.5rem">
        <SaveButton onClick={updatePhone}>Concluir</SaveButton>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow className="tr-body">
      <TableCell width="17.1875rem">{name}</TableCell>

      <TableCell width="15.3125rem">{phone}</TableCell>

      <TableCell width="17.375rem">{integration_id}</TableCell>

      <TableCell width="15rem">{connection_source}</TableCell>

      <TableCell width="6.5rem">
        <DeleteButton onClick={openModalSwitcher} />
        <EditButton onClick={editorModeSwitcher} />
      </TableCell>

      <DeleteConfirmationModal
        is_open={openConfirmationModal}
        onConfirm={deletePhone}
        onRequestClose={openModalSwitcher}
      />
    </TableRow>
  );
}
