import styled from 'styled-components';

export const ModalQueues = {
  content: {
    width: '39rem',
    minHeight: '10.75rem',
    height: '35.625rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
};

export const ModalHeader = styled.div`
  width: 100%;
  height: 3.5rem;
  text-align: center;

  background: #cfdef3;
  border-radius: 0.625rem 0.625rem 0 0;

  display: grid;
  grid-template-columns: 2.5fr 3fr 0.27fr;
  align-items: center;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    color: #05233e;
  }
`;

export const CloseModalButton = styled.button`
  background-color: transparent;
  color: #1665d8;
  border: 0;
`;

export const ModalBody = styled.div`
  width: 100%;
  height: 7.25rem;
  //height: fit-content;
  //padding: 2.625rem 1.875rem;
  display: grid;
  //grid-template-columns: 9.5rem 9.5rem 9.5rem;
  align-items: center;
  //gap: 2.625rem;
  justify-content: center;
`;

export const StatusGraphBox = styled.div`
  width: 9.5rem;
  height: 5.25rem;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  background: #f4f4f5;
  box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
`;

export const TextStatusGraphBox = styled.div`
  width: 50%;
  justify-content: center;
  display: grid;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  color: #6d6d6d;

  b {
    font-weight: bold;
    color: #3d3d3d;
  }
`;

export const HoursContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PeriodContainer = styled.div`
  p {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #efefef;
    width: 100%;
    height: 1.875rem;

    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: #05233e;

    margin-bottom: 0.875rem;
  }

  margin: 1.25rem 0 1rem 0;
`;

export const Hour = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 4rem);
  grid-gap: 0.688rem;

  .btnHour {
    border: none;
    border-radius: 0.25rem;

    width: 4rem;
    height: 1.875rem;

    background: #efefef;

    font-family: Poppins;
    font-weight: 500;
    font-size: 1rem;
    color: #3d3d3d;

    cursor: pointer;
    transition: 0.5s;

    text-align: center;

    &:hover,
    &:focus {
      background-color: #1665d8;
      color: #fff;
    }
  }
`;
