import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import {
  FileContainer,
  TitleContainer,
  FileInputContainer,
  SendFileContainer,
  SendButtonContainer,
  Separator,
  FilesHistoryContainer,
  Table,
  DivTable,
  ContainerTable,
  Thead,
  TitleHistory,
  DivSeparator,
  Divisor,
  Tbody,
  Celula,
  ContainerTitle,
  PaginationDiv,
  PageButton,
} from './styles';
import apiTrfs from '../../../services/apiTrfs';
import { Title } from '../../../components/Styles/Title';
import { Body } from '../../../components/Styles/Body';
import { NavigationBar } from '../../../components/NavigationBar';
import { Container } from '../../../components/Container';

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface IProcessesHistory {
  id: number;
  filename: string;
  formattedFileName: string;
  upload_time: string;
  row_amount: number;
  file_url: string;
}

const Processes: React.FC = () => {
  const [file, setFile] = useState<FileProps>({} as FileProps);

  const [filesHistory, setFilesHistory] = useState<IProcessesHistory[]>([]);

  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState([]);

  useEffect(() => {
    apiTrfs
      .get<IProcessesHistory[]>('/process_search/processes/history?offset=1')
      .then(response => {
        setFilesHistory(response.data);
      });

    apiTrfs.get('/process_search/processes/history/total').then(response => {
      const pages: number[] = [];

      for (let index = 1; index <= Math.ceil(response.data / 10); index++) {
        pages.push(index);
      }

      setTotalPages(pages);
    });
  }, [file]);

  const handleDownload = useCallback(async (url: string) => {
    const index = url.indexOf('- ');

    const fileName = url.substr(index + 2);

    const response = await apiTrfs.get(url, {
      responseType: 'blob',
    });

    saveAs(response.data, fileName);

    Swal.fire('Seu download está iniciando', 'Por favor aguarde', 'success');
  }, []);

  const submitFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const getFile =
      event.target.files === null ? ({} as File) : event.target.files[0];

    const newFile: FileProps = {
      file: getFile,
      name: getFile.name,
      readableSize: getFile.size.toString(),
    };

    setFile(newFile);

    event.target.value = null;
  }, []);

  const handleChangePagination = useCallback(
    async toPage => {
      if (toPage === -1) {
        if (page !== 1) {
          const uploadHistory = await apiTrfs.get(
            `/process_search/processes/history?offset=${page - 1}`,
          );

          setPage(prevPage => prevPage - 1);

          setFilesHistory(uploadHistory.data);
        }
      } else if (toPage === 0) {
        if (page !== totalPages.length) {
          const uploadHistory = await apiTrfs.get(
            `/process_search/processes/history?offset=${page + 1}`,
          );

          setPage(prevPage => prevPage + 1);

          setFilesHistory(uploadHistory.data);
        }
      } else if (page !== toPage) {
        const uploadHistory = await apiTrfs.get(
          `/process_search/processes/history?offset=${toPage}`,
        );

        setPage(toPage);

        setFilesHistory(uploadHistory.data);
      }
    },
    [page, totalPages],
  );

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    try {
      Swal.fire({
        title: 'Seu arquivo está sendo processado',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCloseButton: false,
        showConfirmButton: false,
      });

      data.append('file', file.file);

      await apiTrfs.post('/process_search', data);

      setFile({} as FileProps);

      Swal.close();

      Swal.fire('Arquivo recebido com sucesso', '', 'success');
    } catch (error) {
      Swal.fire(
        'Erro ao enviar o arquivo , tente novamente mais tarde.',
        '',
        'error',
      );
    }
  }, [file]);

  return (
    <Container>
      <NavigationBar
        past_screens="Processsamento > "
        active_screen="Bases TRF"
      />

      <Body>
        <TitleContainer>
          <Title>Processamento de arquivos - Bases TRF</Title>
        </TitleContainer>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <FileContainer>
          <FileInputContainer>
            <SendFileContainer>
              <strong>Arquivo:</strong>
              <div>
                <label htmlFor="file">Selecione um arquivo</label>
                <input
                  id="file"
                  type="file"
                  name="file"
                  onChange={submitFile}
                />
                {file && file.name ? (
                  <span>{file.name}</span>
                ) : (
                  <span>Nenhum arquivo selecionado</span>
                )}
              </div>
            </SendFileContainer>

            <SendButtonContainer>
              <button type="button" onClick={() => handleUpload()}>
                Enviar
              </button>
              <span>Arquivos serão processados automaticamente</span>
            </SendButtonContainer>
          </FileInputContainer>
        </FileContainer>
        <FilesHistoryContainer>
          <TitleHistory>
            <h2>Procesamento anteriores</h2>
          </TitleHistory>
        </FilesHistoryContainer>
        <DivTable>
          <ContainerTable>
            <Table>
              <Thead>
                <tr>
                  <th>
                    <Celula>
                      <ContainerTitle>Data de importação</ContainerTitle>
                      <Divisor />
                    </Celula>
                  </th>
                  <th>
                    <Celula>
                      <ContainerTitle>Nome do arquivo CSV</ContainerTitle>
                      <Divisor />
                    </Celula>
                  </th>
                  <th>
                    <Celula>
                      <ContainerTitle>Quantidade de Linhas</ContainerTitle>
                    </Celula>
                  </th>
                </tr>
              </Thead>
              <Tbody>
                {filesHistory.length > 0 &&
                  filesHistory.map(fileHistory => (
                    <tr key={fileHistory.id}>
                      <td>{fileHistory.upload_time}</td>
                      <td onClick={() => handleDownload(fileHistory.file_url)}>
                        {fileHistory.formattedFileName}
                      </td>
                      <td>{fileHistory.row_amount}</td>
                    </tr>
                  ))}
              </Tbody>
            </Table>
          </ContainerTable>
        </DivTable>

        {totalPages.length > 0 && totalPages.length <= 5 ? (
          <PaginationDiv>
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(-1);
              }}
            >
              {'<'}
            </PageButton>
            {totalPages.length > 0
              ? totalPages.map(row => (
                  <PageButton
                    active={false}
                    onClick={() => {
                      handleChangePagination(row);
                    }}
                  >
                    {row}
                  </PageButton>
                ))
              : ''}
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(0);
              }}
            >
              {'>'}
            </PageButton>
          </PaginationDiv>
        ) : totalPages.length > 5 ? (
          <PaginationDiv>
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(-1);
              }}
            >
              {'<'}
            </PageButton>
            {totalPages.length > 0
              ? totalPages.map(row =>
                  page <= 4 && row <= 7 ? (
                    <PageButton
                      active={false}
                      onClick={() => {
                        handleChangePagination(row);
                      }}
                    >
                      {row}
                    </PageButton>
                  ) : page > 4 && row >= page - 3 && row <= page + 3 ? (
                    <PageButton
                      active={false}
                      onClick={() => {
                        handleChangePagination(row);
                      }}
                    >
                      {row}
                    </PageButton>
                  ) : (
                    ''
                  ),
                )
              : ''}
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(0);
              }}
            >
              {'>'}
            </PageButton>
          </PaginationDiv>
        ) : (
          ''
        )}
      </Body>
    </Container>
  );
};

export default Processes;
