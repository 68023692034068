import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';

import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Separator } from '../../../components/Separator';
import { Body } from '../../../components/Styles/Body';

import { Title } from '../../../components/Title';
import { apiByState } from '../../../services/apiTjs';

import { InlineSeparator } from '../components/ExampleFileTRF3Pje/styles';
import { TimeRemainingTJS } from '../components/TimeRemainingTJS';
import {
  RegionAndProcessContainer,
  RegionSelectTitleContainer,
  RegionContentContainer,
  ProcessFileContainer,
  RegionContainer,
  SelectFileContainer,
  TextContainer,
  SendFileContainer,
  InputFileContainer,
  SendFileButtonContainer,
  ModelFileContainer,
  FilesHistoryContainer,
  TitleHistory,
  ButtonContainer,
  ClearHitoryButton,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
  ButtonZip,
  Button,
  Line,
} from './styles';
import { locations } from '../../../utils/states';
import { cleanString } from '../../../utils/cleanString';

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface ILocation {
  id: number;
  name: string;
  route: string;
  initials: string;
  region: string;
}

interface IFileTemplate {
  id: number;
  court: string;
  index: number;
  column_name: string;
  column_example: string;
}

interface FileHistory {
  id: number;
  name: string;
  court: string;
  lines: string;
  processed: string;
  created_at: string;
  created_at_brazil: string;
  file_url: string;
  zip_url: string;
  zip_name: string;
}

export const TJS: React.FC = () => {
  const [states, setStates] = useState<ILocation[]>([]);

  const [state, setState] = useState<ILocation>({} as ILocation);

  const [court, setCourt] = useState<string>('');

  const [filesHistory, setFilesHistory] = useState<FileHistory[]>([]);

  const [fileTemplateByState, setFileTemplateByState] = useState<
    IFileTemplate[]
  >([]);

  const [file, setFile] = useState<FileProps>({} as FileProps);

  const handleSelectRegion = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      const statesByRegion = locations.filter(
        location => location.region === value,
      );

      setStates(statesByRegion);
    },
    [],
  );

  const handleSelectState = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      const location = locations.find(
        findLocation => findLocation.initials === value,
      );

      setState(location);

      setCourt(`TJ${location.initials}`);
    },
    [],
  );

  useEffect(() => {
    if (state.name) {
      const apiState = apiByState(cleanString(state.route));

      apiState.get<IFileTemplate[]>('files/file_templates').then(response => {
        const fileTemplates = response.data.filter(
          findFileTemplate => findFileTemplate.court === court,
        );

        setFileTemplateByState(fileTemplates);
      });

      apiState.get(`files/process_files?court=${court}`).then(response => {
        setFilesHistory(response.data);
      });
    }
  }, [state, court]);

  const handleLoadFilesHistory = useCallback(async () => {
    const apiState = apiByState(cleanString(state.route));

    const response = await apiState.get<FileHistory[]>(
      `files/process_files?court=${court}`,
    );

    setFilesHistory(response.data);
  }, [court, state]);

  const handleSubmitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: FileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };

      setFile(newFile);

      event.target.value = null;
    },
    [],
  );

  const handleUploadFile = useCallback(async () => {
    const data = new FormData();

    const apiState = apiByState(cleanString(state.route));

    try {
      data.append('file', file.file);

      await apiState.post(`tjs/${state.initials.toLowerCase()}`, data);

      setFile({} as FileProps);

      await handleLoadFilesHistory();

      Swal.fire('Sucesso!', 'Requisição recebida com sucesso', 'success');
    } catch (error) {
      Swal.fire('Erro!', 'Falha ao enviar requisição.', 'error');
    }
  }, [file, state, handleLoadFilesHistory]);

  const handleDownload = useCallback(
    async (url: string, file_name: string) => {
      const apiState = apiByState(cleanString(state.route));

      const response = await apiState.get(url, {
        responseType: 'blob',
      });

      saveAs(response.data, file_name);
    },
    [state],
  );

  const handleZipPdfs = useCallback(
    async (id: number, url: string, zip_name: string) => {
      try {
        const apiState = apiByState(cleanString(state.route));

        await apiState.post(`/files/zip/${id}`, {
          state: court,
        });

        handleDownload(url, zip_name);
      } catch (error) {
        Swal.fire('Erro!', 'Falha ao enviar requisição.', 'error');
      }
    },
    [handleDownload, court, state],
  );

  return (
    <Container>
      <NavigationBar past_screens="Processsamento > " active_screen="TJ's" />

      <Body>
        <Title name="Processamento de arquivos - Ofícios TJ's" />

        <RegionAndProcessContainer>
          <RegionContainer>
            <RegionSelectTitleContainer>
              Selecione a região e o estado respectivo:
            </RegionSelectTitleContainer>
            <RegionContentContainer>
              <select defaultValue="Região" onChange={handleSelectRegion}>
                <option value="Região">Região</option>
                <option value="Norte">Norte</option>
                <option value="Nordeste">Nordeste</option>
                <option value="Sudeste">Sudeste</option>
                <option value="Sul">Sul</option>
                <option value="Centro-Oeste">Centro-Oeste</option>
              </select>
              <select defaultValue="Estado" onChange={handleSelectState}>
                <option value="Estado">Estado</option>
                {states.map(mapState => (
                  <option key={mapState.id} value={mapState.initials}>
                    {mapState.name}
                  </option>
                ))}
              </select>
            </RegionContentContainer>
          </RegionContainer>

          <ProcessFileContainer>
            <SelectFileContainer>
              <TextContainer>
                <strong>Arquivo:</strong>
              </TextContainer>
              <SendFileContainer>
                <InputFileContainer>
                  <label htmlFor="file">Selecione um arquivo</label>
                  <input
                    id="file"
                    type="file"
                    name="file"
                    onChange={handleSubmitFile}
                  />
                  {file && file.name ? (
                    <span>{file.name}</span>
                  ) : (
                    <span>Nenhum arquivo selecionado</span>
                  )}
                </InputFileContainer>

                <SendFileButtonContainer>
                  <button type="button" onClick={handleUploadFile}>
                    Enviar
                  </button>
                  <span>Arquivos serão processados automaticamente</span>
                </SendFileButtonContainer>
              </SendFileContainer>
            </SelectFileContainer>

            <ModelFileContainer>
              <strong>Modelo de arquivo:</strong>
              <Line>
                {fileTemplateByState.length > 0 &&
                  fileTemplateByState.map(fileT => {
                    if (fileT.index === 1) return <p>{fileT.column_name}</p>;
                    return (
                      <>
                        <InlineSeparator>|</InlineSeparator>
                        <p>{fileT.column_name}</p>
                      </>
                    );
                  })}
              </Line>
              <Separator />
              <Line>
                {fileTemplateByState.length > 0 &&
                  fileTemplateByState.map(fileT => {
                    if (fileT.index === 1) return <p>{fileT.column_example}</p>;
                    return (
                      <>
                        <InlineSeparator>|</InlineSeparator>
                        <p>{fileT.column_example}</p>
                      </>
                    );
                  })}
              </Line>
            </ModelFileContainer>
          </ProcessFileContainer>
        </RegionAndProcessContainer>

        <FilesHistoryContainer>
          <TitleHistory>Processamento anteriores</TitleHistory>
          <ButtonContainer>
            <ClearHitoryButton type="button" onClick={handleLoadFilesHistory}>
              Atualizar listagem
            </ClearHitoryButton>
          </ButtonContainer>
        </FilesHistoryContainer>

        <Table>
          <Thead>
            <TableRowHead>
              <CellHeader>
                <TitleTable>Data de importação</TitleTable>
                <Divisor />
              </CellHeader>
              <CellHeader>
                <TitleTable>Nome do arquivo CSV</TitleTable>
                <Divisor />
              </CellHeader>
              <CellHeader>
                <TitleTable>Zip PDFs</TitleTable>
                <Divisor />
              </CellHeader>
              <CellHeader>
                <TitleTable>Situação</TitleTable>

                <Divisor />
              </CellHeader>
              <CellHeader>
                <TitleTable>Ações</TitleTable>
              </CellHeader>
            </TableRowHead>
          </Thead>
          <Tbody>
            {filesHistory.length > 0 &&
              filesHistory.map(currentItem => (
                <>
                  <TableRowBody key={currentItem.id} item-id={currentItem.id}>
                    <CellBody>{currentItem.created_at_brazil}</CellBody>
                    <CellBody
                      onClick={() =>
                        handleDownload(currentItem.file_url, currentItem.name)
                      }
                    >
                      {currentItem.name}
                    </CellBody>
                    <CellBody>
                      <ButtonZip
                        type="button"
                        onClick={() =>
                          handleZipPdfs(
                            currentItem.id,
                            currentItem.zip_url,
                            currentItem.zip_name,
                          )
                        }
                        disabled={currentItem.processed === '0'}
                      >
                        {currentItem.processed !== '0'
                          ? 'PDFs da consulta'
                          : 'Sem PDF'}
                      </ButtonZip>
                    </CellBody>
                    <CellBody>
                      <TimeRemainingTJS
                        processed={Number(currentItem.processed)}
                        lines={Number(currentItem.lines)}
                        createdAt={currentItem.created_at}
                      />
                      (Processado {currentItem.processed} de {currentItem.lines}
                      )
                    </CellBody>
                    <CellBody>
                      <Button type="button">Reprocessar</Button>
                    </CellBody>
                  </TableRowBody>
                </>
              ))}
          </Tbody>
        </Table>
      </Body>
    </Container>
  );
};
