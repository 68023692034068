import React, { ChangeEvent } from 'react';

import { Container, Input, Checkmark, Text } from './styles';

interface ContentProps {
  isPermission?: boolean;
  id: string;
  name: string;
  checked?: boolean;
  change: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputCheckbox: React.FC<ContentProps> = ({
  isPermission,
  id,
  name,
  change,
  checked,
}) => {
  return (
    <Container isPermission={isPermission}>
      <Input
        type="checkbox"
        value={id}
        id={id}
        onChange={change}
        name={name}
        checked={checked}
      />
      <Checkmark className="checkmark" />
      <Text isPermission={isPermission}>{name}</Text>
    </Container>
  );
};
