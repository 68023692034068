import { useCallback } from 'react';
import Modal from 'react-modal';
import {
  AffirmativeButton,
  ButtonsDiv,
  ConfirmationMessage,
  DeleteConfirmationModalContainer,
  NegativeButton,
} from './styles';

interface IDeleteConfirmationModalProps {
  is_open: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
}

export function DeleteConfirmationModal({
  is_open,
  onRequestClose,
  onConfirm,
}: IDeleteConfirmationModalProps): JSX.Element {
  const handleConfirmButton = useCallback(() => {
    onConfirm();

    onRequestClose();
  }, [onConfirm, onRequestClose]);

  return (
    <Modal
      isOpen={is_open}
      onRequestClose={onRequestClose}
      style={DeleteConfirmationModalContainer}
    >
      <ConfirmationMessage>Tem certeza que deseja excluir?</ConfirmationMessage>

      <ButtonsDiv>
        <NegativeButton onClick={onRequestClose} className="button">
          Não
        </NegativeButton>

        <AffirmativeButton onClick={handleConfirmButton} className="button">
          Sim
        </AffirmativeButton>
      </ButtonsDiv>
    </Modal>
  );
}
