import { add, sub } from 'date-fns';
import { useCallback } from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow_right.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_blue.svg';
import { DayPicker } from '../DayPicker';

import {
  DaySelectedContainer,
  PreviousButton,
  ContainerInput,
  ContainerInputDate,
  NextButton,
} from './styles';

interface InputDateProps {
  date: Date;
  setDate: (date: Date) => void;
}

export function InputDate({ date, setDate }: InputDateProps): JSX.Element {
  const handleDateInputChange = useCallback(
    (day: Date) => {
      setDate(day);
    },
    [setDate],
  );

  const previousDate = useCallback(() => {
    const newDate = sub(date, {
      days: 1,
    });

    setDate(newDate);
  }, [date, setDate]);

  const nextDate = useCallback(() => {
    const newDate = add(date, {
      days: 1,
    });

    setDate(newDate);
  }, [date, setDate]);

  return (
    <DaySelectedContainer>
      <PreviousButton onClick={previousDate}>
        <ArrowLeftIcon />
      </PreviousButton>
      <ContainerInput>
        <ContainerInputDate>
          <CalendarIcon />
          <DayPicker
            value={date}
            handleChange={day => handleDateInputChange(day)}
          />
        </ContainerInputDate>
      </ContainerInput>
      <NextButton onClick={nextDate}>
        <ArrowRightIcon />
      </NextButton>
    </DaySelectedContainer>
  );
}
