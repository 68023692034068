/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { format } from 'date-fns';
import { ScheduleOptionsButton } from '../../../../../components/ScheduleOptionsButton';

import {
  CloseModalButton,
  Hour,
  HoursContainer,
  ModalBody,
  ModalHeader,
  ModalQueues,
  PeriodContainer,
} from './styles';
import close_modal from '../../../../../assets/icons/close_modal.svg';
import api from '../../../../../services/api';
import { ModalAvailableClosers } from './ModalCloserAvailableHour';

Modal.setAppElement('#root');

interface IPossibleHours {
  hour: Date;
  period: string;
  closers: string[];
}

interface IPeriods {
  morning: IPossibleHours[];
  afternoon: IPossibleHours[];
  evening: IPossibleHours[];
  queue: string;
}

interface IModalHoursAvailableProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalHoursAvailable({
  isOpen,
  onRequestClose,
}: IModalHoursAvailableProps) {
  const [hoursAvailable, setHoursAvailable] = useState<IPeriods>(
    {} as IPeriods,
  );
  const [closers, setClosers] = useState<string[]>();

  const [openClosersModal, setOpenClosersModal] = useState(false);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  useEffect(() => {
    if (isOpen) {
      const newDate = new Date();

      const formattedDate = format(newDate, 'yyyy-MM-dd');

      api
        .get(`schedule/get-hours-available/${formattedDate}`)
        .then(response => {
          setHoursAvailable(response.data);
        });
    }
  }, [isOpen]);

  const handleOpenClosersModal = useCallback((closersAvailable: string[]) => {
    setClosers(closersAvailable);
    setOpenClosersModal(true);
  }, []);

  const handleCloseClosersModal = useCallback(() => {
    setOpenClosersModal(false);
  }, []);

  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalQueues}>
      <ModalHeader>
        <p />
        <p>Horários:</p>
        <CloseModalButton id="closeModal" type="button" onClick={closeModal}>
          <img src={close_modal} alt="Fechar modal" />
        </CloseModalButton>
      </ModalHeader>

      <ModalBody>
        <HoursContainer className="datetime">
          <PeriodContainer>
            <p>Manhã</p>
            <Hour>
              {hoursAvailable.morning &&
                hoursAvailable.morning.map(morning =>
                  morning.closers.length > 0 ? (
                    <ScheduleOptionsButton
                      key={String(morning.hour)}
                      scheduleHour
                      type="button"
                      onClick={() => {
                        handleOpenClosersModal(morning.closers);
                      }}
                    >
                      {formattedTime(morning.hour)}
                    </ScheduleOptionsButton>
                  ) : (
                    ''
                  ),
                )}
            </Hour>
          </PeriodContainer>

          <PeriodContainer>
            <p>Tarde</p>
            <Hour>
              {hoursAvailable.afternoon &&
                hoursAvailable.afternoon.map(afternoon =>
                  afternoon.closers.length > 0 ? (
                    <ScheduleOptionsButton
                      key={String(afternoon.hour)}
                      scheduleHour
                      type="button"
                      onClick={() => {
                        handleOpenClosersModal(afternoon.closers);
                      }}
                    >
                      {formattedTime(afternoon.hour)}
                    </ScheduleOptionsButton>
                  ) : (
                    ''
                  ),
                )}
            </Hour>
          </PeriodContainer>

          <PeriodContainer>
            <p>Noite</p>
            <Hour>
              {hoursAvailable.evening &&
                hoursAvailable.evening.map(evening =>
                  evening.closers.length > 0 ? (
                    <ScheduleOptionsButton
                      key={String(evening.hour)}
                      scheduleHour
                      type="button"
                      onClick={() => {
                        handleOpenClosersModal(evening.closers);
                      }}
                    >
                      {formattedTime(evening.hour)}
                    </ScheduleOptionsButton>
                  ) : (
                    ''
                  ),
                )}
            </Hour>
          </PeriodContainer>
        </HoursContainer>
      </ModalBody>
      <ModalAvailableClosers
        isOpen={openClosersModal}
        onRequestClose={handleCloseClosersModal}
        closers={closers}
      />
    </Modal>
  );
}
