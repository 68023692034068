/* eslint-disable @typescript-eslint/no-unused-vars */
import Loader from 'react-loader-spinner';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';
import { Title } from './Title';

import { StickTable } from './StickTable';
import { HeaderAccompanimentResult } from './HeaderAccompanimentResult';
import { TableAccompanimentHistoryCloser } from './TableAccompanimentHistoryCloser';
import { TableAccompanimentResultCloser } from './TableAccompanimentResultCloser';

import {
  ContentPage,
  ContainerLoader,
  BoxAccompanimentResult,
  ContentAccopanimentResult,
  InputLabelContainer,
} from './styles';
import { useAuth } from '../../hooks/auth';
import { CloserDTO } from '../CloserSettings/dtos/CloserDTO';
import api from '../../services/api';

export interface ICloserPanelData {
  amount: string;
  hubspotDealId: string;
  percentage: string;
  status: string;
  id: string;
}

export interface IMonthlyCloserResult {
  day_production: string;
  monthly_production: string;
  goal: string;
  realized_goal: string;
  projection_goal: string;
  payment: string;
  commission: string;
}

export interface ICloserHistory {
  liquid_conection: number;
  proposal: number;
  negociation: number;
  closed: number;
  baston: number;
  ticket: string;
  conversion?: number;
  goal_conversion?: number;
}

export function ReportCloser(): JSX.Element {
  const { user } = useAuth();

  const [accompanimentButtonSelected, setAccompanimentButtonSelected] =
    useState<string>('Diario');

  const [toogleButtonSelected, setToogleButtonSelected] =
    useState<string>('DIA');

  const [closerPanelData, setCloserPanelData] = useState<ICloserPanelData[]>(
    [] as ICloserPanelData[],
  );

  const defaultHistoryCloser: ICloserHistory = {
    liquid_conection: 0,
    proposal: 0,
    negociation: 0,
    closed: 0,
    baston: 0,
    ticket: '-',
    conversion: 0,
    goal_conversion: 0,
  };

  const defaultCloserPanel: IMonthlyCloserResult = {
    commission: '-',
    day_production: '-',
    goal: '-',
    monthly_production: '-',
    payment: '-',
    projection_goal: '-',
    realized_goal: '',
  };

  const [currentFirstTableData, setCurrentFirstTableData] =
    useState<ICloserHistory>(defaultHistoryCloser);

  const [monthlyCloserHistoryMonthOne, setMonthlyCloserHistoryMonthOne] =
    useState<ICloserHistory>(defaultHistoryCloser);

  const [monthlyCloserHistoryMonthTwo, setMonthlyCloserHistoryMonthTwo] =
    useState<ICloserHistory>(defaultHistoryCloser);

  const [monthlyCloserHistoryMonthThree, setMonthlyCloserHistoryMonthThree] =
    useState<ICloserHistory>(defaultHistoryCloser);

  const [dailyCloserHistoryDay, setDailyCloserHistoryDay] =
    useState<ICloserHistory>(defaultHistoryCloser);

  const [dailyCloserHistoryWeek, setDailyCloserHistoryWeek] =
    useState<ICloserHistory>(defaultHistoryCloser);

  const [monthlyCloserResult, setMonthlyCloserResult] =
    useState<IMonthlyCloserResult>(defaultCloserPanel);

  useEffect(() => {
    const fetchData = async () => {
      const loader = document.getElementById('loader');

      loader.style.display = 'flex';

      await api
        .get(`/closer_panel/get-closer-panel?user_id=${user.id}`)
        .then(response => {
          setCloserPanelData(response.data);
        })
        .catch(() => {
          return false;
        });

      await api
        .get(`closer_panel/monthly-closer-panel?user_id=${user.id}`)
        .then(response => {
          setMonthlyCloserResult(response.data);
        })
        .catch(() => {
          return false;
        });

      // await api
      //   .get(
      //     `closer_panel/monthly-closer-history?user_id=7be65add-3f06-4e13-90ce-ab248d454e2a&month=1`,
      //   )
      //   .then(response => {
      //     setMonthlyCloserHistoryMonthOne(response.data);
      //     setCurrentFirstTableData(response.data);
      //   })
      //   .catch(() => {
      //     return false;
      //   });

      // await api
      //   .get(
      //     `closer_panel/monthly-closer-history?user_id=7be65add-3f06-4e13-90ce-ab248d454e2a&month=2`,
      //   )
      //   .then(response => {
      //     setMonthlyCloserHistoryMonthTwo(response.data);
      //   })
      //   .catch(() => {
      //     return false;
      //   });

      // await api
      //   .get(
      //     `closer_panel/monthly-closer-history?user_id=7be65add-3f06-4e13-90ce-ab248d454e2a&month=3`,
      //   )
      //   .then(response => {
      //     setMonthlyCloserHistoryMonthThree(response.data);
      //     loader.style.display = 'none';
      //   })
      //   .catch(() => (loader.style.display = 'none'));

      await api
        .get(`closer_panel/daily-closer-history?user_id=${user.id}&day=false`)
        .then(response => {
          setDailyCloserHistoryWeek(response.data);
        })
        .catch(() => {
          return false;
        });

      await api
        .get(`closer_panel/daily-closer-history?user_id=${user.id}&day=true`)
        .then(response => {
          setDailyCloserHistoryDay(response.data);
          setCurrentFirstTableData(response.data);
          loader.style.display = 'none';
        })
        .catch(() => (loader.style.display = 'none'));
    };

    if (user.profile === 'Closers' || user.profile === 'Closer Linha 1') {
      fetchData();
    }
  }, [user.id, user.profile]);
  const [closers, setClosers] = useState<CloserDTO[]>([] as CloserDTO[]);
  const [closerSelected, setCloserSelected] = useState<CloserDTO>(
    {} as CloserDTO,
  );

  useEffect(() => {
    api.get('/schedule/list-all-closers').then(response => {
      setClosers(response.data);
    });
  }, []);

  const handleSelectChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      setCloserSelected(closers.find(closer => closer.user_id === value));

      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      await api
        .get(`/closer_panel/get-closer-panel?user_id=${value}`)
        .then(response => {
          setCloserPanelData(response.data);
        })
        .catch(() => {
          return false;
        });

      await api
        .get(`closer_panel/monthly-closer-panel?user_id=${value}`)
        .then(response => {
          setMonthlyCloserResult(response.data);
        })
        .catch(() => {
          setMonthlyCloserResult(defaultCloserPanel);
        });

      await api
        .get(`closer_panel/daily-closer-history?user_id=${value}&day=false`)
        .then(response => {
          setDailyCloserHistoryWeek(response.data);
        })
        .catch(() => {
          return false;
        });

      await api
        .get(`closer_panel/daily-closer-history?user_id=${value}&day=true`)
        .then(response => {
          setDailyCloserHistoryDay(response.data);
          setCurrentFirstTableData(response.data);
          loader.style.display = 'none';
        })
        .catch(() => (loader.style.display = 'none'));
    },
    [closers, defaultCloserPanel],
  );

  return (
    (user.profile === 'Gestores' ||
      user.profile === 'Gestor Linha 1' ||
      user.profile === 'Closers' ||
      user.profile === 'Closer Linha 1' ||
      user.profile === 'Administrador' ||
      user.profile === 'Gestor Operação Comercial' ||
      user.profile === 'Gestor Operação Comercial + Leads' ||
      user.profile === 'Liderança Comercial') && (
      <Container>
        <ContainerLoader id="loader">
          <Loader type="Oval" color="#05233e" height={50} width={50} />
        </ContainerLoader>
        <Navegation>
          <H1Navigation>Negociação {'>'} </H1Navigation>
          <H1NavigationNow>Relatório Closer</H1NavigationNow>
        </Navegation>
        <Body>
          <Title
            name="Relatório Closer"
            nameStrong=""
            isShared={false}
            separatorWidth={0}
          />

          {(user.profile === 'Gestores' ||
            user.profile === 'Gestor Linha 1' ||
            user.profile === 'Administrador' ||
            user.profile === 'Gestor Operação Comercial' ||
            user.profile === 'Gestor Operação Comercial + Leads' ||
            user.profile === 'Liderança Comercial') && (
            <InputLabelContainer>
              {/* <Label>Closers:</Label> */}
              <select
                className="select"
                name="closer_id"
                id="closer_id"
                value={closerSelected.user_id || 'Selecione o closer'}
                onChange={handleSelectChange}
              >
                <option value="Selecione o closer" disabled>
                  Selecione o closer
                </option>
                {closers &&
                  closers.map(closer => (
                    <option
                      key={closer.id}
                      id={closer.id}
                      value={closer.user_id}
                    >
                      {closer.name}
                    </option>
                  ))}
              </select>
            </InputLabelContainer>
          )}

          <ContentPage
            isCloser={
              user.profile === 'Closers' || user.profile === 'Closer Linha 1'
            }
          >
            <BoxAccompanimentResult>
              <HeaderAccompanimentResult
                accompanimentButtonSelected={accompanimentButtonSelected}
                monthlyCloserHistoryMonthOne={monthlyCloserHistoryMonthOne}
                dailyCloserHistoryDay={dailyCloserHistoryDay}
                setAccompanimentButtonSelected={setAccompanimentButtonSelected}
                setCurrentFirstTableData={setCurrentFirstTableData}
                setToogleButtonSelected={setToogleButtonSelected}
              />
              <ContentAccopanimentResult>
                <TableAccompanimentHistoryCloser
                  accompanimentButtonSelected={accompanimentButtonSelected}
                  currentFirstTableData={currentFirstTableData}
                  monthlyCloserHistoryMonthOne={monthlyCloserHistoryMonthOne}
                  monthlyCloserHistoryMonthThree={
                    monthlyCloserHistoryMonthThree
                  }
                  monthlyCloserHistoryMonthTwo={monthlyCloserHistoryMonthTwo}
                  dailyCloserHistoryDay={dailyCloserHistoryDay}
                  dailyCloserHistoryWeek={dailyCloserHistoryWeek}
                  toogleButtonSelected={toogleButtonSelected}
                  setCurrentFirstTableData={setCurrentFirstTableData}
                  setToogleButtonSelected={setToogleButtonSelected}
                />
                <TableAccompanimentResultCloser
                  accompanimentButtonSelected={accompanimentButtonSelected}
                  monthlyCloserResult={monthlyCloserResult}
                />
              </ContentAccopanimentResult>
              {/* <TableAccompanimentCommercial
              toogleButtonSelected={toogleButtonSelected}
              conversion={
                currentFirstTableData.conversion !== undefined
                  ? currentFirstTableData.conversion
                  : monthlyCloserHistoryMonthOne.conversion
              }
              goal_conversion={
                currentFirstTableData.goal_conversion !== undefined
                  ? currentFirstTableData.goal_conversion
                  : monthlyCloserHistoryMonthOne.goal_conversion
              }
            /> */}
            </BoxAccompanimentResult>

            <StickTable closerPanelData={closerPanelData} />
          </ContentPage>
        </Body>
      </Container>
    )
  );
}
