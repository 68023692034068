import { useEffect, useState } from 'react';
import {
  Input,
  InputContainer,
  Label,
  Select,
  TimestampContainer,
} from '../../styles';

interface ISelectedData {
  data: string;
  time: string;
  availableHours: string[];
  handleChange: (event: any) => void;
}

export function SelectedData({
  data,
  time,
  availableHours,
  handleChange,
}: ISelectedData): JSX.Element {
  return (
    <TimestampContainer>
      <InputContainer>
        <Label>Data:</Label>
        <Input
          type="date"
          name="day"
          className="small-input"
          value={data || ''}
          onChange={handleChange}
        />
      </InputContainer>
      <InputContainer>
        <Label>Horário:</Label>
        <Select
          name="hours"
          className="small-input"
          value={time || ''}
          onChange={handleChange}
        >
          <option value="" disabled>
            Selecione um horário
          </option>
          {availableHours &&
            availableHours.map(availableHour => (
              <option key={availableHour} value={availableHour}>
                {availableHour}
              </option>
            ))}
        </Select>
      </InputContainer>
    </TimestampContainer>
  );
}
