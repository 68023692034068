import styled from 'styled-components';

export const DivContent = styled.div`
  display: flex;
`;

export const ContainerLoader = styled.div`
  width: 100vw;
  height: 100vh;

  display: none;
  position: fixed;

  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.25);
`;
