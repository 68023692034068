import styled, { css } from 'styled-components';

interface StatusProps {
  status: string;
  stage: string;
}

export const Container = styled.div<StatusProps>`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0.5rem 1rem;

  border-radius: 0.25rem;

  ${props =>
    props.status === 'atrasado' || props.status === 'perdido'
      ? css`
          background: rgba(234, 63, 63, 0.15);
          box-shadow: inset 0.188rem 0 0 #de0c0c;

          p#status {
            background: #ea3f3f;
          }
        `
      : props.status === 'pendente'
      ? css`
          background: rgba(180, 173, 9, 0.15);
          box-shadow: inset 0.188rem 0 0 #b4ad09;

          p#status {
            background: #b4ad09;
          }
        `
      : props.status === 'concluído atrasado'
      ? css`
          background: rgba(60, 166, 71, 0.15);
          box-shadow: inset 0.188rem 0 0 #3ca647;

          p#status {
            background: #ea3f3f;
          }
        `
      : css`
          background: rgba(60, 166, 71, 0.15);
          box-shadow: inset 0.188rem 0 0 #3ca647;

          p#status {
            background: #3ca647;
          }
        `}

  div.field-column:nth-child(1) {
    width: 20%;
  }

  div.field-column:nth-child(2) {
    width: 12%;
    border-left: 1px solid #9d9d9d;
  }

  div.field-column:nth-child(3) {
    width: 12%;
    border-left: 1px solid #9d9d9d;
  }

  div.field-column:nth-child(4) {
    width: 46%;
    border-left: 1px solid #9d9d9d;
  }

  div.field-column:nth-child(5) {
    width: 10%;
  }

  .field-column {
    height: 2.5rem;

    display: flex;
    justify-content: center;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 0.875rem;

  label {
    font-weight: 600;
    color: #595959;
  }

  p {
    font-weight: 500;
    color: #041524;
  }

  p#link_hubspot {
    color: #1665d8;
  }

  p#status {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 5.125rem;
    height: 1.625rem;

    border-radius: 0.25rem;

    font-size: 0.75rem;
  }
`;

export const CreditorContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 0.5rem;

  div {
    flex-direction: row;
    align-items: flex-start;

    font-size: 0.813rem;
  }
`;
