import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/x_excluir.svg';

import { handleCpfAndCnpjMask } from '../../../../../utils/handleCpfAndCnpjMask';
import { PaymentDTO } from '../../dtos/PaymentDTO';
import { PaymentFormDataDTO } from '../../dtos/PaymentFormDataDTO';

import {
  FormSessionContainer,
  InputsContainer,
  Label,
  Input,
  Select,
  SubtitleFormSession,
  RemoveSessionButton,
} from './styles';

interface Bank {
  ispb: string;
  code: number;
  fullName: string;
}

interface PaymentFormProps {
  index: number;
  paymentFormData: PaymentFormDataDTO[];
  setPaymentFormData: (payment: PaymentFormDataDTO[]) => void;
  quantityBankData: number;
  setQuantityBankData: (quantity: number) => void;
  setTotalBankData: (totalBankData: number[]) => void;
  createdPayment: PaymentDTO | undefined;
}

export function PaymentForm({
  index,
  paymentFormData,
  setPaymentFormData,
  quantityBankData,
  setQuantityBankData,
  setTotalBankData,
  createdPayment,
}: PaymentFormProps): JSX.Element {
  const [banks, setBanks] = useState<Bank[]>([] as Bank[]);

  useEffect(() => {
    fetch('https://brasilapi.com.br/api/banks/v1')
      .then(response => response.json())
      .then(data => {
        const replacedData = data.map((bank: Bank) => {
          if (bank.code) {
            const replaceData = {
              ...bank,
              fullName: bank.fullName.toUpperCase(),
            };

            return replaceData;
          }
          return '';
        });

        const sortedData = replacedData.sort((a: Bank, b: Bank) => {
          if (a.fullName < b.fullName) {
            return -1;
          }
          if (a.fullName > b.fullName) {
            return 1;
          }
          return 0;
        });

        setBanks(sortedData);
      });
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      let cpf_cnpj: string;

      if (name === 'cpf_cnpj') {
        cpf_cnpj = handleCpfAndCnpjMask(value);
      }

      const updatedPaymentData = {
        ...paymentFormData[index],
        [name]: cpf_cnpj || value,
      };

      paymentFormData[index] = updatedPaymentData;

      setPaymentFormData(paymentFormData);
    },
    [index, paymentFormData, setPaymentFormData],
  );

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (
    value,
    _,
    values,
  ): void => {
    const updatedPaymentData = {
      ...paymentFormData[index],
      [_]: values.float,
    };

    paymentFormData[index] = updatedPaymentData;

    setPaymentFormData(paymentFormData);
  };

  const handleRemoveSessionDataBank = useCallback(() => {
    const quantity = quantityBankData - 1;

    setQuantityBankData(quantity);

    const bankData: number[] = [];

    for (let i = 1; i <= quantity; i++) {
      bankData.push(i);
    }

    setTotalBankData(bankData);
  }, [quantityBankData, setQuantityBankData, setTotalBankData]);

  return (
    <>
      <SubtitleFormSession>Dados bancários</SubtitleFormSession>
      <FormSessionContainer>
        <InputsContainer>
          <Label className="transaction">Código banco:</Label>
          <Select name="bank_code" defaultValue="" onChange={handleInputChange}>
            <option disabled value="">
              Selecione uma opção
            </option>
            {banks &&
              banks.map(bank => {
                return (
                  bank.code && (
                    <option
                      key={bank.ispb}
                      value={
                        String(bank.code).length < 3
                          ? String(bank.code).padStart(3, '0')
                          : String(bank.code)
                      }
                      selected={
                        createdPayment?.bank_code ===
                          (String(bank.code).length < 3
                            ? String(bank.code).padStart(3, '0')
                            : String(bank.code)) ||
                        paymentFormData[index]?.bank_code ===
                          (String(bank.code).length < 3
                            ? String(bank.code).padStart(3, '0')
                            : String(bank.code))
                      }
                    >
                      {String(bank.code).padStart(3, '0')} - {bank.fullName}
                    </option>
                  )
                );
              })}
          </Select>
        </InputsContainer>

        <InputsContainer>
          <Label className="transaction">Agência:</Label>
          <Input
            name="bank_agency"
            defaultValue={
              createdPayment?.agency || paymentFormData[index]?.bank_agency
            }
            onChange={handleInputChange}
          />
        </InputsContainer>

        <InputsContainer>
          <Label className="transaction">Conta:</Label>
          <Input
            name="bank_account"
            defaultValue={
              createdPayment?.account || paymentFormData[index]?.bank_account
            }
            onChange={handleInputChange}
          />
        </InputsContainer>

        <InputsContainer>
          <Label className="transaction">Tipo da conta:</Label>
          <Select
            name="account_type"
            defaultValue={paymentFormData[index]?.account_type || ''}
            onChange={handleInputChange}
          >
            <option disabled value="">
              Selecione uma opção
            </option>
            <option
              selected={createdPayment?.account_type === 'POUPANÇA'}
              value="POUPANÇA"
            >
              Poupança
            </option>
            <option
              selected={createdPayment?.account_type === 'CORRENTE'}
              value="CORRENTE"
            >
              Corrente
            </option>
          </Select>
        </InputsContainer>

        <InputsContainer>
          <Label className="transaction">Valor da transação:</Label>
          <CurrencyInput
            name="transaction_value"
            className="currency_input"
            defaultValue={
              createdPayment?.transaction_value ||
              paymentFormData[index]?.transaction_value
            }
            allowDecimals
            onValueChange={handleOnValueChange}
            placeholder="Digite apenas números"
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          />
        </InputsContainer>

        {index > 0 && (
          <>
            <InputsContainer>
              <Label className="transaction">Nome titular:</Label>
              <Input
                name="titular_name"
                defaultValue={paymentFormData[index]?.titular_name}
                onChange={handleInputChange}
              />
            </InputsContainer>

            <InputsContainer>
              <Label className="transaction">CPF/CNPJ:</Label>
              <Input
                name="cpf_cnpj"
                maxLength={18}
                defaultValue={paymentFormData[index]?.cpf_cnpj}
                onChange={handleInputChange}
              />
            </InputsContainer>
          </>
        )}
      </FormSessionContainer>

      {index > 0 && (
        <RemoveSessionButton onClick={handleRemoveSessionDataBank}>
          <DeleteIcon />
          Remover sessão
        </RemoveSessionButton>
      )}
    </>
  );
}
