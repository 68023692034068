import styled, { css } from 'styled-components';

interface ICloseModal {
  isClose: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
`;

export const Image = styled.img``;

export const Button = styled.button<ICloseModal>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 8.15rem;
  position: absolute;
  height: 2.063rem;
  width: 2.063rem;

  border: none;

  background: #ffffff;
  padding: 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.313rem 0px 0px 0.313rem;

  :disabled {
    opacity: 0;
  }

  ${props =>
    props.isClose &&
    css`
      opacity: 1;
      transition: opacity 0.5s linear;
    `}

  @media (max-width: 1270px) {
    margin-top: 11.813rem;
  }
`;
