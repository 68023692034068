export const locations = [
  /* {
    id: 12,
    name: 'Acre',
    route: 'Acre',
    initials: 'AC',
    region: 'Norte',
  }, */
  {
    id: 27,
    name: 'Alagoas',
    route: 'Alagoas',
    initials: 'AL',
    region: 'Nordeste',
  },
  /* {
    id: 16,
    name: 'Amapá',
    route: 'Amapá',
    initials: 'AP',
    region: 'Norte',
  },
  {
    id: 13,
    name: 'Amazonas',
    route: 'Amazonas',
    initials: 'AM',
    region: 'Norte',
  },
  {
    id: 29,
    name: 'Bahia',
    route: 'Bahia',
    initials: 'BA',
    region: 'Nordeste',
  },
  {
    id: 23,
    name: 'Ceará',
    route: 'Ceará',
    initials: 'CE',
    region: 'Nordeste',
  },
  {
    id: 53,
    name: 'Distrito Federal',
    route: 'Distrito Federal',
    initials: 'DF',
    region: 'Centro-Oeste',
  },
  {
    id: 32,
    name: 'Espírito Santo',
    route: 'Espírito Santo',
    initials: 'ES',
    region: 'Sudeste',
  },
  {
    id: 52,
    name: 'Goiás',
    route: 'Goiás',
    initials: 'GO',
    region: 'Centro-Oeste',
  },
  {
    id: 21,
    name: 'Maranhão',
    route: 'Maranhão',
    initials: 'MA',
    region: 'Nordeste',
  }, */
  {
    id: 51,
    name: 'Mato Grosso',
    route: 'Mato Grosso',
    initials: 'MT',
    region: 'Centro-Oeste',
  },
  /* {
    id: 50,
    name: 'Mato Grosso do Sul',
    route: 'Mato Grosso do Sul',
    initials: 'MS',
    region: 'Centro-Oeste',
  }, */
  {
    id: 31,
    name: 'Minas Gerais',
    route: 'Minas Gerais',
    initials: 'MG',
    region: 'Sudeste',
  },
  /* {
    id: 15,
    name: 'Pará',
    route: 'Pará',
    initials: 'PA',
    region: 'Norte',
  },
  {
    id: 25,
    name: 'Paraíba',
    route: 'Paraíba',
    initials: 'PB',
    region: 'Nordeste',
  },
  {
    id: 41,
    name: 'Paraná',
    route: 'Paraná',
    initials: 'PR',
    region: 'Sul',
  },
  {
    id: 26,
    name: 'Pernambuco',
    route: 'Pernambuco',
    initials: 'PE',
    region: 'Nordeste',
  },
  {
    id: 22,
    name: 'Piauí',
    route: 'Piauí',
    initials: 'PI',
    region: 'Nordeste',
  }, */
  {
    id: 33,
    name: 'Rio de Janeiro',
    route: 'Rio de Janeiro',
    initials: 'RJ',
    region: 'Sudeste',
  },
  {
    id: 11,
    name: 'Rondônia',
    route: 'Rondônia',
    initials: 'RO',
    region: 'Norte',
  },
  /* {
    id: 24,
    name: 'Rio Grande do Norte',
    route: 'Rio Grande do Norte',
    initials: 'RN',
    region: 'Nordeste',
  },
  {
    id: 14,
    name: 'Roraima',
    route: 'Roraima',
    initials: 'RR',
    region: 'Norte',
  }, */
  {
    id: 42,
    name: 'Santa Catarina',
    route: 'Santa Catarina',
    initials: 'SC',
    region: 'Sul',
  },
  {
    id: 43,
    name: 'Rio Grande do Sul',
    route: 'Rio Grande do Sul',
    initials: 'RS',
    region: 'Sul',
  },
  {
    id: 35,
    name: 'São Paulo',
    route: 'São Paulo',
    initials: 'SP',
    region: 'Sudeste',
  },
  {
    id: 36,
    name: 'São Paulo - Diario de Justiça',
    route: 'São Paulo',
    initials: 'SP-JD',
    region: 'Sudeste',
  },
  /* {
    id: 28,
    name: 'Sergipe',
    route: 'Sergipe',
    initials: 'SE',
    region: 'Nordeste',
  }, */
  {
    id: 17,
    name: 'Tocantins',
    route: 'Tocantins',
    initials: 'TO',
    region: 'Norte',
  },
];
