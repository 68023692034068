import styled, { css } from 'styled-components';

interface ISelectHistory {
  selectHistory: string;
}

export const TitleContainer = styled.h1<ISelectHistory>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 2.063rem;
  margin-bottom: 3.063rem;

  button {
    width: 8rem;
    height: 2.5rem;
    border: none;

    border-radius: 0.313rem;
    font-family: 'Helvetica', sans-serif;
    font-weight: 500;
    font-size: 1.625rem;
    text-align: center;
  }

  ${props =>
    props.selectHistory === 'Closers'
      ? css`
          button.closer-history {
            background: #041524;
            color: white;
          }

          button.queue-history {
            background: #9d9d9d;
            color: white;
          }
        `
      : css`
          button.queue-history {
            background: #041524;
            color: white;
          }

          button.closer-history {
            background: #9d9d9d;
            color: white;
          }
        `}
`;

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 11.938rem;
  height: 0.0625rem;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 1rem;

  color: var(--blue-700);
`;

export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20rem;

  margin: 1rem 3rem 0.625rem 0rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .select {
    width: 5.2rem;
    height: 1.5rem;
    background-color: aliceblue;

    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid var(--blue-300);

    &:focus {
      border: 2px solid var(--blue-400);
    }
  }
`;
