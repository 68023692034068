import { shade } from 'polished';
import styled from 'styled-components';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonCreate = styled.button`
  width: 9.75rem;
  height: 2.25rem;
  color: var(--white);
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  font-weight: 600;
  font-size: 0.813rem;
  text-align: center;

  background: var(--green-600);
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  color: var(--white);
  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.3, '#089B63')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  width: 100%;

  overflow: auto;
`;

export const THead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--blue-100);
  margin-bottom: 0.625rem;
  border-radius: 0.25rem;

  min-width: 78.5rem;
  height: 2.5rem;
`;

export const TBody = styled.tbody`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  min-width: 78.5rem;

  tr {
    width: 100%;
    height: 3.5rem;

    border-radius: 0.25rem;
    margin-bottom: 0.313rem;
  }

  tr:nth-child(even) {
    background: var(--gray-100);
  }

  tr:nth-child(odd) {
    background: var(--gray-50);
  }

  #body-row {
    cursor: pointer;
    transition: filter 0.2s;

    :hover {
      filter: brightness(0.9);
    }
  }
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;

  width: 100%;

  th:nth-child(2) {
    width: 15.85%;
  }

  th:nth-child(3) {
    width: 15.85%;
  }

  th:nth-child(8) {
    width: 12%;
  }

  td:nth-child(2) {
    width: 15.85%;
  }

  td:nth-child(3) {
    width: 15.85%;
  }

  td:nth-child(8) {
    width: 12%;
  }

  th {
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--blue-700);

    width: 10%;
    height: 100%;

    & + th {
      border-left: 1px solid var(--gray-500);
    }
  }

  td {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 300;
    font-size: 0.875rem;
    color: var(--blue-800);
    text-align: center;

    width: 10%;
    height: 100%;
  }
`;

export const ButtonPaymentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 6rem;
  margin-bottom: 1rem;
`;

export const ButtonNewPayment = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  width: 9.688rem;
  height: 2.313rem;

  background: var(--green-600);

  border: 0;
  border-radius: 0.5rem;

  text-align: center;

  color: var(--white);
  font-family: Poppins;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  font-style: normal;

  transition: background-color 0.2s;

  &:hover {
    background: var(--green-500);
  }
`;

export const ButtonPaymentType = styled.button`
  display: flex;
  position: relative;

  width: 9.688rem;
  height: 1.75rem;

  border: 0;
  border-radius: 0.5rem;

  background: var(--gray-200);

  color: var(--blue-800);
  font-family: Poppins;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  text-align: center;

  transition: 0.2s;

  &:hover {
    background: var(--green-600);
    color: var(--white);
  }
`;

export const DivNameButton = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  text-align: center;
`;

export const IconCreate = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;

  font-size: 11px;
  margin-left: 1.438rem;
`;

export const ContainerButtonPayment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  border-radius: 00.5rem;
  margin-top: 0.13rem;

  background: var(--gray-200);
`;
