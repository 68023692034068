import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Swal from 'sweetalert2';
// import apiTrfs from '../../../services/apiTrfs';
import { Body } from '../../../components/Styles/Body';
import { ExampleFileTrfDataExtraction } from '../components/ExampleFileTrfDataExtraction';
import {
  FileContainer,
  FilesHistoryContainer,
  TitleHistory,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
} from './styles';
import apiTrfsUrl from '../../../services/apiTrfsUrl';

interface IFileHistory {
  id: number;
  name: string;
  trf: string;
  lines: string;
  processed: string;
  created_at: Date;
  file_url: string;
  zip_url: string;
  zip_name: string;
}

interface ITrfDataExtraction {
  court: string;
}

export const TrfDataExtraction: React.FC<ITrfDataExtraction> = ({
  court,
}: ITrfDataExtraction) => {
  const [filesHistory, setFilesHistory] = useState<IFileHistory[]>([]);
  const [files, setFiles] = useState<FileList[]>([]);
  const [fileLength, setFileLength] = useState(0);

  useEffect(() => {
    const url =
      court === 'trf3-pdf'
        ? '/files/process_files/TRF3DATAEXTRACTION'
        : court === 'trf5-pdf'
        ? '/trfs/TRF5DATAEXTRACTION'
        : court === 'trf6-pdf'
        ? '/trfs/TRF6PDF'
        : court === 'trf1-pdf-model-1'
        ? '/trfs/TRF1DATAEXTRACTIONMODEL1'
        : '/trfs/TRF1DATAEXTRACTIONMODEL2';

    const apiUrl = apiTrfsUrl(court);

    apiUrl.get<IFileHistory[]>(`${url}`).then(response => {
      setFilesHistory(response.data);
    });
  }, [files, court]);

  const sendFileSucess = useCallback(async () => {
    Swal.fire('Sucesso!', 'Requisição recebida com sucesso', 'success');
  }, []);

  const sendFileError = useCallback(async () => {
    Swal.fire('Erro!', 'Falha ao enviar requisição.', 'error');
  }, []);

  const handleDownload = useCallback(
    async (url: string, type_file: string) => {
      const index = url.indexOf(type_file);

      const fileName = url.slice(index + 8, url.length);

      const apiUrl = apiTrfsUrl(court);

      const response = await apiUrl.get(url, {
        responseType: 'blob',
      });

      saveAs(response.data, fileName);
    },
    [court],
  );

  const submitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filesFormatted = [];

      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index];

        filesFormatted.push(file);
      }

      setFiles(filesFormatted);

      setFileLength(event.target.files.length);

      event.target.value = null;
    },
    [setFiles],
  );

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    try {
      files.forEach(oneFile => {
        data.append('files', oneFile as any);
      });

      const url =
        court === 'trf3-pdf'
          ? '/extract-data'
          : court === 'trf5-pdf'
          ? 'trfs/trf5-data-extraction'
          : court === 'trf6-pdf'
          ? 'trfs/trf6-pdf'
          : court === 'trf1-pdf-model-1'
          ? 'trfs/trf1-data-extraction-model-1'
          : 'trfs/trf1-data-extraction-model-2';

      const apiUrl = apiTrfsUrl(court);

      await apiUrl.post(`${url}`, data);

      setFiles([]);

      setFileLength(0);

      sendFileSucess();
    } catch (error) {
      sendFileError();
    }
  }, [files, sendFileError, sendFileSucess, court]);

  return (
    <Body>
      <FileContainer>
        <ExampleFileTrfDataExtraction
          handleUpload={handleUpload}
          submitFile={submitFile}
          files_length={fileLength}
        />
      </FileContainer>

      <FilesHistoryContainer>
        <TitleHistory>Processamento anteriores</TitleHistory>
      </FilesHistoryContainer>

      <Table>
        <Thead>
          <TableRowHead>
            <CellHeader>
              <TitleTable>Data de importação</TitleTable>
              <Divisor />
            </CellHeader>
            <CellHeader>
              <TitleTable>Nome do arquivo CSV</TitleTable>
              <Divisor />
            </CellHeader>
            <CellHeader>
              <TitleTable>Situação</TitleTable>
            </CellHeader>
          </TableRowHead>
        </Thead>
        <Tbody>
          {filesHistory.length > 0 &&
            filesHistory.map(fileHistory => (
              <TableRowBody key={fileHistory.id}>
                <CellBody>{fileHistory.created_at}</CellBody>
                <CellBody
                  onClick={() =>
                    handleDownload(fileHistory.file_url, 'uploads')
                  }
                >
                  {fileHistory.name}
                </CellBody>
                <CellBody>
                  (Processado {fileHistory.processed} de {fileHistory.lines})
                </CellBody>
              </TableRowBody>
            ))}
        </Tbody>
      </Table>
    </Body>
  );
};
