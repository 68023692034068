import { shade } from 'polished';
import styled from 'styled-components';

export const ProposalModal = {
  content: {
    width: '41.875rem',
    maxHeight: '39rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0.813rem 1.875rem ',
    borderRadius: '0.625rem',
    alignItems: 'center',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const ModalHeader = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  border-radius: 0.25rem;

  font-weight: 400;
  font-size: 1.5rem;
  color: var(--blue-700);
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.313rem;
`;

export const Separator = styled.hr`
  width: 22rem;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 0.875rem;
  gap: 1.25rem;

  .search-wrapper.searchWrapper {
    padding: 0;
    width: 100%;
    border: none;

    input {
      width: 100%;
    }
  }

  .searchBox {
    padding: 0;
    margin: 0;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--blue-100);
    border-radius: 0.2rem;
    padding: 0 0.5rem;

    font-weight: 600;
    font-size: 0.875rem;
    color: var(--blue-700);

    height: 1.563rem;
  }

  .question-label {
    height: fit-content;
  }

  input {
    background: var(--gray-100);
    padding-left: 0.25rem;
    border-radius: 0.2rem;
    border: 0.5px solid var(--blue-600);

    font-weight: 400;
    font-size: 0.75rem;
    color: var(--gray-600);

    height: 1.563rem;
    flex: 1;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 4.063rem;

  resize: none;

  margin-bottom: 0.875rem;
  border-radius: 0.25rem;
  padding-left: 0.25rem;
  padding-top: 0.25rem;
  background: var(--gray-100);

  font-weight: 400;
  font-size: 0.75rem;
  color: var(--gray-600);
`;

export const H1 = styled.h1`
  font-size: 1.125rem;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-700);

  margin-bottom: 0.938rem;
  height: 1.563rem;

  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;
`;

export const InputFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 0.313rem;
`;

export const LabelFile = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 9.375rem;
  height: 1.625rem;
  border-radius: 0.313rem;
  background: var(--gray-300);

  font-weight: 600;
  font-size: 0.688rem;
  color: var(--gray-800);

  cursor: pointer;
`;

export const InputFile = styled.input`
  display: none;
`;

export const TextFile = styled.span`
  width: 10rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.625rem;
  color: var(--gray-900);
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const SolicitationButton = styled.button`
  width: 9.688rem;
  height: 2.313rem;

  margin-top: 0.5rem;
  background: var(--blue-600);
  border-radius: 0.5rem;
  border: none;

  color: var(--white);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;

  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.2, '#05233E')};
  }
`;
