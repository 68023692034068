import { IMonthlyCloserResult } from '..';
import {
  ContentBigTableItem,
  ContentTable,
  ContentTableData,
  ContentTableItem,
  ContentTableRow,
  ContentTableSubItem,
  ContentTableSubItems,
} from './styles';

interface TableAccompanimentResultCloserProps {
  accompanimentButtonSelected: string;
  monthlyCloserResult: IMonthlyCloserResult;
}

export function TableAccompanimentResultCloser({
  accompanimentButtonSelected,
  monthlyCloserResult,
}: TableAccompanimentResultCloserProps): JSX.Element {
  return accompanimentButtonSelected === 'Mensal' ? (
    <ContentTableData>
      <ContentTable className="meta-row">
        <ContentTableRow>
          <ContentTableItem className="first-solo-item">META.</ContentTableItem>
          <ContentTableItem className="second-solo-item">
            {monthlyCloserResult.goal}
          </ContentTableItem>
        </ContentTableRow>
      </ContentTable>
      <ContentTable>
        <ContentTableRow>
          <ContentBigTableItem className="first-table-item">
            PROD.
          </ContentBigTableItem>
          <ContentBigTableItem className="second-table-item">
            <ContentTableSubItems>
              <ContentTableSubItem> DIA </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.day_production}
              </ContentTableSubItem>
            </ContentTableSubItems>
            <ContentTableSubItems>
              <ContentTableSubItem> MÊS </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.monthly_production}
              </ContentTableSubItem>
            </ContentTableSubItems>
          </ContentBigTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentBigTableItem className="first-table-item">
            META
          </ContentBigTableItem>
          <ContentBigTableItem className="second-table-item">
            <ContentTableSubItems>
              <ContentTableSubItem> %REALIZADO </ContentTableSubItem>
              <ContentTableSubItem>{`${monthlyCloserResult.realized_goal}%`}</ContentTableSubItem>
            </ContentTableSubItems>
            <ContentTableSubItems>
              <ContentTableSubItem> RIT. E PROJ. </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.projection_goal}
              </ContentTableSubItem>
            </ContentTableSubItems>
          </ContentBigTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentBigTableItem className="first-table-item">
            PAG.
          </ContentBigTableItem>
          <ContentBigTableItem className="last-second-table-item">
            <ContentTableSubItems>
              <ContentTableSubItem> PAGAMENTO </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.payment}
              </ContentTableSubItem>
            </ContentTableSubItems>
            <ContentTableSubItems className="last-content-table-sub-items">
              <ContentTableSubItem> COMISSÃO </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.commission}
              </ContentTableSubItem>
            </ContentTableSubItems>
          </ContentBigTableItem>
        </ContentTableRow>
      </ContentTable>
    </ContentTableData>
  ) : (
    <ContentTableData>
      <ContentTable className="meta-row">
        <ContentTableRow>
          <ContentTableItem className="first-solo-item">META.</ContentTableItem>
          <ContentTableItem className="second-solo-item">
            {monthlyCloserResult.goal}
          </ContentTableItem>
        </ContentTableRow>
      </ContentTable>
      <ContentTable>
        <ContentTableRow>
          <ContentBigTableItem className="first-table-item">
            PROD.
          </ContentBigTableItem>
          <ContentBigTableItem className="second-table-item">
            <ContentTableSubItems>
              <ContentTableSubItem> DIA </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.day_production}
              </ContentTableSubItem>
            </ContentTableSubItems>
            <ContentTableSubItems>
              <ContentTableSubItem> MÊS </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.monthly_production}
              </ContentTableSubItem>
            </ContentTableSubItems>
          </ContentBigTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentBigTableItem className="first-table-item">
            META
          </ContentBigTableItem>
          <ContentBigTableItem className="second-table-item">
            <ContentTableSubItems>
              <ContentTableSubItem> %REALIZADO </ContentTableSubItem>
              <ContentTableSubItem>{`${monthlyCloserResult.realized_goal}%`}</ContentTableSubItem>
            </ContentTableSubItems>
            <ContentTableSubItems>
              <ContentTableSubItem> RIT. E PROJ. </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.projection_goal}
              </ContentTableSubItem>
            </ContentTableSubItems>
          </ContentBigTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentBigTableItem className="first-table-item">
            PAG.
          </ContentBigTableItem>
          <ContentBigTableItem className="last-second-table-item">
            <ContentTableSubItems>
              <ContentTableSubItem> PAGAMENTO </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.payment}
              </ContentTableSubItem>
            </ContentTableSubItems>
            <ContentTableSubItems className="last-content-table-sub-items">
              <ContentTableSubItem> COMISSÃO </ContentTableSubItem>
              <ContentTableSubItem>
                {monthlyCloserResult.commission}
              </ContentTableSubItem>
            </ContentTableSubItems>
          </ContentBigTableItem>
        </ContentTableRow>
      </ContentTable>
    </ContentTableData>
    // <ContentTableData>
    //   <HeadSecondTableDiary>
    //     <ContentHeadSecondTableDiary>
    //       <ContentHeadSecondTableDiaryItem>CNX</ContentHeadSecondTableDiaryItem>
    //       <ContentHeadSecondTableDiaryItem>
    //         PROP.
    //       </ContentHeadSecondTableDiaryItem>
    //     </ContentHeadSecondTableDiary>
    //   </HeadSecondTableDiary>
    //   <ContentTable className="second-table-diary">
    //     <ContentTableRow>
    //       <ContentBigTableItem className="first-table-item-diary">
    //         TRANSFERÊNCIAS
    //       </ContentBigTableItem>
    //       <ContentBigTableItem className="second-table-item second-table-item-diary">
    //         <ContentTableSubItems className="diary-table">
    //           <ContentTableSubItem className="diary-item-table">
    //             15
    //           </ContentTableSubItem>
    //           <ContentTableSubItem className="diary-item-table">
    //             7
    //           </ContentTableSubItem>
    //         </ContentTableSubItems>
    //       </ContentBigTableItem>
    //     </ContentTableRow>
    //     <ContentTableRow>
    //       <ContentBigTableItem className="first-table-item-diary">
    //         AGENDAMENTO
    //       </ContentBigTableItem>
    //       <ContentBigTableItem className="last-second-table-item">
    //         <ContentTableSubItems className="diary-table">
    //           <ContentTableSubItem className="diary-item-table">
    //             10
    //           </ContentTableSubItem>
    //           <ContentTableSubItem className="diary-item-table">
    //             5
    //           </ContentTableSubItem>
    //         </ContentTableSubItems>
    //       </ContentBigTableItem>
    //     </ContentTableRow>
    //   </ContentTable>
    // </ContentTableData>
  );
}
