import { AiOutlinePlus } from 'react-icons/ai';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import { useQuery } from 'react-query';
import Loader from 'react-loader-spinner';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import { ReactComponent as ArrowBlue } from '../../../assets/icons/arrow_blue.svg';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Title } from './Title';
import { Body } from '../../../components/Styles/Body';
import { Container } from '../../../components/Container';
import { InputDate } from '../../../components/InputDate';
import { ScheduleOptionsButton } from '../../../components/ScheduleOptionsButton';
import { ResumePanel } from './ResumePanel';
import { WeeklySchedule } from './WeeklySchedule';
import { ProvidencesComponent } from './ProvidencesComponent';
import { ModalCreateActivity } from './ModalCreateActivity';
import { ScheduleComponent } from './ScheduleComponent';

import { IActivitiesInADayDTO } from './dtos/IActivitiesInADayDTO';
import { formatAmount } from '../../../utils/formatAmount';
import { IActivityCSDTO } from './dtos/IActivityCSDTO';

import {
  Main,
  ButtonCreateActivity,
  CalendarPeriodsButtons,
  ActivitiesContainer,
  TitleBackButtonContainer,
  TitleContainer,
  BackButtonContainer,
  Icon,
  CalendarAndFilters,
  LabelInput,
  Label,
  Select,
  Input,
  GoBackLink,
} from './styles';
import { ScheduleManager } from './ScheduleManager';
import {
  DealsListComponent,
  ICustomerSuccessCases,
} from './DealsListComponent';
import { Loading } from '../../../components/Loading';
import {
  LoaderContainer,
  TextLoader,
} from '../../Connection/CallsAudit/styles';

interface IHubspotDeal {
  dealname: string;
  hubspot_deal_id: string;
  amount: string;
  pipeline: string;
  numero_da_operacao: string;
  deal_stage_name: string;
  ticket_stage_name: string;
}

interface ICustomerSucess {
  user_id: string;
  type: string;
  user_name: string;
  filter?: string;
}

interface IMonthlyActivities {
  filteredActivities: IActivityCSDTO[];
  filteredProvidences: IActivityCSDTO[];
  filteredReminders: IActivityCSDTO[];
}

export function CustomerSuccess(): JSX.Element {
  const [date, setDate] = useState(new Date());
  const history = useHistory();
  const [weekdays, setWeekdays] = useState<Date[]>([] as Date[]);

  const [activitiesInADay, setActivitiesInADay] = useState(
    {} as IActivitiesInADayDTO,
  );
  const [activitiesInAWeek, setActivitiesInAWeek] = useState(
    {} as IActivitiesInADayDTO,
  );
  const [hubspotDeals, setHubspotDeals] = useState<IHubspotDeal[]>(
    [] as IHubspotDeal[],
  );

  const location = useLocation();

  const user_cs = location.state as ICustomerSucess;

  const [monthlyActivities, setMonthlyActivities] =
    useState<IMonthlyActivities>({} as IMonthlyActivities);
  const [currentMonthlyActivities, setCurrentMonthlyActivities] = useState<
    IActivityCSDTO[]
  >([] as IActivityCSDTO[]);
  const [currentMonthlyProvidences, setCurrentMonthlyProvidences] = useState<
    IActivityCSDTO[]
  >([] as IActivityCSDTO[]);

  const [openCreateActivityModal, setOpenCreateActivityModal] = useState(false);
  const [selectedCalendarPeriod, setSelectedCalendarPeriod] = useState('Dia');
  const [filterHubspotDeal, setFilterHubspotDeal] = useState(
    {} as IHubspotDeal,
  );

  const [customerSuccessInfo, setCustomerSuccessInfo] =
    useState<ICustomerSuccessCases[]>();

  const calendarPeriods = ['Dia', 'Semana', 'Casos'];

  const { user } = useAuth();

  let currentUserId: string;

  if (user_cs) {
    currentUserId = user_cs.user_id;
  } else {
    currentUserId = user.id;
  }

  const REFRESH_ACTIVITIES_CS = gql`
    subscription ListAllActivitiesCS {
      listAllActivitiesCS {
        quantity_activities
        quantity_cases
        quantity_reminders
        formatted_amount
        activities {
          hour
          activities {
            id
            name
            type
            time_init
            time_end
            timestamp
            hubspot_deal_id
            dealname
            closer_name
            user_name
            amount
            proposal_amount
            stage_deal
            stage_ticket
            valid_contact
            operation_number
            observation
            situation
            checklist_concluded
            closer_id
          }
        }
        providences {
          id
          name
          type
          time_init
          time_end
          timestamp
          hubspot_deal_id
          dealname
          closer_name
          user_name
          amount
          proposal_amount
          stage_deal
          stage_ticket
          valid_contact
          operation_number
          observation
          situation
          checklist_concluded
          closer_id
        }
        reminders {
          id
          name
          type
          time_init
          time_end
          timestamp
          hubspot_deal_id
          dealname
          closer_name
          user_name
          amount
          proposal_amount
          stage_deal
          stage_ticket
          valid_contact
          operation_number
          observation
          situation
          checklist_concluded
          closer_id
        }
      }
    }
  `;

  const refresh = useSubscription(REFRESH_ACTIVITIES_CS);

  useEffect(() => {
    if (
      refresh.data &&
      (user.profile === 'Customer Success' ||
        user.profile === 'Customer Success Calculator')
    ) {
      api
        .get(
          `/schedule-cs?start_date=${format(
            date,
            'yyyy-MM-dd',
          )}&end_date&user_id=${currentUserId}`,
        )
        .then(response => {
          setActivitiesInADay(response.data);
        });

      api.get(`/schedule-cs/cases-by-owner/${currentUserId}`).then(response => {
        setHubspotDeals(response.data);
      });
    }
  }, [currentUserId, date, refresh, user.profile]);

  const handleRedirectToIndividualCsHistory = useCallback(() => {
    history.push({
      pathname: '/payments/schedule/customer-sucess/ScheduleListing',
      state: {
        user_id: user.id,
        type: 'Manager',
        user_name: user.name,
        sucess: 0,
      },
    });

    setSelectedCalendarPeriod('deals');
  }, [history, user.id, user.name]);

  useEffect(() => {
    api.get(`/schedule-cs/cases-by-owner/${currentUserId}`).then(response => {
      setHubspotDeals(response.data);
    });
    if (user_cs && user_cs.filter && user_cs.filter === 'deals') {
      setSelectedCalendarPeriod('Casos');
    }
  }, [currentUserId, user.id, user_cs]);

  useEffect(() => {
    const currentDate = new Date();
    const week: Date[] = [];

    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1);

    for (let i = 1; i < 6; i++) {
      week.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setWeekdays(week);
  }, []);

  useEffect(() => {
    setFilterHubspotDeal({} as IHubspotDeal);

    if (
      user.profile === 'Customer Success' ||
      user.profile === 'Customer Success Calculator'
    ) {
      api
        .get<IActivitiesInADayDTO>(
          `/schedule-cs?start_date=${format(
            date,
            'yyyy-MM-dd',
          )}&end_date&user_id=${currentUserId}`,
        )
        .then(response => {
          setActivitiesInADay(response.data);
        });
    }
  }, [date, currentUserId, user.profile]);

  useEffect(() => {
    if (
      weekdays.length > 0 &&
      (user.profile === 'Customer Success' ||
        user.profile === 'Customer Success Calculator')
    ) {
      api
        .get(
          `/schedule-cs?start_date=${format(
            weekdays[0],
            'yyyy-MM-dd',
          )}&end_date=${format(
            weekdays[weekdays.length - 1],
            'yyyy-MM-dd',
          )}&user_id=${currentUserId}`,
        )
        .then(response => {
          setActivitiesInAWeek(response.data);
        });
    }
  }, [currentUserId, user.profile, weekdays]);

  useEffect(() => {
    if (user.profile === 'Gestor Customer Success') {
      const firstDay = format(
        new Date(date.getFullYear(), date.getMonth(), 1),
        'yyyy-MM-dd',
      );

      const lastDay = format(
        new Date(date.getFullYear(), date.getMonth() + 1, 0),
        'yyyy-MM-dd',
      );

      api
        .get(
          `/schedule-cs/monthly-activities?start_date=${firstDay}&end_date=${lastDay}&user_id=${currentUserId}`,
        )
        .then(response => {
          setMonthlyActivities(response.data);
          setCurrentMonthlyActivities(response.data.filteredActivities);
          setCurrentMonthlyProvidences(response.data.filteredProvidences);
        });
    }
  }, [date, currentUserId, user.profile]);

  const handleCloseCreateActivityModal = useCallback(() => {
    setOpenCreateActivityModal(false);
  }, []);

  const handleFilterActivities = useCallback(
    async (hubspot_deal: IHubspotDeal) => {
      if (hubspot_deal) {
        const filteredActivities: IActivityCSDTO[] =
          monthlyActivities.filteredActivities.filter(
            activity =>
              activity.hubspot_deal_id === hubspot_deal.hubspot_deal_id,
          );

        const filteredProvidences =
          monthlyActivities.filteredProvidences.filter(
            providence =>
              providence.hubspot_deal_id === hubspot_deal.hubspot_deal_id,
          );

        const filteredReminders = monthlyActivities.filteredReminders.filter(
          reminder => reminder.hubspot_deal_id === hubspot_deal.hubspot_deal_id,
        );

        setCurrentMonthlyActivities(filteredActivities);

        setCurrentMonthlyProvidences(filteredProvidences);
      }
    },
    [monthlyActivities],
  );

  const handleFilterInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = event.target;

      const findDeal = hubspotDeals.find(
        hubspotDeal => hubspotDeal.hubspot_deal_id === value,
      );

      setFilterHubspotDeal(findDeal);

      if (value === 'Todos') {
        setCurrentMonthlyActivities(monthlyActivities.filteredActivities);

        setCurrentMonthlyProvidences(monthlyActivities.filteredProvidences);
      } else {
        await handleFilterActivities(findDeal);
      }
    },
    [handleFilterActivities, hubspotDeals, monthlyActivities],
  );

  function useDeals() {
    return useQuery({
      queryKey: ['deals'],
      queryFn: async () => {
        if (user_cs && user_cs.filter) {
          const { data } = await api.get<ICustomerSuccessCases[]>(
            `/schedule-cs/all-categorized-cases?user_id=${user_cs.user_id}`,
          );
          setCustomerSuccessInfo(data);
        } else {
          const { data } = await api.get<ICustomerSuccessCases[]>(
            `/schedule-cs/all-categorized-cases?user_id=${user.id}`,
          );
          setCustomerSuccessInfo(data);
        }
      },
    });
  }

  const { isLoading } = useDeals();

  const handleInputChange = useCallback(
    async (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      const [category, deal_id] = value.split(';');

      await api.post('/schedule-cs/update-deal-cache', {
        user_id: user.id,
        category_cache: {
          hubspot_deal_id: deal_id,
          category,
          observation: '',
        },
      });

      const { data } = await api.get<ICustomerSuccessCases[]>(
        `/schedule-cs/all-categorized-cases?user_id=${user.id}`,
      );

      setCustomerSuccessInfo(data);
    },

    [user.id],
  );
  console.log(customerSuccessInfo);
  return (
    <Container>
      <Navegation>
        <H1Navigation>Pagamento {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1NavigationNow>Costumer Success</H1NavigationNow>
      </Navegation>
      <Body>
        {user_cs && !user_cs.filter ? (
          <TitleBackButtonContainer>
            <BackButtonContainer>
              <GoBackLink onClick={() => handleRedirectToIndividualCsHistory()}>
                <Icon>
                  <ArrowBlue />
                </Icon>
                Voltar
              </GoBackLink>
            </BackButtonContainer>

            <TitleContainer>
              <Title
                name="Agenda - "
                nameStrong={user_cs.user_name}
                isShared={false}
                separatorWidth={0}
              />
            </TitleContainer>
          </TitleBackButtonContainer>
        ) : (
          <Title
            name="Agenda"
            nameStrong=""
            isShared={false}
            separatorWidth={0}
          />
        )}

        <Main>
          <ResumePanel
            selectedCalendarPeriod={selectedCalendarPeriod}
            quantity_activities={
              selectedCalendarPeriod === 'Dia'
                ? activitiesInADay.quantity_activities
                : activitiesInAWeek.quantity_activities
            }
            quantity_finished_activities={
              activitiesInADay.quantity_finished_activities
            }
            activities={
              selectedCalendarPeriod === 'Dia'
                ? activitiesInADay.activities
                : activitiesInAWeek.activities
            }
            activitiesInADay={activitiesInADay}
            setActivitiesInADay={setActivitiesInADay}
            hubspotDeals={hubspotDeals.filter(
              hubspotDeal =>
                hubspotDeal.pipeline === '5133291' ||
                hubspotDeal.pipeline === '9664399' ||
                hubspotDeal.pipeline === '12235312',
            )}
            user_id={user.id}
          />

          <ButtonCreateActivity
            onClick={() => setOpenCreateActivityModal(true)}
          >
            <AiOutlinePlus />
            Criar atividade
          </ButtonCreateActivity>

          <CalendarPeriodsButtons>
            {calendarPeriods.map(calendarPeriod => (
              <ScheduleOptionsButton
                key={calendarPeriod}
                selectedValue={selectedCalendarPeriod === calendarPeriod}
                weekdaySchedule
                type="button"
                onClick={() => setSelectedCalendarPeriod(calendarPeriod)}
              >
                {calendarPeriod}
              </ScheduleOptionsButton>
            ))}
          </CalendarPeriodsButtons>

          <CalendarAndFilters>
            {(user.profile === 'Customer Success' ||
              user.profile === 'Customer Success Calculator') &&
              selectedCalendarPeriod === 'Dia' && (
                <InputDate date={date} setDate={setDate} />
              )}

            {user.profile === 'Gestor Customer Success' && (
              <>
                <LabelInput>
                  <Label>Credor:</Label>
                  <Select
                    name="creditor"
                    className="larger"
                    defaultValue="Todos"
                    onChange={handleFilterInputChange}
                  >
                    <option value="Todos">Todos</option>
                    {hubspotDeals.length > 0 &&
                      hubspotDeals.map(hubspotDeal => (
                        <option
                          key={hubspotDeal.hubspot_deal_id}
                          value={hubspotDeal.hubspot_deal_id}
                        >
                          {hubspotDeal.dealname}
                        </option>
                      ))}
                  </Select>
                </LabelInput>

                <LabelInput>
                  <Label>Etapa:</Label>
                  <Input
                    type="text"
                    className="smaller"
                    disabled
                    defaultValue={
                      filterHubspotDeal ? filterHubspotDeal.deal_stage_name : ''
                    }
                  />
                </LabelInput>

                <LabelInput>
                  <Label>Etapa TAG:</Label>
                  <Input
                    type="text"
                    className="smaller"
                    disabled
                    defaultValue={
                      filterHubspotDeal
                        ? filterHubspotDeal.ticket_stage_name
                        : ''
                    }
                  />
                </LabelInput>

                <LabelInput>
                  <Label>Valor:</Label>
                  <Input
                    type="text"
                    className="smaller"
                    disabled
                    defaultValue={
                      filterHubspotDeal &&
                      Object.keys(filterHubspotDeal).length > 0
                        ? formatAmount(Number(filterHubspotDeal.amount))
                        : ''
                    }
                  />
                </LabelInput>
              </>
            )}
          </CalendarAndFilters>

          {(user.profile === 'Customer Success' ||
            user.profile === 'Customer Success Calculator') && (
            <ActivitiesContainer>
              {selectedCalendarPeriod === 'Dia' ? (
                <>
                  <ScheduleComponent
                    date={date}
                    activitiesInADay={activitiesInADay}
                    setActivitiesInADay={setActivitiesInADay}
                  />

                  <ProvidencesComponent
                    providences={
                      activitiesInADay && activitiesInADay.providences
                    }
                    activitiesInADay={activitiesInADay}
                    setActivitiesInADay={setActivitiesInADay}
                  />
                </>
              ) : selectedCalendarPeriod === 'Semana' ? (
                <WeeklySchedule
                  weekdays={weekdays}
                  setWeekdays={setWeekdays}
                  activitiesInAWeek={activitiesInAWeek}
                  setActivitiesInAWeek={setActivitiesInAWeek}
                />
              ) : !customerSuccessInfo ? (
                <LoaderContainer>
                  <Loader type="Oval" color="#0c0b0b" height={25} width={25} />
                  <TextLoader>Carregando</TextLoader>
                </LoaderContainer>
              ) : (
                <DealsListComponent
                  type="CS"
                  user_id={user.id}
                  user_name={user.name}
                  customerSuccessInfo={customerSuccessInfo}
                  handleInputChange={handleInputChange}
                />
              )}
            </ActivitiesContainer>
          )}

          {user.profile === 'Gestor Customer Success' && (
            <ActivitiesContainer>
              <ScheduleManager
                activities={
                  currentMonthlyActivities && currentMonthlyActivities
                }
              />

              <ProvidencesComponent
                providences={
                  currentMonthlyProvidences && currentMonthlyProvidences
                }
                activitiesInADay={activitiesInADay}
                setActivitiesInADay={setActivitiesInADay}
              />
            </ActivitiesContainer>
          )}

          <ModalCreateActivity
            isOpen={openCreateActivityModal}
            onRequestClose={handleCloseCreateActivityModal}
            activitiesInADay={activitiesInADay}
            setActivitiesInADay={setActivitiesInADay}
            hubspotDeals={hubspotDeals}
            currentUserId={currentUserId}
          />
        </Main>
      </Body>
    </Container>
  );
}
