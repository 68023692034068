import axios from 'axios';

const url =
  process.env.REACT_APP_API_URL === 'dev'
    ? 'http://localhost:4000'
    : 'https://api-federal.precato.com.br';

const apiTrfs = axios.create({
  baseURL: url,
});

export default apiTrfs;
