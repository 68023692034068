import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { add, format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';

import { Title } from '../../../../../components/Styles/Title';
import { BackButton } from '../../../../../components/BackButton';
import { Body } from '../../../../../components/Styles/Body';
import { DayPicker } from '../../../../../components/DayPicker';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../../components/Styles/Navigation';

import { ReactComponent as ArrowLeftIcon } from '../../../../../assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow_right.svg';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar_blue.svg';
import { ReactComponent as CalendarSelectedIcon } from '../../../../../assets/icons/calendar_selected.svg';
import { ReactComponent as ListSelectedIcon } from '../../../../../assets/icons/list_selected.svg';
import { ReactComponent as CalendarNotSelectedIcon } from '../../../../../assets/icons/calendar_not_selected.svg';
import { ReactComponent as ListNotSelectedIcon } from '../../../../../assets/icons/list_not_selected.svg';

import block from '../../../../../assets/icons/block.svg';
import unlock from '../../../../../assets/icons/unlock.svg';
import box_time from '../../../../../assets/icons/box_time.svg';
import to_define from '../../../../../assets/icons/to_define.svg';

import { ModalPriorityCloser } from './ModalPriorityCloser';

import { ScheduleCloserContainer } from '../../../components/ScheduleCloserContainer';
import { ModalSchedulePriority } from '../../../components/ModalSchedulePriority';
import { ModalRestrictedTime } from '../../../components/ModalRestrictedTime';
import { Container } from '../../../../../components/Container';
import {
  DivSeparator,
  Separator,
  BackButtonContainer,
  Main,
  BlockPriorityButtons,
  ButtonsContainer,
  SchedulesAndActivitiesContainer,
  SchedulesContainer,
  DaySelectedContainer,
  PreviousButton,
  ContainerInput,
  ContainerInputDate,
  NextButton,
  ScheduleTasksContainer,
  ScheduleLineContainer,
  Icon,
  LineTableContent,
  DateHeaderContainer,
  ButtonsVisualizationContainer,
  ActivitiesWithNotHourDefinedContainer,
  TitleNotHourDefinedContainer,
  NotDefinedHourContainer,
  SearchByNameContainer,
  Activities,
  ActivityContainer,
} from './styles';

import api from '../../../../../services/api';

import { IActivitiesCloserInADayDTO } from '../../../dtos/IActivitiesCloserInADayDTO';
import { ICloserRestrictedTimeDTO } from '../../../dtos/ICloserRestrictedTimeDTO';
import { InfosWorkImpactPanel } from '../../../components/InfosWorkImpactPanel';
import { INextActivityDTO } from '../../../dtos/INextActivityDTO';
import { IActivityDTO } from '../../../dtos/IActivityDTO';
import { ListingActivitiesCloserContainer } from '../../../components/ListingActivitiesCloserContainer';
import { useAuth } from '../../../../../hooks/auth';
import { ScheduleOptionsButton } from '../../../../../components/ScheduleOptionsButton';
import {
  ICadence,
  ListingCadencesCloserContainer,
} from '../../../components/ListingCadencesCloserContainer';
import { FilterContent, Select } from '../../../Closer/styles';
import { CloserWalletCases } from '../../../components/CloserWalletCases';

interface IUserLocation {
  user_id: string;
  closer_id: string;
  type: string;
  blocked: boolean;
  blocked_for_day: Date;
}

interface ICloserInfosQueue {
  queue_id: string;
  queue_name: string;
  position: number;
  type: string;
  valor_min: number;
}

interface IUser {
  id: string;
  name: string;
  avatar: string;
}

export const IndividualCloserHistory: React.FC = () => {
  const weekdays = [
    'DOMINGO',
    'SEGUNDA',
    'TERÇA',
    'QUARTA',
    'QUINTA',
    'SEXTA',
    'SÁBADO',
  ];

  const [scheduleDate, setScheduleDate] = useState(new Date());
  const location = useLocation();
  const { user } = useAuth();

  const locationUser = location.state as IUserLocation;
  const [blockCloser, setBlockCloser] = useState<boolean>(locationUser.blocked);
  const [blockDayCloser, setBlockUnblockDayCloser] = useState<Date>(
    locationUser.blocked_for_day,
  );

  const [selectedView, setSelectedView] = useState<string>('');
  const [userCloser, setUserCloser] = useState<IUser>({} as IUser);
  const [closerActivityInADay, setCloserActivityInADay] =
    useState<IActivitiesCloserInADayDTO>({} as IActivitiesCloserInADayDTO);
  const [restrictedTimeData, setRestrictedTimeData] = useState<
    ICloserRestrictedTimeDTO | INextActivityDTO
  >({} as ICloserRestrictedTimeDTO);
  const [activityData, setActivityData] = useState<IActivityDTO>(
    {} as IActivityDTO,
  );

  const [selectedVisualization, setSelectedVisualization] =
    useState<string>('calendar');

  const [openRestrictedTimeModal, setOpenRestrictedTimeModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openPriorityCloserModal, setOpenPriorityCloserModal] = useState(false);
  const [modalActivityType, setModalActivityType] = useState('');
  const [spokenTime, setSpokenTime] = useState('00:00:00');
  const [cadences, setCadences] = useState([] as ICadence[]);
  const [closerInfosQueue, setCloserInfosQueue] = useState<ICloserInfosQueue[]>(
    [] as ICloserInfosQueue[],
  );

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    api.get(`/users/${locationUser.user_id}`).then(response => {
      setUserCloser(response.data);
    });
  }, [locationUser]);

  useEffect(() => {
    api
      .get(
        `/schedule/list-closer-activity?closer_id=${
          locationUser.user_id
        }&date=${format(scheduleDate, 'yyyy-MM-dd')}`,
      )
      .then(response => {
        setCloserActivityInADay(response.data);
      });

    api
      .get(`/schedule/time-spoken?user_id=${locationUser.user_id}`)
      .then(response => {
        setSpokenTime(response.data);
      });
  }, [scheduleDate, locationUser]);

  const handleOpenActivityModal = useCallback(
    (activity: IActivityDTO | INextActivityDTO) => {
      setOpenActivityModal(true);
      setActivityData(activity);
      if (
        activity.notes_next_activity_date &&
        activity.notes_next_activity_date !== 'A Definir'
      ) {
        setModalActivityType('Priority');
      } else if (activity.notes_next_activity_date === 'A Definir') {
        setModalActivityType('ToDefine');
      } else {
        setModalActivityType('Scheduling');
      }
    },
    [],
  );

  const handleOpenRestrictedTimeModal = useCallback(
    (activity: ICloserRestrictedTimeDTO | INextActivityDTO) => {
      setOpenRestrictedTimeModal(true);
      setRestrictedTimeData(activity);
    },
    [],
  );

  // const handleOpenPriorityCloserModal = useCallback((user_id: string) => {
  //   api.get(`users/list-closer-queues/${user_id}`).then(response => {
  //     setCloserInfosQueue(response.data);
  //   });

  //   setOpenPriorityCloserModal(true);
  // }, []);

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleCloseRestrictedTimeModal = useCallback(() => {
    setOpenRestrictedTimeModal(false);
  }, []);

  const handleClosePriorityCloserModal = useCallback(() => {
    setOpenPriorityCloserModal(false);
  }, []);

  const handleDateInputChange = useCallback((day: Date) => {
    setScheduleDate(day);
  }, []);

  const previousDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: -1,
    });
    setScheduleDate(newDate);
  }, [scheduleDate]);

  const nextDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: 1,
    });

    setScheduleDate(newDate);
  }, [scheduleDate]);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  const getCloserActivityInADay = useCallback(() => {
    api
      .get(
        `/schedule/list-closer-activity?closer_id=${
          locationUser.user_id
        }&date=${format(scheduleDate, 'yyyy-MM-dd')}`,
      )
      .then(response => {
        setCloserActivityInADay(response.data);
      });
  }, [locationUser, scheduleDate]);

  const searchNotDefinedActivity = useCallback(
    (value: string) => {
      const filterActivity = closerActivityInADay.notDefined.filter(
        activity =>
          activity.dealname.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setCloserActivityInADay({
        ...closerActivityInADay,
        notDefined: filterActivity,
      });
    },
    [closerActivityInADay],
  );

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === ''
        ? getCloserActivityInADay()
        : searchNotDefinedActivity(value);
    },
    [getCloserActivityInADay, searchNotDefinedActivity],
  );

  const handleBlockUnblockCloser = useCallback(
    async isBlocked => {
      setBlockCloser(isBlocked);

      await api.patch('/schedule/block-closer', {
        closer_id: locationUser.user_id,
        user_id: user.id,
        managerAction:
          isBlocked === true ? 'Bloquear Sempre' : 'Desbloquear Sempre',
        blocked: isBlocked,
      });
    },
    [locationUser.user_id, user],
  );

  const handleBlockUnblockDayCloser = useCallback(
    async dayBlocked => {
      if (dayBlocked) {
        setBlockUnblockDayCloser(new Date());
      } else {
        setBlockUnblockDayCloser(null);
      }

      let managerAction = '';

      if (!dayBlocked) {
        managerAction = 'Desbloquear Hoje';
      } else {
        managerAction = 'Bloquear Hoje';
      }

      await api.patch('schedule/block-closer', {
        closer_id: locationUser.user_id,
        user_id: user.id,
        managerAction,
        blocked_for_day: dayBlocked,
      });
    },
    [locationUser.user_id, user],
  );

  const handleUpdateActivity = useCallback(
    activity => {
      const updatedActivity = closerActivityInADay.defined.dayHours;
      const indexOldActivity = updatedActivity.findIndex(
        foundIndex =>
          formattedTime(foundIndex.hour) ===
          formattedTime(new Date(activity.timestamp)),
      );

      const indexActivity = updatedActivity[
        indexOldActivity
      ].activity.scheduling.findIndex(
        foundIndex => foundIndex.id === activity.id,
      );

      updatedActivity[indexOldActivity].activity.scheduling.splice(
        indexActivity,
        1,
      );

      setCloserActivityInADay({
        ...closerActivityInADay,
        defined: {
          ...closerActivityInADay.defined,
          dayHours: updatedActivity,
        },
      });
    },
    [closerActivityInADay, formattedTime],
  );

  const getCadences = useCallback(() => {
    api
      .get(`/schedule/list-cadences-cases/${locationUser.user_id}`)
      .then(response => {
        setCadences(response.data);
      });
  }, [locationUser.user_id]);

  const handleSelectChangeView = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setSelectedView('activity');
      } else {
        setSelectedView(value);
      }

      if (value === 'cadence') {
        getCadences();
      }
    },
    [getCadences],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1Navigation>Gestor {'>'}</H1Navigation>
        <H1NavigationNow>Histórico do closer</H1NavigationNow>
      </Navegation>
      <Body>
        {locationUser.type === 'Closer' ? (
          <Title>
            Agenda - <strong>Histórico</strong>
          </Title>
        ) : (
          <Title>
            Agenda - <strong>{userCloser.name}</strong>
          </Title>
        )}
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <BackButtonContainer>
          {locationUser.type === 'Closer' ? (
            <BackButton
              link={{
                pathname: '/negociations/schedule/closer',
              }}
            />
          ) : (
            <BackButton
              link={{
                pathname: '/negociations/schedule/manager/history',
              }}
            />
          )}
        </BackButtonContainer>

        <Main>
          {locationUser.type === 'Closer' ? (
            ' '
          ) : (
            <>
              <BlockPriorityButtons>
                <ButtonsContainer
                  style={{
                    justifyContent:
                      blockCloser === true ? 'center' : 'space-between',
                  }}
                >
                  {blockCloser === true ? (
                    <button
                      id="unblock-button"
                      type="button"
                      onClick={() => {
                        handleBlockUnblockCloser(false);
                      }}
                    >
                      <img src={unlock} alt="Desbloquear" />
                      <span>Desbloquear</span>
                    </button>
                  ) : blockDayCloser &&
                    new Date(blockDayCloser).getDate() ===
                      new Date(todayDate).getDate() ? (
                    <>
                      <button
                        id="unblock-today-button"
                        type="button"
                        onClick={() => {
                          handleBlockUnblockDayCloser(null);
                        }}
                      >
                        <img src={unlock} alt="Desbloquear" />
                        <span>Desbloquear</span>
                      </button>
                      <button
                        id="block-button"
                        type="button"
                        onClick={() => handleBlockUnblockCloser(true)}
                      >
                        <img src={block} alt="Bloquear" />
                        <span>Bloquear Sempre</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        id="block-today-button"
                        type="button"
                        onClick={() => handleBlockUnblockDayCloser(new Date())}
                      >
                        <img src={block} alt="Bloquear" />
                        <span>Bloquear Hoje</span>
                      </button>
                      <button
                        id="block-button"
                        type="button"
                        onClick={() => handleBlockUnblockCloser(true)}
                      >
                        <img src={block} alt="Bloquear" />
                        <span>Bloquear Sempre</span>
                      </button>
                    </>
                  )}
                </ButtonsContainer>
              </BlockPriorityButtons>
            </>
          )}

          <InfosWorkImpactPanel
            schedulesDate={scheduleDate}
            closerInfos={closerActivityInADay.info}
            spokenTime={spokenTime}
            formattedTime={formattedTime}
          />

          <SchedulesAndActivitiesContainer>
            <SchedulesContainer>
              <ButtonsContainer>
                <DaySelectedContainer>
                  <PreviousButton onClick={previousDate}>
                    <ArrowLeftIcon />
                  </PreviousButton>
                  <ContainerInput>
                    <ContainerInputDate>
                      <CalendarIcon />
                      <DayPicker
                        handleChange={day => handleDateInputChange(day)}
                        value={scheduleDate}
                      />
                    </ContainerInputDate>
                  </ContainerInput>
                  <NextButton onClick={nextDate}>
                    <ArrowRightIcon />
                  </NextButton>
                </DaySelectedContainer>
                <FilterContent>
                  <Select
                    defaultValue="acitivity"
                    onChange={handleSelectChangeView}
                  >
                    <option value="">Selecione a opção</option>
                    <option value="activity">Atividades</option>
                    <option value="cadence">Cadencia</option>

                    <option value="wallet">Carteira</option>
                  </Select>
                </FilterContent>
              </ButtonsContainer>

              {selectedView !== 'cadence' && selectedView !== 'wallet' ? (
                <ScheduleTasksContainer>
                  <ScheduleLineContainer className="dateHeader">
                    <Icon>
                      <img src={box_time} alt="" />
                    </Icon>

                    <LineTableContent>
                      <div id="empty" />
                      <DateHeaderContainer>
                        <p id="title">{scheduleDate.getDate()}</p>
                        <p id="subtitle">{weekdays[scheduleDate.getDay()]}</p>
                      </DateHeaderContainer>

                      <ButtonsVisualizationContainer>
                        <button
                          type="button"
                          onClick={() => setSelectedVisualization('calendar')}
                        >
                          {selectedVisualization === 'calendar' ? (
                            <CalendarSelectedIcon />
                          ) : (
                            <CalendarNotSelectedIcon />
                          )}
                        </button>

                        <button
                          type="button"
                          onClick={() => setSelectedVisualization('listing')}
                        >
                          {selectedVisualization === 'listing' ? (
                            <ListSelectedIcon />
                          ) : (
                            <ListNotSelectedIcon />
                          )}
                        </button>
                      </ButtonsVisualizationContainer>
                    </LineTableContent>
                  </ScheduleLineContainer>

                  {selectedVisualization === 'calendar' ? (
                    <ScheduleCloserContainer
                      closerActivityInADay={closerActivityInADay}
                      schedulesDate={
                        new Date(`${format(scheduleDate, 'yyyy-MM-dd')} 3:00`)
                      }
                      formattedTime={formattedTime}
                      handleOpenRestrictedTimeModal={
                        handleOpenRestrictedTimeModal
                      }
                      handleOpenActivityModal={handleOpenActivityModal}
                    />
                  ) : (
                    <ListingActivitiesCloserContainer
                      schedulesDate={
                        new Date(`${format(scheduleDate, 'yyyy-MM-dd')} 3:00`)
                      }
                      closerActivityInADay={closerActivityInADay}
                      formattedTime={formattedTime}
                      handleOpenRestrictedTimeModal={
                        handleOpenRestrictedTimeModal
                      }
                      handleOpenActivityModal={handleOpenActivityModal}
                    />
                  )}
                </ScheduleTasksContainer>
              ) : selectedView === 'wallet' ? (
                <ScheduleTasksContainer>
                  <CloserWalletCases closerId={userCloser.id} />
                </ScheduleTasksContainer>
              ) : cadences.length > 0 ? (
                <ScheduleTasksContainer>
                  <ListingCadencesCloserContainer
                    userId={locationUser.user_id}
                    cadences={cadences}
                    setCadences={setCadences}
                  />
                </ScheduleTasksContainer>
              ) : (
                ''
              )}
            </SchedulesContainer>

            {selectedView !== 'cadence' && selectedView !== 'wallet' ? (
              <ActivitiesWithNotHourDefinedContainer>
                <TitleNotHourDefinedContainer>
                  <p>Atividades com horários a definir</p>
                </TitleNotHourDefinedContainer>

                <NotDefinedHourContainer>
                  <SearchByNameContainer>
                    <input
                      type="text"
                      placeholder="Digite o nome do credor"
                      onChange={handleSearchInputChange}
                    />
                    <AiOutlineSearch />
                  </SearchByNameContainer>

                  <Activities>
                    {closerActivityInADay.notDefined &&
                      closerActivityInADay.notDefined.map(
                        priorityHourNotDefined => (
                          <ActivityContainer
                            key={priorityHourNotDefined.id}
                            talkProps={priorityHourNotDefined.talk}
                            statusTaskProps={priorityHourNotDefined.status}
                            tryTimesProps={String(
                              priorityHourNotDefined.try_times,
                            )}
                            onClick={() =>
                              handleOpenActivityModal(priorityHourNotDefined)
                            }
                          >
                            <div id="headerActivity">
                              <div id="titleActivity">
                                <p id="define">
                                  A definir{' '}
                                  <img src={to_define} alt="A definir" />
                                </p>
                                <p id="priority">Priorização</p>
                              </div>

                              <div id="stage-try-times">
                                <p id="stage">
                                  {priorityHourNotDefined.stage_name}
                                </p>
                                <span id="try_times">
                                  {priorityHourNotDefined.try_times
                                    ? priorityHourNotDefined.try_times
                                    : 0}
                                </span>
                              </div>
                            </div>

                            <p className="credor">
                              Credor: {priorityHourNotDefined.dealname}
                            </p>
                            <p className="credor">
                              Valor: {priorityHourNotDefined.amount}
                            </p>
                          </ActivityContainer>
                        ),
                      )}
                  </Activities>
                </NotDefinedHourContainer>
              </ActivitiesWithNotHourDefinedContainer>
            ) : (
              ''
            )}
          </SchedulesAndActivitiesContainer>

          <ModalSchedulePriority
            type={modalActivityType}
            isOpen={openActivityModal}
            activity={activityData}
            onRequestClose={handleCloseActivityModal}
            handleInfo={null}
            handleUpdateActivity={handleUpdateActivity}
            closerHistory
            handleOpenSLAModal={null}
          />

          <ModalRestrictedTime
            isOpen={openRestrictedTimeModal}
            onRequestClose={handleCloseRestrictedTimeModal}
            handleUpdateActivity={null}
            restrictedTime={restrictedTimeData}
            closerHistory
            dateActivity={String(formattedTime)}
          />

          <ModalPriorityCloser
            isOpen={openPriorityCloserModal}
            onRequestClose={handleClosePriorityCloserModal}
            closer_id={locationUser.user_id}
            closerInfosQueue={closerInfosQueue}
          />
        </Main>
      </Body>
    </Container>
  );
};
