import { useCallback, useEffect, useState } from 'react';
import { isAfter } from 'date-fns';
import { ICampaigns } from '../../dtos/ICampaigns';
import { CampaignResult } from '../CampaignResult';
import { ModalCompaigns } from '../../ShotWpp/ModalCampaigns';
import editPen from '../../../../assets/icons/edit_pen.svg';
import {
  TableRow,
  BodyCell,
  BodyCellButton,
} from '../RegisteredCampaigns/style';
import { ModalCampaigns } from '../../RCSTrigger/RCSModalCampaigns';

interface ITableRowCampaignProps {
  campaign: ICampaigns;
  handleRequestCampaigns: (pageNumber: number) => Promise<void>;
  type: string;
}

export function TableRowCampaign({
  campaign,
  handleRequestCampaigns,
  type,
}: ITableRowCampaignProps): JSX.Element {
  const [isActiveResult, setIsAcvtiveResult] = useState<string>(null);
  const [isActiveModal, setIsAcvtiveModal] = useState(false);
  const [triggersLength, setTriggersLength] = useState<number>(0);
  const [concluded, setConcluded] = useState(false);

  function handleActiveResult(id: string) {
    if (id === isActiveResult) {
      setIsAcvtiveResult(null);
    } else {
      setIsAcvtiveResult(id);
    }
  }

  const handleCloseModalRequest = useCallback(() => {
    setIsAcvtiveModal(false);
  }, []);

  const handleActiveModal = useCallback(() => {
    setIsAcvtiveModal(true);
  }, []);

  useEffect(() => {
    let numbertriggers = 0;
    let numberConcluded = 0;
    let numberTriggerConcluded = 0;
    for (const bratches of campaign.batches) {
      numbertriggers += bratches.triggers.length;

      if (bratches.triggered_at && type !== 'RCS') {
        numberConcluded++;
      } else {
        for (const trigger of bratches.triggers) {
          if (trigger.triggered_at) {
            numberTriggerConcluded++;
          }
        }

        if (numberTriggerConcluded === bratches.triggers.length) {
          numberConcluded++;
        }
      }
    }

    if (numberConcluded === campaign.batches.length) {
      setConcluded(true);
    } else {
      setConcluded(false);
    }

    setTriggersLength(numbertriggers);
  }, [campaign.batches, type]);

  return (
    <>
      <TableRow className="tr-body" key={String(campaign.id)}>
        <BodyCell
          onClick={() => handleActiveResult(campaign.id)}
          concluded={concluded}
        >
          {campaign.name}
        </BodyCell>
        <BodyCell
          onClick={() => handleActiveResult(campaign.id)}
          concluded={concluded}
        >
          {campaign.batches.length}
        </BodyCell>
        <BodyCell
          onClick={() => handleActiveResult(campaign.id)}
          concluded={concluded}
        >
          {triggersLength}
        </BodyCell>
        {!concluded ? (
          <BodyCellButton>
            <button type="button" onClick={() => handleActiveModal()}>
              <img src={editPen} alt="edit" />
            </button>
          </BodyCellButton>
        ) : (
          ''
        )}
      </TableRow>
      <CampaignResult
        isActive={isActiveResult === campaign.id}
        campaign={campaign}
        type={type}
      />
      {type === 'RCS' || type === 'UNIPIX' ? (
        <ModalCampaigns
          isOpen={isActiveModal}
          onRequestClose={handleCloseModalRequest}
          campaigns={campaign}
          handleRequestCampaigns={handleRequestCampaigns}
          type={type}
        />
      ) : (
        <ModalCompaigns
          isOpen={isActiveModal}
          onRequestClose={handleCloseModalRequest}
          campaigns={campaign}
          handleRequestCampaigns={handleRequestCampaigns}
        />
      )}
    </>
  );
}
