import Modal from 'react-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  LostModalStyle,
  Main,
  ButtonsContainer,
  ApprovedButton,
  InputArea,
  DeniedButton,
  Container,
  TitleContainer,
  DescTitle,
  UploadFileContainer,
  DownloadFileContainer,
  LostModalStyleCEO,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

interface DescriptionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  description: IDescriptionValue;
  descriptionName: string;
  handleModalSubmit: (value: string, file: IFileProps) => void;
  dataFileName: string;
  dataDownload: { question: string; downloadLink: string }[];
}

interface IDescriptionValue {
  prospect_description: string;
  prospect_automatization_description: string;
  rcl_percent_description: string;
}

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export function DescriptionModal({
  isOpen,
  onRequestClose,
  description,
  descriptionName,
  handleModalSubmit,
  dataFileName,
  dataDownload,
}: DescriptionModalProps): JSX.Element {
  const [descriptionInput, setDescriptionInput] = useState<string>('');
  const [file, setFile] = useState<IFileProps>({} as IFileProps);
  const [fileDownload, setFileDownload] = useState<any>({});

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { value } = event.target;

      setDescriptionInput(value);
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const { user } = useAuth();

  const handleSubmit = useCallback((event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData();

    const fileInput = event.target.elements.namedItem(
      'img',
    ) as HTMLInputElement;

    const newFile: IFileProps = {
      file: fileInput.files[0],
      name: fileInput.files[0].name,
      readableSize: fileInput.files[0].size.toString(),
    };

    setFile(newFile);

    if (fileInput.files && fileInput.files.length > 0) {
      formData.append('file', fileInput.files[0]);

      api
        .post('/pricing/forms/uploadfiles', formData)
        .then(() => {
          console.log('Upload realizado com sucesso!');
        })
        .catch(error => {
          console.log('Erro ao realizar o upload:', error);
        });
    }
  }, []);

  useEffect(() => {
    const regexForDescription = /^(.*?)_description/;

    const descriptionNameRegex = descriptionName.match(regexForDescription)[1];
    for (const key in dataDownload) {
      if (Object.prototype.hasOwnProperty.call(dataDownload, key)) {
        const data = dataDownload[key];
        if (data.question === descriptionNameRegex) {
          setFileDownload(data);
        }
      }
    }
  }, [fileDownload, dataDownload, descriptionName]);

  const handleButtonClick = () => {
    const submitButton = document.getElementById('submitButton');
    if (submitButton) {
      submitButton.removeAttribute('hidden');
      submitButton.click();
      submitButton.setAttribute('hidden', 'true');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={user.profile === 'CEO' ? LostModalStyleCEO : LostModalStyle}
      onRequestClose={handleCloseModal}
    >
      <Main>
        <Container>
          <TitleContainer>
            <DescTitle>Descrição</DescTitle>
          </TitleContainer>
          {user.profile === 'CEO' ? (
            <InputArea disabled defaultValue={description[descriptionName]} />
          ) : (
            <>
              <InputArea
                name={descriptionName}
                defaultValue={description[descriptionName] || ''}
                onChange={handleInputChange}
              />

              <UploadFileContainer>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <input
                    name="img"
                    type="file"
                    onChange={event => {
                      const selectedFile = event.target.files[0];
                      const newFile: IFileProps = {
                        file: selectedFile,
                        name: selectedFile.name,
                        readableSize: selectedFile.size.toString(),
                      };
                      setFile(newFile);
                    }}
                  />
                  <input
                    type="submit"
                    value="Enviar"
                    id="submitButton"
                    hidden
                  />
                </form>
              </UploadFileContainer>
            </>
          )}

          {user.profile === 'CEO' ? (
            <ButtonsContainer>
              <DownloadFileContainer
                href={fileDownload.downloadLink}
                download={`${fileDownload.question}.img`}
                target="_blank"
              >
                Baixar Arquivos
              </DownloadFileContainer>
              <DeniedButton
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Sair
              </DeniedButton>{' '}
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <DeniedButton
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancelar
              </DeniedButton>
              <ApprovedButton
                onClick={() => {
                  handleModalSubmit(descriptionInput, file);
                  handleButtonClick();
                }}
              >
                Salvar
              </ApprovedButton>
            </ButtonsContainer>
          )}
        </Container>
      </Main>
    </Modal>
  );
}
