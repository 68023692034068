import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.25rem;
  background: #f6f9fd;
  border-radius: 0 0.25rem 0 0;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    margin: 0.625rem auto auto 0.625rem;
  }

  @media (max-width: 1270px) {
    margin-top: 3.5rem;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const Text = styled.h1`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  line-height: 1.188rem;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;

  color: #666360;
  padding-left: 0.2rem;
`;

export const TextActiveScreen = styled.h1`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  line-height: 1.188rem;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;

  color: #1665d8;
  padding-left: 0.2rem;
`;
