import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';

import { gql, useSubscription } from '@apollo/client';
import api from '../../../../../services/api';

import { Container } from '../../../../../components/Container';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../../components/Styles/Navigation';
import { Body } from '../../../../../components/Styles/Body';
import { Title } from '../../../../../components/Styles/Title';
import { BackButton } from '../../../../../components/BackButton';

import { ClosedDeals } from './ClosedDeals';

import {
  DivSeparator,
  Separator,
  BackButtonContainer,
  Main,
  InputDateAndFilterContainer,
  SelectLabelContainer,
  Span,
  Select,
} from './styles';

import { InputDate } from '../../../../../components/InputDate';
import { TotalAmountPanel } from './TotalAmountPanel';

interface IClosedDeal {
  id: string;
  deal_name: string;
  closer_name: string;
  stage_name: string;
  status: string;
  link: string;
  amount: string;
}

interface IClosedDealInfo {
  closedDeals: IClosedDeal[];
  totalAmountClosed: string;
  totalAmountConcluded: string;
  totalCoursePending: string;
  totalCourseLate: string;
  totalLockedPending: string;
  totalLockedLate: string;
  quantityClosed: number;
  quantityBatonPass: number;
  quantityCoursePending: number;
  quantityCourseLate: number;
  quantityLockedPending: number;
  quantityLockedLate: number;
}

export function ListingClosedDeals(): JSX.Element {
  const [date, setDate] = useState(new Date());

  const [closedDealsInfo, setClosedDealsInfo] = useState<IClosedDealInfo>(
    {} as IClosedDealInfo,
  );
  const [currentItems, setCurrentItems] = useState<IClosedDealInfo>(
    {} as IClosedDealInfo,
  );

  const REFRESH = gql`
    subscription ListClosedDeals {
      listClosedDeals {
        id
        hubspot_owner_id
        hubspot_deal_id
        closer_id
        dealstage
        status
        created_at
        updated_at
      }
    }
  `;

  const refresh = useSubscription(REFRESH);

  useEffect(() => {
    if (refresh.data) {
      api
        .get<IClosedDealInfo>(
          `schedule/get-closed-deal/${format(date, 'yyyy-MM-dd')}`,
        )
        .then(response => {
          setClosedDealsInfo(response.data);
          setCurrentItems(response.data);
        });
    }
  }, [date, refresh]);

  useEffect(() => {
    api
      .get(`/schedule/get-closed-deal/${format(date, 'yyyy-MM-dd')}`)
      .then(response => {
        setClosedDealsInfo(response.data);
        setCurrentItems(response.data);
      });
  }, [date]);

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setCurrentItems(closedDealsInfo);
      } else {
        const filteredItems = closedDealsInfo.closedDeals.filter(
          closed_deal => {
            return closed_deal.status === value;
          },
        );

        setCurrentItems({ ...currentItems, closedDeals: filteredItems });
      }
    },
    [closedDealsInfo, currentItems],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1Navigation>Gestor {'>'} </H1Navigation>
        <H1Navigation>Histórico {'>'} </H1Navigation>

        <H1NavigationNow>Passagem de bastão</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Passagem de bastão</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <BackButtonContainer>
          <BackButton
            link={{
              pathname: '/negociations/schedule/manager/history',
            }}
          />
        </BackButtonContainer>

        <Main>
          <TotalAmountPanel date={date} closedDealsInfo={closedDealsInfo} />

          <InputDateAndFilterContainer>
            <InputDate date={date} setDate={setDate} />

            <SelectLabelContainer>
              <Span>Filtre as atividades por:</Span>

              <Select defaultValue="" onChange={handleSelectChange}>
                <option value="">Selecione uma opção</option>
                <option value="Atrasado">Atrasado</option>
                <option value="Perdido">Perdido</option>
                <option value="Pendente">Pendente</option>
                <option value="Concluído">Concluído</option>
                <option value="Concluído Atrasado">Concluído Atrasado</option>
              </Select>
            </SelectLabelContainer>
          </InputDateAndFilterContainer>

          {currentItems.closedDeals &&
            currentItems.closedDeals.map(closedDeal => (
              <ClosedDeals key={closedDeal.id} closedDeal={closedDeal} />
            ))}
        </Main>
      </Body>
    </Container>
  );
}
