import Modal from 'react-modal';
import { useCallback } from 'react';
import {
  CellBody,
  CellHeader,
  LoanModalStyle,
  ModalHeader,
  Table,
  TableRowBody,
  TableRowHead,
  Tbody,
  Thead,
  TitleTable,
} from './styles';
import { IResponseLoan } from '../../dtos/IResponse';

Modal.setAppElement('#root');

interface ILoanModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  loanData: IResponseLoan;
}

const tableHeaderColumn = [
  'Finan.',
  'Quitação',
  'Parcela',
  'Taxa',
  'Pagas',
  'Total parc',
  'Restam',
  'Total',
  'Falta',
  'Empresa',
];

export function LoanModal({
  isOpen,
  onRequestClose,
  loanData,
}: ILoanModalProps): JSX.Element {
  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={LoanModalStyle}>
      <ModalHeader>Detalhes do Consignado</ModalHeader>
      {loanData && loanData.loanData && loanData.loanData.length > 1 ? (
        <Table>
          <TableRowHead>
            <CellHeader />
            {tableHeaderColumn.map(column => (
              <CellHeader>
                <TitleTable>{column}</TitleTable>
              </CellHeader>
            ))}
          </TableRowHead>
          <Tbody>
            <TableRowBody>
              <CellBody>
                <b>TOTAL</b>
              </CellBody>
              <CellBody>{loanData.totalValueFinanced}</CellBody>
              <CellBody>{loanData.totalValueDischarge}</CellBody>
              <CellBody>{loanData.totalInstallments}</CellBody>
              <CellBody />
              <CellBody />
              <CellBody />
              <CellBody />
              <CellBody>{loanData.totalValue}</CellBody>
              <CellBody>{loanData.totalRemaining}</CellBody>
              <CellBody>Mais de uma</CellBody>
            </TableRowBody>
          </Tbody>
        </Table>
      ) : (
        <Table>
          <Thead>
            <TableRowHead>
              <CellHeader>
                <TitleTable>Data</TitleTable>
              </CellHeader>
              {tableHeaderColumn.map(column => (
                <CellHeader>
                  <TitleTable>{column}</TitleTable>
                </CellHeader>
              ))}
            </TableRowHead>
          </Thead>
          <Tbody>
            <TableRowBody>
              {loanData &&
                loanData.loanData.map(loan => (
                  <>
                    <CellBody>{loan.date}</CellBody>
                    <CellBody>{loan.financed}</CellBody>
                    <CellBody>{loan.discharge}</CellBody>
                    <CellBody>{loan.installments}</CellBody>
                    <CellBody>{loan.tax}</CellBody>
                    <CellBody>{loan.portionPayed}</CellBody>
                    <CellBody>{loan.portion}</CellBody>
                    <CellBody>{loan.remaining}</CellBody>
                    <CellBody>{loan.total}</CellBody>
                    <CellBody>{loan.remainingValue}</CellBody>
                    <CellBody>{loan.company}</CellBody>
                  </>
                ))}
            </TableRowBody>
          </Tbody>
        </Table>
      )}
      {loanData ? (
        <Table>
          <Thead>
            <TableRowHead>
              <CellHeader>
                <TitleTable>Valor Líquido</TitleTable>
              </CellHeader>
              <CellHeader>
                <TitleTable>Proposta</TitleTable>
              </CellHeader>
              <CellHeader>
                <TitleTable>%</TitleTable>
              </CellHeader>
              <CellHeader>
                <TitleTable>Total Ganho</TitleTable>
              </CellHeader>
              <CellHeader>
                <TitleTable>Valor Gerado</TitleTable>
              </CellHeader>
              <CellHeader>
                <TitleTable>%</TitleTable>
              </CellHeader>
            </TableRowHead>
          </Thead>

          <Tbody>
            <TableRowBody>
              <CellBody>{loanData.liquidValue}</CellBody>
              <CellBody>{loanData.proposal}</CellBody>
              <CellBody>{loanData.porposalPercentage}%</CellBody>
              <CellBody>{loanData.totalGain}</CellBody>
              <CellBody>{loanData.value}</CellBody>
              <CellBody>{loanData.percentageValue}</CellBody>
            </TableRowBody>
          </Tbody>
        </Table>
      ) : (
        ' '
      )}
    </Modal>
  );
}
