import styled, { css } from 'styled-components';

interface IButtonToogle {
  toogleButtonSelected: string;
}

export const ContentTableData = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  width: 50%;

  margin-top: 1rem;
  margin-bottom: 1rem;

  .second-table-diary {
    border-radius: 0.5rem 0 0.5rem 0.5rem;
  }

  .tkc-row {
    margin-top: 0.5rem;
  }

  .meta-row {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
  }
`;

export const ContainerToogleButtons = styled.div<IButtonToogle>`
  display: flex;

  justify-content: center;

  margin-left: 2.5rem;

  .day-toogle {
    width: 5.375rem;
  }

  .week-toogle {
    width: 5.375rem;
  }

  ${props =>
    props.toogleButtonSelected === '1'
      ? css`
          .first-toogle {
            background: var(--blue-100);
          }
        `
      : props.toogleButtonSelected === '2'
      ? css`
          .second-toogle {
            background: var(--blue-100);
          }
        `
      : props.toogleButtonSelected === '3'
      ? css`
          .third-toogle {
            background: var(--blue-100);
          }
        `
      : props.toogleButtonSelected === 'DIA'
      ? css`
          .day-toogle {
            background: var(--blue-100);
          }
        `
      : css`
          .week-toogle {
            background: var(--blue-100);
          }
        `}
`;

export const ButtonToogle = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 2.375rem;
  width: 3.375rem;

  font-weight: 600;
  font-size: 0.9375rem;

  margin-left: 1rem;

  border-radius: 1.25rem 1.25rem 0 0;
  border: solid 2px var(--blue-100);
  border-bottom: 0;

  color: var(--blue-800);

  background: var(--white);

  text-align: center;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;

  border: solid 2px var(--blue-100);

  border-radius: 0.5rem;

  margin-left: 1rem;
  margin-right: 1rem;

  .accompaniment-row-covertion {
    justify-content: center;
  }
`;

export const ContentTableRow = styled.div`
  display: flex;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 0.875rem;

  .first-table-item-diary {
    display: flex;

    background: var(--blue-100);

    width: 60%;

    align-items: center;
    justify-content: center;
  }

  .title-item {
    width: 80%;

    font-size: 0.9375rem;
  }

  .percentage-item {
    background: var(--blue-100);

    width: 20%;

    font-size: 0.9375rem;
    font-weight: 700;
  }

  .red-item {
    color: var(--red-500);
  }

  .first-table-item {
    display: flex;

    background: var(--blue-100);

    width: 27%;

    align-items: center;
    justify-content: center;
  }

  .first-solo-item {
    display: flex;

    background: var(--blue-100);

    width: 35%;
  }

  .second-table-item {
    width: 73%;

    border-bottom: 1px solid var(--blue-100);
  }

  .last-second-table-item {
    width: 73%;

    border: 0;
  }

  .second-table-item-diary {
    border-bottom: 2px solid var(--blue-100);
  }

  .second-solo-item {
    width: 65%;
  }

  .last-second-table-item {
    width: 73%;
  }
`;

export const ContentTableItem = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 2.25rem;
`;
