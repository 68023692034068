import addZerosToNumber from './addZerosToNumber';

export default function convertSecondsInHours(seconds: number): string {
  if (seconds === 0) {
    return '00:00:00';
  }

  const hour = Math.trunc(seconds / 3600);
  const minutes = Math.trunc((seconds - 3600 * hour) / 60);
  seconds %= 60;

  return `${addZerosToNumber(hour)}:${addZerosToNumber(
    minutes,
  )}:${addZerosToNumber(seconds)}`;
}
