import styled from 'styled-components';

export const ComponentBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TableTitle = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  color: #05233e;
`;

export const Table = styled.table`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.875rem;
  flex-direction: column;
`;

export const TableHeader = styled.thead`
  width: 71.563rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #cfdef3;

  display: grid;
  grid-template-columns: 18.563rem 0rem 14.5rem 0rem 14.5rem 0rem 24rem;
  align-items: center;
`;

export const TableHead = styled.th`
  display: flex;
  justify-content: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #05233e;
`;

export const VerticalSeparator = styled.div`
  width: 0rem;
  height: 2rem;
  border-left: 0.063rem solid #063966a3;
`;

export const TableBody = styled.tbody`
  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }
`;
