import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import { NavigationBar } from '../../../components/NavigationBar';
import { Title } from '../../../components/Title';
import {
  TitleContainer,
  TextContainer,
  Text,
  ButtonsTableContainer,
  Button,
  Table,
  THead,
  TRowHead,
  TCellHead,
  TBody,
  TRowBody,
  TCell,
} from './styles';
import { BackButton } from '../../../components/BackButton';
import apiChatbot from '../../../services/apiChatbot';
import formatDateWithHours from '../../../utils/formatDateWithHours';

interface IAgentProps {
  id: string;
  name: string;
  initial_date?: Date;
  final_date?: Date;
}

interface IRoutes {
  transferredAndClosedRoute: string;
  openedAttendanceRoute: string;
}

interface IAttendance {
  id: number;
  mobile_phone: string;
  opened_at: Date;
  finished_at: Date;
  transferred_at: Date;
  agent_id: string;
  contact_name: string;
  hubspot_contact_id: string;
}

interface ITakeBlipContactLink {
  attendance_id: number;
  link: string;
}

export const ListAttendancesByAgent: React.FC = () => {
  const [buttonActive, setButtonActive] = useState('open');
  const [attendanceOpened, setAttendanceOpened] = useState<IAttendance[]>([]);
  const [attendanceClosed, setAttendanceClosed] = useState<IAttendance[]>([]);
  const [takeBlipContactLink, setTakeBlipContactLink] = useState<
    ITakeBlipContactLink[]
  >([]);

  const location = useLocation();
  const agent = location.state as IAgentProps;

  const createRoutes = useCallback(() => {
    let route = '';

    if (agent.initial_date) {
      route += `?initial_date=${formatDateWithHours(agent.initial_date)}`;
    }

    if (agent.final_date) {
      route += agent.initial_date ? '&' : '?';
      route += `final_date=${formatDateWithHours(agent.final_date)}`;
    }

    const routes = {} as IRoutes;

    routes.transferredAndClosedRoute = `attendance/agent/transferred-closed/${agent.id}${route}`;

    routes.openedAttendanceRoute = `/attendance/agent/opened/${agent.id}${route}`;

    return routes;
  }, [agent]);

  const updatePage = useCallback(async () => {
    const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

    const routes = createRoutes();

    await chatbot.get(routes.openedAttendanceRoute).then(response => {
      setAttendanceOpened(response.data);
    });

    await chatbot.get(routes.transferredAndClosedRoute).then(response => {
      setAttendanceClosed(response.data);
    });
  }, [createRoutes]);

  useEffect(() => {
    updatePage();
  }, [createRoutes, updatePage]);

  useEffect(() => {
    const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

    const attendanceOpenedIds = attendanceOpened.map(
      attendance => attendance.id,
    );

    const attendanceClosedIds = attendanceClosed.map(
      attendance => attendance.id,
    );

    const attendanceIds = attendanceOpenedIds.concat(attendanceClosedIds);

    chatbot
      .post('/attendance/contact-link', {
        attendance_ids: attendanceIds,
      })
      .then(response => {
        setTakeBlipContactLink(response.data);
      });
  }, [attendanceClosed, attendanceOpened]);

  useQuery('infos', updatePage, {
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const openContactLink = useCallback(
    attendance_id => {
      const link = takeBlipContactLink.find(
        attendanceId => attendanceId.attendance_id === attendance_id,
      )?.link;

      window.open(link, '_blank');
    },
    [takeBlipContactLink],
  );

  return (
    <Container>
      <NavigationBar
        past_screens="Conexão > Take blip"
        active_screen="Relatório de atendimento"
      />
      <Body>
        <TitleContainer>
          <BackButton
            link={{
              pathname: '/connection/take-blip',
            }}
            isNotMargin
          />

          <Title name="Relatório de atendimento" isShared />
        </TitleContainer>

        <TextContainer>
          <Text>{agent.name}</Text>
        </TextContainer>

        <ButtonsTableContainer>
          <Button
            isSelected={buttonActive === 'open'}
            onClick={() => setButtonActive('open')}
          >
            Abertos
          </Button>

          <Button
            isSelected={buttonActive === 'close'}
            onClick={() => setButtonActive('close')}
          >
            Finalizados
          </Button>
        </ButtonsTableContainer>

        <Table>
          <THead>
            <TRowHead>
              <TCellHead className="data-creditor">Dados do credor</TCellHead>
              <TCellHead>Data</TCellHead>
            </TRowHead>
            <TRowHead>
              <TCellHead>Nome</TCellHead>
              <TCellHead>Hubspot Contact ID</TCellHead>
              <TCellHead>Transferência</TCellHead>
              <TCellHead>Abertura</TCellHead>
              <TCellHead>Finalização</TCellHead>
            </TRowHead>
          </THead>
          {buttonActive === 'open' ? (
            <TBody>
              {attendanceOpened &&
                attendanceOpened.map(attendance => (
                  <TRowBody
                    key={attendance.id}
                    onClick={() => openContactLink(attendance.id)}
                  >
                    <TCell>
                      {attendance.contact_name
                        ? attendance.contact_name
                        : attendance.mobile_phone}
                    </TCell>
                    <TCell>{attendance.hubspot_contact_id}</TCell>
                    <TCell>
                      {attendance.transferred_at
                        ? formatDateWithHours(attendance.transferred_at)
                        : '-'}
                    </TCell>
                    <TCell>
                      {attendance.opened_at
                        ? formatDateWithHours(attendance.opened_at)
                        : '-'}
                    </TCell>
                    <TCell>
                      {attendance.finished_at
                        ? formatDateWithHours(attendance.finished_at)
                        : '-'}
                    </TCell>
                  </TRowBody>
                ))}
            </TBody>
          ) : (
            <TBody>
              {attendanceClosed &&
                attendanceClosed.map(attendance => (
                  <TRowBody onClick={() => openContactLink(attendance.id)}>
                    <TCell>
                      {attendance.contact_name
                        ? attendance.contact_name
                        : attendance.mobile_phone}
                    </TCell>
                    <TCell>{attendance.hubspot_contact_id}</TCell>
                    <TCell>
                      {attendance.transferred_at
                        ? formatDateWithHours(attendance.transferred_at)
                        : '-'}
                    </TCell>
                    <TCell>
                      {attendance.opened_at
                        ? formatDateWithHours(attendance.opened_at)
                        : '-'}
                    </TCell>
                    <TCell>
                      {attendance.finished_at
                        ? formatDateWithHours(attendance.finished_at)
                        : '-'}
                    </TCell>
                  </TRowBody>
                ))}
            </TBody>
          )}
        </Table>
      </Body>
    </Container>
  );
};
