import { useCallback, useState, useEffect } from 'react';
import { ToggleSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';
import 'react-day-picker/lib/style.css';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import { NavigationBar } from '../../../components/NavigationBar';
import { Title } from '../../../components/Title';
import { InputInitialAndFinalDayPicker } from '../components/InputInitialAndFinalDayPicker';
import { ButtonsOrderTakeBlip } from '../components/ButtonsOrderTakeBlip';
import apiChatbot from '../../../services/apiChatbot';
import millisecondsToMinutesAndSecondsAndDays from '../../../utils/millisecondsToMinutesAndSecondsAndDays';
import convertHoursInSeconds from '../../../utils/convertHoursInSeconds';
import convertSecondsInHours from '../../../utils/convertSecondsInHours';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import {
  DateAndFilterContainer,
  DateContainer,
  ClearDateButton,
  FiltersContainer,
  Text,
  Label,
  Select,
  Option,
  Divisor,
  SearchButton,
  SwitchContainer,
  TextSwitch,
  CardsContainer,
  TextCard,
  Card,
  InfoCardContainer,
  Info,
  Value,
  Legend,
  ButtonsTableContainer,
  Button,
  Table,
  THead,
  TRowHead,
  TCellHead,
  TBody,
  TRowBody,
  TCell,
  AgentNameContainer,
  AgentStatusContainer,
  CellNameContainer,
  AgentStatus,
} from './styles';
import formatDateWithHours from '../../../utils/formatDateWithHours';

interface IAttendance {
  opened_at: Date;
  finished_at: Date;
  agent_id: string;
}

interface IAgentMetrics {
  id: string;
  name: string;
  metrics: Array<{
    created_at: Date;
    average_attendance_time: string;
    average_response_time: string;
  }>;
}
interface IAttendanceTags {
  tag: string;
  closed: number;
  attendance_time: number;
}

interface IAgentsStatus {
  id: string;
  status: boolean;
}

interface IRoutes {
  transferredAndClosedRoute: string;
  openedAttendanceRoute: string;
  agentMetricsRoute: string;
  queueRoute: string;
  queueTimeRoute: string;
  attendanceTagsRoute: string;
  agentsStatus: string;
}

export const TakeBlip: React.FC = () => {
  const [buttonActive, setButtonActive] = useState('Atendentes');
  const [channelSelected, setChannelSelected] = useState(' ');
  const [queueSelected, setQueueSelected] = useState('CORE');
  const [maxTimeAttendance, setMaxTimeAttendance] = useState('');
  const [businessHourChecked, setBusinessHourChecked] = useState(false);
  const [from, setFrom] = useState<Date>(undefined);
  const [to, setTo] = useState<Date>(undefined);
  const [attendanceOpened, setAttendanceOpened] = useState<IAttendance[]>([]);
  const [attendanceClosed, setAttendanceClosed] = useState<IAttendance[]>([]);
  const [attendanceTags, setAttendanceTags] = useState<IAttendanceTags[]>([]);
  const [agentsStatus, setAgentsStatus] = useState<IAgentsStatus[]>([]);
  const [queue, setQueue] = useState([]);
  const [queueTime, setQueueTime] = useState('');
  const [averageAttendance, setAverageAttendance] = useState('00:00:00');
  const [averageResponse, setAverageResponse] = useState('00:00:00');
  const [agentMetrics, setAgentMetrics] = useState<IAgentMetrics[]>(
    [] as IAgentMetrics[],
  );

  const history = useHistory();

  const channels = [
    { id: '1', label: 'SMS', value: 'SMS' },
    { id: '2', label: 'Messenger', value: 'MESSENGER' },
    { id: '3', label: 'E-mail', value: 'EMAIL' },
    { id: '4', label: 'Site', value: 'SITE' },
    { id: '5', label: 'Facebook', value: 'FACEBOOK' },
    { id: '6', label: 'Whatsapp', value: 'WPP' },
    { id: '7', label: 'Google', value: 'GOOGLE' },
    { id: '8', label: 'Todos', value: ' ' },
  ];

  const queues = ['CORE', 'LAB'];

  const createRoutes = useCallback(() => {
    let route = '';

    if (from) {
      route += `?initial_date=${formatDateWithHours(from)}`;
    }

    if (to) {
      route += from ? '&' : '?';
      route += `final_date=${formatDateWithHours(to)}`;
    }

    const routes = {} as IRoutes;

    routes.agentsStatus = 'attendance/agents-status';

    const customAttendanceRoute = `${queueSelected}${route}${
      channelSelected === ' '
        ? ''
        : route
        ? `&input_channel=${channelSelected}`
        : `?input_channel=${channelSelected}`
    }`;

    routes.transferredAndClosedRoute = `attendance/transferred-closed/${customAttendanceRoute}`;

    routes.openedAttendanceRoute = `/attendance/opened/${customAttendanceRoute}`;

    routes.agentMetricsRoute = `/attendance/agent-metrics${route}`;

    const customQueueRoute = `${queueSelected}${
      route ? `${route}&` : '?'
    }work_time=${businessHourChecked}${
      channelSelected === ' ' ? '' : `&input_channel=${channelSelected}`
    }`;

    routes.queueRoute = `/attendance/queue/${customQueueRoute}`;

    routes.queueTimeRoute = `/attendance/queue-time/${customQueueRoute}`;

    routes.attendanceTagsRoute = `/attendance/tags/${customQueueRoute}`;

    return routes;
  }, [from, queueSelected, to, businessHourChecked, channelSelected]);

  const updatePage = useCallback(async () => {
    const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

    const routes = createRoutes();

    await chatbot.get(routes.agentsStatus).then(response => {
      setAgentsStatus(response.data);
    });

    await chatbot.get(routes.agentMetricsRoute).then(response => {
      setAgentMetrics(response.data);
    });

    await chatbot.get(routes.openedAttendanceRoute).then(response => {
      setAttendanceOpened(response.data);
    });

    await chatbot.get(routes.transferredAndClosedRoute).then(response => {
      setAttendanceClosed(response.data);
    });

    await chatbot.get(routes.queueRoute).then(response => {
      setQueue(response.data);
    });

    await chatbot.get(routes.queueTimeRoute).then(response => {
      setQueueTime(response.data.queue_time);
    });

    await chatbot.get(routes.attendanceTagsRoute).then(response => {
      setAttendanceTags(response.data);
    });
  }, [createRoutes]);

  useEffect(() => {
    updatePage();
  }, [createRoutes, updatePage]);

  useQuery('infos', updatePage, {
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const handleResetDate = useCallback(() => {
    setFrom(undefined);
    setTo(undefined);
  }, []);

  const handleChangeSwitch = useCallback(() => {
    setBusinessHourChecked(!businessHourChecked);
  }, [businessHourChecked]);

  const handleAttendanceMediumTimeSum = useCallback(() => {
    let avgAttendance = 0;

    let avgResponse = 0;

    for (const agent of agentMetrics) {
      const arrayAttendanceMetrics = agent.metrics.map(metric =>
        convertHoursInSeconds(metric.average_attendance_time),
      );

      const sumAttendanceMetrics = arrayAttendanceMetrics.reduce(
        (previous, current) => previous + current,
        0,
      );

      const arrayResponseMetrics = agent.metrics.map(metric =>
        convertHoursInSeconds(metric.average_response_time),
      );

      const sumResponseMetrics = arrayResponseMetrics.reduce(
        (previous, current) => previous + current,
        0,
      );

      if (sumAttendanceMetrics > 0 && sumResponseMetrics > 0) {
        avgAttendance += sumAttendanceMetrics / agent.metrics.length;
        avgResponse += sumResponseMetrics / agent.metrics.length;
      }
    }

    if (agentMetrics.length > 0) {
      avgAttendance = Math.round(avgAttendance / agentMetrics.length);

      avgResponse = Math.round(avgResponse / agentMetrics.length);

      setAverageAttendance(convertSecondsInHours(avgAttendance));

      setAverageResponse(convertSecondsInHours(avgResponse));
    }
  }, [agentMetrics]);

  const handleTimeMediumAttendance = useCallback((average: string[]) => {
    if (average.length === 0) {
      return ' - ';
    }

    const arrayMetrics = average.map(metric => convertHoursInSeconds(metric));

    const sumMetrics = arrayMetrics.reduce(
      (previous, current) => previous + current,
      0,
    );

    const avgMetrics = Math.round(sumMetrics / average.length);

    return convertSecondsInHours(avgMetrics);
  }, []);

  const handleOpenedTicket = useCallback(
    (agent: IAgentMetrics) => {
      return attendanceOpened.filter(
        attendance => attendance.agent_id === agent.id,
      ).length;
    },
    [attendanceOpened],
  );

  const handleClosedTicket = useCallback(
    (agent: IAgentMetrics) => {
      return attendanceClosed.filter(
        attendance => attendance.agent_id === agent.id,
      ).length;
    },
    [attendanceClosed],
  );

  const maximumTime = useCallback(() => {
    const attendanceTimes = attendanceClosed.map(
      closed =>
        new Date(closed.finished_at).getTime() -
        new Date(closed.opened_at).getTime(),
    );

    attendanceTimes.sort((firstTime, secondTime) => secondTime - firstTime);

    setMaxTimeAttendance(
      millisecondsToMinutesAndSecondsAndDays(attendanceTimes[0]),
    );
  }, [attendanceClosed]);

  useEffect(() => {
    handleAttendanceMediumTimeSum();
    maximumTime();
  }, [handleAttendanceMediumTimeSum, maximumTime]);

  return (
    <Container>
      <NavigationBar past_screens="Conexão > " active_screen="Take blip" />
      <Body>
        <Title name="Relatório de atendimento" />

        <DateAndFilterContainer>
          <DateContainer>
            <InputInitialAndFinalDayPicker
              initialDate={from}
              finalDate={to}
              dayClickFrom={value => setFrom(value)}
              dayClickTo={value => setTo(value)}
            />
            <ClearDateButton onClick={handleResetDate}>
              <ClearIcon />
              Limpar data
            </ClearDateButton>
          </DateContainer>

          <FiltersContainer>
            <Text>Filtre por:</Text>

            <Label htmlFor="channel">Canal</Label>
            <Select
              name="channel"
              id="channel"
              value={channelSelected}
              defaultValue="Selecione um canal"
              onChange={e => setChannelSelected(e.target.value)}
            >
              <Option value="Selecione um canal" disabled>
                Selecione uma canal
              </Option>
              {channels.map(channel => (
                <Option key={channel.id} value={channel.value}>
                  {channel.label}
                </Option>
              ))}
            </Select>

            <Divisor />

            <Label htmlFor="queue">Fila</Label>
            <Select
              name="queue"
              id="queue"
              value={queueSelected}
              defaultValue="Selecione uma fila"
              onChange={e => setQueueSelected(e.target.value)}
            >
              <Option value="Selecione uma fila" disabled>
                Selecione uma fila
              </Option>
              {queues.map(queueSelect => (
                <Option key={queueSelect} value={queueSelect}>
                  {queueSelect}
                </Option>
              ))}
            </Select>

            <SearchButton>
              <SearchIcon />
            </SearchButton>
          </FiltersContainer>
        </DateAndFilterContainer>

        <SwitchContainer>
          <TextSwitch>Horário comercial?</TextSwitch>
          <ToggleSwitch
            onColor="rgba(22, 101, 216, 0.15)"
            offColor="rgba(0,0,0,0.15)"
            className="toggle"
            handleColor={businessHourChecked ? '#1665D8' : '#9D9D9D'}
            checked={businessHourChecked}
            onChange={handleChangeSwitch}
          />
        </SwitchContainer>

        <CardsContainer>
          <Card>
            <TextCard>Quantidade de atendimentos</TextCard>
            <InfoCardContainer>
              <Info>
                <Value>{queue.length}</Value>
                <Legend>Fila</Legend>
              </Info>
              <Info>
                <Value>{attendanceOpened.length}</Value>
                <Legend>Abertos</Legend>
              </Info>
              <Info>
                <Value>{attendanceClosed.length}</Value>
                <Legend>Fechados</Legend>
              </Info>
            </InfoCardContainer>
          </Card>

          <Card>
            <TextCard>Tempo máximo</TextCard>
            <Info>
              <Value>
                {attendanceClosed.length > 0 ? maxTimeAttendance : '0d 00:00'}
              </Value>
              <Legend>Atendimento</Legend>
            </Info>
          </Card>

          <Card>
            <TextCard>Tempo médio</TextCard>
            <InfoCardContainer>
              <Info>
                <Value>{averageAttendance}</Value>
                <Legend>Atendimento</Legend>
              </Info>
              <Info>
                <Value>{convertSecondsInHours(Number(queueTime))}</Value>
                <Legend>Fila</Legend>
              </Info>
              <Info>
                <Value>{averageResponse}</Value>
                <Legend>Resposta</Legend>
              </Info>
            </InfoCardContainer>
          </Card>
        </CardsContainer>

        <ButtonsTableContainer>
          <Button
            isSelected={buttonActive === 'Atendentes'}
            onClick={() => setButtonActive('Atendentes')}
          >
            Atendentes
          </Button>

          <Button
            isSelected={buttonActive === 'Tags'}
            onClick={() => setButtonActive('Tags')}
          >
            Tags
          </Button>
        </ButtonsTableContainer>

        {buttonActive === 'Atendentes' ? (
          <Table>
            <THead>
              <TRowHead>
                <TCellHead>Informações gerais</TCellHead>
                <TCellHead>Tickets</TCellHead>
                <TCellHead>Tempo médio </TCellHead>
              </TRowHead>
              <TRowHead>
                <TCellHead>
                  Atendente
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={setAgentMetrics}
                    setAttendanceTags={undefined}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="attendance"
                  />
                </TCellHead>
                <TCellHead>
                  Horário de login
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={setAgentMetrics}
                    setAttendanceTags={undefined}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="created_at"
                  />
                </TCellHead>
                <TCellHead>
                  Abertos
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={setAgentMetrics}
                    setAttendanceTags={undefined}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="open"
                  />
                </TCellHead>
                <TCellHead>
                  Finalizados
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={setAgentMetrics}
                    setAttendanceTags={undefined}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="close"
                  />
                </TCellHead>
                <TCellHead>
                  Resposta
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={setAgentMetrics}
                    setAttendanceTags={undefined}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="average_response_time"
                  />
                </TCellHead>
                <TCellHead>
                  Atendimento
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={setAgentMetrics}
                    setAttendanceTags={undefined}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="average_attendance_time"
                  />
                </TCellHead>
              </TRowHead>
            </THead>
            <TBody>
              {agentMetrics &&
                agentMetrics.map(agent => (
                  <TRowBody
                    key={agent.id}
                    onClick={() =>
                      history.push({
                        pathname: '/connection/take-blip/attendances',
                        state: {
                          id: agent.id,
                          name: agent.name,
                          initial_date: from,
                          final_date: to,
                        },
                      })
                    }
                  >
                    <TCell>
                      <AgentNameContainer>
                        <AgentStatusContainer>
                          <AgentStatus
                            style={
                              agentsStatus.find(
                                agent_status => agent_status.id === agent.id,
                              )?.status
                                ? { background: '#089b63' }
                                : { background: '#EE1717' }
                            }
                          />
                        </AgentStatusContainer>
                        <CellNameContainer>{agent.name}</CellNameContainer>
                      </AgentNameContainer>
                    </TCell>
                    <TCell>
                      {agent.metrics.length > 0
                        ? formatDateWithHours(
                            agent.metrics[agent.metrics.length - 1].created_at,
                          )
                        : ' - '}
                    </TCell>
                    <TCell>{handleOpenedTicket(agent)}</TCell>
                    <TCell>{handleClosedTicket(agent)}</TCell>
                    <TCell>
                      {handleTimeMediumAttendance(
                        agent.metrics.map(
                          metric => metric.average_response_time,
                        ),
                      )}
                    </TCell>
                    <TCell>
                      {handleTimeMediumAttendance(
                        agent.metrics.map(
                          metric => metric.average_attendance_time,
                        ),
                      )}
                    </TCell>
                  </TRowBody>
                ))}
            </TBody>
          </Table>
        ) : (
          <Table>
            <THead>
              <TRowHead>
                <TCellHead>Informações gerais</TCellHead>
                <TCellHead>Tickets</TCellHead>
                <TCellHead>Tempo médio </TCellHead>
              </TRowHead>
              <TRowHead>
                <TCellHead>
                  Tag
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={undefined}
                    setAttendanceTags={setAttendanceTags}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="tag"
                  />
                </TCellHead>
                <TCellHead>
                  Finalizados
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={undefined}
                    setAttendanceTags={setAttendanceTags}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="closed"
                  />
                </TCellHead>
                <TCellHead>
                  Atendimento
                  <ButtonsOrderTakeBlip
                    setAgentMetrics={undefined}
                    setAttendanceTags={setAttendanceTags}
                    agentMetrics={agentMetrics}
                    attendanceTags={attendanceTags}
                    attendanceOpened={attendanceOpened}
                    attendanceClosed={attendanceClosed}
                    type="attendance_time"
                  />
                </TCellHead>
              </TRowHead>
            </THead>
            <TBody>
              {attendanceTags &&
                attendanceTags.map(tag => (
                  <TRowBody key={tag.attendance_time}>
                    <TCell>{tag.tag} </TCell>
                    <TCell>{tag.closed}</TCell>
                    <TCell>
                      {millisecondsToMinutesAndSecondsAndDays(
                        tag.attendance_time,
                      )}
                    </TCell>
                  </TRowBody>
                ))}
            </TBody>
          </Table>
        )}
      </Body>
    </Container>
  );
};
