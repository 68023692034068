import { ChangeEvent, useCallback } from 'react';

import {
  Container,
  TitleFile,
  InputFileContainer,
  LabelFile,
  InputFile,
  TextFile,
} from './styles';

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface SendFileContainerProps {
  file: FileProps;
  setFile: (file: FileProps) => void;
}

export function SendFileContainer({
  file,
  setFile,
}: SendFileContainerProps): JSX.Element {
  const handleSetFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: FileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };
      setFile(newFile);

      event.target.value = null;
    },
    [setFile],
  );

  return (
    <Container>
      <TitleFile>Arquivo</TitleFile>
      <InputFileContainer>
        <LabelFile htmlFor="file">Selecione um arquivo</LabelFile>
        <InputFile
          onChange={event => handleSetFile(event)}
          id="file"
          type="file"
          name="file"
        />
        {file && file.name ? (
          <TextFile>{file.name}</TextFile>
        ) : (
          <TextFile>Nenhum arquivo selecionado</TextFile>
        )}
      </InputFileContainer>
    </Container>
  );
}
