import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ISelectHistory {
  selectHistory: string;
}

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 11.938rem;
  height: 0.0625rem;

  @media print {
    display: none;
  }
`;

export const TitleContainer = styled.h1<ISelectHistory>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;

  margin-top: 6.313rem;

  button {
    width: 12rem;
    height: 2.5rem;
    border: none;

    border-radius: 0.375rem;
    font-weight: 400;
    font-size: 1.625rem;
    text-align: center;
  }

  ${props =>
    props.selectHistory === 'Login'
      ? css`
          button.login-history {
            background: rgba(207, 222, 243, 0.83);
            color: #05233e;

            hr {
              border-color: #05233e;
            }
          }

          button.public-history {
            background: none;
            color: #9d9d9d;
          }

          button.state-history {
            background: none;
            color: #9d9d9d;
          }
        `
      : props.selectHistory === 'Estadual'
      ? css`
          button.state-history {
            background: rgba(207, 222, 243, 0.83);
            color: #05233e;

            hr {
              border-color: #05233e;
            }
          }

          button.login-history {
            background: none;
            color: #9d9d9d;
          }

          button.public-history {
            background: none;
            color: #9d9d9d;
          }
        `
      : css`
          button.public-history {
            background: rgba(207, 222, 243, 0.83);
            color: #05233e;

            hr {
              border-color: #05233e;
            }
          }

          button.login-history {
            background: none;
            color: #9d9d9d;
          }

          button.state-history {
            background: none;
            color: #9d9d9d;
          }
        `}
`;

export const RegionAndProcessContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const RegionContainer = styled.div`
  margin-top: 1.875rem;
  width: 48.75rem;
  height: 8rem;
  border: 0.188rem solid #d7e4f5;
  box-shadow: 0.125rem 0.125rem 0.25rem -1.25rem #d7e4f5;
  border-radius: 0.625rem;
`;

export const RegionSelectTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-100);

  height: 2rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #3d3d3d;
`;

export const RegionContentContainer = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    width: 19.375rem;
    height: 2.5rem;

    & + select {
      margin-left: 1.25rem;
    }
  }
`;

export const ProcessFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 4.375rem;

  height: 7.5rem;

  background: #f0f0f0bd;
  border-radius: 0.313rem;
`;

export const SelectFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 24.375rem;
`;

export const TextContainer = styled.div`
  margin: 0.625rem 0;

  strong {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;

    text-align: center;
    letter-spacing: -0.015em;

    color: #041524;
  }
`;

export const SendFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const InputFileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 1.75rem;

  label {
    cursor: pointer;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    background: #c4c4c4;
    border-radius: 0.313rem;
    width: 9.375rem;
    height: 1.875rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #4e4e4e;

    transition: background-color 0.2s ease;

    &:hover {
      background: ${shade(0.2, '#c4c4c4')};
    }
  }

  span {
    margin-top: 0.5rem;
    color: #666666;
    height: 1.875rem;
    width: 9.375rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 0.625rem;
    line-height: 0.938rem;
    text-align: center;
    letter-spacing: -0.015em;

    color: #666666;
  }

  input {
    display: none;
  }
`;

export const SendFileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: 0;
    width: 9.375rem;
    height: 1.875rem;
    background: #063966;
    border-radius: 0.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 1rem;

    letter-spacing: -0.015em;

    color: #ffffff;

    transition: background-color 0.2s ease;

    &:hover {
      background: ${shade(0.2, '#063966')};
    }
  }

  span {
    margin-top: 0.5rem;
    color: #666666;
    height: 1.875rem;
    width: 9.375rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 0.625rem;
    line-height: 0.938rem;
    text-align: center;
    letter-spacing: -0.015em;

    color: #666666;
  }
`;

export const FilesHistoryContainer = styled.div`
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.5rem;
  @media (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const DateContainer = styled.div`
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5.5rem;
  @media (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const TitleHistory = styled.h1`
  width: 58%;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;

  color: #05233e;

  @media (max-width: 1350px) {
    justify-content: center;
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Thead = styled.thead`
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
`;

export const TableRowHead = styled.tr`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background: var(--blue-100);
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;
`;

export const CellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: #05233e;
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25rem;
  text-align: center;
`;

export const TableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;

  :nth-child(odd) {
    background: #efefef;
    &:hover {
      background: ${shade(0.1, '#CFDEF3')};
    }
  }

  :nth-child(even) {
    background: #e5e5e5;
    &:hover {
      background: ${shade(0.1, '#CFDEF3')};
    }
  }
`;

export const CellBody = styled.td`
  word-break: break-all;
  width: 100%;
  background: transparent;
  padding: 0.5rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  :nth-child(2) {
    cursor: pointer;
    color: #1665d8;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.875rem;
`;

export const PreviousButton = styled.button`
  margin-right: 0.563rem;
  border: 0.031rem solid #05233e;
  background: transparent;
  border-radius: 0.25rem;

  height: 1.875rem;
  width: 1.563rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div input {
    border: none;
    width: 10rem;
    height: 1.875rem;
    color: #05233e;
  }

  &:nth-child(1) {
    margin-right: 5.05rem;
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0.031rem solid #063966;
  border-radius: 0.25rem;

  width: 12.063rem;

  input {
    padding-left: 1.196rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const NextButton = styled.button`
  margin-left: 0.563rem;
  border: 0.031rem solid #05233e;
  background: transparent;
  border-radius: 0.25rem;

  height: 1.875rem;
  width: 1.563rem;
`;

export const Button = styled.button`
  width: 7rem;
  height: 1.75rem;

  background: #063966;
  border-radius: 0.313rem;

  color: #fff;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;
  border: none;

  :disabled {
    background: ${shade(0.1, '#817f7f')};
    cursor: initial;
  }
`;
