import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;
export const ReturnContainer = styled.div`
  width: 71.563rem;
  height: 1.25rem;
  margin-bottom: 0.75rem;

  a {
    display: flex;
    align-items: center;
    color: #1665d8;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const Title = styled.h1`
  margin-top: 4.313rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 2.438rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;

  @media (max-width: 1100px) {
    width: 95vw;
  }
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
`;

export const Div = styled.h2``;

export const Label = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.188rem;
  letter-spacing: -0.015em;

  color: #05233e;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.25rem;
  transition: border 0.2s;
  margin-bottom: 0.5rem;

  background: #ffffff;
  border: 0.063rem solid #67ba99;
  box-sizing: border-box;

  box-shadow: 0px 0.188rem 0.625rem rgba(114, 30, 255, 0.1);
  border-radius: 0.375rem;

  &:focus {
    border: 1px solid #2e9afe;
  }
  ::placeholder {
    font-size: 0.8rem;
  }
`;

export const CustomerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.813rem;

  margin-bottom: 2.125rem;
  select {
    background: #ffffff;
    border: 0.063rem solid #67ba99;
    box-sizing: border-box;
    box-shadow: 0px 0.188rem 0.625rem rgba(114, 30, 255, 0.1);
    border-radius: 0.375rem;
    width: 100%;
    height: 2.25rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.188rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #041524;
  }

  @media (max-width: 1100px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-template-columns: 1fr;

    margin-bottom: 0.5rem;
    gap: 0;
  }
`;

export const CustomerTitleContainer = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 2.125rem;
  margin-bottom: 1.313rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;

  color: #05233e;

  background: rgba(209, 235, 225, 0.83);
  border-radius: 0.25rem;

  @media (max-width: 1100px) {
    width: 95vw;
    margin-top: 0.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  padding-bottom: 2rem;
  margin-top: 4.875rem;

  div {
    margin-right: 2rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-right: 2rem;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100vw;
    gap: 1rem;
  }
`;

export const CancelButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #817f7f;
    border: 0;
    border-radius: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;
export const CreateButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #019158;
    border: 0;
    border-radius: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;
