import { ChangeEvent } from 'react';
import { InlineSeparator } from '../ExampleFileTRF3Pje/styles';
import {
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  TitleFile,
  Label,
  InputFile,
  TextFile,
  SendButtonContainer,
  ButtonSendFile,
  TextFileButton,
  ExampleFileContainer,
  ExampleTitle,
  Separator,
  FileProcessExampleContainer,
  ExampleTextContainer,
  SelectFileContainer,
  EmptyColumnText,
  OriginatingExampleText,
  EmptyColumn,
  OriginatingExampleTextValue,
} from './styles';

interface IExampleFileTRF2UpdatedValue {
  handleUpload: () => Promise<void>;
  submitFile: (event: ChangeEvent<HTMLInputElement>) => void;
  file_name: string;
}

export const ExampleFileTRF2UpdatedValue: React.FC<IExampleFileTRF2UpdatedValue> =
  ({ submitFile, handleUpload, file_name }: IExampleFileTRF2UpdatedValue) => {
    const templateFileTrfRequisite = '0000000-00.0000.0.00.0000';

    return (
      <FileContainer>
        <FileInputContainer>
          <TitleFile>Arquivo:</TitleFile>
          <SendFileContainer>
            <SelectFileContainer>
              <Label htmlFor="file">Selecione um arquivo</Label>
              <InputFile
                onChange={event => submitFile(event)}
                id="file"
                type="file"
                name="file"
              />
              {file_name ? (
                <TextFile>{file_name}</TextFile>
              ) : (
                <TextFile>Nenhum arquivo selecionado</TextFile>
              )}
            </SelectFileContainer>
          </SendFileContainer>

          <SendButtonContainer>
            <ButtonSendFile type="button" onClick={handleUpload}>
              Enviar
            </ButtonSendFile>
            <TextFileButton>
              Arquivos serão processados automaticamente
            </TextFileButton>
          </SendButtonContainer>
        </FileInputContainer>

        <ExampleFileContainer>
          <ExampleTitle>Modelo de arquivo:</ExampleTitle>

          <FileProcessExampleContainer>
            <ExampleTextContainer>
              <EmptyColumnText>Coluna Vazia</EmptyColumnText>
              <InlineSeparator>|</InlineSeparator>
              <OriginatingExampleText>
                Numero do Processo
              </OriginatingExampleText>
              <Separator />
              <EmptyColumn>Coluna Vazia</EmptyColumn>
              <InlineSeparator>|</InlineSeparator>
              <OriginatingExampleTextValue>
                {templateFileTrfRequisite}
              </OriginatingExampleTextValue>
            </ExampleTextContainer>
          </FileProcessExampleContainer>
        </ExampleFileContainer>
      </FileContainer>
    );
  };
