import styled from 'styled-components';

export const LogoContainer = styled.div`
  align-items: center;
  justify-content: center;
  height: 85vh;
  display: flex;

  img {
    width: 50rem;
    height: 10rem;
    opacity: 0.2;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;
