/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useState, ChangeEvent, useMemo, useEffect } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { AiOutlineCheck } from 'react-icons/ai';
import { format, addDays } from 'date-fns';
import { gql, useMutation } from '@apollo/client';
import api from '../../../../../services/api';
import { Title } from '../../../../../components/Styles/Title';
import { ScheduleOptionsButton } from '../../../../../components/ScheduleOptionsButton';
import {
  ModalSchedule,
  ModalMain,
  Separator,
  DivSeparator,
  TitleContainer,
  Main,
  DateActivityContainer,
  ActivityContainer,
  DetailsActivityContainer,
  FieldContainer,
  InputsContainer,
  Icon,
  ButtonsContainerSchedule,
  ButtonSchedule,
} from './styles';
import { useAuth } from '../../../../../hooks/auth';

import { CloserDTO } from '../../../../CloserSettings/dtos/CloserDTO';
import { InputDate } from '../../../../../components/InputDate';
import { IHunterDTO } from '../../../Hunter/dtos/IHunterDTO';
import { SuccessfulModal } from '../../../Hunter/SuccessfulModal';
import { Loading } from '../../../../../components/Loading';

Modal.setAppElement('#root');

interface ICreateActivityModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  closers: CloserDTO[];
  hunters: IHunterDTO[];
  hours: Date[];
}

interface IHubspotDeal {
  deal: {
    hubspot_deal_id: string;
    dealname: string;
    amount: string;
    dealstage: string;
    loa: string;
    contato_valido: string;
    trf: string;
    produto_precato: string;
  };
  group: string;
  dono_do_lead___atual: string;
  ticket_pipeline_id: string;
}

interface ICreateActivityFormData {
  deal: IHubspotDeal;
  closer_id: string;
  hunter_id: string;
  date: Date;
  user_id: string;
  contact_preference?: string;
  connection_method: string;
  ticket_id: string;
}

export function ModalCreateScheduling({
  isOpen,
  onRequestClose,
  closers,
  hunters,
  hours,
}: ICreateActivityModalProps) {
  const [date, setDate] = useState(new Date());
  const [selectedConnection, setSelectedConnection] = useState<string>();
  const [selectedContact, setSelectedContact] = useState<string>();
  const [ticketId, setTicketId] = useState<string>();
  const { user } = useAuth();
  const [hoursAvailable, setHoursAvailable] = useState<Date[]>(hours);
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [hubspotDeal, setHubspotDeal] = useState<IHubspotDeal>(
    {} as IHubspotDeal,
  );
  const [closerNameScheduling, setCloserNameScheduling] = useState(undefined);

  const [schedulingHubActivity, setSchedulingHubActivity] = useState('');

  const [formData, setFormData] = useState<ICreateActivityFormData>(
    {} as ICreateActivityFormData,
  );

  const connections = [
    {
      label: 'Outbound',
      id: '5',
    },
    {
      label: 'Inbound Ativo',
      id: '3',
    },
    {
      label: 'Inbound Digital',
      id: '4',
    },
    {
      label: 'Retomada',
      id: '1',
    },
    {
      label: 'Honorários',
      id: '2',
    },
  ];

  const contacts = ['Telefone', 'Whatsapp', 'E-mail'];

  const SEND_NOTIFICATION = gql`
    mutation CreateManagerScheduling($deal: String!, $info: String!) {
      createManagerScheduling(deal: $deal, info: $info) {
        id
        hubspot_deal_id
        dealname
        amount
        stage_name
        observations
        closer_id
        manager_id
        hunter_id
        hunter_name
        try_times
        talk
        contact
        contact_preference
        contato_valido
        status
        loa
        connection_method
        deleted_by
        current_lead_owner
        ticket_id
        timestamp
        deleted
        business_situation
        queue
        type
        hub_activity_id
        calculation_status
        calculation_timestamp
        forced
        created_at
        updated_at
      }
    }
  `;

  const [createScheduling] = useMutation(SEND_NOTIFICATION, {
    variables: {
      deal: JSON.stringify({ ...hubspotDeal.deal, ticket_id: ticketId }),
      info: JSON.stringify({
        closer_id: formData.closer_id,
        hunter_id: formData.hunter_id,
        date: formData.date,
        user_id: user.id,
        contact_preference: selectedContact,
        connection_method: formData.connection_method,
      }),
    },
  });

  const handleCloseSuccessfulModal = useCallback(() => {
    setOpenSuccessfulModal(false);
  }, []);

  const closeModal = useCallback(() => {
    onRequestClose();

    setHubspotDeal({} as IHubspotDeal);
    setFormData({} as ICreateActivityFormData);
    setSelectedConnection('');
  }, [onRequestClose]);

  const createActivitySuccess = useCallback(() => {
    toast.success('Atividade criada com sucesso');
  }, []);

  const createActivityError = useCallback((message: string) => {
    Swal.fire('Atenção!', `${message}`, 'warning');
  }, []);

  const handleSetHubspotDeal = useCallback(
    async (hubspot_ticket_id: string) => {
      try {
        const response = await api.get(
          `schedule/get-hubspot-deal-info?hubspot_ticket_id=${hubspot_ticket_id}&connection=${selectedConnection}`,
        );

        setHubspotDeal(response.data);
        setTicketId(hubspot_ticket_id);
      } catch (error) {
        // eslint-disable-next-line no-console
      }
    },
    [selectedConnection],
  );

  const formattedDate = useCallback((timestamp: Date, tomorrow?: boolean) => {
    return tomorrow
      ? format(addDays(timestamp, 1), 'yyyy-MM-dd')
      : format(timestamp, 'yyyy-MM-dd');
  }, []);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  useEffect(() => {
    setHoursAvailable([] as Date[]);
    const selectedDateFormatted = date
      ? format(date, 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd');
    api
      .get(`schedule/list-all-date-possible-hours/${selectedDateFormatted}`)
      .then(response => {
        setHoursAvailable(response.data);
      });
  }, [date]);

  const formattedAmount = useMemo(() => {
    if (hubspotDeal.deal && hubspotDeal.deal.amount) {
      const amount = Number(hubspotDeal.deal.amount).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });

      return amount;
    }

    return '';
  }, [hubspotDeal]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      if (name === 'date') {
        const newDate = formattedDate(new Date(date));

        setFormData({ ...formData, [name]: new Date(`${newDate} ${value}`) });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [date, formData, formattedDate],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      const { data } = await createScheduling();

      setSchedulingHubActivity(data.createManagerScheduling.hub_activity_id);
      createActivitySuccess();
      onRequestClose();

      const closerName = closers.find(
        foundCloser => foundCloser.id === formData.closer_id,
      );

      setCloserNameScheduling(closerName.name);

      setOpenSuccessfulModal(true);
    } catch (error) {
      createActivityError(
        error.message
          .split('Unexpected error value: ')[1]
          .split('{ message: ')[1]
          .split(',')[0]
          .split('"')[1],
      );
    }
  }, [
    createActivityError,
    createActivitySuccess,
    createScheduling,
    onRequestClose,
    formData,
    closers,
  ]);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalSchedule}>
        <ModalMain>
          <TitleContainer>
            <Title>Novo agendamento</Title>
            <DivSeparator>
              <Separator />
            </DivSeparator>
          </TitleContainer>

          <Loading />

          <Main>
            <DateActivityContainer>
              <FieldContainer>
                <p className="subtitleModal">Conexão:</p>

                <div id="selectCloser">
                  <select
                    className="select"
                    name="connection_method"
                    id="connection_id"
                    defaultValue="Selecione a conexão"
                    onChange={handleInputChange}
                  >
                    <option value="Selecione a conexão" disabled>
                      Selecione a conexão
                    </option>
                    {connections.map(connection => (
                      <option
                        key={connection.id}
                        id={connection.id}
                        value={connection.label}
                      >
                        {connection.label}
                      </option>
                    ))}
                  </select>
                </div>
              </FieldContainer>

              <FieldContainer>
                <p className="subtitleModal">Contato de preferência:</p>

                <div id="selectCloser">
                  {contacts.map(contact => (
                    <ScheduleOptionsButton
                      key={contact}
                      selectedValue={selectedContact === contact}
                      managerSchedulingContact
                      type="button"
                      onClick={() => {
                        setSelectedContact(contact);
                      }}
                    >
                      {contact}
                    </ScheduleOptionsButton>
                  ))}
                </div>
              </FieldContainer>

              <ActivityContainer>
                <FieldContainer>
                  <p className="subtitleModal">Selecione uma data:</p>
                  <div id="selectDate">
                    <InputDate date={date} setDate={setDate} />
                  </div>
                </FieldContainer>
              </ActivityContainer>

              <ActivityContainer>
                <FieldContainer>
                  <p className="subtitleModal">Selecione um horário:</p>

                  <div id="selectHour">
                    <select
                      name="date"
                      id="date"
                      defaultValue="Horário"
                      onChange={handleInputChange}
                    >
                      <option value="Horário" disabled>
                        Horário
                      </option>
                      {hoursAvailable &&
                        hoursAvailable.map(hourAvailable => (
                          <option
                            key={String(hourAvailable)}
                            value={formattedTime(hourAvailable)}
                          >
                            {formattedTime(hourAvailable)}
                          </option>
                        ))}
                    </select>
                    {/*  <span id="intervalText">às</span>
                  <select
                    name="time_end"
                    id="time_end"
                    defaultValue="Horário final"
                    onChange={handleInputChange}
                  >
                    <option value="Horário final" disabled>
                      Horário final
                    </option>
                    <option value={handleChangeTimeEnd}>
                      {handleChangeTimeEnd}
                    </option>
                  </select> */}
                  </div>
                </FieldContainer>
              </ActivityContainer>
            </DateActivityContainer>

            <DetailsActivityContainer>
              <FieldContainer>
                <p className="subtitleModal">Selecione um closer:</p>

                <div id="selectCloser">
                  <select
                    className="select"
                    name="closer_id"
                    id="closer_id"
                    defaultValue="Selecione o closer"
                    onChange={handleInputChange}
                  >
                    <option value="Selecione o closer" disabled>
                      Selecione o closer
                    </option>
                    {closers &&
                      closers.map(closer => (
                        <option
                          key={closer.id}
                          id={closer.id}
                          value={closer.id}
                        >
                          {closer.name}
                        </option>
                      ))}
                  </select>
                </div>
              </FieldContainer>

              <FieldContainer>
                <p className="subtitleModal">Selecione um Hunter:</p>

                <div id="selectCloser">
                  <select
                    className="select"
                    name="hunter_id"
                    id="hunter_id"
                    defaultValue="Selecione o hunter"
                    onChange={handleInputChange}
                  >
                    <option value="Selecione o hunter" disabled>
                      Selecione o hunter
                    </option>
                    {hunters &&
                      hunters.map(hunter => (
                        <option
                          key={hunter.id}
                          id={hunter.id}
                          value={hunter.id}
                        >
                          {hunter.name}
                        </option>
                      ))}
                  </select>
                </div>
              </FieldContainer>

              <FieldContainer>
                <p className="subtitleModal">Dados do negócio:</p>

                <InputsContainer>
                  <input
                    type="text"
                    id="hubspot_deal_id"
                    name="hubspot_deal_id"
                    placeholder="ID do ticket"
                    onChange={e => handleSetHubspotDeal(e.target.value)}
                  />

                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Valor"
                    onChange={handleInputChange}
                    value={formattedAmount || ''}
                    disabled
                  />

                  <input
                    type="text"
                    id="dealname"
                    name="dealname"
                    placeholder="Nome do credor"
                    value={hubspotDeal.deal ? hubspotDeal.deal.dealname : ''}
                    onChange={handleInputChange}
                    disabled
                  />
                </InputsContainer>
              </FieldContainer>
            </DetailsActivityContainer>
          </Main>

          <ButtonsContainerSchedule>
            <ButtonSchedule onClick={handleSubmit}>
              <Icon>
                <AiOutlineCheck />
              </Icon>
              Agendar
            </ButtonSchedule>
          </ButtonsContainerSchedule>
        </ModalMain>
      </Modal>
      {openSuccessfulModal && (
        <SuccessfulModal
          isOpen={openSuccessfulModal}
          onRequestClose={handleCloseSuccessfulModal}
          name_sla="Agendamento"
          selectedDay=""
          ticket_id={ticketId}
          contact_preference={selectedContact}
          selected_time={formData.date}
          queue={undefined}
          scheduling_hub_activity={schedulingHubActivity}
          closer_name_scheduling={closerNameScheduling}
          connection={selectedConnection}
          previousConnectionMethod={selectedConnection}
          hunter={hunters.find(hunter => hunter.id === formData.hunter_id)}
          ticket_pipeline={hubspotDeal.ticket_pipeline_id}
        />
      )}
    </>
  );
}
