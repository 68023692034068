import { InputContainer, Label, Textarea } from '../../styles';

interface IInputSLAProps {
  title: string;
  name: string;
  value: string;
  disable: boolean;
  handleChange: (e: any) => void;
}

export function TextAreaSLA({
  title,
  name,
  value,
  handleChange,
  disable,
}: IInputSLAProps): JSX.Element {
  return (
    <InputContainer>
      <Label>{title}</Label>
      <Textarea
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disable}
      />
    </InputContainer>
  );
}
