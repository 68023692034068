import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import Modal from 'react-modal';

import api from '../../../../../services/api';
import { formatAmount } from '../../../../../utils/formatAmount';

import { IProposalFormDTO } from '../../../dtos/IProposalFormDTO';

import {
  ApprovalRequestContainer,
  NoteSpan,
  Main,
  LabelInputContainer,
  Label,
  Input,
  InputSpanContainer,
  PercentageSpan,
  AnnexButton,
  Textarea,
  ButtonsContainer,
  RefuseButton,
  ApproveButton,
} from './styles';

Modal.setAppElement('#root');

interface ModalApprovalRequestProps {
  isOpen: boolean;
  onRequestClose: () => void;
  proposal: IProposalFormDTO;
  proposals: IProposalFormDTO[];
  setProposals: (proposals: IProposalFormDTO[]) => void;
}

export function ModalApprovalRequest({
  isOpen,
  onRequestClose,
  proposal,
  proposals,
  setProposals,
}: ModalApprovalRequestProps): JSX.Element {
  const [managerDescription, setManagerDescription] = useState('');

  const handleSubmitRequest = useCallback(
    async (status: string) => {
      try {
        await api.patch('/negociations/calculations/proposal-form', {
          id: proposal.id,
          status,
          manager_description: managerDescription,
        });

        const index = proposals.findIndex(
          foundProposal => foundProposal.id === proposal.id,
        );

        const updatedProposals = proposals;

        updatedProposals.splice(index, 1);

        setProposals(updatedProposals);

        toast.success('Proposta atualizada com sucesso');

        onRequestClose();
      } catch {
        toast.error('Não foi possível atualizar a proposta');
      }
    },
    [managerDescription, onRequestClose, proposal.id, proposals, setProposals],
  );

  const handleDownload = useCallback(async (file_name: string, url: string) => {
    const response = await api.get(url, {
      responseType: 'blob',
    });

    saveAs(response.data, file_name);
  }, []);

  const handleCalculatePercentage = useCallback(
    (value: number, liquid_value: string) => {
      const percentage = ((value * 100) / Number(liquid_value)).toFixed(0);

      return percentage;
    },
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ApprovalRequestContainer}
    >
      <NoteSpan final_percent={proposal.final_percent}>
        Nota - {proposal.final_percent}%
      </NoteSpan>

      <Main>
        <LabelInputContainer>
          <Label>Closer:</Label>
          <Input value={proposal.closer_name} disabled />
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>ID do Hubspot:</Label>
          <Input value={proposal.hubspot_deal_id} disabled />
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Valores iniciados:</Label>
          <InputSpanContainer>
            <Input
              value={
                proposal.started_value && formatAmount(proposal.started_value)
              }
              disabled
            />
            <PercentageSpan>
              {proposal.liquid_value &&
                handleCalculatePercentage(
                  proposal.started_value,
                  proposal.liquid_value,
                )}
              %
            </PercentageSpan>
          </InputSpanContainer>
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Valores de expectativa:</Label>
          <InputSpanContainer>
            <Input
              value={
                proposal.expectation_value &&
                formatAmount(proposal.expectation_value)
              }
              disabled
            />
            <PercentageSpan>
              {proposal.liquid_value &&
                handleCalculatePercentage(
                  proposal.expectation_value,
                  proposal.liquid_value,
                )}
              %
            </PercentageSpan>
          </InputSpanContainer>
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Valores para finalizar:</Label>
          <InputSpanContainer>
            <Input
              value={
                proposal.finish_value && formatAmount(proposal.finish_value)
              }
              disabled
            />
            <PercentageSpan>
              {proposal.liquid_value &&
                handleCalculatePercentage(
                  proposal.finish_value,
                  proposal.liquid_value,
                )}
              %
            </PercentageSpan>
          </InputSpanContainer>
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Planos do credor com o recurso:</Label>
          <Input
            value={
              proposal.creditor_plans &&
              proposal.creditor_plans
                .replaceAll(',', ', ')
                .replace('[', '')
                .replace(']', '')
                .replaceAll('"', '')
            }
            disabled
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Interferências:</Label>
          <Input
            value={
              proposal.interference &&
              proposal.interference
                .replaceAll(',', ', ')
                .replace('[', '')
                .replace(']', '')
                .replaceAll('"', '')
            }
            disabled
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Concorrência envolvida:</Label>
          <Input
            value={
              proposal.concurrence &&
              proposal.concurrence
                .replaceAll(',', ', ')
                .replace('[', '')
                .replace(']', '')
                .replaceAll('"', '')
            }
            disabled
          />
        </LabelInputContainer>

        <LabelInputContainer>
          <Label>Proposta da concorrência formalizada?</Label>
          <Input value={proposal.annex ? 'Sim' : 'Não'} disabled />
        </LabelInputContainer>

        <AnnexButton
          hasAnnex={!!proposal.annex}
          disabled={!proposal.annex}
          onClick={() => handleDownload(proposal.annex, proposal.annex_url)}
        >
          Anexo
        </AnnexButton>

        <Label id="description">Descrição da negociação</Label>
        <Textarea value={proposal.description} disabled />

        <Label id="description">Descrição do Gestor</Label>
        <Textarea
          id="manager-textarea"
          placeholder="Digite a descrição"
          onChange={e => setManagerDescription(e.target.value)}
        />

        <ButtonsContainer>
          <RefuseButton onClick={() => handleSubmitRequest('Recusado')}>
            Recusar
          </RefuseButton>
          <ApproveButton onClick={() => handleSubmitRequest('Aprovado')}>
            Aprovar
          </ApproveButton>
        </ButtonsContainer>
      </Main>
    </Modal>
  );
}
