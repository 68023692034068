import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ReactComponent as OrderCrescent } from '../../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../../assets/icons/polygon_down.svg';
import checkTaskIcon from '../../../../../assets/icons/check_task.svg';
import checkedTaskIcon from '../../../../../assets/icons/checked_task.svg';
import {
  HeaderWraper,
  HeadContainer,
  Container,
  TableRow,
  Thead,
  Cell,
  CellAct,
  Tbody,
} from './styles';
import api from '../../../../../services/api';
import { OrderContainerIcon } from '../styles';
import { useAuth } from '../../../../../hooks/auth';
import { ModalConclusionConfirmation } from '../../ModalConclusionConfirmation';
import { ModalCreateActivityFromActivity } from '../../ModalCreateActivityFromProvidence';
import { IActivitiesInADayDTO } from '../../dtos/IActivitiesInADayDTO';
import { IActivityCSDTO } from '../../dtos/IActivityCSDTO';

interface IProvidencesInfo {
  id: string;
  hubspot_deal_id: string;
  name: string;
  conclusion_prev: string;
  status: string;
}
interface IActivitiesInfo extends IActivityCSDTO {
  hubspot_deal_id: string;
  id: string;
  date: string;
  hour: string;
  name: string;
  conclusion_prev: string;
  status: string;
  providence_id: string;
  type: string;
  observation: string;
}
interface ListingProvidencesComponent {
  componentChooser: string;
  hubspot_deal_id: string;
  type: string;
}

export function ListingProvidencesComponent({
  componentChooser,
  hubspot_deal_id,
  type,
}: ListingProvidencesComponent): JSX.Element {
  const [isOrder, setIsOrder] = useState(false);
  const [openCreateActivityModal, setOpenCreateActivityModal] = useState(false);
  const [openConclusionConfirmationModal, setConclusionConfirmationModal] =
    useState(false);
  const { user } = useAuth();
  const [providencesInfo, setProvidencesInfo] = useState<IProvidencesInfo[]>([
    {
      id: '',
      name: ' - ',
      hubspot_deal_id: ' ',
      status: ' - ',
      conclusion_prev: '-',
    },
  ] as IProvidencesInfo[]);
  const [updatedStatusActivity, setUpdatedStatusActivity] = useState({
    id: '',
    situation: '',
  });
  const [selectedConclusionActivity, setSelectedConclusionActivity] =
    useState<IActivitiesInfo>({} as IActivitiesInfo);
  const [activityTypeToCreate, setActivityTypeToCreate] = useState('');
  const [activitiesInfo, setActivitiesInfo] = useState<IActivitiesInfo[]>([
    {
      id: ' ',
      name: ' - ',
      hubspot_deal_id: ' ',
      status: ' - ',
      conclusion_prev: '-',
      date: '-',
      hour: '-',
    },
  ] as IActivitiesInfo[]);

  useEffect(() => {
    api
      .get(`/schedule-cs/all-providences?hubspot_deal_id=${hubspot_deal_id}`)
      .then(response => {
        setProvidencesInfo(response.data);
      });
  }, [hubspot_deal_id]);

  useEffect(() => {
    api
      .get(`/schedule-cs/all-activities?hubspot_deal_id=${hubspot_deal_id}`)
      .then(response => {
        setActivitiesInfo(response.data);
      });
  }, [hubspot_deal_id]);

  const orderActivitiesCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = activitiesInfo.sort(
        (previous: IActivitiesInfo, next: IActivitiesInfo) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
            case 'date':
              return previous.date > next.date
                ? 1
                : previous.date < next.date
                ? -1
                : 0;
            case 'hour':
              return previous.hour > next.hour
                ? 1
                : previous.hour < next.hour
                ? -1
                : 0;
            case 'status':
              return previous.status > next.status
                ? 1
                : previous.status < next.status
                ? -1
                : 0;
            case 'conclusion':
              return previous.conclusion_prev > next.conclusion_prev
                ? 1
                : previous.conclusion_prev < next.conclusion_prev
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setActivitiesInfo([...order]);
    },
    [activitiesInfo, isOrder],
  );

  const orderActivitiesCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = activitiesInfo.sort(
        (previous: IActivitiesInfo, next: IActivitiesInfo) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
            case 'date':
              return previous.date < next.date
                ? 1
                : previous.date > next.date
                ? -1
                : 0;
            case 'hour':
              return previous.hour < next.hour
                ? 1
                : previous.hour > next.hour
                ? -1
                : 0;
            case 'status':
              return previous.status < next.status
                ? 1
                : previous.status > next.status
                ? -1
                : 0;
            case 'conclusion':
              return previous.conclusion_prev > next.conclusion_prev
                ? 1
                : previous.conclusion_prev < next.conclusion_prev
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setActivitiesInfo([...order]);
    },
    [activitiesInfo, isOrder],
  );

  const orderProvidencesCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = providencesInfo.sort(
        (previous: IProvidencesInfo, next: IProvidencesInfo) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
            case 'conclusion':
              return previous.conclusion_prev > next.conclusion_prev
                ? 1
                : previous.conclusion_prev < next.conclusion_prev
                ? -1
                : 0;
            case 'status':
              return previous.status > next.status
                ? 1
                : previous.status < next.status
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setProvidencesInfo([...order]);
    },
    [isOrder, providencesInfo],
  );

  const orderProvidencesCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = providencesInfo.sort(
        (previous: IProvidencesInfo, next: IProvidencesInfo) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
            case 'conclusion':
              return previous.conclusion_prev < next.conclusion_prev
                ? 1
                : previous.conclusion_prev > next.conclusion_prev
                ? -1
                : 0;
            case 'status':
              return previous.status < next.status
                ? 1
                : previous.status > next.status
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setProvidencesInfo([...order]);
    },
    [isOrder, providencesInfo],
  );

  const handleConcludeProvidence = useCallback(
    async (providence: IProvidencesInfo) => {
      try {
        await api.patch('/schedule-cs/providence', {
          providence_id: providence.id,
          situation: 'Concluída',
        });

        api
          .get(
            `/schedule-cs/all-providences?hubspot_deal_id=${hubspot_deal_id}`,
          )
          .then(response => {
            setProvidencesInfo(response.data);
          });

        toast.success('Atividade atualizada com sucesso!');
      } catch (error) {
        toast.error('Falha ao atualizar a tarefa!');
      }
    },
    [hubspot_deal_id],
  );

  const handleChangeActivityStatus = useCallback(
    async (activity: IActivitiesInfo) => {
      try {
        activity.status =
          activity.status === 'Concluída' ? 'Pendente' : 'Concluída';

        setUpdatedStatusActivity({
          id: activity.id,
          situation: activity.status,
        });

        if (activity.status === 'Concluída' && activity.providence_id != null) {
          setActivityTypeToCreate('Providência');
          setConclusionConfirmationModal(true);

          setSelectedConclusionActivity(activity);

          await api.patch('/schedule-cs', {
            id: activity.id,
            user_id: user.id,
            situation: activity.status,
          });
        } else if (
          activity.status === 'Concluída' &&
          activity.type === 'Retorno credor'
        ) {
          setActivityTypeToCreate('Retorno credor');
          setConclusionConfirmationModal(true);

          setSelectedConclusionActivity(activity);

          await api.patch('/schedule-cs', {
            id: activity.id,
            user_id: user.id,
            situation: activity.status,
          });
        } else {
          await api.patch('/schedule-cs', {
            id: activity.id,
            user_id: user.id,
            situation: activity.status,
          });
        }

        toast.success('Atividade atualizada com sucesso!');
      } catch {
        toast.error('Falha ao atualizar a tarefa!');
      }
    },
    [user.id],
  );

  const handleConclusionVariableConfirmation = useCallback(
    async aux => {
      if (aux === false) {
        setOpenCreateActivityModal(true);
      } else if (activityTypeToCreate === 'Providência') {
        await api.patch('/schedule-cs/providence', {
          providence_id: selectedConclusionActivity.providence_id,
          situation: 'Concluída',
        });
      }
    },
    [activityTypeToCreate, selectedConclusionActivity.providence_id],
  );

  const handleCloseCreateActivityModal = useCallback(() => {
    setOpenCreateActivityModal(false);
  }, []);

  const handleUpdateListAcitivity = useCallback(async () => {
    api
      .get(`/schedule-cs/all-activities?hubspot_deal_id=${hubspot_deal_id}`)
      .then(response => {
        setActivitiesInfo(response.data);
      });
  }, [hubspot_deal_id]);

  const handleCloseConclusionConfirmationModal = useCallback(() => {
    setConclusionConfirmationModal(false);
    handleUpdateListAcitivity();
  }, [handleUpdateListAcitivity]);

  return (
    <Container>
      {componentChooser === 'Providências' ? (
        <>
          <HeaderWraper>
            <HeadContainer>PROVIDÊNCIAS</HeadContainer>
          </HeaderWraper>

          <Thead>
            <TableRow>
              <Cell
                onClick={() =>
                  isOrder
                    ? orderProvidencesCsByAsc('name')
                    : orderProvidencesCsByDesc('name')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                PROVIDÊNCIA
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
              <Cell
                onClick={() =>
                  isOrder
                    ? orderProvidencesCsByAsc('status')
                    : orderProvidencesCsByDesc('status')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                STATUS
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
              <Cell
                onClick={() =>
                  isOrder
                    ? orderProvidencesCsByAsc('conclusion')
                    : orderProvidencesCsByDesc('conclusion')
                }
              >
                PREVISÃO DE CONCLUSÃO
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
              {type !== 'Manager' ? <Cell /> : ''}
            </TableRow>
          </Thead>
          {providencesInfo &&
            providencesInfo.map(providence => (
              <Tbody providenceStatus={providence.status}>
                <TableRow key={providence.hubspot_deal_id}>
                  <Cell onClick={() => setOpenCreateActivityModal(true)}>
                    {providence.name}
                  </Cell>

                  <Cell onClick={() => setOpenCreateActivityModal(true)}>
                    {providence.status}
                  </Cell>

                  <Cell onClick={() => setOpenCreateActivityModal(true)}>
                    {providence.conclusion_prev}
                  </Cell>
                  {type !== 'Manager' ? (
                    <Cell>
                      <img
                        onClick={() => handleConcludeProvidence(providence)}
                        src={
                          providence.status === 'Concluída'
                            ? checkedTaskIcon
                            : checkTaskIcon
                        }
                        alt="Concluir tarefa"
                      />
                    </Cell>
                  ) : (
                    ''
                  )}
                </TableRow>
              </Tbody>
            ))}
        </>
      ) : (
        <>
          <HeaderWraper>
            <HeadContainer>ATIVIDADES</HeadContainer>
          </HeaderWraper>

          <Thead>
            <TableRow>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('date')
                    : orderActivitiesCsByDesc('date')
                }
                style={{
                  borderRight: '1px solid #9d9d9d',
                }}
              >
                DATA
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('hour')
                    : orderActivitiesCsByDesc('hour')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                HORA
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>

              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('name')
                    : orderActivitiesCsByDesc('name')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                ATIVIDADE
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('status')
                    : orderActivitiesCsByDesc('status')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                STATUS
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('conclusion')
                    : orderActivitiesCsByDesc('conclusion')
                }
              >
                PREVISÃO DE CONCLUSÃO
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct />
            </TableRow>
          </Thead>
          {activitiesInfo &&
            activitiesInfo.map(activity => (
              <Tbody providenceStatus={activity.status}>
                <TableRow key={activity.hubspot_deal_id}>
                  <CellAct>{activity.date}</CellAct>
                  <CellAct>{activity.hour}</CellAct>
                  <CellAct>{activity.name}</CellAct>

                  <CellAct>{activity.status}</CellAct>

                  <CellAct>{activity.conclusion_prev}</CellAct>
                  {type !== 'Manager' ? (
                    <CellAct>
                      {user.profile === 'Customer Success' ||
                      user.profile === 'Customer Success Calculator' ? (
                        <img
                          onClick={() => handleChangeActivityStatus(activity)}
                          src={
                            (updatedStatusActivity.id === activity.id &&
                              updatedStatusActivity.situation ===
                                'Concluída') ||
                            activity.status === 'Concluída'
                              ? checkedTaskIcon
                              : checkTaskIcon
                          }
                          alt="Concluir tarefa"
                        />
                      ) : (
                        ''
                      )}
                    </CellAct>
                  ) : (
                    ''
                  )}
                </TableRow>
              </Tbody>
            ))}
        </>
      )}
      <ModalConclusionConfirmation
        isOpen={openConclusionConfirmationModal}
        conclusionConfirmation={handleConclusionVariableConfirmation}
        onRequestClose={handleCloseConclusionConfirmationModal}
        type={activityTypeToCreate}
      />
      {openCreateActivityModal && (
        <ModalCreateActivityFromActivity
          isOpen={openCreateActivityModal}
          onRequestClose={handleCloseCreateActivityModal}
          activity={selectedConclusionActivity}
          observation={selectedConclusionActivity.observation}
          activitiesInADay={undefined}
          setActivitiesInADay={undefined}
          updateActivityClose={undefined}
          activityType={activityTypeToCreate}
        />
      )}
    </Container>
  );
}
