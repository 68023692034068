import Modal from 'react-modal';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { CurrencyInputProps } from 'react-currency-input-field';
import { Main, SLAModalStyle } from './styles';
import { Title } from '../../../../components/Title';
import { Loading } from '../../../../components/Loading';

import {
  ISLAComplet,
  ISLAN1,
  ISLAN2,
  ISLAN3,
  ISLAN4,
  ISLAN5,
} from './interface';
import { SLAN1 } from './ModalSLAN1';
import { SLAN2 } from './ModalSLAN2';
import { SLAN3 } from './ModalSLAN3';
import { SLAN4 } from './ModalSLAN4';
import { SLAN5 } from './ModalSLAN5';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { validateFields } from './utils/validateFields';
import { fromatedSlaRequest } from './utils/formatedSLARequest';
import { createFirstPassClarificationNote } from '../../Hunter/utils/createClarificationNote';

interface IMoldalSLAProps {
  isOpen: boolean;
  typeModal: TypeModal;
  handleSaveSLA: () => void;
  handleChange: (e: any) => void;
  availableHours: string[];
  SLAInfo: ISLAComplet;
  onRequestClose: () => void;
  closer_name_scheduling: string | undefined;
  handleChangeCurrency: (e: any) => void;
}

type TypeModal = 'N1' | 'N2' | 'N3' | 'N4' | 'N5';

export function ModalSLA({
  isOpen,
  typeModal,
  handleChange,
  handleSaveSLA,
  SLAInfo,
  availableHours,
  onRequestClose,
  closer_name_scheduling,
  handleChangeCurrency,
}: IMoldalSLAProps): JSX.Element {
  const { user } = useAuth();

  const [info, setInfo] = useState<ISLAComplet>({
    ...{},
    profile: [],
  } as ISLAComplet);

  const [availableHoursInternal, setAvailableHoursInternal] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (
      info.profile.length > 0 &&
      info.connection_method &&
      info.connection_type &&
      info.contact_preferences &&
      info.objection &&
      info.valid_contact &&
      info.was_aware_precatory &&
      info.who_contacted
    ) {
      const clarificationNote = createFirstPassClarificationNote({
        hunter_name: user.name,
        who_contacted: info.who_contacted,
        connection_type: info.connection_type,
        connection_method: info.connection_method,
        profile: info.profile.join(','),
        influence: info.influence,
        objection: info.objection,
        valid_contact: info.valid_contact,
        value_to_negociate: info.value_to_negociate,
        contact_preferences: info.contact_preferences,
        was_aware_precatory: info.was_aware_precatory,
        reaction: info.customer_knows_that_we_can_solve_his_problem,
        plans: info.plans,
        doubts: info.customer_want_to_solve_the_problem,
        was_aware_PEC: info.was_aware_PEC,
        feel_uncomfortable: info.feel_uncomfortable,
      });
      setInfo(info_change => {
        return { ...info_change, clarification_note: clarificationNote };
      });
    } else {
      setInfo(info_change => {
        return { ...info_change, clarification_note: '' };
      });
    }
  }, [
    info.connection_method,
    info.connection_type,
    info.contact_preferences,
    info.influence,
    info.objection,
    info.profile.length,
    info.profile,
    info.valid_contact,
    info.value_to_negociate,
    info.was_aware_precatory,
    info.who_contacted,
    info.customer_knows_that_we_can_solve_his_problem,
    info.plans,
    info.customer_want_to_solve_the_problem,
    info.was_aware_PEC,
    info.feel_uncomfortable,
    user.name,
  ]);

  useEffect(() => {
    const getLastSLA = async () => {
      try {
        const { data, status } = await api.get(
          `/schedule/last-deal-sla/${info.ticket_id}`,
        );

        if (data) {
          data.reason_for_scheduling = null;
          data.objection = null;
          data.scheduled_for_closer = null;
          data.we_schedule_a_new_contact = null;
          data.day = null;
          data.hours = null;
          data.who_contacted =
            data.who_contacted === 'Outros (Nota)'
              ? 'Outros'
              : data.who_contacted;
          data.profile = data.profile ? data.profile.split(';') : [];
        }

        if (status === 200) setInfo({ ...data });
      } catch (error) {
        console.log(error);
      }
    };
    getLastSLA();
  }, [info.ticket_id]);

  const closeModal = useCallback(() => {
    if (typeModal !== 'N5') {
      setInfo({
        ...{},
        profile: [],
      } as ISLAComplet);
      onRequestClose();
    }
  }, [onRequestClose, typeModal]);

  const handleListAvailableHours = useCallback(
    async (date: string) => {
      try {
        const { data } = await api.get<string[]>(
          `/schedule/hunter-available-hours?date=${date}&user_id=${user.id}`,
        );
        const formattedHours = data.map(hour => {
          return format(new Date(hour), 'HH:mm');
        });
        setAvailableHoursInternal(formattedHours);
      } catch (error) {
        console.warn(error);
      }
    },
    [user.id],
  );

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (
    value,
    _,
    values,
  ): void => {
    setInfo({ ...info, [_]: values.float });
  };

  const handleChangeInternal = (e: any): void => {
    const { name, value } = e.target;

    if (
      name === 'Direto' ||
      name === 'Educado' ||
      name === 'Grosso' ||
      name === 'Instruído' ||
      name === 'Simples' ||
      name === 'Conversador'
    ) {
      const index = info.profile.findIndex(
        foundProfile => foundProfile === name,
      );

      if (index >= 0) {
        info.profile.splice(index, 1);
      } else {
        info.profile.push(name);
      }
    }

    if (name === 'day') {
      handleListAvailableHours(value);
    }
    if (name === 'hours') {
      const dateFormatted = new Date(`${info.day} ${value}`);
      info.schedule_date = dateFormatted;
    }

    info[name] = value;

    if (
      info.scheduled_for_closer === 'Não' ||
      info.we_schedule_a_new_contact === 'Não'
    ) {
      info.day = '';
      info.hours = '';
      info.schedule_date = null;
      info[name] = value;
    }

    setInfo({ ...info });
  };

  const handleSaveSLAInternal = async (): Promise<void> => {
    const IsValid = await Promise.resolve(validateFields(info, typeModal));
    const loader = document.getElementById('loader_up');

    if (IsValid) {
      try {
        loader.style.display = 'flex';
        const slaFormatted = fromatedSlaRequest(info, typeModal);
        if (!slaFormatted) {
          return;
        }

        await api.post('/schedule/hunter-sla', {
          ...slaFormatted,
          name: typeModal,
          hunter_id: user.id,
          ticket_id: info.ticket_id,
          sla_type: typeModal,
          profile: info.profile.join(';'),
        });
        closeModal();
        toast.success(`SLA ${typeModal} criado com sucesso!`);
        loader.style.display = 'none';
      } catch (error) {
        toast.error(`Não foi possível criar SLA ${typeModal}.`);
        loader.style.display = 'none';
      }
    }
  };

  const changeModal = (type: TypeModal): ReactNode => {
    switch (type) {
      case 'N1':
        return (
          <SLAN1
            typeModal={typeModal}
            SLA={info as ISLAN1}
            data={availableHoursInternal}
            handleChange={handleChangeInternal}
            handleChangeCurrency={handleOnValueChange}
            handleSaveSLA={handleSaveSLAInternal}
          />
        );
      case 'N2':
        return (
          <SLAN2
            typeModal={type}
            SLA={info as ISLAN2}
            data={availableHoursInternal}
            handleChange={handleChangeInternal}
            handleChangeCurrency={handleOnValueChange}
            handleSaveSLA={handleSaveSLAInternal}
          />
        );
      case 'N3':
        return (
          <SLAN3
            typeModal={type}
            SLA={info as ISLAN3}
            data={availableHoursInternal}
            handleChange={handleChangeInternal}
            handleChangeCurrency={handleOnValueChange}
            handleSaveSLA={handleSaveSLAInternal}
          />
        );
      case 'N4':
        return (
          <SLAN4
            typeModal={type}
            SLA={info as ISLAN4}
            data={availableHoursInternal}
            handleChange={handleChangeInternal}
            handleChangeCurrency={handleOnValueChange}
            handleSaveSLA={handleSaveSLAInternal}
          />
        );
      case 'N5':
        return (
          <SLAN5
            typeModal={type}
            SLA={SLAInfo as ISLAN5}
            data={availableHours}
            handleChange={handleChange}
            handleChangeCurrency={handleChangeCurrency}
            handleSaveSLA={handleSaveSLA}
            closer_name_scheduling={closer_name_scheduling}
          />
        );
      default:
        return (
          <SLAN1
            typeModal={type}
            SLA={SLAInfo as ISLAN1}
            data={availableHoursInternal}
            handleChange={handleSaveSLAInternal}
            handleChangeCurrency={handleOnValueChange}
            handleSaveSLA={handleSaveSLAInternal}
          />
        );
    }
  };
  return (
    <Modal isOpen={isOpen} style={SLAModalStyle} onRequestClose={closeModal}>
      <Loading loader_up />
      <Title name={typeModal} />
      <Main>{changeModal(typeModal)}</Main>
    </Modal>
  );
}
