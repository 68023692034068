import { useCallback, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';
import apiChatbot from '../../../services/apiChatbot';
import {
  Table,
  THead,
  TRowHead,
  TCellHead,
  TBody,
  TRowBody,
  TCell,
  Button,
  ButtonContainer,
  ButtonCreate,
  FiltersContainer,
  Select,
  Text,
  Option,
} from './styles';

interface ISmsReturn {
  id: string;
  mobile_phone: string;
  classification: string;
  unipix_trigger: {
    hubspot_deal_id: string;
  };
  message: string;
}

export const SmsReturn: React.FC = () => {
  const [smsReturns, setSmsReturns] = useState<ISmsReturn[]>([]);
  const [costCenterSelected, setCostCenterSelected] = useState('');

  const costCenters = [
    { id: '1', label: 'Federal', value: 'Federal' },
    { id: '2', label: 'Estadual', value: 'Estadual' },
  ];

  useEffect(() => {
    const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

    chatbot
      .get(`/sms/unrated/${costCenterSelected.toUpperCase()}`)
      .then(response => {
        setSmsReturns(response.data);
      });
  }, [costCenterSelected]);

  const stylingButton = useCallback(
    (active: boolean, sms_return: ISmsReturn) => {
      const actionButton = document.getElementById(
        `${sms_return.id}-${sms_return.classification}`,
      );

      if (active) {
        actionButton?.style.setProperty('border', '1px solid transparent');

        actionButton?.style.setProperty('color', '#ffffff');

        if (sms_return.classification === 'IN') {
          actionButton?.style.setProperty('background', '#0d901a');

          const actionButtonDesactive = document.getElementById(
            `${sms_return.id}-OUT`,
          );

          actionButtonDesactive?.style.setProperty('background', 'transparent');

          actionButtonDesactive?.style.setProperty(
            'border',
            '1px solid #de0c0c',
          );

          actionButtonDesactive?.style.setProperty('color', '#de0c0c');
        } else {
          actionButton?.style.setProperty('background', '#de0c0c');

          const actionButtonDesactive = document.getElementById(
            `${sms_return.id}-IN`,
          );

          actionButtonDesactive?.style.setProperty('background', 'transparent');

          actionButtonDesactive?.style.setProperty(
            'border',
            '1px solid #0d901a',
          );

          actionButtonDesactive?.style.setProperty('color', '#0d901a');
        }
      } else {
        actionButton?.style.setProperty('background', 'transparent');

        if (sms_return.classification === 'IN') {
          actionButton?.style.setProperty('border', '1px solid #0d901a');

          actionButton?.style.setProperty('color', '#0d901a');
        } else {
          actionButton?.style.setProperty('border', '1px solid #de0c0c');

          actionButton?.style.setProperty('color', '#de0c0c');
        }
      }
    },
    [],
  );

  const handleSmsReturn = useCallback(
    (action: string, sms_return: ISmsReturn) => {
      const index = smsReturns.findIndex(
        smsReturn => smsReturn.id === sms_return.id,
      );

      if (sms_return.classification !== action) {
        smsReturns[index].classification = action;

        stylingButton(true, sms_return);
      } else {
        stylingButton(false, sms_return);

        smsReturns[index].classification = null;
      }

      setSmsReturns(smsReturns);
    },
    [smsReturns, stylingButton],
  );

  const updateClassification = useCallback(() => {
    try {
      const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

      smsReturns.forEach(sms_return => {
        if (!sms_return.classification) {
          const index = smsReturns.findIndex(
            smsReturn => smsReturn.id === sms_return.id,
          );

          smsReturns[index].classification = 'OFF';

          setSmsReturns(smsReturns);
        }
      });

      chatbot
        .patch('/sms/classification', {
          classifications: smsReturns,
        })
        .then(() => {
          setSmsReturns([]);
        });

      toast.success('Retornos de SMS atualizados com sucesso.');
    } catch (error) {
      Swal.fire(
        'Erro!',
        'Não foi possível atualizar os retornos de SMS!',
        'error',
      );
    }
  }, [smsReturns]);

  return (
    <Container>
      <NavigationBar past_screens="Conexão > " active_screen="Retornos SMS" />
      <Toaster position="top-right" reverseOrder={false} />
      <Body>
        <Title name="Retornos SMS" />

        <FiltersContainer>
          <Text>Centro de custo:</Text>

          <Select
            name="cost_center"
            id="cost_center"
            value={costCenterSelected}
            onChange={e => setCostCenterSelected(e.target.value)}
          >
            <Option disabled />
            {costCenters.map(costCenter => (
              <Option key={costCenter.id} value={costCenter.value}>
                {costCenter.label}
              </Option>
            ))}
          </Select>
        </FiltersContainer>

        <Table>
          <THead>
            <TRowHead>
              <TCellHead>Número do credor</TCellHead>
              <TCellHead>Deal ID</TCellHead>
              <TCellHead>Mensagem</TCellHead>
              <TCellHead>Ações</TCellHead>
            </TRowHead>
          </THead>
          <TBody>
            {smsReturns.length > 0 &&
              smsReturns.map(sms_return => (
                <TRowBody key={sms_return.id}>
                  <TCell>{sms_return.mobile_phone}</TCell>
                  <TCell>{sms_return.unipix_trigger.hubspot_deal_id}</TCell>
                  <TCell>{sms_return.message}</TCell>
                  <TCell>
                    <Button
                      id={`${sms_return.id}-IN`}
                      className="in"
                      onClick={() => handleSmsReturn('IN', sms_return)}
                    >
                      IN
                    </Button>
                    <Button
                      id={`${sms_return.id}-OUT`}
                      className="out"
                      onClick={() => handleSmsReturn('OUT', sms_return)}
                    >
                      OUT
                    </Button>
                  </TCell>
                </TRowBody>
              ))}
          </TBody>
        </Table>
        <ButtonContainer>
          <ButtonCreate onClick={updateClassification}>
            Salvar alterações
          </ButtonCreate>
        </ButtonContainer>
      </Body>
    </Container>
  );
};
