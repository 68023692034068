import { PageButton, PaginationDiv } from './styles';

interface IPaginationProps {
  totalPages: number[];
  page: number;
  handleChangePagination: (page: number) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PaginationComponent({
  totalPages,
  page,
  handleChangePagination,
}: IPaginationProps) {
  return totalPages.length > 0 && totalPages.length <= 5 ? (
    <PaginationDiv>
      <PageButton
        active={false}
        onClick={() => {
          handleChangePagination(-1);
        }}
      >
        {'<'}
      </PageButton>
      {totalPages.length > 0
        ? totalPages.map(row => (
            <PageButton
              key={row}
              active={row === page}
              onClick={() => {
                handleChangePagination(row);
              }}
            >
              {row}
            </PageButton>
          ))
        : ''}
      <PageButton
        active={false}
        onClick={() => {
          handleChangePagination(0);
        }}
      >
        {'>'}
      </PageButton>
    </PaginationDiv>
  ) : totalPages.length > 5 ? (
    <PaginationDiv>
      <PageButton
        active={false}
        onClick={() => {
          handleChangePagination(-1);
        }}
      >
        {'<'}
      </PageButton>
      {totalPages.length > 0
        ? totalPages.map(row =>
            page <= 4 && row <= 7 ? (
              <PageButton
                key={row}
                active={row === page}
                onClick={() => {
                  handleChangePagination(row);
                }}
              >
                {row}
              </PageButton>
            ) : page > 4 && row >= page - 3 && row <= page + 3 ? (
              <PageButton
                active={row === page}
                onClick={() => {
                  handleChangePagination(row);
                }}
              >
                {row}
              </PageButton>
            ) : (
              ''
            ),
          )
        : ''}
      <PageButton
        active={false}
        onClick={() => {
          handleChangePagination(0);
        }}
      >
        {'>'}
      </PageButton>
    </PaginationDiv>
  ) : (
    <PaginationDiv />
  );
}
