import styled, { css } from 'styled-components';

interface StatusProps {
  statusTaskProps?: string;
  isBefore?: boolean;
  talkProps?: string;
  tryTimesProps?: string;
  statusBusinessTask?: string;
}

export const ModalSchedule = {
  content: {
    width: '48.75rem',
    height: '38rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
};

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const ModalHeader = styled.div<StatusProps>`
  width: 100%;
  height: 3.125rem;
  text-align: center;

  background: #008146;
  border-radius: 0.625rem 0.625rem 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    padding-left: 1.5rem;
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.25rem;
    color: #ffffff;
  }

  ${props =>
    props.statusTaskProps === 'Concluída'
      ? css`
          background: #3ca647;
        `
      : props.isBefore &&
        props.statusTaskProps === 'Pendente' &&
        (!props.tryTimesProps ||
          props.tryTimesProps === '0' ||
          props.tryTimesProps === 'null')
      ? css`
          background: #de0c0c;
        `
      : props.tryTimesProps > '0' && props.tryTimesProps !== 'null'
      ? css`
          background: #b4ad09;
        `
      : css`
          background: #014077;
        `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  margin-right: 1.5rem;

  button {
    border: none;
    background: transparent;
    height: 1.25rem;
  }

  #saveChanges {
    width: 10.375rem;
    height: 1.75rem;

    border-radius: 0.25rem;

    background: #ffffff;
    cursor: pointer;

    font-family: Poppins;
    font-weight: 600;
    font-size: 1rem;
    color: #1665d8;
  }

  #deleteActivity {
    margin-right: 1.5rem;
  }

  #closeModal {
    margin-left: 1.5rem;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 1.5rem;
  margin-top: 0.25rem;

  span {
    font-size: 1rem;
    font-family: Poppins;
    font-weight: 700;
    color: #041524;
    margin-bottom: 0.8rem;
  }

  #observations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .editInputsContainer {
    display: grid;
    grid-template-columns: repeat(5, 5.6rem) 8.5rem;
    gap: 0.5rem;
  }

  .situationActivity {
    display: grid;
    grid-template-columns: 11.8rem 5.6rem 5.6rem;
    gap: 0.5rem;
  }

  .hours {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin-bottom: 0.875rem;
    }

    div {
      display: grid;
      grid-template-columns: repeat(8, 5rem);
      gap: 0.75rem;
    }
  }
`;

export const InputsContainer = styled.div<StatusProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.6rem;

  label {
    color: #041524;
    font-weight: 600;
    margin-right: 0.25rem;
  }

  input,
  label,
  textarea {
    font-size: 1rem;
    font-family: Poppins;
  }

  a {
    color: #1665d8;
  }

  input {
    font-weight: 600;
  }

  input,
  textarea {
    border: none;
    color: #3d3d3d;
    background: transparent;
  }

  textarea {
    resize: vertical;
    width: 42rem;
    height: 1rem;
    min-height: 1rem;
    max-height: 7rem;
    text-align: justify;
    font-weight: 500;
  }

  p#stage,
  input#activity_situation {
    height: 1.625rem;
    border-radius: 0.25rem;

    font-family: Poppins;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    color: #041524;
  }

  p#stage {
    width: auto;
    background: #c4c4c4;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 0.75rem;
  }

  input#business_situation {
    font-weight: 500;

    ${props =>
      props.statusBusinessTask === 'Em aberto'
        ? css`
            color: #3ca647;
          `
        : css`
            color: #ea3f3f;
          `}
  }

  input#activity_situation {
    ${props =>
      props.statusTaskProps === 'Concluída'
        ? css`
            background: #3ca647;
          `
        : css`
            background: #ea3f3f;
          `}

    width: 5.5rem;
  }

  .fieldsName {
    width: 35rem;
  }

  #tryTimesInput,
  #observationsTextarea {
    background: #efefef;
    border: 0.031rem solid #063966;
    border-radius: 0.25rem;

    font-size: 0.875rem;
  }

  #tryTimesInput {
    width: 5.125rem;
    height: 1.625rem;
    text-align: center;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      opacity: 1;
    }
  }

  #observationsTextarea {
    padding-left: 0.25rem;

    width: 97.5%;
  }
`;

export const LineSeparator = styled.div`
  border: 1px solid #c2c2c2;
  width: 45.75rem;
  margin-bottom: 0.8rem;
`;

export const EditSchedulingByManagerContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 45.75rem;

  span {
    display: flex;
    align-items: center;

    width: 100%;
    height: 2.5rem;

    padding-left: 0.625rem;
    border-radius: 0.25rem;
  }

  span#title-choose-closer {
    background: rgba(207, 222, 243, 0.83);

    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;
export const ButtonContact = styled.button`
  display: flex;
  width: 13rem;
  height: 2.125rem;
  color: #ffffff;
  border: none;
  padding: 0.7rem;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #1665d8;
  border-radius: 0.375rem;
  margin: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
