/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { format } from 'date-fns';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import edit from '../../../../assets/icons/edit.svg';
import cancel from '../../../../assets/icons/cancel.svg';
import deleteIcon from '../../../../assets/icons/trash.svg';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import {
  ModalSchedule,
  ModalMain,
  ModalHeader,
  ButtonsContainer,
  ModalBody,
  FrequencyActivityContainer,
  InputsRadioContainer,
  InputsContainer,
  CredorContainer,
  ColumnCredorContainer,
} from './styles';

import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';

import { ICloserRestrictedTimeDTO } from '../../dtos/ICloserRestrictedTimeDTO';
import { INextActivityDTO } from '../../dtos/INextActivityDTO';
import { IActivityDTO } from '../../dtos/IActivityDTO';

import { ModalDeleteRestrictedTime } from '../ModalDeleteRestrictedTime';

Modal.setAppElement('#root');

interface IRestrictedTimeFormData {
  name: string;
  hubspot_deal_id?: string;
  dealname?: string;
  amount?: string;
  description?: string;
  frequency: string;
  time_init: string;
  time_end: string;
}

interface IIntervals {
  intervalInit: Date[];
  intervalEnd: Date[];
}

interface IHubspotDeal {
  hubspot_deal_id: string;
  hubspot_owner_id: string;
  dealname: string;
  amount: string;
  dealstage: string;
  loa: string;
}

interface IScheduleModalProps {
  isOpen: boolean;
  handleUpdateActivity:
    | ((
        activity: IActivityDTO | ICloserRestrictedTimeDTO,
        typeActivity: string,
        oldHour?: string | Date,
      ) => void)
    | null;
  restrictedTime: ICloserRestrictedTimeDTO | INextActivityDTO;
  onRequestClose: () => void;
  closerHistory: boolean;
  dateActivity: string;
}

export function ModalRestrictedTime({
  isOpen,
  onRequestClose,
  handleUpdateActivity,
  restrictedTime,
  closerHistory,
  dateActivity,
}: IScheduleModalProps) {
  const situationArray = ['Pendente', 'Concluída'];
  const [editFields, setEditFields] = useState(false);
  const [situationOption, setSituationOption] = useState<string>('');
  const [hoursAvailable, setHoursAvailable] = useState<IIntervals>(
    {} as IIntervals,
  );
  const [formData, setFormData] = useState<IRestrictedTimeFormData>(
    {} as IRestrictedTimeFormData,
  );
  const [hubspotDeal, setHubspotDeal] = useState<IHubspotDeal>(
    {} as IHubspotDeal,
  );
  const [openDeleteScheduleModal, setOpenDeleteScheduleModal] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (isOpen) {
      api
        .get(
          `schedule/list-closer-possible-hours?closer_id=${user.id}&date=${dateActivity}&activity_id=${restrictedTime.id}`,
        )
        .then(response => {
          setHoursAvailable(response.data);
        });

      setFormData({
        frequency: '',
        name: '',
        time_end: '',
        time_init: '',
        amount: restrictedTime.amount,
        dealname: restrictedTime.dealname,
        description: '',
        hubspot_deal_id: restrictedTime.hubspot_deal_id,
      });
    }
  }, [user, isOpen, restrictedTime, dateActivity]);

  const closeModal = useCallback(() => {
    onRequestClose();
    setEditFields(false);
  }, [onRequestClose]);

  const editActivitySuccess = useCallback(() => {
    toast.success('Atividade editada com sucesso');
  }, []);

  const actionActivityError = useCallback((message: string) => {
    Swal.fire('Erro!', `${message}`, 'error');
  }, []);

  const formattedTimestamp = useCallback((timestamp: Date | undefined) => {
    return timestamp
      ? `${String(new Date(timestamp).getDate()).padStart(2, '0')}/${String(
          new Date(timestamp).getMonth() + 1,
        ).padStart(2, '0')}/${new Date(timestamp).getFullYear()} - ${new Date(
          timestamp,
        ).getHours()}:${String(new Date(timestamp).getMinutes()).padStart(
          2,
          '0',
        )}`
      : '';
  }, []);

  const formattedEndTime = useCallback((timestamp: Date | undefined) => {
    return timestamp
      ? `${String(new Date(timestamp).getHours())}:${String(
          new Date(timestamp).getMinutes(),
        ).padStart(2, '0')}`
      : '';
  }, []);

  const formattedAmount = useCallback((activity_amount: string) => {
    const amount = Number(activity_amount).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return amount;
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const handleSetHubspotDeal = useCallback(
    (hubspot_ticket_id: string) => {
      try {
        api
          .get(`schedule/get-hubspot-deal/${hubspot_ticket_id}`)
          .then(response => {
            setHubspotDeal(response.data);
          });

        setFormData({
          ...formData,
          hubspot_deal_id: hubspotDeal.hubspot_owner_id,
          dealname: hubspotDeal.dealname,
          amount: hubspotDeal.amount,
        });
      } catch (error) {
        actionActivityError(error.response.data.message);
      }
    },
    [formData, hubspotDeal, actionActivityError],
  );

  const formattedDate = useCallback(() => {
    const formatted = format(new Date(), 'yyyy-MM-dd');

    return formatted;
  }, []);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  const handleSetWeekday = useCallback(() => {
    const weekday = new Date().toLocaleString('default', { weekday: 'long' });

    return weekday;
  }, []);

  const handleOpenDeleteScheduleModal = useCallback(() => {
    setOpenDeleteScheduleModal(true);
  }, []);

  const handleCloseDeleteScheduleModal = useCallback(() => {
    setOpenDeleteScheduleModal(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const oldHour = restrictedTime.time_init;

      const response = await api.patch('schedule/update-restricted-time', {
        id: restrictedTime.id,
        name: restrictedTime.name,
        hubspot_deal_id: formData.hubspot_deal_id
          ? formData.hubspot_deal_id
          : restrictedTime.hubspot_deal_id,
        dealname: formData.dealname
          ? formData.dealname
          : restrictedTime.dealname,
        amount: formData.amount ? formData.amount : restrictedTime.amount,
        description: formData.description
          ? formData.description
          : restrictedTime.description,
        frequency: formData.frequency
          ? formData.frequency
          : restrictedTime.frequency,
        time_init: formData.time_init
          ? new Date(`${formattedDate()} ${formData.time_init}`)
          : restrictedTime.time_init,
        time_end: formData.time_end
          ? new Date(`${formattedDate()} ${formData.time_end}`)
          : restrictedTime.time_end,
        status: situationOption,
      });

      editActivitySuccess();
      handleUpdateActivity(response.data, 'Restricted Time', oldHour);
      closeModal();
    } catch (error) {
      actionActivityError(error.response.data.message);
    }
  }, [
    restrictedTime,
    formData,
    formattedDate,
    situationOption,
    editActivitySuccess,
    actionActivityError,
    closeModal,
    handleUpdateActivity,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={ModalSchedule}
      data={restrictedTime}
    >
      <ModalMain>
        <ModalHeader>
          <p>
            {formattedTimestamp(restrictedTime.time_init)} às{' '}
            {formattedEndTime(restrictedTime.time_end)}
          </p>

          <ButtonsContainer>
            {!closerHistory ? (
              <button
                id="deleteActivity"
                type="button"
                onClick={handleOpenDeleteScheduleModal}
              >
                <img src={deleteIcon} alt="Excluir atividade" />
              </button>
            ) : (
              ''
            )}

            {editFields && !closerHistory ? (
              <>
                <button id="saveChanges" type="button" onClick={handleSubmit}>
                  Salvar alterações
                </button>
              </>
            ) : !editFields && !closerHistory ? (
              <>
                <button type="button" onClick={() => setEditFields(true)}>
                  <img src={edit} alt="Editar informações" />
                </button>
              </>
            ) : (
              ''
            )}

            <button id="closeModal" type="button" onClick={closeModal}>
              <img src={cancel} alt="Fechar modal" />
            </button>
          </ButtonsContainer>
        </ModalHeader>

        <ModalBody>
          <span>{restrictedTime.name}</span>

          {editFields && restrictedTime.name === 'Almoço' ? (
            <>
              <label htmlFor="time_init">Horário definido:</label>
              <InputsContainer>
                <select
                  name="time_init"
                  id="time_init"
                  onChange={handleInputChange}
                  defaultValue="Horário inicial"
                >
                  <option value="Horário inicial" disabled>
                    Horário inicial
                  </option>
                  {hoursAvailable.intervalInit &&
                    hoursAvailable.intervalInit.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>

                <p id="hourText">até as</p>

                <select
                  name="time_end"
                  id="time_end"
                  defaultValue="Horário final"
                  onChange={handleInputChange}
                >
                  <option value="Horário final" disabled>
                    Horário final
                  </option>
                  {hoursAvailable.intervalEnd &&
                    hoursAvailable.intervalEnd.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>
              </InputsContainer>

              <FrequencyActivityContainer>
                <InputsRadioContainer>
                  <input
                    type="radio"
                    id="not_repeat"
                    name="frequency"
                    value="not_repeat"
                    defaultChecked={restrictedTime.frequency === 'not_repeat'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="not_repeat">Não se repete</label>
                </InputsRadioContainer>

                <InputsRadioContainer>
                  <input
                    type="radio"
                    id="weekly"
                    name="frequency"
                    value="weekly"
                    defaultChecked={restrictedTime.frequency === 'weekly'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="weekly">{`Padronizar semanal (Todas as ${handleSetWeekday()})`}</label>
                </InputsRadioContainer>

                <InputsRadioContainer>
                  <input
                    type="radio"
                    id="every_day"
                    name="frequency"
                    value="every_day"
                    defaultChecked={restrictedTime.frequency === 'every_day'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="every_day">Padronizar todos os dias</label>
                </InputsRadioContainer>
              </FrequencyActivityContainer>
            </>
          ) : editFields &&
            (restrictedTime.name === 'Indisponível' ||
              restrictedTime.name === 'Reunião') ? (
            <>
              <label htmlFor="time_init">Horário definido:</label>
              <InputsContainer>
                <select
                  name="time_init"
                  id="time_init"
                  onChange={handleInputChange}
                  defaultValue="Horário inicial"
                >
                  <option value="Horário inicial" disabled>
                    Horário inicial
                  </option>
                  {hoursAvailable.intervalInit &&
                    hoursAvailable.intervalInit.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>

                <p id="hourText">até as</p>

                <select
                  name="time_end"
                  id="time_end"
                  defaultValue="Horário final"
                  onChange={handleInputChange}
                >
                  <option value="Horário final" disabled>
                    Horário final
                  </option>
                  {hoursAvailable.intervalEnd &&
                    hoursAvailable.intervalEnd.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>
              </InputsContainer>

              <label htmlFor="description">Descrição da atividade:</label>
              <InputsContainer>
                <input
                  type="text"
                  id="description"
                  name="description"
                  className="editInput"
                  defaultValue={restrictedTime.description}
                  onChange={handleInputChange}
                />
              </InputsContainer>

              <FrequencyActivityContainer>
                <InputsRadioContainer>
                  <input
                    type="radio"
                    id="not_repeat"
                    name="frequency"
                    value="not_repeat"
                    defaultChecked={restrictedTime.frequency === 'not_repeat'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="not_repeat">Não se repete</label>
                </InputsRadioContainer>

                <InputsRadioContainer>
                  <input
                    type="radio"
                    id="weekly"
                    name="frequency"
                    value="weekly"
                    defaultChecked={restrictedTime.frequency === 'weekly'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="weekly">{`Padronizar semanal (Todas as ${handleSetWeekday()})`}</label>
                </InputsRadioContainer>

                <InputsRadioContainer>
                  <input
                    type="radio"
                    id="every_day"
                    name="frequency"
                    value="every_day"
                    defaultChecked={restrictedTime.frequency === 'every_day'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="every_day">Padronizar todos os dias</label>
                </InputsRadioContainer>
              </FrequencyActivityContainer>
            </>
          ) : editFields && restrictedTime.name === 'Passagem de bastão' ? (
            <>
              <label htmlFor="time_init">Horário definido:</label>
              <InputsContainer>
                <select
                  name="time_init"
                  id="time_init"
                  onChange={handleInputChange}
                  defaultValue="Horário inicial"
                >
                  <option value="Horário inicial" disabled>
                    Horário inicial
                  </option>
                  {hoursAvailable.intervalInit &&
                    hoursAvailable.intervalInit.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>

                <p id="hourText">até as</p>

                <select
                  name="time_end"
                  id="time_end"
                  defaultValue="Horário final"
                  onChange={handleInputChange}
                >
                  <option value="Horário final" disabled>
                    Horário final
                  </option>
                  {hoursAvailable.intervalEnd &&
                    hoursAvailable.intervalEnd.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>
              </InputsContainer>

              <CredorContainer>
                <ColumnCredorContainer>
                  <label htmlFor="hubspot_deal_id">ID do negócio:</label>
                  <InputsContainer>
                    <input
                      type="text"
                      id="hubspot_deal_id"
                      name="hubspot_deal_id"
                      defaultValue={formData.hubspot_deal_id}
                      onChange={e => handleSetHubspotDeal(e.target.value)}
                    />
                  </InputsContainer>
                </ColumnCredorContainer>

                <ColumnCredorContainer>
                  <label htmlFor="dealname">Nome do credor:</label>
                  <InputsContainer>
                    <input
                      type="text"
                      id="dealname"
                      name="dealname"
                      disabled
                      value={formData.dealname}
                      onChange={handleInputChange}
                    />
                  </InputsContainer>
                </ColumnCredorContainer>

                <ColumnCredorContainer>
                  <label htmlFor="amount">Valor:</label>
                  <InputsContainer>
                    <input
                      type="text"
                      id="amount"
                      name="amount"
                      disabled
                      value={formattedAmount(formData.amount)}
                      onChange={handleInputChange}
                    />
                  </InputsContainer>
                </ColumnCredorContainer>
              </CredorContainer>

              <label htmlFor="situation">Situação:</label>
              <InputsContainer className="editInputsContainer">
                {situationArray.map(situation => (
                  <ScheduleOptionsButton
                    key={situation}
                    selectedValue={situationOption === situation}
                    type="button"
                    name="situation"
                    onClick={() => setSituationOption(situation)}
                  >
                    {situation}
                  </ScheduleOptionsButton>
                ))}
              </InputsContainer>
            </>
          ) : !editFields &&
            (restrictedTime.name === 'Indisponível' ||
              restrictedTime.name === 'Reunião') ? (
            <>
              <p id="description">{restrictedTime.description}</p>
            </>
          ) : !editFields && restrictedTime.name === 'Almoço' ? (
            <InputsContainer>
              <p>
                {restrictedTime.frequency === 'every_day'
                  ? 'Atividade definida para todos os dias.'
                  : 'Atividade definida apenas para hoje.'}
              </p>
            </InputsContainer>
          ) : !editFields && restrictedTime.name === 'Passagem de bastão' ? (
            <>
              <InputsContainer>
                <label htmlFor="negocio_id">ID do negócio:</label>
                <input
                  type="text"
                  name="negocio_id"
                  value={restrictedTime.hubspot_deal_id}
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="dealname">Nome do credor:</label>
                <input
                  type="text"
                  name="dealname"
                  id="dealname"
                  className="fieldsName"
                  value={restrictedTime.dealname}
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="amount">Valor:</label>
                <input
                  type="text"
                  name="amount"
                  value={formattedAmount(restrictedTime.amount)}
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="stage">Etapa:</label>
                <p id="stage">{restrictedTime.stage_name}</p>
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="hubspot_deal_id">Link Hubspot:</label>
                <a
                  href={`https://app.hubspot.com/contacts/7857327/deal/${restrictedTime.hubspot_deal_id}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {`https://app.hubspot.com/contacts/7857327/deal/${restrictedTime.hubspot_deal_id}`}
                </a>
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="situation">Situação:</label>
                <p id="situation">{restrictedTime.status}</p>
              </InputsContainer>
            </>
          ) : (
            ''
          )}
        </ModalBody>
        <ModalDeleteRestrictedTime
          isOpen={openDeleteScheduleModal}
          onRequestClose={handleCloseDeleteScheduleModal}
          handleUpdateActivity={handleUpdateActivity}
          restrictedTime={restrictedTime}
          handleCloseModalRestrictedTime={onRequestClose}
          dateSchedulingPriority={dateActivity}
        />
      </ModalMain>
    </Modal>
  );
}
