import styled, { css } from 'styled-components';

export const Content = styled.div`
  height: 40rem;

  display: flex;
  flex-direction: column;

  overflow: auto;

  padding-left: 0.05rem;
`;

interface QuantityActivitiesProps {
  moreThanOne: boolean;
}

export const ScheduleLineContainer = styled.div<QuantityActivitiesProps>`
  height: 6.25rem;

  display: flex;
  align-items: center;

  border-top: 1px solid rgba(194, 194, 194, 0.5);

  p {
    font-weight: 600;
    font-size: 0.938rem;
    color: var(--blue-700);
  }

  ${props =>
    props.moreThanOne
      ? css`
          height: 11.75rem;
        `
      : css`
          height: 6.25rem;
        `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.5%;
`;

export const LineTableContent = styled.div<QuantityActivitiesProps>`
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 0 2.5rem;

  width: 92.4%;
  height: 6.25rem;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  ${props =>
    props.moreThanOne
      ? css`
          height: 11.75rem;
          justify-content: space-between;
        `
      : css`
          height: 6.25rem;
          flex-direction: column;
          justify-content: center;
        `}
`;

interface InfosTasksContainerProps {
  statusTask: string;
  isBefore: boolean;
  try_times: number;
  moreThanOne: boolean;
}

export const InfosTasksContainer = styled.div<InfosTasksContainerProps>`
  height: 5rem;

  display: flex;
  justify-content: space-between;

  margin: 0;
  cursor: pointer;

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--green-400),
            0 0.25rem 0.25rem var(--overlay);
        `
      : props.isBefore && props.statusTask === 'Pendente'
      ? css`
          background: rgba(236, 68, 68, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--red-700),
            0 0.25rem 0.25rem var(--overlay);
        `
      : props.statusTask === 'Pendente' && props.try_times > 0
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--yellow-500),
            0 0.25rem 0.25rem var(--overlay);
        `
      : css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: inset 0.188rem 0 0 var(--blue-500),
            0 0.25rem 0.25rem var(--overlay);
        `}

  ${props =>
    props.moreThanOne
      ? css`
          height: 9.875rem;
          width: 18.125rem;
          flex-direction: column;
        `
      : css`
          height: 5rem;
          width: 100%;
        `}
`;

interface HourTypeCloserContainerProps {
  statusTask: string;
  isBefore: boolean;
  try_times: number;
}

export const HourTypeCloserContainer = styled.div<HourTypeCloserContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.25rem 0 0.25rem 0.625rem;

  p {
    font-family: 500;
    line-height: 1.125rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          p {
            color: var(--green-400);
          }
        `
      : props.isBefore && props.statusTask === 'Pendente'
      ? css`
          p {
            color: var(--red-700);
          }
        `
      : props.statusTask === 'Pendente' && props.try_times > 0
      ? css`
          p {
            color: var(--yellow-500);
          }
        `
      : css`
          p {
            color: var(--blue-500);
          }
        `}

  p:nth-child(1) {
    display: flex;
    flex-direction: row;
  }

  p.credor {
    font-size: 0.65rem;
    color: var(--gray-800);
  }

  img {
    margin-left: 0.23rem;
  }
`;

export const HourNameActivity = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IntervalTime = styled.p`
  display: flex;
  gap: 0.25rem;
`;

interface StatusTaskStageContainerProps {
  statusTask: string;
  isBefore: boolean;
  moreThanOne: boolean;
}

export const StatusTaskStageContainer = styled.div<StatusTaskStageContainerProps>`
  margin-right: 0.938rem;
  margin-left: 0.625rem;

  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 0.3rem 0;

  p {
    font-weight: 600;
    font-size: 0.75rem;

    width: 5.125rem;
    height: 1.625rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
  }

  p#stage {
    background: rgba(60, 166, 71, 0.08);
    border: 0.125rem solid var(--gray-800);
    width: fit-content;
    padding: 0 0.25rem 0 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
  }

  p#status {
    ${props =>
      props.statusTask === 'Concluída'
        ? css`
            background: var(--green-400);
          `
        : props.statusTask === 'Pendente'
        ? css`
            background: var(--red-500);
          `
        : css`
            background: var(--yellow-500);
          `}
  }

  ${props =>
    props.moreThanOne &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

export const StatusConcludeTask = styled.div`
  position: relative;
  display: inline-block;
`;

interface ButtonSituationProps {
  statusTask: string;
}

export const ButtonSituation = styled.button<ButtonSituationProps>`
  width: 5.125rem;
  height: 1.625rem;
  border: none;
  border-radius: 0.25rem;

  font-weight: 600;
  font-size: 0.75rem;
  color: #041524;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          background: var(--green-400);
        `
      : props.statusTask === 'Tentou'
      ? css`
          background: var(--yellow-500);
        `
      : css`
          background: var(--red-500);
        `}
`;

interface TryTimesProps {
  statusTask: string;
  tryTimes: string;
}

export const TryTimes = styled.span<TryTimesProps>`
  font-weight: 700;
  font-size: 1rem;
  margin-left: 0.85rem;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          color: var(--green-400);
        `
      : props.statusTask === 'Tentou'
      ? css`
          color: var(--yellow-500);
        `
      : css`
          color: var(--red-500);
        `}
`;
