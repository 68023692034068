import { useCallback } from 'react';
import Modal from 'react-modal';
import { IProposalFormDTO } from '../../../dtos/IProposalFormDTO';

import {
  CloserRequestContainer,
  Title,
  ClosersContainer,
  CloserName,
} from './styles';

Modal.setAppElement('#root');

interface ModalCloserRequestProps {
  isOpen: boolean;
  onRequestClose: () => void;
  proposals: IProposalFormDTO[];
  setProposal: (proposal: IProposalFormDTO) => void;
  setOpenModalApprovalRequest: (openApprovalRequestModal: boolean) => void;
}

export function ModalCloserRequest({
  isOpen,
  onRequestClose,
  proposals,
  setProposal,
  setOpenModalApprovalRequest,
}: ModalCloserRequestProps): JSX.Element {
  const handleOpenApprovalRequestModal = useCallback(
    (proposal: IProposalFormDTO) => {
      onRequestClose();

      setProposal(proposal);
      setOpenModalApprovalRequest(true);
    },
    [onRequestClose, setOpenModalApprovalRequest, setProposal],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={CloserRequestContainer}
    >
      <Title>Solicitam aprovação</Title>

      <ClosersContainer>
        {proposals.length > 0 &&
          proposals.map(proposal => (
            <CloserName
              key={proposal.id}
              onClick={() => handleOpenApprovalRequestModal(proposal)}
            >
              {proposal.closer_name}
            </CloserName>
          ))}
      </ClosersContainer>
    </Modal>
  );
}
