import styled, { css } from 'styled-components';

interface AvailabilityProps {
  availabilityProps?: boolean;
}

export const AvailabilityContainer = styled.div<AvailabilityProps>`
  position: sticky;
  top: 5.375rem;
  width: 75vw;
  max-width: 80rem;
  height: 2.063rem;
  margin-bottom: 0.75rem;
  background: #f9f9f8;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.22);
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  transition: background-color 1s;

  p#containerText {
    font-family: Poppins;
    font-weight: 500;
    font-size: 1rem;
    color: #ffffff;
    width: 87%;
    text-align: center;
    padding-left: 13%;
  }

  ${props =>
    props.availabilityProps
      ? css`
          background: #eb5757;
        `
      : css`
          background: #3ca647;
        `};
`;

export const UnavailabilityButton = styled.button<AvailabilityProps>`
  border: none;
  display: flex;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.75rem;
  width: 12%;
  height: 1.375rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;

  p {
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.813rem;
    transition: background-color 1s;
  }

  ${props =>
    props.availabilityProps
      ? css`
          p {
            color: #eb5757;
          }
        `
      : css`
          p {
            color: #3ca647;
          }
        `}
`;

export const InfosWorkImpactContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 9.5rem;
  width: 75vw;
  max-width: 80rem;

  background: #f9f8f8;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.22);
  border-radius: 0.5rem;

  margin-bottom: 1.75rem;

  .columnImpact {
    margin-bottom: 3rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 3.375rem;

  background: #e0e0e0;

  font-family: Poppins;
  font-weight: 600;
  font-size: 1.125rem;
  color: #1665d8;

  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 24%;
  height: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 7.688rem;
    height: 1.375rem;

    border: none;
    border-radius: 0.125rem;
    background: rgba(220, 220, 220, 0.83);

    font-size: 0.813rem;
    font-weight: 600;
    color: #1665d8;

    transition: 0.5s;
  }

  button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const TitleColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: #3d3d3d;
  }
`;

export const ButtonsAtendimentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;

  button {
    width: 1.375rem;
    height: 1.375rem;
    border: none;
    font-size: 1.5rem;
  }
`;

export const LineSeparator = styled.div`
  border-right: 1px solid #9d9d9d;
  height: 8.438rem;
`;

export const ValueContainer = styled.div<AvailabilityProps>`
  display: flex;
  flex-direction: row;

  p {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.125rem;
    color: #041524;
  }

  img {
    margin-right: 0.23rem;
  }
  ${props =>
    props.availabilityProps
      ? css`
          p#unavailabilityTimer {
            color: #eb5757;
          }
        `
      : css`
          p#unavailabilityTimer {
            color: #041524;
          }
        `}
`;

export const PriorizationCredorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.75rem;
    color: #041524;
    text-align: center;
  }
`;
