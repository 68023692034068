import addZerosToNumber from './addZerosToNumber';

export default function millisecondsToMinutesAndSecondsAndDays(
  milliseconds: number,
): string {
  const seconds = Math.trunc((milliseconds / 1000) % 60);

  const minutes = Math.trunc((milliseconds / (1000 * 60)) % 60);

  const hours = Math.trunc((milliseconds / (1000 * 60 * 60)) % 24);

  const days = Math.trunc(milliseconds / (1000 * 60 * 60 * 24));

  let daysFormatted = '';

  if (days > 0) {
    daysFormatted = `${addZerosToNumber(days)}d `;
  }

  return `${daysFormatted}${addZerosToNumber(hours)}:${addZerosToNumber(
    minutes,
  )}:${addZerosToNumber(seconds)}`;
}
