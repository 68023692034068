import { ISLAN3, ISLAProps } from '../interface';
import { SLABase } from '../ModalSLABase';
import { SelectSLA } from '../components/SelectSLA';
import { reasonsN3 } from '../utils/optionsSLA';
import { TextAreaSLA } from '../components/TextAreaSLA';
import { InputButtonSave } from '../components/InputButtonSave';

interface ISLAN3Props extends ISLAProps {
  SLA: ISLAN3;
}

export function SLAN3({
  SLA,
  data,
  handleChange,
  handleSaveSLA,
  typeModal,
  handleChangeCurrency,
}: ISLAN3Props): JSX.Element {
  return (
    <>
      <SLABase
        SLA={SLA}
        handleChange={handleChange}
        handleChangeCurrency={handleChangeCurrency}
        typemodal={typeModal}
        data={data}
      />

      <SelectSLA
        title="Motivo do caso estar na etapa N3 *"
        name="reason_for_scheduling"
        value={SLA.reason_for_scheduling || ''}
        handleChange={handleChange}
        options={reasonsN3}
      />

      <TextAreaSLA
        title="Observação *"
        name="observation"
        value={SLA.observation || ''}
        handleChange={handleChange}
        disable={false}
      />

      <TextAreaSLA
        title="Nota de esclarecimento:"
        name="clarification_note"
        value={SLA.clarification_note || ''}
        handleChange={handleChange}
        disable
      />

      <InputButtonSave handleSaveSLA={handleSaveSLA} />
    </>
  );
}
