import styled from 'styled-components';

export const ContactItemContainer = styled.div`
  display: flex;
  align-items: center;
  input[type='radio'] {
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: var(--gray-200);
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 0.625em;
    height: 0.625em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--blue-700);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-100);
  width: 33.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  margin: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;

  span {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .end-span {
    flex: 0.7;
  }
`;
