import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Container } from '../../../components/Container';
import {
  H1Navigation,
  H1NavigationNow,
} from '../../../components/Styles/Navigation';
import { CreateTableRow } from './CreateTableRow';
import {
  AddPhoneButton,
  Separator,
  Table,
  TableHead,
  TableHeader,
  Title,
  VerticalSeparator,
} from './styles';
import { WppConfigTableRow } from './WppConfigTableRow';

interface ISunshinePhone {
  id: string;
  name: string;
  phone: string;
  integration_id: string;
  connection_source: string;
}

export function WppConfig(): JSX.Element {
  const [sunshinePhones, setSunshinePhones] = useState<ISunshinePhone[]>([]);
  const [creationMode, setCreationMode] = useState(false);

  const getSunshinePhones = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_MARKETING_URL}/sunshine/phones`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      setSunshinePhones(data.sunshine_phones);
    } catch (error) {
      Swal.fire(`${error.response.status}!`, error.message, 'error');
    }
  }, []);

  const creationModeSwitcher = useCallback(() => {
    setCreationMode(!creationMode);
  }, [creationMode]);

  useEffect(() => {
    getSunshinePhones();
  }, [getSunshinePhones]);

  return (
    <Container>
      <H1Navigation>Conexão {'>'}</H1Navigation>
      <H1NavigationNow>Configuração WhatsApp</H1NavigationNow>

      <Title>Configuração WhatsApp</Title>
      <Separator />

      <Table>
        <TableHeader>
          <TableHead width="17.1875rem">Nome</TableHead>
          <VerticalSeparator />
          <TableHead width="15.3125rem">Número</TableHead>
          <VerticalSeparator />
          <TableHead width="17.375rem">ID de Integração</TableHead>
          <VerticalSeparator />
          <TableHead width="15rem">Meio de Conexão</TableHead>
          <TableHead width="6.5rem" />
        </TableHeader>

        {sunshinePhones.map(
          ({ connection_source, id, integration_id, name, phone }) => (
            <WppConfigTableRow
              connection_source={connection_source}
              id={id}
              integration_id={integration_id}
              name={name}
              phone={phone}
              getSunshinePhones={getSunshinePhones}
            />
          ),
        )}

        <CreateTableRow
          closeCreationMode={creationModeSwitcher}
          enabled={creationMode}
          getSunshinePhones={getSunshinePhones}
        />
      </Table>

      <AddPhoneButton onClick={creationModeSwitcher}>Adicionar</AddPhoneButton>
    </Container>
  );
}
