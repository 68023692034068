import styled from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const Container = {
  content: {
    width: '42.188rem',
    height: '35rem',
    margin: 'auto',
    padding: '0 1.75rem',
    borderRadius: '0.625rem',
    alignItems: 'center',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const FieldSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.5rem;

  background: var(--blue-100);
  border-radius: 0.25rem;

  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-700);
`;

export const TitleInput = styled.input`
  width: 100%;
  height: 1.875rem;
  margin-top: 0.563rem;

  background: var(--gray-100);
  border-radius: 0.25rem;
  border: none;
  padding-left: 0.25rem;

  font-weight: 400;
  font-size: 0.75rem;
  color: var(--gray-800);

  ::placeholder {
    color: var(--gray-500);
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  .small-field {
    width: 18.125rem;
    margin: 0.5rem 0;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  .hubspot-deal {
    width: 18.125rem;
    height: 1.875rem;
  }
`;

export const InputsTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 0.75rem;
  color: var(--gray-800);

  width: 18.125rem;
`;

export const Select = styled.select`
  width: 8.438rem;
  height: 1.875rem;

  background: var(--gray-100);
  border: 0.031rem solid var(--blue-600);
  box-sizing: border-box;
  border-radius: 0.25rem;

  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--gray-800);
`;
export const ContainerDayPicker = styled.div`
  display: flex;
  flex-direction: column;

  width: 18.125rem;

  background: var(--gray-100);

  div input {
    background: var(--gray-100);
    border: none;

    width: 15.5rem;
    height: 1.875rem;

    color: var(--blue-700);
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  align-items: center;

  border: 0.031rem solid var(--blue-600);
  border-radius: 0.25rem;
  padding-left: 0.5rem;

  width: 100%;
  gap: 1rem;

  input {
    font-weight: 600;
    font-size: 0.85rem;
  }
`;

export const InputsDeal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.313rem;

  #hubspot_deal_id {
    border: 0.5px solid var(--blue-600);
  }

  #dealname {
    border: none;
  }
`;

export const Textarea = styled.textarea`
  resize: none;
  border: none;

  height: ${pxToRem(90)};

  background: var(--gray-100);
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;

  font-weight: 400;
  font-size: ${pxToRem(12)};
  color: var(--gray-800);

  ::placeholder {
    color: var(--gray-500);
  }
`;

export const NamesActivityContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 8.438rem);
  justify-content: space-between;
  gap: 0.438rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const ButtonToSchedule = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 9.688rem;
  height: 2rem;

  gap: 0.5rem;
  border: none;
  padding: 1rem;
  margin-top: 1.5rem;
  background: var(--blue-600);
  border-radius: 0.5rem;

  font-weight: 600;
  font-size: 0.9rem;
  color: var(--white);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
