import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ICasePriorizationDTO } from '../dtos/ICasePriorizationDTO';
import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';
import { formatAmount } from '../../../utils/formatAmount';
import {
  BodyContainer,
  ColumnBodyContainer,
  ColumnHeadContainer,
  ContainerTable,
  HeadContainer,
  InputSearch,
  RowBody,
  RowContainer,
  TableContainer,
} from './styles';
import { ModalUpdateActivity } from '../ModalUpdateActivity';

interface TableCasePriorizationSlaProps {
  casePriorizations: ICasePriorizationDTO[];
  setCasePriorizations: (cases: ICasePriorizationDTO[]) => void;
}

export function TableCasePriorization({
  casePriorizations,
  setCasePriorizations,
}: TableCasePriorizationSlaProps): JSX.Element {
  const itemsPerPage = 8;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<ICasePriorizationDTO[]>(
    [] as ICasePriorizationDTO[],
  );

  const [openCaseModal, setOpenActivityModal] = useState(false);
  const [selectedCase, setSelectedActivity] = useState<ICasePriorizationDTO>(
    {} as ICasePriorizationDTO,
  );

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback(
    (activity: ICasePriorizationDTO) => {
      setSelectedActivity(activity);
      setOpenActivityModal(true);
    },
    [],
  );

  const searchDebitors = useCallback(
    (value: string) => {
      const filterDebitors = casePriorizations.filter(
        caseFound =>
          String(caseFound.hubspot_ticket_id).includes(value) ||
          caseFound.subject.includes(value.toLocaleUpperCase()),
      );

      setCurrentItems(filterDebitors);
    },
    [casePriorizations],
  );

  useEffect(() => {
    setCurrentItems(casePriorizations.slice(0, itemsPerPage));

    const pages: number[] = [];

    for (
      let index = 1;
      index <= Math.ceil(casePriorizations.length / itemsPerPage);
      index++
    ) {
      pages.push(index);
    }
    setTotalPages(pages);
  }, [casePriorizations]);

  const getCases = useCallback(() => {
    setCurrentItems(casePriorizations.slice(0, itemsPerPage));

    const pages: number[] = [];

    for (
      let index = 1;
      index <= Math.ceil(casePriorizations.length / itemsPerPage);
      index++
    ) {
      pages.push(index);
    }
    setTotalPages(pages);
  }, [casePriorizations]);

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getCases() : searchDebitors(value);
    },
    [getCases, searchDebitors],
  );

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: ICasePriorizationDTO[] = casePriorizations;

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(items.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }

      setTotalPages(pages);

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [casePriorizations, page, totalPages],
  );

  return (
    <>
      <InputSearch
        onChange={handleSearchInputChange}
        placeholder="Pesquise pelo Ticket ID ou Nome do Ticket"
      />
      <ContainerTable>
        <TableContainer>
          <HeadContainer>
            <RowContainer className="header">
              <ColumnHeadContainer>Ticket ID</ColumnHeadContainer>
              <ColumnHeadContainer>Priorização</ColumnHeadContainer>
              <ColumnHeadContainer>Contato</ColumnHeadContainer>
              <ColumnHeadContainer>Nome do Ticket</ColumnHeadContainer>
              <ColumnHeadContainer>Valor</ColumnHeadContainer>
              <ColumnHeadContainer>Status</ColumnHeadContainer>
              <ColumnHeadContainer>Fundo Parceiro</ColumnHeadContainer>
              <ColumnHeadContainer>Providências Parceiro</ColumnHeadContainer>
            </RowContainer>
          </HeadContainer>

          <BodyContainer>
            {currentItems.length > 0 &&
              currentItems.map(casePriority => (
                <RowBody
                  key={casePriority.hubspot_ticket_id}
                  className="row-props-body"
                >
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {casePriority.hubspot_ticket_id}
                  </ColumnBodyContainer>
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {casePriority.priority}
                  </ColumnBodyContainer>

                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  />

                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {casePriority.subject}
                  </ColumnBodyContainer>

                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {formatAmount(casePriority.amount)}
                  </ColumnBodyContainer>

                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {casePriority.status_ticket.toUpperCase()}
                  </ColumnBodyContainer>
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {casePriority.partner_fund}
                  </ColumnBodyContainer>
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(casePriority)}
                  >
                    {casePriority.providence_partner}
                  </ColumnBodyContainer>
                </RowBody>
              ))}
          </BodyContainer>
        </TableContainer>
        {casePriorizations.length >= 8 && (
          <PaginationComponent
            totalPages={totalPages}
            page={page}
            handleChangePagination={handleChangePagination}
          />
        )}
        {Object.keys(selectedCase).length > 0 && (
          <ModalUpdateActivity
            isOpen={openCaseModal}
            onRequestClose={handleCloseActivityModal}
            activity={selectedCase}
            allCases={casePriorizations}
            setAllCases={setCasePriorizations}
            currentItems={currentItems}
            setCurrentItems={setCurrentItems}
          />
        )}
      </ContainerTable>
    </>
  );
}
