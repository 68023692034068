import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import { AiOutlineCheck } from 'react-icons/ai';
import Swal from 'sweetalert2';
import api from '../../../services/apiCustomerSuccess';

import { changePagination } from '../../../utils/changePagination';

import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Styles/Title';
import { PaginationComponent } from '../../../components/Pagination';

import search_icon from '../../../assets/icons/search.svg';
import attachmentIcon from '../../../assets/icons/attachment.svg';
import approvedIcon from '../../../assets/icons/approved.svg';
import pendingIcon from '../../../assets/icons/pending.svg';
import notApprovedIcon from '../../../assets/icons/notApproved.svg';
import checkIcon from '../../../assets/icons/check.svg';
import {
  DivSeparator,
  Separator,
  SearchContainer,
  InputSearch,
  Table,
  TableHead,
  Tbody,
  THeadLine,
  TBodyLine,
  Icon,
  ButtonContainer,
  ButtonSendFiles,
} from './styles';

interface Customer {
  id: string;
  name: string;
  cpf: string;
  birth_date: string;
  status: string;
  hubspot_deal_id: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface CustomerZipFiles {
  files: FileProps[];
  hubspot_deal_id: string;
}

export function Assignors(): JSX.Element {
  const [customers, setCustomers] = useState<Customer[]>([] as Customer[]);
  const [customerZipFiles, setCustomerZipFiles] = useState<CustomerZipFiles[]>(
    [] as CustomerZipFiles[],
  );
  const [status, setStatus] = useState('Status');

  const itemsPerPage = 8;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<Customer[]>(
    [] as Customer[],
  );

  useEffect(() => {
    api.get('/customers').then(response => {
      setCustomers(response.data);
      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, []);

  const searchCustomers = useCallback(
    async (value: string, isFilterStatus?: boolean) => {
      if (isFilterStatus) {
        const filterStatus =
          value === 'approved'
            ? 'STATUS_CADASTRO_APROVADO'
            : value === 'pendent'
            ? 'STATUS_CADASTRO_ADICIONADO'
            : value === 'reproved'
            ? 'STATUS_CADASTRO_REPROVADO'
            : value === 'inserted-files'
            ? 'ARQUIVOS_INSERIDOS'
            : 'status';

        if (filterStatus === 'STATUS_CADASTRO_ADICIONADO') {
          const filteredCustomers = customers.filter(
            customer =>
              customer.status &&
              (customer.status.indexOf(filterStatus) !== -1 ||
                customer.status.indexOf(
                  'STATUS_CADASTRO_ARQUIVOS_INSERIDOS',
                ) !== -1),
          );

          setPage(1);

          const pages: number[] = [];

          for (
            let index = 1;
            index <= Math.ceil(filteredCustomers.length / itemsPerPage);
            index++
          ) {
            pages.push(index);
          }
          setTotalPages(pages);

          setCurrentItems(filteredCustomers.slice(0, itemsPerPage));
        } else if (filterStatus === 'status') {
          setPage(1);

          const pages: number[] = [];

          for (
            let index = 1;
            index <= Math.ceil(customers.length / itemsPerPage);
            index++
          ) {
            pages.push(index);
          }
          setTotalPages(pages);

          setCurrentItems(customers.slice(0, itemsPerPage));
        } else {
          const filteredCustomers = customers.filter(
            customer => customer.status === filterStatus,
          );

          setPage(1);

          const pages: number[] = [];

          for (
            let index = 1;
            index <= Math.ceil(filteredCustomers.length / itemsPerPage);
            index++
          ) {
            pages.push(index);
          }
          setTotalPages(pages);

          setCurrentItems(filteredCustomers.slice(0, itemsPerPage));
        }
      } else {
        const filteredCustomers = customers.filter(
          customer =>
            customer.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
        );

        setCurrentItems(filteredCustomers);
      }
    },
    [customers],
  );

  const getCustomers = useCallback(() => {
    setPage(1);

    const pages: number[] = [];

    for (
      let index = 1;
      index <= Math.ceil(customers.length / itemsPerPage);
      index++
    ) {
      pages.push(index);
    }
    setTotalPages(pages);

    setCurrentItems(customers.slice(0, itemsPerPage));
  }, [customers]);

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getCustomers() : searchCustomers(value);
    },
    [getCustomers, searchCustomers],
  );

  const handleSelectChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      setStatus(value);

      return value === '' || value === 'status'
        ? getCustomers()
        : searchCustomers(value, true);
    },
    [getCustomers, searchCustomers],
  );

  const updateCustomerStatus = useCallback(
    (hubspot_deal_id: string) => {
      const customerIndex = customers.findIndex(
        customer => customer.hubspot_deal_id === hubspot_deal_id,
      );

      const elementImage = document.getElementById(hubspot_deal_id);
      elementImage.setAttribute('src', checkIcon);
      elementImage.setAttribute('class', 'check-icon');

      const updatedCustomers = customers;

      const updateStatusCustomer: Customer = {
        ...customers[customerIndex],
        status: 'ARQUIVOS_INSERIDOS',
      };

      updatedCustomers.splice(customerIndex, 1, updateStatusCustomer);

      setCustomers(updatedCustomers);

      const currentItemIndex = currentItems.findIndex(
        currentItem => currentItem.hubspot_deal_id === hubspot_deal_id,
      );

      const updatedCurrentItems = currentItems;

      const updateStatusCurrentItem: Customer = {
        ...currentItems[currentItemIndex],
        status: 'ARQUIVOS_INSERIDOS',
      };

      updatedCurrentItems.splice(currentItemIndex, 1, updateStatusCurrentItem);

      setCurrentItems(updatedCurrentItems);
    },
    [currentItems, customers],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name: hubspot_deal_id, files } = event.target;

      updateCustomerStatus(hubspot_deal_id);

      const newFiles = [];

      Object.keys(files).forEach(index => {
        const newFile = {
          file: files[index],
          name: files[index].name,
        };

        newFiles.push(newFile);
      });

      const customerFile: CustomerZipFiles = {
        files: newFiles,
        hubspot_deal_id,
      };

      const foundIndex = customerZipFiles.findIndex(
        zipFile => zipFile.hubspot_deal_id === hubspot_deal_id,
      );

      if (foundIndex >= 0) {
        customerZipFiles[foundIndex].files.splice(0, 1);

        customerZipFiles[foundIndex].files = newFiles;

        setCustomerZipFiles(customerZipFiles);
      } else {
        customerZipFiles.push(customerFile);
        setCustomerZipFiles(customerZipFiles);
      }
    },
    [customerZipFiles, updateCustomerStatus],
  );

  const handleGetStatus = useCallback((status_name: string) => {
    if (status_name === 'STATUS_CADASTRO_APROVADO') {
      return (
        <div>
          <span>CADASTRO APROVADO</span>
          <img src={approvedIcon} alt="status icon" />
        </div>
      );
    }

    if (status_name === 'STATUS_CADASTRO_REPROVADO') {
      return (
        <div>
          <span>CADASTRO REPROVADO</span>
          <img src={notApprovedIcon} alt="status icon" />
        </div>
      );
    }

    if (status_name === 'STATUS_CADASTRO_ARQUIVOS_INSERIDOS') {
      return (
        <div>
          <span>AGUARDANDO APROVAÇÃO</span>
          <img src={pendingIcon} alt="status icon" />
        </div>
      );
    }

    return (
      <div>
        <span>ARQUIVO PENDENTE</span>
        <img src={pendingIcon} alt="status icon" />
      </div>
    );
  }, []);

  const handleChangePagination = useCallback(
    async (toPage: number) => {
      let items: Customer[];

      if (status === 'approved') {
        items = customers.filter(
          customer =>
            customer.status && customer.status === 'STATUS_CADASTRO_APROVADO',
        );
      } else if (status === 'reproved') {
        items = customers.filter(
          customer =>
            customer.status && customer.status === 'STATUS_CADASTRO_REPROVADO',
        );
      } else if (status === 'pendent') {
        items = customers.filter(
          customer =>
            customer.status &&
            (customer.status === 'STATUS_CADASTRO_ADICIONADO' ||
              customer.status === 'STATUS_CADASTRO_ARQUIVOS_INSERIDOS'),
        );
      } else if (status === 'inserted-files') {
        items = customers.filter(
          customer =>
            customer.status && customer.status === 'ARQUIVOS_INSERIDOS',
        );
      } else {
        items = customers;
      }

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [customers, page, status, totalPages],
  );

  const handleSendFiles = useCallback(async () => {
    try {
      if (customerZipFiles.length > 0) {
        const data = new FormData();
        const hubspotDealIds = [];

        customerZipFiles.forEach(customerZipFile => {
          hubspotDealIds.push(customerZipFile.hubspot_deal_id);

          customerZipFile.files.forEach(file => {
            data.append('file', file.file);
          });
        });

        const hubspotDealIdsToString = JSON.stringify(hubspotDealIds);

        await api.post(
          `/customers/hubspot_register_solicitation?hubspot_deal_ids=${hubspotDealIdsToString}`,
          data,
        );

        Swal.fire('Arquivo(s) enviado(s) com sucesso!', '', 'success');
      }
    } catch {
      Swal.fire(
        'Não foi possível enviar os documentos. Verifique e tente novamente.',
        '',
        'error',
      );
    }
  }, [customerZipFiles]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Vortx {'>'} </H1Navigation>
        <H1NavigationNow>Cedentes</H1NavigationNow>
      </Navegation>

      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Cedentes</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <SearchContainer>
          <InputSearch
            type="text"
            placeholder="Pesquise por um cedente"
            onChange={handleSearchInputChange}
          />
          <img src={search_icon} alt="Search icon" />
        </SearchContainer>

        <Table>
          <TableHead>
            <THeadLine>
              <th>Nome</th>
              <th>CPF</th>
              <th>Data de nascimento</th>
              <th>
                <select
                  id="status"
                  name="status"
                  value={status}
                  onChange={handleSelectChange}
                >
                  <option value="status">Status</option>
                  <option value="approved">Aprovado</option>
                  <option value="pendent">Pendente</option>
                  <option value="reproved">Reprovado</option>
                  <option value="inserted-files">Arquivos inseridos</option>
                </select>
              </th>
              <th>Arquivo</th>
            </THeadLine>
          </TableHead>

          <Tbody>
            {currentItems &&
              currentItems.map(customer => (
                <TBodyLine key={customer.id} status={customer.status}>
                  <td>{customer.name}</td>
                  <td>{customer.cpf}</td>
                  <td>{customer.birth_date}</td>
                  <td className="status">{handleGetStatus(customer.status)}</td>
                  <td>
                    <label
                      htmlFor={customer.id}
                      className={customer.hubspot_deal_id}
                    >
                      <input
                        multiple
                        type="file"
                        id={customer.id}
                        name={customer.hubspot_deal_id}
                        onChange={handleInputChange}
                      />
                      {customer.status === 'ARQUIVOS_INSERIDOS' ? (
                        <img
                          src={checkIcon}
                          alt="attachment icon"
                          id={customer.hubspot_deal_id}
                          className="check-icon"
                        />
                      ) : (
                        <img
                          id={customer.hubspot_deal_id}
                          src={attachmentIcon}
                          alt="attachment icon"
                        />
                      )}
                    </label>
                  </td>
                </TBodyLine>
              ))}
          </Tbody>
        </Table>

        <PaginationComponent
          totalPages={totalPages}
          page={page}
          handleChangePagination={handleChangePagination}
        />

        <ButtonContainer>
          <ButtonSendFiles type="button" onClick={handleSendFiles}>
            <Icon>
              <AiOutlineCheck />
            </Icon>
            Enviar
          </ButtonSendFiles>
        </ButtonContainer>
      </Body>
    </Container>
  );
}
