import styled from 'styled-components';

export const Table = styled.table`
  width: 28.0625rem;
  margin: 5rem auto 0 auto;

  .tr-body:nth-child(odd) {
    background: #efefef;
  }

  .tr-body:nth-child(even) {
    background: #e5e5e5;
  }
`;

export const TableHead = styled.tr`
  width: 28.0625rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #cfdef3;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface ITableHeaderProps {
  width: string;
}

export const TableHeader = styled.th<ITableHeaderProps>`
  width: ${props => props.width};
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const VerticalSeparator = styled.div`
  width: 0rem;
  height: 2rem;
  border-left: 0.0625rem solid #9d9d9d;
`;

interface ITableRowProps {
  active: boolean;
}

export const TableRow = styled.tr<ITableRowProps>`
  width: 28.0625rem;
  height: 3.5rem;
  margin-top: 0.3125rem;
  border-radius: 0.25rem;

  display: flex;
  justify-content: space-around;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: ${props => (props.active ? '#041524' : '#9D9D9D')};
`;

interface ITableCellProps {
  width: string;
}

export const TableCell = styled.td<ITableCellProps>`
  width: ${props => props.width};
`;

export const EditButton = styled.button`
  width: 9.6875rem;
  height: 2.3125rem;
  margin: 3.5rem auto 0 auto;
  border: none;
  border-radius: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #014077;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-align: center;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    background: #05233e;
  }
`;
