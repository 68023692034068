import styled, { css } from 'styled-components';

interface ISelectHistory {
  selectHistory: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.h1<ISelectHistory>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;

  margin-top: 6.313rem;

  button {
    width: 12rem;
    height: 2.5rem;
    border: none;

    border-radius: 0.375rem;
    font-weight: 400;
    font-size: 1.625rem;
    text-align: center;
  }

  ${props =>
    props.selectHistory === 'Closers'
      ? css`
          button.closer-history {
            background: rgba(207, 222, 243, 0.83);
            color: #05233e;

            hr {
              border-color: #05233e;
            }
          }

          button.hunter-history {
            background: none;
            color: #9d9d9d;
          }
        `
      : css`
          button.hunter-history {
            background: rgba(207, 222, 243, 0.83);
            color: #05233e;

            hr {
              border-color: #05233e;
            }
          }

          button.closer-history {
            background: none;
            color: #9d9d9d;
          }
        `}
`;

export const StatusAndImportContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.313rem 0;
  gap: 0 1.5625rem;
`;

export const OpenModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  width: 12.25rem;
  height: 7rem;

  background: rgba(215, 227, 245, 0.3);
  border-radius: 0.5rem;

  font-size: 0.875rem;
  font-weight: 600;

  span {
    color: #595959;
  }
`;

export const ButtonModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 9.125rem;
  height: 4rem;

  background: #f4f4f5;
  box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;

  button {
    border: none;
    background: none;

    font-size: 0.875rem;
    font-weight: 600;
    color: #1665d8;
  }
`;

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 11.938rem;
  height: 0.0625rem;

  @media print {
    display: none;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SchedulingsStatusContainer = styled.div`
  width: 12.25rem;
  height: 7rem;

  background: rgba(215, 227, 245, 0.3);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const TitleSchedulingStatus = styled.span`
  height: 1.5rem;
  display: grid;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #595959;
`;

export const StatusGraphBox = styled.div`
  width: 9.1875rem;
  height: 3.9375rem;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  background: #f4f4f5;
  box-shadow: 4px 4px 80px rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
`;

export const TextStatusGraphBox = styled.div`
  width: 50%;
  justify-content: center;
  display: grid;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #6d6d6d;

  p {
    font-weight: bold;
    color: #3d3d3d;
  }
`;

export const StatusBox = styled.div`
  width: 9.1875rem;
  height: 3.9375rem;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #f4f4f5;
  box-shadow: 4px 4px 80px rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #6d6d6d;
`;

export const QueuesButton = styled.button`
  width: 6.5625rem;
  height: 2.625rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  background-color: transparent;
  color: #1665d8;
  border: 0;
`;

export const CalendarAndFilterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 0.688rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 3.05rem;
`;

export const DaySelectedModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 1.875rem;
  margin-top: 1rem;
`;

export const DaySelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 1.875rem;
`;
export const PreviousButton = styled.button`
  margin-right: 0.563rem;
  min-width: 1.5rem;
  min-height: 2.2rem;
  border: 0.031rem solid #05233e;
  background: rgba(244, 244, 245, 0, 83);
  border-radius: 0.25rem;
`;

export const ForwardButton = styled.button`
  margin-left: 0.563rem;
  margin-right: 0.563rem;
  min-width: 1.5rem;
  min-height: 2.2rem;
  border: 0.031rem solid #05233e;
  background: rgba(244, 244, 245, 0, 83);
  border-radius: 0.25rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(244, 244, 245, 1);

  div input {
    border: none;
    width: 10rem;
    color: #05233e;
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 0.031rem solid #063966;

  border-radius: 0.25rem;

  width: 12.063rem;

  padding: 0.313rem;

  input {
    background: rgba(244, 244, 245, 1);
    padding-left: 1.196rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const NextButton = styled.button`
  margin-left: 0.563rem;
  min-width: 1.5rem;
  min-height: 2.2rem;
  border: 0.031rem solid #05233e;
  background: rgba(244, 244, 245, 0, 83);
  border-radius: 0.25rem;
`;

export const FilterSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 22.5rem;
  height: 1.875rem;

  padding: 0 0.938rem;

  background: #f4f4f5;
  border-radius: 0.125rem;
  border: 0.031rem solid #05233e;

  span {
    font-size: 0.875rem;
    font-weight: 500;
    color: #05233e;
  }
`;

export const ListClosedAndRequestButton = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.688rem;
`;

export const ApprovalRequestButton = styled.button`
  display: inline-block;
  position: relative;

  width: 13rem;
  height: 2.75rem;

  border-radius: 0.5rem;
  border: 2px solid var(--blue-400);
  background: none;

  font-weight: 600;
  font-size: 0.875rem;
  color: var(--blue-400);

  transition: 0.2s;

  span.notification {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: -0.625rem;
    top: -0.625rem;

    background: var(--blue-400);
    border-radius: 50%;

    font-weight: 600;
    font-size: 0.875rem;
    color: var(--white);

    width: 1.5rem;
    height: 1.5rem;

    transition: 0.2s;
  }

  &:hover {
    border: 2px solid var(--blue-700);
    color: var(--blue-700);

    span.notification {
      background: var(--blue-700);
    }
  }
`;

export const ListClosedDealsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;

  height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--blue-400);

  &:hover {
    color: var(--blue-700);
  }
`;

export const SelectContainer = styled.select`
  width: 9.313rem;
  height: 1.25rem;

  border-radius: 0.25rem;
  background: #d5d5d5;
  border: none;

  padding-left: 0.813rem;

  font-size: 0.875rem;
  font-weight: 600;
  color: #817f7f;

  cursor: pointer;
`;
