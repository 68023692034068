import * as yup from 'yup';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';

import React, { useEffect, useState, useCallback } from 'react';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidateErrors';

import { CreateUserForm } from './CreateUserForm';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Styles/Title';
import { BackButton } from '../../../components/BackButton';

import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../../components/Styles/Navigation';

import {
  Separator,
  DivSeparator,
  Icon,
  ButtonsContainerCreateUser,
  ButtonCreate,
} from './styles';

import { ITeamDTO } from '../dtos/ITeamDTO';
import { IProfileDTO } from '../dtos/IProfileDTO';
import { IUserFormDataDTO } from '../dtos/IUserFormDataDTO';
import { IWorkHourDTO } from '../dtos/IWorkHourDTO';
import { WeekdayWorkingHourDTO } from '../../CloserSettings/dtos/WeekdayWorkingHourDTO';

export const CreateUsers: React.FC = () => {
  const history = useHistory();

  const [teams, setTeams] = useState<ITeamDTO[]>([]);
  const [profiles, setProfiles] = useState<IProfileDTO[]>([]);
  const [formData, setFormData] = useState<IUserFormDataDTO>(
    {} as IUserFormDataDTO,
  );
  const [workHourArray, setWorkHourArray] = useState<IWorkHourDTO>(
    {} as IWorkHourDTO,
  );

  const [closerProfileId, setCloserProfileId] = useState('');
  const [hunterProfileId, setHunterProfileId] = useState('');

  useEffect(() => {
    api.get('/users/hunter-teams').then(response => {
      setTeams(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/users/profiles/').then(response => {
      setProfiles(response.data);

      const closerProfile = response.data.find(
        (profile: { name: string }) => profile.name === 'Closers',
      );

      const hunterProfile = response.data.find(
        (profile: { name: string }) =>
          profile.name === 'Hunter New' ||
          profile.name === 'Gestores Hunter New',
      );

      setCloserProfileId(closerProfile.id);
      setHunterProfileId(hunterProfile.id);
    });
  }, []);

  const handleCreateUserSuccess = useCallback(() => {
    toast.success('Usuário criado com sucesso');
  }, []);

  const handleCreateUserError = useCallback((message: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  const handleCreateUser = useCallback(async () => {
    try {
      const createUserFormSchema = yup.object().shape({
        name: yup.string().required('Nome obrigatório!'),
        email: yup
          .string()
          .required('E-mail obrigatório!')
          .email('E-mail inválido!'),
        password: yup.string().required('Senha obrigatória!'),
        password_confirm: yup
          .string()
          .oneOf([null, yup.ref('password')], 'As senhas não coincidem!'),
        profile_id: yup.string().required('Perfil obrigatório!'),
      });

      await createUserFormSchema.validate(formData, {
        abortEarly: false,
      });

      const weekdayWorkingHourArray: WeekdayWorkingHourDTO[] = [
        {
          work_day: 1,
          time_init: workHourArray.work_hour_init_1,
          time_end: workHourArray.work_hour_end_1,
        },
        {
          work_day: 2,
          time_init: workHourArray.work_hour_init_2,
          time_end: workHourArray.work_hour_end_2,
        },
        {
          work_day: 3,
          time_init: workHourArray.work_hour_init_3,
          time_end: workHourArray.work_hour_end_3,
        },
        {
          work_day: 4,
          time_init: workHourArray.work_hour_init_4,
          time_end: workHourArray.work_hour_end_4,
        },
        {
          work_day: 5,
          time_init: workHourArray.work_hour_init_5,
          time_end: workHourArray.work_hour_end_5,
        },
      ];

      await api.post('/users', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        profile_id: formData.profile_id,
        team_id: formData.team_id,
        ramal: formData.ramal,
        work_hour: weekdayWorkingHourArray,
      });

      setFormData({} as IUserFormDataDTO);

      handleCreateUserSuccess();
      history.push('/administration/users');
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = getValidationErrors(error);

        handleCreateUserError(errors[Object.keys(errors)[0]]);

        return;
      }
      handleCreateUserError(error.response.data.message);
    }
  }, [
    formData,
    handleCreateUserError,
    handleCreateUserSuccess,
    history,
    workHourArray,
  ]);

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Navegation>
        <H1Navigation>
          Administração {'>'} Usuários {'>'}
        </H1Navigation>
        <H1NavigationNow>Novo usuário</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Novo usuário</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <BackButton
          link={{
            pathname: '/administration/users',
          }}
          isNotMargin
        />

        <CreateUserForm
          profiles={profiles}
          teams={teams}
          formData={formData}
          setFormData={setFormData}
          setWorkHourArray={setWorkHourArray}
          workHourArray={workHourArray}
          closerProfileId={closerProfileId}
          hunterProfileId={hunterProfileId}
        />

        <ButtonsContainerCreateUser>
          <ButtonCreate type="button" onClick={handleCreateUser}>
            <Icon>
              <AiOutlinePlus />
            </Icon>
            Criar usuário
          </ButtonCreate>
        </ButtonsContainerCreateUser>
      </Body>
    </Container>
  );
};
