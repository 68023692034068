import { format, isBefore } from 'date-fns';
import { useCallback, useState } from 'react';
import { useHunterSchedule } from '../../../../../hooks/useHunterSchedule';

import { formatAmount } from '../../../../../utils/formatAmount';
import { IHunterActivity } from '../../../dtos/IDayHoursDTO';
import { ActivityHunterModal } from '../../../Hunter/ActivityHunterModal';

import {
  TableContainer,
  HeadContainer,
  RowBody,
  RowContainer,
  ColumnHeadContainer,
  BodyContainer,
  ColumnBodyContainer,
} from './styles';

export function VisualizationList(): JSX.Element {
  const { dayHours } = useHunterSchedule();

  const [openActivityHunterModal, setOpenActivityHunterModal] = useState(false);
  const [activityHunter, setActivityHunter] = useState<IHunterActivity>(
    {} as IHunterActivity,
  );

  const handleOpenActivityHunterModal = useCallback(
    (activity: IHunterActivity) => {
      setActivityHunter(activity);
      setOpenActivityHunterModal(true);
    },
    [],
  );

  const handleCloseActivityHunterModal = useCallback(() => {
    setOpenActivityHunterModal(false);
  }, []);

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  return (
    <>
      <TableContainer>
        <HeadContainer>
          <RowContainer className="header">
            <ColumnHeadContainer>HORÁRIO</ColumnHeadContainer>
            <ColumnHeadContainer>ATIVIDADE</ColumnHeadContainer>
            <ColumnHeadContainer>CREDOR</ColumnHeadContainer>
            <ColumnHeadContainer>VALOR</ColumnHeadContainer>
            <ColumnHeadContainer>ETAPA</ColumnHeadContainer>
            <ColumnHeadContainer>STATUS</ColumnHeadContainer>
          </RowContainer>
        </HeadContainer>

        <BodyContainer>
          {dayHours.length > 0 &&
            dayHours.map(day_hour => {
              return day_hour.activities.map(activity => {
                return (
                  !activity.deleted && (
                    <RowBody
                      key={activity.id}
                      className="row-props-body"
                      isBefore={verifyIsBeforeNow(activity.timestamp)}
                      statusTask={activity.status === 'Concluída'}
                      try_times={activity.try_times}
                      onClick={() => handleOpenActivityHunterModal(activity)}
                    >
                      <ColumnBodyContainer className="time">
                        {format(new Date(activity.timestamp), 'HH:mm')}
                      </ColumnBodyContainer>

                      <ColumnBodyContainer>{activity.name}</ColumnBodyContainer>

                      <ColumnBodyContainer>
                        {activity.dealname}
                      </ColumnBodyContainer>

                      <ColumnBodyContainer>
                        {formatAmount(Number(activity.amount))}
                      </ColumnBodyContainer>

                      <ColumnBodyContainer>
                        {activity.dealstage}
                      </ColumnBodyContainer>

                      <ColumnBodyContainer>
                        {activity.status}
                      </ColumnBodyContainer>
                    </RowBody>
                  )
                );
              });
            })}
        </BodyContainer>
      </TableContainer>

      {activityHunter && (
        <ActivityHunterModal
          isOpen={openActivityHunterModal}
          onRequestClose={handleCloseActivityHunterModal}
          activity={activityHunter}
        />
      )}
    </>
  );
}
