import { format } from 'date-fns';
import { useCallback } from 'react';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';

import {
  TableContainer,
  RowContainerProps,
  RowContainer,
  BodyContainer,
  ColumnBodyContainer,
} from './styles';

export interface ICadence {
  id: string;
  amount: string;
  dealname: string;
  deal_stage: string;
  ticket_create_date: string;
  days_since_connection: string;
  priority: {
    label: string;
    note: number;
  };
  hubspot_deal_id: string;
}

interface IListingCadencesCloserContainer {
  userId: string;
  cadences: ICadence[];
  setCadences: React.Dispatch<React.SetStateAction<any[]>>;
}

export const ListingCadencesCloserContainer: React.FC<IListingCadencesCloserContainer> =
  ({ userId, cadences, setCadences }) => {
    const { user } = useAuth();
    const updateCadencePriority = useCallback(
      async (cadenceId: string, priority: string) => {
        const index = cadences.findIndex(cadence => cadence.id === cadenceId);

        const note = priority === 'Alta' ? 1 : priority === 'Média' ? 2 : 3;

        cadences.splice(index, 1, {
          ...cadences[index],
          priority: {
            label: priority,
            note,
          },
        });

        const response = await api.post<ICadence[]>(
          '/schedule/update-cadences-cases',
          {
            userId,
            cadences,
          },
        );
        setCadences(response.data);
      },

      [cadences, userId, setCadences],
    );

    const handleOpenHubspotLink = useCallback((hubspot_deal_id: string) => {
      window.open(
        `https://app.hubspot.com/contacts/7857327/deal/${hubspot_deal_id}`,
      );
    }, []);

    return (
      <TableContainer>
        <BodyContainer>
          {cadences.map(cadence => (
            <RowContainerProps
              className="row-props-body"
              priority={cadence.priority.label}
            >
              <RowContainer className="body" key={cadence.id}>
                <ColumnBodyContainer
                  onClick={() => {
                    handleOpenHubspotLink(cadence.hubspot_deal_id);
                  }}
                >
                  <p>Data de conexão:</p>
                  {format(new Date(cadence.ticket_create_date), 'dd/MM/yyyy')}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => {
                    handleOpenHubspotLink(cadence.hubspot_deal_id);
                  }}
                >
                  <p>Dias passados:</p>
                  {cadence.days_since_connection}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => {
                    handleOpenHubspotLink(cadence.hubspot_deal_id);
                  }}
                >
                  <p>Valor:</p>

                  {cadence.amount}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => {
                    handleOpenHubspotLink(cadence.hubspot_deal_id);
                  }}
                >
                  <p>Nome:</p>

                  {cadence.dealname}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => {
                    handleOpenHubspotLink(cadence.hubspot_deal_id);
                  }}
                >
                  <p>Etapa:</p>

                  {cadence.deal_stage}
                </ColumnBodyContainer>

                <ColumnBodyContainer>
                  <p>Prioridade:</p>

                  {user.profile !== 'Closers' ? (
                    cadence.priority.label
                  ) : (
                    <select
                      name="priority"
                      onChange={event =>
                        updateCadencePriority(cadence.id, event.target.value)
                      }
                    >
                      <option value={cadence.priority.label}>
                        {cadence.priority.label}
                      </option>
                      <option value="Alta">Alta</option>
                      <option value="Média">Média</option>
                      <option value="Baixa">Baixa</option>
                    </select>
                  )}
                </ColumnBodyContainer>
              </RowContainer>
            </RowContainerProps>
          ))}
        </BodyContainer>
      </TableContainer>
    );
  };
