import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/pxToRem';

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;

export const Label = styled.label`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  letter-spacing: -1.5%;
  color: #05233e;
  margin-right: 10px;
`;

export const Input = styled.input`
  width: 18.125rem;
  height: ${pxToRem(18)};
  background-color: #d9d9d9;
  border: none;
  border-radius: ${pxToRem(5)};
  font-family: 'Poppins';
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};
  letter-spacing: -1.5%;
  color: #6d6d6d;
  text-indent: ${pxToRem(8)};
`;
