import styled from 'styled-components';

export const ModalSchedule = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  content: {
    width: '35rem',
    height: '28rem',
    margin: 'auto',
    padding: '0',
    border: 'none',
    background: '#e5e5e5',
  },
};

export const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  margin: auto;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 2.5rem);

  margin: auto;

  gap: 0.75rem;
  background: #fff;
`;

export const ContainerClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.5rem;

  background: #2d80c9;
  padding: 0.5rem;

  color: #fff;
`;

export const Image = styled.img`
  margin-top: 0.5rem;
  padding: 0;
`;

export const Button = styled.button`
  background: transparent;
  border: none;
`;

export const ContainerText = styled.div`
  padding: 0.75rem;
  display: flex;
  align-items: flex-start;
  background: #e5e5e5;

  width: 90%;

  border-radius: 0.25rem;
`;

export const Text = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  min-width: 10rem;
  max-width: 20rem;

  color: #05233e;

  margin-right: 0.5rem;
`;

export const ContentCreditor = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;

  color: #05233e;
`;

export const Link = styled.a`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;

  word-break: break-all;
  text-decoration: underline;

  color: #05233e;
`;
