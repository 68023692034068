import { InputContainer, Label, Select } from '../../styles';

interface ISelectSLAProps {
  title: string;
  name: string;
  value: string;
  handleChange: (e: any) => void;
  options: { label: string; amount: number }[];
}

export function SelectSLA({
  title,
  name,
  value,
  handleChange,
  options,
}: ISelectSLAProps): JSX.Element {
  return (
    <InputContainer>
      <Label>{title}</Label>
      <Select name={name} defaultValue="" value={value} onChange={handleChange}>
        <option value="" disabled>
          Selecione uma opção
        </option>
        {options.map(Option => (
          <option key={Option.label} value={Option.label}>
            {Option.label}
          </option>
        ))}
      </Select>
    </InputContainer>
  );
}
