import styled, { css } from 'styled-components';

export const TitleBackButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  padding-right: 7%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 7%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 0.8125rem;

  cursor: pointer;

  color: #1665d8;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const FilterByTeam = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 2.5rem;
`;

interface ButtonTeamProps {
  isSelected: boolean;
}

export const ButtonTeam = styled.button<ButtonTeamProps>`
  width: 12rem;
  height: 2.5rem;

  border: none;
  border-radius: 0.25rem;

  font-weight: 400;
  font-size: 1.5rem;

  transition: background-color 0.2s;

  ${props =>
    props.isSelected
      ? css`
          color: var(--blue-700);
          background: var(--blue-100);

          &:hover {
            color: var(--gray-700);
            background: var(--gray-300);
          }
        `
      : css`
          color: var(--gray-700);
          background: var(--gray-300);

          &:hover {
            color: var(--blue-700);
            background: var(--blue-100);
          }
        `}
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 2rem;
`;

export const FilterContent = styled.div`
  width: 10.75rem;
  height: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--gray-200);
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(5, 35, 62, 0.25);
  border-radius: 0.25rem;

  margin-top: 1.125rem;

  span {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--blue-800);
    line-height: 1.25rem;
  }
`;

export const Select = styled.select`
  width: 9.75rem;
  height: 1.125rem;

  background: var(--white);
  border-radius: 0.25rem;
  cursor: pointer;
  border: none;

  font-weight: 400;
  font-size: 0.75;
  color: var(--blue-800);
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-top: 0.875rem;
`;

export const LineSeparator = styled.div`
  border-right: 0.063rem solid var(--gray-500);
  height: 3.2rem;
`;

interface RowTableProps {
  hasActedBy: boolean;
}

export const RowTable = styled.div<RowTableProps>`
  cursor: pointer;
  margin-bottom: 0.9375rem;

  border-radius: 0.25rem;
  height: 3.6rem;
  width: 100%;

  display: flex;
  align-items: center;

  .hunter {
    min-width: 17.5%;
  }

  .stage {
    min-width: 15%;
  }

  .hubspot {
    min-width: 25%;
  }

  .acted_by {
    min-width: 17.5%;
  }

  ${props =>
    props.hasActedBy
      ? css`
          background: rgba(13, 144, 26, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--green-400);
        `
      : css`
          background: rgba(1, 64, 119, 0.15);
          box-shadow: inset 0.188rem 0 0 var(--blue-500);
        `}
`;

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 10rem;
`;

export const Legend = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--gray-700);
`;

export const Info = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: var(--blue-800);

  span {
    display: none;
  }
`;

export const HubspotLink = styled.a`
  color: #368eda;
  font-size: 1rem;
`;
