import styled, { css } from 'styled-components';

interface PositionInputProps {
  verifyCloserQueue: boolean;
}

interface ButtonsProps {
  noClickable: boolean;
}

interface IPops {
  show: boolean;
}

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TableTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 0.225rem;
  align-items: center;
`;

export const TableContainer = styled.div`
  /* width: 68.75rem;

  overflow-x: auto;

  @media (min-width: 1440px) {
    width: 100%;
  } */
  border: 0.125rem solid #000;
  border-radius: 0.5rem;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const Table = styled.table`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1440px) {
    width: fit-content;
  }
`;

export const Thead = styled.thead`
  width: 100%;

  border-radius: 0.25rem;
  display: flex;
  align-items: center;

  .head-row {
    height: 2.5rem;
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  border-radius: 0.25rem;

  /* .buttons-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.438rem;
  } */
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.563rem;

  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-700);
`;

export const Tbody = styled.tbody`
  width: 100%;

  border-radius: 0.25rem;
  display: flex;
  align-items: center;

  .body-row {
    height: 1.313rem;
  }
`;

export const BodyCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.563rem;

  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-700);
`;

export const PositionInput = styled.input<PositionInputProps>`
  width: 90%;
  height: 1.3rem;

  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;

  ${props =>
    props.verifyCloserQueue
      ? css`
          border-radius: 0.25rem;
          border: 1px solid var(--blue-700);
        `
      : css`
          border: none;
        `}
`;

export const ButtonDelete = styled.button<ButtonsProps>`
  height: 1.125rem;
  width: 1.125rem;

  img {
    height: 1.125rem;
    width: 1.125rem;
  }

  border: none;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const ButtonEdit = styled.button<ButtonsProps>`
  height: 1.125rem;
  width: 1.125rem;

  background: var(--blue-600);
  border: none;
  border-radius: 0.313rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const ButtonSave = styled.button`
  img {
    height: 1.125rem;
    width: 1.125rem;
  }

  border: none;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
