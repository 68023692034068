import styled, { css } from 'styled-components';

interface IMessageError {
  passwordError?: boolean;
  confirmPassword?: boolean;
  passwordLength?: boolean;
  containSpecial?: boolean;
  containUpperCase?: boolean;
  newPassword?: boolean;
}

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45%;

  margin-top: 2.5rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.9rem;

    color: #1665d8;
  }
`;

export const IconBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const ContainerProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const ContainerDados = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.875rem;

  img {
    height: 9.688rem;
    width: 9.688rem;
    border-radius: 5rem;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  }
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInput = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: -2.6rem;

  img {
    width: 1.892rem;
    height: 1.892rem;

    cursor: pointer;
  }
`;

export const InputAvatar = styled.input`
  width: 1.892rem;
  height: 1.892rem;
  position: absolute;
  cursor: pointer;

  display: none;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 8rem;
`;

export const H1 = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;

  color: #05233e;
`;

export const DivInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  border-left: 1px solid #9d9d9d;
  padding-left: 0.375rem;
  gap: 0.375rem;
`;

export const Separator = styled.div`
  border: 1px solid #9d9d9d;
  height: 5.313rem;
`;

export const DivH2 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const H2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  font-family: Poppins;
  font-weight: 300;
  font-size: 0.875rem;
  color: #05233e;

  img {
    width: 0.938rem;
    height: 0.938rem;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  color: #51a7fc;
`;

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 2rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const TitleHeader = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 21.125rem;
  height: 2.438rem;

  font-family: Poppins;
  font-weight: normal;
  font-size: 1.125rem;
  border-radius: 0.25rem;
  background: #cfdef3;
`;

export const InputContainer = styled.div<IMessageError>`
  display: flex;
  flex-direction: column;

  margin-bottom: 0.625rem;

  .message-error {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.7rem;
    color: #eb5757;
  }

  ${props =>
    props.passwordError
      ? css`
          #password-message-error {
            display: visible;
          }

          #now-password-container {
            border: 1px solid #eb5757;

            svg {
              color: #eb5757;
            }
          }
        `
      : css`
          #password-message-error {
            display: none;
          }
        `}

  ${props =>
    !props.confirmPassword
      ? css`
          #confirm-message-error {
            display: visible;
          }

          #new-password-confirm {
            border: 1px solid #eb5757;

            svg {
              color: #eb5757;
            }
          }
        `
      : css`
          #confirm-message-error {
            display: none;
          }
        `}

  ${props =>
    props.newPassword
      ? css`
          #password-message-length,
          #password-message-special,
          #password-message-upper {
            display: visible;
          }
        `
      : css`
          #password-message-length,
          #password-message-special,
          #password-message-upper {
            display: none;
          }
        `}

  ${props =>
    props.passwordLength
      ? css`
          #password-message-length {
            color: #063966;
          }
        `
      : css`
          #password-message-length {
            color: #eb5757;
          }
        `}

  ${props =>
    props.containSpecial
      ? css`
          #password-message-special {
            color: #063966;
          }
        `
      : css`
          #password-message-special {
            color: #eb5757;
          }
        `}

  ${props =>
    props.containUpperCase
      ? css`
          #password-message-upper {
            color: #063966;
          }
        `
      : css`
          #password-message-upper {
            color: #eb5757;
          }
        `}
`;

export const ContainerIcon = styled.div<IMessageError>`
  display: flex;
  align-items: center;
  flex-direction: row;

  background: #ffffff;

  border: 1px solid #51a7fc;
  box-shadow: 0 0.188rem 0.625rem rgba(114, 30, 255, 0.1);
  border-radius: 0.375rem;

  width: 21.125rem;
  padding: 0 0.5rem;

  transition: border 0.2s;

  &:focus {
    border: 1px solid #2e9afe;
  }

  svg {
    color: #51a7fc;
  }

  button {
    background: none;
    border: none;
    height: 100%;

    display: flex;
    align-items: center;
  }
`;

export const Input = styled.input`
  width: 21.125rem;
  height: 2.25rem;

  border: none;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  color: #041524;

  padding-left: 0.5rem;

  background: transparent;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;

  color: #595959;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
`;

export const ButtonSave = styled.button`
  background: #063966;
  border-radius: 0.5rem;
  border: none;

  width: 14.063rem;
  height: 2.25rem;

  color: white;

  :disabled {
    background: rgba(6, 57, 102, 0.72);
    cursor: not-allowed;
  }
`;
