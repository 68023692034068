import { Toaster } from 'react-hot-toast';
import { useState } from 'react';

import { Container } from '../../../components/Container';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Styles/Title';
import {
  ContainerPerformance,
  DivSeparator,
  Separator,
  DateContainer,
  ContainerTableAndInputs,
} from './styles';
import { InputInitialAndFinalDayPicker } from '../components/InputInitialAndFinalDayPicker';

import { TablePerformance } from './TablePerformance';
import { InputsPerformance } from './InputsPerformance';
import { Body } from '../../../components/Styles/Body';

export interface IPerfomance {
  hunter: string;
  user_id: string;
  email: string;
  raw: number;
  predicted: number;
  liquid: number;
  proposal: number;
  proposalLiquid: number;
  dateGain: number;
  batonPass: number;
  liquidRaw: number;
}

export function Performance(): JSX.Element {
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());
  const [performances, setPerformances] = useState<IPerfomance[]>([]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Conexão {'>'} </H1Navigation>
        <H1NavigationNow>Hunters</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Hunters</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <ContainerPerformance>
          <div>
            <DateContainer>
              <InputInitialAndFinalDayPicker
                initialDate={from}
                finalDate={to}
                dayClickFrom={value => setFrom(value)}
                dayClickTo={value => setTo(value)}
              />
            </DateContainer>

            <ContainerTableAndInputs>
              <TablePerformance performances={performances} />

              <InputsPerformance
                to={to}
                from={from}
                setPerformances={setPerformances}
              />
            </ContainerTableAndInputs>
          </div>
        </ContainerPerformance>
      </Body>
    </Container>
  );
}
