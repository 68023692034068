import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import toast from 'react-hot-toast';
import { FormHandles } from '@unform/core';
import { useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import Input from '../../../components/Input';
import Button from '../../../components/ButtonLogin';
import getValidationErrors from '../../../utils/getValidateErrors';

import logo from '../../../assets/icons/logo.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/icons/password.svg';
import { ReactComponent as PasswordErrorIcon } from '../../../assets/icons/password_vermelho.svg';

import {
  Container,
  Content,
  LoginContainer,
  Logo,
  ContainerResetPassword,
  Title,
  Separator,
  Label,
  ButtonContainer,
  Back,
} from './styles';

interface IResetPasswordFormData {
  new_password: string;
  password_confirmation: string;
}

interface IUserLocation {
  old_password?: string;
}

export const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location = useLocation();
  const user = location.state as IUserLocation;
  const queryParam = new URLSearchParams(window.location.search);

  const token = queryParam.get('token');

  const resetSuccess = useCallback(() => {
    toast.success('A sua senha foi alterada com sucesso!');
  }, []);

  const resetError = useCallback(() => {
    Swal.fire('Erro', 'Volte para a tela de login e tente novamente.', 'error');
  }, []);

  const handleSubmit = useCallback(
    async (data: IResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          new_password: Yup.string()
            .required('Senha obrigatória')
            .matches(
              /^(?=.*[A-Z])(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])[A-Za-z\d`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]{8,}$/,
              'Sua senha precisa de no mínimo 8 caracteres, 1 letra maiúscula e 1 caractere especial.',
            )
            .oneOf(
              [Yup.ref('password_confirmation'), undefined],
              'Senhas não correspondem',
            ),
          password_confirmation: Yup.string()
            .required('Senha obrigatória')
            .matches(
              /^(?=.*[A-Z])(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])[A-Za-z\d`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]{8,}$/,
              'Sua senha precisa de no mínimo 8 caracteres, 1 letra maiúscula e 1 caractere especial.',
            )
            .oneOf(
              [Yup.ref('new_password'), undefined],
              'Senhas não correspondem',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        if (token === null) {
          await api.put('/users', {
            password: data.new_password,
            old_password: user.old_password,
            token,
          });
        } else {
          await api.patch('/forgot_password/reset-forgot-password', {
            password: data.new_password,
            token,
          });
        }

        resetSuccess();

        history.push('/');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else {
          resetError();
        }
      }
    },
    [history, resetError, resetSuccess, token, user],
  );

  return (
    <Container>
      <Content>
        <LoginContainer>
          <Logo src={logo} />
          <ContainerResetPassword>
            {token === null ? (
              <Title>Primeiro acesso</Title>
            ) : (
              <Title>Nova senha</Title>
            )}
            <Separator />
          </ContainerResetPassword>

          <p id="password_requirements">
            Sua senha precisa de no mínimo 8 caracteres, 1 letra maiúscula e 1
            caractere especial.
          </p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Label htmlFor="new_password">Nova senha:</Label>
            <Input
              id="new_password"
              name="new_password"
              type="password"
              icon={PasswordIcon}
              iconError={PasswordErrorIcon}
            />

            <Label htmlFor="password_confirmation">
              Confirme a nova senha:
            </Label>
            <Input
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              icon={PasswordIcon}
              iconError={PasswordErrorIcon}
            />

            <ButtonContainer>
              <Button type="submit">ALTERAR SENHA</Button>
            </ButtonContainer>
          </Form>

          <Back>
            <Link to="/">Voltar</Link>
          </Back>
        </LoginContainer>
      </Content>
    </Container>
  );
};
