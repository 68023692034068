import styled from 'styled-components';

export const TableContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: center;
`;

export const Table = styled.table`
  width: 25.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1440px) {
    width: fit-content;
  }
`;

export const Thead = styled.thead`
  width: 100%;
  background: var(--blue-100);

  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;

  .head-row {
    height: 3.125rem;
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.25rem;
  font-weight: 600;

  .stick-cell {
    width: 5.185rem;
  }

  .value-cell {
    width: 7.3125rem;
  }

  .percentage-cell {
    width: 5.625rem;
  }

  .status-cell {
    width: 7.5rem;
  }

  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
    border-bottom: solid 2px var(--blue-100);
  }
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  height: 1.563rem;

  font-weight: 600;
  font-size: 0.8125rem;
  text-align: center;

  color: var(--blue-800);

  & + th {
    border-left: 0.063rem solid var(--gray-500);
  }
`;

export const Tbody = styled.tbody`
  width: 100%;

  overflow: auto;
  max-height: 19.09375rem;

  tr:nth-child(odd) {
    background: var(--gray-100);
  }

  tr:nth-child(even) {
    background: var(--gray-50);
  }

  .body-row {
    border-left: solid 2px var(--blue-100);
    border-right: solid 2px var(--blue-100);
  }
`;

export const BodyCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: 0.6875rem;
  font-weight: 600;
  text-align: center;
`;

export const StickInfoContainer = styled.div`
  display: flex;

  justify-content: flex-start;

  width: 100%;

  margin-top: 0.5rem;

  font-size: 0.6875rem;
  font-weight: 600;
`;

export const StickInfoItem = styled.div`
  margin-right: 1.5rem;
`;
