import { useState } from 'react';

import { TitleSla } from '../../components/TitleSla';
import { Body } from '../../components/Styles/Body';
import { Container } from '../../components/Container';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';

import { TablePanelSla } from './TablePanelSla';

import { DivContent, SituationButton } from './styles';

export function PanelSla(): JSX.Element {
  const [statePanel, setStatePanel] = useState<string>('Inicial');

  return (
    <>
      {statePanel && statePanel !== 'Inicial' ? (
        <TablePanelSla title_page={statePanel} setStatePanel={setStatePanel} />
      ) : (
        <Container>
          <Navegation>
            <H1Navigation>Negociação {'>'} </H1Navigation>
            <H1NavigationNow>Painel SLA</H1NavigationNow>
          </Navegation>

          <Body>
            <TitleSla
              name="Painel SLA"
              nameStrong=""
              isShared={false}
              separatorWidth={0}
            />

            <DivContent>
              <SituationButton onClick={() => setStatePanel('Conectado')}>
                CONECTADO
              </SituationButton>

              <SituationButton onClick={() => setStatePanel('Proposta')}>
                PROPOSTA
              </SituationButton>

              <SituationButton onClick={() => setStatePanel('Negociação')}>
                NEGOCIAÇÃO
              </SituationButton>

              <SituationButton onClick={() => setStatePanel('Fechado')}>
                FECHADO
              </SituationButton>
            </DivContent>
          </Body>
        </Container>
      )}
    </>
  );
}
