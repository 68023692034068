import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selectedValue?: boolean;
  scheduleHour?: boolean;
  weekdaySchedule?: boolean;
  createScheduleOption?: boolean;
  createActivity?: boolean;
  selectCloser?: boolean;
  statusDisponibility?: boolean;
  fillHubspot?: boolean;
  transfer?: boolean;
  managerSchedulingContact?: boolean;
}

const ScheduleOptionsButton: React.FC<ButtonProps> = ({
  children,
  selectedValue,
  scheduleHour,
  weekdaySchedule,
  createScheduleOption,
  createActivity,
  selectCloser,
  statusDisponibility,
  fillHubspot,
  transfer,
  managerSchedulingContact,
  ...rest
}) => {
  return (
    <Container
      type="button"
      selectedValue={selectedValue}
      scheduleHour={scheduleHour}
      weekdaySchedule={weekdaySchedule}
      createScheduleOption={createScheduleOption}
      createActivity={createActivity}
      selectCloser={selectCloser}
      statusDisponibility={statusDisponibility}
      fillHubspot={fillHubspot}
      transfer={transfer}
      managerSchedulingContact={managerSchedulingContact}
      {...rest}
    >
      {children}
    </Container>
  );
};

export { ScheduleOptionsButton };
