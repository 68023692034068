import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface Customerstatus {
  status?: string;
}

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const SearchContainer = styled.label`
  width: 26rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: var(--gray-200);
  border-radius: 1.875rem;

  img {
    width: 1rem;
    height: 1rem;

    margin-right: 0.938rem;
  }
`;

export const InputSearch = styled.input`
  width: 24rem;
  height: 100%;

  background: var(--gray-200);

  border-radius: 1.875rem 0 0 1.875rem;
  padding-left: 1rem;
  border: none;

  font-size: 0.938rem;
  color: var(--blue-800);

  &::placeholder {
    color: var(--gray-400);
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableHead = styled.thead`
  width: 100%;
`;

export const THeadLine = styled.tr`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-100) 83%;

  border-radius: 0.25rem;
  margin-bottom: 0.688rem;

  height: 2.5rem;

  th:nth-child(n):not(:last-child) {
    width: 21.25%;
  }

  th {
    width: 15%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    color: var(--blue-700);

    border-right: 0.063rem solid rgba(6, 57, 102, 0.64);

    &:nth-child(5) {
      border-right: none;
    }

    select {
      width: 90%;
      border: none;
      background: none;

      cursor: pointer;

      font-weight: 600;
      font-size: 0.875rem;
      text-align: center;
      color: var(--blue-700);
      font-family: Poppins;
    }
  }
`;

export const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  tr:nth-child(even) {
    background: var(--gray-100);
  }

  tr:nth-child(odd) {
    background: var(--gray-50);
  }
`;

export const TBodyLine = styled.tr<Customerstatus>`
  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
  height: 3.5rem;

  td:nth-child(n):not(:last-child) {
    width: 21.25%;
  }

  .status {
    gap: 0.5rem;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      padding: 0 0.5rem;

      span {
        width: 90%;
        text-align: center;
      }
    }
  }

  td {
    width: 15%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 300;
    font-size: 0.875rem;
    text-align: center;

    color: var(--blue-700);

    .check-icon {
      width: 1rem;
      height: 1rem;
    }
  }

  input {
    display: none;
  }

  label {
    width: 1.375rem;
    height: 1.375rem;
  }

  ${props =>
    props.status === 'STATUS_CADASTRO_APROVADO' ||
    props.status === 'STATUS_CADASTRO_ARQUIVOS_INSERIDOS'
      ? css`
          label {
            opacity: 0.5;
            pointer-events: none;
          }
        `
      : css`
          label {
            cursor: pointer;
          }
        `}
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  margin: 2rem 0;
`;

export const ButtonSendFiles = styled.button`
  width: 9.75rem;
  height: 2.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;
  border: none;
  border-radius: 0.25rem;

  color: var(--white);
  font-weight: 600;
  font-size: 0.9rem;

  background: var(--blue-600);
  transition: background-color 0.2ms;

  &:hover {
    background: ${shade(0.3, '#063966')};
  }
`;
