import styled from 'styled-components';

export const PriorityCloserModal = {
  content: {
    width: '42.25rem',
    minHeight: '13.75rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0 2rem 2rem 2rem',
    borderRadius: '0.625rem',
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  },
};

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Poppins;

  span {
    font-weight: 600;
    font-size: 1rem;
    color: #05233e;
  }

  button {
    width: 9.75rem;
    height: 2.25rem;
    background: #1665d8;
    align-self: center;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-self: flex-end;

    border: none;
    border-radius: 0.5rem;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;

    transition: background-color 0.2s;

    img {
      margin: 0 1.063rem 0 1.25rem;
    }

    span {
      background: none;
      color: #fff;
    }
  }

  button:hover {
    background: #144792;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 3.5rem;
  width: 42.2rem;
  background: #cfdef3;

  text-align: center;
  margin-bottom: 1.125rem;

  h1 {
    font-family: Poppins;
    font-weight: 400;
    font-size: 1.5rem;
    color: #05233e;
  }
`;

export const QueuePriorityContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.25rem;

  span {
    width: 33.85rem;
    height: 1.875rem;
    background: #efefef;
    border-radius: 0.25rem;
    padding-left: 0.375rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  span#priority-queue {
    background: none;
    justify-content: flex-start;
    margin-bottom: 0.625rem;
    font-weight: 600;
    font-size: 1rem;
    color: #05233e;
    padding: 0;
  }
`;

export const LabelInputContainer = styled.div`
  display: flex;
  margin-bottom: 0.125rem;
  justify-content: space-between;

  p {
    width: 34.25rem;

    background: #efefef;
    border-radius: 0.375rem;

    font-family: Poppins;
    font-weight: 500;
    font-size: 1rem;
    color: #3d3d3d;

    margin-right: 0.37rem;
    padding-left: 0.375rem;

    display: flex;
    align-items: center;
  }

  input {
    font-size: 0.75rem;
    color: #3d3d3d;
    text-align: left;
    padding-left: 0.375rem;

    border: none;
    width: 4rem;
    height: 1.875rem;

    background: #efefef;
    border: 0.5px solid #063966;
    border-radius: 0.25rem;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      opacity: 1;
    }
  }
`;
