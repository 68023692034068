import { ReactComponent as BoxTimeIcon } from '../../../../assets/icons/box_time.svg';
import { ReactComponent as ScheduleIcon } from '../../../../assets/icons/calendar_blue.svg';
import { IActivityCSDTO } from '../dtos/IActivityCSDTO';

import { ManagerVisualizationList } from './ManagerVisualizationList';

import {
  Container,
  DateContainer,
  LineContent,
  ScheduleLine,
  WatchIconContent,
} from './styles';

interface ScheduleManagerProps {
  activities: IActivityCSDTO[];
}

export function ScheduleManager({
  activities,
}: ScheduleManagerProps): JSX.Element {
  function getLocaleStringMonth(): string {
    return (
      new Date()
        .toLocaleString('default', { month: 'long' })
        .charAt(0)
        .toUpperCase() +
      new Date().toLocaleString('default', { month: 'long' }).slice(1)
    );
  }

  return (
    <Container>
      <ScheduleLine>
        <WatchIconContent>
          <div className="icon-calendar">
            <ScheduleIcon style={{ height: 20, width: 20 }} />
          </div>
          <div className="icon-time">
            <BoxTimeIcon />
          </div>
        </WatchIconContent>

        <LineContent>
          <div id="empty" />
          <DateContainer>
            <p id="title">{getLocaleStringMonth()}</p>
          </DateContainer>
          <div id="empty" />
        </LineContent>
      </ScheduleLine>

      <ManagerVisualizationList activities={activities && activities} />
    </Container>
  );
}
