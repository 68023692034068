import { Link } from 'react-router-dom';

import { useAuth } from '../../../hooks/auth';

import { handleGetPageIcon } from '../utils/handleGetPageIcon';
import { handleGetPageLink } from '../utils/handleGetPageLink';

import { IconLabel, NavigateChildContainer } from '../styles';

interface PermissionItensProps {
  openPermission: string;
  permission: {
    id: string;
    name: string;
    description: string;
  };
  permissions: Array<{
    id: string;
    name: string;
    description: string;
  }>;
}

export function PermissionItens({
  openPermission,
  permission,
  permissions,
}: PermissionItensProps): JSX.Element {
  const { user } = useAuth();

  const { profile } = user;

  return (
    <NavigateChildContainer
      style={
        openPermission !== permission.description
          ? {
              display: 'none',
            }
          : {}
      }
    >
      <ul>
        {permissions.length > 0 &&
          permissions.map(user_permission => {
            return (
              user_permission.description === permission.description && (
                <li key={user_permission.id}>
                  <Link
                    to={handleGetPageLink(
                      user_permission.name,
                      profile,
                      user_permission.description,
                    )}
                  >
                    <IconLabel>
                      <img
                        src={handleGetPageIcon(user_permission.name)}
                        alt={user_permission.name}
                      />
                      <span>{user_permission.name}</span>
                    </IconLabel>
                  </Link>
                </li>
              )
            );
          })}
      </ul>
    </NavigateChildContainer>
  );
}
