import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

export const TitleGraphic = styled.span`
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 1.5rem;
  color: #05233e;
`;
