/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeEvent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

import api from '../../../../../services/api';

import {
  ImportPriorityStyle,
  ModalMain,
  TitleContainer,
  DivSeparator,
  Separator,
  SelectTypeImportContainer,
  CheckpointsContainer,
  InputContainer,
  ImportFileContainer,
  ButtonsContainer,
  ButtonLabelContainer,
} from './styles';

Modal.setAppElement('#root');

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface IImportPriorityModal {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ModalImportPriority({
  isOpen,
  onRequestClose,
}: IImportPriorityModal) {
  const [file, setFile] = useState<FileProps>({} as FileProps);
  const [typeImport, setTypeImport] = useState<string>('');

  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const uploadFileError = useCallback((message: string) => {
    Swal.fire('Erro!', `${message}`, 'error');
  }, []);

  const handleSubmitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: FileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };

      setFile(newFile);

      event.target.value = null;
    },
    [],
  );

  const handleUploadFile = useCallback(async () => {
    const data = new FormData();

    try {
      if (typeImport === '') {
        Swal.fire('Selecione o tipo.', '', 'error');
      } else {
        Swal.fire({
          title: 'Seu arquivo está sendo processado',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: false,
          showConfirmButton: false,
        });

        data.append('file', file.file);

        if (typeImport === 'closer') {
          await api.post('schedule/upload-closer-priority', data);
        } else if (typeImport === 'queue') {
          await api.post('schedule/upload-active-queues', data);
        }

        setFile({} as FileProps);

        Swal.close();

        Swal.fire('Arquivo recebido com sucesso', '', 'success');

        setTypeImport('');

        closeModal();
      }
    } catch (error) {
      uploadFileError(error.response.data.message);
    }
  }, [file, typeImport, closeModal, uploadFileError]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={ImportPriorityStyle}
    >
      <ModalMain>
        <TitleContainer>
          <h1>Arquivo de queues</h1>
          <DivSeparator>
            <Separator />
          </DivSeparator>
        </TitleContainer>

        <SelectTypeImportContainer>
          <span>Selecione o tipo:</span>

          <CheckpointsContainer>
            <InputContainer>
              <input
                type="radio"
                name="type_file"
                id="queue"
                onClick={() => setTypeImport('queue')}
              />
              <label htmlFor="queue">Queues</label>
            </InputContainer>

            <InputContainer>
              <input
                type="radio"
                name="type_file"
                id="closer"
                onClick={() => setTypeImport('closer')}
              />
              <label htmlFor="closer">Closers</label>
            </InputContainer>
          </CheckpointsContainer>
        </SelectTypeImportContainer>

        <ImportFileContainer>
          <span>Importe o arquivo:</span>

          <ButtonsContainer>
            <ButtonLabelContainer>
              <label id="file" htmlFor="select_file">
                Selecione um arquivo
              </label>
              <input
                id="select_file"
                type="file"
                name="file"
                placeholder="Selecione um arquivo"
                onChange={handleSubmitFile}
              />
              {file && file.name ? (
                <label htmlFor="select_file">{file.name}</label>
              ) : (
                <label htmlFor="select_file">Nenhum arquivo selecionado</label>
              )}
            </ButtonLabelContainer>

            <ButtonLabelContainer>
              <button type="button" id="send" onClick={handleUploadFile}>
                Enviar
              </button>
              <label htmlFor="send">
                Arquivos serão processados <br /> automaticamente
              </label>
            </ButtonLabelContainer>
          </ButtonsContainer>
        </ImportFileContainer>
      </ModalMain>
    </Modal>
  );
}
