import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as OrderCrescent } from '../../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../../assets/icons/polygon_down.svg';
import { ReactComponent as AvailableIcon } from '../../../../../assets/icons/available_icon.svg';

import {
  Table,
  Thead,
  TableRow,
  Cell,
  ContainerTitle,
  OrderContainerIcon,
  Tbody,
  BoxValor,
  BoxTable,
  BoxCasos,
} from './styles';

import { ICustomerSuccessCases } from '..';

interface CustomerSuccessHistoryProps {
  customerSuccessInfo: ICustomerSuccessCases[];
  setCustomerSuccessInfo: (
    customerSuccessInfo: ICustomerSuccessCases[],
  ) => void;
}

export function CustomerSuccessHistory({
  customerSuccessInfo,
  setCustomerSuccessInfo,
}: CustomerSuccessHistoryProps): JSX.Element {
  const [isOrder, setIsOrder] = useState(false);

  const history = useHistory();

  const orderInfoCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = customerSuccessInfo.sort(
        (previous: ICustomerSuccessCases, next: ICustomerSuccessCases) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
            case 'late':
              return previous.late > next.late
                ? 1
                : previous.late < next.late
                ? -1
                : 0;
            case 'amount':
              return previous.amountSum > next.amountSum
                ? 1
                : previous.amountSum < next.amountSum
                ? -1
                : 0;
            case 'count':
              return previous.countSum > next.countSum
                ? 1
                : previous.countSum < next.countSum
                ? -1
                : 0;
            case 'schedulings':
              return previous.schedulings > next.schedulings
                ? 1
                : previous.schedulings < next.schedulings
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setCustomerSuccessInfo([...order]);
    },
    [customerSuccessInfo, isOrder, setCustomerSuccessInfo],
  );

  const orderInfoCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = customerSuccessInfo.sort(
        (previous: ICustomerSuccessCases, next: ICustomerSuccessCases) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
            case 'late':
              return previous.late < next.late
                ? 1
                : previous.late > next.late
                ? -1
                : 0;
            case 'amount':
              return previous.amountSum < next.amountSum
                ? 1
                : previous.amountSum > next.amountSum
                ? -1
                : 0;
            case 'count':
              return previous.countSum < next.countSum
                ? 1
                : previous.countSum > next.countSum
                ? -1
                : 0;
            case 'schedulings':
              return previous.schedulings < next.schedulings
                ? 1
                : previous.schedulings > next.schedulings
                ? -1
                : 0;
            default:
              return null;
          }
        },
      );

      setCustomerSuccessInfo([...order]);
    },
    [customerSuccessInfo, isOrder, setCustomerSuccessInfo],
  );

  const handleRedirectToIndividualCsHistory = useCallback(
    (customerSuccess: ICustomerSuccessCases) => {
      history.push({
        pathname: '/payments/schedule/customer-sucess/schedule-cases-listing',
        state: {
          user_id: customerSuccess.user_id,
          type: 'Manager',
          user_name: customerSuccess.name,
          sucess: customerSuccess.sucesso_cliente,
        },
      });
    },
    [history],
  );

  return (
    <BoxTable>
      <BoxCasos>CASOS</BoxCasos>
      <BoxValor>VALOR</BoxValor>
      <Table>
        <Thead>
          <TableRow>
            <Cell style={{ borderRight: '1px solid #9d9d9d' }}>
              <AvailableIcon />
            </Cell>
            <Cell
              onClick={() =>
                isOrder ? orderInfoCsByAsc('name') : orderInfoCsByDesc('name')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Sucesso do Cliente</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder ? orderInfoCsByAsc('count') : orderInfoCsByDesc('count')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Fundos/Precato</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCsByAsc('amount')
                  : orderInfoCsByDesc('amount')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Fundos/Precato</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCsByAsc('schedulings')
                  : orderInfoCsByDesc('schedulings')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Agendadas</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder ? orderInfoCsByAsc('late') : orderInfoCsByDesc('late')
              }
            >
              <ContainerTitle>Atrasadas</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>
          </TableRow>
        </Thead>
        <Tbody>
          {customerSuccessInfo &&
            customerSuccessInfo.map(customer_sucess => (
              <TableRow
                key={customer_sucess.id}
                onClick={() =>
                  handleRedirectToIndividualCsHistory(customer_sucess)
                }
              >
                <Cell>
                  <img src={customer_sucess.avatar_url} alt="Foto CS" />
                </Cell>
                <Cell>
                  <span>{customer_sucess.name}</span>
                </Cell>
                <Cell>{customer_sucess.count}</Cell>
                <Cell>{customer_sucess.amount}</Cell>

                <Cell>{customer_sucess.schedulings}</Cell>
                <Cell>{customer_sucess.late}</Cell>
              </TableRow>
            ))}
        </Tbody>
      </Table>
    </BoxTable>
  );
}
