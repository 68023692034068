import { format, isBefore } from 'date-fns';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import checkTaskIcon from '../../../../../assets/icons/check_task.svg';
import checkedTaskIcon from '../../../../../assets/icons/checked_task.svg';
import { ReactComponent as PinSymbol } from '../../../../../assets/icons/pin-symbol.svg';

import api from '../../../../../services/api';
import { useAuth } from '../../../../../hooks/auth';
import { formatAmount } from '../../../../../utils/formatAmount';
import { IActivitiesInADayDTO } from '../../dtos/IActivitiesInADayDTO';
import { IActivityCSDTO } from '../../dtos/IActivityCSDTO';
import { ModalUpdateActivity } from '../../ModalUpdateActivity';

import {
  TableContainer,
  HeadContainer,
  RowBody,
  RowContainer,
  ColumnHeadContainer,
  BodyContainer,
  ColumnBodyContainer,
  ReminderRowBody,
} from './styles';
import { IconContainer } from '../VisualizationCalendar/styles';
import { ModalConclusionConfirmation } from '../../ModalConclusionConfirmation';
import { ModalCreateActivityFromActivity } from '../../ModalCreateActivityFromProvidence';

interface VisualizationListProps {
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (activity: IActivitiesInADayDTO) => void;
}

export function VisualizationList({
  activitiesInADay,
  setActivitiesInADay,
}: VisualizationListProps): JSX.Element {
  const { user } = useAuth();
  const [openConclusionConfirmationModal, setConclusionConfirmationModal] =
    useState(false);
  const [openCreateActivityModal, setOpenCreateActivityModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [activityTypeToCreate, setActivityTypeToCreate] = useState('');
  const [selectedActivity, setSelectedActivity] = useState<IActivityCSDTO>(
    {} as IActivityCSDTO,
  );

  const [selectedConclusionActivity, setSelectedConclusionActivity] =
    useState<IActivityCSDTO>({} as IActivityCSDTO);

  const [updatedStatusActivity, setUpdatedStatusActivity] = useState({
    id: '',
    situation: '',
  });

  /*  const REFRESH_ACTIVITIES_CS = gql`
    subscription ListAllActivitiesCS {
      listAllActivitiesCS {
        quantity_activities
        quantity_cases
        formatted_amount
        activities {
          hour
          activities {
            id
            name
            type
            time_init
            time_end
            timestamp
            hubspot_deal_id
            dealname
            closer_name
            user_name
            amount
            proposal_amount
            stage_deal
            stage_ticket
            valid_contact
            operation_number
            observation
            situation
            checklist_concluded
            closer_id
          }
        }
        providences {
          id
          name
          type
          time_init
          time_end
          timestamp
          hubspot_deal_id
          dealname
          closer_name
          user_name
          amount
          proposal_amount
          stage_deal
          stage_ticket
          valid_contact
          operation_number
          observation
          situation
          checklist_concluded
          closer_id
        }
      }
    }
  `;

  const refresh = useSubscription(REFRESH_ACTIVITIES_CS);

  useEffect(() => {
    if (refresh.data) {
      api
        .get(
          `/schedule-cs?start_date=${format(
            new Date(),
            'yyyy-MM-dd',
          )}&end_date&user_id=${user.id}`,
        )
        .then(response => {
          setActivitiesInADay(response.data);
        });
    }
  }, [refresh, setActivitiesInADay, user.id]); */

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback((activity: IActivityCSDTO) => {
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  }, []);

  const handleConclusionVariableConfirmation = useCallback(
    async aux => {
      if (aux === false) {
        setOpenCreateActivityModal(true);
      } else if (activityTypeToCreate === 'Providência') {
        await api.patch('/schedule-cs/providence', {
          providence_id: selectedConclusionActivity.providence_id,
          situation: 'Concluída',
        });
      }
    },
    [activityTypeToCreate, selectedConclusionActivity.providence_id],
  );

  const handleChangeActivityStatus = useCallback(
    async (activity: IActivityCSDTO) => {
      try {
        activity.situation =
          activity.situation === 'Concluída' ? 'Pendente' : 'Concluída';

        setUpdatedStatusActivity({
          id: activity.id,
          situation: activity.situation,
        });

        if (activity.time_init) {
          if (
            activity.situation === 'Concluída' &&
            activity.providence_id != null
          ) {
            setActivityTypeToCreate('Providência');
            setConclusionConfirmationModal(true);

            setSelectedConclusionActivity(activity);

            await api.patch('/schedule-cs', {
              id: activity.id,
              user_id: user.id,
              situation: activity.situation,
            });
          } else if (
            activity.situation === 'Concluída' &&
            activity.type === 'Retorno credor'
          ) {
            setActivityTypeToCreate('Retorno credor');
            setConclusionConfirmationModal(true);

            setSelectedConclusionActivity(activity);

            await api.patch('/schedule-cs', {
              id: activity.id,
              user_id: user.id,
              situation: activity.situation,
            });
          } else {
            await api.patch('/schedule-cs', {
              id: activity.id,
              user_id: user.id,
              situation: activity.situation,
            });
          }
        } else {
          await api.patch('/schedule-cs/providence', {
            providence_id: activity.id,
            situation: activity.situation,
          });
        }

        toast.success('Atividade atualizada com sucesso!');
      } catch {
        toast.error('Falha ao atualizar a tarefa!');
      }
    },
    [user.id],
  );

  const handleCloseCreateActivityModal = useCallback(() => {
    setOpenCreateActivityModal(false);
  }, []);

  const handleUpdateListAcitivity = useCallback(async () => {
    api
      .get(
        `/schedule-cs?start_date=${format(
          new Date(),
          'yyyy-MM-dd',
        )}&end_date&user_id=${user.id}`,
      )
      .then(response => {
        setActivitiesInADay(response.data);
      });
  }, [setActivitiesInADay, user.id]);

  const handleCloseConclusionConfirmationModal = useCallback(() => {
    setConclusionConfirmationModal(false);
    handleUpdateListAcitivity();
  }, [handleUpdateListAcitivity]);

  return (
    <>
      <TableContainer>
        <HeadContainer>
          <RowContainer className="header">
            <ColumnHeadContainer>HORÁRIO</ColumnHeadContainer>
            <ColumnHeadContainer>ATIVIDADE</ColumnHeadContainer>
            <ColumnHeadContainer>CREDOR</ColumnHeadContainer>
            <ColumnHeadContainer>VALOR</ColumnHeadContainer>
            <ColumnHeadContainer>ETAPA</ColumnHeadContainer>
            <ColumnHeadContainer>STATUS</ColumnHeadContainer>
          </RowContainer>
        </HeadContainer>

        <BodyContainer>
          {activitiesInADay.reminders &&
            activitiesInADay.reminders.map(reminder => (
              <ReminderRowBody
                key={reminder.id}
                className="row-props-body"
                isBefore={
                  reminder.type !== 'Reunião'
                    ? reminder.time_init
                      ? verifyIsBeforeNow(reminder.time_init)
                      : verifyIsBeforeNow(reminder.timestamp)
                    : false
                }
                statusTask={
                  reminder.type !== 'Reunião' &&
                  ((updatedStatusActivity.id === reminder.id &&
                    updatedStatusActivity.situation === 'Concluída') ||
                    reminder.situation === 'Concluída')
                }
                checklist_concluded={
                  reminder.checklist_concluded &&
                  Number(reminder.checklist_concluded) > 0
                }
              >
                <ColumnBodyContainer
                  className="time"
                  onClick={() => handleOpenActivityModal(reminder)}
                >
                  <PinSymbol
                    style={{ width: '20px', height: '20px', fill: '#8696A0' }}
                  />
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => handleOpenActivityModal(reminder)}
                >
                  {reminder.title || reminder.name}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => handleOpenActivityModal(reminder)}
                >
                  {reminder.dealname}
                </ColumnBodyContainer>

                {reminder.type !== 'Reunião' ? (
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(reminder)}
                  >
                    {formatAmount(Number(reminder.amount))}
                  </ColumnBodyContainer>
                ) : (
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(reminder)}
                  />
                )}

                <ColumnBodyContainer
                  onClick={() => handleOpenActivityModal(reminder)}
                >
                  {reminder.stage_deal}
                </ColumnBodyContainer>

                <ColumnBodyContainer>
                  {(updatedStatusActivity.id === reminder.id &&
                    updatedStatusActivity.situation) ||
                    reminder.situation}
                  {user.profile === 'Customer Success' ||
                  user.profile === 'Customer Success Calculator' ? (
                    <img
                      onClick={() => handleChangeActivityStatus(reminder)}
                      src={
                        (updatedStatusActivity.id === reminder.id &&
                          updatedStatusActivity.situation === 'Concluída') ||
                        reminder.situation === 'Concluída'
                          ? checkedTaskIcon
                          : checkTaskIcon
                      }
                      alt="Concluir tarefa"
                    />
                  ) : (
                    ''
                  )}
                </ColumnBodyContainer>
              </ReminderRowBody>
            ))}

          {activitiesInADay.activities.length > 0 &&
            activitiesInADay.activities.map(
              activityInADay =>
                Object.keys(activityInADay.activities).length > 0 &&
                activityInADay.activities.map(activity => (
                  <RowBody
                    key={activity.id}
                    className="row-props-body"
                    isBefore={
                      activity.type !== 'Reunião'
                        ? activity.time_init
                          ? verifyIsBeforeNow(activity.time_init)
                          : verifyIsBeforeNow(activity.timestamp)
                        : false
                    }
                    statusTask={
                      activity.type !== 'Reunião' &&
                      ((updatedStatusActivity.id === activity.id &&
                        updatedStatusActivity.situation === 'Concluída') ||
                        activity.situation === 'Concluída')
                    }
                    checklist_concluded={
                      activity.checklist_concluded &&
                      Number(activity.checklist_concluded) > 0
                    }
                  >
                    <ColumnBodyContainer
                      className="time"
                      onClick={() => handleOpenActivityModal(activity)}
                    >
                      {activity.time_init
                        ? format(new Date(activity.time_init), 'HH:mm')
                        : format(new Date(activity.timestamp), 'HH:mm')}
                    </ColumnBodyContainer>

                    <ColumnBodyContainer
                      onClick={() => handleOpenActivityModal(activity)}
                    >
                      {activity.title || activity.name}
                    </ColumnBodyContainer>

                    <ColumnBodyContainer
                      onClick={() => handleOpenActivityModal(activity)}
                    >
                      {activity.dealname}
                    </ColumnBodyContainer>

                    {activity.type !== 'Reunião' ? (
                      <ColumnBodyContainer
                        onClick={() => handleOpenActivityModal(activity)}
                      >
                        {formatAmount(Number(activity.amount))}
                      </ColumnBodyContainer>
                    ) : (
                      <ColumnBodyContainer
                        onClick={() => handleOpenActivityModal(activity)}
                      />
                    )}

                    <ColumnBodyContainer
                      onClick={() => handleOpenActivityModal(activity)}
                    >
                      {activity.stage_deal}
                    </ColumnBodyContainer>

                    {activity.type !== 'Reunião' ? (
                      <ColumnBodyContainer>
                        {(updatedStatusActivity.id === activity.id &&
                          updatedStatusActivity.situation) ||
                          activity.situation}
                        {user.profile === 'Customer Success' ||
                        user.profile === 'Customer Success Calculator' ? (
                          <img
                            onClick={() => handleChangeActivityStatus(activity)}
                            src={
                              (updatedStatusActivity.id === activity.id &&
                                updatedStatusActivity.situation ===
                                  'Concluída') ||
                              activity.situation === 'Concluída'
                                ? checkedTaskIcon
                                : checkTaskIcon
                            }
                            alt="Concluir tarefa"
                          />
                        ) : (
                          ''
                        )}
                      </ColumnBodyContainer>
                    ) : (
                      <ColumnBodyContainer
                        onClick={() => handleOpenActivityModal(activity)}
                      />
                    )}
                  </RowBody>
                )),
            )}
        </BodyContainer>
      </TableContainer>
      <ModalConclusionConfirmation
        isOpen={openConclusionConfirmationModal}
        conclusionConfirmation={handleConclusionVariableConfirmation}
        onRequestClose={handleCloseConclusionConfirmationModal}
        type={activityTypeToCreate}
      />
      {openCreateActivityModal && (
        <ModalCreateActivityFromActivity
          isOpen={openCreateActivityModal}
          onRequestClose={handleCloseCreateActivityModal}
          activity={selectedConclusionActivity}
          observation={selectedConclusionActivity.observation}
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
          updateActivityClose={undefined}
          activityType={activityTypeToCreate}
        />
      )}

      {Object.keys(selectedActivity).length > 0 && (
        <ModalUpdateActivity
          isOpen={openActivityModal}
          isProvidence={false}
          onRequestClose={handleCloseActivityModal}
          activity={selectedActivity}
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
          updateListAcitivity={handleUpdateListAcitivity}
        />
      )}
    </>
  );
}
