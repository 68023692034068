import styled from 'styled-components';

export const ModalSchedule = {
  content: {
    width: '18.938rem',
    height: '11.75rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
};

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Title = styled.h1`
  margin-top: 1.688rem;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #041524;
`;

export const RadioButtonGroup = styled.div`
  margin-top: 1.313rem;
  margin-left: 2.647rem;

  display: flex;
  flex-direction: column;
  gap: 0.563rem;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.308rem;

  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #3d3d3d;

  input,
  label {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.938rem;
  margin-top: 1.813rem;
  margin-right: 1.563rem;
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0 0.25rem;
  transition: background-color 0.3s;

  :first-child {
    color: #6d6d6d;

    :hover {
      border-radius: 0.125rem;

      background: #6d6d6d;
      color: #ffffff;
    }
  }

  :last-child {
    color: #1665d8;

    :hover {
      border-radius: 0.125rem;

      background: #1665d8;
      color: #ffffff;
    }
  }
`;
