/* eslint-disable no-alert */
import React, { ChangeEvent, useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';

import { IoIosSend } from 'react-icons/io';
import { FaCalendarCheck } from 'react-icons/fa';
import { format } from 'date-fns';
import { RiCloseCircleFill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { Container } from '../../../components/Container';
import {
  DivSeparator,
  Separator,
  LiquidationDataContainer,
  LiquidationDataTitleContainer,
  Table,
  Divisor,
  Thead,
  Tbody,
  Celula,
  ContainerTitle,
  ButtonsContainer,
  SendButtonContainer,
  CreateButtonContainer,
  H1,
  FileContainer,
  FileInputContainer,
  SendFileContainer,
} from './styles';
import { Body } from '../../../components/Styles/Body';
import {
  H1NavigationNow,
  Navegation,
  H1Navigation,
} from '../../../components/Styles/Navigation';

import api from '../../../services/apiCustomerSuccess';

import { Title } from '../../../components/Styles/Title';

interface Liquidation {
  id: string;
  customer_name: string;
  cpf_cnpj: string;
  process_number: string;
  due_date: string;
  nominal_value: string;
  liquidation_type: string;
  liquidation_value: string;
  liquidation_date: string;
  residual_value: string;
  debitor_name: string;
  debitor_cpf_cnpj: string;
  status: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

const Liquidations: React.FC = () => {
  const [file, setFile] = useState<FileProps>({} as FileProps);

  const [liquidations, setLiquidations] = useState<Liquidation[]>([]);
  const [date, setDate] = useState<string>(() =>
    format(new Date(), 'yyyy-MM-dd'),
  );

  useEffect(() => {
    api.get<Liquidation[]>(`liquidations?date=${date}`).then(response => {
      setLiquidations(response.data);
    });
  }, [date]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setDate(value);
  };

  const createAlert = useCallback((message: string) => {
    Swal.fire('Atenção!', `${message}`, 'warning');
  }, []);

  const handleUploadFile = useCallback(async () => {
    const data = new FormData();

    try {
      data.append('file', file.file);

      const response = await api.post('liquidations/send-file', data);

      setFile({} as FileProps);

      alert(response.data.message);

      const responseLiquidations = await api.get<Liquidation[]>(
        `liquidations?date=${date}`,
      );

      setLiquidations(responseLiquidations.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      createAlert(error.data.message);
    }
  }, [file.file, date, createAlert]);

  const submitFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const getFile =
      event.target.files === null ? ({} as File) : event.target.files[0];

    const newFile: FileProps = {
      file: getFile,
      name: getFile.name,
      readableSize: getFile.size.toString(),
    };
    setFile(newFile);
  }, []);

  const handleSend = useCallback(async () => {
    const [year, month, day] = date.split('-');

    if (
      window.confirm(
        `Deseja enviar as remessas referente ao dia ${day}/${month}/${year} ?`,
      )
    ) {
      try {
        await api.post(`liquidations/send?date=${date}`);

        alert('As remessas serão enviadas, favor acompanhar.');

        await api
          .get<Liquidation[]>(`liquidations?date=${date}`)
          .then(response => {
            setLiquidations(response.data);
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, [date]);

  const handleDelete = useCallback(
    async (id: string) => {
      if (window.confirm('Deseja apagar essa remessa ?')) {
        try {
          await api.delete(`liquidations/delete/${id}`);

          const updatedLiquidations = liquidations.filter(
            liquidation => liquidation.id !== id,
          );

          setLiquidations(updatedLiquidations);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    },
    [liquidations],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Vortx {'>'} </H1Navigation>
        <H1NavigationNow>Liquidação</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Vórtx - Liquidações</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <ButtonsContainer>
          <CreateButtonContainer>
            <Link to="/vortx/liquidations/create">
              <button type="button">
                <FiPlus size={20} />
                Criar
              </button>
            </Link>
          </CreateButtonContainer>
          <SendButtonContainer>
            <button type="button" onClick={handleSend}>
              <IoIosSend size={20} />
              Enviar
            </button>
          </SendButtonContainer>
        </ButtonsContainer>

        <FileContainer>
          <FileInputContainer>
            <SendFileContainer>
              <strong>Arquivo:</strong>
              <div>
                <label htmlFor="file">Selecione um arquivo</label>
                <input
                  onChange={event => submitFile(event)}
                  id="file"
                  type="file"
                  name="file"
                />
                {file && file.name ? (
                  <span>{file.name}</span>
                ) : (
                  <span>Nenhum arquivo selecionado</span>
                )}
              </div>
            </SendFileContainer>

            <SendButtonContainer>
              <button type="button" onClick={() => handleUploadFile()}>
                Realizar upload
              </button>
            </SendButtonContainer>
          </FileInputContainer>
        </FileContainer>

        <H1>Listagem de casos</H1>

        <LiquidationDataContainer>
          <LiquidationDataTitleContainer>
            <FaCalendarCheck size={20} color="#6D6D6D" />
            <input
              type="date"
              name="date"
              onChange={handleInputChange}
              value={date}
            />
          </LiquidationDataTitleContainer>

          <Table>
            <Thead>
              <tr>
                <th>
                  <Celula>
                    <ContainerTitle>Credor</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Nº precatório</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Valor nominal</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Forma liquidação</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Data liquidação</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Valor liquidação</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Valor residual</ContainerTitle>
                    <Divisor />
                  </Celula>
                </th>
                <th>
                  <Celula>
                    <ContainerTitle>Status</ContainerTitle>
                  </Celula>
                </th>
                <th style={{ width: '2rem' }}>
                  <RiCloseCircleFill style={{ width: '2rem' }} />
                </th>
              </tr>
            </Thead>
            <Tbody>
              {liquidations.length > 0 ? (
                liquidations.map(liquidation => (
                  <tr key={liquidation.id}>
                    <Link
                      to={{
                        pathname: '/vortx/liquidations/edit',
                        state: {
                          id: liquidation.id,
                        },
                      }}
                    >
                      <td>{liquidation.customer_name}</td>
                      <td>{liquidation.process_number}</td>
                      <td>{liquidation.nominal_value}</td>
                      <td>{liquidation.liquidation_type}</td>
                      <td>{liquidation.liquidation_date}</td>
                      <td>{liquidation.liquidation_value}</td>
                      <td>{liquidation.residual_value}</td>
                      <td>{liquidation.status}</td>
                    </Link>
                    <td
                      onClick={() => handleDelete(liquidation.id)}
                      style={{ width: '2rem' }}
                    >
                      <RiCloseCircleFill size={18} />
                    </td>
                  </tr>
                ))
              ) : (
                <strong>Não há resultados para essa data.</strong>
              )}
            </Tbody>
          </Table>
        </LiquidationDataContainer>
      </Body>
    </Container>
  );
};

export { Liquidations };
