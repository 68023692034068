import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { Title } from '../../../../components/Title';
import { useAuth } from '../../../../hooks/auth';
import { Loading } from '../../../../components/Loading';

import {
  PreparationModalStyle,
  Main,
  InputContainer,
  Label,
  Select,
  Input,
  Textarea,
  ButtonSave,
} from './styles';
import { IPreparationPropertiesDTO } from '../dtos/IPreparationPropertiesDTO';
import { createPreparationClarificationNote } from '../utils/createPreparationClarificationNote';

interface FormData {
  scheduling_id: string;
  hubspot_deal_id: string;
  sla_note: string;
  timestamp: Date;
  clarification_note: string;
  waiting_time_expectation?: string;
  deadline_deconstruction?: string;
  accompanying_process?: string;
  approach?: string;
  influences?: string;
  influences_highlights?: string;
  have_competition?: string;
  have_discomfort?: string;
  discomfort?: string;
  implication_questions?: string;
  general_observations?: string;
  where_did_he_come_from?: string;
  know_who_to_talk_to?: string;
  hunter_objections?: string;
  customer_plans?: string;
  customer_s_age?: number;
}

interface PreparationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  preparation: IPreparationPropertiesDTO;
  scheduling_connection: string;
}

export function PreparationModal({
  isOpen,
  onRequestClose,
  preparation,
  scheduling_connection,
}: PreparationModalProps): JSX.Element {
  const [formData, setFormData] = useState<FormData>({
    ...{},
  } as FormData);

  const [preparationValues, setPreparationValues] =
    useState<IPreparationPropertiesDTO>({} as IPreparationPropertiesDTO);
  const [selectedConnection, setSelectedConnection] = useState<string>();

  useEffect(() => {
    const clarificationNote = createPreparationClarificationNote({
      scheduling_connection,
      waiting_time_expectation: formData.waiting_time_expectation,
      deadline_deconstruction: formData.deadline_deconstruction,
      accompanying_process: formData.accompanying_process,
      approach: formData.approach,
      influences: formData.influences,
      influences_highlights: formData.influences_highlights,
      have_competition: formData.have_competition,
      have_discomfort: formData.have_discomfort,
      discomfort: formData.discomfort,
      implication_questions: formData.implication_questions,
      general_observations: formData.general_observations,
      where_did_he_come_from: formData.where_did_he_come_from,
      know_who_to_talk_to: formData.know_who_to_talk_to,
      hunter_objections: formData.hunter_objections,
      customer_plans: formData.customer_plans,
      customer_s_age: formData.customer_s_age,
    });

    setFormData(form_data => {
      return { ...form_data, clarification_note: clarificationNote };
    });
  }, [
    formData.accompanying_process,
    formData.approach,
    formData.customer_plans,
    formData.customer_s_age,
    formData.deadline_deconstruction,
    formData.discomfort,
    formData.general_observations,
    formData.have_competition,
    formData.have_discomfort,
    formData.hunter_objections,
    formData.implication_questions,
    formData.influences,
    formData.influences_highlights,
    formData.know_who_to_talk_to,
    formData.waiting_time_expectation,
    formData.where_did_he_come_from,
    preparation.hubspot_deal_id,
    preparation.sla_note,
    scheduling_connection,
  ]);

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setFormData({
      ...{},
    } as FormData);
    setPreparationValues({} as IPreparationPropertiesDTO);
  }, [onRequestClose]);

  useEffect(() => {
    const loadData = async () => {
      setSelectedConnection(undefined);

      const response = await api.post(
        '/schedule/update-scheduling-preparation',
        {
          type: 'Carregar',
          preparation_id: preparation.id,
        },
      );

      if (response.data) {
        setPreparationValues(response.data);

        setFormData({
          ...response.data,
        });
      } else {
        setFormData({ ...formData });
      }
    };

    loadData();
  }, [preparation.id]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData],
  );

  const handleUpdatePreparation = useCallback(async () => {
    try {
      await api.post('/schedule/update-scheduling-preparation', {
        type: 'Salvar',
        preparation_id: preparation.id,
        scheduling_id: preparation.scheduling_id,
        timestamp: preparation.timestamp,
        clarification_note: formData.clarification_note,
        waiting_time_expectation: formData.waiting_time_expectation,
        deadline_deconstruction: formData.deadline_deconstruction,
        accompanying_process: formData.accompanying_process,
        approach: formData.approach,
        influences: formData.influences,
        influences_highlights: formData.influences_highlights,
        have_competition: formData.have_competition,
        have_discomfort: formData.have_discomfort,
        discomfort: formData.discomfort,
        implication_questions: formData.implication_questions,
        general_observations: formData.general_observations,
        where_did_he_come_from: formData.where_did_he_come_from,
        know_who_to_talk_to: formData.know_who_to_talk_to,
        hunter_objections: formData.hunter_objections,
        customer_plans: formData.customer_plans,
        customer_s_age: formData.customer_s_age,
      });
      const loader = document.getElementById('loader_up');
      loader.style.display = 'flex';

      handleCloseModal();
      toast.success('Informações de preparo atualizadas!');
    } catch (error) {
      const loader = document.getElementById('loader_up');
      loader.style.display = 'none';

      if (error instanceof Yup.ValidationError) {
        toast.error('Preencha todos os campos corretamente.');
        return;
      }
      if (error.response.data.status === 404) {
        Swal.fire('Erro!');
        return;
      }
      toast.error('Não foi possível atualizar o preparo de agendamento.');
    }
  }, [
    formData.accompanying_process,
    formData.approach,
    formData.clarification_note,
    formData.customer_plans,
    formData.customer_s_age,
    formData.deadline_deconstruction,
    formData.discomfort,
    formData.general_observations,
    formData.have_competition,
    formData.have_discomfort,
    formData.hunter_objections,
    formData.implication_questions,
    formData.influences,
    formData.influences_highlights,
    formData.know_who_to_talk_to,
    formData.waiting_time_expectation,
    formData.where_did_he_come_from,
    handleCloseModal,
    preparation.id,
    preparation.scheduling_id,
    preparation.timestamp,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={PreparationModalStyle}
    >
      <Title
        name={
          scheduling_connection.includes('Retomada')
            ? 'Preparo - Retomada'
            : scheduling_connection.includes('Inbound') ||
              scheduling_connection.includes('Honorários')
            ? 'Preparo - Inbound Digital'
            : scheduling_connection.includes('Outbound')
            ? 'Preparo - Outbound'
            : scheduling_connection.includes('Honorários')
            ? 'Preparo - Inbound Digital'
            : scheduling_connection.includes('Novo Processo')
            ? 'Preparo - Novo Processo'
            : ''
        }
      />

      <Loading loader_up />

      <Main>
        <>
          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo')) && (
            <InputContainer>
              <Label>Tenho ciência de com quem vou conversar?</Label>
              <Select
                name="know_who_to_talk_to"
                defaultValue=""
                value={formData.know_who_to_talk_to}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo')) && (
            <InputContainer>
              <Label>Trouxe objeções com o hunter?</Label>
              <Select
                name="hunter_objections"
                defaultValue=""
                value={formData.hunter_objections}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
              </Select>
            </InputContainer>
          )}

          {scheduling_connection.includes('Retomada') && (
            <InputContainer>
              <Label>Qual o tempo de espera que o credor acredita?</Label>
              <Select
                name="waiting_time_expectation"
                defaultValue=""
                value={formData.waiting_time_expectation}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </Select>
            </InputContainer>
          )}

          {scheduling_connection.includes('Retomada') && (
            <InputContainer>
              <Label>Preciso desconstruir o prazo para o credor?</Label>
              <Select
                name="deadline_deconstruction"
                defaultValue=""
                value={formData.deadline_deconstruction}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo') ||
            scheduling_connection.includes('Retomada')) && (
            <InputContainer>
              <Label>Acompanha o andamento do processo?</Label>
              <Select
                name="accompanying_process"
                defaultValue=""
                value={formData.accompanying_process}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Honorários')) && (
            <InputContainer>
              <Label>Ele veio por consulta ou por anúncio?</Label>
              <Select
                name="where_did_he_come_from"
                defaultValue=""
                value={formData.where_did_he_come_from}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="CONSULTA">CONSULTA</option>
                <option value="E-MAIL">E-MAIL</option>
                <option value="SMS">SMS</option>
                <option value="WHATSAPP">WHATSAPP</option>
                <option value="ANÚNCIO">ANÚNCIO</option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Retomada') ||
            scheduling_connection.includes('Honorários')) && (
            <InputContainer>
              <Label>Abordagem?</Label>
              <Select
                name="approach"
                defaultValue=""
                value={formData.approach}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="mais_detalhista_pec">
                  MAIS DETALHISTA - PEC
                </option>
                <option value="mais_detalhista_desconstrucao_valores">
                  MAIS DETALHISTA - DESCONSTRUÇÃO DE VALORES
                </option>
                <option value="mais_detalhista_descontos">
                  MAIS DETALHISTA - DESCONTOS
                </option>
                <option value="mais_detalhista_desconfortos">
                  MAIS DETALHISTA - DESCONFORTOS
                </option>
                <option value="mais_detalhista_interferencias">
                  MAIS DETALHISTA - INTERFERÊNCIAS
                </option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo') ||
            scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Retomada') ||
            scheduling_connection.includes('Honorários')) && (
            <InputContainer>
              <Label>Tem influências?</Label>
              <Select
                name="influences"
                defaultValue=""
                value={formData.influences}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
                <option value="não_identificado">Não Identificado</option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo') ||
            scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Retomada') ||
            scheduling_connection.includes('Honorários')) &&
            formData.influences === 'sim' && (
              <InputContainer>
                <Label>Está destacado quais influências são?</Label>
                <Select
                  name="influences_highlights"
                  defaultValue=""
                  value={formData.influences_highlights}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option value="Advogado">Advogado</option>
                  <option value="Terceiros">Terceiros</option>
                  <option value="Esposo_a">Esposo(A)</option>
                  <option value="Filho_a">Filho(A)</option>
                </Select>
              </InputContainer>
            )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo') ||
            scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Retomada') ||
            scheduling_connection.includes('Honorários')) && (
            <InputContainer>
              <Label>Ele possui concorrência?</Label>
              <Select
                name="have_competition"
                defaultValue=""
                value={formData.have_competition}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
                <option value="não_identificado">Não Identificado</option>
              </Select>
            </InputContainer>
          )}

          {scheduling_connection.includes('Retomada') && (
            <InputContainer>
              <Label>Possui desconforto?</Label>
              <Select
                name="have_discomfort"
                defaultValue=""
                value={formData.have_discomfort}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </Select>
            </InputContainer>
          )}

          {scheduling_connection.includes('Retomada') &&
            formData.have_discomfort === 'sim' && (
              <InputContainer>
                <Label>Qual desconforto?</Label>
                <Select
                  name="discomfort"
                  defaultValue=""
                  value={formData.discomfort}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option value="Dívida">Dívida</option>
                  <option value="Problemas de saúde">Problemas de saúde</option>
                  <option value="Comprar casa">Comprar casa</option>
                  <option value="Ajudar familiares">Ajudar familiares</option>
                  <option value="Abrir um negócio">Abrir um negócio</option>
                  <option value="Comprar carro">Comprar carro</option>
                  <option value="Viagem">Viagem</option>
                  <option value="Segurança em ter o recurso">
                    Segurança em ter o recurso
                  </option>
                  <option value="Investimento">Investimento</option>
                </Select>
              </InputContainer>
            )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo')) && (
            <InputContainer>
              <Label>Quais são os planos do cliente?</Label>
              <Select
                name="customer_plans"
                defaultValue=""
                value={formData.customer_plans}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="Dívida">Dívida</option>
                <option value="Problemas de saúde">Problemas de saúde</option>
                <option value="Comprar casa">Comprar casa</option>
                <option value="Ajudar familiares">Ajudar familiares</option>
                <option value="Abrir um negócio">Abrir um negócio</option>
                <option value="Comprar carro">Comprar carro</option>
                <option value="Viagem">Viagem</option>
                <option value="Segurança em ter o recurso">
                  Segurança em ter o recurso
                </option>
                <option value="Investimento">Investimento</option>
              </Select>
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo')) && (
            <InputContainer>
              <Label>Qual a idade do credor?</Label>
              <Input
                type="number"
                name="customer_s_age"
                defaultValue=""
                value={formData.customer_s_age}
                onChange={handleInputChange}
              />
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo') ||
            scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Retomada') ||
            scheduling_connection.includes('Honorários')) && (
            <InputContainer>
              <Label>
                Quais perguntas de implicação você está se preparando para
                fazer?
              </Label>
              <Textarea
                name="implication_questions"
                defaultValue=""
                value={formData.implication_questions}
                onChange={handleInputChange}
              />
            </InputContainer>
          )}

          {(scheduling_connection.includes('Outbound') ||
            scheduling_connection.includes('Novo Processo') ||
            scheduling_connection.includes('Inbound') ||
            scheduling_connection.includes('Retomada') ||
            scheduling_connection.includes('Honorários')) && (
            <InputContainer>
              <Label>Observações Gerais:</Label>
              <Textarea
                name="general_observations"
                defaultValue=""
                value={formData.general_observations}
                onChange={handleInputChange}
              />
            </InputContainer>
          )}
        </>

        <InputContainer>
          <ButtonSave onClick={handleUpdatePreparation}>Salvar</ButtonSave>
        </InputContainer>
      </Main>
    </Modal>
  );
}
