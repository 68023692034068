interface IRequest {
  scheduling_connection: string;
  waiting_time_expectation?: string;
  deadline_deconstruction?: string;
  accompanying_process?: string;
  approach?: string;
  influences?: string;
  influences_highlights?: string;
  have_competition?: string;
  have_discomfort?: string;
  discomfort?: string;
  implication_questions?: string;
  general_observations?: string;
  where_did_he_come_from?: string;
  know_who_to_talk_to?: string;
  hunter_objections?: string;
  customer_plans?: string;
  customer_s_age?: number;
}

export function createPreparationClarificationNote({
  scheduling_connection,
  waiting_time_expectation,
  deadline_deconstruction,
  accompanying_process,
  approach,
  influences,
  influences_highlights,
  have_competition,
  have_discomfort,
  discomfort,
  implication_questions,
  general_observations,
  where_did_he_come_from,
  know_who_to_talk_to,
  hunter_objections,
  customer_plans,
  customer_s_age,
}: IRequest): string {
  if (scheduling_connection === 'Retomada') {
    return `RAPPORT \n\
  Qual o tempo de espera que o credor acredita? ${waiting_time_expectation} \n\
  Preciso desconstruir o prazo para o credor? ${deadline_deconstruction} \n\
  Acompanha o andamento do processo? ${accompanying_process} \n\
  Abordagem? ${approach} \n\
  Tem influências? ${influences} \n
  Está destacado quais influências são? ${influences_highlights} \n\
  Ele possui concorrência? ${have_competition} \n\
  Possui desconforto? ${have_discomfort} \n\
  Qual desconforto? ${discomfort} \n\
  Quais perguntas de implicação você está se preparando para fazer? ${implication_questions} \n\
  Observações gerais: ${general_observations} \n\
  `;
  }
  if (scheduling_connection === 'Inbound Digital') {
    return `RAPPORT \n\
  Ele veio por consulta ou por anúncio? ${where_did_he_come_from} \n\
  Abordagem? ${approach} \n\
  Tem influências? ${influences} \n
  Está destacado quais influências são? ${influences_highlights} \n\
  Ele possui concorrência? ${have_competition} \n\
  Quais perguntas de implicação você está se preparando para fazer? ${implication_questions} \n\
  Observações gerais: ${general_observations} \n\
  `;
  }
  if (scheduling_connection === 'Outbound') {
    return `RAPPORT \n\
  Tenho ciência de com quem vou conversar? ${know_who_to_talk_to} \n\
  Trouxe objeções com o hunter? ${hunter_objections} \n\
  Acompanha o andamento do processo? ${accompanying_process} \n\
  Tem influências? ${influences} \n\
  Está destacado quais influências são? ${influences_highlights} \n\
  Ele possui concorrência? ${have_competition} \n\
  Quais são os planos do cliente? ${customer_plans} \n\
  Qual a idade do credor? ${customer_s_age} \n\
  Quais perguntas de implicação você está se preparando para fazer? ${implication_questions} \n\
  Observações gerais: ${general_observations} \n\
  `;
  }
  return `RAPPORT \n\
  Qual o tempo de espera que o credor acredita? ${waiting_time_expectation} \n\
  Preciso desconstruir o prazo para o credor? ${deadline_deconstruction} \n\
  Acompanha o andamento do processo? ${accompanying_process} \n\
  Abordagem? ${approach} \n\
  Tem influências? ${influences} \n
  Está destacado quais influências são? ${influences_highlights} \n\
  Ele possui concorrência? ${have_competition} \n\
  Possui desconforto? ${have_discomfort} \n\
  Qual desconforto? ${discomfort} \n\
  Quais perguntas de implicação você está se preparando para fazer? ${implication_questions} \n\
  Observações gerais: ${general_observations} \n\
  Ele veio por consulta ou por anúncio? ${where_did_he_come_from} \n\
  Tenho ciência de com quem vou conversar? ${know_who_to_talk_to} \n\
  Trouxe objeções com o hunter? ${hunter_objections} \n\
  Quais são os planos do cliente? ${customer_plans} \n\
  Qual a idade do credor? ${customer_s_age} \n\
  `;
}
