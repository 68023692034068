import styled, { css } from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';

interface QueueInputProps {
  verifyQueueName?: boolean;
}

interface ButtonsProps {
  noClickable?: boolean;
}

export const MainContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  display: grid;
  align-items: center;
  /* grid-template-rows: repeat(2, 50%); */
  grid-template-columns: repeat(2, 50%);
  gap: 1rem;
`;

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-rows: repeat(2, 50%);
  /* justify-content: center; */
`;

export const TableContainer = styled.div`
  /* width: 68.75rem;

  overflow-x: auto;

  @media (min-width: 1440px) {
    width: 100%;
  } */
  width: 100%;
  border: 0.125rem solid #000;
  border-radius: 0.5rem;
  padding: 0.625rem;
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const Table = styled.table`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Thead = styled.thead`
  width: 100%;

  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head-row {
    height: 2.5rem;
  }
`;

export const TableTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 0.225rem;
  align-items: center;
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;

  width: 100%;

  .buttons-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.563rem;

  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-700);
`;

export const Tbody = styled.tbody`
  width: 100%;

  .body-row {
    height: 1.563rem;
  }

  tr:nth-child(odd) {
    background: var(--gray-100);
  }

  tr:nth-child(even) {
    background: var(--gray-50);
  }
`;

export const BodyCell = styled.td`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .checkbox-cell {
    width: 0.938rem;
    height: 0.938rem;
    cursor: default;
  }
`;

export const QueueInput = styled.input<QueueInputProps>`
  width: 90%;
  height: 1.3rem;

  background: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;

  ${props =>
    props.verifyQueueName
      ? css`
          border-radius: 0.25rem;
          border: 1px solid var(--blue-700);
          cursor: text;
        `
      : css`
          border: none;
          cursor: default;
        `}
`;

export const ButtonDelete = styled.button<ButtonsProps>`
  height: 1.125rem;
  width: 1.125rem;

  img {
    height: 1.125rem;
    width: 1.125rem;
  }

  border: none;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const ButtonEdit = styled.button<ButtonsProps>`
  height: 1.125rem;
  width: 1.125rem;

  background: var(--blue-600);
  border: none;
  border-radius: 0.313rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const CreateQueueButton = styled.button<ButtonsProps>`
  width: 15rem;
  height: 2.75rem;
  color: var(--white);

  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;

  background: var(--green-600);
  border-radius: 0.5rem;
  border: none;
  margin-top: 1.938rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const ButtonSave = styled.button`
  img {
    height: 1.125rem;
    width: 1.125rem;
  }

  border: none;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 25%);
  justify-content: center;
  margin-bottom: ${pxToRem(30)};
  gap: 1.125rem;
`;

export const CheckboxContainer = styled.div`
  gap: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Checkbox = styled.input`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};

  cursor: pointer;
`;

export const LabelCheckbox = styled.label`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  color: var(--blue-700);
`;
