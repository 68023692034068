import { IHubspotDeal } from '../../dtos/IHubspotDeal';
import { IResponse } from '../../dtos/IResponse';
import { Form, Div, Label, Input } from '../../styles';

interface IProps {
  deal: IHubspotDeal;
  userProfile: string;
  returnValues: IResponse;
  brokerClient: boolean;
}
export const BaseValues = ({
  deal,
  userProfile,
  returnValues,
  brokerClient,
}: IProps): JSX.Element => {
  return (
    <Form>
      <Div className="container">
        <Div className="two">
          <Label>
            Data base
            <Input
              id="date-base"
              name="dateBase"
              value={deal.date_base}
              disabled
            />
          </Label>
          <Label>
            Prazo de recebimento
            <Input
              id="number"
              name="number"
              value={deal.receipt_deadline}
              disabled
            />
          </Label>
        </Div>
        <Div className="two">
          <Label>
            Número de meses
            <Input
              id="number"
              name="number"
              value={deal.number_of_months}
              disabled
            />
          </Label>

          <Label>
            Valor ofício expedido
            <Input
              id="valor-oficio"
              name="valueIssue"
              value={deal.value_currency}
              disabled
            />
          </Label>
        </Div>
        <Div className="two">
          <Label>
            Valor LOA
            <Input
              id="main-value"
              name="mainValue"
              value={deal.loa_value}
              disabled
            />
          </Label>
          {userProfile !== 'Hunter New' &&
            userProfile !== 'Hunter Líder' &&
            userProfile !== 'Hunter Teste' &&
            userProfile !== 'Gestores Hunter New' &&
            !brokerClient && (
              <Label>
                Tabela
                <Input
                  id="proposal_variation"
                  name="proposal_variation"
                  setColor={
                    deal &&
                    deal.system_of_process &&
                    (deal.system_of_process.toUpperCase() === 'FÍSICO' ||
                      deal.system_of_process.toUpperCase() ===
                        'FÍSICO SEM OFÍCIO' ||
                      deal.system_of_process.toUpperCase() === 'MATURAÇÃO' ||
                      deal.system_of_process.toUpperCase() === 'DILIGENTE')
                  }
                  value={
                    returnValues.proposalVariation || deal.proposalVariation
                  }
                />
              </Label>
            )}
        </Div>
      </Div>
    </Form>
  );
};
