import styled, { css } from 'styled-components';

interface StepItemProps {
  active?: boolean;
}

export const TableContainer = styled.table`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 50rem;

  overflow: auto;

  margin-top: 2rem;
`;

export const InputSearch = styled.input`
  width: 25rem;

  background: #d8d8d8;
  border-radius: 1.875rem;

  margin-top: 2rem;

  height: 3.25rem;
  padding: 0 1.5rem;
  border: 0.125rem solid #fff;
  font-size: 1rem;
  &::placeholder {
    color: #a8a8b3;
  }
  @media (max-width: 1290px) {
    width: 18rem;
  }
  @media print {
    display: none;
  }
`;

export const HeadContainer = styled.thead`
  width: 100%;
  height: 2.375rem;

  background: var(--blue-100);
  border-radius: 0.25rem;
`;

export const RowBody = styled.tr`
  margin-bottom: 0.25rem;

  width: 100%;
  height: 100%;
  transition: 0.2s;
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-template-columns: 9% 8% 8% 15% 12% 10% 12% 24%;

  width: 100%;
  height: 100%;

  &:nth-child(odd) {
    background: var(--gray-100);
  }
  &:nth-child(even) {
    background: var(--gray-50);
  }
  &:nth-child(1) {
    margin-top: 0.25rem;
  }
`;

export const RowContainer = styled.tr`
  display: grid;
  align-items: center;
  grid-template-columns: 9% 8% 8% 15% 12% 10% 12% 24%;
  width: 100%;
  height: 100%;

  th:nth-child(1) {
    border-right: 1px solid var(--gray-500);
  }

  th:nth-child(2) {
    border-right: 1px solid var(--gray-500);
  }

  th:nth-child(3) {
    border-right: 1px solid var(--gray-500);
  }

  th:nth-child(4) {
    border-right: 1px solid var(--gray-500);
  }

  th:nth-child(5) {
    border-right: 1px solid var(--gray-500);
  }
  th:nth-child(6) {
    border-right: 1px solid var(--gray-500);
  }
  th:nth-child(7) {
    border-right: 1px solid var(--gray-500);
  }
`;

export const ColumnHeadContainer = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 0.813rem;
  color: var(--blue-800);

  height: 1.875rem;
`;

export const BodyContainer = styled.tbody`
  width: 100%;
  height: 2.375rem;

  border-radius: 0.125rem;

  .row-props-body {
    height: 5.5rem;
  }

  .header {
    height: 2.375rem;
  }
`;

export const ColumnBodyContainer = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;

  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--gray-800);
  height: 100%;
`;

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerStep = styled.div`
  display: flex;

  margin-top: 1rem;

  justify-content: space-around;
`;

export const StepItem = styled.button<StepItemProps>`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 3rem;
  width: 7.6875rem;

  font-size: 1rem;
  font-weight: 600;

  border: 0;
  border-radius: 0.375rem;

  ${props =>
    props.active
      ? css`
          background: var(--blue-700);
          color: var(--gray-100);
        `
      : css`
          background: var(--gray-100);
          color: var(--blue-700);
        `}
`;
