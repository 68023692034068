import Swal from 'sweetalert2';

export async function confirmModal(title: string): Promise<boolean> {
  return Swal.fire({
    title,
    showDenyButton: true,
    confirmButtonText: `Confirmar`,
    denyButtonText: `Cancelar`,
  }).then(result => {
    if (result.isConfirmed) {
      return true;
    }
    return false;
  });
}
