import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Swal from 'sweetalert2';
import apiChatbot from '../../../services/apiChatbot';

import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';

import {
  StateDataContainer,
  TitleStateData,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  Option,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
  FiltersContainer,
  FormFilterInput,
  SubmitButton,
  TextLabel,
  FormFilterSelect,
  BackButtonContainer,
  Icon,
} from './styles';
import formatDateWithHours from '../../../utils/formatDateWithHours';
import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';

export interface IStateDebit {
  id: string;
  state: string;
  expedition_year: string;
  expedition_in_year: string;
  payment_year: string;
  total_accumulated_debt: string;
  updated_at: Date;
  created_at: Date;
}

export const StateDebit: React.FC = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [state, setState] = useState<string>(undefined);
  const [expeditionYear, setExpeditionYear] = useState<string>(undefined);
  const [expeditionInYear, setExpeditionInYear] = useState<string>(undefined);
  const [paymentYear, setPaymentYear] = useState<string>(undefined);
  const [totalAccumulatedDebt, setTotalAccumulatedDebt] =
    useState<string>(undefined);

  const [statesDatas, setStatesDatas] = useState<IStateDebit[]>([]);
  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<IStateDebit[]>(
    [] as IStateDebit[],
  );

  const states = [
    { id: '1', label: 'Acre', value: 'Acre' },
    { id: '2', label: 'Alagoas', value: 'Alagoas' },
    { id: '3', label: 'Amapá', value: 'Amapá' },
    { id: '4', label: 'Amazonas', value: 'Amazonas' },
    { id: '5', label: 'Bahia', value: 'Bahia' },
    { id: '6', label: 'Ceara', value: 'Ceara' },
    { id: '7', label: 'Distrito Federal', value: 'Distrito Federal' },
    { id: '8', label: 'Espirito Santo', value: 'Espirito Santo' },
    { id: '9', label: 'Goiás', value: 'Goiás' },
    { id: '10', label: 'Maranhão', value: 'Maranhão' },
    { id: '11', label: 'Mato Grosso', value: 'Mato Grosso' },
    { id: '12', label: 'Mato Grosso do Sul', value: 'Mato Grosso do Sul' },
    { id: '13', label: 'Minas Gerais', value: 'Minas Gerais' },
    { id: '14', label: 'Pará', value: 'Pará' },
    { id: '15', label: 'Paraíba', value: 'Paraíba' },
    { id: '16', label: 'Paraná', value: 'Paraná' },
    { id: '17', label: 'Pernambuco', value: 'Pernambuco' },
    { id: '18', label: 'Piauí', value: 'Piauí' },
    { id: '19', label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
    { id: '20', label: 'Rio Grande do Norte', value: 'Rio Grande do Norte' },
    { id: '21', label: 'Rio Grande do Sul', value: 'Rio Grande do Sul' },
    { id: '22', label: 'Rondônia', value: 'Rondônia' },
    { id: '23', label: 'Roraima', value: 'Roraima' },
    { id: '24', label: 'Santa Catarina', value: 'Santa Catarina' },
    { id: '25', label: 'São Paulo', value: 'São Paulo' },
    { id: '26', label: 'Sergipe', value: 'Sergipe' },
    { id: '27', label: 'Tocantins', value: 'Tocantins' },
  ];

  const currencyFormater = new Intl.NumberFormat('pt-Br', {
    currency: 'BRL',
    style: 'currency',
  });

  const responseSuccess = useCallback(async () => {
    Swal.fire('Sucesso!', 'Dados atualizados com sucesso', 'success').then();
  }, []);

  const responseError = useCallback(async () => {
    Swal.fire('Erro!', 'Falha ao atualizar os dados.', 'error');
  }, []);

  useEffect(() => {
    const api = apiChatbot(localStorage.getItem('@Precato:token'));

    api.get('/lawyer_processes/state-debit').then(response => {
      setStatesDatas(response.data);
      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, [status]);

  useEffect(() => {
    if (
      expeditionInYear &&
      expeditionYear &&
      paymentYear &&
      state &&
      totalAccumulatedDebt
    ) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [
    expeditionInYear,
    expeditionYear,
    paymentYear,
    state,
    totalAccumulatedDebt,
  ]);

  const handleSubmitFilters = useCallback(() => {
    const api = apiChatbot(localStorage.getItem('@Precato:token'));

    api
      .post('/lawyer_processes/state-debit', {
        expedition_in_year: expeditionInYear,
        expedition_year: expeditionYear,
        payment_year: paymentYear,
        state,
        total_accumulated_debt: totalAccumulatedDebt,
      })
      .then(response => {
        if (response.status === 201) {
          setStatus(!status);

          responseSuccess();
        } else {
          responseError();
        }
      });
  }, [
    expeditionInYear,
    expeditionYear,
    paymentYear,
    responseError,
    responseSuccess,
    state,
    status,
    totalAccumulatedDebt,
  ]);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: IStateDebit[] = statesDatas;

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [page, totalPages, statesDatas],
  );

  return (
    <Container>
      <NavigationBar
        past_screens="Newsletter > "
        active_screen="Acompanhamentos"
      />

      <Body>
        <Title name="Configurações de Dívida" />
        <BackButtonContainer>
          <Link to="/newsletter/lawyers-processes">
            <Icon>
              <AiOutlineArrowLeft />
            </Icon>
            Voltar
          </Link>
        </BackButtonContainer>
        <>
          <TextLabel>Insira os dados requeridos:</TextLabel>
          <FiltersContainer>
            <FormFilterSelect
              name="Estado"
              id="Estado"
              onChange={e => setState(e.target.value)}
            >
              <Option value="Selecione um estado" selected disabled>
                Selecione um estado
              </Option>
              {states.map(stateItem => (
                <Option key={stateItem.id} value={stateItem.value}>
                  {stateItem.label}
                </Option>
              ))}
            </FormFilterSelect>

            <FormFilterInput
              name="Ano de expedição"
              id="Ano de expedição"
              type="input"
              placeholder="Ano de expedição"
              maxLength={4}
              onChange={e => setExpeditionYear(e.target.value)}
            />

            <FormFilterInput
              name="Valor expedido"
              id="Valor expedido"
              type="input"
              placeholder="Valor expedido"
              onChange={e => setExpeditionInYear(e.target.value)}
            />

            <FormFilterInput
              name="Ano Pagamento"
              id="Ano Pagamento"
              type="input"
              maxLength={4}
              placeholder="Ano Pagamento"
              onChange={e => setPaymentYear(e.target.value)}
            />

            <FormFilterInput
              name="Dívida total"
              id="Dívida total"
              type="input"
              placeholder="Dívida total"
              onChange={e => setTotalAccumulatedDebt(e.target.value)}
            />

            <SubmitButton
              name="submit"
              onClick={handleSubmitFilters}
              isEnabled={isEnabled}
            >
              Ok
            </SubmitButton>
          </FiltersContainer>
          <StateDataContainer>
            <TitleStateData>Dívida por estado</TitleStateData>
          </StateDataContainer>

          <Table>
            <Thead>
              <TableRowHead>
                <CellHeader>
                  <TitleTable>Estado</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Ano expedição</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Valor expedido</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Ano pagamento</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Dívida total</TitleTable>
                  <Divisor />
                </CellHeader>
                <CellHeader>
                  <TitleTable>Ultima atualização</TitleTable>
                </CellHeader>
              </TableRowHead>
            </Thead>
            <Tbody>
              {currentItems.length > 0 &&
                currentItems.map(stateData => (
                  <TableRowBody key={stateData.id}>
                    <CellBody>{stateData.state}</CellBody>
                    <CellBody>{stateData.expedition_year}</CellBody>
                    <CellBody>
                      {currencyFormater.format(
                        Number(stateData.expedition_in_year),
                      )}
                    </CellBody>
                    <CellBody>{stateData.payment_year}</CellBody>
                    <CellBody>
                      {currencyFormater.format(
                        Number(stateData.total_accumulated_debt),
                      )}
                    </CellBody>
                    <CellBody>
                      {formatDateWithHours(stateData.updated_at)}
                    </CellBody>
                  </TableRowBody>
                ))}
            </Tbody>
          </Table>
          <PaginationComponent
            totalPages={totalPages}
            page={page}
            handleChangePagination={handleChangePagination}
          />
        </>
      </Body>
    </Container>
  );
};
