import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100vw;
  height: 100vh;
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  width: 45%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: -0.015em;

    color: #1665d8;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Line = styled.div`
  position: absolute;
  width: 0.313rem;
  height: 4rem;

  background: linear-gradient(
    179.93deg,
    rgba(6, 57, 102, 0.81) 0.25%,
    rgba(103, 186, 153, 0.81) 100.19%
  );
  border-radius: 5.938rem;
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 2rem;

  @media (max-width: 800px) {
    align-items: center;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;

  @media (max-width: 800px) {
    margin-left: 0rem;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 400px) {
    justify-content: center;
    align-items: center;
    padding: 0rem;
  }
`;

export const Label = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.813rem;
  line-height: 1.188rem;
  letter-spacing: -0.015em;

  color: #05233e;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  text-align: center;

  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const TitleHeader = styled.h1`
  width: 10rem;
  background: #d1ebe1;
  border-radius: 0.25rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.875rem;

  letter-spacing: -0.015em;

  color: #05233e;
`;

export const Input = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #67ba99;
  width: 21rem;
  padding: 0.25rem;
  transition: border 0.2s;
  margin-bottom: 0.5rem;

  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 0.75rem;

  &:focus {
    border: 2px solid #2e9afe;
  }

  @media (max-width: 930px) {
    width: 18rem;
  }

  @media (max-width: 400px) {
    width: 12rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 3rem;

  margin-bottom: 0.625rem;

  .select {
    width: 21rem;
    padding: 0.2rem;
    border: 1px solid #67ba99;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 930px) {
      width: 18rem;
    }

    @media (max-width: 400px) {
      width: 12rem;
    }
  }

  @media (max-width: 400px) {
    margin-right: 0rem;
  }
`;

export const ButtonCreate = styled.button`
  width: 7.5rem;
  height: 2rem;
  color: #ffffff;
  border: none;

  padding: 0.7rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
  letter-spacing: -0.015em;

  letter-spacing: -0.015em;
  background: #089b63;
  border-radius: 0.25rem;

  color: #ffffff;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.3, '#089B63')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;

export const ButtonsContainerEditTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
  padding-bottom: 1rem;

  @media (max-width: 930px) {
    justify-content: center;
    border: none;
  }
`;

export const P = styled.p`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  text-align: center;
  letter-spacing: -0.015em;
  line-height: 1.875rem;

  margin-bottom: 1rem;

  color: #05233e;
`;
