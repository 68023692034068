import { useCallback, useEffect, useState } from 'react';

import Loader from 'react-loader-spinner';
import { ReactComponent as ArrowBlue } from '../../../assets/icons/arrow_blue.svg';

import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';

import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import api from '../../../services/api';
import { formatAmount } from '../../../utils/formatAmount';
import { transformLettersCreditorName } from '../../../utils/transformLettersCreditorName';

import { TitleSla } from '../../../components/TitleSla';
import {
  BackButtonContainer,
  ButtonTeam,
  CellContainer,
  ContainerLoader,
  ContentTable,
  FilterByTeam,
  HubspotLink,
  Icon,
  Info,
  Legend,
  LineSeparator,
  FilterContent,
  RowTable,
  Select,
  TitleBackButtonContainer,
  TitleContainer,
  FiltersContainer,
} from './styles';

import { ModalSLAAnswers } from '../AnswersModal';

interface IReportItem {
  closer_name: string;
  deal_name: string;
  amount: string;
  hubspot_link: string;
  note: number;
  team: string;
  acted_by: string;
}

export interface IAnswers {
  id: string;
  creditor_plans: string;
  creditor_objection: string;
  creditor_procedures: string;
  authority: boolean;
  has_follow: boolean;
  negociation: boolean;
  note: number;
  created_at: Date;
}

interface TablePanelSlaProps {
  title_page: string;
  setStatePanel: (statePanel: string) => void;
}

export function TablePanelSla({
  title_page,
  setStatePanel,
}: TablePanelSlaProps): JSX.Element {
  const [selectedTeam, setSelectedTeam] = useState('LINHA 1');
  const [rangeNote, setRangeNote] = useState('Todas');
  const [reportItems, setReportItems] = useState<IReportItem[]>(
    [] as IReportItem[],
  );
  const [openClosersModal, setOpenClosersModal] = useState(false);
  const [answers, setAnswers] = useState<IAnswers[]>([] as IAnswers[]);

  const itemsPerPage = 8;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<IReportItem[]>(
    [] as IReportItem[],
  );
  const [currentClosers, setCurrentClosers] = useState<string[]>(
    [] as string[],
  );
  const [closerName, setCloserName] = useState<string>('Todos');

  useEffect(() => {
    try {
      let dealStage: string;

      if (title_page.includes('Conectado')) {
        dealStage = '5101746';
      } else if (title_page.includes('Proposta')) {
        dealStage = '5101748';
      } else if (title_page.includes('Negociação')) {
        dealStage = '6773351';
      } else {
        dealStage = '5101750';
      }

      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      api
        .get<IReportItem[]>(
          `/schedule/list-sla-informations?deal_stage=${dealStage}`,
        )
        .then(response => {
          setReportItems(response.data);

          const filteredItems = response.data.filter(item =>
            item.team ? item.team.toUpperCase() === 'LINHA 1' : true,
          );

          setCurrentItems(filteredItems.slice(0, itemsPerPage));

          const filteredClosers: string[] = [];

          filteredItems.forEach(item => {
            const findCloserName = filteredClosers.find(
              closer => closer === item.closer_name,
            );

            if (!findCloserName) {
              filteredClosers.push(item.closer_name);
            }
          });

          setCurrentClosers(filteredClosers);

          const pages: number[] = [];

          for (
            let index = 1;
            index <= Math.ceil(filteredItems.length / itemsPerPage);
            index++
          ) {
            pages.push(index);
          }
          setTotalPages(pages);

          loader.style.display = 'none';
        });
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';
    }
  }, [title_page]);

  const handleFilterCases = useCallback(
    (team_name: string, range_note: string, closer_name: string) => {
      setSelectedTeam(team_name);
      setRangeNote(range_note);
      setCloserName(closer_name);

      let filteredItems = reportItems.filter(item =>
        item.team ? item.team.toUpperCase() === team_name : false,
      );

      const filteredClosers: string[] = [];

      filteredItems.forEach(item => {
        const findCloserName = filteredClosers.find(
          closer => closer === item.closer_name,
        );

        if (!findCloserName) {
          filteredClosers.push(item.closer_name);
        }
      });

      setCurrentClosers(filteredClosers);

      if (range_note === '15 a 35') {
        filteredItems = filteredItems.filter(
          item => item.note >= 15 && item.note <= 35,
        );
      }

      if (range_note === '10 a 15') {
        filteredItems = filteredItems.filter(
          item => item.note >= 10 && item.note <= 15,
        );
      }

      if (range_note === '6 a 10') {
        filteredItems = filteredItems.filter(
          item => item.note >= 6 && item.note <= 10,
        );
      }

      if (range_note === '3 a 6') {
        filteredItems = filteredItems.filter(
          item => item.note >= 3 && item.note <= 6,
        );
      }

      if (range_note === '0 a 3') {
        filteredItems = filteredItems.filter(
          item => item.note >= 0 && item.note <= 3,
        );
      }

      if (closer_name !== 'Todos') {
        filteredItems = filteredItems.filter(
          item => item.closer_name === closer_name,
        );
      }

      setCurrentItems(filteredItems.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(filteredItems.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);

      return filteredItems;
    },
    [reportItems],
  );

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: IReportItem[] = handleFilterCases(
        selectedTeam,
        rangeNote,
        closerName,
      );

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(items.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }

      setTotalPages(pages);

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [handleFilterCases, selectedTeam, rangeNote, closerName, page, totalPages],
  );

  const handleOpenQuestionsModal = useCallback((hubspotDealId: string) => {
    api
      .get(`/schedule/list-all-sla-informations/${hubspotDealId}`)
      .then(response => {
        setAnswers(response.data);
      });

    setOpenClosersModal(true);
  }, []);

  const handleCloseClosersModal = useCallback(() => {
    setOpenClosersModal(false);
  }, []);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'}</H1Navigation>
        <H1Navigation>Painel SLA {'>'}</H1Navigation>
        <H1NavigationNow>{title_page}</H1NavigationNow>
      </Navegation>
      <Body>
        <ContainerLoader id="loader">
          <Loader type="Oval" color="#05233e" height={50} width={50} />
        </ContainerLoader>

        <TitleBackButtonContainer>
          <BackButtonContainer onClick={() => setStatePanel('Inicial')}>
            <Icon>
              <ArrowBlue />
            </Icon>
            Voltar
          </BackButtonContainer>

          <TitleContainer>
            <TitleSla
              name="Relatório - "
              nameStrong={title_page}
              isShared={false}
              separatorWidth={0}
            />
          </TitleContainer>
        </TitleBackButtonContainer>

        <FilterByTeam>
          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'LINHA 1'}
            onClick={() => handleFilterCases('LINHA 1', rangeNote, closerName)}
          >
            Linha 1
          </ButtonTeam>

          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'LINHA 2'}
            onClick={() => handleFilterCases('LINHA 2', rangeNote, closerName)}
          >
            Linha 2
          </ButtonTeam>
          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'LINHA 3'}
            onClick={() => handleFilterCases('LINHA 3', rangeNote, closerName)}
          >
            Linha 3
          </ButtonTeam>
          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'LINHA 4'}
            onClick={() => handleFilterCases('LINHA 4', rangeNote, closerName)}
          >
            Linha 4
          </ButtonTeam>
          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'LINHA 5'}
            onClick={() => handleFilterCases('LINHA 5', rangeNote, closerName)}
          >
            Linha 5
          </ButtonTeam>
          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'LINHA 6'}
            onClick={() => handleFilterCases('LINHA 6', rangeNote, closerName)}
          >
            Linha 6
          </ButtonTeam>
        </FilterByTeam>

        <FiltersContainer>
          <FilterContent>
            <span>Notas</span>
            <Select
              onChange={e =>
                handleFilterCases(selectedTeam, e.target.value, closerName)
              }
            >
              <option value="Todas">Todas</option>
              <option value="15 a 35">15 a 35</option>
              <option value="10 a 15">10 a 15</option>
              <option value="6 a 10">6 a 10</option>
              <option value="3 a 6">3 a 6</option>
              <option value="0 a 3">0 a 3</option>
            </Select>
          </FilterContent>

          <FilterContent>
            <span>Closer</span>
            <Select
              onChange={e =>
                handleFilterCases(selectedTeam, rangeNote, e.target.value)
              }
            >
              <option value="Todos">Todos</option>
              {currentClosers.map(closer => (
                <option key={closer} value={closer}>
                  {closer}
                </option>
              ))}
            </Select>
          </FilterContent>
        </FiltersContainer>

        <ContentTable>
          {currentItems &&
            currentItems.map(reportItem => (
              <RowTable
                key={reportItem.hubspot_link}
                onClick={() =>
                  handleOpenQuestionsModal(reportItem.hubspot_link)
                }
                hasActedBy={!!reportItem.acted_by}
              >
                <CellContainer className="closer">
                  <Legend>Closer:</Legend>
                  <Info>{reportItem.closer_name}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="value">
                  <Legend>Valor:</Legend>
                  <Info>{formatAmount(Number(reportItem.amount))}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="credor">
                  <Legend>Credor:</Legend>
                  <Info>
                    {transformLettersCreditorName(reportItem.deal_name)}
                  </Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="hubspot">
                  <Legend>Link Hubspot:</Legend>
                  <Info>
                    <HubspotLink
                      href={`https://app.hubspot.com/contacts/7857327/deal/${reportItem.hubspot_link}`}
                      target="_blank"
                    >
                      https://app.hubspot.com...
                    </HubspotLink>
                  </Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="acted_by">
                  <Legend>Atuado por:</Legend>
                  <Info>
                    {reportItem.acted_by
                      ? reportItem.acted_by
                          .toLowerCase()
                          .split(' ')
                          .map(
                            string =>
                              string.charAt(0).toUpperCase() +
                              string.substring(1),
                          )
                          .join(' ')
                      : ''}
                  </Info>
                </CellContainer>
              </RowTable>
            ))}
        </ContentTable>

        {reportItems.length >= 8 && (
          <PaginationComponent
            totalPages={totalPages}
            page={page}
            handleChangePagination={handleChangePagination}
          />
        )}

        <ModalSLAAnswers
          isOpen={openClosersModal}
          onRequestClose={handleCloseClosersModal}
          answers={answers}
        />
      </Body>
    </Container>
  );
}
