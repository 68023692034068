import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { format, addDays } from 'date-fns';

import { AiOutlinePlus } from 'react-icons/ai';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { ScheduleHunterProvider } from '../../../hooks/useHunterSchedule';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Body } from '../../../components/Styles/Body';
import { Container } from '../../../components/Container';
import { Title } from '../../../components/Styles/Title';
import { DropdownCreateActivity } from './DropdownCreateActivity';
import { ScheduleOptionsButton } from '../../../components/ScheduleOptionsButton';
import { ScheduleHunterContainer } from '../components/ScheduleHunterContainer';
import { ActivitisHunterToCloserComponent } from '../components/ActivitiesHunterToCloserComponent';

import {
  DivSeparator,
  Separator,
  Main,
  ButtonsDateContainer,
  ButtonCreate,
  IconCreate,
  CreateActivityContainerActivity,
} from './styles';
import { NutriticionCases } from './NutritionCases';

interface IActivity {
  id: string;
  name: string;
  closer_name?: string;
  closer_avatar?: string;
  amount: string;
  contact_preference?: string;
  connection_method?: string;
  status: string;
  try_times?: number;
  talk?: string;
  dealname: string;
  hubspot_deal_id: string;
  business_situation?: string;
  timestamp: Date;
  deleted?: boolean;
}

interface IDaySelected {
  daySelected: string;
}

export function ScheduleHunter(): JSX.Element {
  const [date, setDate] = useState(new Date());
  const { user } = useAuth();

  const location = useLocation();
  const locationDaySelected = location.state as IDaySelected;

  const [chooseDay, setChooseDay] = useState<string[]>([]);
  const [selectedDay, setSelectedDay] = useState<string>(
    locationDaySelected?.daySelected || 'Hoje',
  );
  const [schedulesByDay, setSchedulesByDay] = useState<IActivity[]>(
    [] as IActivity[],
  );

  useEffect(() => {
    api
      .get(
        `schedule/list-hunter-schedulings?hunter_id=${user.id}&date=${format(
          date,
          'yyyy-MM-dd',
        )}`,
      )
      .then(response => {
        setSchedulesByDay(response.data);
      });
  }, [user, date]);

  useEffect(() => {
    const newDate = new Date();

    const friday = newDate.getDay() === 5;

    if (friday) {
      setChooseDay(['Hoje', 'Segunda-feira', 'Nutrição']);
    } else {
      setChooseDay(['Hoje', 'Amanhã', 'Nutrição']);
    }
  }, [user.id, user.permissions]);

  const handleSelecteDay = useCallback(
    (day: string) => {
      if (day === 'Hoje' && schedulesByDay) {
        setDate(new Date());
      } else if (day === 'Amanhã') {
        setDate(addDays(new Date(), 1));
      } else {
        setDate(addDays(new Date(), 3));
      }

      setSelectedDay(day);
    },
    [schedulesByDay],
  );

  return (
    <ScheduleHunterProvider date={date} user_id={user.id}>
      <Container>
        <Navegation>
          <H1Navigation>Negociação {'>'} </H1Navigation>
          <H1Navigation>Agenda {'>'} </H1Navigation>
          <H1NavigationNow>Hunter</H1NavigationNow>
        </Navegation>
        <Body>
          <Title>Agenda</Title>
          <DivSeparator>
            <Separator />
          </DivSeparator>

          <CreateActivityContainerActivity>
            {user.profile === 'Hunter New' ||
            user.profile === 'Hunter Líder' ||
            user.profile === 'Hunter Teste' ||
            user.profile === 'Gestores Hunter New' ? (
              <DropdownCreateActivity profile={user.profile} />
            ) : (
              <Link to="/negociations/schedule/hunter/create">
                <ButtonCreate type="button">
                  <IconCreate>
                    <AiOutlinePlus />
                  </IconCreate>
                  Criar agendamento
                </ButtonCreate>
              </Link>
            )}
          </CreateActivityContainerActivity>

          <ButtonsDateContainer manyButtons={chooseDay.length === 2 ? 2 : 3}>
            <div className="buttons">
              {chooseDay &&
                chooseDay.map(day => (
                  <ScheduleOptionsButton
                    key={day}
                    selectedValue={selectedDay === day}
                    weekdaySchedule
                    type="button"
                    onClick={() => handleSelecteDay(day)}
                  >
                    {day}
                  </ScheduleOptionsButton>
                ))}
            </div>
          </ButtonsDateContainer>

          <Main>
            {selectedDay === 'Nutrição' ? (
              <NutriticionCases hunterId={user.id} />
            ) : (
              <>
                {' '}
                <ScheduleHunterContainer date={date} />
                <ActivitisHunterToCloserComponent
                  activitiesToCloser={schedulesByDay}
                />
              </>
            )}
          </Main>
        </Body>
      </Container>
    </ScheduleHunterProvider>
  );
}
