import React, { ChangeEvent, useEffect, useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TiCancel } from 'react-icons/ti';
import { FiPlus } from 'react-icons/fi';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Container } from '../../../../components/Container';
import {
  DivSeparator,
  ReturnContainer,
  Separator,
  ButtonsContainer,
  CancelButtonContainer,
  CreateButtonContainer,
  Div,
  Label,
  Input,
  CustomerTitleContainer,
  CustomerContainer,
} from './styles';

import api from '../../../../services/apiCustomerSuccess';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';
import { Body } from '../../../../components/Styles/Body';
import { Title } from '../../../../components/Styles/Title';

interface Debitor {
  id: string;
  name: string;
  cnpj: string;
  cep: string;
  street: string;
  active_type: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  uf: string;
  created_at: Date;
}

interface Liquidation {
  customer_name: string;
  cpf_cnpj: string;
  process_number: string;
  nominal_value: string;
  liquidation_type: string;
  liquidation_value: string;
  liquidation_date: string;
  residual_value: string;
  debitor_name: string;
  debitor_cpf_cnpj: string;
  debitor_id: string;
}

interface LocationProps {
  id: string;
}

const EditLiquidation: React.FC = () => {
  const [liquidation, setLiquidation] = useState<Liquidation>({
    customer_name: '',
    cpf_cnpj: '',
    process_number: '',
    nominal_value: '',
    liquidation_type: '',
    liquidation_value: '',
    liquidation_date: '',
    residual_value: '',
    debitor_name: '',
    debitor_cpf_cnpj: '',
    debitor_id: '',
  });
  const [debitors, setDebitors] = useState<Debitor[]>([]);

  const history = useHistory();

  const location = useLocation();
  const liquidationProps = location.state as LocationProps;

  useEffect(() => {
    api.get(`liquidations/find?id=${liquidationProps.id}`).then(response => {
      setLiquidation(response.data);
    });

    api.get('debitors').then(response => {
      setDebitors(response.data);
    });
  }, [liquidationProps.id]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setLiquidation({ ...liquidation, [name]: value });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setLiquidation({ ...liquidation, [name]: value });
  };

  const handleDebitorChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    const debitor = debitors.find(findDebitor => findDebitor.id === value);

    if (debitor) {
      setLiquidation({
        ...liquidation,
        debitor_cpf_cnpj: debitor.cnpj,
        debitor_name: debitor.name,
        debitor_id: debitor.id,
      });
    }
  };

  const handleUpdate = useCallback(
    async id => {
      try {
        await api.put(`liquidations/${id}`, liquidation);

        setLiquidation({
          customer_name: '',
          cpf_cnpj: '',
          process_number: '',
          nominal_value: '',
          liquidation_type: '',
          liquidation_value: '',
          liquidation_date: '',
          residual_value: '',
          debitor_name: '',
          debitor_cpf_cnpj: '',
          debitor_id: '',
        });

        history.goBack();
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert('Confira os dados e tente novamente.');
      }
    },
    [liquidation, history],
  );

  const handleCancel = useCallback(() => {
    setLiquidation({
      customer_name: '',
      cpf_cnpj: '',
      process_number: '',
      nominal_value: '',
      liquidation_type: '',
      liquidation_value: '',
      liquidation_date: '',
      residual_value: '',
      debitor_name: '',
      debitor_cpf_cnpj: '',
      debitor_id: '',
    });

    history.goBack();
  }, [history]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>
          Vórtx {'>'} Liquidação {'>'}
        </H1Navigation>
        <H1NavigationNow>Editar Liquidação</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Editar liquidação</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <ReturnContainer>
          <Link to="/vortx/liquidations">
            <AiOutlineArrowLeft size={18} color="#1665D8" />
            Voltar
          </Link>
        </ReturnContainer>

        <Div>
          <CustomerTitleContainer>Dados do credor</CustomerTitleContainer>

          <CustomerContainer>
            <Div>
              <Label htmlFor="customer_name">Nome do credor:</Label>
              <Input
                type="text"
                name="customer_name"
                onChange={handleInputChange}
                value={liquidation.customer_name}
              />
            </Div>

            <Div>
              <Label htmlFor="cpf_cnpj">CPF/CNPJ:</Label>
              <Input
                type="text"
                name="cpf_cnpj"
                onChange={handleInputChange}
                value={liquidation.cpf_cnpj}
              />
            </Div>
          </CustomerContainer>
        </Div>

        <Div>
          <CustomerTitleContainer>Dados de liquidação</CustomerTitleContainer>

          <CustomerContainer>
            <Div>
              <Label htmlFor="process_number">Nº do precatório</Label>
              <Input
                type="text"
                name="process_number"
                onChange={handleInputChange}
                value={liquidation.process_number}
              />
            </Div>

            <Div>
              <Label htmlFor="nominal_value">Valor nominal:</Label>
              <Input
                type="text"
                name="nominal_value"
                onChange={handleInputChange}
                value={liquidation.nominal_value}
              />
            </Div>

            <Div>
              <Label htmlFor="liquidation_type">Forma liquidação</Label>
              <select
                name="liquidation_type"
                onChange={handleSelectChange}
                value={liquidation.liquidation_type}
              >
                <option>Selecione uma opção</option>
                <option value="03">Parcial</option>
                <option value="01">Total</option>
              </select>
            </Div>

            <Div>
              <Label htmlFor="liquidation_date">Data liquidação</Label>
              <Input
                type="date"
                name="liquidation_date"
                onChange={handleInputChange}
                value={liquidation.liquidation_date}
              />
            </Div>

            <Div>
              <Label htmlFor="liquidation_value">Valor liquidação</Label>
              <Input
                type="text"
                name="liquidation_value"
                onChange={handleInputChange}
                value={liquidation.liquidation_value}
              />
            </Div>

            <Div>
              <Label htmlFor="residual_value">Valor residual</Label>
              <Input
                placeholder="Digite apenas se for maior que 0.00"
                name="residual_value"
                onChange={handleInputChange}
                value={liquidation.residual_value}
              />
            </Div>
          </CustomerContainer>
        </Div>

        <Div>
          <CustomerTitleContainer>Dados do sacado</CustomerTitleContainer>

          <CustomerContainer>
            <Div>
              <Label htmlFor="debitor_name">Sacado:</Label>
              <select
                name="debitor_name"
                onChange={handleDebitorChange}
                value={liquidation.debitor_id}
              >
                <option>Selecione uma opção</option>
                {debitors.length > 0 &&
                  debitors.map(debitor => (
                    <option value={debitor.id}>{debitor.name}</option>
                  ))}
              </select>
            </Div>
          </CustomerContainer>
        </Div>

        <ButtonsContainer>
          <CancelButtonContainer>
            <button type="button" onClick={handleCancel}>
              <TiCancel size={20} />
              Cancelar
            </button>
          </CancelButtonContainer>

          <CreateButtonContainer>
            <button
              type="button"
              onClick={() => handleUpdate(liquidationProps.id)}
            >
              <FiPlus size={20} />
              Atualizar
            </button>
          </CreateButtonContainer>
        </ButtonsContainer>
      </Body>
    </Container>
  );
};

export { EditLiquidation };
