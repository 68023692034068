interface IHunters {
  hunter_id: string;
  hunter_name: string | undefined;
  hunter_avatar: string | undefined;
  ticket: number;
  schedulings: number;
  team: string;
}

export function orderInfoTableByDesc(
  column: string,
  value: IHunters[],
): IHunters[] {
  const order = value.sort((previous: IHunters, next: IHunters) => {
    switch (column) {
      case 'hunter_name':
        return previous.hunter_name < next.hunter_name
          ? 1
          : previous.hunter_name > next.hunter_name
          ? -1
          : 0;
        break;
      case 'ticket':
        return previous.ticket < next.ticket
          ? 1
          : previous.ticket > next.ticket
          ? -1
          : 0;
        break;
      case 'schedulings':
        return previous.schedulings < next.schedulings
          ? 1
          : previous.schedulings > next.schedulings
          ? -1
          : 0;
        break;
      default:
        return null;
        break;
    }
  });
  return order;
}
