import { Container, TextTitle } from './styles';
import { Separator } from '../Separator';

interface TitleProps {
  name: string;
  isShared?: boolean;
  separatorWidth?: number;
}

export const Title: React.FC<TitleProps> = ({
  name,
  isShared,
  separatorWidth,
}) => {
  return (
    <Container isShared={isShared}>
      <TextTitle>{name}</TextTitle>
      <Separator separatorWidth={separatorWidth} />
    </Container>
  );
};
