export function handleGetPageLink(
  name_page: string,
  profile?: string,
  description?: string,
): string {
  let pageLink = '';

  if (
    name_page === 'Agenda' &&
    (profile === 'Closers' ||
      profile === 'Closer Newsletter' ||
      profile === 'Closer Linha 1')
  ) {
    pageLink = '/negociations/schedule/closer';
  } else if (
    name_page === 'Agenda' &&
    (profile === 'Hunters' ||
      profile === 'Hunter New' ||
      profile === 'Hunter Líder' ||
      profile === 'Hunter Teste' ||
      profile === 'Gestores Hunter New')
  ) {
    pageLink = '/negociations/schedule/hunter';
  } else if (
    name_page === 'Agenda' &&
    (profile === 'Administrador' ||
      profile === 'Gestores' ||
      profile === 'Gestor Linha 1' ||
      profile === 'Gestao Conexao' ||
      profile === 'Gestor Operação Comercial' ||
      profile === 'Gestor Operação Comercial + Leads' ||
      profile === 'Liderança Comercial' ||
      profile === 'Gestor Operação')
  ) {
    pageLink = '/negociations/schedule/manager/history';
  }

  if (
    name_page === 'Agenda' &&
    description === 'Pagamento' &&
    (profile === 'Administrador' ||
      profile === 'Gestor Customer Success' ||
      profile === 'Gestor Operação Comercial + Leads' ||
      profile === 'Gestor Operação Comercial' ||
      profile === 'Gestor Operação' ||
      profile === 'Gestor Linha 1' ||
      profile === 'Gestores')
  ) {
    pageLink = '/payments/schedule/manager/customer-sucess';
  } else if (
    name_page === 'Agenda' &&
    (profile === 'Customer Success' ||
      profile === 'Customer Success Calculator' ||
      profile === 'Customer Sucess Partner Fund') &&
    description === 'Pagamento'
  ) {
    pageLink = '/payments/schedule/customer-success';
  }

  switch (name_page) {
    case 'Priorização':
      pageLink = '/payments/priorization';
      break;

    case 'Configurar Closer':
      pageLink = '/administration/closer-settings';
      break;

    case 'Painel SLA':
      pageLink = '/negociations/panel-sla';
      break;

    case 'Relatório Closer':
      pageLink = '/negociations/report-closer';
      break;

    case 'Perfis':
      pageLink = '/administration/profiles';
      break;

    case 'Permissões':
      pageLink = '/administration/permissions';
      break;

    case 'Times':
      pageLink = '/administration/teams';
      break;

    case 'Queue':
      pageLink = '/administration/closer-queues';
      break;

    case 'Usuários':
      pageLink = '/administration/users';
      break;

    case 'Take blip':
      pageLink = '/connection/take-blip';
      break;

    case 'Retornos SMS':
      pageLink = '/connection/sms-returns';
      break;

    case 'Precificação':
      pageLink = '/pricing';
      break;

    case 'Performance':
      pageLink = '/connection/performance';
      break;

    case 'Disparo WPP':
      pageLink = '/connection/shot-wpp';
      break;

    case 'Disparo RCS':
      pageLink = '/connection/rcs-trigger';
      break;

    case 'Chatbot Flow':
      pageLink = '/connection/attendances/chatbot-flow';
      break;

    case 'Enriquecimento':
      pageLink = '/connection/enrichment';
      break;

    case 'Opt in':
      pageLink = '/connection/opt-in';
      break;

    case 'Auditar Ligações':
      pageLink = '/connection/calls-audit';
      break;

    case 'Relatório':
      pageLink = '/connection/report';
      break;

    case 'Calculadora':
      pageLink = '/negociations/calculator';
      break;
    case 'Calculadora Cliente':
      pageLink = '/negociations/calculator-client-broker';
      break;
    case 'Cálculos':
      pageLink = '/negociations/schedule-listing';
      break;
    case 'Calculadora SELIC':
      pageLink = '/negociations/new/calculator';
      break;

    case 'Painel SLA Hunter':
      pageLink = '/connection/panel-sla-hunter';
      break;

    case 'Agenda':
      break;

    case 'Novo agendamento':
      pageLink = '/negociations/schedule/manager/create-scheduling';
      break;

    case "Cronologia TJ's":
      pageLink = '/leads/tjs-oc';
      break;

    case "Ofícios TJ's":
      pageLink = '/leads/tjs';
      break;

    case "Ofícios TRF's":
      pageLink = '/leads/trfs';
      break;

    case 'Bases TRF':
      pageLink = '/leads/processes';
      break;

    case 'Pesquisa de Processos':
      pageLink = '/leads/search-processes';
      break;

    case 'Cedentes':
      pageLink = '/vortx/assignors';
      break;

    case 'Entes devedores':
      pageLink = '/vortx/debitors';
      break;

    case 'Liquidação':
      pageLink = '/vortx/liquidations';
      break;

    case 'Pagamento':
      pageLink = '/vortx/payments';
      break;

    case "OKR's":
      pageLink = '/okr';
      break;

    case 'Acompanhamentos':
      pageLink = '/newsletter/lawyers-processes';
      break;

    case 'Disparo SMS':
      pageLink = '/connection/sms-trigger';
      break;

    case 'Horário de atendimento':
      pageLink = '/connection/work-time';
      break;

    case 'Configuração WhatsApp':
      pageLink = '/connection/wpp-config';
      break;

    case 'Cessão':
      pageLink = '/assignments';
      break;

    default:
      break;
  }

  return pageLink;
}
