import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import Modal from 'react-modal';

import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import { Title } from '../../../../components/Title';
import { IHunterSLADTO } from '../dtos/IHunterSLADTO';
import { Loading } from '../../../../components/Loading';
import {
  whoContacted,
  connectionMethod,
  motivesForNotAdvancing,
  motivesOfOpportunity,
  influences,
  objections,
  plans,
  profiles,
  reactions,
  doubts,
  processKnowledge,
  proposalExplanation,
  autority,
} from '../utils/noteAmountByInput';

import {
  OpportunityModalStyle,
  Main,
  InputContainer,
  Label,
  Select,
  Input,
  Textarea,
  ButtonSave,
  CheckboxContainer,
  Checkbox,
  LabelCheckbox,
} from './styles';
import { createFirstPassClarificationNote } from '../utils/createClarificationNote';
import {
  contactWithCompetitor,
  customerPriority,
  decisionReview,
  informedNegotiableValue,
  whyDidntAnticipateBefore,
} from '../utils/retakeNoteAmountByInput';
import { createRetakeClarificationNote } from '../utils/createRetakeClarificationNote';
import verifyDealTj from './utils/verifyDealTj';
import { ButtonExit } from '../LostModal/styles';
import { optIn } from '../../components/ModalSLA/utils/optionsSLA';

interface FormData {
  ticket_id: string;
  who_contacted: string;
  valid_contact: string;
  valid_email: string;
  connection_type: string;
  connection_method: string;
  closing_potencial: string;
  reaction: string;
  plans: string;
  profile: string[];
  influence: string;
  objection: string;
  autority: string;
  doubts: string;
  process_knowledge: string;
  proposal_explanation: string;
  observation: string;
  clarification_note: string;
  valid_contact_wpp_opt_in: string;
  value_offered_by_competition: string;
  motive_for_not_advancing: string;
  motive_of_opportunity: string;
  why_didnt_anticipate_before: string;
  customer_priority: string;
  decision_review: string;
  contact_with_competitor: string;
  informed_negotiable_value: string;
  negotiable_value: string;
  opt_in: string;
}

interface IHubspotDeal {
  hs_pipeline: string;
}

interface OpportunityModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function OpportunityModal({
  isOpen,
  onRequestClose,
}: OpportunityModalProps): JSX.Element {
  const { user } = useAuth();

  const [formData, setFormData] = useState<FormData>({
    ...{},
    profile: [],
  } as FormData);

  const [lastSLA, setLastSLA] = useState<IHunterSLADTO>({} as IHunterSLADTO);

  const [ticketDeal, setTicketDeal] = useState<IHubspotDeal>(
    {} as IHubspotDeal,
  );

  const [verifyDeal, setVerifyDeal] = useState<boolean>(true);
  const [selectedConnection, setSelectedConnection] = useState<string>();

  const handleCloseBlockedModal = useCallback(() => {
    setVerifyDeal(true);
    onRequestClose();
  }, [onRequestClose, setVerifyDeal]);

  useEffect(() => {
    if (
      (ticketDeal.hs_pipeline === '2945972' ||
        ticketDeal.hs_pipeline === '9536414') &&
      formData.who_contacted &&
      formData.connection_method &&
      formData.connection_type &&
      formData.reaction &&
      formData.plans &&
      formData.profile.length > 0 &&
      formData.influence &&
      formData.objection
    ) {
      const clarificationNote = createFirstPassClarificationNote({
        name_modal: 'Opportunity',
        hunter_name: user.name,
        who_contacted: formData.who_contacted,
        connection_type: formData.connection_type,
        connection_method: formData.connection_method,
        valid_contact: formData.valid_contact,
        motive_for_not_advancing: formData.motive_for_not_advancing,
        motive_of_opportunity: formData.motive_of_opportunity,
        autority: formData.autority,
        contact_with_competitor: formData.contact_with_competitor,
        reaction: formData.reaction,
        plans: formData.plans,
        doubts: formData.doubts,
        profile: formData.profile.toString(),
        influence: formData.influence,
        objection: formData.objection,
      });

      setFormData(form_data => {
        return { ...form_data, clarification_note: clarificationNote };
      });
    } else if (
      (ticketDeal.hs_pipeline === '2154049' ||
        ticketDeal.hs_pipeline === '9528351') &&
      user.name &&
      formData.who_contacted &&
      formData.connection_method &&
      formData.connection_type &&
      formData.valid_contact &&
      formData.autority &&
      formData.contact_with_competitor &&
      formData.customer_priority &&
      formData.reaction &&
      formData.plans &&
      formData.profile.length > 0 &&
      formData.doubts &&
      formData.why_didnt_anticipate_before &&
      formData.decision_review &&
      formData.informed_negotiable_value &&
      formData.influence &&
      formData.objection
    ) {
      const clarificationNote = createRetakeClarificationNote({
        name_modal: 'Opportunity',
        hunter_name: user.name,
        who_contacted: formData.who_contacted,
        connection_method: formData.connection_method,
        connection_type: formData.connection_type,
        reaction: formData.reaction,
        plans: formData.plans,
        profile: formData.profile.toString(),
        influence: formData.influence,
        objection: formData.objection,
        valid_contact: formData.valid_contact,
        autority: formData.autority,
        contact_with_competitor: formData.contact_with_competitor,
        customer_priority: formData.customer_priority,
        doubts: formData.doubts,
        why_didnt_anticipate_before: formData.why_didnt_anticipate_before,
        decision_review: formData.decision_review,
        informed_negotiable_value: formData.informed_negotiable_value,
        negotiable_value: formData.negotiable_value,
      });

      setFormData(form_data => {
        return { ...form_data, clarification_note: clarificationNote };
      });
    } else {
      setFormData(form_data => {
        return { ...form_data, clarification_note: '' };
      });
    }
  }, [
    formData.who_contacted,
    formData.connection_method,
    formData.connection_type,
    formData.reaction,
    formData.plans,
    formData.profile,
    formData.influence,
    formData.objection,
    ticketDeal.hs_pipeline,
    formData.valid_contact,
    formData.autority,
    formData.contact_with_competitor,
    formData.customer_priority,
    formData.doubts,
    formData.why_didnt_anticipate_before,
    formData.decision_review,
    formData.informed_negotiable_value,
    user.name,
    formData.negotiable_value,
    formData.motive_for_not_advancing,
    formData.motive_of_opportunity,
    formData.observation,
  ]);

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setFormData({
      ...{},
      profile: [],
    } as FormData);
    setLastSLA({} as IHunterSLADTO);
  }, [onRequestClose]);

  const handleLoadLastSLA = useCallback(
    async (ticket_id: string) => {
      const response = await api.get(`/schedule/last-deal-sla/${ticket_id}`);
      if (response.data) {
        setLastSLA(response.data);

        setFormData({
          ...response.data,
          clarification_note: '',
          profile: response.data.profile
            ? response.data.profile.split(',')
            : [],
        });
      } else {
        setFormData({ ...formData, ticket_id });
      }

      const secondResponse = await api.get(
        `/schedule/get-hubspot-deal-info?hubspot_ticket_id=${ticket_id}&connection=${selectedConnection}`,
      );

      const isTicketValid = await verifyDealTj({
        closed_date: secondResponse.data.closed_date,
      });
      setVerifyDeal(isTicketValid);
    },
    [formData, selectedConnection],
  );

  const handleSetTicketDeal = useCallback(async (hubspot_ticket_id: string) => {
    try {
      const { data } = await api.get(`schedule/ticket/${hubspot_ticket_id}`);
      setTicketDeal(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  const handleInputChange = useCallback(
    async (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      if (name === 'ticket_id' && value.length >= 9) {
        await handleLoadLastSLA(value);
        await handleSetTicketDeal(value);
      } else if (
        name === 'Direto' ||
        name === 'Educado' ||
        name === 'Grosso' ||
        name === 'Instruído' ||
        name === 'Simples' ||
        name === 'Conversador'
      ) {
        const index = formData.profile.findIndex(profile => profile === name);

        if (index >= 0) {
          formData.profile.splice(index, 1);
        } else {
          formData.profile.push(name);
        }
        setFormData({ ...formData, [name]: value });
      } else if (name === 'value_offered_by_competition') {
        let valor = value;
        valor = valor.replace(/[\D]+/g, '');

        setFormData({ ...formData, [name]: valor });

        //  valor = valor.replace(/([0-9]{2})$/g, ',$1');
        if (valor.length > 6) {
          valor = valor.replace(
            /([0-9]{1,3})([0-9]{3})([0-9]{3}$)/g,
            '$1.$2.$3',
          );
        } else if (valor.length > 3) {
          valor = valor.replace(/([0-9]{1,3})([0-9]{3}$)/g, '$1.$2');
        }

        valor = `R$ ${valor}`;

        event.target.value = valor;
      } else if (name === 'negotiable_value') {
        let valor = value;
        valor = valor.replace(/[\D]+/g, '');

        setFormData({ ...formData, [name]: valor });

        //  valor = valor.replace(/([0-9]{2})$/g, ',$1');
        if (valor.length > 6) {
          valor = valor.replace(
            /([0-9]{1,3})([0-9]{3})([0-9]{3}$)/g,
            '$1.$2.$3',
          );
        } else if (valor.length > 3) {
          valor = valor.replace(/([0-9]{1,3})([0-9]{3}$)/g, '$1.$2');
        }

        valor = `R$ ${valor}`;

        event.target.value = valor;
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData, handleLoadLastSLA, handleSetTicketDeal],
  );

  const handleSaveSLA = useCallback(async () => {
    try {
      if (formData.ticket_id === '' || isNaN(Number(formData.ticket_id))) {
        Swal.fire('Erro!', 'Ticket ID inválido!', 'error');
      } else {
        const loader = document.getElementById('loader');

        loader.style.display = 'flex';

        const schema = yup.object().shape({
          autority: yup.string().required('Autoridade obrigatório!'),
          ticket_id: yup.string().required('Ticket ID obrigatório!'),
          who_contacted: yup
            .string()
            .required('Com quem conversou obrigatório!'),
          valid_contact: yup.string().required('Contato válido obrigatório!'),
          connection_type: yup
            .string()
            .required('Tipo de conexão obrigatório!'),
          connection_method: yup
            .string()
            .required('Meio de conexão obrigatório!'),
          closing_potencial: yup
            .string()
            .required('Potencial de fechamento obrigatório!'),
          reaction: yup.string().required('Reação obrigatório!'),
          plans: yup.string().required('Planos do credor obrigatório!'),
          profile: yup.array().of(yup.string()).required('Perfil obrigatório!'),
          influence: yup.string().required('Influência obrigatório!'),
          objection: yup.string().required('Objeção obrigatório!'),
          doubts: yup.string().required('Campo dúvidas obrigatório!'),
          motive_for_not_advancing: yup
            .string()
            .required('Motivo de não avançar obrigatório!'),
          motive_of_opportunity: yup
            .string()
            .required('Motivo da oportunidade obrigatório!'),
          observation: yup.string().required('Observação obrigatório!'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const autorityValue = autority.find(
          found_autority => found_autority.label === formData.autority,
        );

        const connectionMethodValue = connectionMethod.find(
          connection_method =>
            connection_method.label === formData.connection_method,
        );

        const motivesForNotAdvancingValue = motivesForNotAdvancing.find(
          motiveForNotAdvancing =>
            motiveForNotAdvancing.label === formData.motive_for_not_advancing,
        );

        const motivesOfOpportunityValue = motivesOfOpportunity.find(
          motiveOfOpportunity =>
            motiveOfOpportunity.label === formData.motive_of_opportunity,
        );

        const reactionValue = reactions.find(
          reaction => reaction.label === formData.reaction,
        );

        const planValue = plans.find(plan => plan.label === formData.plans);

        const influencesValue = influences.find(
          influence => influence.label === formData.influence,
        );

        const objectionValue = objections.find(
          objection => objection.label === formData.objection,
        );

        const filterProfiles = profiles.filter(profile =>
          formData.profile.includes(profile.label),
        );

        const profileValue = filterProfiles.reduce(
          (previous, current) => previous + current.amount,
          0,
        );

        const doubtsValue = doubts.find(
          doubt => doubt.label === formData.doubts,
        );

        const totalAmount =
          autorityValue.amount +
          connectionMethodValue.amount +
          motivesForNotAdvancingValue.amount +
          motivesOfOpportunityValue.amount +
          reactionValue.amount +
          planValue.amount +
          influencesValue.amount +
          objectionValue.amount +
          profileValue +
          doubtsValue.amount;

        if (
          ticketDeal.hs_pipeline === '2945972' ||
          ticketDeal.hs_pipeline === '9536414'
        ) {
          await api.post('/schedule/hunter-sla', {
            name: 'Oportunidade',
            hunter_id: user.id,
            ticket_id: formData.ticket_id,
            who_contacted: formData.who_contacted,
            valid_contact: formData.valid_contact,
            valid_email: formData.valid_email,
            valid_contact_wpp_opt_in: formData.valid_contact_wpp_opt_in,
            connection_type: formData.connection_type,
            connection_method: formData.connection_method,
            closing_potencial: formData.closing_potencial,
            reaction: formData.reaction,
            plans: formData.plans,
            profile: formData.profile.toString(),
            influence: formData.influence,
            objection: formData.objection,
            autority: formData.autority,
            doubts: formData.doubts,
            process_knowledge: formData.process_knowledge,
            proposal_explanation: formData.proposal_explanation,
            value_offered_by_competition: formData.value_offered_by_competition,
            motive_for_not_advancing: formData.motive_for_not_advancing,
            motive_of_opportunity: formData.motive_of_opportunity,
            observation: formData.observation,
            note_amount: totalAmount,
            clarification_note: formData.clarification_note,
            opt_in: formData.opt_in,
          });
        } else {
          await api.post('/schedule/hunter-sla', {
            name: 'Oportunidade',
            hunter_id: user.id,
            ticket_id: formData.ticket_id,
            who_contacted: formData.who_contacted,
            valid_contact: formData.valid_contact,
            valid_email: formData.valid_email,
            valid_contact_wpp_opt_in: formData.valid_contact_wpp_opt_in,
            connection_type: formData.connection_type,
            connection_method: formData.connection_method,
            closing_potencial: formData.closing_potencial,
            reaction: formData.reaction,
            plans: formData.plans,
            profile: formData.profile.toString(),
            influence: formData.influence,
            objection: formData.objection,
            autority: formData.autority,
            doubts: formData.doubts,
            value_offered_by_competition: formData.value_offered_by_competition,
            motive_for_not_advancing: formData.motive_for_not_advancing,
            motive_of_opportunity: formData.motive_of_opportunity,
            observation: formData.observation,
            note_amount: totalAmount,
            clarification_note: formData.clarification_note,
            why_didnt_anticipate_before: formData.why_didnt_anticipate_before,
            decision_review: formData.decision_review,
            informed_negotiable_value: formData.informed_negotiable_value,
            negotiable_value: formData.negotiable_value,
            contact_with_competitor: formData.contact_with_competitor,
            customer_priority: formData.customer_priority,
          });
        }

        toast.success('Oportunidade salva com sucesso!');

        handleCloseModal();
      }
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      if (error instanceof yup.ValidationError) {
        toast.error(
          error.errors.join('\n\n') || 'Preencha todos os campos corretamente.',
        );

        return;
      }

      if (error.message === 'Network Error') {
        Swal.fire('Erro!', 'Não foi possível conectar com o servidor', 'error');
        return;
      }

      if (error.response?.data.status === 404) {
        Swal.fire('Erro!', error.response.data.message, 'error');
        return;
      }

      toast.error('Não foi possível salvar Oportunidade');
    }
  }, [formData, ticketDeal.hs_pipeline, handleCloseModal, user.id]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={OpportunityModalStyle}
    >
      <Title name="Oportunidade" />

      <Loading />

      <Main>
        <InputContainer>
          <Label>ID do ticket:</Label>
          <Input type="number" name="ticket_id" onChange={handleInputChange} />
        </InputContainer>

        {!verifyDeal ? (
          <InputContainer>
            <ButtonExit onClick={handleCloseBlockedModal}>Sair</ButtonExit>
          </InputContainer>
        ) : (
          <>
            <InputContainer>
              <Label>Autoridade?</Label>
              <Select
                name="autority"
                defaultValue={lastSLA.autority || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {autority.map(found_autority => (
                  <option
                    key={found_autority.label}
                    value={found_autority.label}
                    selected={formData.autority === found_autority.label}
                  >
                    {found_autority.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Com quem foi o contato?</Label>
              <Select
                name="who_contacted"
                defaultValue={lastSLA.who_contacted || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {whoContacted.map(who_contacted => (
                  <option
                    key={who_contacted.label}
                    value={who_contacted.label}
                    selected={formData.who_contacted === who_contacted.label}
                  >
                    {who_contacted.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Contato válido (credor):</Label>
              <Input
                type="text"
                name="valid_contact"
                defaultValue={lastSLA.valid_contact || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Contato válido (WPP):</Label>
              <Input
                type="text"
                name="valid_contact_wpp_opt_in"
                defaultValue={lastSLA.valid_contact_wpp_opt_in || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>E-mail válido (credor):</Label>
              <Input
                type="text"
                name="valid_email"
                defaultValue={lastSLA.valid_email || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Tipo de conexão:</Label>
              <Select
                name="connection_type"
                defaultValue={lastSLA.connection_type || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option
                  value="Inbound (0800)"
                  selected={lastSLA.connection_type === 'Inbound (0800)'}
                >
                  Inbound (0800)
                </option>
                <option
                  value="Inbound (Virtual)"
                  selected={lastSLA.connection_type === 'Inbound (Virtual)'}
                >
                  Inbound (Virtual)
                </option>
                <option
                  value="Outbound"
                  selected={lastSLA.connection_type === 'Outbound'}
                >
                  Outbound
                </option>
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Meio de conexão:</Label>
              <Select
                name="connection_method"
                defaultValue={lastSLA.connection_method || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {connectionMethod.map(connection_method => (
                  <option
                    key={connection_method.label}
                    value={connection_method.label}
                    selected={
                      formData.connection_method === connection_method.label
                    }
                  >
                    {connection_method.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Opt in:</Label>
                <Select
                  name="opt_in"
                  defaultValue={formData.opt_in || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {optIn.map(opt_in => (
                    <option
                      key={opt_in.label}
                      value={opt_in.label}
                      selected={formData.opt_in === opt_in.label}
                    >
                      {opt_in.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            <InputContainer>
              <Label>Potencial de fechamento:</Label>
              <Select
                name="closing_potencial"
                defaultValue={lastSLA.closing_potencial || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option
                  value="Alto"
                  selected={lastSLA.closing_potencial === 'Alto'}
                >
                  Alto
                </option>
                <option
                  value="Médio"
                  selected={lastSLA.closing_potencial === 'Médio'}
                >
                  Médio
                </option>
                <option
                  value="Baixo"
                  selected={lastSLA.closing_potencial === 'Baixo'}
                >
                  Baixo
                </option>
              </Select>
            </InputContainer>

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Gerou incerteza no recebimento? Reação?</Label>
                <Select
                  name="reaction"
                  defaultValue={lastSLA.reaction || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option
                    value="Sim, mas adv contradiz"
                    selected={lastSLA.reaction === 'Sim, mas adv contradiz'}
                  >
                    Sim, mas adv contradiz
                  </option>
                  <option
                    value="Sim, ficou incomodado"
                    selected={lastSLA.reaction === 'Sim, ficou incomodado'}
                  >
                    Sim, ficou incomodado
                  </option>
                  <option
                    value="Sim, indiferente"
                    selected={lastSLA.reaction === 'Sim, indiferente'}
                  >
                    Sim, indiferente
                  </option>
                  <option value="Não" selected={lastSLA.reaction === 'Não'}>
                    Não
                  </option>
                </Select>
              </InputContainer>
            ) : (
              <InputContainer>
                <Label>Gerou incerteza no recebimento? Reação?</Label>
                <Select
                  name="reaction"
                  defaultValue={lastSLA.reaction || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option
                    value="Gerei incerteza, mas adv disse que vai receber esse ano"
                    selected={
                      lastSLA.reaction ===
                      'Gerei incerteza, mas adv disse que vai receber esse ano'
                    }
                  >
                    Gerei incerteza, mas adv disse que vai receber esse ano
                  </option>
                  <option
                    value="Gerei incerteza, mas o adv é contra a antecipação"
                    selected={
                      lastSLA.reaction ===
                      'Gerei incerteza, mas o adv é contra a antecipação'
                    }
                  >
                    Gerei incerteza, mas o adv é contra a antecipação
                  </option>
                  <option
                    value="Gerei incerteza, e o credor ficou incomodado"
                    selected={
                      lastSLA.reaction ===
                      'Gerei incerteza, e o credor ficou incomodado'
                    }
                  >
                    Gerei incerteza, e o credor ficou incomodado
                  </option>
                  <option
                    value="Gerei incerteza, e o credor ficou indiferente"
                    selected={
                      lastSLA.reaction ===
                      'Gerei incerteza, e o credor ficou indiferente'
                    }
                  >
                    Gerei incerteza, e o credor ficou indiferente
                  </option>
                  <option
                    value="Não falei sobre incerteza"
                    selected={lastSLA.reaction === 'Não falei sobre incerteza'}
                  >
                    Não falei sobre incerteza
                  </option>
                </Select>
              </InputContainer>
            )}

            <InputContainer>
              <Label>Sobre planos com o recurso:</Label>
              <Select
                name="plans"
                defaultValue={lastSLA.plans || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {plans.map(plan => (
                  <option
                    key={plan.label}
                    value={plan.label}
                    selected={formData.plans === plan.label}
                  >
                    {plan.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Influência:</Label>
                <Select
                  name="influence"
                  defaultValue={lastSLA.influence || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option
                    value="Advogado"
                    selected={lastSLA.reaction === 'Advogado'}
                  >
                    Advogado
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Familiar'}
                    value="Familiar"
                  >
                    Familiar
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Terceiros'}
                    value="Terceiros"
                  >
                    Terceiros
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Concorrência'}
                    value="Concorrência"
                  >
                    Concorrência
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Sem influência'}
                    value="Sem influência"
                  >
                    Sem influência
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Não identificou'}
                    value="Não identificou"
                  >
                    Não identificou
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Não mencionado'}
                    value="Não mencionado"
                  >
                    Não mencionado
                  </option>
                </Select>
              </InputContainer>
            ) : (
              <InputContainer>
                <Label>Influência:</Label>
                <Select
                  name="influence"
                  defaultValue={lastSLA.influence || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option
                    selected={
                      lastSLA.reaction === 'Disse que acompanha tudo com o adv'
                    }
                    value="Disse que acompanha tudo com o adv"
                  >
                    Disse que acompanha tudo com o adv
                  </option>
                  <option
                    selected={
                      lastSLA.reaction ===
                      'Tinha um familiar perto na hora da conversa'
                    }
                    value="Tinha um familiar perto na hora da conversa"
                  >
                    Tinha um familiar perto na hora da conversa
                  </option>
                  <option
                    selected={
                      lastSLA.reaction ===
                      'Mencionou que algum familiar era contra a antecipação'
                    }
                    value="Mencionou que algum familiar era contra a antecipação"
                  >
                    Mencionou que algum familiar era contra a antecipação
                  </option>
                  <option
                    selected={
                      lastSLA.reaction ===
                      'Disse que já ouviu proposta da concorrência'
                    }
                    value="Disse que já ouviu proposta da concorrência"
                  >
                    Disse que já ouviu proposta da concorrência
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Sem influência'}
                    value="Sem influência"
                  >
                    Sem influência
                  </option>
                  <option
                    selected={lastSLA.reaction === 'Não mencionado'}
                    value="Não mencionado"
                  >
                    Não mencionado
                  </option>
                </Select>
              </InputContainer>
            )}

            <InputContainer>
              <Label>Objeção:</Label>
              <Select
                name="objection"
                defaultValue={lastSLA.objection || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {objections.map(objection => (
                  <option
                    key={objection.label}
                    value={objection.label}
                    selected={formData.objection === objection.label}
                  >
                    {objection.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Teve dúvidas sobre o serviço, qual?</Label>
              <Select
                name="doubts"
                defaultValue={lastSLA.doubts || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {doubts.map(doubt => (
                  <option
                    key={doubt.label}
                    value={doubt.label}
                    selected={formData.doubts === doubt.label}
                  >
                    {doubt.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Conhecimento do processo:</Label>
                <Select
                  name="process_knowledge"
                  defaultValue={lastSLA.process_knowledge || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {processKnowledge.map(process_knowledge => (
                    <option
                      key={process_knowledge.label}
                      value={process_knowledge.label}
                      selected={
                        formData.process_knowledge === process_knowledge.label
                      }
                    >
                      {process_knowledge.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              <div />
            )}

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Explicação da proposta:</Label>
                <Select
                  name="proposal_explanation"
                  defaultValue={lastSLA.proposal_explanation || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {proposalExplanation.map(proposal_explanation => (
                    <option
                      key={proposal_explanation.label}
                      value={proposal_explanation.label}
                      selected={
                        formData.proposal_explanation ===
                        proposal_explanation.label
                      }
                    >
                      {proposal_explanation.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            {ticketDeal.hs_pipeline === '2154049' ||
            ticketDeal.hs_pipeline === '9528351' ? (
              <InputContainer>
                <Label>
                  Motivo de não ter tido interesse de antecipar antes:
                </Label>
                <Select
                  name="why_didnt_anticipate_before"
                  defaultValue={lastSLA.why_didnt_anticipate_before || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {whyDidntAnticipateBefore.map(reason => (
                    <option
                      key={reason.label}
                      value={reason.label}
                      selected={
                        formData.why_didnt_anticipate_before === reason.label
                      }
                    >
                      {reason.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            {ticketDeal.hs_pipeline === '2154049' ||
            ticketDeal.hs_pipeline === '9528351' ? (
              <InputContainer>
                <Label>
                  Qual a prioridade do cliente em resolver esse problema?
                </Label>
                <Select
                  name="customer_priority"
                  defaultValue={lastSLA.customer_priority || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {customerPriority.map(priority => (
                    <option
                      key={priority.label}
                      value={priority.label}
                      selected={formData.customer_priority === priority.label}
                    >
                      {priority.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            {ticketDeal.hs_pipeline === '2154049' ||
            ticketDeal.hs_pipeline === '9528351' ? (
              <InputContainer>
                <Label>
                  Poderia rever a sua decisão e antecipar o precatório com a
                  Precato?
                </Label>
                <Select
                  name="decision_review"
                  defaultValue={lastSLA.decision_review || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {decisionReview.map(decision => (
                    <option
                      key={decision.label}
                      value={decision.label}
                      selected={formData.decision_review === decision.label}
                    >
                      {decision.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            {ticketDeal.hs_pipeline === '2154049' ||
            ticketDeal.hs_pipeline === '9528351' ? (
              <InputContainer>
                <Label>Teve contato com algum concorrente?</Label>
                <Select
                  name="contact_with_competitor"
                  defaultValue={lastSLA.contact_with_competitor || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {contactWithCompetitor.map(contact => (
                    <option
                      key={contact.label}
                      value={contact.label}
                      selected={
                        formData.contact_with_competitor === contact.label
                      }
                    >
                      {contact.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            {ticketDeal.hs_pipeline === '2154049' ||
            ticketDeal.hs_pipeline === '9528351' ? (
              <InputContainer>
                <Label>Informou o valor que negociaria?</Label>
                <Select
                  name="informed_negotiable_value"
                  defaultValue={lastSLA.informed_negotiable_value || ''}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {informedNegotiableValue.map(info => (
                    <option
                      key={info.label}
                      value={info.label}
                      selected={
                        formData.informed_negotiable_value === info.label
                      }
                    >
                      {info.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            ) : (
              ''
            )}

            {(ticketDeal.hs_pipeline === '2154049' ||
              ticketDeal.hs_pipeline === '9528351') &&
            formData.informed_negotiable_value === 'Sim' ? (
              <InputContainer>
                <Label>Qual valor?</Label>
                <Input
                  type="text"
                  name="negotiable_value"
                  defaultValue={lastSLA.negotiable_value || ''}
                  onChange={handleInputChange}
                />
              </InputContainer>
            ) : (
              ''
            )}

            <InputContainer>
              <Label>Valor oferecido por concorrente:</Label>
              <Input
                type="text"
                name="value_offered_by_competition"
                defaultValue={lastSLA.value_offered_by_competition || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Motivo de não avançar:</Label>
              <Select
                name="motive_for_not_advancing"
                defaultValue={lastSLA.motive_for_not_advancing || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {motivesForNotAdvancing.map(motive => (
                  <option
                    key={motive.label}
                    value={motive.label}
                    selected={
                      formData.motive_for_not_advancing === motive.label
                    }
                  >
                    {motive.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Motivo da oportunidade:</Label>
              <Select
                name="motive_of_opportunity"
                defaultValue={lastSLA.motive_of_opportunity || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {motivesOfOpportunity.map(motive => (
                  <option
                    key={motive.label}
                    value={motive.label}
                    selected={
                      formData.motive_for_not_advancing === motive.label
                    }
                  >
                    {motive.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Perfil:</Label>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  name="Direto"
                  onChange={handleInputChange}
                  checked={
                    !!formData.profile.find(profile => profile === 'Direto')
                  }
                />
                <LabelCheckbox>Direto</LabelCheckbox>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  name="Educado"
                  onChange={handleInputChange}
                  checked={
                    !!formData.profile.find(profile => profile === 'Educado')
                  }
                />
                <LabelCheckbox>Educado</LabelCheckbox>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  name="Grosso"
                  onChange={handleInputChange}
                  checked={
                    !!formData.profile.find(profile => profile === 'Grosso')
                  }
                />
                <LabelCheckbox>Grosso</LabelCheckbox>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  name="Instruído"
                  onChange={handleInputChange}
                  checked={
                    !!formData.profile.find(profile => profile === 'Instruído')
                  }
                />
                <LabelCheckbox>Instruído</LabelCheckbox>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  name="Simples"
                  onChange={handleInputChange}
                  checked={
                    !!formData.profile.find(profile => profile === 'Simples')
                  }
                />
                <LabelCheckbox>Simples</LabelCheckbox>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  name="Conversador"
                  onChange={handleInputChange}
                  checked={
                    !!formData.profile.find(
                      profile => profile === 'Conversador',
                    )
                  }
                />
                <LabelCheckbox>Conversador</LabelCheckbox>
              </CheckboxContainer>
            </InputContainer>

            <InputContainer>
              <Label>Observação:</Label>
              <Textarea
                name="observation"
                defaultValue=""
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Nota de esclarecimento:</Label>
              <Textarea
                disabled
                name="clarification_note"
                defaultValue={formData.clarification_note}
              />
            </InputContainer>

            <InputContainer>
              <ButtonSave onClick={handleSaveSLA}>Salvar</ButtonSave>
            </InputContainer>
          </>
        )}
      </Main>
    </Modal>
  );
}
