import styled, { css } from 'styled-components';

interface ITableRowProps {
  background: string;
}

interface IPaginationButtonProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

export const TitleContainer = styled.div`
  margin-top: 5.188rem;
  @media (max-width: 1200px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
`;

export const Title = styled.h2`
  margin-left: 0.5rem;
  width: 74.625rem;
  height: 4.875rem;
  left: 11.063rem;
  top: 9.688rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 2.438rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 39.188rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  background-color: #d7e4f5;
  border-radius: 0.625rem 0.625rem 0px 0px;
`;

export const FilterBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 6.188rem;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;

  width: 39.188rem;

  border: 0.188rem solid #d7e4f5;
  border-radius: 0px 0px 0.625rem 0.625rem;

  box-shadow: 2px 2px 4px -2px #d7e4f5;
`;

export const FilterOptions = styled.select`
  height: 2.375rem;
  width: 17.063rem;

  border: 0.5px solid var(--blue-700);
  border-radius: 0.25rem;

  text-align: center;

  color: var(--blue-700);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const FilterInput = styled.input`
  height: 2.375rem;
  width: 4rem;

  border: 0.5px solid var(--blue-700);
  border-radius: 0.25rem;

  text-align: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-700);
`;

export const SearchButton = styled.button`
  background: var(--blue-400);
  color: var(--white);
  border-radius: 8px;
  border-width: 0px;

  margin-top: 41px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;

  width: 155px;
  height: 37px;
`;

export const SearchTableImg = styled.img`
  margin-right: 11.65px;
`;

export const LodingImg = styled.img``;

export const SearchTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchResultCaption = styled.div`
  display: flex;
  flex-direction: row;

  cursor: default;

  gap: 23px;
  margin-top: 61px;
  padding-left: 8px;
`;

export const ResultAmount = styled.div`
  min-width: 26px;
  height: 21px;

  padding: 0 6px;

  border-radius: 5px;
  border-width: 0;

  text-align: center;
  color: var(--white);
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

export const ResultDiv = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

export const DivTable = styled.div`
  width: 100%;

  @media (max-width: 1200px) {
    margin-right: 1.8rem;
    margin-top: 0.5rem;
    overflow-x: scroll;
    width: 93vw;
  }
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Divider = styled.div`
  height: 2rem;

  border-right: 1px solid var(--gray-500);
`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
`;

export const TableHeader = styled.thead`
  background: var(--gray-300);
  border-radius: 0.4rem;
  margin-bottom: 0.7rem;
`;

export const TableHeaderRow = styled.tr`
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  justify-content: space-between;
  height: 2.5rem;
  background: var(--gray-300);
`;

export const Option = styled.option``;

export const TCellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: var(--blue-700);
`;

export const TableBody = styled.tbody`
  border-radius: 0.25rem;
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: pointer;

  @media (max-width: 1100px) {
    td {
      width: 10rem;
    }
  }
`;

export const TableRow = styled.tr<ITableRowProps>`
  border-radius: 4px;
  box-shadow: ${props =>
    props.background === 'inHub'
      ? 'inset 3px 0px 0px #1665D8'
      : props.background === 'blocked'
      ? 'inset 3px 0px 0px #EB5757'
      : 'inset 3px 0px 0px #B4AD09'};

  ${props =>
    props.background === 'inHub'
      ? css`
          background: rgba(22, 101, 216, 0.1);
          &:hover {
            background: rgba(22, 101, 216, 0.2);
          }
        `
      : props.background === 'blocked'
      ? css`
          background: rgba(235, 87, 87, 0.1);
          &:hover {
            background: rgba(235, 87, 87, 0.2);
          }
        `
      : css`
          background: rgba(180, 173, 9, 0.1);
          &:hover {
            background: rgba(180, 173, 9, 0.2);
          }
        `}
`;

export const TCellBody = styled.td`
  word-break: break-word;
  width: 100vw;
  background: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.015em;
  padding: 0.5rem;

  color: var(--blue-700);
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  padding: 8px;
  margin-top: 12px;

  justify-content: flex-end;
`;

export const PageButton = styled.button<IPaginationButtonProps>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  text-align: center;
  letter-spacing: -0.015em;

  border-width: 0;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;

  ${props =>
    props.active
      ? {
          background: '#1665d8',
          color: '#fff',
          borderRadius: '4px',
        }
      : { background: 'transparent', color: '#666360' }}
`;
