import React from 'react';
import { Container, Divisor } from './styles';

interface SeparatorProps {
  separatorWidth?: number;
}

export const Separator: React.FC<SeparatorProps> = ({ separatorWidth }) => {
  return (
    <Container>
      <Divisor separatorWidth={separatorWidth} />
    </Container>
  );
};
