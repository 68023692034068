import {
  ISLABase,
  ISLAComplet,
  ISLAN1,
  ISLAN2,
  ISLAN3,
  ISLAN4,
} from '../interface';

type TypeModal = 'N1' | 'N2' | 'N3' | 'N4' | 'N5';
type TypeSLA = ISLAN1 | ISLAN2 | ISLAN3 | ISLAN4;

export function fromatedSlaRequest(
  sla: ISLAComplet,
  type: TypeModal,
): TypeSLA | false {
  if (type !== 'N5') {
    const SLAFormated: ISLABase = {
      reason_for_scheduling: sla.reason_for_scheduling,
      objection: sla.objection,
      send_message_to_client: sla.send_message_to_client,
      was_aware_precatory: sla.was_aware_precatory,
      we_schedule_a_new_contact: sla.we_schedule_a_new_contact,
      schedule_date: sla.schedule_date,
      day: sla.day,
      hours: sla.hours,
      observation: sla.observation,
      ticket_id: sla.ticket_id,
      who_contacted: sla.who_contacted,
      decision_maker: sla.decision_maker,
      valid_contact: sla.valid_contact,
      valid_contact_wpp_opt_in: sla.valid_contact_wpp_opt_in,
      valid_email: sla.valid_email,
      contact_preferences: sla.contact_preferences,
      connection_type: sla.connection_type,
      connection_method: sla.connection_method,
      opt_in: sla.opt_in,
      clarification_note: sla.clarification_note,
      value_to_negociate: sla.value_to_negociate,
      profile: sla.profile,
      contact_competition: sla.contact_competition,
      Interested_in_knowing_the_values: sla.Interested_in_knowing_the_values,
      late_payment_delays_the_customer: sla.late_payment_delays_the_customer,
      plans: sla.plans,
      thermometer: sla.thermometer,
      valid_contact_decision_maker: sla.valid_contact_decision_maker,
      value_proposal_competition: sla.value_proposal_competition,
      was_aware_PEC: sla.was_aware_PEC,
    };
    return SLAFormated;
  }

  return false;
}
