/* eslint-disable prettier/prettier */
import { useCallback, useState } from 'react';
import { CampaignResult, TableRow, TableRowCell, UpdateButton } from './styles';
import failure from '../../../../assets/icons/failure.svg';
import sent from '../../../../assets/icons/sent.svg';
import received from '../../../../assets/icons/received.svg';
import { CampaignModal } from '../CampaignModal';
import { ISmsTriggerDTO } from '../dtos/ISmsTriggerDTO';

interface ITableBodyRowProps {
  name: string;
  scheduled_date: string;
  triggers: ISmsTriggerDTO[];
  id: string;
  onEditCampaign: () => void;
}

export function TableBodyRow({
  name,
  scheduled_date,
  triggers,
  id,
  onEditCampaign,
}: ITableBodyRowProps): JSX.Element {
  const [activeModal, setActiveModal] = useState(false);

  const handleUpdateButton = useCallback(() => {
    setActiveModal(true);
  }, []);

  const modalRequestClose = useCallback(() => {
    onEditCampaign();

    setActiveModal(false);
  }, [onEditCampaign]);

  return (
    <TableRow
      isActive={
        !scheduled_date ||
        new Date(scheduled_date).getTime() > new Date().getTime()
      }
      className="tr-body"
    >
      <TableRowCell>{name}</TableRowCell>

      <TableRowCell>
        {scheduled_date
          ? new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }).format(new Date(scheduled_date))
          : '--/--/-- --:--'}
      </TableRowCell>

      <TableRowCell>{triggers ? triggers.length : 0}</TableRowCell>

      <TableRowCell>
        <CampaignResult>
          <img src={failure} alt="failure" />
          <span>
            {
              triggers.filter(trigger => trigger.status_detail === 'Error')
                .length
            }
          </span>
        </CampaignResult>

        <CampaignResult>
          <img src={sent} alt="sent" />
          <span>
            {
              triggers.filter(
                trigger => trigger.status_detail_message === 'Message Sent',
              ).length
            }
          </span>
        </CampaignResult>

        <CampaignResult>
          <img src={received} alt="received" />
          <span>
            {
              triggers.filter(
                trigger =>
                  trigger.status_detail_message ===
                  'Message received by mobile',
              ).length
            }
          </span>
        </CampaignResult>

        <UpdateButton
          isActive={
            !scheduled_date ||
            new Date(scheduled_date).getTime() > new Date().getTime()
          }
          onClick={handleUpdateButton}
        />
      </TableRowCell>

      <CampaignModal
        isOpen={activeModal}
        onRequestClose={modalRequestClose}
        campaign_name={name}
        scheduled_date={scheduled_date}
        triggers_count={triggers ? triggers.length : 0}
        batch_id={id}
      />
    </TableRow>
  );
}
