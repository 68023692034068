import { ChangeEvent } from 'react';
import {
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  TitleFile,
  Label,
  InputFile,
  TextFile,
  SendButtonContainer,
  ButtonSendFile,
  TextFileButton,
  ExampleFileContainer,
  ExampleTitle,
  Separator,
  FileProcessExampleContainer,
  ExampleText,
  ExampleTextContainer,
  SelectFileContainer,
} from './styles';

interface IExampleFileTRF2Withdraw {
  handleUpload: () => Promise<void>;
  submitFile: (event: ChangeEvent<HTMLInputElement>) => void;
  file_name: string;
}

export const ExampleFileTRF2Withdraw: React.FC<IExampleFileTRF2Withdraw> = ({
  submitFile,
  handleUpload,
  file_name,
}: IExampleFileTRF2Withdraw) => {
  const templateFileTrf = '0000000';

  return (
    <FileContainer>
      <FileInputContainer>
        <TitleFile>Arquivo:</TitleFile>
        <SendFileContainer>
          <SelectFileContainer>
            <Label htmlFor="file">Selecione um arquivo</Label>
            <InputFile
              onChange={event => submitFile(event)}
              id="file"
              type="file"
              name="file"
            />
            {file_name ? (
              <TextFile>{file_name}</TextFile>
            ) : (
              <TextFile>Nenhum arquivo selecionado</TextFile>
            )}
          </SelectFileContainer>
        </SendFileContainer>

        <SendButtonContainer>
          <ButtonSendFile type="button" onClick={() => handleUpload()}>
            Enviar
          </ButtonSendFile>
          <TextFileButton>
            Arquivos serão processados automaticamente
          </TextFileButton>
        </SendButtonContainer>
      </FileInputContainer>

      <ExampleFileContainer>
        <ExampleTitle>Modelo de arquivo:</ExampleTitle>

        <FileProcessExampleContainer>
          <ExampleTextContainer>
            <ExampleText id="process-number">Numero do Processo</ExampleText>
            <Separator />
            <ExampleText id="process-template">{templateFileTrf}</ExampleText>
          </ExampleTextContainer>
        </FileProcessExampleContainer>
      </ExampleFileContainer>
    </FileContainer>
  );
};
