import Modal from 'react-modal';
import { ChangeEvent, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  LostModalStyle,
  Main,
  ButtonsContainer,
  ApprovedButton,
  SemiApprovedButton,
  DeniedButton,
  BoxHeader,
  ConcBoxTable,
  ConcBoxRow,
  BoxHeadFirstCell,
  BoxHeadCell,
  BoxBodyCell,
  InputArea,
  BoxBody,
  Title,
  BoxRow,
  BoxTable,
} from './styles';
import api from '../../../services/api';
import { BoxInputShowing, LoaInputShowing } from '../PricingForms/styles';

interface ConclusionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  formData: FormData;
}

interface FormData {
  id: string;
  approved: string;
  competidor_one: string;
  competidor_two: string;
  competidor_three: string;

  prices_table_one_01: string;
  prices_table_one_02: string;
  prices_table_one_03: string;
  prices_table_two_01: string;
  prices_table_two_02: string;
  prices_table_two_03: string;
  prices_table_three_01: string;
  prices_table_three_02: string;
  prices_table_three_03: string;

  loa_prices_table_01: string;
  loa_prices_table_02: string;
  loa_prices_table_03: string;

  final_term_01: string;
  final_term_02: string;
  final_term_03: string;
  loa_final_term_01: string;
  loa_final_term_02: string;
  loa_final_term_03: string;
  observation_field: string;
}

export function ConclusionModal({
  isOpen,
  onRequestClose,
  formData,
}: ConclusionModalProps): JSX.Element {
  const history = useHistory();
  const [inputFormData, setInputFormData] = useState<FormData>({} as FormData);
  const handleCloseModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;
      setInputFormData({ ...inputFormData, [name]: value });
    },
    [inputFormData],
  );

  const handleSubmit = useCallback(
    async (approved: string) => {
      try {
        await api.patch('/pricing/forms', {
          forms_id: formData.id,
          approved,
          observation_field: inputFormData.observation_field,
        });
      } catch (error) {
        toast.error('Não foi possível concluir a operação!');
      }

      handleCloseModal();
      history.push({
        pathname: `/pricing`,
      });
    },
    [handleCloseModal, history, inputFormData, formData.id],
  );
  return (
    <Modal
      isOpen={isOpen}
      style={LostModalStyle}
      onRequestClose={handleCloseModal}
    >
      <Main>
        <ConcBoxTable>
          <BoxHeader>
            <ConcBoxRow>
              <BoxHeadFirstCell>Concorrentes</BoxHeadFirstCell>
              <BoxHeadCell>
                LOA{' '}
                <LoaInputShowing
                  id="text-id"
                  name="loa_prices_table_01"
                  disabled
                  defaultValue={formData.loa_prices_table_01}
                />
              </BoxHeadCell>
              <BoxHeadCell>
                LOA{' '}
                <LoaInputShowing
                  id="text-id"
                  name="loa_prices_table_02"
                  disabled
                  defaultValue={formData.loa_prices_table_02}
                />
              </BoxHeadCell>
              <BoxHeadCell>
                LOA{' '}
                <LoaInputShowing
                  id="text-id"
                  name="loa_prices_table_03"
                  disabled
                  defaultValue={formData.loa_prices_table_03}
                />
              </BoxHeadCell>
            </ConcBoxRow>
          </BoxHeader>

          <ConcBoxRow>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="competidor_one"
                disabled
                defaultValue={formData.competidor_one}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_one_01"
                disabled
                defaultValue={formData.prices_table_one_01}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_one_02"
                disabled
                defaultValue={formData.prices_table_one_02}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_one_03"
                disabled
                defaultValue={formData.prices_table_one_03}
              />
            </BoxBodyCell>
          </ConcBoxRow>

          <ConcBoxRow>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="competidor_two"
                disabled
                defaultValue={formData.competidor_two}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_two_01"
                disabled
                defaultValue={formData.prices_table_two_01}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_two_02"
                disabled
                defaultValue={formData.prices_table_two_02}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_two_03"
                disabled
                defaultValue={formData.prices_table_two_03}
              />
            </BoxBodyCell>
          </ConcBoxRow>

          <ConcBoxRow>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="competidor_three"
                disabled
                defaultValue={formData.competidor_three}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_three_01"
                disabled
                defaultValue={formData.prices_table_three_01}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_three_02"
                disabled
                defaultValue={formData.prices_table_three_02}
              />
            </BoxBodyCell>
            <BoxBodyCell>
              <BoxInputShowing
                id="text-id"
                name="prices_table_three_03"
                disabled
                defaultValue={formData.prices_table_three_03}
              />
            </BoxBodyCell>
          </ConcBoxRow>
        </ConcBoxTable>

        <BoxTable>
          <BoxRow>
            <Title>Prazo final de recebimento</Title>
          </BoxRow>
          <BoxHeader>
            <BoxRow>
              <BoxHeadCell>
                LOA{' '}
                <LoaInputShowing
                  id="text-id"
                  name="loa_final_term_01"
                  disabled
                  defaultValue={formData.loa_final_term_01}
                />
              </BoxHeadCell>
              <BoxHeadCell>
                LOA{' '}
                <LoaInputShowing
                  id="text-id"
                  name="loa_final_term_02"
                  disabled
                  defaultValue={formData.loa_final_term_02}
                />
              </BoxHeadCell>
              <BoxHeadCell>
                LOA{' '}
                <LoaInputShowing
                  id="text-id"
                  name="loa_final_term_03"
                  disabled
                  defaultValue={formData.loa_final_term_03}
                />
              </BoxHeadCell>
            </BoxRow>
          </BoxHeader>

          <BoxBody>
            <BoxRow>
              <BoxBodyCell>
                <BoxInputShowing
                  id="LOA-id"
                  name="final_term_01"
                  disabled
                  defaultValue={formData.final_term_01}
                />
              </BoxBodyCell>
              <BoxBodyCell>
                <BoxInputShowing
                  id="LOA-id"
                  name="final_term_02"
                  disabled
                  defaultValue={formData.final_term_02}
                />
              </BoxBodyCell>
              <BoxBodyCell>
                <BoxInputShowing
                  id="LOA-id"
                  name="final_term_03"
                  disabled
                  defaultValue={formData.final_term_03}
                />
              </BoxBodyCell>
            </BoxRow>
          </BoxBody>
        </BoxTable>
        <Title>Observações</Title>
        {formData.approved === 'Aprovado' ? (
          <InputArea
            name="observation_field"
            disabled
            defaultValue={formData.observation_field || ''}
          />
        ) : (
          <InputArea
            name="observation_field"
            defaultValue={formData.observation_field || ''}
            onChange={handleInputChange}
          />
        )}
        {formData.approved === 'Aprovado' ? (
          <ButtonsContainer>
            <DeniedButton
              onClick={() => {
                handleCloseModal();
              }}
            >
              Sair
            </DeniedButton>
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <ApprovedButton onClick={() => handleSubmit('Aprovado')}>
              Aprovado
            </ApprovedButton>
            <SemiApprovedButton
              onClick={() => handleSubmit('Aprovado com ressalva')}
            >
              Aprovado com ressalva
            </SemiApprovedButton>
            <DeniedButton onClick={() => handleSubmit('Reprovado')}>
              Reprovado
            </DeniedButton>
          </ButtonsContainer>
        )}
      </Main>
    </Modal>
  );
}
