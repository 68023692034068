import styled from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 9.5rem;

  margin-top: 1.625rem;

  background: var(--white);
  box-shadow: 0.25rem 0.25rem 0.125rem rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 3.375rem;

  background: var(--gray-50);

  font-weight: 600;
  font-size: 1.125rem;
  color: var(--blue-400);

  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

export const ResumeFields = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 18% 18% 18% 18% 28%;
  align-items: center;
`;

export const FieldColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  width: 100%;
  height: 90%;

  span {
    text-align: center;
  }

  span.title {
    font-weight: 600;
    font-size: 1rem;
    color: var(--gray-600);
  }

  span.value {
    font-weight: 700;
    font-size: 1rem;
    color: var(--blue-800);
  }

  & + div {
    border-left: 1px solid var(--gray-600);
  }
`;

export const RegistriesContainer = styled.div`
  width: 100%;

  padding-left: ${pxToRem(15)};

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
`;

export const RegitryActivity = styled.div`
  div {
    display: flex;
    gap: 0.125rem;
  }

  span {
    font-size: ${pxToRem(10)};
    color: var(--gray-800);
  }

  .title-registry {
    font-weight: 600;
  }
`;

export const InfoButton = styled.button`
  border: none;
  background: none;

  font-size: ${pxToRem(10)};
  font-weight: 600;
  color: var(--blue-400);
`;
