import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import 'react-dragswitch/dist/index.css';
import 'react-day-picker/lib/style.css';
import Loader from 'react-loader-spinner';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import { NavigationBar } from '../../../components/NavigationBar';
import { Title } from '../../../components/Title';
import { InputInitialAndFinalDayPicker } from '../components/InputInitialAndFinalDayPicker';
import apiChatbot from '../../../services/apiChatbot';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import {
  DateContainer,
  ClearDateButton,
  FiltersContainer,
  Text,
  Label,
  Divisor,
  SearchButton,
  FilterInput,
  Table,
  THead,
  TRowHead,
  TCellHead,
  TBody,
  TRowBody,
  TCell,
  DateAndFilterContainer,
  LoaderContainer,
  TextLoader,
  FormFilterInput,
  InputCheckBox,
} from './styles';
import formatDateWithHours from '../../../utils/formatDateWithHours';
import { formatDate } from '../../../utils/formatDate';
import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';

interface ICalls {
  id: number;
  number: number;
  hubspot_link: string;
  duration: number;
  form_value: string;
  change_stage: string;
  isAudited: boolean;
  start_time: string;
  call_link: string;
}

interface IRoutes {
  callsByFilter: string;
  getAuditedState: string;
  updateAuditedState: string;
}

export function CallsAudit(): JSX.Element {
  const [calls, setCalls] = useState<ICalls[]>([] as ICalls[]);
  const [minDuration, setMinDuration] = useState<number>(undefined);
  const [maxDuration, setMaxDuration] = useState<number>(undefined);
  const [form, setForm] = useState<string>(undefined);
  const [ramal, setRamal] = useState<string>(undefined);
  const [dbPage, setDbPage] = useState<number>(1);
  const [from, setFrom] = useState<Date>(undefined);
  const [to, setTo] = useState<Date>(undefined);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<ICalls[]>([] as ICalls[]);

  const createRoutes = useCallback(() => {
    const routes = {} as IRoutes;

    routes.callsByFilter = `/calls_audit/`;

    routes.getAuditedState = `/calls_audit/audited-state`;

    routes.updateAuditedState = `/calls_audit/update-state/`;

    return routes;
  }, []);

  const itemsPerPage = 8;

  const updatePage = useCallback(
    async (db_page: number) => {
      setLoading(true);

      setPage(1);

      const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

      const routes = createRoutes();

      await chatbot
        .post(routes.callsByFilter, {
          start_date: formatDate(from || new Date()),
          end_date: formatDate(to || new Date()),
          page: db_page,
          start_duration: minDuration,
          end_duration: maxDuration,
          form,
          ramal,
        })
        .then(response => {
          setCalls(response.data);

          setCurrentItems(response.data.slice(0, itemsPerPage));

          const pages: number[] = [];

          for (
            let index = 1;
            index <= Math.ceil(response.data.length / itemsPerPage);
            index++
          ) {
            pages.push(index);
          }

          setTotalPages(pages);

          setLoading(false);
        });
    },
    [createRoutes, form, from, to, ramal, minDuration, maxDuration],
  );

  const updateAuditedState = useCallback(async () => {
    if (calls.length > 0) {
      const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

      const routes = createRoutes();

      await chatbot
        .post(routes.getAuditedState, {
          calls_ids: calls.map(call => call.id),
        })
        .then(response => {
          const updatedCalls = calls.map(call => {
            const state = response.data.find(
              callState => call.id === callState.callId,
            );

            call.isAudited = state.state;

            return call;
          });

          setCalls(updatedCalls);
        });
    }
  }, [createRoutes, calls, setCalls]);

  useQuery('infos', updateAuditedState, {
    refetchInterval: 30 * 1000,
    refetchOnWindowFocus: false,
  });

  const handleSubmitFilters = useCallback(async () => {
    setDbPage(1);

    await updatePage(1);
  }, [updatePage, setDbPage]);

  const handleResetDate = useCallback(() => {
    setFrom(undefined);

    setTo(undefined);
  }, []);

  const handleChangePagination = useCallback(
    async (toPage: number) => {
      const items: ICalls[] = calls;

      if (toPage === 0 && page === totalPages.length) {
        setDbPage(dbPage + 1);

        await updatePage(dbPage + 1);
      }

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [page, totalPages, calls, setDbPage, updatePage, dbPage],
  );

  const handleChangeAuditedState = useCallback(
    async (callId: number) => {
      const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

      const routes = createRoutes();

      const response = await chatbot.patch(
        `${routes.updateAuditedState}${callId}`,
      );

      if (response.status !== 200) {
        return;
      }

      const updatedCalls = calls.map(call => {
        if (call.id === callId) {
          call.isAudited = !call.isAudited;
        }

        return call;
      });

      setCalls(updatedCalls);
    },

    [calls, createRoutes, setCalls],
  );

  return (
    <Container>
      <NavigationBar
        past_screens="Conexão > "
        active_screen="Auditar Ligações"
      />

      <Body>
        <Title name="Auditar Ligações" separatorWidth={15.5} />

        <DateAndFilterContainer>
          <DateContainer>
            <InputInitialAndFinalDayPicker
              initialDate={from}
              finalDate={to}
              dayClickFrom={value => setFrom(value)}
              dayClickTo={value => setTo(value)}
            />

            <ClearDateButton onClick={handleResetDate}>
              <ClearIcon />
              Limpar data
            </ClearDateButton>
          </DateContainer>

          <FiltersContainer>
            <Text>Filtre por:</Text>

            <Label htmlFor="MinDuration">Duração Min.</Label>
            <FilterInput
              name="MinDuration"
              id="MinDuration"
              type="number"
              onChange={e => setMinDuration(Number(e.target.value))}
            />

            <Divisor />

            <Label htmlFor="MaxDuration">Duração Max.</Label>
            <FilterInput
              name="MaxDuration"
              id="MaxDuration"
              type="number"
              onChange={e => setMaxDuration(Number(e.target.value))}
            />

            <Divisor />

            <Label htmlFor="ramal">Ramal</Label>
            <FilterInput
              name="ramal"
              id="ramal"
              onChange={e => setRamal(e.target.value)}
            />

            <Divisor />

            <Label htmlFor="form">Form</Label>
            <FormFilterInput
              name="form"
              id="form"
              onChange={e => setForm(e.target.value)}
            />

            <SearchButton onClick={handleSubmitFilters}>
              <SearchIcon />
            </SearchButton>
          </FiltersContainer>
        </DateAndFilterContainer>

        {!loading ? (
          <>
            <Table>
              <THead>
                <TRowHead>
                  <TCellHead>Ouvir</TCellHead>
                  <TCellHead>Data Ligação</TCellHead>
                  <TCellHead>Ramal Hunter</TCellHead>
                  <TCellHead>Duração</TCellHead>
                  <TCellHead>Link Ticket</TCellHead>
                  <TCellHead>Resposta do formulário</TCellHead>
                  <TCellHead>Ultima movimentação</TCellHead>
                  <TCellHead>Auditada?</TCellHead>
                </TRowHead>
              </THead>
              <TBody>
                {currentItems.length > 0 &&
                  currentItems.map(call => (
                    <TRowBody key={call.id}>
                      <TCell>
                        <a
                          href={call.call_link}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Áudio
                        </a>
                      </TCell>
                      <TCell>
                        {formatDateWithHours(new Date(call.start_time))}
                      </TCell>
                      <TCell>{call.number} </TCell>
                      <TCell>{call.duration} </TCell>
                      <TCell>
                        <a
                          href={call.hubspot_link}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Ticket
                        </a>
                      </TCell>
                      <TCell>{call.form_value}</TCell>
                      <TCell>{call.change_stage}</TCell>
                      <TCell>
                        <InputCheckBox
                          type="checkbox"
                          onChange={() => handleChangeAuditedState(call.id)}
                          checked={call.isAudited}
                        />
                      </TCell>
                    </TRowBody>
                  ))}
              </TBody>
            </Table>
            <PaginationComponent
              totalPages={totalPages}
              page={page}
              handleChangePagination={handleChangePagination}
            />
          </>
        ) : (
          <LoaderContainer>
            <Loader type="Oval" color="#0c0b0b" height={25} width={25} />
            <TextLoader>Carregando</TextLoader>
          </LoaderContainer>
        )}
      </Body>
    </Container>
  );
}
