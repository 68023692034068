import styled from 'styled-components';

export const TableContainer = styled.div`
  margin-top: 4.875rem;
`;

export const Title = styled.h1`
  margin-top: 2rem;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.875rem;
  color: var(--blue-800);
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 18.563rem 22rem 24.687rem 6.313rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.25rem;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-top: 1.188rem;
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;

  margin-left: 36rem;
`;

export const UploadedFileName = styled.p`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;

  cursor: pointer;
`;
export const UploadedFileContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const Tbody = styled.tbody`
  .tr-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3.125rem;
    width: 71.7rem;
    border-radius: 0.25rem;
    margin-top: 0.375rem;
    font-family: 'Poppins';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    background: var(--gray-100);

    .downloadLinkContainer {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .downloadFileLink {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: black;
      text-decoration: none;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }
`;

export const BodyCell = styled.td`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;
  cursor: pointer;
`;
