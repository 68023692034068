import { shade } from 'polished';
import styled from 'styled-components';

export const SLAQuestions = {
  content: {
    width: '58rem',
    maxHeight: '38rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
    alignItems: 'center',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const ModalHeader = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;

  font-family: Poppins;
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--blue-700);
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 2rem 1rem 2rem;
  align-items: flex-start;
`;

export const ColumnContainer = styled.div`
  width: 26rem;
  display: flex;
  flex-direction: column;
`;

export const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--gray-100);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;
    padding: 0 0.5rem;
    margin-top: 1rem;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--blue-700);

    margin-bottom: 0.75rem;

    height: 3rem;
  }

  input {
    font-size: 0.875rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 12.5rem);
  justify-content: space-between;
`;

export const ButtonSaveSLAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonSend = styled.button`
  width: 10.625rem;
  height: 2.25rem;
  border: none;
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9rem;

  background: var(--blue-600);
  border-radius: 0.5rem;

  color: var(--white);
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.3, '#063966')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
