import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  font-family: Poppins;

  strong {
    color: #05233e;
    font-size: 20px;
  }

  button {
    color: #6d6d6d;
    border: 0;
    background: #fff;
    font-weight: 600;
    font-size: 13px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  font-family: Poppins;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;

  margin-bottom: 0.3125rem;
`;

export const Notification = styled.div``;

export const Separator = styled.hr`
  margin: 1.375rem 0;
  width: 18rem;
`;

export const NotificationBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9px;
  padding: 1rem 0.5rem;

  background: #edf3fc;
  border-radius: 2px;

  font-weight: 600;
  font-size: 13px;
  color: #595959;

  strong {
    color: #1665d8;
  }
`;

export const Circle = styled.div`
  display: flex;

  min-height: 2.5rem;
  min-width: 2.5rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #008146;

  align-items: center;
  justify-content: center;

  color: #fff;
  font-weight: 600;
  font-size: 20px;
`;

export const NotificationFooter = styled.div`
  color: #6d6d6d;
  padding-left: 0.25rem;

  font-size: 12px;
  font-weight: 600;
`;

export const StyleModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },

  content: {
    height: '40vh',
    width: '22.25rem',
    margin: '0.5625rem auto auto 63%',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },
};
