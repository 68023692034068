export interface ISlaFields {
  field: string;
  label: string;
}

const n1 = [
  {
    field: 'Com quem foi o contato?',
    label: 'who_contacted',
  },
  {
    field: 'Tomador de decisão:',
    label: 'decision_maker',
  },
  {
    field: 'Qual é o contato válido:',
    label: 'valid_contact',
  },
  {
    field: 'Contato válido WPP:',
    label: 'valid_contact_wpp_opt_in',
  },
  {
    field: 'E-mail válido:',
    label: 'valid_email',
  },
  {
    field: 'Tipo de conexão:',
    label: 'connection_type',
  },
  {
    field: 'Meio de conexão:',
    label: 'connection_method',
  },
  {
    field: 'Opt In:',
    label: 'opt_in',
  },
  {
    field: 'Valor que considera negociar:',
    label: 'value_to_negociate',
  },
  {
    field: 'Motivo do caso estar na etapa N1',
    label: 'reason_for_scheduling',
  },
  {
    field: 'Influência',
    label: 'influence',
  },
  {
    field: 'Objeção que o cliente trouxe N1',
    label: 'objection',
  },
  {
    field: 'Foi enviado mensagem para o cliente N1?',
    label: 'send_message_to_client',
  },
  {
    field: 'Tinha conhecimento do precatório?',
    label: 'was_aware_precatory',
  },
  {
    field: 'Agendamos um novo contato?',
    label: 'we_schedule_a_new_contact',
  },
  {
    field: 'Perfil:',
    label: 'profile',
  },
];

const n2 = [
  {
    field: 'Com quem foi o contato?',
    label: 'who_contacted',
  },
  {
    field: 'Tomador de decisão:',
    label: 'decision_maker',
  },
  {
    field: 'Qual é o contato válido:',
    label: 'valid_contact',
  },
  {
    field: 'Contato válido WPP:',
    label: 'valid_contact_wpp_opt_in',
  },
  {
    field: 'E-mail válido:',
    label: 'valid_email',
  },
  {
    field: 'Tipo de conexão:',
    label: 'connection_type',
  },
  {
    field: 'Meio de conexão:',
    label: 'connection_method',
  },
  {
    field: 'Opt In:',
    label: 'opt_in',
  },
  {
    field: 'Valor que considera negociar:',
    label: 'value_to_negociate',
  },
  {
    field: 'Tinha conhecimento da previsão de pagamento?',
    label: 'was_aware_PEC',
  },
  {
    field: 'Se sente incomodado?',
    label: 'feel_uncomfortable',
  },
  {
    field: 'Valor que considera negociar:',
    label: 'value_to_negociate',
  },
  {
    field: 'Influência:',
    label: 'influence',
  },
  {
    field: 'Objeção que o cliente trouxe N2:',
    label: 'objection',
  },
  {
    field: 'Teve interesse em saber os valores?',
    label: 'Interested_in_knowing_the_values',
  },
  {
    field: 'Foi enviado mensagem para o cliente N2?',
    label: 'send_message_to_client',
  },
  {
    field: 'Agendamos um novo contato?',
    label: 'we_schedule_a_new_contact',
  },
  {
    field: 'Tinha conhecimento do precatório?',
    label: 'was_aware_precatory',
  },
];

const n3 = [
  {
    field: 'Com quem foi o contato?',
    label: 'who_contacted',
  },
  {
    field: 'Tomador de decisão:',
    label: 'decision_maker',
  },
  {
    field: 'Qual é o contato válido:',
    label: 'valid_contact',
  },
  {
    field: 'Contato válido WPP:',
    label: 'valid_contact_wpp_opt_in',
  },
  {
    field: 'E-mail válido:',
    label: 'valid_email',
  },
  {
    field: 'Tipo de conexão:',
    label: 'connection_type',
  },
  {
    field: 'Meio de conexão:',
    label: 'connection_method',
  },
  {
    field: 'Opt In:',
    label: 'opt_in',
  },
  {
    field: 'Valor que considera negociar:',
    label: 'value_to_negociate',
  },
  {
    field: 'O atraso do pagamento atrasa a vida do cliente?',
    label: 'late_payment_delays_the_customer',
  },
  {
    field: 'Qual plano do cliente?',
    label: 'plans',
  },
  {
    field: 'Qual a urgência em realizar o plano?',
    label: 'urgency_to_carry_out_the_plan',
  },
  {
    field: 'O cliente quer ter dinheiro em mãos?',
    label: 'money_in_hand',
  },
  {
    field: 'Motivo do caso estar na etapa:',
    label: 'reason_for_scheduling',
  },
  {
    field: 'Objeção que o cliente trouxe N3:',
    label: 'objection',
  },
  {
    field: 'Foi enviado mensagem para o cliente N3?',
    label: 'send_message_to_client',
  },
  {
    field: 'Agendamos um novo contato?',
    label: 'we_schedule_a_new_contact',
  },
  {
    field: 'Tinha conhecimento do precatório?',
    label: 'was_aware_precatory',
  },
  {
    field: 'Tinha conhecimento sobre a previsão de pagamento?',
    label: 'was_aware_PEC',
  },
  {
    field: 'Se sente incomodado?',
    label: 'feel_uncomfortable',
  },
  {
    field: 'Influência',
    label: 'influence',
  },
  {
    field: 'Teve interesse em saber os valores?',
    label: 'Interested_in_knowing_the_values',
  },
  {
    field: 'Perfil',
    label: 'profile',
  },
];

const n4 = [
  {
    field: 'Com quem foi o contato?',
    label: 'who_contacted',
  },
  {
    field: 'Tomador de decisão:',
    label: 'decision_maker',
  },
  {
    field: 'Qual é o contato válido:',
    label: 'valid_contact',
  },
  {
    field: 'Contato válido WPP:',
    label: 'valid_contact_wpp_opt_in',
  },
  {
    field: 'E-mail válido:',
    label: 'valid_email',
  },
  {
    field: 'Tipo de conexão:',
    label: 'connection_type',
  },
  {
    field: 'Meio de conexão:',
    label: 'connection_method',
  },
  {
    field: 'Opt In:',
    label: 'opt_in',
  },
  {
    field: 'Valor que considera negociar:',
    label: 'value_to_negociate',
  },
  {
    field: 'O cliente disse que quer resolver o problema?',
    label: 'customer_want_to_solve_the_problem',
  },
  {
    field: 'Ficou claro para o cliente como podemos resolver o problema dele?',
    label: 'customer_knows_that_we_can_solve_his_problem',
  },
  {
    field: 'Motivo do caso estar na etapa:',
    label: 'reason_for_scheduling',
  },
  {
    field: 'Objeção que o cliente trouxe N4:',
    label: 'objection',
  },
  {
    field: 'Agendado para o closer?',
    label: 'scheduled_for_closer',
  },
  {
    field: 'Tinha conhecimento do precatório?',
    label: 'was_aware_precatory',
  },
  {
    field: 'Tinha conhecimento da previsão de pagamento?',
    label: 'was_aware_PEC',
  },
  {
    field: 'Se sente incomodado?',
    label: 'feel_uncomfortable',
  },
  {
    field: 'Influência:',
    label: 'influence',
  },
  {
    field: 'Teve interesse em saber os valores?',
    label: 'Interested_in_knowing_the_values',
  },
  {
    field: 'O atraso do pagamento atrasa a vida do cliente?',
    label: 'late_payment_delays_the_customer',
  },
  {
    field: 'Qual plano do cliente?',
    label: 'plans',
  },
  {
    field: 'O cliente quer ter dinheiro em mãos?',
    label: 'money_in_hand',
  },
  {
    field: 'Perfil:',
    label: 'profile',
  },
];

export function getSlaFields(slaName: string): ISlaFields[] {
  if (slaName === 'N1') {
    return n1;
  }
  if (slaName === 'N2') {
    return n2;
  }
  if (slaName === 'N3') {
    return n3;
  }
  if (slaName === 'N4') {
    return n4;
  }
  return undefined;
}
