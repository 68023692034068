import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { format, isEqual, parseISO } from 'date-fns';
import * as yup from 'yup';

import { useQuery } from 'react-query';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_blue.svg';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import { Title } from '../../../../components/Title';
import { DayPicker } from '../../../../components/DayPicker';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';

import {
  Container,
  FieldSpan,
  TitleInput,
  Main,
  ColumnContainer,
  FieldContainer,
  InputsTimeContainer,
  Select,
  ContainerDayPicker,
  ContainerInputDate,
  InputsDeal,
  Textarea,
  NamesActivityContainer,
  ButtonToSchedule,
  ButtonContainer,
} from './styles';

import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';
import { Loading } from '../../../../components/Loading';
import { IIntervalsDTO } from '../dtos/IIntervalsDTO';

Modal.setAppElement('#root');

interface FormData {
  title: string;
  type: string;
  time_init: Date;
  time_end: Date;
  hubspot_deal_id?: string;
  observation?: string;
}

interface IHubspotDeal {
  dealname: string;
  hubspot_deal_id: string;
  amount: string;
  numero_da_operacao: string;
}

interface ModalCreateActivityProps {
  isOpen: boolean;
  onRequestClose: () => void;
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (actvities: IActivitiesInADayDTO) => void;
  hubspotDeals: IHubspotDeal[];
  currentUserId: string;
}

export function ModalCreateActivity({
  isOpen,
  onRequestClose,
  activitiesInADay,
  setActivitiesInADay,
  hubspotDeals,
  currentUserId,
}: ModalCreateActivityProps): JSX.Element {
  const { user } = useAuth();
  const [formData, setFormData] = useState<FormData>({} as FormData);

  const [intervals, setIntervals] = useState<IIntervalsDTO>(
    {} as IIntervalsDTO,
  );

  const [date, setDate] = useState<Date>(new Date());
  const typesActivity = [
    'Passagem de bastão',
    'Retorno credor',
    'Conferir emissão CND',
    'Validar honorário',
    'Cartório',
    'Reunião',
    'Outro',
    'Lembrete',
  ];
  const typesManagerActivity = [
    'Fácil resolução',
    'Média resolução',
    'Vai rolar',
    'Cartório',
  ];
  const currentTypesActivity =
    user.profile === 'Customer Success' ||
    user.profile === 'Customer Success Calculator'
      ? typesActivity
      : typesManagerActivity;

  useEffect(() => {
    if (!isOpen) {
      setFormData({} as FormData);
    }
  }, [isOpen]);

  const getAvailableHours = useCallback(async () => {
    await api
      .get(
        `/schedule-cs/available-hours?date=${format(
          date,
          'yyyy-MM-dd',
        )}&user_id=${user.id}`,
      )
      .then(response => {
        setIntervals(response.data);
      });
  }, [date, user.id]);

  useQuery('infos', getAvailableHours, {
    refetchInterval: 60 * 1000 * 15,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    getAvailableHours();
  }, [date, getAvailableHours, user.id]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      const schema =
        formData.type !== 'Lembrete'
          ? formData.type !== 'Reunião'
            ? yup.object().shape({
                title: yup.string().required('Título obrigatório'),
                type: yup.string().required('Tipo obrigatório'),
                time_init: yup.string().required('Horário inicial obrigatório'),
                time_end: yup.string().required('Horário final obrigatório'),
                hubspot_deal_id: yup
                  .string()
                  .required('Dados do negócio obrigatório'),
              })
            : yup.object().shape({
                title: yup.string().required('Título obrigatório'),
                type: yup.string().required('Tipo obrigatório'),
                time_init: yup.string().required('Horário inicial obrigatório'),
                time_end: yup.string().required('Horário final obrigatório'),
              })
          : yup.object().shape({
              title: yup.string().required('Título obrigatório'),
              type: yup.string().required('Tipo obrigatório'),
            });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const timeInit =
        formData.type !== 'Lembrete'
          ? `${format(date, 'yyyy-MM-dd')} ${formData.time_init}`
          : `${format(date, 'yyyy-MM-dd')}`;
      const timeEnd =
        formData.type !== 'Lembrete'
          ? `${format(date, 'yyyy-MM-dd')} ${formData.time_end}`
          : `${format(date, 'yyyy-MM-dd')}`;

      if (formData.type === 'Reunião') {
        const response = await api.post('/schedule-cs', {
          title: formData.title,
          type: formData.type,
          time_init: timeInit,
          time_end: timeEnd,
          observation: formData.observation,
          user_id: user.id,
          situation: 'Pendente',
        });

        const index = activitiesInADay.activities.findIndex(day_hour =>
          isEqual(parseISO(String(day_hour.hour)), parseISO(timeInit)),
        );

        if (index >= 0) {
          activitiesInADay.activities[index].activities.push(response.data);
          setActivitiesInADay(activitiesInADay);
        }
      } else if (formData.type === 'Lembrete') {
        const response = await api.post('/schedule-cs', {
          user_id:
            user.profile === 'Gestor Customer Success'
              ? currentUserId
              : user.id,
          title: formData.title,
          type: formData.type,
          time_init: timeInit,
          time_end: timeEnd,
          observation: formData.observation,
          hubspot_deal_id: formData.hubspot_deal_id,
          situation: 'Pendente',
          manager_id:
            user.profile === 'Gestor Customer Success' ? user.id : undefined,
        });

        activitiesInADay.reminders.push(response.data);
        setActivitiesInADay(activitiesInADay);
      } else {
        const response = await api.post('/schedule-cs', {
          user_id:
            user.profile === 'Gestor Customer Success'
              ? currentUserId
              : user.id,
          title: formData.title,
          type: formData.type,
          time_init: timeInit,
          time_end: timeEnd,
          observation: formData.observation,
          hubspot_deal_id: formData.hubspot_deal_id,
          situation: 'Pendente',
          manager_id:
            user.profile === 'Gestor Customer Success' ? user.id : undefined,
        });

        const index = activitiesInADay.activities.findIndex(day_hour =>
          isEqual(parseISO(String(day_hour.hour)), parseISO(timeInit)),
        );

        if (index >= 0) {
          activitiesInADay.activities[index].activities.push(response.data);
          setActivitiesInADay(activitiesInADay);
        }
      }

      setFormData({} as FormData);

      onRequestClose();

      toast.success('Atividade criada com sucesso!');
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      if (error instanceof yup.ValidationError) {
        toast.error(error.errors[0]);

        return;
      }

      Swal.fire('Atenção!', error.response.data.message, 'warning');
    }
  }, [
    formData,
    date,
    onRequestClose,
    user.id,
    user.profile,
    activitiesInADay,
    setActivitiesInADay,
    currentUserId,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={Container}>
      <Title name="Nova Atividade" />

      <Loading />

      <FieldSpan className="big-field" style={{ marginTop: '1rem' }}>
        Título da Atividade
      </FieldSpan>
      <TitleInput
        name="title"
        placeholder="Título da Atividade"
        onChange={handleInputChange}
      />

      <Main>
        <ColumnContainer>
          <FieldSpan className="small-field">Escolha uma data:</FieldSpan>

          <ContainerDayPicker>
            <ContainerInputDate>
              <CalendarIcon />
              <DayPicker value={date} handleChange={day => setDate(day)} />
            </ContainerInputDate>
          </ContainerDayPicker>

          {formData.type !== 'Lembrete' ? (
            <FieldContainer>
              <FieldSpan className="small-field">
                Selecione um horário:
              </FieldSpan>
              <InputsTimeContainer>
                <Select
                  id="time_init"
                  name="time_init"
                  defaultValue="Horário inicial"
                  onChange={handleInputChange}
                >
                  <option value="Horário inicial" disabled>
                    Horário inicial
                  </option>

                  {intervals.intervalInit &&
                    intervals.intervalInit.map(hour => (
                      <option
                        key={String(hour)}
                        value={format(new Date(hour), 'HH:mm')}
                      >
                        {format(new Date(hour), 'HH:mm')}
                      </option>
                    ))}
                </Select>

                <span id="intervalText">às</span>

                <Select
                  id="time_end"
                  name="time_end"
                  defaultValue="Horário final"
                  onChange={handleInputChange}
                >
                  <option value="Horário final" disabled>
                    Horário final
                  </option>

                  {intervals.intervalEnd &&
                    intervals.intervalEnd.map(hour => (
                      <option
                        key={String(hour)}
                        value={format(new Date(hour), 'HH:mm')}
                      >
                        {format(new Date(hour), 'HH:mm')}
                      </option>
                    ))}
                </Select>
              </InputsTimeContainer>
            </FieldContainer>
          ) : null}

          <FieldContainer>
            <FieldSpan className="small-field">Observação:</FieldSpan>

            <InputsDeal>
              <Textarea
                id="observation"
                name="observation"
                placeholder="Digite aqui sua observação"
                onChange={handleInputChange}
              />
            </InputsDeal>
          </FieldContainer>
        </ColumnContainer>

        <ColumnContainer>
          <FieldContainer>
            <FieldSpan className="small-field">
              Escolha uma atividade:
            </FieldSpan>

            <NamesActivityContainer>
              {currentTypesActivity.map(type => (
                <ScheduleOptionsButton
                  key={type}
                  createActivity
                  selectedValue={formData.type === type}
                  type="button"
                  onClick={() =>
                    setFormData({
                      ...formData,
                      type,
                    })
                  }
                >
                  {type}
                </ScheduleOptionsButton>
              ))}
            </NamesActivityContainer>
          </FieldContainer>

          {formData.type !== 'Reunião' && (
            <FieldContainer>
              <FieldSpan className="small-field">Dados do negócio:</FieldSpan>

              <Select
                id="hubspot_deal_id"
                name="hubspot_deal_id"
                className="hubspot-deal"
                defaultValue="Escolha um Deal"
                onChange={handleInputChange}
              >
                <option value="Escolha um Deal" disabled>
                  Escolha um Deal
                </option>

                {hubspotDeals &&
                  hubspotDeals.map(hubspotDeal => (
                    <option
                      key={hubspotDeal.hubspot_deal_id}
                      value={hubspotDeal.hubspot_deal_id}
                    >
                      {hubspotDeal.dealname} - {hubspotDeal.numero_da_operacao}
                    </option>
                  ))}
              </Select>
            </FieldContainer>
          )}
        </ColumnContainer>
      </Main>

      <ButtonContainer>
        <ButtonToSchedule onClick={handleSubmit}>
          <AiOutlineCheck />
          Agendar
        </ButtonToSchedule>
      </ButtonContainer>
    </Modal>
  );
}
