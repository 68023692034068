export function cleanString(text: string): string {
  let str = text || '';

  str = str.replace(/^\s+|\s+$/g, ''); // remover espaco do comeco e do fim
  str = str.toLowerCase();

  // remover acentuacao
  const from = 'ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remover caracteres inválidos
    .replace(/\s+/g, '') // Remover espaços
    .replace(/-+/g, ''); // Remover traços

  return str;
}
