import { shade } from 'polished';
import styled from 'styled-components';

export const FormSessionContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 21.25rem);
  justify-content: space-between;
  gap: 1rem;

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 21.25rem);
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .hubspot_id {
    color: var(--blue-700);
    font-size: 1.1rem;
  }

  .transaction {
    color: var(--gray-600);
    font-size: 1rem;
  }

  .business-data {
    border: none;
  }

  .currency_input {
    width: 21.25rem;
    height: 2.25rem;

    background: var(--gray-100);
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--blue-600);
    color: var(--blue-700);

    font-weight: 400;
    font-size: 0.875rem;
  }
`;

export const Label = styled.label`
  font-weight: 500;
  margin-bottom: 0.2rem;
`;

export const Input = styled.input`
  width: 21.25rem;
  height: 2.25rem;

  background: var(--gray-100);
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--blue-600);
  color: var(--blue-700);

  font-weight: 400;
  font-size: 0.875rem;
`;

export const Select = styled.select`
  width: 21.25rem;
  height: 2.25rem;

  background: var(--gray-100);
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--blue-600);
  color: var(--blue-700);

  font-weight: 400;
  font-size: 0.875rem;

  cursor: pointer;
`;

export const SubtitleFormSession = styled.span`
  width: 100%;
  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--blue-100);
  border-radius: 0.25rem;
  margin: 2rem 0;

  font-weight: 400;
  font-size: 1.25rem;
  color: var(--blue-700);
`;

export const NewPaymentButton = styled.button`
  width: 9.75rem;
  height: 2.25rem;
  border-radius: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.313rem;

  transition: background-color 0.2s;
  background: var(--green-600);
  border-radius: 0.5rem;
  margin: 2rem 0;
  border: none;

  color: var(--white);
  font-weight: 600;
  font-size: 0.813rem;

  &:hover {
    background: ${shade(0.3, '#089B63')};
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
