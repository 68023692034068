import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { gql, useSubscription } from '@apollo/client';
import watch from '../../../../assets/icons/watch.svg';
import { ReactComponent as OrderCrescent } from '../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../assets/icons/polygon_down.svg';

import {
  GuidesContainer,
  Guide,
  TitleGuideContainer,
  LineHorizontal,
  Table,
  Thead,
  TableRow,
  Cell,
  ContainerTitle,
  OrderContainerIcon,
  ColoredCell,
  Tbody,
  Timetable,
  IconHour,
  Image,
  Time,
  LineTable,
  CellContainer,
  Legend,
  Info,
  LineSeparator,
  InfoCredor,
  TextInfo,
  ButtonSituation,
} from './styles';

import api from '../../../../services/api';
import { orderInfoTableByAsc } from '../../../../utils/orderInfoTableByAsc';
import { orderInfoTableByDesc } from '../../../../utils/orderInfoTableByDesc';
import { PaginationComponent } from '../../../../components/Pagination';
import { changePagination } from '../../../../utils/changePagination';

interface IHunters {
  hunter_id: string;
  hunter_name: string | undefined;
  hunter_avatar: string | undefined;
  ticket: number;
  schedulings: number;
  team: string;
}

interface IHuntersActivities {
  line1: IHunters[];
  line2: IHunters[];
  line3: IHunters[];
  line4: IHunters[];
  line6: IHunters[];
  virtual: IHunters[];
  schedulingsLine1: number;
  schedulingsLine2: number;
  schedulingsLine3: number;
  schedulingsLine4: number;
  schedulingsLine6: number;
  schedulingsVirtual: number;
}

interface ISchedulings {
  hunter_name: string;
  closer_name: string;
  amount: string;
  connection: string;
  contact: string;
  creditor_name: string;
  link_hubspot: string;
  timestamp: Date;
  schedule_id: string;
  schedule_status: string;
  schedule_try_times: number;
}

interface ISchedulingsByTeam {
  line1: ISchedulings[];
  line2: ISchedulings[];
  line3: ISchedulings[];
  line4: ISchedulings[];
  line6: ISchedulings[];
  virtual: ISchedulings[];
}

interface IHunterHistory {
  scheduleDate: Date;
  filterHuntersHistory: string;
  setSelectHunterTeam: (selectHunterTeam: string) => void;
  setFilterHuntersHistory: (selectHunterTeam: string) => void;
}

export const HuntersHistory: React.FC<IHunterHistory> = ({
  scheduleDate,
  filterHuntersHistory,
  setSelectHunterTeam,
  setFilterHuntersHistory,
}: IHunterHistory) => {
  const [huntersInfo, setHuntersInfo] = useState<IHuntersActivities>(
    {} as IHuntersActivities,
  );
  const [huntersScheduling, setHuntersScheduling] =
    useState<ISchedulingsByTeam>({} as ISchedulingsByTeam);

  const [isOrder, setIsOrder] = useState(false);
  const [allSchedulings, setAllSchedulings] = useState<ISchedulings[]>(
    [] as ISchedulings[],
  );
  const [teamsOption, setTeamsOption] = useState<string>('Linha 1');

  const arrayTeams = [
    { team: 'Linha 1', schedulings: huntersInfo.schedulingsLine1 },
    { team: 'Linha 2', schedulings: huntersInfo.schedulingsLine2 },
    { team: 'Linha 3', schedulings: huntersInfo.schedulingsLine3 },
    { team: 'Linha 4', schedulings: huntersInfo.schedulingsLine4 },
    { team: 'Linha 6', schedulings: huntersInfo.schedulingsLine6 },
    { team: 'Virtual', schedulings: huntersInfo.schedulingsVirtual },
    {
      team: 'Todos',
      schedulings:
        huntersInfo.schedulingsLine1 +
        huntersInfo.schedulingsLine2 +
        huntersInfo.schedulingsLine3 +
        huntersInfo.schedulingsLine4 +
        huntersInfo.schedulingsLine6 +
        huntersInfo.schedulingsVirtual,
    },
  ];

  const itemsPerPage = 7;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);

  const [currentItems, setCurrentItems] = useState<ISchedulings[]>(
    [] as ISchedulings[],
  );

  const history = useHistory();

  const REFRESH = gql`
    subscription Subscription {
      refreshPanel
    }
  `;

  const refresh = useSubscription(REFRESH);

  useEffect(() => {
    if (refresh.data) {
      api
        .get(`/schedule/get-hunters-info/${format(scheduleDate, 'yyyy-MM-dd')}`)
        .then(response => {
          setHuntersInfo(response.data);
        });
    }
  }, [refresh, scheduleDate]);

  useEffect(() => {
    api
      .get(`/schedule/get-hunters-info/${format(scheduleDate, 'yyyy-MM-dd')}`)
      .then(response => {
        setHuntersInfo(response.data);
      });
  }, [scheduleDate]);

  useEffect(() => {
    api
      .get(
        `/schedule/list-hunter-schedulings-by-team/${format(
          scheduleDate,
          'yyyy-MM-dd',
        )}`,
      )
      .then(response => {
        setHuntersScheduling(response.data);

        setCurrentItems(response.data.line1.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(response.data.line1.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      });
  }, [scheduleDate]);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      let items: ISchedulings[];

      if (teamsOption === 'Linha 1') {
        items = huntersScheduling.line1;
      } else if (teamsOption === 'Linha 2') {
        items = huntersScheduling.line2;
      } else if (teamsOption === 'Linha 3') {
        items = huntersScheduling.line3;
      } else if (teamsOption === 'Linha 4') {
        items = huntersScheduling.line4;
      } else if (teamsOption === 'Linha 6') {
        items = huntersScheduling.line6;
      } else if (teamsOption === 'Virtual') {
        items = huntersScheduling.virtual;
      } else {
        items = allSchedulings;
      }

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [allSchedulings, huntersScheduling, page, teamsOption, totalPages],
  );

  const handleSelectTeam = useCallback(
    (teamName: string) => {
      setSelectHunterTeam(teamName);
      setTeamsOption(teamName);

      setPage(1);

      if (teamName === 'Linha 1') {
        setFilterHuntersHistory('Individual');
        setCurrentItems(huntersScheduling.line1.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(huntersScheduling.line1.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      } else if (teamName === 'Linha 2') {
        setFilterHuntersHistory('Individual');
        setCurrentItems(huntersScheduling.line2.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(huntersScheduling.line2.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      } else if (teamName === 'Linha 3') {
        setFilterHuntersHistory('Individual');
        setCurrentItems(huntersScheduling.line3.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(huntersScheduling.line3.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      } else if (teamName === 'Linha 4') {
        setFilterHuntersHistory('Individual');
        setCurrentItems(huntersScheduling.line4.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(huntersScheduling.line4.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      } else if (teamName === 'Linha 6') {
        setFilterHuntersHistory('Individual');
        setCurrentItems(huntersScheduling.line6.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(huntersScheduling.line6.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      } else if (teamName === 'Virtual') {
        setFilterHuntersHistory('Individual');
        setCurrentItems(huntersScheduling.virtual.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(huntersScheduling.virtual.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      } else {
        setFilterHuntersHistory('Todos');

        const allItems = [
          ...huntersScheduling.line1,
          ...huntersScheduling.line2,
          ...huntersScheduling.line3,
          ...huntersScheduling.line4,
          ...huntersScheduling.line6,
          ...huntersScheduling.virtual,
        ];

        allItems.sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        );

        setAllSchedulings(allItems);

        setCurrentItems(allItems.slice(0, itemsPerPage));

        const pages: number[] = [];

        for (
          let index = 1;
          index <= Math.ceil(allItems.length / itemsPerPage);
          index++
        ) {
          pages.push(index);
        }

        setTotalPages(pages);
      }
    },
    [huntersScheduling, setFilterHuntersHistory, setSelectHunterTeam],
  );

  const orderInfoCloserByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      let order = null;

      switch (teamsOption) {
        case 'Linha 1':
          order = orderInfoTableByAsc(column, huntersInfo.line1);
          setHuntersInfo({ ...huntersInfo, line1: order });
          break;

        case 'Linha 2':
          order = orderInfoTableByAsc(column, huntersInfo.line2);
          setHuntersInfo({ ...huntersInfo, line2: order });
          break;

        case 'Linha 3':
          order = orderInfoTableByAsc(column, huntersInfo.line3);
          setHuntersInfo({ ...huntersInfo, line3: order });
          break;
        case 'Linha 4':
          order = orderInfoTableByAsc(column, huntersInfo.line4);
          setHuntersInfo({ ...huntersInfo, line4: order });
          break;
        case 'Linha 6':
          order = orderInfoTableByAsc(column, huntersInfo.line6);
          setHuntersInfo({ ...huntersInfo, line6: order });
          break;
        case 'Virtual':
          order = orderInfoTableByAsc(column, huntersInfo.virtual);
          setHuntersInfo({ ...huntersInfo, virtual: order });
          break;

        default:
          break;
      }
    },
    [huntersInfo, isOrder, teamsOption],
  );

  const orderInfoCloserByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      let order = null;

      switch (teamsOption) {
        case 'Linha 1':
          order = orderInfoTableByDesc(column, huntersInfo.line1);
          setHuntersInfo({ ...huntersInfo, line1: order });
          break;

        case 'Linha 2':
          order = orderInfoTableByDesc(column, huntersInfo.line2);
          setHuntersInfo({ ...huntersInfo, line2: order });
          break;

        case 'Linha 3':
          order = orderInfoTableByDesc(column, huntersInfo.line3);
          setHuntersInfo({ ...huntersInfo, line3: order });
          break;

        case 'Linha 4':
          order = orderInfoTableByDesc(column, huntersInfo.line4);
          setHuntersInfo({ ...huntersInfo, line4: order });
          break;

        case 'Linha 6':
          order = orderInfoTableByDesc(column, huntersInfo.line6);
          setHuntersInfo({ ...huntersInfo, line6: order });
          break;

        case 'Virtual':
          order = orderInfoTableByDesc(column, huntersInfo.virtual);
          setHuntersInfo({ ...huntersInfo, virtual: order });
          break;

        default:
          break;
      }
    },
    [huntersInfo, isOrder, teamsOption],
  );

  useEffect(() => {
    if (teamsOption === 'Todos') {
      let allItems = huntersScheduling.line1
        .concat(huntersScheduling.line2)
        .concat(huntersScheduling.line3)
        .concat(huntersScheduling.line4)
        .concat(huntersScheduling.line6)
        .concat(huntersScheduling.virtual);

      allItems.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      );

      if (filterHuntersHistory === 'Tentou') {
        allItems = allItems.filter(
          items =>
            items.schedule_status === 'Pendente' &&
            items.schedule_try_times > 0,
        );
      } else if (filterHuntersHistory === 'Pendente') {
        allItems = allItems.filter(
          items =>
            items.schedule_status === filterHuntersHistory &&
            items.schedule_try_times < 1,
        );
      } else if (filterHuntersHistory !== 'Todos') {
        allItems = allItems.filter(
          items => items.schedule_status === filterHuntersHistory,
        );
      }

      setCurrentItems(allItems.slice(0, itemsPerPage));
      setAllSchedulings(allItems);

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(allItems.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }

      setTotalPages(pages);
    }
  }, [filterHuntersHistory, huntersScheduling, teamsOption]);

  const handleRedirectToIndividualHunterHistory = useCallback(
    (hunter: IHunters) => {
      history.push({
        pathname: '/negociations/schedule/manager/hunter-history/individual',
        state: {
          hunter_id: hunter.hunter_id,
          type: 'Manager',
          date: scheduleDate,
        },
      });
    },
    [history, scheduleDate],
  );

  const formatTicket = useCallback((ticket: string) => {
    const formattedTicket =
      ticket === '0'
        ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(0))
        : new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(ticket));
    return formattedTicket;
  }, []);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  return (
    <>
      <GuidesContainer>
        {arrayTeams.map(team => (
          <Guide
            key={team.team}
            isSelected={teamsOption === team.team}
            onClick={() => handleSelectTeam(team.team)}
          >
            <TitleGuideContainer>
              <span>{team.team}</span>
              <span>{team.schedulings}</span>
            </TitleGuideContainer>

            <LineHorizontal />
          </Guide>
        ))}
      </GuidesContainer>
      {filterHuntersHistory === 'Individual' && teamsOption !== 'Todos' ? (
        <Table>
          <Thead>
            <TableRow>
              <Cell />
              <Cell
                onClick={() =>
                  isOrder
                    ? orderInfoCloserByAsc('hunter_name')
                    : orderInfoCloserByDesc('hunter_name')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                <ContainerTitle>Hunter</ContainerTitle>
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>

              <Cell
                onClick={() =>
                  isOrder
                    ? orderInfoCloserByAsc('ticket')
                    : orderInfoCloserByDesc('ticket')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                <ContainerTitle>Ticket médio</ContainerTitle>
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>

              <Cell
                onClick={() =>
                  isOrder
                    ? orderInfoCloserByAsc('schedulings')
                    : orderInfoCloserByDesc('schedulings')
                }
              >
                <ContainerTitle>Agendamentos</ContainerTitle>
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
            </TableRow>
          </Thead>
          <Tbody>
            {huntersInfo.line1 &&
              teamsOption === 'Linha 1' &&
              huntersInfo.line1.map(hunter => (
                <TableRow
                  key={hunter.hunter_id}
                  onClick={() =>
                    handleRedirectToIndividualHunterHistory(hunter)
                  }
                >
                  <Cell>
                    <img src={hunter.hunter_avatar} alt="Foto closer" />
                  </Cell>
                  <Cell>
                    <span>{hunter.hunter_name}</span>
                  </Cell>
                  <Cell>{formatTicket(String(hunter.ticket))}</Cell>

                  <ColoredCell quantidade={hunter.schedulings}>
                    <strong>{hunter.schedulings}</strong>
                  </ColoredCell>
                </TableRow>
              ))}

            {huntersInfo.line2 &&
              teamsOption === 'Linha 2' &&
              huntersInfo.line2.map(hunter => (
                <TableRow
                  key={hunter.hunter_id}
                  onClick={() =>
                    handleRedirectToIndividualHunterHistory(hunter)
                  }
                >
                  <Cell>
                    <img src={hunter.hunter_avatar} alt="Foto closer" />
                  </Cell>
                  <Cell>
                    <span>{hunter.hunter_name}</span>
                  </Cell>
                  <Cell>{formatTicket(String(hunter.ticket))}</Cell>

                  <ColoredCell quantidade={hunter.schedulings}>
                    <strong>{hunter.schedulings}</strong>
                  </ColoredCell>
                </TableRow>
              ))}

            {huntersInfo.line3 &&
              teamsOption === 'Linha 3' &&
              huntersInfo.line3.map(hunter => (
                <TableRow
                  key={hunter.hunter_id}
                  onClick={() =>
                    handleRedirectToIndividualHunterHistory(hunter)
                  }
                >
                  <Cell>
                    <img src={hunter.hunter_avatar} alt="Foto closer" />
                  </Cell>
                  <Cell>
                    <span>{hunter.hunter_name}</span>
                  </Cell>
                  <Cell>{formatTicket(String(hunter.ticket))}</Cell>

                  <ColoredCell quantidade={hunter.schedulings}>
                    <strong>{hunter.schedulings}</strong>
                  </ColoredCell>
                </TableRow>
              ))}

            {huntersInfo.line4 &&
              teamsOption === 'Linha 4' &&
              huntersInfo.line4.map(hunter => (
                <TableRow
                  key={hunter.hunter_id}
                  onClick={() =>
                    handleRedirectToIndividualHunterHistory(hunter)
                  }
                >
                  <Cell>
                    <img src={hunter.hunter_avatar} alt="Foto closer" />
                  </Cell>
                  <Cell>
                    <span>{hunter.hunter_name}</span>
                  </Cell>
                  <Cell>{formatTicket(String(hunter.ticket))}</Cell>

                  <ColoredCell quantidade={hunter.schedulings}>
                    <strong>{hunter.schedulings}</strong>
                  </ColoredCell>
                </TableRow>
              ))}

            {huntersInfo.line6 &&
              teamsOption === 'Linha 6' &&
              huntersInfo.line6.map(hunter => (
                <TableRow
                  key={hunter.hunter_id}
                  onClick={() =>
                    handleRedirectToIndividualHunterHistory(hunter)
                  }
                >
                  <Cell>
                    <img src={hunter.hunter_avatar} alt="Foto closer" />
                  </Cell>
                  <Cell>
                    <span>{hunter.hunter_name}</span>
                  </Cell>
                  <Cell>{formatTicket(String(hunter.ticket))}</Cell>

                  <ColoredCell quantidade={hunter.schedulings}>
                    <strong>{hunter.schedulings}</strong>
                  </ColoredCell>
                </TableRow>
              ))}

            {huntersInfo.virtual &&
              teamsOption === 'Virtual' &&
              huntersInfo.virtual.map(hunter => (
                <TableRow
                  key={hunter.hunter_id}
                  onClick={() =>
                    handleRedirectToIndividualHunterHistory(hunter)
                  }
                >
                  <Cell>
                    <img src={hunter.hunter_avatar} alt="Foto closer" />
                  </Cell>
                  <Cell>
                    <span>{hunter.hunter_name}</span>
                  </Cell>
                  <Cell>{formatTicket(String(hunter.ticket))}</Cell>

                  <ColoredCell quantidade={hunter.schedulings}>
                    <strong>{hunter.schedulings}</strong>
                  </ColoredCell>
                </TableRow>
              ))}
          </Tbody>
        </Table>
      ) : teamsOption !== 'Todos' ? (
        currentItems &&
        currentItems.map(scheduling => (
          <Timetable key={scheduling.schedule_id}>
            <IconHour>
              <Image src={watch} alt="Relógio" />
              <Time>{formattedTime(scheduling.timestamp)}</Time>
            </IconHour>

            <LineTable
              talkProps={String(scheduling.schedule_try_times)}
              statusTaskProps={
                scheduling.schedule_status
                  ? scheduling.schedule_status
                  : 'Pendente'
              }
              existTryTimesProps={!!scheduling.schedule_try_times}
              tryTimesProps={String(scheduling.schedule_try_times)}
            >
              <CellContainer className="hunter">
                <Legend>Hunter:</Legend>
                <Info>{scheduling.hunter_name}</Info>
              </CellContainer>

              <LineSeparator />

              <CellContainer className="closer">
                <Legend>Closer:</Legend>
                <Info>{scheduling.closer_name}</Info>
              </CellContainer>

              <LineSeparator />

              <CellContainer className="value">
                <Legend>Valor:</Legend>
                <Info>{scheduling.amount}</Info>
              </CellContainer>

              <LineSeparator />

              <CellContainer className="connection">
                <Legend>Conexão:</Legend>
                <Info>{scheduling.connection}</Info>
              </CellContainer>

              <LineSeparator />

              <CellContainer className="contact">
                <Legend>Contato:</Legend>
                <Info>{scheduling.contact}</Info>
              </CellContainer>

              <LineSeparator />

              <CellContainer className="credor">
                <InfoCredor>
                  <TextInfo>
                    <Legend>Credor:</Legend>
                    <Info>{scheduling.creditor_name}</Info>
                  </TextInfo>

                  <TextInfo>
                    <Legend>Link Hubspot: </Legend>
                    <Info>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={scheduling.link_hubspot}
                      >
                        https://app.hub...
                      </a>
                    </Info>
                  </TextInfo>
                </InfoCredor>
                {scheduling.schedule_status && (
                  <ButtonSituation
                    statusTaskProps={
                      scheduling.schedule_try_times > 0 &&
                      scheduling.schedule_status === 'Pendente'
                        ? 'Tentou'
                        : scheduling.schedule_status
                    }
                    tryTimesProps={String(scheduling.schedule_try_times)}
                  >
                    {scheduling.schedule_try_times > 0 &&
                    scheduling.schedule_status === 'Pendente'
                      ? 'Tentou'
                      : scheduling.schedule_status}
                  </ButtonSituation>
                )}
              </CellContainer>
            </LineTable>
          </Timetable>
        ))
      ) : (
        <>
          {currentItems &&
            currentItems.map(scheduling =>
              scheduling.schedule_status === filterHuntersHistory ? (
                <Timetable key={scheduling.schedule_id}>
                  <IconHour>
                    <Image src={watch} alt="Relógio" />
                    <Time>{formattedTime(scheduling.timestamp)}</Time>
                  </IconHour>

                  <LineTable
                    talkProps={String(scheduling.schedule_try_times)}
                    statusTaskProps={
                      scheduling.schedule_status
                        ? scheduling.schedule_status
                        : 'Pendente'
                    }
                    existTryTimesProps={!!scheduling.schedule_try_times}
                    tryTimesProps={String(scheduling.schedule_try_times)}
                  >
                    <CellContainer className="hunter">
                      <Legend>Hunter:</Legend>
                      <Info>{scheduling.hunter_name}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="closer">
                      <Legend>Closer:</Legend>
                      <Info>{scheduling.closer_name}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="value">
                      <Legend>Valor:</Legend>
                      <Info>{scheduling.amount}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="connection">
                      <Legend>Conexão:</Legend>
                      <Info>{scheduling.connection}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="contact">
                      <Legend>Contato:</Legend>
                      <Info>{scheduling.contact}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="credor">
                      <InfoCredor>
                        <TextInfo>
                          <Legend>Credor:</Legend>
                          <Info>{scheduling.creditor_name}</Info>
                        </TextInfo>

                        <TextInfo>
                          <Legend>Link Hubspot: </Legend>
                          <Info>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={scheduling.link_hubspot}
                            >
                              https://app.hub...
                            </a>
                          </Info>
                        </TextInfo>
                      </InfoCredor>
                      {scheduling.schedule_status && (
                        <ButtonSituation
                          statusTaskProps={
                            scheduling.schedule_try_times > 0 &&
                            scheduling.schedule_status === 'Pendente'
                              ? 'Tentou'
                              : scheduling.schedule_status
                          }
                          tryTimesProps={String(scheduling.schedule_try_times)}
                        >
                          {scheduling.schedule_try_times > 0 &&
                          scheduling.schedule_status === 'Pendente'
                            ? 'Tentou'
                            : scheduling.schedule_status}
                        </ButtonSituation>
                      )}
                    </CellContainer>
                  </LineTable>
                </Timetable>
              ) : (
                <Timetable key={scheduling.schedule_id}>
                  <IconHour>
                    <Image src={watch} alt="Relógio" />
                    <Time>{formattedTime(scheduling.timestamp)}</Time>
                  </IconHour>

                  <LineTable
                    talkProps={String(scheduling.schedule_try_times)}
                    statusTaskProps={
                      scheduling.schedule_status
                        ? scheduling.schedule_status
                        : 'Pendente'
                    }
                    existTryTimesProps={!!scheduling.schedule_try_times}
                    tryTimesProps={String(scheduling.schedule_try_times)}
                  >
                    <CellContainer className="hunter">
                      <Legend>Hunter:</Legend>
                      <Info>{scheduling.hunter_name}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="closer">
                      <Legend>Closer:</Legend>
                      <Info>{scheduling.closer_name}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="value">
                      <Legend>Valor:</Legend>
                      <Info>{scheduling.amount}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="connection">
                      <Legend>Conexão:</Legend>
                      <Info>{scheduling.connection}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="contact">
                      <Legend>Contato:</Legend>
                      <Info>{scheduling.contact}</Info>
                    </CellContainer>

                    <LineSeparator />

                    <CellContainer className="credor">
                      <InfoCredor>
                        <TextInfo>
                          <Legend>Credor:</Legend>
                          <Info>{scheduling.creditor_name}</Info>
                        </TextInfo>

                        <TextInfo>
                          <Legend>Link Hubspot: </Legend>
                          <Info>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={scheduling.link_hubspot}
                            >
                              https://app.hub...
                            </a>
                          </Info>
                        </TextInfo>
                      </InfoCredor>
                      {scheduling.schedule_status && (
                        <ButtonSituation
                          statusTaskProps={
                            scheduling.schedule_try_times > 0 &&
                            scheduling.schedule_status === 'Pendente'
                              ? 'Tentou'
                              : scheduling.schedule_status
                          }
                          tryTimesProps={String(scheduling.schedule_try_times)}
                        >
                          {scheduling.schedule_try_times > 0 &&
                          scheduling.schedule_status === 'Pendente'
                            ? 'Tentou'
                            : scheduling.schedule_status}
                        </ButtonSituation>
                      )}
                    </CellContainer>
                  </LineTable>
                </Timetable>
              ),
            )}
        </>
      )}

      <PaginationComponent
        totalPages={totalPages}
        page={page}
        handleChangePagination={handleChangePagination}
      />
    </>
  );
};
