import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react';

import { Form } from '@unform/web';
import { AiOutlinePlus, AiOutlineArrowLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Container } from '../../../components/Container';
import {
  Icon,
  Line,
  BackButtonContainer,
  FormContainer,
  FormGroup,
  Label,
  TitleContainer,
  TitleHeader,
  Input,
  InputContainer,
  ButtonCreate,
  ButtonsContainerEditTeam,
  DivSeparator,
  Separator,
} from './styles';
import api from '../../../services/api';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Styles/Title';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';

interface Permission {
  id: string;
  name: string;
  description: string;
}

const CreatePermission: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    api.get('/users/permissions').then(response => {
      setPermissions(response.data);
    });
  }, []);

  const [formData, setFormData] = useState({
    namePermission: '',
    descriptionPermission: '',
    permission_delete: '',
    permission_name: '',
    permission_id: '',
  });

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function PermissionError() {
    Swal.fire(
      'Erro!',
      'Verifique os dados preenchidos e tente novamente.',
      'error',
    );
  }

  function createPermissionSuccess() {
    toast.success('Permissão criada com sucesso');
  }

  async function createPermission() {
    try {
      let permission_name;
      let permission_description;

      if (formData.namePermission === '') {
        permission_name = null;
      } else {
        permission_name = formData.namePermission;
      }

      if (formData.descriptionPermission === '') {
        permission_description = null;
      } else {
        permission_description = formData.descriptionPermission;
      }

      const { data } = await api.post('/users/permissions', {
        name: permission_name,
        description: permission_description,
      });

      setPermissions([...permissions, data]);
      createPermissionSuccess();
    } catch (err) {
      PermissionError();
    }
  }

  return (
    <Container>
      <Navegation>
        <H1Navigation>
          Administração {'>'} Permissões {'>'}{' '}
        </H1Navigation>
        <H1NavigationNow>Nova permissão</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Nova Permissão</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <BackButtonContainer>
          <Link to="/administration/permissions">
            <Icon>
              <AiOutlineArrowLeft />
            </Icon>
            Voltar
          </Link>
        </BackButtonContainer>

        <TitleContainer>
          <TitleHeader>Dados de acesso</TitleHeader>
        </TitleContainer>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={() => {
              // eslint-disable-next-line no-console
              console.log('Teste');
            }}
          >
            <Line />
            <FormGroup>
              <InputContainer>
                <Label>Informe o nome da permissão</Label>
                <Input
                  id="namePermission"
                  name="namePermission"
                  type="text"
                  onChange={handleInputChange}
                />
              </InputContainer>
              <InputContainer>
                <Label>Selecione o contexto</Label>
                <select
                  className="select"
                  name="descriptionPermission"
                  onChange={handleSelectChange}
                >
                  <option>Selecione uma opção</option>
                  <option value="Nenhum">Nenhum</option>
                  <option value="Processamento">Processamento</option>
                  <option value="Negociação">Negociação</option>
                  <option value="Administração">Administração</option>
                  <option value="Vórtx">Vórtx</option>
                  <option value="Conexão">Conexão</option>
                  <option value="Newsletter">Newsletter</option>
                  <option value="Pagamento">Pagamento</option>
                </select>
              </InputContainer>
            </FormGroup>
          </Form>
        </FormContainer>

        <ButtonsContainerEditTeam>
          <Link to="/administration/permissions">
            <ButtonCreate type="button" onClick={createPermission}>
              <Icon>
                <AiOutlinePlus />
              </Icon>
              Criar Permissão
            </ButtonCreate>
          </Link>
        </ButtonsContainerEditTeam>
      </Body>
    </Container>
  );
};

export default CreatePermission;
