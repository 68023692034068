import styled from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';

export const LostModalStyle = {
  content: {
    width: '470px',
    height: '490px',
    margin: 'auto',
    padding: '0',
    overflow: 'auto',
    border: '1px solid #CFDEF3',
    borderRadius: '5px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const Main = styled.div`
  margin-bottom: ${pxToRem(16)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ApprovedButton = styled.button`
  width: 225px;
  height: 26px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: white;
  align-items: center;
  letter-spacing: -0.015em;
  background: #3ca647;

  border: 0;
  border-radius: 0.5rem;

  margin-bottom: 0.5rem;
`;

export const InputArea = styled.textarea`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 100%;
  height: 96px;
  padding: 0.25rem;
  transition: border 0.2s;
  text-align: justify;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 20px;
  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const SemiApprovedButton = styled.button`
  width: 225px;
  height: 26px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: white;
  align-items: center;
  letter-spacing: -0.015em;
  background: #b4ad09;

  border: 0;
  border-radius: 0.5rem;

  margin-bottom: 0.5rem;
`;

export const DeniedButton = styled.button`
  width: 225px;
  height: 26px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: white;
  align-items: center;
  letter-spacing: -0.015em;
  background: #ea3f3f;

  border: 0;
  border-radius: 0.5rem;
`;

export const BoxRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 33.4% 33.3% 33.3%;
`;
export const Title = styled.div`
  width: 468.5px;
  background-color: #d7e4f5;
  height: 1.5rem;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  font-size: 1.2rem;
  font-weight: 450;
`;

export const BoxHeader = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 2.25rem;
  width: 100%;

  background-color: #d7e4f5;
`;

export const BoxHeadCell = styled.th`
  font-family: Poppins;
  font-weight: 450;
  font-size: 1rem;
`;

export const BoxHeadFirstCell = styled.th`
  font-family: Poppins;
  font-weight: 450;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxBodyCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0 1rem;
`;

export const BoxBody = styled.tbody`
  width: 100%;
`;

export const BoxTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const BoxInput = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 100%;
  height: 70%;
  transition: border 0.2s;
  text-align: center;

  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const LoaInput = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 30%;
  height: 48%;
  transition: border 0.2s;
  margin-top: 0.6rem;
  text-align: center;

  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const ConcBoxRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 16.6% 16.6% 16.6%;
`;

export const ConcBoxTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
