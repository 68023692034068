interface formatLOA {
  loa_term_01: string;
  loa_term_02: string;
  loa_term_03: string;
  term_01: string;
  term_02: string;
  term_03: string;
}

export function formatStringLOA({
  loa_term_01,
  loa_term_02,
  loa_term_03,
  term_01,
  term_02,
  term_03,
}: formatLOA): string {
  const loaTerm01 = loa_term_01 || '';
  const loaTerm02 = loa_term_02 || '';
  const loaTerm03 = loa_term_03 || '';
  const term01 = term_01 || '';
  const term02 = term_02 || '';
  const term03 = term_03 || '';

  return `${loaTerm01}(${term01})#${loaTerm02}(${term02})#${loaTerm03}(${term03})#`;
}
