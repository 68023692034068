import { IWorkDayDTO } from '../dtos/IWorkDayDTO';
import {
  EditButton,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  VerticalSeparator,
} from './styles';

interface IDefaultTableProps {
  goToEditorMode: () => void;
  workTime: IWorkDayDTO[];
}

export function DefaultTable({
  goToEditorMode,
  workTime,
}: IDefaultTableProps): JSX.Element {
  const weekDays = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  return (
    <>
      <Table>
        <TableHead>
          <TableHeader width="13.5625rem">Dia da semana</TableHeader>
          <VerticalSeparator />
          <TableHeader width="7.25rem">Entrada</TableHeader>
          <VerticalSeparator />
          <TableHeader width="7.25rem">Saída</TableHeader>
        </TableHead>

        {workTime.map(workDay => {
          return (
            <TableRow className="tr-body" active={workDay.is_work_day}>
              <TableCell width="13.5625rem">
                {weekDays[workDay.week_day]}
              </TableCell>
              <TableCell width="7.25rem">
                {workDay.start_hour}:
                {workDay.start_minute < 10
                  ? `0${workDay.start_minute}`
                  : workDay.start_minute}
              </TableCell>
              <TableCell width="7.25rem">
                {workDay.finish_hour}:
                {workDay.finish_minute < 10
                  ? `0${workDay.finish_minute}`
                  : workDay.finish_minute}
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <EditButton onClick={goToEditorMode}>Editar</EditButton>
    </>
  );
}
