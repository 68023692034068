import { shade } from 'polished';
import styled from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const Dropdown = styled.button`
  width: ${pxToRem(155)};
  height: ${pxToRem(37)};
  color: var(--white);
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 600;
  font-size: ${pxToRem(13)};

  background: var(--blue-600);
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.3, '#063966')};
  }
`;

export const IconCreate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateActivityOptions = styled.div`
  width: ${pxToRem(155)};
  height: fit-content;
  margin-top: ${pxToRem(37)};

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  position: absolute;

  background: var(--white);
  border: 1.5px solid var(--blue-800);
  border-radius: 0.5rem;
  padding: 0;

  #first-option {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  #last-option {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const CreateActivity = styled.button`
  width: ${pxToRem(155)};
  height: ${pxToRem(25)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  border: none;
  background: none;

  font-weight: 600;
  font-size: ${pxToRem(13)};
  color: var(--blue-800);

  transition: 0.2s;

  :hover {
    background: var(--blue-800);
    color: var(--white);
  }
`;
