import Modal from 'react-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  ModalCampaignsContainer,
  Title,
  DivSeparator,
  Separator,
  DivContainer,
  NameDiv,
  InfoContainer,
  ButtonAdd,
  ButtonSalved,
  InputDiv,
} from './style';
import addMore from '../../../../assets/icons/add_more.svg';
import { ICampaigns } from '../../dtos/ICampaigns';
import apiChatbot from '../../../../services/apiChatbot';

interface ModalCompaignsRequestProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleRequestCampaigns: (pageNumber: number) => Promise<void>;
  campaigns: ICampaigns;
  type: string;
}

interface INewBatch {
  batch: string;
  scheduling_date?: string;
  triggers: string;
  triggered_at?: string;
}

export function ModalCampaigns({
  isOpen,
  onRequestClose,
  campaigns,
  handleRequestCampaigns,
  type,
}: ModalCompaignsRequestProps): JSX.Element {
  const [newBatchs, setNewBatchs] = useState<INewBatch[]>([]);

  const handleAddInfo = useCallback(() => {
    const allBatches: INewBatch[] = newBatchs;

    let triggersLength = 0;
    const notTriggeredBatches = allBatches.filter(batch => !batch.triggered_at);

    for (const batch of notTriggeredBatches) {
      triggersLength += Number(batch.triggers);
    }

    const batchSize = Math.trunc(
      triggersLength / (notTriggeredBatches.length + 1),
    );

    let remaining = triggersLength % (notTriggeredBatches.length + 1);

    for (const batch of notTriggeredBatches) {
      if (remaining > 0) {
        batch.triggers = String(batchSize + 1);
        --remaining;
      } else {
        batch.triggers = String(batchSize);
      }
    }

    const tes = {
      batch: String(newBatchs.length + 1),
      triggers: String(batchSize),
      scheduled_date: null,
      triggered_at: null,
    };

    setNewBatchs([...allBatches, tes]);
  }, [newBatchs]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const allBatches: INewBatch[] = newBatchs;

      const { id, value } = event.target;

      const batcheSingle = allBatches.findIndex(newB => newB.batch === id);

      allBatches[batcheSingle].scheduling_date = value;

      setNewBatchs(allBatches);
    },
    [newBatchs],
  );

  const handleEditUpload = useCallback(async () => {
    try {
      const data = newBatchs.map(nb => new Date(nb.scheduling_date));
      if (type === 'RCS') {
        await axios
          .put(
            `${process.env.REACT_APP_MARKETING_URL}/rcs/batches`,
            {
              campaign_id: campaigns.id,
              scheduled_dates: data,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  '@Precato:token',
                )}`,
              },
            },
          )
          .then(async () => {
            onRequestClose();

            handleRequestCampaigns(1);
          });
      } else if (type === 'UNIPIX') {
        await axios
          .put(
            `${process.env.REACT_APP_MARKETING_URL}/unipix/batches`,
            {
              campaign_id: campaigns.id,
              scheduled_dates: data,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  '@Precato:token',
                )}`,
              },
            },
          )
          .then(async () => {
            onRequestClose();

            handleRequestCampaigns(1);
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [campaigns.id, handleRequestCampaigns, newBatchs, onRequestClose, type]);

  useEffect(() => {
    const allBatches: INewBatch[] = [];
    let index = 1;

    for (const batch of campaigns.batches) {
      allBatches.push({
        batch: String(index),
        triggers: String(batch.triggers.length),
        scheduling_date: batch.scheduling_date,
        triggered_at: batch.triggered_at,
      });

      index++;
    }

    allBatches.sort((a, b) => {
      if (
        new Date(a.scheduling_date).getDate() <
        new Date(b.scheduling_date).getDate()
      ) {
        return -1;
      }
      if (
        new Date(a.scheduling_date).getDate() >
        new Date(b.scheduling_date).getDate()
      ) {
        return 1;
      }
      return 0;
    });

    setNewBatchs(allBatches);
  }, [campaigns.batches]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ModalCampaignsContainer}
    >
      <Title>Campanha</Title>
      <DivSeparator>
        <Separator />
      </DivSeparator>
      <DivContainer>
        <NameDiv>
          <p>Nome da campanha:</p>
          <input type="text" defaultValue={campaigns.name} />
        </NameDiv>

        {newBatchs.map((data, index) => (
          <InfoContainer key={String(index)} concluded={!!data.triggered_at}>
            <div>
              <span>Nº do lote</span>
              <InputDiv concluded={!!data.triggered_at}>{index + 1}</InputDiv>
            </div>
            <div>
              <span>Data de disparo</span>
              {data.scheduling_date ? (
                <input
                  id={`${data.batch}`}
                  name="scheduling_date_"
                  type="datetime-local"
                  defaultValue={new Date(
                    new Date(data.scheduling_date).getTime() -
                      3 * 60 * 60 * 1000,
                  )
                    .toISOString()
                    .slice(0, -1)}
                  onChange={handleInputChange}
                />
              ) : (
                <input
                  id={`${data.batch}`}
                  name="scheduled_date_"
                  type="datetime-local"
                  onChange={handleInputChange}
                />
              )}
            </div>
            <div>
              <span>Nº de disparos</span>
              <InputDiv concluded={!!data.triggered_at}>
                {data.triggers}
              </InputDiv>
            </div>
          </InfoContainer>
        ))}

        <ButtonAdd onClick={handleAddInfo}>
          <img src={addMore} alt="Adicionar" />
        </ButtonAdd>
        <ButtonSalved onClick={handleEditUpload} type="button">
          Salvar
        </ButtonSalved>
      </DivContainer>
    </Modal>
  );
}
