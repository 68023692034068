import styled from 'styled-components';

export const ContainerPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4.5rem;
`;
