import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';

import api from '../../../services/api';
import { changePagination } from '../../../utils/changePagination';

import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { InputDate } from '../../../components/InputDate';
import { PaginationComponent } from '../../../components/Pagination';
import { Loading } from '../../../components/Loading';
import { TitleSla } from '../../../components/TitleSla';

import { ReactComponent as ArrowBlue } from '../../../assets/icons/arrow_blue.svg';
import { HunterSLADTO } from '../dtos/HunterSLADTO';

import {
  BackButtonContainer,
  ButtonTeam,
  CellContainer,
  ContentTable,
  FilterByTeam,
  FilterContent,
  FiltersContainer,
  HubspotLink,
  Icon,
  Info,
  Legend,
  LineSeparator,
  RowTable,
  Select,
  TitleBackButtonContainer,
  TitleContainer,
} from './styles';
import { ModalAnswerSLA } from './ModalAnswerSLA';

interface TableHunterSLAProps {
  title_page: string;
  setStatePanel: (statePanel: string) => void;
}

export function TableHunterSLA({
  title_page,
  setStatePanel,
}: TableHunterSLAProps): JSX.Element {
  const [selectedTeam, setSelectedTeam] = useState('Phoenix');
  const [rangeNote, setRangeNote] = useState('Todas');
  const [hunterName, setHunterName] = useState<string>('Todos');
  const [date, setDate] = useState(new Date());
  const [hunterSlas, setHunterSlas] = useState<HunterSLADTO[]>(
    [] as HunterSLADTO[],
  );
  const [currentItems, setCurrentItems] = useState<HunterSLADTO[]>(
    [] as HunterSLADTO[],
  );
  const [selectedHunterSLA, setSelectedHunterSLA] = useState<HunterSLADTO>(
    {} as HunterSLADTO,
  );
  const [currentHunters, setCurrentHunters] = useState<string[]>(
    [] as string[],
  );

  const itemsPerPage = 8;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);

  const [openModalAnswerSLA, setModalAnswerSLA] = useState(false);

  useEffect(() => {
    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      api
        .get<HunterSLADTO[]>(
          `/schedule/hunter-sla-by-type?date=${format(
            date,
            'yyyy-MM-dd',
          )}&type=${title_page}`,
        )
        .then(response => {
          setHunterSlas(response.data);

          const filteredItems = response.data.filter(
            item =>
              item.team &&
              item.team.toUpperCase() === selectedTeam.toUpperCase(),
          );

          setCurrentItems(filteredItems.slice(0, itemsPerPage));

          const filteredHunters: string[] = [];

          filteredItems.forEach(item => {
            const findHunterName = filteredHunters.find(
              hunter => hunter === item.hunter_name,
            );

            if (!findHunterName) {
              filteredHunters.push(item.hunter_name);
            }
          });

          setCurrentHunters(filteredHunters);

          const pages: number[] = [];

          for (
            let index = 1;
            index <= Math.ceil(filteredItems.length / itemsPerPage);
            index++
          ) {
            pages.push(index);
          }
          setTotalPages(pages);

          loader.style.display = 'none';
        });
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';
    }
  }, [date, selectedTeam, title_page]);

  const handleFilterCases = useCallback(
    (team_name: string, range_note: string, hunter_name: string) => {
      setSelectedTeam(team_name);
      setRangeNote(range_note);
      setHunterName(hunter_name);

      let filteredItems = hunterSlas.filter(
        item =>
          item.team && item.team.toUpperCase() === team_name.toUpperCase(),
      );

      const filteredHunters: string[] = [];

      filteredItems.forEach(item => {
        const findHunterName = filteredHunters.find(
          hunter => hunter === item.hunter_name,
        );

        if (!findHunterName) {
          filteredHunters.push(item.hunter_name);
        }
      });

      if (title_page === 'Bem-sucedido' || title_page === 'Perdido') {
        if (range_note === '41 a 55') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 41 && item.note_amount <= 45,
          );
        }

        if (range_note === '36 a 40') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 36 && item.note_amount <= 40,
          );
        }

        if (range_note === '30 a 35') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 30 && item.note_amount <= 35,
          );
        }

        if (range_note === '24 a 29') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 24 && item.note_amount <= 29,
          );
        }

        if (range_note === '0 a 23') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 0 && item.note_amount <= 23,
          );
        }
      } else {
        if (range_note === '30 ou +') {
          filteredItems = filteredItems.filter(item => item.note_amount >= 30);
        }

        if (range_note === '27 a 29') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 27 && item.note_amount <= 29,
          );
        }

        if (range_note === '24 a 26') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 24 && item.note_amount <= 26,
          );
        }

        if (range_note === '21 a 23') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 21 && item.note_amount <= 23,
          );
        }

        if (range_note === '0 a 20') {
          filteredItems = filteredItems.filter(
            item => item.note_amount >= 0 && item.note_amount <= 20,
          );
        }
      }

      if (hunter_name !== 'Todos') {
        filteredItems = filteredItems.filter(
          item => item.hunter_name.toUpperCase() === hunter_name.toUpperCase(),
        );
      }

      setCurrentHunters(filteredHunters);

      setCurrentItems(filteredItems.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(filteredItems.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);

      return filteredItems;
    },
    [hunterSlas, title_page],
  );

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: HunterSLADTO[] = handleFilterCases(
        selectedTeam,
        rangeNote,
        hunterName,
      );

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(items.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }

      setTotalPages(pages);

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [handleFilterCases, selectedTeam, rangeNote, hunterName, page, totalPages],
  );

  const handleOpenAnswerSLAModal = useCallback((hunter_sla: HunterSLADTO) => {
    setModalAnswerSLA(true);
    setSelectedHunterSLA(hunter_sla);
  }, []);

  const handleCloseAnswerSLAModal = useCallback(() => {
    setModalAnswerSLA(false);
  }, []);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'}</H1Navigation>
        <H1Navigation>Painel SLA {'>'}</H1Navigation>
        <H1NavigationNow>{title_page}</H1NavigationNow>
      </Navegation>
      <Body>
        <Loading />

        <TitleBackButtonContainer>
          <BackButtonContainer onClick={() => setStatePanel('Inicial')}>
            <Icon>
              <ArrowBlue />
            </Icon>
            Voltar
          </BackButtonContainer>

          <TitleContainer>
            <TitleSla
              name="Relatório - "
              nameStrong={title_page}
              isShared={false}
              separatorWidth={25}
            />
          </TitleContainer>
        </TitleBackButtonContainer>

        <FilterByTeam>
          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'Phoenix'}
            onClick={() => handleFilterCases('Phoenix', rangeNote, hunterName)}
          >
            Phoenix
          </ButtonTeam>

          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'Jaguar'}
            onClick={() => handleFilterCases('Jaguar', rangeNote, hunterName)}
          >
            Jaguar
          </ButtonTeam>

          <ButtonTeam
            type="button"
            isSelected={selectedTeam === 'Scorpion'}
            onClick={() => handleFilterCases('Scorpion', rangeNote, hunterName)}
          >
            Scorpion
          </ButtonTeam>
        </FilterByTeam>

        <FiltersContainer>
          <InputDate date={date} setDate={setDate} />

          <FilterContent>
            <span>Notas</span>

            {title_page === 'Bem-sucedido' || title_page === 'Perdido' ? (
              <Select
                onChange={e =>
                  handleFilterCases(selectedTeam, e.target.value, hunterName)
                }
              >
                <option value="Todas">Todas</option>
                <option value="41 a 45">41 a 45</option>
                <option value="36 a 40">36 a 40</option>
                <option value="30 a 35">30 a 35</option>
                <option value="24 a 29">24 a 29</option>
                <option value="0 a 23">0 a 23</option>
              </Select>
            ) : (
              <Select
                onChange={e =>
                  handleFilterCases(selectedTeam, e.target.value, hunterName)
                }
              >
                <option value="Todas">Todas</option>
                <option value="30 ou +">30 ou +</option>
                <option value="27 a 29">27 a 29</option>
                <option value="24 a 26">24 a 26</option>
                <option value="21 a 23">21 a 23</option>
                <option value="0 a 20">0 a 20</option>
              </Select>
            )}
          </FilterContent>

          <FilterContent>
            <span>Hunter</span>
            <Select
              onChange={e =>
                handleFilterCases(selectedTeam, rangeNote, e.target.value)
              }
            >
              <option value="Todos">Todos</option>
              {currentHunters.map(hunter => (
                <option key={hunter} value={hunter}>
                  {hunter}
                </option>
              ))}
            </Select>
          </FilterContent>
        </FiltersContainer>

        <ContentTable>
          {currentItems.length > 0 &&
            currentItems.map(currentItem => (
              <RowTable
                key={currentItem.id}
                onClick={() => handleOpenAnswerSLAModal(currentItem)}
                hasActedBy={!!currentItem.acted_by}
              >
                <CellContainer className="hunter">
                  <Legend>Hunter:</Legend>
                  <Info>{currentItem.hunter_name}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="stage">
                  <Legend>Etapa:</Legend>
                  <Info>{currentItem.stage}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="hubspot">
                  <Legend>Link Hubspot:</Legend>
                  <Info>
                    <HubspotLink
                      href={`https://app.hubspot.com/contacts/7857327/deal/${currentItem.hubspot_deal_id}`}
                      target="_blank"
                    >
                      {`https://app.hubspot.com/contacts/7857327/deal/${currentItem.hubspot_deal_id}`}
                    </HubspotLink>
                  </Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="acted_by">
                  <Legend>Atuado por:</Legend>
                  <Info>
                    {currentItem.acted_by
                      ? currentItem.acted_by.name
                      : 'Não atuado'}
                  </Info>
                </CellContainer>
              </RowTable>
            ))}
        </ContentTable>

        <PaginationComponent
          totalPages={totalPages}
          page={page}
          handleChangePagination={handleChangePagination}
        />
      </Body>

      {selectedHunterSLA && (
        <ModalAnswerSLA
          isOpen={openModalAnswerSLA}
          onRequestClose={handleCloseAnswerSLAModal}
          hunterSLA={selectedHunterSLA}
          currentItems={currentItems}
          setCurrentItems={setCurrentItems}
          setSelectedHunterSLA={setSelectedHunterSLA}
        />
      )}
    </Container>
  );
}
