import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface Props {
  isEnabled: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const FileContainer = styled.div`
  width: 100%;
  margin-top: 4.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 1.625rem;
  border-radius: 0.313rem;
  padding: 0.3rem;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  cursor: pointer;
  color: #4e4e4e;
  background: #c4c4c4;

  color: #4e4e4e;
`;

export const ButtonSendFile = styled.button<Props>`
  padding: 0.5rem;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  width: 8rem;
  height: 1.625rem;

  border: 0;
  border-radius: 0.313rem;

  color: #ffffff;

  ${props =>
    props.isEnabled
      ? css`
          background: #063966;
        `
      : css`
          background: #c4c4c4;
          cursor: no-drop;
        `}
`;

export const VerticalDivisor = styled.div`
  height: 1.5rem;

  border-right: 1px solid #041524;
`;

export const FilesHistoryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -1rem;
  justify-content: center;
  @media (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const TitleHistory = styled.h1`
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;

  color: #05233e;

  @media (max-width: 1350px) {
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.25rem;
  margin-top: 5.5rem;
`;

export const ClearHitoryButton = styled.button`
  margin-left: auto;
  border: 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: -0.015em;

  width: 8rem;
  height: 2rem;
  padding: 0.3rem;

  background: #c4c4c4;
  border-radius: 0.313rem;
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Thead = styled.thead`
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
`;

export const TableRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background: var(--blue-100);
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;
`;

export const CellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 33%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: var(--blue-700);
`;

export const NameCellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: var(--blue-700);
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25rem;
  text-align: center;
`;

export const TableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;

  :nth-child(odd) {
    background: #efefef;
  }

  :nth-child(even) {
    background: #e5e5e5;
  }
`;

export const CellBody = styled.td`
  word-break: break-all;
  width: 33%;
  background: transparent;
  padding: 0.5rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  :nth-child(2) {
    cursor: pointer;
    color: #1665d8;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const NameCellBody = styled.td`
  word-break: break-all;
  width: 100%;
  background: transparent;
  padding: 0.5rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  :nth-child(2) {
    cursor: pointer;
    color: #1665d8;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Button = styled.button`
  width: 7rem;
  height: 1.75rem;

  background: #900505;
  border-radius: 0.313rem;

  color: #fff;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;
  border: none;

  :disabled {
    background: ${shade(0.1, '#817f7f')};
    cursor: initial;
  }
`;

export const FileInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6rem;
  width: 39.5rem;
  background: var(--blue-100);
  border-radius: 0.625rem;

  @media (max-width: 1200px) {
    margin-left: 4.5rem;
    justify-content: center;
    margin-right: 0;
  }
`;

export const SendFileContainer = styled.div`
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0.5rem 2rem 0.5rem;
`;

export const TitleFile = styled.h1`
  grid-column: 1;
  margin: 1rem 0 3.75rem 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.688rem;
  letter-spacing: -0.015em;

  color: #041524;
`;

export const InputFile = styled.input`
  display: none;
`;

export const TextFile = styled.span`
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #666666;
  height: 1.313rem;
  width: 10rem;

  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.938rem;
  letter-spacing: -0.015em;
  text-align: center;
  overflow: -moz-hidden-unscrollable;
  text-overflow: ellipsis;
  white-space: wrap;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  grid-column: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 2rem 0rem;
`;

export const TextFileButton = styled.span`
  margin-top: 0.5rem;
  color: #666666;
  height: 1.313rem;
  width: 12rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.938rem;
  letter-spacing: -0.015em;
  text-align: center;
`;

export const ExampleFileContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-top: 3.5rem;
  width: 32rem;
`;

export const ExampleTitle = styled.h1`
  grid: 1;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.015em;
  margin: 1rem 0 0 0;
  width: 12rem;

  color: #041524;
`;

export const FileProcessExampleContainer = styled.div`
  grid: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1rem;
`;

export const ExampleTextContainer = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ExampleText = styled.span`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: var(--blue-800);
  font-size: 0.938rem;
`;

export const Separator = styled.hr`
  width: 14rem;
`;

export const SelectFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StateSelect = styled.select`
  width: 9.375rem;
  height: 1.625rem;

  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #c4c4c4;

  font-family: Poppins;
  font-weight: 500;
  font-size: 11px;
  color: #4e4e4e;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
`;
