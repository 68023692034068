export const locationsOC = [
  // {
  //   id: 12,
  //   name: 'Acre',
  //   route: 'Acre',
  //   initials: 'AC-OC',
  //   region: 'Norte',
  //   fetchEntities: false,
  // },
  {
    id: 27,
    name: 'Alagoas',
    route: 'Alagoas',
    initials: 'AL-OC',
    region: 'Nordeste',
    fetchEntities: false,
  },
  // {
  //   id: 16,
  //   name: 'Amapá',
  //   route: 'Amapá',
  //   initials: 'AP-OC',
  //   region: 'Norte',
  //   fetchEntities: false,
  // },
  // {
  //   id: 13,
  //   name: 'Amazonas',
  //   route: 'Amazonas',
  //   initials: 'AM-OC',
  //   region: 'Norte',
  //   fetchEntities: false,
  // },
  {
    id: 29,
    name: 'Bahia',
    route: 'Bahia',
    initials: 'BA-OC',
    region: 'Nordeste',
    fetchEntities: true,
  },

  // {
  //   id: 23,
  //   name: 'Ceará',
  //   route: 'Ceará',
  //   initials: 'CE-OC',
  //   region: 'Nordeste',
  //   fetchEntities: false,
  // },
  // {
  //   id: 53,
  //   name: 'Distrito Federal',
  //   route: 'Distrito Federal',
  //   initials: 'DF-OC',
  //   region: 'Centro-Oeste',
  //   fetchEntities: false,
  // },
  // {
  //   id: 32,
  //   name: 'Espírito Santo',
  //   route: 'Espírito Santo',
  //   initials: 'ES-OC',
  //   region: 'Sudeste',
  //   fetchEntities: false,
  // },
  // {
  //   id: 52,
  //   name: 'Goiás',
  //   route: 'Goiás',
  //   initials: 'GO-OC',
  //   region: 'Centro-Oeste',
  //   fetchEntities: false,
  // },
  // {
  //   id: 21,
  //   name: 'Maranhão',
  //   route: 'Maranhão',
  //   initials: 'MA-OC',
  //   region: 'Nordeste',
  //   fetchEntities: false,
  // },
  {
    id: 51,
    name: 'Mato Grosso',
    route: 'Mato Grosso',
    initials: 'MT-OC',
    region: 'Centro-Oeste',
    fetchEntities: false,
  },
  // {
  //   id: 50,
  //   name: 'Mato Grosso do Sul',
  //   route: 'Mato Grosso do Sul',
  //   initials: 'MS-OC',
  //   region: 'Centro-Oeste',
  //   fetchEntities: false,
  // },
  {
    id: 31,
    name: 'Minas Gerais - Entity',
    route: 'Minas Gerais',
    initials: 'MG-OC-E',
    region: 'Sudeste',
    fetchEntities: true,
  },
  {
    id: 31,
    name: 'Minas Gerais',
    route: 'Minas Gerais',
    initials: 'MG-OC',
    region: 'Sudeste',
    fetchEntities: true,
  },
  // {
  //     id: 15,
  //     name: 'Pará',
  //     route: 'Pará',
  //     initials: 'PA-OC',
  //     region: 'Norte',
  //     fetchEntities: false,
  //   },
  //   {
  //     id: 25,
  //     name: 'Paraíba',
  //     route: 'Paraíba',
  //     initials: 'PB-OC',
  //     region: 'Nordeste',
  //     fetchEntities: false,
  //   },
  //   {
  //     id: 41,
  //     name: 'Paraná',
  //     route: 'Paraná',
  //     initials: 'PR-OC',
  //     region: 'Sul',
  //     fetchEntities: false,
  //   },
  {
    id: 26,
    name: 'Pernambuco',
    route: 'Pernambuco',
    initials: 'PE-OC',
    region: 'Nordeste',
    fetchEntities: true,
  },
  // {
  //   id: 22,
  //   name: 'Piauí',
  //   route: 'Piauí',
  //   initials: 'PI-OC',
  //   region: 'Nordeste',
  //   fetchEntities: false,
  // },
  {
    id: 33,
    name: 'Rio de Janeiro',
    route: 'Rio de Janeiro',
    initials: 'RJ-OC',
    region: 'Sudeste',
    fetchEntities: false,
  },
  {
    id: 11,
    name: 'Rondônia',
    route: 'Rondônia',
    initials: 'RO-OC',
    region: 'Norte',
    fetchEntities: false,
  },
  // {
  //   id: 24,
  //   name: 'Rio Grande do Norte',
  //   route: 'Rio Grande do Norte',
  //   initials: 'RN-OC',
  //   region: 'Nordeste',
  //   fetchEntities: false,
  // },
  // {
  //   id: 14,
  //   name: 'Roraima',
  //   route: 'Roraima',
  //   initials: 'RR-OC',
  //   region: 'Norte',
  //   fetchEntities: false,
  // },
  {
    id: 42,
    name: 'Santa Catarina',
    route: 'Santa Catarina',
    initials: 'SC-OC',
    region: 'Sul',
    fetchEntities: false,
  },
  {
    id: 43,
    name: 'Rio Grande do Sul',
    route: 'Rio Grande do Sul',
    initials: 'RS-OC',
    region: 'Sul',
    fetchEntities: true,
  },
  {
    id: 35,
    name: 'São Paulo',
    route: 'São Paulo',
    initials: 'SP-OC',
    region: 'Sudeste',
    fetchEntities: false,
  },
  /* {
    id: 28,
    name: 'Sergipe',
    route: 'Sergipe',
    initials: 'SE-OC',
    region: 'Nordeste',
    fetchEntities: false,
  }, */
  {
    id: 17,
    name: 'Tocantins',
    route: 'Tocantins',
    initials: 'TO-OC',
    region: 'Norte',
    fetchEntities: false,
  },
];
