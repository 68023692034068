import styled from 'styled-components';

export const FileContainer = styled.div`
  width: 36rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FileInputContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 0.5fr 0.9fr 1fr;
  align-items: center;
  height: 6rem;
  width: 32rem;
  background: var(--blue-100);
  border-radius: 0.625rem;

  @media (max-width: 1200px) {
    margin-left: 4.5rem;
    justify-content: center;
    margin-right: 0;
  }
`;

export const SendFileContainer = styled.div`
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0.5rem 2rem 0.5rem;
`;

export const TitleFile = styled.h1`
  grid-column: 1;
  margin: 1rem 0 1.5rem 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.688rem;
  letter-spacing: -0.015em;

  color: #041524;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 1.625rem;
  border-radius: 0.313rem;
  padding: 0.2rem;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  cursor: pointer;
  color: #4e4e4e;
  background: var(--gray-300);

  color: #4e4e4e;
`;

export const InputFile = styled.input`
  display: none;
`;

export const TextFile = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #666666;
  height: 1.313rem;
  width: 10rem;

  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.938rem;
  letter-spacing: -0.015em;
  text-align: center;
  overflow: -moz-hidden-unscrollable;
  text-overflow: ellipsis;
  white-space: wrap;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  grid-column: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 2rem 0rem;
`;

export const ButtonSendFile = styled.button`
  padding: 0.2rem;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  width: 12rem;
  height: 1.625rem;

  background: var(--blue-600);

  &:hover {
    background: var(--blue-700);
  }
  border: 0;
  border-radius: 0.313rem;

  color: #ffffff;
`;

export const TextFileButton = styled.span`
  margin-top: 0.5rem;
  color: #666666;
  height: 1.313rem;
  width: 12rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.938rem;
  letter-spacing: -0.015em;
  text-align: center;
`;

export const ExampleFileContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-top: 3.5rem;
  width: 36rem;
`;

export const ExampleTitle = styled.h1`
  grid: 1;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.015em;
  margin: 1rem 0 0 2rem;
  width: 12rem;

  color: #041524;
`;

export const FileProcessExampleContainer = styled.div`
  grid: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 1rem 0 0;
`;

export const ExampleTextContainer = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ExampleText = styled.span`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: var(--blue-800);
  font-size: 0.938rem;
`;

export const Separator = styled.hr`
  width: 14rem;
`;

export const SelectFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
