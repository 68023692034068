import styled, { css } from 'styled-components';

interface IProps {
  isShared: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;

  ${props =>
    props.isShared &&
    css`
      width: 60%;
    `}

  @media (max-width: 1350px) {
    /* width: 100vw; */
  }
`;

export const TextTitle = styled.h1`
  margin-top: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.625rem;
  text-align: center;
  color: #05233e;
`;
