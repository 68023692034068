import styled from 'styled-components';

export const TableContainer = styled.div``;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  tbody:nth-child(odd) {
    background: var(--gray-50);
  }

  tbody:nth-child(even) {
    background: var(--gray-100);
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  display: grid;
  // 5.063rem prevista na 3 9.313rem Real/Prev_Liq na 5
  grid-template-columns: 7.938rem 3.938rem 5.063rem 9.313rem 6rem 6rem 6rem; //6.188rem;
  // 6rem 6.188rem;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem 0 0 0.25rem;
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  color: var(--blue-700);

  border-right: 0.01rem solid var(--blue-700);

  &:nth-child(7) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  width: 100%;

  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }
`;

export const BodyCell = styled.td`
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;

  &.arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
  }
`;
