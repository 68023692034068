import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AiOutlineDownload } from 'react-icons/ai';
import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';

import api from '../../services/api';

import { Body } from '../../components/Styles/Body';
import { Title } from '../../components/Styles/Title';
import { Container } from '../../components/Container';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';
import { PaginationComponent } from '../../components/Pagination';

import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import search_icon from '../../assets/icons/search.svg';
import email_user_icon from '../../assets/icons/email_user.svg';
import user_profile_icon from '../../assets/icons/user_profile.svg';
import star_icon from '../../assets/icons/star.svg';
import user_icon from '../../assets/icons/user.svg';

import { changePagination } from '../../utils/changePagination';

import {
  DivSeparator,
  Separator,
  ContainerHeader,
  SearchContainer,
  InputSearch,
  ButtonNewUser,
  Table,
  TableHead,
  TextTitle,
  Tbody,
  IconForm,
  ButtonDelete,
  ButtonEdit,
  ContainerExport,
  Export,
} from './styles';

interface User {
  id: string;
  name: string;
  email: string;
  team_id: string;
  profile_id: string;
  avatar_url: string;
}

interface Team {
  id: string;
  name: string;
}

interface Profile {
  id: string;
  name: string;
}

export const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const itemsPerPage = 8;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<User[]>([] as User[]);

  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);

      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, []);

  useEffect(() => {
    api.get('/people/teams').then(response => {
      setTeams(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/users/profiles/').then(response => {
      setProfiles(response.data);
    });
  }, []);

  const searchUsers = useCallback(
    (value: string) => {
      const filterUsers = users.filter(
        user => user.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setCurrentItems(filterUsers);
    },
    [users],
  );

  const getUsers = useCallback(() => {
    api.get('/users').then(response => {
      setUsers(response.data);

      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, []);

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getUsers() : searchUsers(value);
    },
    [getUsers, searchUsers],
  );

  const deleteSuccess = useCallback(() => {
    toast.success('Usuário deletado com sucesso');
  }, []);

  const deleteError = useCallback(() => {
    Swal.fire('Erro!', 'Ocorreu um erro ao deletar o usuário.', 'error');
  }, []);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: User[] = users;

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [page, totalPages, users],
  );

  const deleteUser = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/users/${id}`);
        const updatedTeam = users.filter(user => user.id !== id);

        deleteSuccess();

        setUsers(updatedTeam);
      } catch (err) {
        deleteError();
      }
    },
    [deleteError, deleteSuccess, users],
  );

  const pdf = useCallback(() => {
    window.print();
  }, []);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Administração {'>'} </H1Navigation>
        <H1NavigationNow>Usuários</H1NavigationNow>
      </Navegation>

      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Usuários</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <ContainerHeader>
          <SearchContainer>
            <InputSearch
              type="text"
              placeholder="Pesquise por usuário"
              onChange={handleSearchInputChange}
            />
            <img src={search_icon} alt="Search icon" />
          </SearchContainer>

          <Link to="/administration/users/new">
            <ButtonNewUser type="submit">Novo usuário</ButtonNewUser>
          </Link>
        </ContainerHeader>

        <Table>
          <TableHead>
            <tr>
              <th>
                <IconForm className="icon-head">
                  <img src={user_icon} alt="User icon" />
                </IconForm>
                <TextTitle>Nome</TextTitle>
              </th>
              <th>
                <IconForm className="icon-head">
                  <img src={email_user_icon} alt="Email icon" />
                </IconForm>
                <TextTitle>Email</TextTitle>
              </th>
              <th>
                <IconForm className="icon-head">
                  <img src={user_profile_icon} alt="Profile icon" />
                </IconForm>
                <TextTitle>Perfil</TextTitle>
              </th>
              <th>
                <IconForm className="icon-head">
                  <img src={star_icon} alt="Team icon" />
                </IconForm>
                <TextTitle>Time</TextTitle>
              </th>
            </tr>
          </TableHead>

          <Tbody>
            {currentItems.length > 0 &&
              currentItems.map(user => (
                <tr key={user.id}>
                  <td>
                    <IconForm className="icon-body">
                      <img src={user.avatar_url} alt="Profile" />
                    </IconForm>
                    <span>{user.name}</span>
                  </td>
                  <td>{user.email}</td>
                  <td>
                    {profiles.length > 0 &&
                      profiles.map(profile =>
                        user.profile_id === profile.id ? (
                          <span key={profile.id}>{profile.name}</span>
                        ) : (
                          ''
                        ),
                      )}
                  </td>
                  <td>
                    {teams.length > 0 &&
                      teams.map(team =>
                        user.team_id === team.id ? (
                          <span key={team.id}>{team.name}</span>
                        ) : (
                          ''
                        ),
                      )}

                    <IconForm className="icon-body">
                      <ButtonDelete
                        type="button"
                        onClick={() => deleteUser(user.id)}
                      >
                        <DeleteIcon />
                      </ButtonDelete>

                      <Link
                        to={{
                          pathname: '/administration/users/edit',
                          state: {
                            id: user.id,
                            name: user.name,
                            email: user.email,
                            profile_id: user.profile_id,
                            team_id: user.team_id,
                          },
                        }}
                      >
                        <ButtonEdit type="button">
                          <EditIcon />
                        </ButtonEdit>
                      </Link>
                    </IconForm>
                  </td>
                </tr>
              ))}
          </Tbody>
        </Table>

        <ContainerExport>
          <Export type="button" onClick={pdf}>
            <AiOutlineDownload />
            Exportar
          </Export>

          <PaginationComponent
            totalPages={totalPages}
            page={page}
            handleChangePagination={handleChangePagination}
          />
        </ContainerExport>
      </Body>
    </Container>
  );
};
