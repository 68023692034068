import styled from 'styled-components';

export const Title = styled.h1`
  margin: 2.3125rem auto 0 auto;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 2.4375rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin: 1rem auto 0 auto;

  color: #9d9d9d;
`;

export const Table = styled.table`
  width: 71.5rem;
  margin: 5rem auto 0 auto;

  .tr-body:nth-child(odd) {
    background: #e5e5e5;
  }

  .tr-body:nth-child(even) {
    background: #efefef;
  }
`;

export const TableHeader = styled.th`
  width: 71.5rem;
  height: 2.5rem;
  border-radius: 0.3125rem;
  background-color: #cfdef3;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface ITableHeadProps {
  width: string;
}

export const TableHead = styled.td<ITableHeadProps>`
  width: ${props => props.width};

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const VerticalSeparator = styled.div`
  width: 0rem;
  height: 2rem;
  border: 1px solid #063966a3;
  border-left: 0.0625rem solid #9d9d9d;
`;

export const AddPhoneButton = styled.button`
  width: 9.6875rem;
  height: 2.3125rem;
  margin: 5.625rem auto 0 auto;
  border: none;
  border-radius: 0.5rem;
  background-color: #014077;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-align: center;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: #ffffff;

  :hover {
    cursor: pointer;
    background-color: #05233e;
  }
`;
