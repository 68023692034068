import Swal from 'sweetalert2';

interface IRequest {
  closed_date: Date;
}

export default async function verifyDealTj(data: IRequest): Promise<boolean> {
  console.log(data.closed_date);

  if (data.closed_date) {
    Swal.fire(
      'Atenção!',
      `TICKET FECHADO. CONFERIR NO HUBSPOT - NÃO É POSSÍVEL REALIZAR REGISTROS OU ALTERAÇÕES`,
      'warning',
    );

    return false;
  }

  return true;
}
