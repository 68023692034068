import { format, isBefore } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useAuth } from '../../../../hooks/auth';
import { IHunterActivity } from '../../dtos/IDayHoursDTO';

import { formatAmount } from '../../../../utils/formatAmount';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';
import { DayPicker } from '../../../../components/DayPicker';
import { confirmModal } from '../../../../components/ModalConfirm';
import { Loading } from '../../../../components/Loading';
import { useHunterSchedule } from '../../../../hooks/useHunterSchedule';
import edit_scheduling_manager from '../../../../assets/icons/edit_scheduling_manager.svg';

import api from '../../../../services/api';

import closeIcon from '../../../../assets/icons/cancel.svg';
import editIcon from '../../../../assets/icons/edit.svg';
import delete_icon from '../../../../assets/icons/trash.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_blue.svg';

import {
  Container,
  Header,
  ButtonsHeaderContainer,
  EditDeleteButton,
  CloseButton,
  Main,
  LabelInputContainer,
  Label,
  Input,
  Link,
  LineSeparator,
  Textarea,
  ContainerInput,
  ContainerInputDate,
  HoursContainer,
  ChangeHunterContainer,
} from './styles';

Modal.setAppElement('#root');
interface User {
  id: string;
  name: string;
}

interface IHunters {
  id: string;
  user: User;
}

interface ModalActivityProps {
  isOpen: boolean;
  onRequestClose: () => void;
  activity: IHunterActivity;
}

export function ActivityHunterModal({
  isOpen,
  onRequestClose,
  activity,
}: ModalActivityProps): JSX.Element {
  const { user } = useAuth();
  const { handleUpdateDayHour } = useHunterSchedule();

  const [editFields, setEditFields] = useState(false);

  const talkArray = ['Sim', 'Não'];
  const activitySituation = ['Pendente', 'Concluída'];

  const [talkOption, setTalkOption] = useState<string>('');
  const [activitySituationOption, setActivitySituationOption] =
    useState<string>('');
  const [hourOption, setHourOption] = useState<string>('');
  const [observation, setObservation] = useState<string>('');
  const [tryTimes, setTryTimes] = useState<string>('');

  const [hunterID, setHunterID] = useState<string>();

  const [date, setDate] = useState(
    activity.timestamp ? new Date(activity.timestamp) : new Date(),
  );
  const [availableHours, setAvailableHours] = useState<string[]>(
    [] as string[],
  );

  const [hunterAvailable, setHunterAvailable] = useState<IHunters[]>(
    [] as IHunters[],
  );

  const [changeHunter, setChangeHunter] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setObservation(activity.observation);
      setTalkOption(activity.spoken);
      setActivitySituationOption(activity.status);
      setTryTimes(String(activity.try_times));
    }
  }, [
    activity.spoken,
    activity.status,
    activity.try_times,
    activity.observation,
    isOpen,
  ]);

  useEffect(() => {
    try {
      api
        .get<string[]>(
          `/schedule/hunter-available-hours?date=${format(
            date,
            'yyyy-MM-dd',
          )}&user_id=${user.id}`,
        )
        .then(response => {
          const formattedHours = response.data.map(hour => {
            return format(new Date(hour), 'HH:mm');
          });

          setAvailableHours(formattedHours);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }, [date, user.id]);

  const closeModal = useCallback(() => {
    onRequestClose();
    setEditFields(false);
    setChangeHunter(false);
    setTalkOption(activity.spoken);
    setActivitySituationOption(activity.status);
    setObservation(activity.observation);
    setTryTimes(String(activity.try_times));
  }, [activity, onRequestClose]);

  const handleDeleteActivity = useCallback(async () => {
    const confirm = await confirmModal('Tem certeza que deseja excluir?');

    if (confirm) {
      try {
        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        const { data } = await api.patch('schedule/hunter-sla', {
          id: activity.id,
          deleted: true,
        });

        handleUpdateDayHour(activity.timestamp, data, activity);
      } catch (error) {
        if (error.response.data.status === 404) {
          const loader = document.getElementById('loader');
          loader.style.display = 'none';

          Swal.fire('Atenção!', error.response.data.message, 'warning');
        } else {
          const loader = document.getElementById('loader');
          loader.style.display = 'none';

          Swal.fire(
            'Atenção!',
            'Não foi possísvel deletar atividade.',
            'warning',
          );
        }
      }

      closeModal();
    }
  }, [activity, closeModal, handleUpdateDayHour]);

  const handleUpdateActivity = useCallback(async () => {
    try {
      if (
        activitySituationOption === 'Concluída' &&
        (Number(tryTimes) === 0 || talkOption === 'Não')
      ) {
        Swal.fire(
          'Atenção!',
          'Não é possível concluir atividade sem ter tentado ou falado.',
          'warning',
        );
      } else {
        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        const { data } = await api.patch('schedule/hunter-sla', {
          id: activity.id,
          observation: observation || activity.observation,
          try_times: tryTimes || activity.try_times,
          status: activitySituationOption || activity.status,
          spoken: talkOption || activity.spoken,
          timestamp: hourOption
            ? `${format(date, 'yyyy-MM-dd')} ${hourOption}`
            : undefined,
          hunter_id: hunterID || activity.hunter_id,
        });

        toast.success('Atividade atualizada com sucesso!');

        setTalkOption(talkOption || activity.spoken);
        setActivitySituationOption(activitySituationOption || activity.status);
        setObservation(observation || activity.observation);
        setTryTimes(tryTimes || String(activity.try_times));

        handleUpdateDayHour(activity.timestamp, data, activity);

        closeModal();
      }
    } catch (error) {
      if (error.response.data.status === 404) {
        Swal.fire('Atenção!', error.response.data.message, 'warning');
      } else {
        Swal.fire(
          'Atenção!',
          'Não foi possísvel atualizar atividade.',
          'warning',
        );
      }

      const loader = document.getElementById('loader');
      loader.style.display = 'none';
    }
  }, [
    activitySituationOption,
    tryTimes,
    talkOption,
    activity,
    observation,
    hourOption,
    date,
    handleUpdateDayHour,
    closeModal,
    hunterID,
  ]);

  const handleFilterClosersAvailable = useCallback(async () => {
    const { data } = await api.get(
      `schedule//list-all-hunters-available?date=${activity.timestamp}`,
    );

    setHunterAvailable(data);

    setChangeHunter(true);
    setEditFields(true);
  }, [activity.timestamp]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={Container}>
      <Loading />
      <Header
        isBefore={isBefore(new Date(activity.timestamp), new Date())}
        status={activity.status}
        try_times={activity.try_times}
      >
        {activity.timestamp && (
          <span>
            {format(new Date(activity.timestamp), 'dd/MM/yyyy')} -{' '}
            {format(new Date(activity.timestamp), 'HH:mm')}
          </span>
        )}

        <ButtonsHeaderContainer>
          {(user.profile === 'Hunter New' ||
            user.profile === 'Hunter Teste' ||
            user.profile === 'Hunter Líder' ||
            user.profile === 'Gestores Hunter New') &&
            (editFields ? (
              <EditDeleteButton
                editFields={editFields}
                onClick={handleUpdateActivity}
              >
                Salvar alterações
              </EditDeleteButton>
            ) : (
              <>
                <EditDeleteButton
                  type="button"
                  onClick={handleFilterClosersAvailable}
                >
                  <img src={edit_scheduling_manager} alt="Editar informações" />
                </EditDeleteButton>
                <EditDeleteButton onClick={() => setEditFields(true)}>
                  <img src={editIcon} alt="Editar informações" />
                </EditDeleteButton>
                <EditDeleteButton
                  id="deleteActivity"
                  type="button"
                  onClick={handleDeleteActivity}
                >
                  <img src={delete_icon} alt="Excluir atividade" />
                </EditDeleteButton>
              </>
            ))}
          <CloseButton onClick={closeModal}>
            <img src={closeIcon} alt="Fechar modal" />
          </CloseButton>
        </ButtonsHeaderContainer>
      </Header>

      <Main>
        {!changeHunter ? (
          <>
            <Label>{activity.name}</Label>

            <LabelInputContainer>
              <Label>Hunter:</Label>
              <Input type="text" disabled value={user.name} />
            </LabelInputContainer>

            {activity.closer_name && (
              <LabelInputContainer>
                <Label>Closer:</Label>
                <Input type="text" disabled value={activity.closer_name} />
              </LabelInputContainer>
            )}

            <LabelInputContainer>
              <Label>Conexão:</Label>
              <Input type="text" disabled value={activity.connection_type} />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label>Contato:</Label>
              <Input type="text" disabled value={activity.connection_method} />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label>ID do ticket:</Label>
              <Input type="text" disabled value={activity.ticket_id} />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label>Nome do credor:</Label>
              <Input type="text" disabled value={activity.dealname} />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label>Valor:</Label>
              <Input
                type="text"
                disabled
                value={formatAmount(Number(activity.amount))}
              />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label>Link Hubspot:</Label>
              <Link
                href={`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
              </Link>
            </LabelInputContainer>

            {editFields && (
              <>
                <LineSeparator />

                <LabelInputContainer className="observation-container">
                  <Label>Observações:</Label>
                  <Textarea
                    name="observation"
                    defaultValue={activity.observation || ''}
                    onChange={e => setObservation(e.target.value)}
                  />
                </LabelInputContainer>

                <LabelInputContainer className="editInputsContainer">
                  <Label htmlFor="try_times">Tentou:</Label>
                  <Input
                    min="0"
                    type="number"
                    name="try_times"
                    id="tryTimesInput"
                    defaultValue={tryTimes || 0}
                    onChange={e => setTryTimes(e.target.value)}
                  />
                </LabelInputContainer>

                <LabelInputContainer className="editInputsContainer">
                  <Label htmlFor="spoken">Falado:</Label>
                  {talkArray.map(talk => (
                    <ScheduleOptionsButton
                      key={talk}
                      selectedValue={talkOption === talk}
                      type="button"
                      onClick={() => setTalkOption(talk)}
                    >
                      {talk}
                    </ScheduleOptionsButton>
                  ))}
                </LabelInputContainer>

                <LabelInputContainer className="editInputsContainer situationActivity">
                  <Label htmlFor="activity_situation">Situação:</Label>
                  {activitySituation.map(situation => (
                    <ScheduleOptionsButton
                      key={situation}
                      selectedValue={activitySituationOption === situation}
                      type="button"
                      onClick={() => setActivitySituationOption(situation)}
                    >
                      {situation}
                    </ScheduleOptionsButton>
                  ))}
                </LabelInputContainer>

                <LineSeparator />

                <LabelInputContainer editFields={editFields}>
                  <Label>Reagendar:</Label>
                </LabelInputContainer>

                <LabelInputContainer>
                  <Label>Data:</Label>
                  <ContainerInput>
                    <ContainerInputDate>
                      <CalendarIcon />
                      <DayPicker
                        value={date}
                        handleChange={day => setDate(day)}
                        isEdit
                      />
                    </ContainerInputDate>
                  </ContainerInput>
                </LabelInputContainer>

                <LabelInputContainer className="hour-container">
                  <Label>Horário:</Label>

                  <HoursContainer>
                    {availableHours &&
                      availableHours.map(possibleHour => (
                        <ScheduleOptionsButton
                          key={String(possibleHour)}
                          selectedValue={hourOption === possibleHour}
                          type="button"
                          onClick={() =>
                            setHourOption(
                              hourOption === possibleHour ? '' : possibleHour,
                            )
                          }
                        >
                          {possibleHour}
                        </ScheduleOptionsButton>
                      ))}
                  </HoursContainer>
                </LabelInputContainer>
              </>
            )}
          </>
        ) : (
          <ChangeHunterContainer>
            <span id="title-choose-closer">
              Selecione um hunter disponível:
            </span>
            {hunterAvailable && hunterAvailable.length > 0 ? (
              hunterAvailable.map(hunter => (
                <ScheduleOptionsButton
                  key={hunter.id}
                  selectCloser
                  selectedValue={hunterID === hunter.id}
                  type="button"
                  onClick={() => {
                    setHunterID(hunter.id);
                  }}
                >
                  {hunter.user.name}
                </ScheduleOptionsButton>
              ))
            ) : (
              <span>Não há closer disponível!</span>
            )}
          </ChangeHunterContainer>
        )}
      </Main>
    </Modal>
  );
}
