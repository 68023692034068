import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface InputProps {
  setColor?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100vw;
  height: 100vh;
  @media (max-width: 1045px) {
    display: flex;
    flex-direction: column;
  }
  @media print {
    button {
      display: none;
    }
    #text-id {
      display: none;
    }
  }
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
`;

export const InputID = styled.input`
  background: #ffffff;
  border: 1px solid #05233e;
  box-shadow: 0px 4px 21px rgba(149, 154, 152, 0.29);
  border-radius: 0.5rem;
  padding: 0.3rem;
  &:focus {
    border: 1px solid #05233e;
    box-shadow: 0px 4px 4px #fff;
  }
  ::placeholder {
    font-size: 0.8rem;
  }
`;

export const ProposalButton = styled.button`
  width: 7.125rem;
  height: 1.75rem;

  background: var(--gray-900);
  border-radius: 0.313rem;
  border: none;

  font-weight: 600;
  font-size: 0.688rem;
  color: var(--white);
  text-align: center;

  transition: background-color 0.3s;

  &:hover {
    background: ${shade(0.2, '#777777')};
  }
`;

export const H2 = styled.h2`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.688rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.015em;
  margin-top: 1rem;
  padding: 0.25rem;

  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;
  color: #05233e;

  @media (max-width: 1045px) {
    justify-content: start;
    width: 95vw;
  }
  @media print {
    margin-top: 0;
  }
`;

export const Div = styled.h2``;

export const DivHeader = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
`;

export const DivInputHubSpot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DivInfos = styled.div`
  align-items: center;
  padding-left: 0.125rem;
  display: flex;
`;

export const DivIdCnx = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ComissionStaggeredContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
`;

export const DivComissionStaggered = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelHubspot = styled.label`
  width: 18.375rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 1.063rem;
  line-height: 1.563rem;
  letter-spacing: -0.015em;

  color: #05233e;
  @media print {
    margin-top: 0.5rem;
  }
`;

export const InputCnx = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 16.375rem;
  padding: 0.25rem;
  transition: border 0.2s;

  &:focus {
    border: 1px solid #2e9afe;
  }

  ::placeholder {
    font-size: 0.8rem;
  }
`;

export const InputInfos = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 10.938rem;
  padding: 0.125rem;
  transition: border 0.2s;

  &:focus {
    border: 1px solid #2e9afe;
  }

  ::placeholder {
    font-size: 0.8rem;
  }
`;

export const Label = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.188rem;
  letter-spacing: -0.015em;

  color: #05233e;
  @media print {
    margin-top: 0.5rem;
  }
`;

export const Input = styled.input<InputProps>`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 100%;
  padding: 0.25rem;
  transition: border 0.2s;
  margin-bottom: 0.5rem;

  background: ${props => (props.setColor ? '#ED3824' : '')};

  &:focus {
    border: 1px solid #2e9afe;
  }

  ::placeholder {
    font-size: 0.8rem;
  }

  @media print {
    margin-bottom: 0.7rem;
  }
`;

export const ButtonConfiguration = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7.563rem;
  height: 1.75rem;

  background: #817f7f;
  padding: 0.5rem;

  border-radius: 0.313rem;
  border: none;
  color: white;
  text-align: center;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: -0.015em;

  transition: background-color 0.2s;

  &:disabled {
    display: none;
    cursor: not-allowed;
  }

  &:hover {
    background: ${shade(0.2, '#817f7f')};
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.8rem;
  padding-right: 1.8rem;

  width: 9.125rem;
  height: 2.313rem;

  background: #019158;
  border-radius: 0.5rem;
  margin-top: 0.85rem;
  border: none;
  color: white;
  text-align: center;

  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.2, '#019158')};
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  gap: 0.5rem;

  @media (max-width: 1045px) {
    justify-content: start;
    flex-direction: column;
  }

  @media print {
    padding-bottom: 0;
  }
`;

export const Button1 = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.8rem;
  padding-right: 1.8rem;

  width: 9.125rem;
  height: 2.313rem;

  background: #767676;
  border-radius: 0.5rem;
  border: none;
  color: white;
  text-align: center;

  transition: background-color 0.3s;
  &:hover {
    background: ${shade(0.2, '#767676')};
  }
`;

export const Button2 = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  width: 9.125rem;
  height: 2.313rem;

  margin-left: 2rem;

  background: #063966;
  border-radius: 0.5rem;
  border: none;
  color: white;
  text-align: center;

  transition: background-color 0.3s;
  &:hover {
    background: ${shade(0.2, '#063966')};
  }
  @media (max-width: 1045px) {
    margin-left: 0;
  }
`;

export const Button3 = styled(Button2)`
  width: auto;
`;

export const Form = styled.form`
  margin-top: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 0;

  input:disabled {
    background: #eeeeee;
    color: black;
  }

  .container {
    display: grid;
    gap: 2.813rem;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .three {
    display: grid;
    gap: 2.813rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .two {
    display: grid;
    gap: 1.8rem;
    grid-template-columns: 1fr 1fr;
  }

  .one {
    display: grid;
    gap: 2.813rem;
    grid-template-columns: 1fr;
  }

  @media (max-width: 1045px) {
    width: 87vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container {
      gap: 0.5rem;
      grid-template-columns: 1fr;
    }

    .three {
      grid-template-columns: 1fr;
    }

    .two {
      grid-template-columns: 1fr;
    }
  }

  @media print {
    margin-top: 0;
    gap: 0;
    .container {
      gap: 0;
    }
    .three {
      gap: 0;
    }

    .two {
      gap: 0;
    }
  }
`;
