import styled from 'styled-components';

export const FileContainer = styled.div`
  /* width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column; */
`;

export const FileInputContainer = styled.div`
  display: inline-grid;
  align-items: center;
  height: 6rem;
  width: 32rem;
  background: var(--blue-100);
  border-radius: 0.625rem;

  @media (max-width: 1200px) {
    margin-left: 4.5rem;
    justify-content: center;
    margin-right: 0;
  }

  .teste {
    display: flex;
    justify-content: center;
    align-items: top;

    width: 15.375rem;
    height: 2.5rem;
  }
`;

export const SendButtonContainer = styled.div`
  display: flex;
  grid-column: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 2rem 0rem;
`;

export const ButtonSendFile = styled.button`
  padding: 0.2rem;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  width: 12rem;
  height: 1.625rem;

  background: var(--blue-600);

  &:hover {
    background: var(--blue-700);
  }
  border: 0;
  border-radius: 0.313rem;

  color: #ffffff;
`;

export const TextFileButton = styled.span`
  margin-top: 0.5rem;
  color: #666666;
  height: 1.313rem;
  width: 12rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.938rem;
  letter-spacing: -0.015em;
  text-align: center;
`;

export const DivSelect = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  .teste {
    display: block;
  }
`;
