import {
  Table,
  TableContainer,
  Title,
  Thead,
  TableRow,
  HeadCell,
  Tbody,
} from './style';
import { ICampaigns } from '../../dtos/ICampaigns';
import { TableRowCampaign } from '../TableRowCampaign';
import { PaginationComponent } from '../../../../components/Pagination';

interface IRegisteredCampaignsProps {
  handleRequestCampaigns: (page: number) => Promise<void>;
  campaigns: ICampaigns[];
  page: number;
  allPages: number[];
  type: string;
}

export function RegisteredCampaigns({
  campaigns,
  handleRequestCampaigns,
  page,
  allPages,
  type,
}: IRegisteredCampaignsProps): JSX.Element {
  return (
    <TableContainer>
      <Title>Campanhas registradas</Title>
      <Table>
        <Thead>
          <TableRow>
            <HeadCell>Nome</HeadCell>
            <HeadCell>Quantidade de lotes</HeadCell>
            <HeadCell>Quantidade de disparos</HeadCell>
            <HeadCell> </HeadCell>
          </TableRow>
        </Thead>
        <Tbody>
          {campaigns.map((campaign, index) => (
            <TableRowCampaign
              type={type}
              key={String(index)}
              campaign={campaign}
              handleRequestCampaigns={handleRequestCampaigns}
            />
          ))}
        </Tbody>
      </Table>
      <PaginationComponent
        totalPages={allPages}
        page={page}
        handleChangePagination={handleRequestCampaigns}
      />
    </TableContainer>
  );
}
