import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import {
  Navegation,
  H1NavigationNow,
  H1Navigation,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Title';
import { Body } from '../../../components/Styles/Body';
import { Container } from '../../../components/Container';
import { BackButton } from '../../../components/BackButton';
import { InputCheckbox } from '../../../components/InputCheckbox';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

import {
  ContainerTitleSection,
  TitleSection,
  FormContainer,
  Line,
  FormGroup,
  InputContainer,
  Label,
  Input,
  LinePermissions,
  FormGroupPermission,
  ContainerLevel,
  PermissionsContainer,
  ButtonsContainerCreateProfile,
  ButtonCreate,
  Icon,
} from './styles';

interface IPermission {
  id: string;
  name: string;
  description: string;
}

interface IPermissionData {
  name: string;
  permission: string[];
}

export const CreateProfile: React.FC = () => {
  const [permissions, setPermissions] = useState<IPermission[]>(
    [] as IPermission[],
  );
  const [formData, setFormData] = useState<IPermissionData>({
    name: '',
    permission: [],
  });

  const history = useHistory();

  useEffect(() => {
    api.get('/users/permissions').then(response => {
      setPermissions(response.data);
    });
  }, []);

  const handleChecked = useCallback(
    (id, value) => {
      const index = formData.permission.indexOf(id);

      if (value === true) {
        formData.permission.push(id);
        document.getElementById(value)?.setAttribute('checked', 'true');
      } else {
        formData.permission.splice(index, 1);
        document.getElementById(id)?.setAttribute('checked', 'null');
      }

      const input = document.getElementById(id) as HTMLInputElement;
      input.checked = value;
    },
    [formData],
  );

  const handleInputChangePermission = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = event.target;

      const permissionsByContext = permissions.filter(
        permission => permission.description === value && permission,
      );

      if (
        value === 'Processamento' ||
        value === 'Negociação' ||
        value === 'Administração'
      ) {
        for (const permission of permissionsByContext) {
          handleChecked(permission.id, checked);
        }
      } else {
        handleChecked(value, checked);
      }
    },
    [handleChecked, permissions],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const createProfileSuccess = useCallback(() => {
    toast.success('Perfil criado com sucesso');
  }, []);

  const createProfileError = useCallback((message?: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  const createNewProfile = useCallback(async () => {
    try {
      if (formData.name === '') {
        createProfileError('Por favor preencha o nome do perfil.');
      } else if (formData.permission.length <= 0) {
        createProfileError('Por favor marque as permissões do perfil.');
      } else {
        await api.post('/users/profiles', {
          name: formData.name,
          permissions: formData.permission,
        });

        createProfileSuccess();
        history.push({
          pathname: '/administration/profiles',
          state: {
            profile: formData.name,
          },
        });
      }
    } catch (error) {
      createProfileError(error.response.data.message);
    }
  }, [createProfileError, createProfileSuccess, formData, history]);

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Navegation>
        <H1Navigation>
          Administração {'>'} Perfis {'>'}{' '}
        </H1Navigation>
        <H1NavigationNow>Novo perfil</H1NavigationNow>
      </Navegation>

      <Body>
        <Title name="Novo perfil" />

        <BackButton
          link={{
            pathname: '/administration/profiles',
          }}
          isNotMargin
        />

        <ContainerTitleSection>
          <TitleSection>Dados de acesso</TitleSection>
        </ContainerTitleSection>

        <FormContainer>
          <Line />
          <FormGroup>
            <InputContainer>
              <Label>Nome do perfil:</Label>
              <Input name="name" onChange={handleInputChange} />
            </InputContainer>
          </FormGroup>
        </FormContainer>

        <ContainerTitleSection>
          <TitleSection>Permissões</TitleSection>
        </ContainerTitleSection>

        <FormContainer>
          <LinePermissions />
          <FormGroupPermission>
            <Label>Páginas permitidas:</Label>

            <PermissionsContainer>
              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Processamento"
                  name="Processamento"
                  change={handleInputChangePermission}
                />

                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Processamento' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Negociação"
                  name="Negociação"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Negociação' && (
                        <InputCheckbox
                          key={permission.id}
                          isPermission
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Administração"
                  name="Administração"
                  change={handleInputChangePermission}
                />

                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Administração' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Conexão"
                  name="Conexão"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Conexão' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Newsletter"
                  name="Newsletter"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Newsletter' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Vórtx"
                  name="Vórtx"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Vórtx' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Pagamento"
                  name="Pagamento"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Pagamento' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Nenhum' && (
                        <InputCheckbox
                          key={permission.id}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>
            </PermissionsContainer>
          </FormGroupPermission>
        </FormContainer>

        <ButtonsContainerCreateProfile>
          <ButtonCreate type="button" onClick={createNewProfile}>
            <Icon>
              <PlusIcon />
            </Icon>
            Criar Perfil
          </ButtonCreate>
        </ButtonsContainerCreateProfile>
      </Body>
    </Container>
  );
};
