import styled from 'styled-components';
import { shade } from 'polished';

export const StyleModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },

  content: {
    height: '11.438rem',
    width: '23.688rem',
    margin: '12rem auto auto 40%',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;
export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;

  font-family: Poppins;
  font-weight: 500;
  font-size: 0.8rem;
  color: #05233e;
  text-align: center;

  b {
    font-weight: 400;
    font-size: 0.7rem;
    color: #041524;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 9.125rem;
  height: 2.313rem;

  background: #014077;
  border-radius: 0.5rem;
  border: none;
  color: white;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  transition: background-color 0.3s;
  &:hover {
    background: ${shade(0.2, '#014077')};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;
