import { useState, useEffect, ChangeEvent, useCallback } from 'react';

import { format } from 'date-fns';
import {
  InputsContainer,
  InputDiv,
  InputDiv2,
  InputSpan,
  LabelCheckbox,
  Checkbox,
  InputCheckbox,
  SelectSpan,
  SelectBox,
  OptionBox,
} from './styles';
import checkImg from '../../../../assets/icons/check.svg';
import api from '../../../../services/api';
import { IPerfomance } from '..';

interface IProps {
  from: Date;
  to: Date;
  setPerformances: (data: Array<IPerfomance>) => void;
}

export function InputsPerformance({
  from,
  to,
  setPerformances,
}: IProps): JSX.Element {
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawInconvertibleCases, setWithdrawInconvertibleCases] =
    useState(false);
  const [firstPass, setFirstPass] = useState(true);
  const [resumption, setResumption] = useState(true);
  const [inbound, setInbound] = useState(true);
  const [outbound, setOutbound] = useState(true);
  const [firstPass2, setFirstPass2] = useState(true);
  const [postProposal, setPostProposal] = useState(true);
  const [preProposal, setPreProposal] = useState(true);
  const [loa2022, setLoa2022] = useState(true);
  const [loa2023, setLoa2023] = useState(true);
  const [loa2024, setLoa2024] = useState(true);
  const [transparencyFailure, setTransparencyFailure] = useState('todos');
  const [scheduling, setScheduling] = useState('todos');

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { id, value } = event.target;
      if (id === 'scheduling') {
        setTransparencyFailure(value);
      } else if (id === 'transparencyFailure') {
        setScheduling(value);
      }
    },
    [],
  );

  useEffect(() => {
    const searchFilter = JSON.stringify({
      withdrawOpen,
      withdrawInconvertibleCases,
      firstPass,
      resumption,
      inbound,
      outbound,
      firstPass2,
      postProposal,
      preProposal,
      loa2022,
      loa2023,
      loa2024,
      transparencyFailure,
      scheduling,
    });

    const initialDate = format(from, 'yyyy-MM-dd');
    const finalDate = format(to, 'yyyy-MM-dd');

    api
      .get(
        `/hunter_panel/report-data-search/${initialDate}/${finalDate}?search_information=${searchFilter}`,
      )
      .then(response => {
        setPerformances(response.data);
      });
  }, [
    firstPass,
    firstPass2,
    from,
    inbound,
    loa2022,
    loa2023,
    loa2024,
    outbound,
    postProposal,
    preProposal,
    resumption,
    scheduling,
    setPerformances,
    to,
    transparencyFailure,
    withdrawInconvertibleCases,
    withdrawOpen,
  ]);

  return (
    <InputsContainer>
      <Checkbox className="checkbox-1">
        <InputDiv>
          <LabelCheckbox
            isActive={withdrawOpen}
            onChange={() => {
              setWithdrawOpen(!withdrawOpen);
            }}
          >
            {withdrawOpen && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>Retirar em aberto</InputSpan>
        </InputDiv>
      </Checkbox>

      <Checkbox className="checkbox-1">
        <InputDiv>
          <LabelCheckbox
            isActive={withdrawInconvertibleCases}
            onChange={() => {
              setWithdrawInconvertibleCases(!withdrawInconvertibleCases);
            }}
          >
            <InputCheckbox />
            {withdrawInconvertibleCases && <img src={checkImg} alt="check" />}
          </LabelCheckbox>
          <InputSpan>Retirar casos inconversível</InputSpan>
        </InputDiv>
      </Checkbox>

      <Checkbox className="checkbox-1 ">
        <InputDiv2>
          <SelectSpan>Falha de transparencia</SelectSpan>
          <SelectBox
            id="transparencyFailure"
            defaultValue="todos"
            onChange={handleInputChange}
          >
            <OptionBox value="todos">Todos</OptionBox>
            <OptionBox value="true">Sim</OptionBox>
            <OptionBox value="false">Não</OptionBox>
          </SelectBox>
        </InputDiv2>
      </Checkbox>

      <Checkbox className="checkbox-1 ">
        <InputDiv2>
          <SelectSpan>Agendamento</SelectSpan>
          <SelectBox id="scheduling" onChange={handleInputChange}>
            <OptionBox value="todos">Todos</OptionBox>
            <OptionBox value="true">Agendado</OptionBox>
            <OptionBox value="false">Sem agendamento</OptionBox>
          </SelectBox>
        </InputDiv2>
      </Checkbox>

      <Checkbox className="checkbox-2">
        <InputDiv>
          <LabelCheckbox
            isActive={firstPass}
            onChange={() => {
              setFirstPass(!firstPass);
            }}
          >
            <InputCheckbox />
            {firstPass && <img src={checkImg} alt="check" />}
          </LabelCheckbox>
          <InputSpan>1ª Passagem</InputSpan>
        </InputDiv>
        <InputDiv>
          <LabelCheckbox
            isActive={resumption}
            onChange={() => {
              setResumption(!resumption);
            }}
          >
            <InputCheckbox />
            {resumption && <img src={checkImg} alt="check" />}
          </LabelCheckbox>
          <InputSpan>Retomada</InputSpan>
        </InputDiv>
      </Checkbox>

      <Checkbox className="checkbox-2">
        <InputDiv>
          <LabelCheckbox
            isActive={inbound}
            onChange={() => {
              setInbound(!inbound);
            }}
          >
            {inbound && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>Inbound</InputSpan>
        </InputDiv>
        <InputDiv>
          <LabelCheckbox
            isActive={outbound}
            onChange={() => {
              setOutbound(!outbound);
            }}
          >
            {outbound && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>Outbound</InputSpan>
        </InputDiv>
      </Checkbox>

      <Checkbox className="checkbox-3">
        <InputDiv>
          <LabelCheckbox
            isActive={firstPass2}
            onChange={() => {
              setFirstPass2(!firstPass2);
            }}
          >
            {firstPass2 && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>1ª Passagem</InputSpan>
        </InputDiv>
        <InputDiv>
          <LabelCheckbox
            isActive={postProposal}
            onChange={() => {
              setPostProposal(!postProposal);
            }}
          >
            {postProposal && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>Pos-proposta</InputSpan>
        </InputDiv>
        <InputDiv>
          <LabelCheckbox
            isActive={preProposal}
            onChange={() => {
              setPreProposal(!preProposal);
            }}
          >
            {preProposal && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>Pré-proposta</InputSpan>
        </InputDiv>
      </Checkbox>

      <Checkbox className="checkbox-3">
        <InputDiv>
          <LabelCheckbox
            isActive={loa2022}
            onChange={() => {
              setLoa2022(!loa2022);
            }}
          >
            {loa2022 && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>LOA 2022</InputSpan>
        </InputDiv>
        <InputDiv>
          <LabelCheckbox
            isActive={loa2023}
            onChange={() => {
              setLoa2023(!loa2023);
            }}
          >
            {loa2023 && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>LOA 2023</InputSpan>
        </InputDiv>
        <InputDiv>
          <LabelCheckbox
            isActive={loa2024}
            onChange={() => {
              setLoa2024(!loa2024);
            }}
          >
            {loa2024 && <img src={checkImg} alt="check" />}
            <InputCheckbox />
          </LabelCheckbox>
          <InputSpan>LOA 2024</InputSpan>
        </InputDiv>
      </Checkbox>
    </InputsContainer>
  );
}
