import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  contactValue: string;
  selected: string;
}

const SchedulingContactButton: React.FC<ButtonProps> = ({
  children,
  contactValue,
  selected,

  ...rest
}) => {
  return (
    <Container
      type="button"
      contactValue={contactValue}
      selected={selected}
      {...rest}
    >
      {children}
    </Container>
  );
};

export { SchedulingContactButton };
