import styled, { css } from 'styled-components';

interface IProps {
  isEdit: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .DayPicker-Day--today {
    color: #fff;
    background-color: #1665d8;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    font-weight: 700;
    color: #041524;
    background-color: #cfdef3;
  }

  ${props =>
    props.isEdit &&
    css`
      .DayPickerInput-Overlay {
        bottom: 50%;
        margin-bottom: 1.8rem;
      }
    `}
`;
