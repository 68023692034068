import Swal from 'sweetalert2';
import { BsPeopleFill } from 'react-icons/bs';
import { gql, useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AiFillStar, AiOutlineArrowLeft, AiOutlineLock } from 'react-icons/ai';
import { FiMail, FiEyeOff, FiEye } from 'react-icons/fi';

import api from '../../services/api';

import { Body } from '../../components/Styles/Body';
import { Container } from '../../components/Container';

import {
  BackButtonContainer,
  Icon,
  ContainerProfile,
  ContainerDados,
  ProfileInfoContainer,
  H1,
  H2,
  DivH2,
  DivInformation,
  InputAvatar,
  DivIcon,
  DivInput,
  IconBack,
  ContainerForm,
  TitleContainer,
  TitleHeader,
  InputContainer,
  Label,
  ContainerButton,
  ButtonSave,
  Input,
  ContainerIcon,
} from './styles';

import user_profile_icon from '../../assets/icons/user_profile.svg';
import add_image_icon from '../../assets/icons/add_image.svg';

interface Team {
  id: string;
  name: string;
  leader_id: string;
}
interface IUpdateUser {
  now_password: string;
  new_password: string;
  new_password_confirm: string;
  avatar: string;
}

const Profile: React.FC = () => {
  const user = JSON.parse(localStorage.getItem('@Precato:user'));
  const [team, setTeam] = useState<Team>();
  const [userFormData, setUserFormData] = useState<IUpdateUser>(
    {} as IUpdateUser,
  );
  const [cardFile, setCardFile] = useState('');

  const history = useHistory();

  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<boolean>(true);
  const [verifyNewPassword, setVerifyNewPassword] = useState<boolean>(true);

  const [containSpecial, setContainSpecial] = useState<boolean>(false);
  const [containUpperCase, setContainUpperCase] = useState<boolean>(false);
  const [passwordLength, setPasswordLength] = useState<boolean>(false);

  const [showNowPassword, setShowNowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const UPDATE_USER = gql`
    mutation Mutation(
      $oldPassword: String!
      $password: String!
      $updateUserByProfileId: String!
    ) {
      updateUserByProfile(
        old_password: $oldPassword
        password: $password
        id: $updateUserByProfileId
      ) {
        id
        profile_id
        team_id
        email
        name
        avatar
        avatar_url
      }
    }
  `;

  const [updateUser] = useMutation(UPDATE_USER, {
    variables: {
      oldPassword: userFormData.now_password || '',
      password: userFormData.new_password || '',
      updateUserByProfileId: user.id,
    },
  });

  useEffect(() => {
    if (user.team_id) {
      api.get(`/people/teams/${user.team_id}`).then(response => {
        setTeam(response.data);
      });
    }
  }, [user]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name === 'new_password') {
        const special_characters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        const uppercase = /[A-Z]/;

        setContainSpecial(special_characters.test(value));
        setContainUpperCase(uppercase.test(value));
        setPasswordLength(value.length >= 8);

        if (
          special_characters.test(value) &&
          uppercase.test(value) &&
          value.length >= 8
        ) {
          setVerifyNewPassword(true);
        } else {
          setVerifyNewPassword(false);
        }
      }

      if (
        name === 'new_password_confirm' &&
        value !== userFormData.new_password
      ) {
        setConfirmPassword(false);
      } else {
        setConfirmPassword(true);
      }

      setUserFormData({ ...userFormData, [name]: value });
    },
    [userFormData],
  );

  const updateData = useCallback(
    async (avatarUrl: string) => {
      localStorage.removeItem('@Precato:user');

      localStorage.setItem(
        '@Precato:user',
        JSON.stringify({
          ...user,
          avatar_url: cardFile ? avatarUrl : user.avatar_url,
        }),
      );
    },
    [user, cardFile],
  );

  const editSuccess = useCallback(() => {
    Swal.fire('Boa!', 'Seu perfil foi editado com sucesso!', 'success');
  }, []);

  const editError = useCallback((message: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  const updateProfile = useCallback(async () => {
    try {
      const data = new FormData();

      data.append('avatar', cardFile);

      if (confirmPassword && verifyNewPassword) {
        let avatarUrl: string;

        if (cardFile) {
          await api.patch(`/users/avatar`, data).then(response => {
            avatarUrl = response.data.avatar_url;
          });
        }

        await updateData(avatarUrl);

        await updateUser();

        editSuccess();

        history.push('/dashboard');
      }
    } catch (error) {
      if (
        error.message
          .split('Unexpected error value: ')[1]
          .split('{ message: ')[1]
          .split(',')[0]
          .split('"')[1] === 'Senha atual inválida!'
      ) {
        setPasswordError(true);
      } else {
        editError(
          error.message
            .split('Unexpected error value: ')[1]
            .split('{ message: ')[1]
            .split(',')[0]
            .split('"')[1],
        );
      }
    }
  }, [
    cardFile,
    confirmPassword,
    verifyNewPassword,
    updateData,
    updateUser,
    editSuccess,
    history,
    editError,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUploadFile = (e: any) => {
    setCardFile(e.target.files[0]);

    document
      .getElementById('avatar')
      ?.setAttribute('src', URL.createObjectURL(e.target.files[0]));
  };

  return (
    <Container>
      <Body>
        <BackButtonContainer>
          <Link to="/dashboard">
            <IconBack>
              <AiOutlineArrowLeft />
            </IconBack>
            Voltar
          </Link>
        </BackButtonContainer>

        <ContainerProfile>
          <ContainerDados>
            <DivIcon>
              <a rel="noreferrer" target="_blank" href={user.avatar_url}>
                <img id="avatar" src={user.avatar_url} alt="User Avatar" />
              </a>
              <DivInput>
                <label htmlFor="file-input">
                  <img src={add_image_icon} alt="Adicionar avatar" />
                </label>
                <InputAvatar
                  id="file-input"
                  type="file"
                  name="avatar"
                  onChange={handleUploadFile}
                  accept="image/*"
                />
              </DivInput>
            </DivIcon>
            <ProfileInfoContainer>
              <H1>{user.name}</H1>
              <DivInformation>
                <DivH2>
                  <H2>
                    <Icon>
                      <img src={user_profile_icon} alt="User Avatar" />
                    </Icon>
                    {user.profile}
                  </H2>
                </DivH2>

                <DivH2>
                  {team?.name ? (
                    <H2>
                      <Icon>
                        <BsPeopleFill />
                      </Icon>
                      {team?.name}
                    </H2>
                  ) : (
                    ''
                  )}
                </DivH2>
                {user.id === team?.leader_id ? (
                  <DivH2>
                    <H2>
                      <Icon>
                        <AiFillStar />
                      </Icon>
                      Líder
                    </H2>
                  </DivH2>
                ) : (
                  ''
                )}
              </DivInformation>
            </ProfileInfoContainer>
          </ContainerDados>

          <ContainerForm>
            <TitleContainer>
              <TitleHeader>Meu perfil</TitleHeader>
            </TitleContainer>
            <InputContainer>
              <Label>Email:</Label>
              <ContainerIcon>
                <FiMail />
                <Input
                  id="email"
                  name="email"
                  type="text"
                  disabled
                  defaultValue={user.email}
                />
              </ContainerIcon>
            </InputContainer>
            <InputContainer passwordError={passwordError}>
              <Label>Senha atual: *</Label>
              <ContainerIcon id="now-password-container">
                <AiOutlineLock />
                <Input
                  id="now_password"
                  name="now_password"
                  type={showNowPassword ? 'text' : 'password'}
                  onChange={handleInputChange}
                />

                <button
                  type="button"
                  disabled={!userFormData.now_password}
                  onClick={() => setShowNowPassword(!showNowPassword)}
                >
                  {showNowPassword ? (
                    <FiEye size={14} />
                  ) : (
                    <FiEyeOff size={14} />
                  )}
                </button>
              </ContainerIcon>
              <span id="password-message-error" className="message-error">
                Senha atual inválida.
              </span>
            </InputContainer>
            <InputContainer
              passwordLength={passwordLength}
              containSpecial={containSpecial}
              containUpperCase={containUpperCase}
              newPassword={
                userFormData.new_password !== undefined &&
                userFormData.new_password !== ''
              }
            >
              <Label>Nova senha:</Label>
              <ContainerIcon id="new-password-container">
                <AiOutlineLock />
                <Input
                  id="new_password"
                  name="new_password"
                  type={showNewPassword ? 'text' : 'password'}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNowPassword ? (
                    <FiEye size={14} />
                  ) : (
                    <FiEyeOff size={14} />
                  )}
                </button>
              </ContainerIcon>
              <span id="password-message-length" className="message-error">
                Sua senha deve conter no mínimo 8 caracteres.
              </span>
              <span id="password-message-special" className="message-error">
                Sua senha deve conter no mínimo 1 caractere especial.
              </span>
              <span id="password-message-upper" className="message-error">
                Sua senha deve conter no mínimo 1 letra maiúscula.
              </span>
            </InputContainer>
            <InputContainer confirmPassword={confirmPassword}>
              <Label>Confirme a nova senha:</Label>
              <ContainerIcon id="new-password-confirm">
                <AiOutlineLock />
                <Input
                  id="new_password_confirm"
                  name="new_password_confirm"
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  disabled={!userFormData.now_password}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showNowPassword ? (
                    <FiEye size={14} />
                  ) : (
                    <FiEyeOff size={14} />
                  )}
                </button>
              </ContainerIcon>
              <span id="confirm-message-error" className="message-error">
                As senhas não correspondem.
              </span>
            </InputContainer>
            <ContainerButton>
              <ButtonSave
                type="button"
                onClick={updateProfile}
                disabled={!userFormData.now_password}
              >
                Salvar alterações
              </ButtonSave>
            </ContainerButton>
          </ContainerForm>
        </ContainerProfile>
      </Body>
    </Container>
  );
};

export default Profile;
