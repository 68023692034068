import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';

import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/polygon_down.svg';

import { ModalAutomaticTransfer } from '../../components/ModalAutomaticTransfer';

import {
  Container,
  Dropdown,
  IconCreate,
  CreateActivityOptions,
  CreateActivity,
} from './styles';
import { BeamModal } from '../BeamModal';
import { ReturnModal } from '../ReturnModal';
import { LostModal } from '../LostModal';
import { OpportunityModal } from '../OpportunityModal';
import { ModalSLA } from '../../components/ModalSLA/index';
import { ISLAComplet } from '../../components/ModalSLA/interface';

interface IDropdownCreateActivityProps {
  profile: string;
}

export function DropdownCreateActivity({
  profile,
}: IDropdownCreateActivityProps): JSX.Element {
  const [createActivity, setCreateActivity] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openBeamModal, setOpenBeamModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [openLostModal, setOpenLostModal] = useState(false);
  const [openOpportunityModal, setOpenOpportunityModal] = useState(false);
  const [openN1Modal, setOpenN1Modal] = useState(false);
  const [openN2Modal, setOpenN2Modal] = useState(false);
  const [openN3Modal, setOpenN3Modal] = useState(false);
  const [openN4Modal, setOpenN4Modal] = useState(false);

  const handleCloseTransferModal = useCallback(() => {
    setCreateActivity(false);
    setOpenTransferModal(false);
  }, []);

  const handleCloseBeamModal = useCallback(() => {
    setCreateActivity(false);
    setOpenBeamModal(false);
  }, []);

  const handleCloseReturnModal = useCallback(() => {
    setCreateActivity(false);
    setOpenReturnModal(false);
  }, []);

  const handleCloseLostModal = useCallback(() => {
    setCreateActivity(false);
    setOpenLostModal(false);
  }, []);

  const handleCloseOpportunityModal = useCallback(() => {
    setCreateActivity(false);
    setOpenOpportunityModal(false);
  }, []);

  return (
    <Container>
      <Dropdown
        type="button"
        onClick={() => setCreateActivity(!createActivity)}
      >
        Criar atividade
        <IconCreate>
          <ArrowDownIcon />
        </IconCreate>
      </Dropdown>
      {createActivity && (
        <CreateActivityOptions>
          <CreateActivity
            type="button"
            id="first-option"
            onClick={() => setOpenBeamModal(true)}
          >
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            Trave
          </CreateActivity>

          <CreateActivity
            type="button"
            onClick={() => setOpenReturnModal(true)}
          >
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            Retorno
          </CreateActivity>

          <CreateActivity type="button" onClick={() => setOpenLostModal(true)}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            Perdido
          </CreateActivity>

          {/*  {(profile === 'Hunter New' ||
            profile === 'Hunter Líder' ||
            profile === 'Gestores Hunter New') && (
            <>
              <CreateActivity
                type="button"
                onClick={() => setOpenOpportunityModal(true)}
              >
                <IconCreate>
                  <AiOutlinePlus />
                </IconCreate>
                Oportunidade
              </CreateActivity>
            </>
          )} */}
          {/*  {profile !== 'Hunter Teste' && (
            <CreateActivity
              type="button"
              onClick={() => setOpenTransferModal(true)}
            >
              <IconCreate>
                <AiOutlinePlus />
              </IconCreate>
              Transferência
            </CreateActivity>
          )} */}

          <CreateActivity type="button" onClick={() => setOpenN1Modal(true)}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            N1
          </CreateActivity>

          <CreateActivity type="button" onClick={() => setOpenN2Modal(true)}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            N2
          </CreateActivity>
          <CreateActivity type="button" onClick={() => setOpenN3Modal(true)}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            N3
          </CreateActivity>
          <CreateActivity type="button" onClick={() => setOpenN4Modal(true)}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            N4
          </CreateActivity>

          <Link to="/negociations/schedule/hunter/create">
            <CreateActivity type="button" id="last-option">
              <IconCreate>
                <AiOutlinePlus />
              </IconCreate>
              Agendamento
            </CreateActivity>
          </Link>
        </CreateActivityOptions>
      )}

      <ModalAutomaticTransfer
        is_open={openTransferModal}
        on_request_close={handleCloseTransferModal}
        setCreateActivity={setCreateActivity}
      />

      <BeamModal isOpen={openBeamModal} onRequestClose={handleCloseBeamModal} />

      <ReturnModal
        isOpen={openReturnModal}
        onRequestClose={handleCloseReturnModal}
      />

      <LostModal isOpen={openLostModal} onRequestClose={handleCloseLostModal} />

      <OpportunityModal
        isOpen={openOpportunityModal}
        onRequestClose={handleCloseOpportunityModal}
      />

      <ModalSLA
        isOpen={openN1Modal}
        onRequestClose={() => setOpenN1Modal(false)}
        typeModal="N1"
        handleSaveSLA={undefined}
        handleChange={undefined}
        availableHours={[]}
        SLAInfo={{} as ISLAComplet}
        closer_name_scheduling={undefined}
        handleChangeCurrency={undefined}
      />

      <ModalSLA
        isOpen={openN2Modal}
        onRequestClose={() => setOpenN2Modal(false)}
        typeModal="N2"
        handleSaveSLA={undefined}
        handleChange={undefined}
        availableHours={[]}
        SLAInfo={undefined}
        closer_name_scheduling={undefined}
        handleChangeCurrency={undefined}
      />
      <ModalSLA
        isOpen={openN3Modal}
        onRequestClose={() => setOpenN3Modal(false)}
        typeModal="N3"
        handleSaveSLA={undefined}
        handleChange={undefined}
        availableHours={[]}
        SLAInfo={undefined}
        closer_name_scheduling={undefined}
        handleChangeCurrency={undefined}
      />
      <ModalSLA
        isOpen={openN4Modal}
        onRequestClose={() => setOpenN4Modal(false)}
        typeModal="N4"
        handleSaveSLA={undefined}
        handleChange={undefined}
        availableHours={[]}
        SLAInfo={undefined}
        closer_name_scheduling={undefined}
        handleChangeCurrency={undefined}
      />
    </Container>
  );
}
