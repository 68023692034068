import styled from 'styled-components';
import { shade } from 'polished';

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 16rem;

  margin-bottom: 2.438rem;
  @media print {
    display: none;
  }
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const ButtonCreate = styled.button`
  width: 12rem;
  height: 2rem;
  color: #ffffff;
  border: none;

  padding: 0.7rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;

  background: #014077;
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  color: #ffffff;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.3, '#014077')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const IconCreate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsDateContainer = styled.div`
  width: 100%;

  margin-bottom: 2.188rem;

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 17rem;
  }
`;
