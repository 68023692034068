import { useCallback, useEffect, useState } from 'react';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import {
  IFieldsData,
  ListCasesComponent,
} from '../../Hunter/components/ListCasesComponent';
import { Main } from './styles';
import { IWalletCaseModal, WalletCaseModal } from './WalletCaseModal';

interface IWalletProps {
  closerId: string | undefined;
}

export const CloserWalletCases: React.FC<IWalletProps> = ({
  closerId,
}: IWalletProps) => {
  const { user } = useAuth();

  const [walletCases, setWalletCases] = useState<IFieldsData[]>([]);
  const [currentWalletCases, setCurrentWalletCases] =
    useState<IWalletCaseModal>({} as IWalletCaseModal);
  const [openModalWallet, setOpenModalWallet] = useState<boolean>(false);

  const fields = [
    {
      field: 'Data Ultimo Contato',
      label: 'last_stage_date',
      clickable: true,
    },
    { field: 'Valor', label: 'deal_value', clickable: true },
    { field: 'Nome', label: 'deal_name', clickable: true },
    { field: 'Cadência', label: 'name', clickable: true },
    { field: 'Link do hubspot', label: 'hubspot_ticket_id', clickable: true },
  ];

  useEffect(() => {
    api
      .get(`schedule/closer-wallet?user_id=${closerId || user.id}`)
      .then(response => {
        setWalletCases(response.data);
      });
  }, [closerId, user.id]);

  const handleOpenModalNutrition = useCallback(
    (id: string) => {
      const foundCurrentWalletCase = walletCases.find(
        walletCase => walletCase.id === id,
      );

      setCurrentWalletCases({
        id,
        name: foundCurrentWalletCase.name,
        ticket_sla: foundCurrentWalletCase.ticket_sla,
        status: foundCurrentWalletCase.status,
        spoken: foundCurrentWalletCase.spoken,
        try: foundCurrentWalletCase.try,
        last_stage_date: foundCurrentWalletCase.conection_date,
        deal_value: foundCurrentWalletCase.deal_value,
        hubspot_ticket_id: foundCurrentWalletCase.hubspot_ticket_id,
        hs_pipeline_stage: foundCurrentWalletCase.hs_pipeline_stage,
        deal_name: foundCurrentWalletCase.deal_name,
        hubspot_owner_id: foundCurrentWalletCase.hubspot_owner_id,
        observation: foundCurrentWalletCase.observation,
      });

      setOpenModalWallet(true);
    },
    [walletCases],
  );

  const handleCloseModalNutrition = useCallback(() => {
    setOpenModalWallet(false);
    api
      .get(`schedule/closer-wallet?user_id=${closerId || user.id}`)
      .then(response => {
        setWalletCases(response.data);
      });
  }, [closerId, user.id]);

  return (
    <Main>
      {walletCases && walletCases.length > 0 ? (
        <ListCasesComponent
          datas={walletCases}
          fields={fields}
          onClickList={handleOpenModalNutrition}
        />
      ) : (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <p>Nenhum caso encontrada</p>
        </div>
      )}
      <WalletCaseModal
        isOpen={openModalWallet}
        walletCase={currentWalletCases}
        onRequestClose={handleCloseModalNutrition}
      />
    </Main>
  );
};
