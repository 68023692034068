import styled from 'styled-components';

export const ModalSchedule = {
  content: {
    width: '48.75rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0 0 1rem 0',
    borderRadius: '0.625rem',
  },
};

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 3.125rem;
  text-align: left;

  background: #014077;
  border-radius: 0.625rem 0.625rem 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    padding-left: 1.5rem;
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.25rem;
    color: #ffffff;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  margin-right: 1.5rem;

  button {
    border: none;
    background: transparent;
    height: 1.25rem;
  }

  #saveChanges {
    width: 10.375rem;
    height: 1.75rem;

    border-radius: 0.25rem;

    background: #ffffff;
    cursor: pointer;

    font-family: Poppins;
    font-weight: 600;
    font-size: 1rem;
    color: #1665d8;
  }

  #deleteActivity {
    margin-right: 1.5rem;
  }

  #closeModal {
    margin-left: 1.5rem;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 1.5rem;
  margin-top: 0.5rem;

  span {
    font-size: 1rem;
    font-family: Poppins;
    font-weight: 700;
    color: #041524;
    margin-bottom: 0.8rem;
  }

  label {
    color: #041524;
    font-weight: 600;
    margin-right: 0.25rem;
    font-size: 1rem;
    font-family: Poppins;

    margin-bottom: 0.938rem;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.8rem;

  p,
  input {
    font-size: 1rem;
    font-family: Poppins;
    border: none;
    color: #3d3d3d;
    font-weight: 500;
    background: transparent;
  }

  input#dealname {
    width: 22rem;
  }

  button {
    margin-right: 1.25rem;
  }

  p {
    background: #c4c4c4;
    border-radius: 0.25rem;
    padding: 0 0.5rem;

    height: 1.625rem;

    font-weight: 600;
    font-size: 0.75rem;
    color: #041524;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  select {
    width: 8.5rem;
    height: 1.625rem;

    background: #efefef;
    border: 0.031rem solid #063966;
    box-sizing: border-box;
    border-radius: 0.25rem;

    font-family: Poppins;
    font-weight: 500;
    font-size: 1rem;
    color: #3d3d3d;
  }

  label {
    color: #041524;
    font-weight: 600;
    margin-right: 0.25rem;
    font-size: 1rem;
    font-family: Poppins;

    margin-bottom: 0;
  }

  input#every_day {
    background-color: #1665d8;
    cursor: pointer;
  }

  p#hourText {
    margin: 0 0.938rem 0 0.938rem;
  }

  p#every_day {
    margin-left: 0.938rem;
  }

  .editInput {
    background: #efefef;
    border: 0.031rem solid #063966;
    border-radius: 0.25rem;
    height: 2.313rem;
    width: 45.375rem;
    padding-left: 0.25rem;
  }
`;

export const FrequencyActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.188rem;
  width: 100%;
`;

export const InputsRadioContainer = styled.div`
  display: flex;
  margin-top: 0.45rem;
  align-items: center;

  width: 100%;

  input {
    width: 0.85rem;
    height: 0.85rem;

    cursor: pointer;
    color: #1665d8;
    margin-right: 0.438rem;
  }

  label {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.75rem;
    color: #3d3d3d;
    margin: 0;
  }
`;

export const CredorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColumnCredorContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 10rem;
    height: 2.25rem;

    background: #efefef;
    border-radius: 0.25rem;
    margin-right: 1.25rem;
    padding-left: 0.5rem;

    font-family: Poppins;
    font-weight: 500;
    font-size: 1rem;
    color: #3d3d3d;
  }

  #hubspot_deal_id {
    border: 0.5px solid #063966;
  }

  #dealname {
    width: 21.5rem;
  }
`;
