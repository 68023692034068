import { useCallback } from 'react';
import {
  Timetable,
  IconHour,
  Image,
  Time,
  LineTable,
  CellContainer,
  Legend,
  Info,
  LineSeparator,
  InfoCredor,
  TextInfo,
  ButtonSituation,
} from './styles';

import watch from '../../../../assets/icons/watch.svg';
import { IHunterScheduleDTO } from '../../dtos/IHunterScheduleDTO';

interface IScheduleHunterContainer {
  schedules: IHunterScheduleDTO[];
  schedule_status?: boolean;
}
export const ManagerSchedulingsContainer: React.FC<IScheduleHunterContainer> =
  ({ schedules, schedule_status }) => {
    const formattedTime = useCallback((timestamp: Date) => {
      return `${new Date(timestamp).getHours()}:${String(
        new Date(timestamp).getMinutes(),
      ).padStart(2, '0')}`;
    }, []);

    return (
      <>
        {schedules &&
          schedules.map(schedule => (
            <Timetable key={schedule.id}>
              <IconHour>
                <Image src={watch} alt="Relógio" />
                <Time>{formattedTime(schedule.timestamp)}</Time>
              </IconHour>

              <LineTable
                talkProps={schedule.talk}
                statusTaskProps={schedule.status ? schedule.status : 'Pendente'}
                existTryTimesProps={!!schedule.try_times}
                tryTimesProps={String(schedule.try_times)}
                statusBusinessTask={
                  schedule.business_situation
                    ? schedule.business_situation
                    : 'Em aberto'
                }
                isColored={schedule_status}
              >
                <CellContainer className="closerAvatar">
                  <img src={schedule.closer_avatar} alt="Closer avatar" />
                </CellContainer>
                <CellContainer className="closer">
                  <Legend>Closer:</Legend>
                  <Info>{schedule.closer_name}</Info>
                </CellContainer>

                <LineSeparator />
                {schedule.manager_name && (
                  <>
                    <CellContainer className="closer">
                      <Legend>Gestor:</Legend>
                      <Info>{schedule.manager_name}</Info>
                    </CellContainer>

                    <LineSeparator />
                  </>
                )}

                <CellContainer className="value">
                  <Legend>Valor:</Legend>
                  <Info>{schedule.amount}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="connection">
                  <Legend>Conexão:</Legend>
                  <Info>{schedule.connection_method}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="contact">
                  <Legend>Contato:</Legend>
                  <Info>{schedule.contact_preference}</Info>
                </CellContainer>

                <LineSeparator />

                <CellContainer className="credor">
                  <InfoCredor>
                    <TextInfo>
                      <Legend>Credor:</Legend>
                      <Info>{schedule.dealname}</Info>
                    </TextInfo>

                    <TextInfo>
                      <Legend>Link Hubspot: </Legend>
                      <Info>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://app.hubspot.com/contacts/7857327/deal/${schedule.hubspot_deal_id}/`}
                        >
                          {schedule.hubspot_deal_id}
                        </a>
                      </Info>
                    </TextInfo>
                  </InfoCredor>
                </CellContainer>
              </LineTable>
            </Timetable>
          ))}
      </>
    );
  };
