import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import { confirmModal } from '../../components/ModalConfirm';
import { Container } from '../../components/Container';
import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';
import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../components/Styles/Navigation';

import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import user_icon from '../../assets/icons/user.svg';
import search_icon from '../../assets/icons/search.svg';

import {
  DivSeparator,
  Separator,
  ContainerHeader,
  SearchContainer,
  ButtonNewProfile,
  InputSearch,
  Table,
  Thead,
  Tbody,
  ButtonDelete,
  ButtonEdit,
  IconForm,
  ContainerTitle,
  LoaderContainer,
  TextLoader,
  ContainerExport,
  Export,
} from './styles';

interface IProfile {
  id: string;
  name: string;
}

export const Profiles: React.FC = () => {
  const [profiles, setProfile] = useState<IProfile[]>([]);
  const erro = false;

  useEffect(() => {
    api.get('/users/profiles').then(response => {
      const orderedProfiles = response.data.sort((a: IProfile, b: IProfile) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setProfile(orderedProfiles);
    });
  }, []);

  const getProfiles = useCallback(() => {
    api.get('/users/profiles').then(response => {
      const orderedProfiles = response.data.sort((a: IProfile, b: IProfile) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setProfile(orderedProfiles);
    });
  }, []);

  const filterProfiles = useCallback(
    (value: string) => {
      const filterProfile = profiles.filter(
        profile =>
          profile.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setProfile(filterProfile);
    },
    [profiles],
  );

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getProfiles() : filterProfiles(value);
    },
    [getProfiles, filterProfiles],
  );

  const deleteProfileSuccess = useCallback(() => {
    toast.success('Perfil deletado com sucesso');
  }, []);

  const deleteProfileError = useCallback(() => {
    Swal.fire(
      'Erro!',
      'Ocorreu um erro ao deletar o perfil, ele pode estar atribuído a alguém.',
      'error',
    );
  }, []);

  const deleteProfile = useCallback(
    async (id: string) => {
      const confirm = await confirmModal('Tem certeza que deseja excluir?');

      if (confirm) {
        try {
          await api.delete(`/users/profiles/${id}`);

          const updatedProfile = profiles.filter(profile => profile.id !== id);

          deleteProfileSuccess();
          setProfile(updatedProfile);
        } catch (err) {
          deleteProfileError();
        }
      }
    },
    [deleteProfileError, deleteProfileSuccess, profiles],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Administração {'>'}</H1Navigation>
        <H1NavigationNow>Perfis</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Perfis</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <ContainerHeader>
          <SearchContainer>
            <InputSearch
              type="text"
              placeholder="Pesquise por permissão"
              onChange={handleSearchInputChange}
            />
            <img src={search_icon} alt="Search icon" />
          </SearchContainer>

          <Link to="/administration/profiles/new">
            <ButtonNewProfile>Novo perfil</ButtonNewProfile>
          </Link>
        </ContainerHeader>

        <Table>
          <Thead>
            <tr>
              <th>
                <IconForm className="icon-head">
                  <img src={user_icon} alt="User icon" />
                </IconForm>
                <ContainerTitle>Nome do perfil</ContainerTitle>
              </th>
            </tr>
          </Thead>
          <Tbody>
            {profiles.length > 0 ? (
              profiles.map(profile => (
                <tr key={profile.id}>
                  <td>
                    <span>{profile.name}</span>
                    <IconForm className="icon-body">
                      <ButtonDelete
                        type="button"
                        onClick={() => deleteProfile(profile.id)}
                      >
                        <DeleteIcon />
                      </ButtonDelete>

                      <Link
                        to={{
                          pathname: '/administration/profiles/edit',
                          state: {
                            id: profile.id,
                            name: profile.name,
                          },
                        }}
                      >
                        <ButtonEdit type="button">
                          <EditIcon />
                        </ButtonEdit>
                      </Link>
                    </IconForm>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  {erro ? (
                    <>Não há resultados disponíveis.</>
                  ) : (
                    <LoaderContainer>
                      <Loader
                        type="Oval"
                        color="#0c0b0b"
                        height={25}
                        width={25}
                      />
                      <TextLoader>Carregando</TextLoader>
                    </LoaderContainer>
                  )}
                </td>
              </tr>
            )}
          </Tbody>
        </Table>
        <ContainerExport>
          <Export type="button" onClick={() => window.print()}>
            <ExportIcon /> Exportar
          </Export>
        </ContainerExport>
      </Body>
    </Container>
  );
};
