import styled from 'styled-components';

interface IPaginationButtonProps {
  active: boolean;
}

export const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  padding: 8px;
  margin-top: 12px;
  margin-bottom: 12px;

  justify-content: flex-end;
`;

export const PageButton = styled.button<IPaginationButtonProps>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  text-align: center;
  letter-spacing: -0.015em;

  border-width: 0;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;

  ${props =>
    props.active
      ? {
          background: '#1665d8',
          color: '#fff',
          borderRadius: '4px',
        }
      : { background: 'transparent', color: '#666360' }}
`;
