import Modal from 'react-modal';
import { useCallback } from 'react';
import { HunterSLADTO } from '../../dtos/HunterSLADTO';

import cancel_icon from '../../../../assets/icons/cancel.svg';

import {
  Container,
  Header,
  Title,
  CloseModalButton,
  Main,
  NameActivity,
  AnswerContainer,
  Label,
  AnswerText,
} from './styles';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

Modal.setAppElement('#root');

interface ModalAnswerSLAProps {
  isOpen: boolean;
  onRequestClose: () => void;
  hunterSLA: HunterSLADTO;
  currentItems: HunterSLADTO[];
  setCurrentItems: (hunter_sla: HunterSLADTO[]) => void;
  setSelectedHunterSLA: (hunter_sla: HunterSLADTO) => void;
}

export function ModalAnswerSLA({
  isOpen,
  onRequestClose,
  hunterSLA,
  currentItems,
  setCurrentItems,
  setSelectedHunterSLA,
}: ModalAnswerSLAProps): JSX.Element {
  const { user } = useAuth();

  const handleUpdateActedBy = useCallback(async () => {
    const isChecked = document.getElementById('acted_by') as HTMLInputElement;

    await api.patch('schedule/hunter-sla', {
      id: hunterSLA.id,
      acted_by: isChecked.checked ? user.id : null,
    });

    const index = currentItems.findIndex(sla => sla.id === hunterSLA.id);
    const updatedCurrentItems = currentItems;

    if (index >= 0) {
      if (isChecked.checked) {
        setSelectedHunterSLA({
          ...hunterSLA,
          acted_by: {
            name: user.name,
          },
        });

        updatedCurrentItems[index].acted_by = {
          name: user.name,
        };
        setCurrentItems(updatedCurrentItems);
      } else {
        setSelectedHunterSLA({
          ...hunterSLA,
          acted_by: null,
        });

        updatedCurrentItems[index].acted_by = null;
        setCurrentItems(updatedCurrentItems);
      }
    }
  }, [
    currentItems,
    hunterSLA,
    setCurrentItems,
    setSelectedHunterSLA,
    user.id,
    user.name,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={Container}>
      <Header>
        <Title>Respostas SLA</Title>

        <CloseModalButton onClick={onRequestClose}>
          <img src={cancel_icon} alt="Fechar modal" />
        </CloseModalButton>
      </Header>

      <Main>
        <NameActivity>{hunterSLA.name}</NameActivity>

        {(hunterSLA.name === 'Trave' || hunterSLA.name === 'Retorno') && (
          <AnswerContainer>
            <Label>ID do ticket:</Label>
            <AnswerText>{hunterSLA.ticket_id}</AnswerText>
          </AnswerContainer>
        )}

        <AnswerContainer>
          <Label>Nome do credor:</Label>
          <AnswerText>{hunterSLA.dealname}</AnswerText>
        </AnswerContainer>

        {hunterSLA.name !== 'Retorno' && (
          <AnswerContainer>
            <Label>Com quem foi o contato:</Label>
            <AnswerText>{hunterSLA.who_contacted}</AnswerText>
          </AnswerContainer>
        )}

        {hunterSLA.name === 'Retorno' ? (
          <AnswerContainer>
            <Label>Contato válido:</Label>
            <AnswerText>{hunterSLA.valid_contact_return}</AnswerText>
          </AnswerContainer>
        ) : (
          <AnswerContainer>
            <Label>
              Contato válido{' '}
              {hunterSLA.name === 'Trave' ? '(terceiro)' : '(credor)'}:
            </Label>
            <AnswerText>{hunterSLA.valid_contact}</AnswerText>
          </AnswerContainer>
        )}

        {hunterSLA.name === 'Bem-sucedido' && (
          <>
            <AnswerContainer>
              <Label>E-mail válido (credor):</Label>
              <AnswerText>{hunterSLA.valid_email}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Motivo do agendamento:</Label>
              <AnswerText>{hunterSLA.reason_for_scheduling}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Termômetro:</Label>
              <AnswerText>{hunterSLA.thermometer}</AnswerText>
            </AnswerContainer>
          </>
        )}

        {hunterSLA.name !== 'Bem-sucedido' && (
          <AnswerContainer>
            <Label>Tipo de conexão:</Label>
            <AnswerText>{hunterSLA.connection_type}</AnswerText>
          </AnswerContainer>
        )}

        <AnswerContainer>
          <Label>Meio de conexão:</Label>
          <AnswerText>{hunterSLA.connection_method}</AnswerText>
        </AnswerContainer>

        {hunterSLA.name === 'Trave' && (
          <>
            <AnswerContainer>
              <Label>Possível contato válido:</Label>
              <AnswerText>{hunterSLA.possible_valid_contact}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Tomador de decisão:</Label>
              <AnswerText>{hunterSLA.decision_maker}</AnswerText>
            </AnswerContainer>
          </>
        )}

        {(hunterSLA.name === 'Bem-sucedido' ||
          hunterSLA.name === 'Perdido') && (
          <>
            <AnswerContainer>
              <Label>Gerou incerteza no recebimento? Reação?</Label>
              <AnswerText>{hunterSLA.reaction}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Sobre planos com o recurso:</Label>
              <AnswerText>{hunterSLA.plans}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Perfil:</Label>
              <AnswerText>{hunterSLA.profile}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Influência:</Label>
              <AnswerText>{hunterSLA.influence}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <Label>Objeção:</Label>
              <AnswerText>{hunterSLA.objection}</AnswerText>
            </AnswerContainer>

            <AnswerContainer>
              <AnswerText>
                <Label>Nota de esclarecimento: </Label>
                {hunterSLA.clarification_note}
              </AnswerText>
            </AnswerContainer>
          </>
        )}

        <AnswerContainer>
          <AnswerText>
            <Label>Observação: </Label>
            {hunterSLA.observation}
          </AnswerText>
        </AnswerContainer>

        <AnswerContainer>
          <input
            type="checkbox"
            id="acted_by"
            defaultChecked={!!hunterSLA.acted_by}
            onChange={handleUpdateActedBy}
          />
          <Label>Atuado</Label>
        </AnswerContainer>

        {hunterSLA.acted_by && (
          <AnswerText>{hunterSLA.acted_by.name}</AnswerText>
        )}
      </Main>
    </Modal>
  );
}
