import { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  HeadCell,
  Tbody,
  BodyCell,
  TableRow,
  TableContainer,
} from './styles';
import arrowUp from '../../../../assets/icons/arrow-up.svg';
import arrowDown from '../../../../assets/icons/arrow-down.svg';
import arrow from '../../../../assets/icons/hunter_arrow.svg';

import { IPerfomance } from '..';
import { useAuth } from '../../../../hooks/auth';

interface IProps {
  performances: IPerfomance[];
}

interface ITotal {
  rawTotal: number;
  predictedTotal: number;
  liquidTotal: number;
  actualForecastNetTotal: number;
  proposalTotal: number;
  proposalLiquidTotal: number;
  dateGainTotal: number;
  batonPassTotal: number;
  liquidRawTotal: number;
}

export function TablePerformance({ performances }: IProps): JSX.Element {
  const [total, setTotal] = useState<ITotal>({} as ITotal);
  const { user } = useAuth();

  useEffect(() => {
    let rawTotal = 0;
    let predictedTotal = 0;
    let liquidTotal = 0;
    let actualForecastNetTotal = 0;
    let proposalTotal = 0;
    let proposalLiquidTotal = 0;
    let dateGainTotal = 0;
    let batonPassTotal = 0;
    let liquidRawTotal = 0;

    for (const performance of performances) {
      rawTotal += performance.raw;
      predictedTotal += performance.predicted;
      liquidTotal += performance.liquid;
      proposalTotal += performance.proposal;
      proposalLiquidTotal += performance.proposalLiquid;
      dateGainTotal += performance.dateGain;
      batonPassTotal += performance.batonPass;
      liquidRawTotal += performance.liquidRaw;
    }

    if (performances.length > 0) {
      actualForecastNetTotal /= performances.length;
      proposalLiquidTotal /= performances.length;
      liquidRawTotal /= performances.length;
    }

    setTotal({
      rawTotal,
      predictedTotal,
      liquidTotal,
      actualForecastNetTotal,
      proposalTotal,
      proposalLiquidTotal,
      dateGainTotal,
      batonPassTotal,
      liquidRawTotal,
    });
  }, [performances]);

  return (
    <TableContainer>
      <Table>
        <Thead>
          <TableRow>
            <HeadCell>Hunter</HeadCell>
            <HeadCell>Bruta</HeadCell>
            {/* <HeadCell>Prevista</HeadCell> */}
            <HeadCell>Líquida</HeadCell>
            <HeadCell>Liq/Bruta</HeadCell>
            <HeadCell>Proposta</HeadCell>
            <HeadCell>Prop/Líq</HeadCell>
            <HeadCell>Bastão</HeadCell>
            {/* <HeadCell>Pago</HeadCell> */}
          </TableRow>
        </Thead>

        <Tbody>
          {performances.map(performance => (
            <TableRow className="tr-body" key={performance.user_id}>
              <BodyCell>
                {performance.email === user.email ||
                user.profile === 'Gestores' ||
                user.profile === 'Gestor Linha 1' ||
                user.profile === 'Gestor Operação Comercial' ||
                user.profile === 'Liderança Comercial' ||
                user.profile === 'Administrador' ||
                user.profile === 'Gestor Operação Comercial + Leads'
                  ? performance.hunter
                  : 'Operador X'}
              </BodyCell>
              <BodyCell>{performance.raw}</BodyCell>
              {/* <BodyCell>{performance.predicted}</BodyCell> */}
              <BodyCell>{performance.liquid}</BodyCell>
              <BodyCell className="arrow">
                {(performance.liquid / performance.raw) * 100 < 30 ? (
                  <img src={arrowDown} alt="Cima" />
                ) : (performance.liquid / performance.raw) * 100 >= 30 &&
                  (performance.liquid / performance.raw) * 100 < 100 ? (
                  <img src={arrow} alt="Baixo" />
                ) : (
                  <img src={arrowUp} alt="Baixo" />
                )}{' '}
                {((performance.liquid / performance.raw) * 100).toFixed(2)}%
              </BodyCell>
              <BodyCell>{performance.proposal}</BodyCell>
              <BodyCell>{performance.proposalLiquid?.toFixed(2)}%</BodyCell>
              <BodyCell>{performance.batonPass}</BodyCell>
              {/* <BodyCell>{performance.dateGain}</BodyCell> */}
            </TableRow>
          ))}
          <TableRow className="tr-body" key="total-01">
            <BodyCell>TOTAL</BodyCell>
            <BodyCell>{total.rawTotal}</BodyCell>
            {/* <BodyCell>{total.predictedTotal}</BodyCell> */}
            <BodyCell>{total.liquidTotal}</BodyCell>
            <BodyCell>{total.liquidRawTotal?.toFixed(2)}%</BodyCell>
            {/* <BodyCell className="arrow">
            <BodyCell>{total.netTotal}</BodyCell>
            <BodyCell className="arrow">
              <span> </span> {total.actualForecastNetTotal?.toFixed(2)}%
            </BodyCell> */}
            <BodyCell>{total.proposalTotal}</BodyCell>
            <BodyCell>{total.proposalLiquidTotal?.toFixed(2)}%</BodyCell>
            <BodyCell>{total.batonPassTotal}</BodyCell>
            {/* <BodyCell>{total.dateGainTotal}</BodyCell> */}
          </TableRow>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
