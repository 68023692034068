import { useState, useCallback, ChangeEvent, useEffect } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

import {
  ModalContent,
  ModalField,
  ModalInput,
  ModalLabel,
  ModalMain,
  Title,
  TransferButton,
  CloseModalButton,
  CloseModalContainer,
  ButtonsConnectionContainer,
} from './styles';

import close_modal from '../../../../assets/icons/close_modal.svg';
import { SuccessfulModal } from '../../Hunter/SuccessfulModal';
import api from '../../../../services/api';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';

Modal.setAppElement('#root');

interface IModalProps {
  is_open: boolean;
  on_request_close: () => void;
  setCreateActivity: (isOpen: boolean) => void;
}

interface IInfosToTransfer {
  name: string;
  value: string;
  link: string;
  pipeline: string;
}

export function ModalAutomaticTransfer({
  is_open,
  on_request_close,
  setCreateActivity,
}: IModalProps): JSX.Element {
  const [hubspotId, setHubspotId] = useState<string>('');
  const [modalHubspotId, setModalHubspotId] = useState<string>('');
  const [infosToTransfer, setInfosToTransfer] = useState<IInfosToTransfer>(
    {} as IInfosToTransfer,
  );
  const [selectedConnection, setSelectedConnection] = useState<string>();
  const [connectionTransfer, setConnectionTransfer] = useState<string>();
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [foundQueue, setFoundQueue] = useState(undefined);

  const connections = [
    'Outbound',
    'Inbound Ativo',
    'Inbound Digital',
    'Retomada',
  ];

  const closeModal = useCallback(() => {
    on_request_close();
    setInfosToTransfer({} as IInfosToTransfer);
    setHubspotId('');
    setSelectedConnection('');
  }, [on_request_close]);

  const automaticTransferError = useCallback((message: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  useEffect(() => {
    if ((hubspotId && hubspotId.length === 9) || hubspotId.length === 10) {
      try {
        api.get(`/call_center/transfer/${hubspotId}`).then(response => {
          const { name, value, link, id, pipeline } = response.data;
          setInfosToTransfer({ name, value, link, pipeline });
        });
      } catch (error) {
        if (error.response.data.status === 400) {
          automaticTransferError(
            'Falta informar o Hubspot Ticket Id para poder realizar a operação!',
          );
        } else if (error.response.data.status === 404) {
          automaticTransferError('Hubspot Ticket id não foi encontrado!');
        } else {
          automaticTransferError('Ocorreu um erro inesperado!');
        }
      }
    }
  }, [automaticTransferError, hubspotId]);

  const handleInputLink = useCallback(() => {
    if (infosToTransfer.link) {
      window.open(infosToTransfer.link, '_blank');
    }
  }, [infosToTransfer]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const hubspotTicketId = event.target.value;
      setHubspotId(hubspotTicketId);
      setModalHubspotId(hubspotTicketId);
    },
    [],
  );

  const handleCloseSuccessfulModal = useCallback(() => {
    setOpenSuccessfulModal(false);
    setCreateActivity(false);
  }, [setCreateActivity]);

  const ensureQueueExistsByHubspotTicketId = useCallback(async () => {
    try {
      if (Object.keys(infosToTransfer).length > 0) {
        await api
          .get(
            `/call_center/queue?hubspot_ticket_id=${hubspotId}&connection_type=${selectedConnection}`,
          )
          .then(response => {
            const { queue, id } = response.data.queue;
            window.open(`tel:${queue}`, '_blank');
            setConnectionTransfer(selectedConnection);
            setFoundQueue(id);
            setOpenSuccessfulModal(true);
            closeModal();
          });
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        automaticTransferError('Hubspot Ticket Id não foi inserido!');
      } else if (
        error.response.data.status === 404 &&
        error.response.data.message === 'Ticket not found!'
      ) {
        automaticTransferError('Hubspot Ticket Id não foi encontrado!');
      } else if (
        error.response.data.status === 404 &&
        error.response.data.message === 'Queue not found!'
      ) {
        automaticTransferError('Queue não foi encontrada!');
      } else {
        automaticTransferError('Ocorreu um erro inesperado!');
      }
    }
  }, [
    infosToTransfer,
    hubspotId,
    selectedConnection,
    closeModal,
    automaticTransferError,
  ]);

  return (
    <>
      <Modal isOpen={is_open} style={ModalContent} onRequestClose={closeModal}>
        <ModalMain>
          <CloseModalContainer>
            <CloseModalButton
              id="closeModal"
              type="button"
              onClick={closeModal}
            >
              <img src={close_modal} alt="Fechar modal" />
            </CloseModalButton>
          </CloseModalContainer>
          <Title>Transferindo ...</Title>
          <ModalField>
            <ModalLabel>ID do Hubspot:</ModalLabel>
            <ModalInput
              type="text"
              placeholder="Insira o ID do Hubspot"
              onChange={handleInputChange}
            />
          </ModalField>

          <ModalField>
            <ModalLabel>Nome:</ModalLabel>
            <ModalInput
              type="text"
              disabled
              defaultValue={infosToTransfer.name}
            />
          </ModalField>

          <ModalField>
            <ModalLabel>Valor:</ModalLabel>
            <ModalInput
              type="text"
              disabled
              defaultValue={infosToTransfer.value}
            />
          </ModalField>

          <ModalField>
            <ModalLabel>Link:</ModalLabel>
            <ModalInput
              className="inputLink"
              type="text"
              onClick={handleInputLink}
              readOnly
              defaultValue={infosToTransfer.link}
            />
          </ModalField>

          <ButtonsConnectionContainer>
            {connections.map(connection => (
              <ScheduleOptionsButton
                key={connection}
                selectedValue={selectedConnection === connection}
                transfer
                type="button"
                onClick={() => setSelectedConnection(connection)}
              >
                {connection}
              </ScheduleOptionsButton>
            ))}
          </ButtonsConnectionContainer>

          <TransferButton
            type="submit"
            onClick={() => ensureQueueExistsByHubspotTicketId()}
          >
            Finalizar transferência
          </TransferButton>
        </ModalMain>
      </Modal>

      {openSuccessfulModal && (
        <SuccessfulModal
          isOpen={openSuccessfulModal}
          onRequestClose={handleCloseSuccessfulModal}
          name_sla="Transferência"
          selectedDay={undefined}
          ticket_id={modalHubspotId}
          contact_preference={undefined}
          selected_time={undefined}
          queue={foundQueue}
          scheduling_hub_activity={undefined}
          closer_name_scheduling={undefined}
          connection={connectionTransfer}
          previousConnectionMethod={connectionTransfer}
          hunter={undefined}
          ticket_pipeline={infosToTransfer.pipeline}
        />
      )}
    </>
  );
}
