export function transformLettersCreditorName(creditor_name: string): string {
  creditor_name = creditor_name.toLowerCase();

  const names = creditor_name.split(' ');
  const finalName: string[] = [];

  for (const name of names) {
    if (
      name === 'di' ||
      name === 'do' ||
      name === 'de' ||
      name === 'dos' ||
      name === 'da' ||
      name === 'das'
    ) {
      finalName.push(name);
    } else if (name[0]) {
      finalName.push(name[0].toUpperCase() + name.substring(1));
    }
  }

  return finalName.toString().replace(/,/g, ' ');
}
