/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { format as formattedDate } from 'date-fns';

import close_modal from '../../../assets/icons/close_modal.svg';
import { IAnswers } from '../TablePanelSla';

import {
  ModalClosers,
  ModalHeader,
  CloseModalButton,
  ModalBody,
  AnswersBackgroud,
  ContactsContainer,
  AnswersContainer,
  QuestionAnswersContainer,
  ContactContainer,
} from './styles';

Modal.setAppElement('#root');

interface IAvailableClosers {
  isOpen: boolean;
  onRequestClose: () => void;
  answers: IAnswers[];
}

export function ModalSLAAnswers({
  isOpen,
  onRequestClose,
  answers,
}: IAvailableClosers) {
  const [openAnswers, setOpenAnswers] = useState('');
  let countContact = 0;

  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalClosers}>
      <ModalHeader>
        <p>Contatos:</p>
        <CloseModalButton id="closeModal" type="button" onClick={closeModal}>
          <img src={close_modal} alt="Fechar modal" />
        </CloseModalButton>
      </ModalHeader>

      <ModalBody>
        {answers &&
          answers.map(answer => {
            return (
              <ContactsContainer key={answer.id}>
                <ContactContainer
                  onClick={() =>
                    setOpenAnswers(answer.id === openAnswers ? '' : answer.id)
                  }
                >
                  Contato {(countContact += 1)} -{' '}
                  {formattedDate(new Date(answer.created_at), 'dd/MM/yyy')}{' '}
                  -&nbsp;
                  <b> Nota: {answer.note}</b>
                </ContactContainer>
                {openAnswers === answer.id && (
                  <AnswersBackgroud>
                    <AnswersContainer>
                      <QuestionAnswersContainer>
                        <b>
                          Após apresentar proposta, houveram objeções
                          relacionadas ao valor apresentado?
                        </b>
                        <p>
                          <b>R:</b>&nbsp;
                          {answer.creditor_objection
                            ? answer.creditor_objection
                            : 'Não'}
                        </p>
                      </QuestionAnswersContainer>
                      <QuestionAnswersContainer>
                        <b>Credor tem planos para o futuro?</b>
                        <p>
                          <b>R:</b>&nbsp;
                          {answer.creditor_plans
                            ? answer.creditor_plans
                            : 'Não'}
                        </p>
                      </QuestionAnswersContainer>
                      <QuestionAnswersContainer>
                        <b>É autoridade?</b>
                        <p>
                          <b>R:</b>&nbsp;{answer.authority ? 'Sim' : 'Não'}
                        </p>
                      </QuestionAnswersContainer>
                      <QuestionAnswersContainer>
                        <b>Tem follow-up agendado?</b>
                        <p>
                          <b>R:</b>&nbsp;{answer.has_follow ? 'Sim' : 'Não'}
                        </p>
                      </QuestionAnswersContainer>
                      <QuestionAnswersContainer>
                        <b>
                          Para fechar o negócio você foi questionado a respeito
                          dos procedimentos?
                        </b>
                        <p>
                          <b>R:</b>&nbsp;
                          {answer.creditor_procedures
                            ? answer.creditor_procedures
                            : 'Não'}
                        </p>
                      </QuestionAnswersContainer>
                    </AnswersContainer>
                  </AnswersBackgroud>
                )}
              </ContactsContainer>
            );
          })}
      </ModalBody>
    </Modal>
  );
}
