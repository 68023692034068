import styled from 'styled-components';
import editPen from '../../../../assets/icons/edit_pen_background.svg';
import editPenHover from '../../../../assets/icons/edit_pen_hover.svg';

interface ITableRowProps {
  isActive: boolean;
}

export const TableRow = styled.tr<ITableRowProps>`
  width: 71.563rem;
  height: 3.125rem;
  margin-top: 0.375rem;
  border-radius: 0.25rem;

  display: grid;
  grid-template-columns: 18.563rem 14.5rem 14.5rem 24rem;
  align-items: center;

  color: ${props => (props.isActive ? '#041524' : '#9d9d9d')};
`;

export const TableRowCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  text-align: center;
`;

export const CampaignResult = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #041524;

  span {
    padding-left: 0.25rem;
  }
`;

interface IUpdateButtonProps {
  isActive: boolean;
}

export const UpdateButton = styled.button<IUpdateButtonProps>`
  width: 1.563rem;
  height: 1.563rem;
  border: 0.063rem solid #05233e;
  border-radius: 0.25rem;
  background-image: url(${editPen});
  background-size: cover;

  display: ${props => (props.isActive ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  transition: 500ms;

  &:hover {
    background-color: #041524;
    background-image: url(${editPenHover});
  }
`;
