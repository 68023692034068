import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Styles/Title';
import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';
import {
  H1NavigationNow,
  Navegation,
  H1Navigation,
} from '../../../components/Styles/Navigation';

import apiCustomerSuccess from '../../../services/apiCustomerSuccess';

import {
  ButtonContainer,
  Celula,
  ContainerTitle,
  CreateButtonContainer,
  Divisor,
  DivSeparator,
  FooterTable,
  H1,
  InputSearch,
  Separator,
  Table,
  Tbody,
  Thead,
} from './styles';

interface IDebitor {
  name: string;
  cnpj: string;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  uf: string;
  active_type: string;
}

const Debitors: React.FC = () => {
  const [debitors, setDebitors] = useState<IDebitor[]>([]);
  const [currentItems, setCurrentItems] = useState<IDebitor[]>(
    [] as IDebitor[],
  );
  const [page, setPage] = useState<number>(1);

  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);

  useEffect(() => {
    apiCustomerSuccess.get<IDebitor[]>(`/debitors`).then(response => {
      setDebitors(response.data);

      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, []);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: IDebitor[] = debitors;

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [page, totalPages, debitors],
  );

  const searchDebitors = useCallback(
    (value: string) => {
      const filterDebitors = debitors.filter(
        user => user.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setCurrentItems(filterDebitors);
    },
    [debitors],
  );

  const getDebitors = useCallback(() => {
    apiCustomerSuccess.get('/debitors').then(response => {
      setDebitors(response.data);

      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, []);

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getDebitors() : searchDebitors(value);
    },
    [getDebitors, searchDebitors],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Vortx {'>'} </H1Navigation>
        <H1NavigationNow>Entes devedores</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Vórtx - Entes devedores</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <H1>Listagem de entes devedores</H1>
        <ButtonContainer>
          <InputSearch
            onChange={handleSearchInputChange}
            placeholder="Pesquise por ente devedor"
          />
          <CreateButtonContainer>
            <Link to="/vortx/debitors/create">
              <button type="button">
                <FiPlus size={20} />
                Novo ente devedor
              </button>
            </Link>
          </CreateButtonContainer>
        </ButtonContainer>
        <Table>
          <Thead>
            <tr>
              <th>
                <Celula>
                  <ContainerTitle>Nome</ContainerTitle>
                  <Divisor />
                </Celula>
              </th>
              <th>
                <Celula>
                  <ContainerTitle>CNPJ</ContainerTitle>
                  <Divisor />
                </Celula>
              </th>
              <th>
                <Celula>
                  <ContainerTitle>Estado</ContainerTitle>
                  <Divisor />
                </Celula>
              </th>
              <th>
                <Celula>
                  <ContainerTitle>Cidade</ContainerTitle>
                  <Divisor />
                </Celula>
              </th>
              <th>
                <Celula>
                  <ContainerTitle>CEP</ContainerTitle>
                </Celula>
              </th>
            </tr>
          </Thead>
          <Tbody>
            {currentItems.length > 0 ? (
              currentItems.map(debitor => (
                <tr>
                  <td>{debitor.name}</td>
                  <td>{debitor.cnpj}</td>
                  <td>{debitor.uf}</td>
                  <td>{debitor.city}</td>
                  <td>{debitor.cep}</td>
                </tr>
              ))
            ) : (
              <strong>Não há resultados para essa data.</strong>
            )}
          </Tbody>
        </Table>
        <FooterTable>
          <PaginationComponent
            totalPages={totalPages}
            page={page}
            handleChangePagination={handleChangePagination}
          />
        </FooterTable>
      </Body>
    </Container>
  );
};

export { Debitors };
