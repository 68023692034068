import { Container, NormalText, StrongText, TextTitle } from './styles';
import { Separator } from '../Separator';

interface TitleProps {
  name: string;
  nameStrong: string;
  isShared: boolean;
  separatorWidth: number;
}

export function TitleSla({
  name,
  nameStrong,
  isShared,
  separatorWidth,
}: TitleProps): JSX.Element {
  return (
    <Container isShared={isShared}>
      <TextTitle>
        <NormalText>{name}</NormalText>
        <StrongText>{nameStrong}</StrongText>
      </TextTitle>
      <Separator separatorWidth={separatorWidth} />
    </Container>
  );
}
