import { useCallback, useState } from 'react';
import { addMinutes } from 'date-fns/esm';
import { format, isBefore } from 'date-fns';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import lunch from '../../../../assets/icons/lunch.svg';
import activity_common from '../../../../assets/icons/activity_common.svg';
import activity_concluded from '../../../../assets/icons/activity_concluded.svg';
import activity_tried from '../../../../assets/icons/activity_tried.svg';
import activity_pending from '../../../../assets/icons/activity_pending.svg';
import { IPreparationPropertiesDTO } from '../../Closer/dtos/IPreparationPropertiesDTO';

import {
  Container,
  ScheduleLineContainer,
  Icon,
  InfosTasksContainer,
  HourTypeCloserContainer,
  StatusTaskStageContainer,
  LineTableContent,
  InfosSchedulingPreparationContainer,
  HourTypeCloserPreparationContainer,
} from './styles';

import { IActivityDTO } from '../../dtos/IActivityDTO';
import { IActivitiesCloserInADayDTO } from '../../dtos/IActivitiesCloserInADayDTO';
import { ICloserRestrictedTimeDTO } from '../../dtos/ICloserRestrictedTimeDTO';
import { PreparationModal } from '../../Closer/ModalSchedulingPreparation';

interface ISchedulesCloserContainer {
  schedulesDate?: Date;
  closerActivityInADay?: IActivitiesCloserInADayDTO;
  formattedTime?: (timestamp: Date) => string;
  handleOpenRestrictedTimeModal?: (
    restrictedTime: ICloserRestrictedTimeDTO,
  ) => void;
  handleOpenActivityModal?: (activity: IActivityDTO) => void;
}

export const ScheduleCloserContainer: React.FC<ISchedulesCloserContainer> = ({
  schedulesDate,
  closerActivityInADay,
  formattedTime,
  handleOpenRestrictedTimeModal,
  handleOpenActivityModal,
}) => {
  const [openPreparationModal, setOpenPreparationModal] = useState(false);
  const [PreparationId, setPreparationId] = useState({});
  const [schedulingPreparationConnection, setSchedulingPreparationConnection] =
    useState('');

  const handleClosePreparationModal = useCallback(() => {
    setOpenPreparationModal(false);
  }, []);

  const carouselSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  const handleSubmit = useCallback(
    (preparation: IPreparationPropertiesDTO) => {
      setOpenPreparationModal(true);
      setPreparationId(preparation);

      if (closerActivityInADay.defined) {
        closerActivityInADay.defined.dayHours.forEach(dayHour => {
          dayHour.activity.scheduling.some(scheduling => {
            if (scheduling.id === preparation.scheduling_id) {
              setSchedulingPreparationConnection(scheduling.connection_method);
              return true;
            }
            return false;
          });
        });
      }
    },
    [closerActivityInADay.defined],
  );

  const handleSelectIcon = useCallback(
    (activity: IActivityDTO) => {
      const icon =
        activity.status === 'Concluída'
          ? activity_concluded
          : verifyIsBeforeNow(
              activity.notes_next_activity_date
                ? activity.notes_next_activity_date
                : activity.timestamp,
            ) &&
            (!activity.try_times || activity.try_times === 0)
          ? activity_pending
          : activity.try_times > 0
          ? activity_tried
          : activity_common;

      return icon;
    },
    [verifyIsBeforeNow],
  );

  const formattedMinutes = useCallback(
    (date: Date, minutes: number) => {
      const newDate = new Date(date);
      const dateWithMinutes = addMinutes(newDate, minutes);
      const formatDate = formattedTime(dateWithMinutes);
      return formatDate;
    },
    [formattedTime],
  );

  const formattedDate = useCallback((date: Date) => {
    return format(date, 'yyyy-MM-dd');
  }, []);

  const handleShowRestrictedTime = useCallback(
    (restrictedTime: ICloserRestrictedTimeDTO) => {
      const formattedSchedulesDate = formattedDate(schedulesDate);
      const verifyRestrictedTime =
        !restrictedTime.deleted &&
        ((restrictedTime.deleted_today &&
          formattedDate(new Date(restrictedTime.deleted_date)) !==
            formattedSchedulesDate) ||
          !restrictedTime.deleted_today) &&
        (restrictedTime.frequency === 'every_day' ||
          formattedSchedulesDate ===
            formattedDate(new Date(restrictedTime.time_init)) ||
          (restrictedTime.frequency === 'weekly' &&
            new Date(restrictedTime.time_init).getDay() ===
              schedulesDate.getDay()));

      return verifyRestrictedTime;
    },
    [schedulesDate, formattedDate],
  );

  return (
    <Container>
      {closerActivityInADay.defined &&
        closerActivityInADay.defined.dayHours.map(dayHour => (
          <ScheduleLineContainer
            key={formattedTime(dayHour.hour)}
            className="scheduleContainer"
            quantityActivitiesInLine={
              dayHour.activity.priority.length +
                dayHour.activity.scheduling.length +
                dayHour.activity.restrictedTime.length +
                dayHour.activity.transfer.length >
              1
            }
          >
            <Icon>
              <p>{formattedTime(dayHour.hour)}</p>
            </Icon>

            <LineTableContent
              quantityActivitiesInLine={
                dayHour.activity.priority.length +
                  dayHour.activity.scheduling.length +
                  dayHour.activity.restrictedTime.length +
                  dayHour.activity.transfer.length >
                1
              }
            >
              <>
                <Slider {...carouselSettings}>
                  {dayHour.activity.restrictedTime.length > 0 &&
                    dayHour.activity.restrictedTime.map(restrictedTime =>
                      handleShowRestrictedTime(restrictedTime) &&
                      restrictedTime.name !== 'Passagem de bastão' ? (
                        <InfosTasksContainer
                          key={restrictedTime.id}
                          id="restrictedTime"
                          className="infos-task"
                          onClick={() =>
                            handleOpenRestrictedTimeModal(restrictedTime)
                          }
                        >
                          <HourTypeCloserContainer>
                            <div className="hour-name-activity">
                              <p className="restrictedTime">
                                {formattedTime(restrictedTime.time_init)} -{' '}
                                {formattedTime(restrictedTime.time_end)}
                              </p>
                              <p className="restrictedTime">
                                {restrictedTime.name}{' '}
                                <img src={lunch} alt={restrictedTime.name} />
                              </p>
                            </div>
                          </HourTypeCloserContainer>
                        </InfosTasksContainer>
                      ) : restrictedTime.name === 'Passagem de bastão' &&
                        !restrictedTime.deleted ? (
                        <InfosTasksContainer
                          key={restrictedTime.id}
                          id="restrictedTime"
                          className="infos-task"
                          isBefore={verifyIsBeforeNow(restrictedTime.time_init)}
                          statusTaskProps={
                            restrictedTime.status
                              ? restrictedTime.status
                              : 'Pendente'
                          }
                          onClick={() =>
                            handleOpenRestrictedTimeModal(restrictedTime)
                          }
                        >
                          <HourTypeCloserContainer
                            isBefore={verifyIsBeforeNow(
                              restrictedTime.time_init,
                            )}
                            statusTaskProps={
                              restrictedTime.status
                                ? restrictedTime.status
                                : 'Pendente'
                            }
                          >
                            <div className="hour-name-activity">
                              <p className="restrictedTime">
                                {formattedTime(restrictedTime.time_init)} -{' '}
                                {formattedTime(restrictedTime.time_end)}
                              </p>
                              <p className="restrictedTime">
                                {restrictedTime.name}{' '}
                                <img
                                  src={handleSelectIcon(restrictedTime)}
                                  alt="Passagem de bastão"
                                />
                              </p>
                            </div>
                            <p className="credor">
                              Credor: {restrictedTime.dealname}
                            </p>
                            <p className="credor">
                              Valor: {restrictedTime.amount}
                            </p>
                          </HourTypeCloserContainer>
                          <StatusTaskStageContainer
                            statusTaskProps={
                              restrictedTime.status
                                ? restrictedTime.status
                                : 'Pendente'
                            }
                          >
                            <p id="status">
                              {restrictedTime.status
                                ? restrictedTime.status
                                : 'Pendente'}
                            </p>
                          </StatusTaskStageContainer>
                        </InfosTasksContainer>
                      ) : (
                        ''
                      ),
                    )}

                  {dayHour.activity.schedulingPreparation.length > 0 &&
                    dayHour.activity.schedulingPreparation.map(preparation =>
                      format(schedulesDate, 'yyyy-MM-dd') ===
                      format(new Date(preparation.timestamp), 'yyyy-MM-dd') ? (
                        <InfosSchedulingPreparationContainer
                          id="schedules"
                          key={preparation.id}
                          className="infos-task"
                          isBefore={verifyIsBeforeNow(preparation.timestamp)}
                          onClick={() => handleSubmit(preparation)}
                        >
                          <HourTypeCloserPreparationContainer
                            id="hour-type-closer"
                            isBefore={verifyIsBeforeNow(preparation.timestamp)}
                          >
                            <div className="hour-name-activity">
                              <p className="schedules">
                                {formattedTime(preparation.timestamp)} -{' '}
                                {formattedMinutes(preparation.timestamp, 15)}
                              </p>
                              <p className="schedules">
                                Preparo de Agendamento{' '}
                                <img
                                  src={handleSelectIcon(preparation)}
                                  alt="Agendamento"
                                />
                              </p>
                            </div>
                          </HourTypeCloserPreparationContainer>
                        </InfosSchedulingPreparationContainer>
                      ) : (
                        ''
                      ),
                    )}

                  {dayHour.activity.scheduling.length > 0 &&
                    dayHour.activity.scheduling.map(schedule =>
                      !schedule.deleted &&
                      format(schedulesDate, 'yyyy-MM-dd') ===
                        format(new Date(schedule.timestamp), 'yyyy-MM-dd') ? (
                        <InfosTasksContainer
                          id="schedules"
                          key={schedule.id}
                          className="infos-task"
                          isBefore={verifyIsBeforeNow(schedule.timestamp)}
                          talkProps={schedule.talk}
                          connection_method={
                            schedule.connection_method
                              ? schedule.connection_method.toLowerCase()
                              : ''
                          }
                          statusTaskProps={
                            schedule.status ? schedule.status : 'Pendente'
                          }
                          statusBusinessTask={
                            schedule.business_situation
                              ? schedule.business_situation
                              : 'Em aberto'
                          }
                          tryTimesProps={String(schedule.try_times)}
                          onClick={() => handleOpenActivityModal(schedule)}
                        >
                          <HourTypeCloserContainer
                            id="hour-type-closer"
                            isBefore={verifyIsBeforeNow(schedule.timestamp)}
                            talkProps={schedule.talk}
                            statusTaskProps={
                              schedule.status ? schedule.status : 'Pendente'
                            }
                            tryTimesProps={String(schedule.try_times)}
                          >
                            <div className="hour-name-activity">
                              <p className="schedules">
                                {formattedTime(schedule.timestamp)} -{' '}
                                {formattedMinutes(schedule.timestamp, 30)}
                              </p>
                              <p className="schedules">
                                Agendamento{' '}
                                <img
                                  src={handleSelectIcon(schedule)}
                                  alt="Agendamento"
                                />
                              </p>
                            </div>
                            <p className="credor">
                              Credor: {schedule.dealname}
                            </p>
                            <p className="credor">Valor: {schedule.amount}</p>
                            {schedule.contact ? (
                              <p className="credor">
                                Contato:{' '}
                                {schedule.contact.replaceAll(';', ', ')}
                              </p>
                            ) : (
                              ''
                            )}
                          </HourTypeCloserContainer>

                          <StatusTaskStageContainer
                            id="status-task-stage"
                            statusTaskProps={
                              schedule.status ? schedule.status : 'Pendente'
                            }
                            existTryTimesProps={!!schedule.try_times}
                            statusBusinessTask={
                              schedule.business_situation
                                ? schedule.business_situation
                                : 'Em aberto'
                            }
                          >
                            <div className="stage-business-situation">
                              <p id="connection_method">
                                {schedule.connection_method}
                              </p>
                              <p id="stage">{schedule.stage_name}</p>
                            </div>
                            <div className="status-try-times">
                              <span id="try_times">
                                {schedule.try_times ? schedule.try_times : 0}
                              </span>
                              <p id="status">
                                {schedule.status ? schedule.status : 'Pendente'}
                              </p>
                              <p id="business-situation">
                                {schedule.business_situation
                                  ? schedule.business_situation
                                  : 'Em aberto'}
                              </p>
                            </div>
                          </StatusTaskStageContainer>
                        </InfosTasksContainer>
                      ) : (
                        ''
                      ),
                    )}

                  {dayHour.activity.transfer.length > 0 &&
                    dayHour.activity.transfer.map(transfer =>
                      format(schedulesDate, 'yyyy-MM-dd') ===
                      format(new Date(transfer.timestamp), 'yyyy-MM-dd') ? (
                        <InfosTasksContainer
                          id="transfer"
                          key={transfer.id}
                          className="infos-task"
                          isBefore={verifyIsBeforeNow(transfer.timestamp)}
                          talkProps={transfer.talk}
                          connection_method={
                            transfer.connection_method
                              ? transfer.connection_method.toLowerCase()
                              : ''
                          }
                          statusTaskProps={
                            transfer.status ? transfer.status : 'Pendente'
                          }
                          statusBusinessTask={
                            transfer.business_situation
                              ? transfer.business_situation
                              : 'Em aberto'
                          }
                          tryTimesProps={String(transfer.try_times)}
                          onClick={() => handleOpenActivityModal(transfer)}
                        >
                          <HourTypeCloserContainer
                            id="hour-type-closer"
                            isBefore={verifyIsBeforeNow(transfer.timestamp)}
                            talkProps={transfer.talk}
                            statusTaskProps={
                              transfer.status ? transfer.status : 'Pendente'
                            }
                            tryTimesProps={String(transfer.try_times)}
                          >
                            <div className="hour-name-activity">
                              <p className="transfer">
                                {formattedTime(transfer.timestamp)} -{' '}
                                {formattedMinutes(transfer.timestamp, 30)}
                              </p>
                              <p className="transfer">
                                Transferência{' '}
                                <img
                                  src={handleSelectIcon(transfer)}
                                  alt="Transferência"
                                />
                              </p>
                            </div>
                            <p className="credor">
                              Credor: {transfer.dealname}
                            </p>
                            <p className="credor">Valor: {transfer.amount}</p>
                            {transfer.contact ? (
                              <p className="credor">
                                Contato:{' '}
                                {transfer.contact.replaceAll(';', ', ')}
                              </p>
                            ) : (
                              ''
                            )}
                          </HourTypeCloserContainer>

                          <StatusTaskStageContainer
                            id="status-task-stage"
                            statusTaskProps={
                              transfer.status ? transfer.status : 'Pendente'
                            }
                            existTryTimesProps={!!transfer.try_times}
                            statusBusinessTask={
                              transfer.business_situation
                                ? transfer.business_situation
                                : 'Em aberto'
                            }
                          >
                            <div className="stage-business-situation">
                              <p id="connection_method">
                                {transfer.connection_method}
                              </p>
                              <p id="stage">{transfer.stage_name}</p>
                            </div>
                            <div className="status-try-times">
                              <span id="try_times">
                                {transfer.try_times ? transfer.try_times : 0}
                              </span>
                              <p id="status">
                                {transfer.status ? transfer.status : 'Pendente'}
                              </p>
                              <p id="business-situation">
                                {transfer.business_situation
                                  ? transfer.business_situation
                                  : 'Em aberto'}
                              </p>
                            </div>
                          </StatusTaskStageContainer>
                        </InfosTasksContainer>
                      ) : (
                        ''
                      ),
                    )}

                  {dayHour.activity.priority.length > 0 &&
                    dayHour.activity.priority.map(hubspotPriority =>
                      hubspotPriority.notes_next_activity_date &&
                      !hubspotPriority.deleted &&
                      format(schedulesDate, 'yyyy-MM-dd') ===
                        format(
                          new Date(hubspotPriority.notes_next_activity_date),
                          'yyyy-MM-dd',
                        ) ? (
                        <InfosTasksContainer
                          id="priority"
                          key={hubspotPriority.id}
                          className="infos-task"
                          isBefore={verifyIsBeforeNow(
                            hubspotPriority.notes_next_activity_date,
                          )}
                          talkProps={hubspotPriority.talk}
                          statusTaskProps={
                            hubspotPriority.status
                              ? hubspotPriority.status
                              : 'Pendente'
                          }
                          statusBusinessTask={
                            hubspotPriority.business_situation
                              ? hubspotPriority.business_situation
                              : 'Em aberto'
                          }
                          tryTimesProps={String(hubspotPriority.try_times)}
                          onClick={() =>
                            handleOpenActivityModal(hubspotPriority)
                          }
                        >
                          <HourTypeCloserContainer
                            id="hour-type-closer"
                            isBefore={verifyIsBeforeNow(
                              hubspotPriority.notes_next_activity_date,
                            )}
                            talkProps={hubspotPriority.talk}
                            statusTaskProps={
                              hubspotPriority.status
                                ? hubspotPriority.status
                                : 'Pendente'
                            }
                            tryTimesProps={String(hubspotPriority.try_times)}
                          >
                            <div className="hour-name-activity">
                              <p className="priority">
                                {formattedTime(
                                  new Date(
                                    hubspotPriority.notes_next_activity_date,
                                  ),
                                )}{' '}
                                -{' '}
                                {formattedMinutes(
                                  new Date(
                                    hubspotPriority.notes_next_activity_date,
                                  ),
                                  hubspotPriority.duration || 15,
                                )}
                              </p>
                              <p className="priority">
                                Priorização{' '}
                                <img
                                  src={handleSelectIcon(hubspotPriority)}
                                  alt="Priorização"
                                />
                              </p>
                            </div>
                            <p className="credor">
                              Credor: {hubspotPriority.dealname}
                            </p>
                            <p className="credor">
                              Valor: {hubspotPriority.amount}
                            </p>
                            {hubspotPriority.contact ? (
                              <p className="credor">
                                Contato:{' '}
                                {hubspotPriority.contact.replaceAll(';', ', ')}
                              </p>
                            ) : (
                              ''
                            )}
                          </HourTypeCloserContainer>

                          <StatusTaskStageContainer
                            id="status-task-stage"
                            statusTaskProps={
                              hubspotPriority.status
                                ? hubspotPriority.status
                                : 'Pendente'
                            }
                            existTryTimesProps={!!hubspotPriority.try_times}
                            statusBusinessTask={
                              hubspotPriority.business_situation
                                ? hubspotPriority.business_situation
                                : 'Em aberto'
                            }
                          >
                            <div className="stage-business-situation">
                              <p id="stage">{hubspotPriority.stage_name}</p>
                            </div>

                            <div className="status-try-times">
                              <span id="try_times">
                                {hubspotPriority.try_times
                                  ? hubspotPriority.try_times
                                  : 0}
                              </span>
                              <p id="status">
                                {hubspotPriority.status
                                  ? hubspotPriority.status
                                  : 'Pendente'}
                              </p>
                              <p id="business-situation">
                                {hubspotPriority.business_situation
                                  ? hubspotPriority.business_situation
                                  : 'Em aberto'}
                              </p>
                            </div>
                          </StatusTaskStageContainer>
                        </InfosTasksContainer>
                      ) : (
                        ''
                      ),
                    )}
                </Slider>
              </>
            </LineTableContent>
          </ScheduleLineContainer>
        ))}

      {openPreparationModal && (
        <PreparationModal
          isOpen={openPreparationModal}
          onRequestClose={handleClosePreparationModal}
          preparation={PreparationId}
          scheduling_connection={schedulingPreparationConnection}
        />
      )}
    </Container>
  );
};
