import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 75vw;

  tbody:nth-child(odd) {
    background: var(--gray-50);
  }

  tbody:nth-child(even) {
    background: var(--gray-100);
  }

  @media (min-width: 1500px) {
    min-width: 80vw;
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 32% 30% 19% 19%;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  width: 100%;
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 550;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-700);

  border-right: 0.01rem solid var(--blue-700);

  &:nth-child(4) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  width: 100%;
  margin-top: 0.3rem;

  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }

  .tr-body {
    border-radius: 0.25rem;
  }
`;

export const BodyCell = styled.td`
  font-weight: 450;
  font-size: 0.8rem;
  text-align: center;
  height: 4.3rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
`;

export const EspecialCell = styled.td`
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;
  height: 9rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-top: 3rem;

  div {
    margin-right: 2rem;
  }
`;

export const AddDescriptionButton = styled.div`
  button {
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #014077;
    border: 0;
    border-radius: 1rem;
    position: absolute;
    transform: translate(100%, -50%);
  }
`;
export const SendButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #014077;
    border: 0;
    border-radius: 0.5rem;
  }
`;

export const InputRes = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 100%;
  padding: 0.25rem;
  transition: border 0.2s;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const ShowInputRes = styled.input`
  border: none;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 0.93rem;
  background: local;
  color: black;
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const Select = styled.select`
  width: 9.25rem;
  height: 1.5rem;
  margin-top: 2rem;
  border: none;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;

  margin-bottom: 2rem;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
  text-align: center;
`;

export const BoxRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
`;

export const BoxHeader = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 1.25rem;
  width: 100%;

  background-color: #d7e4f5;
  border-radius: 0.625rem 0.625rem 0px 0px;
`;

export const BoxHeadCell = styled.th`
  font-family: Poppins;
  font-weight: 450;
  font-size: 1rem;
`;

export const BoxHeadFirstCell = styled.th`
  font-family: Poppins;
  font-weight: 450;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxBodyCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0 1rem;
`;

export const BoxBody = styled.tbody`
  width: 100%;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
`;

export const BoxTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const BoxInput = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 100%;
  height: 70%;
  transition: border 0.2s;
  text-align: center;

  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const BoxInputShowing = styled.input`
  border: none;
  width: 100%;
  height: 70%;
  text-align: center;
  font-weight: 450;
  font-size: 1.1rem;
  color: black;
  background: white;
`;

export const LoaInput = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 30%;
  height: 48%;
  transition: border 0.2s;
  margin-top: 0.6rem;
  text-align: center;

  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const LoaInputShowing = styled.input`
  border: none;
  width: 30%;
  height: 48%;
  margin-top: 0.6rem;
  margin-left: -3px;
  text-align: center;
  font-weight: 450;
  font-size: 1rem;
  color: black;
  background: #d7e4f5;
`;

export const ConcBoxRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 16.6% 16.6% 16.6%;
`;

export const ConcBoxTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
`;

export const ShowAnswer = styled.div`
  font-weight: 450;
  font-size: 1.1rem;
  text-align: center;
`;
