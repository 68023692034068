import React, { useCallback, useState } from 'react';
import { decode, JwtPayload } from 'jsonwebtoken';
import blacklistImg from '../../assets/icons/blacklist.svg';
import { Container, Button, Image } from './styles';
import { BlacklistContactFormModal } from '../BlacklistContactFormModal';
import { useAuth } from '../../hooks/auth';

export const BlacklistContactForm: React.FC = () => {
  const { user, signOut } = useAuth();

  const validateVisibility = !!(
    user &&
    user.permissions.find(permission => permission.name === 'Forms Blacklist')
  );

  const [openBlacklistContactFormModal, setOpenBlacklistContactFormModal] =
    useState(false);

  const handleCloseBlacklistContactFormModal = useCallback(() => {
    setOpenBlacklistContactFormModal(false);
  }, []);

  const handleOpenBlacklistContactFormModal = useCallback(() => {
    const token = localStorage.getItem('@Precato:token');

    const { exp } = decode(token) as JwtPayload;

    if (Date.now() >= Number(exp) * 1000) {
      signOut();
    }

    setOpenBlacklistContactFormModal(true);
  }, [signOut]);

  return (
    <>
      {validateVisibility ? (
        <Container>
          <Button
            onClick={handleOpenBlacklistContactFormModal}
            disabled={openBlacklistContactFormModal}
            isClose={!openBlacklistContactFormModal}
          >
            <Image src={blacklistImg} />
          </Button>

          <BlacklistContactFormModal
            is_open={openBlacklistContactFormModal}
            on_request_close={handleCloseBlacklistContactFormModal}
          />
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};
