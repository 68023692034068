import styled, { css } from 'styled-components';

interface IButtonAccompaniment {
  accompanimentButtonSelected: string;
}

export const AccompanimentHead = styled.div`
  display: flex;

  height: 3.125rem;

  justify-content: space-around;
  align-items: center;

  background: var(--blue-100);

  border-radius: 0.5rem 0.5rem 0 0;
`;

export const TitleBox = styled.span`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const ContainerButtons = styled.div<IButtonAccompaniment>`
  display: flex;

  ${props =>
    props.accompanimentButtonSelected === 'Mensal'
      ? css`
          .monthly-button {
            background: var(--blue-700);
            color: var(--white);
          }
        `
      : css`
          .diary-button {
            background: var(--blue-700);
            color: var(--white);
          }
        `}
`;

export const ButtonAccompaniment = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 2.125rem;
  width: 5.5rem;

  font-weight: 600;
  font-size: 0.9375rem;

  margin-left: 1rem;

  border-radius: 0.5rem;
  border: solid 3px var(--blue-700);

  color: var(--blue-800);

  background: var(--blue-50);

  text-align: center;
`;
