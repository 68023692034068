import axios, { AxiosInstance } from 'axios';

export default function apiTrfsUrl(court: string): AxiosInstance {
  let url = '';

  if (process.env.REACT_APP_API_URL === 'prod') {
    url =
      court === 'trf1-pje'
        ? 'https://trf1-service.precato.com.br'
        : court === 'trf3-cpf' ||
          court === 'trf3-protocol' ||
          court === 'trf3-pje' ||
          court === 'trf3-pdf'
        ? 'https://trf3-service.precato.com.br'
        : court === 'trf4-movement' || court === 'trf4-withdraw'
        ? 'https://trf4-service.precato.com.br'
        : court === 'trf2-movement' || court === 'trf2-withdraw'
        ? 'https://trf2-service.precato.com.br'
        : court === 'trf1-procedural'
        ? 'https://api-trfs-service.precato.com.br'
        : 'https://api-federal.precato.com.br';
  } else {
    url =
      court === 'trf1-pje'
        ? 'http://localhost:7006'
        : court === 'trf3-cpf' ||
          court === 'trf3-protocol' ||
          court === 'trf3-pje' ||
          court === 'trf3-pdf'
        ? 'http://localhost:7004'
        : court === 'trf4-movement' || court === 'trf4-withdraw'
        ? 'http://localhost:7001'
        : court === 'trf2-movement' || court === 'trf2-withdraw'
        ? 'http://localhost:7002'
        : 'http://localhost:4000';
  }

  const apiUrl = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    },
  });

  return apiUrl;
}
