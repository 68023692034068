import CurrencyInput from 'react-currency-input-field';
import { Input, InputContainer, Label } from '../../styles';
import { pxToRem } from '../../../../../../utils/pxToRem';

interface IInputSLAProps {
  type: 'text' | 'text';
  title: string;
  name: string;
  value: string | number;
  handleChange: (e: any) => void;
  isCurrency: boolean;
  placeholder: string;
}

export function InputSLA({
  type,
  title,
  name,
  value,
  handleChange,
  isCurrency,
  placeholder,
}: IInputSLAProps): JSX.Element {
  return (
    <InputContainer>
      {isCurrency ? (
        <>
          <label>{title}</label>
          <CurrencyInput
            name={name}
            value={value}
            allowDecimals
            onValueChange={handleChange}
            placeholder={placeholder}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            style={{
              width: '100%',
              height: pxToRem(33),
              border: '1px solid var(--blue-700)',
              borderRadius: '0.5rem',
              paddingLeft: '0.25rem',
              fontWeight: 400,
              fontSize: pxToRem(16),
              color: 'var(--blue-700)',
            }}
          />
        </>
      ) : (
        <>
          <Label>{title}</Label>
          <Input
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
          />
        </>
      )}
    </InputContainer>
  );
}
