import styled, { css } from 'styled-components';

interface Props {
  isEnabled: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const VerticalDivisor = styled.div`
  height: 1.5rem;

  border-right: 1px solid #041524;
`;

export const StateDataContainer = styled.div`
  width: 100%
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5.5rem;
  @media (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const TitleStateData = styled.h1`
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.015em;

  color: #05233e;

  @media (max-width: 1350px) {
    justify-content: center;
    width: 100%;
  }
`;

export const TextLabel = styled.h2`
  margin-top: 3rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;

  color: #05233e;

  @media (max-width: 1350px) {
    justify-content: center;
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Thead = styled.thead`
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
`;

export const TableRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background: var(--blue-100);
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;
`;

export const CellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 33%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: var(--blue-700);
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25rem;
  text-align: center;
`;

export const TableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;

  :nth-child(odd) {
    background: #efefef;
  }

  :nth-child(even) {
    background: #e5e5e5;
  }
`;

export const CellBody = styled.td`
  word-break: break-all;
  width: 33%;
  background: transparent;
  padding: 0.5rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;
`;

export const Separator = styled.hr`
  width: 14rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.938rem;
  background: #f4f4f5;
  border: 0.031rem solid #05233e;
  border-radius: 0.25rem;
  padding: 0.375rem;
  height: 4rem;
  background: var(--blue-100);

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const FormFilterInput = styled.input`
  width: 12vw;
  height: 2.5rem;

  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #ffffff;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const FormFilterSelect = styled.select`
  width: 12vw;
  height: 2.5rem;

  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #ffffff;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
`;

export const SubmitButton = styled.button<Props>`
  text-align: center;
  height: 2.5rem;
  width: 6rem;
  border: none;
  border-radius: 0.25rem;
  color: #fff;

  ${props =>
    props.isEnabled
      ? css`
          background: #063966;
        `
      : css`
          background: #c4c4c4;
          cursor: no-drop;
        `}
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  width: 45%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: -0.015em;

    color: #1665d8;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
