import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import toast from 'react-hot-toast';

import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';
import { Title } from '../../components/Title';

import api from '../../services/api';

import {
  FormContainer,
  BorderFormGroup,
  ButtonContainer,
  ButtonSubmit,
  Icon,
} from './styles';

import { CloserDTO } from './dtos/CloserDTO';
import { FormCloserDTO } from './dtos/FormCloserDTO';
import { CloserWorkHoursDTO } from './dtos/CloserWorkHoursDTO';
import { FormWeekdayWorkHourDTO } from './dtos/FormWeekdayWorkHourDTO';
import { WeekdayWorkingHourDTO } from './dtos/WeekdayWorkingHourDTO';
import { CloserFormGroup } from './CloserFormGroup';

export function CloserSettings(): JSX.Element {
  const [formCloserData, setFormCloserData] = useState<FormCloserDTO>(
    {} as FormCloserDTO,
  );
  const [weekdayFormData, setWeekdayFormData] =
    useState<FormWeekdayWorkHourDTO>({} as FormWeekdayWorkHourDTO);

  const [closers, setClosers] = useState<CloserDTO[]>([] as CloserDTO[]);
  const [closerSelected, setCloserSelected] = useState<CloserDTO>(
    {} as CloserDTO,
  );
  const [closerWorkHours, setCloserWorkHours] = useState<CloserWorkHoursDTO>(
    {} as CloserWorkHoursDTO,
  );

  useEffect(() => {
    api.get('/schedule/list-all-closers').then(response => {
      setClosers(response.data);
    });
  }, []);

  useEffect(() => {
    if (formCloserData.closer_id) {
      api
        .get(`/schedule/closer-work-hour/${formCloserData.closer_id}`)
        .then(response => {
          const { data } = response;

          setCloserWorkHours({
            time_init_01: data[0]
              ? data[0].time_init.replace('-03:00', '')
              : '09:00',
            time_end_01: data[0]
              ? data[0].time_end.replace('-03:00', '')
              : '18:00',
            time_init_02: data[1]
              ? data[1].time_init.replace('-03:00', '')
              : '09:00',
            time_end_02: data[1]
              ? data[1].time_end.replace('-03:00', '')
              : '18:00',
            time_init_03: data[2]
              ? data[2].time_init.replace('-03:00', '')
              : '09:00',
            time_end_03: data[2]
              ? data[2].time_end.replace('-03:00', '')
              : '18:00',
            time_init_04: data[3]
              ? data[3].time_init.replace('-03:00', '')
              : '09:00',
            time_end_04: data[3]
              ? data[3].time_end.replace('-03:00', '')
              : '18:00',
            time_init_05: data[4]
              ? data[4].time_init.replace('-03:00', '')
              : '09:00',
            time_end_05: data[4]
              ? data[4].time_end.replace('-03:00', '')
              : '18:00',
          });
        });
    }
  }, [formCloserData]);

  useEffect(() => {
    if (formCloserData.closer_id) {
      const findCloser = closers.find(
        closer => closer.id === formCloserData.closer_id,
      );

      setCloserSelected(findCloser);
    }
  }, [closers, formCloserData]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name.includes('time_init') || name.includes('time_end')) {
        setWeekdayFormData({ ...weekdayFormData, [name]: value });
      } else {
        setFormCloserData({ ...formCloserData, [name]: value });
      }
    },
    [weekdayFormData, formCloserData],
  );

  const handleEditCloserSuccess = useCallback(() => {
    toast.success('Alterações realizadas com sucesso.');
  }, []);

  const handleEditCloserError = useCallback(() => {
    toast.error('Não foi possível salvar as alterações.');
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const weekdayWorkingHourArray: WeekdayWorkingHourDTO[] = [
        {
          closer_id: closerSelected.id,
          work_day: 1,
          time_init:
            weekdayFormData.time_init_01 || closerWorkHours.time_init_01,
          time_end: weekdayFormData.time_end_01 || closerWorkHours.time_end_01,
        },
        {
          closer_id: closerSelected.id,
          work_day: 2,
          time_init:
            weekdayFormData.time_init_02 || closerWorkHours.time_init_02,
          time_end: weekdayFormData.time_end_02 || closerWorkHours.time_end_02,
        },
        {
          closer_id: closerSelected.id,
          work_day: 3,
          time_init:
            weekdayFormData.time_init_03 || closerWorkHours.time_init_03,
          time_end: weekdayFormData.time_end_03 || closerWorkHours.time_end_03,
        },
        {
          closer_id: closerSelected.id,
          work_day: 4,
          time_init:
            weekdayFormData.time_init_04 || closerWorkHours.time_init_04,
          time_end: weekdayFormData.time_end_04 || closerWorkHours.time_end_04,
        },
        {
          closer_id: closerSelected.id,
          work_day: 5,
          time_init:
            weekdayFormData.time_init_05 || closerWorkHours.time_init_05,
          time_end: weekdayFormData.time_end_05 || closerWorkHours.time_end_05,
        },
      ];

      if (closerSelected.user_id) {
        await api.put(`/users/closer/${closerSelected.id}`, {
          profile_id: closerSelected.profile_id,
          inactive:
            formCloserData.inactive === 'Sim'
              ? true
              : formCloserData.inactive === 'Não'
              ? false
              : closerSelected.inactive,
          ramal: formCloserData.ramal,
          closer_team: formCloserData.team,
          goal: formCloserData.goal,
          work_hour: weekdayWorkingHourArray,
          max_schedulings_day: formCloserData.max_schedulings_day,
        });
      }
      handleEditCloserSuccess();
      setFormCloserData({} as FormCloserDTO);
      setCloserSelected({} as CloserDTO);
      setCloserWorkHours({} as CloserWorkHoursDTO);
    } catch (error) {
      handleEditCloserError();
    }
  }, [
    formCloserData,
    weekdayFormData,
    handleEditCloserSuccess,
    closerSelected,
    closerWorkHours,
    handleEditCloserError,
  ]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1NavigationNow>Configurar Closer</H1NavigationNow>
      </Navegation>

      <Body>
        <Title name="Configurar Closer" />
        <FormContainer>
          <BorderFormGroup />

          <CloserFormGroup
            closers={closers}
            formCloserData={formCloserData}
            setFormCloserData={setFormCloserData}
            closerSelected={closerSelected}
            handleInputChange={handleInputChange}
            closerWorkHours={closerWorkHours}
          />
        </FormContainer>

        <ButtonContainer>
          <ButtonSubmit type="button" onClick={handleSubmit}>
            <Icon>
              <AiOutlineCheck />
            </Icon>
            Salvar alterações
          </ButtonSubmit>
        </ButtonContainer>
      </Body>
    </Container>
  );
}
