import React from 'react';
import { formatTelephoneNumber } from '../../utils/formatTelephoneNumber';

import { ContactItem, ContactItemContainer } from './styles';

export interface ValidContactsProps {
  origin: string;
  phone: string;
  phone_id: string;
  phoneCallAttemptsToday: number;
  setSelectedPhone: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPhoneId: React.Dispatch<React.SetStateAction<string>>;
  setIsContactSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setPhoneInputPlaceholder: React.Dispatch<React.SetStateAction<string>>;
  setContactInputPlaceholder: React.Dispatch<React.SetStateAction<string>>;
  setIsAddValidContactItemSelected: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setCallButtonText: React.Dispatch<React.SetStateAction<string>>;
}

export function ValidContactItem({
  origin,
  phone,
  phone_id,
  phoneCallAttemptsToday,
  setSelectedPhone,
  setSelectedPhoneId,
  setIsContactSelected,
  setContactInputPlaceholder,
  setPhoneInputPlaceholder,
  setIsAddValidContactItemSelected,
  setCallButtonText,
}: ValidContactsProps): JSX.Element {
  return (
    <ContactItemContainer>
      <input
        type="radio"
        name="contact"
        value={phone}
        onClick={() => {
          setSelectedPhone(phone);
          setSelectedPhoneId(phone_id);
          setIsContactSelected(true);
          setContactInputPlaceholder('');
          setPhoneInputPlaceholder('');
          setIsAddValidContactItemSelected(false);
          setCallButtonText('LIGAR');
        }}
      />
      <ContactItem>
        <span>{origin}</span>
        <span>{formatTelephoneNumber(phone)}</span>
        <span className="end-span">{phoneCallAttemptsToday}</span>
      </ContactItem>
    </ContactItemContainer>
  );
}
