import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import {
  AddContact,
  AddContactContainer,
  ButtonCall,
  ButtonContainer,
  ContactsContainer,
  ContactsTitleContainer,
  ModalBody,
  ModalContactsStyle,
  ModalHeader,
  ModalMain,
  PrefixContainer,
  PrefixOption,
} from './styles';
import cancel_icon from '../../../../assets/icons/cancel.svg';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import {
  formatTelephoneNumber,
  unFormatTelephoneNumber,
} from '../../../../utils/formatTelephoneNumber';
import { ValidContactItem } from '../../../../components/ValidContactItem';

interface IModalContactsProps {
  isOpen: boolean;
  hubspot_deal_id: string;
  dealname: string;
  onRequestClose: () => void;
  task_id: string;
  task_type: string;
}

interface IValidContact {
  id: string;
  created_at: string;
  updated_at: string;
  phone: string;
  hubspot_contact_id: string;
  cpf: string;
  active: boolean;
  valid: boolean;
  origin: string;
  phoneCallAttemptsToday: number;
}

export function ModalContacts({
  isOpen,
  onRequestClose,
  hubspot_deal_id,
  dealname,
  task_id,
  task_type,
}: IModalContactsProps): JSX.Element {
  const [validContacts, setValidContacts] = useState<IValidContact[]>(
    [] as IValidContact[],
  );

  const [selectedPhone, setSelectedPhone] = useState<string>('');
  const [prefix, setPrefix] = useState<string>('');
  const [selectedPhoneId, setSelectedPhoneId] = useState<string>('');
  const [selectedOrigin, setSelectedOrigin] = useState<string>('');

  const [isContactSelected, setIsContactSelected] = useState<boolean>(false);

  const [isAddValidContactItemSelected, setIsAddValidContactItemSelected] =
    useState<boolean>(false);

  const [phoneInputPlaceholder, setPhoneInputPlaceholder] =
    useState<string>('');

  const [phoneInputFormattedValue, setPhoneInputFormattedValue] =
    useState<string>('');

  const [contactInputPlaceholder, setContactInputPlaceholder] =
    useState<string>('');

  const [callButtonText, setCallButtonText] = useState<string>('LIGAR');

  const handlePhoneInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSelectedPhone(unFormatTelephoneNumber(value));
      setPhoneInputFormattedValue(formatTelephoneNumber(value));
    },
    [],
  );

  const handleOriginInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSelectedOrigin(value);
    },
    [],
  );

  const { user } = useAuth();

  const listAllValidContactsOfDeal = useCallback(async () => {
    return api
      .get(
        `/schedule/list-all-valid-contacts-of-deal?hubspot_deal_id=${hubspot_deal_id}&user_id=${user.id}`,
      )
      .then(response => {
        setValidContacts(response.data);
      });
  }, [hubspot_deal_id, user]);

  useEffect(() => {
    if (isOpen) {
      listAllValidContactsOfDeal();
      setContactInputPlaceholder('');
      setPhoneInputPlaceholder('');
      setPhoneInputFormattedValue('');
    }
  }, [isOpen, listAllValidContactsOfDeal, prefix]);

  const closeModal = useCallback(() => {
    onRequestClose();
    setIsContactSelected(false);
  }, [onRequestClose]);

  const addNewValidContact = async ({ origin, phone }: IAddNewValidContact) => {
    return api.post('/schedule/create-valid-contact', {
      origin,
      hubspot_deal_id,
      phone,
    });
  };

  const createPhoneCallAttempt = async ({
    phone_id,
    user_id,
  }: ICreatePhoneCallAttempt) => {
    if (task_type === 'Scheduling') {
      await api.post('/schedule/create-phone-call-attempt', {
        phone_id,
        user_id,
        scheduling_id: task_id,
      });
    }
    if (task_type === 'Priority') {
      await api.post('/schedule/create-phone-call-attempt', {
        phone_id,
        user_id,
        hubspot_closer_task_id: task_id,
      });
    }
  };
  interface IAddNewValidContact {
    origin: string;
    phone: string;
  }
  interface ICreatePhoneCallAttempt {
    phone_id: string;
    user_id: string;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={ModalContactsStyle}
    >
      <ModalMain>
        <ModalHeader>
          <p>
            Contatos válidos - <span id="dealname-span">{dealname}</span>
          </p>
          <button id="close-modal" type="button" onClick={closeModal}>
            <img src={cancel_icon} alt="Fechar modal" />
          </button>
        </ModalHeader>

        <ModalBody>
          <span id="select-contact-span">
            Selecione o prefixo e depois selecione o contato para ligação:
          </span>
          <ContactsContainer>
            <PrefixContainer>
              <PrefixOption>
                <input
                  type="radio"
                  name="prefix"
                  value="00"
                  onClick={() => setPrefix('00')}
                />
                <span>00</span>
              </PrefixOption>
              <PrefixOption>
                <input
                  type="radio"
                  name="prefix"
                  value="0098"
                  onClick={() => setPrefix('0098')}
                />
                <span>0098</span>
              </PrefixOption>
              <PrefixOption>
                <input
                  type="radio"
                  name="prefix"
                  value="0015"
                  onClick={() => setPrefix('0015')}
                />
                <span>0015</span>
              </PrefixOption>
            </PrefixContainer>
            <ContactsTitleContainer>
              <span>Dono do Contato</span>
              <span id="middle-title-span">Contato</span>
              <span id="end-title-span">Tentativas</span>
            </ContactsTitleContainer>
            {validContacts.map(validContact => {
              return (
                <ValidContactItem
                  key={validContact.id}
                  origin={validContact.origin}
                  phone={validContact.phone}
                  phone_id={validContact.id}
                  phoneCallAttemptsToday={validContact.phoneCallAttemptsToday}
                  setSelectedPhone={setSelectedPhone}
                  setSelectedPhoneId={setSelectedPhoneId}
                  setIsContactSelected={setIsContactSelected}
                  setContactInputPlaceholder={setContactInputPlaceholder}
                  setPhoneInputPlaceholder={setPhoneInputPlaceholder}
                  setIsAddValidContactItemSelected={
                    setIsAddValidContactItemSelected
                  }
                  setCallButtonText={setCallButtonText}
                />
              );
            })}
            <AddContactContainer>
              <input
                type="radio"
                name="contact"
                onClick={() => {
                  setContactInputPlaceholder('Digite o dono do contato');
                  setPhoneInputPlaceholder('Digite o contato');
                  setIsContactSelected(true);
                  setIsAddValidContactItemSelected(true);
                  setCallButtonText('ADICIONAR');
                }}
              />
              <AddContact>
                <span>
                  <input
                    type="text"
                    name="origin"
                    id="origin"
                    placeholder={contactInputPlaceholder}
                    onChange={handleOriginInputChange}
                  />
                </span>
                <span>
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    value={phoneInputFormattedValue}
                    placeholder={phoneInputPlaceholder}
                    onChange={handlePhoneInputChange}
                  />
                </span>
                <span id="end-span">0</span>
              </AddContact>
            </AddContactContainer>
          </ContactsContainer>
        </ModalBody>

        <ButtonContainer>
          <ButtonCall
            isContactSelected={isContactSelected}
            onClick={async () => {
              if (isContactSelected) {
                if (isAddValidContactItemSelected) {
                  try {
                    await addNewValidContact({
                      origin: selectedOrigin,
                      phone: selectedPhone,
                    });
                  } catch (error) {
                    if (
                      error.message === 'Request failed with status code 400'
                    ) {
                      Swal.fire(
                        'Atenção',
                        'Este número de telefone já foi adicionado',
                        'warning',
                      );
                    }
                  }

                  await listAllValidContactsOfDeal();
                } else {
                  window.location.href = `tel:${prefix}${selectedPhone}`;

                  await createPhoneCallAttempt({
                    phone_id: selectedPhoneId,
                    user_id: user.id,
                  });

                  await listAllValidContactsOfDeal();
                }
              }
            }}
          >
            {callButtonText}
          </ButtonCall>
        </ButtonContainer>
      </ModalMain>
    </Modal>
  );
}
