import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';
import callOptInIcon from '../../../assets/call-opt-in.svg';
import {
  Select,
  Option,
  ContainerCall,
  ContainerButton,
  Button,
  Icon,
  TextButton,
  ContainerTextInfo,
  TextInfo,
} from './styles';
import { OptInModal } from '../OptInModal';
import apiChatbot from '../../../services/apiChatbot';

export const OptIn: React.FC = () => {
  const [countOptIn, setCountOptIn] = useState(0);
  const [costCenterSelected, setCostCenterSelected] = useState('FEDERAL');
  const [openOptInModal, setOpenOptInModal] = useState(false);

  const costCenters = [
    { id: '1', label: 'Federal', value: 'FEDERAL' },
    { id: '2', label: 'Estadual', value: 'ESTADUAL' },
  ];

  const getCountOptIn = useCallback(async () => {
    const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

    await chatbot
      .get(`/sms/classified/${costCenterSelected}`)
      .then(response => {
        setCountOptIn(response.data.count);
      });
  }, [costCenterSelected]);

  useQuery('infos', getCountOptIn, {
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    getCountOptIn();
  }, [getCountOptIn]);

  const handleCloseOptInModal = useCallback(async () => {
    setOpenOptInModal(false);
    getCountOptIn();
  }, [getCountOptIn]);

  const handlOpenOptInModal = useCallback(() => {
    setOpenOptInModal(true);
  }, []);

  return (
    <Container>
      <NavigationBar past_screens="Conexão > " active_screen="Opt in" />

      <Body>
        <Title name="Opt in" />

        <ContainerCall>
          <Select
            name="cost_center"
            id="cost_center"
            value={costCenterSelected}
            onChange={e => setCostCenterSelected(e.target.value)}
          >
            <Option disabled />
            {costCenters.map(costCenter => (
              <Option key={costCenter.id} value={costCenter.value}>
                {costCenter.label}
              </Option>
            ))}
          </Select>

          <ContainerTextInfo>
            <TextInfo>{countOptIn} credores aguardando ligação.</TextInfo>
          </ContainerTextInfo>
          <ContainerButton>
            <Button type="button" onClick={handlOpenOptInModal}>
              <Icon src={callOptInIcon} alt="Ligar" />
              <TextButton>Ligar</TextButton>
            </Button>
          </ContainerButton>
        </ContainerCall>
        <OptInModal
          is_open={openOptInModal}
          cost_center={costCenterSelected}
          on_request_close={handleCloseOptInModal}
        />
      </Body>
    </Container>
  );
};
