import { Toaster } from 'react-hot-toast';
import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useSubscription } from '@apollo/client';
import { Container } from '../../components/Container';
import {
  Table,
  TableRow,
  Thead,
  HeadCell,
  Tbody,
  BodyCell,
  DivSeparator,
  Separator,
  Select,
  Option,
} from './styles';
import api from '../../services/api';
import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';
import { HubspotLink } from '../PanelSla/TablePanelSla/styles';

export interface ISchedulingResponse {
  id: string;
  dealname?: string;
  hubspot_deal_id: string;
  hunter_name?: string;
  timestamp: Date;
  contact_preference: string;
  closer_name?: string;
  calculation_status: string;
}

const ScheduleListing: React.FC = () => {
  const [schedulings, setSchedulings] = useState<ISchedulingResponse[]>([]);
  const [schedulingUpdate, setSchedulingUpdate] = useState<ISchedulingResponse>(
    {} as ISchedulingResponse,
  );

  const calculationStatus = [
    { id: '1', value: 'Calculo Pronto' },
    { id: '2', value: 'Cedido' },
    { id: '3', value: 'Fora do target' },
    { id: '4', value: 'Inviável (Outros)' },
  ];

  const UPDATE_SCHEDULING = gql`
    mutation UpdateScheduling($scheduling: String!) {
      updateScheduling(scheduling: $scheduling) {
        id
        hubspot_deal_id
        dealname
        amount
        stage_name
        observations
        closer_id
        hunter_id
        hunter_name
        try_times
        talk
        contact
        contact_preference
        contato_valido
        status
        loa
        connection_method
        deleted_by
        current_lead_owner
        ticket_id
        timestamp
        deleted
        business_situation
        queue
        type
        hub_activity_id
        calculation_status
        calculation_timestamp
        created_at
        updated_at
      }
    }
  `;

  const REFRESH = gql`
    subscription Subscription {
      refreshPanel
    }
  `;

  const refresh = useSubscription(REFRESH);

  useEffect(() => {
    if (refresh.data) {
      api.get('/schedule/list-all-state-schedulings-today').then(response => {
        setSchedulings(response.data);
      });
    }
  }, [refresh]);

  const [updateScheduling] = useMutation(UPDATE_SCHEDULING, {
    variables: {
      scheduling: JSON.stringify(schedulingUpdate),
    },
  });

  useEffect(() => {
    api.get('/schedule/list-all-state-schedulings-today').then(response => {
      setSchedulings(response.data);
    });
  }, []);

  useEffect(() => {
    updateScheduling();
  }, [schedulingUpdate, updateScheduling]);

  const formattedTime = useCallback((date: Date) => {
    const hour = `${String(new Date(date).getHours()).padStart(2, '0')}`;
    const minutes = `${String(new Date(date).getMinutes()).padStart(2, '0')}`;

    return `${hour}:${minutes}`;
  }, []);

  const handleUpdateScheduling = useCallback(
    async (id: string, value: any) => {
      const currenteScheduling = schedulings.find(
        scheduling => scheduling.id === id,
      );

      const update = { ...currenteScheduling, calculation_status: value };

      setSchedulingUpdate(update);

      const scheduleIndex = schedulings.findIndex(
        scheduling => scheduling.id === id,
      );

      schedulings.splice(scheduleIndex, 1, {
        ...currenteScheduling,
        calculation_status: value,
      });
    },
    [schedulings],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1NavigationNow>Cálculos</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Cálculos</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <Table>
          <Thead>
            <TableRow>
              <HeadCell>Hunter</HeadCell>
              <HeadCell>Link Hubspot</HeadCell>
              <HeadCell>Closer</HeadCell>
              <HeadCell>Credor</HeadCell>
              <HeadCell>Hora</HeadCell>
              <HeadCell>Meio</HeadCell>
              <HeadCell>Status</HeadCell>
            </TableRow>
          </Thead>
          <Tbody>
            {schedulings.length > 0 &&
              schedulings.map(scheduling => {
                return (
                  <TableRow className="tr-body" key={scheduling.id}>
                    <BodyCell isSelected={scheduling.calculation_status}>
                      {scheduling.hunter_name}
                    </BodyCell>
                    <BodyCell isSelected={scheduling.calculation_status}>
                      <HubspotLink
                        href={`https://app.hubspot.com/contacts/7857327/deal/${scheduling.hubspot_deal_id}`}
                        target="_blank"
                      >
                        https://app.hubspot.com...
                      </HubspotLink>
                    </BodyCell>

                    <BodyCell isSelected={scheduling.calculation_status}>
                      {scheduling.closer_name}
                    </BodyCell>
                    <BodyCell isSelected={scheduling.calculation_status}>
                      {scheduling.dealname}
                    </BodyCell>
                    <BodyCell isSelected={scheduling.calculation_status}>
                      {formattedTime(scheduling.timestamp)}
                    </BodyCell>
                    <BodyCell isSelected={scheduling.calculation_status}>
                      {scheduling.contact_preference}
                    </BodyCell>
                    <BodyCell isSelected={scheduling.calculation_status}>
                      <Select
                        name="cost_center"
                        id="cost_center"
                        value={scheduling.calculation_status}
                        onChange={e =>
                          handleUpdateScheduling(scheduling.id, e.target.value)
                        }
                      >
                        <Option disabled />
                        {calculationStatus.map(status => (
                          <Option key={status.id} value={status.value}>
                            {status.value}
                          </Option>
                        ))}
                      </Select>
                    </BodyCell>
                  </TableRow>
                );
              })}
          </Tbody>
        </Table>
      </Body>
    </Container>
  );
};

export default ScheduleListing;
