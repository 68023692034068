import { useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import { CloserQueues, ICloserQueue } from './CloserQueues';
import { CreateQueues } from './CreateQueue';

import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../components/Styles/Navigation';
import { TitleContainer, InputLabelContainer, Label } from './styles';
import api from '../../services/api';

export function Queues(): JSX.Element {
  const [selectHistory, setSelectHistory] = useState<string>('Closers');
  const [selectLine, setSelectLine] = useState<string>('LINHA 1');

  const [closersQueues, setClosersQueues] = useState<ICloserQueue[]>([]);

  useEffect(() => {
    api.get('/schedule/closer-queues').then(response => {
      setClosersQueues(response.data);
    });
  }, [selectLine]);

  const lines = ['LINHA 1', 'LINHA 2', 'LINHA 4', 'LINHA 6'];

  return (
    <Container>
      <Navegation>
        <H1Navigation>Administração {'>'}</H1Navigation>
        <H1NavigationNow>Queues</H1NavigationNow>
      </Navegation>
      <Body>
        <InputLabelContainer>
          <Label>Selecione a linha desejada:</Label>
          <select
            className="select"
            name="line"
            id="closer_id"
            value={selectLine || 'Linhar'}
            onChange={event => setSelectLine(event.target.value)}
          >
            <option value="Selecione o closer" disabled>
              Linha
            </option>
            {lines &&
              lines.map(line => (
                <option key={line} id={line} value={line}>
                  {line}
                </option>
              ))}
          </select>
        </InputLabelContainer>
        <TitleContainer selectHistory={selectHistory}>
          <button
            type="button"
            className="closer-history"
            onClick={() => setSelectHistory('Closers')}
          >
            Closer
          </button>

          <button
            type="button"
            className="queue-history"
            onClick={() => setSelectHistory('Queues')}
          >
            Queues
          </button>
        </TitleContainer>

        {selectHistory === 'Closers' ? (
          <CloserQueues line={selectLine} />
        ) : (
          <CreateQueues line={selectLine} closers={closersQueues} />
        )}
      </Body>
    </Container>
  );
}
