import { useState } from 'react';
import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import { Title } from '../../components/Title';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';

import { DivContent, DivContentDown, SituationButton } from './styles';
import { TableHunterSLA } from './TableHunterSLA';

export function HunterPanelSla(): JSX.Element {
  const [statePanel, setStatePanel] = useState<string>('Inicial');

  return (
    <>
      {statePanel !== 'Inicial' ? (
        <TableHunterSLA title_page={statePanel} setStatePanel={setStatePanel} />
      ) : (
        <Container>
          <Navegation>
            <H1Navigation>Negociação {'>'} </H1Navigation>
            <H1NavigationNow>Painel SLA Hunter</H1NavigationNow>
          </Navegation>

          <Body>
            <Title name="Painel SLA Hunter" />

            <DivContent>
              <SituationButton onClick={() => setStatePanel('Trave')}>
                TRAVE
              </SituationButton>

              <SituationButton onClick={() => setStatePanel('Retorno')}>
                RETORNO
              </SituationButton>

              <SituationButton onClick={() => setStatePanel('Bem-sucedido')}>
                BEM-SUCEDIDO
              </SituationButton>
            </DivContent>
            <DivContentDown>
              <SituationButton onClick={() => setStatePanel('Oportunidade')}>
                OPORTUNIDADE
              </SituationButton>

              <SituationButton onClick={() => setStatePanel('Perdido')}>
                PERDIDO
              </SituationButton>
            </DivContentDown>
          </Body>
        </Container>
      )}
    </>
  );
}
