import { ChangeEvent, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import api from '../../../../services/api';
import { IActivityDTO } from '../../dtos/IActivityDTO';
import { ICloserRestrictedTimeDTO } from '../../dtos/ICloserRestrictedTimeDTO';
import { INextActivityDTO } from '../../dtos/INextActivityDTO';

import {
  ModalSchedule,
  ModalMain,
  Title,
  RadioButtonGroup,
  RadioContainer,
  ButtonsContainer,
  Button,
} from './styles';

Modal.setAppElement('#root');

interface IModalDeleteRestrictedTimeProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleCloseModalRestrictedTime: () => void;
  restrictedTime: ICloserRestrictedTimeDTO | INextActivityDTO;
  handleUpdateActivity:
    | ((
        activity: IActivityDTO | INextActivityDTO,
        typeActivity: string,
        oldHour: string | Date,
      ) => void)
    | null;

  dateSchedulingPriority: string;
}

export const ModalDeleteRestrictedTime: React.FC<IModalDeleteRestrictedTimeProps> =
  ({
    isOpen,
    onRequestClose,
    handleCloseModalRestrictedTime,
    restrictedTime,
    handleUpdateActivity,
    dateSchedulingPriority,
  }) => {
    const [optionSelected, setOptionSelected] = useState(null);
    const closeModal = useCallback(() => {
      onRequestClose();
    }, [onRequestClose]);

    const handleRadioSelection = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setOptionSelected(value);
      },
      [],
    );

    const deleteActivitySuccess = useCallback(() => {
      toast.success('Atividade deletada com sucesso');
    }, []);

    const deleteActivityError = useCallback(() => {
      toast.error('Erro ao excluir atividade');
    }, []);

    const handleDeleteActivity = useCallback(async () => {
      try {
        if (optionSelected === 'every_day') {
          const response = await api.patch('schedule/update-restricted-time', {
            id: restrictedTime.id ? restrictedTime.id : '',
            deleted: true,
          });

          deleteActivitySuccess();
          handleUpdateActivity(
            response.data,
            'Restricted Time',
            restrictedTime.time_init,
          );

          closeModal();
          handleCloseModalRestrictedTime();
        } else {
          const response = await api.patch('schedule/update-restricted-time', {
            id: restrictedTime.id ? restrictedTime.id : '',
            deleted_today: true,
            deleted_date: `${dateSchedulingPriority} 3:00`,
          });

          deleteActivitySuccess();
          handleUpdateActivity(
            response.data,
            'Restricted time',
            restrictedTime.time_init,
          );

          closeModal();
          handleCloseModalRestrictedTime();
        }
      } catch (err) {
        deleteActivityError();
      }
    }, [
      deleteActivityError,
      optionSelected,
      closeModal,
      handleCloseModalRestrictedTime,
      handleUpdateActivity,
      restrictedTime,
      deleteActivitySuccess,
      dateSchedulingPriority,
    ]);

    return (
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalSchedule}>
        <ModalMain>
          <Title>Deseja excluir a atividade?</Title>
          <RadioButtonGroup>
            <RadioContainer>
              <input
                id="radio-1"
                type="radio"
                name="remove"
                value="today"
                onChange={handleRadioSelection}
              />
              <label htmlFor="radio-1">Somente para hoje</label>
            </RadioContainer>
            <RadioContainer>
              <input
                id="radio-2"
                type="radio"
                name="remove"
                value="every_day"
                onChange={handleRadioSelection}
              />
              <label htmlFor="radio-2">Para todos os dias</label>
            </RadioContainer>
          </RadioButtonGroup>
          <ButtonsContainer>
            <Button onClick={closeModal}>Cancelar</Button>
            <Button onClick={handleDeleteActivity}>OK</Button>
          </ButtonsContainer>
        </ModalMain>
      </Modal>
    );
  };
