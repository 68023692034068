import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { Title } from '../../../../components/Title';
import { IHunterSLADTO } from '../dtos/IHunterSLADTO';
import { Loading } from '../../../../components/Loading';
import { createFirstPassClarificationNote } from '../utils/createClarificationNote';
import {
  whoContacted,
  connectionMethod,
  lostCategoryAttendance,
  lostCategoryRecapture,
  lostReason,
} from '../utils/noteAmountByInput';
import {
  LostModalStyle,
  Main,
  InputContainer,
  Label,
  Select,
  Input,
  Textarea,
  ButtonSave,
  ButtonExit,
} from './styles';
import verifyDealTj from './utils/verifyDealTj';

interface FormData {
  ticket_id: string;
  who_contacted: string;
  valid_contact: string;
  valid_contact_wpp_opt_in: string;
  connection_type: string;
  connection_method: string;
  closing_potencial: string;
  reaction: string;
  plans: string;
  profile: string[];
  influence: string;
  objection: string;
  clarification_note: string;
  observation: string;
  lost_category: string;
  autority: string;
  doubts: string;
  process_knowledge: string;
  proposal_explanation: string;
  why_didnt_anticipate_before: string;
  customer_priority: string;
  decision_review: string;
  contact_with_competitor: string;
  informed_negotiable_value: string;
  negotiable_value: string;
  opt_in: string;
}
interface IHubspotDeal {
  hs_pipeline: string;
}

interface LostModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function LostModal({
  isOpen,
  onRequestClose,
}: LostModalProps): JSX.Element {
  const { user } = useAuth();
  const [formData, setFormData] = useState<FormData>({
    ...{},
    profile: [],
  } as FormData);

  const [lastSLA, setLastSLA] = useState<IHunterSLADTO>({} as IHunterSLADTO);
  const [selectedConnection, setSelectedConnection] = useState<string>();

  const [ticketDeal, setTicketDeal] = useState<IHubspotDeal>(
    {} as IHubspotDeal,
  );
  const [produtoPrecato, setProdutoPrecato] = useState<string>('');
  const [verifyDeal, setVerifyDeal] = useState<boolean>(true);

  const [lostCategoryAttendanceHook, setLostCategoryAttendanceHook] = useState(
    lostCategoryAttendance,
  );

  const [lostCategoryRecaptureHook, setLostCategoryRecaptureHook] = useState(
    lostCategoryRecapture,
  );

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setFormData({
      ...{},
      profile: [],
    } as FormData);
    setLastSLA({} as IHunterSLADTO);
  }, [onRequestClose]);

  const handleCloseBlockedModal = useCallback(() => {
    setVerifyDeal(true);
    onRequestClose();
  }, [onRequestClose, setVerifyDeal]);

  useEffect(() => {
    if (
      formData.who_contacted &&
      formData.connection_method &&
      formData.connection_type &&
      formData.valid_contact
    ) {
      const clarificationNote = createFirstPassClarificationNote({
        hunter_name: user.name,
        who_contacted: formData.who_contacted,
        connection_type: formData.connection_type,
        connection_method: formData.connection_method,
        valid_contact: formData.valid_contact,
      });
      setFormData(form_data => {
        return { ...form_data, clarification_note: clarificationNote };
      });
    } else {
      setFormData(form_data => {
        return { ...form_data, clarification_note: '' };
      });
    }
  }, [
    formData.connection_method,
    formData.connection_type,
    formData.valid_contact,
    formData.who_contacted,
    user.name,
  ]);

  useEffect(() => {
    if (produtoPrecato.toLocaleUpperCase() === 'FEDERAL') {
      const attendance = [];
      const recapture = [];

      lostCategoryAttendance.forEach(category => {
        if (
          category.label !== 'Credor realizou acordo' &&
          category.label !== 'Recebeu super preferência'
        ) {
          attendance.push(category);
        }
      });

      lostCategoryRecapture.forEach(category => {
        if (
          category.label !== 'Credor realizou acordo' &&
          category.label !== 'Recebeu super preferência'
        ) {
          recapture.push(category);
        }
      });

      setLostCategoryAttendanceHook(attendance);
      setLostCategoryRecaptureHook(recapture);
    } else {
      setLostCategoryAttendanceHook(lostCategoryAttendance);
      setLostCategoryRecaptureHook(lostCategoryRecapture);
    }
  }, [produtoPrecato]);

  const handleGetProdutoPrecato = useCallback(async ticket_id => {
    const response = await api.get(
      `/schedule/get-hubspot-deal-info?hubspot_ticket_id=${ticket_id}`,
    );

    setProdutoPrecato(response.data.deal.produto_precato);
  }, []);

  const handleLoadLastSLA = useCallback(
    async (ticket_id: string) => {
      setSelectedConnection(undefined);

      const response = await api.get(`/schedule/last-deal-sla/${ticket_id}`);
      if (response.data) {
        setLastSLA(response.data);

        setFormData({
          ...response.data,
          clarification_note: '',
          profile: response.data.profile
            ? response.data.profile.split(',')
            : [],
        });
      } else {
        setFormData({ ...formData, ticket_id });
      }

      const secondResponse = await api.get(
        `/schedule/get-hubspot-deal-info?hubspot_ticket_id=${ticket_id}&connection=${selectedConnection}`,
      );

      const isTicketValid = await verifyDealTj({
        closed_date: secondResponse.data.closed_date,
      });
      setVerifyDeal(isTicketValid);
    },
    [formData, selectedConnection],
  );

  const handleSetTicketDeal = useCallback(async (hubspot_ticket_id: string) => {
    try {
      const { data } = await api.get(`schedule/ticket/${hubspot_ticket_id}`);
      setTicketDeal(data);
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }, []);

  const handleInputChange = useCallback(
    async (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;
      if (name === 'ticket_id' && value.length >= 9) {
        await handleLoadLastSLA(value);
        await handleSetTicketDeal(value);
        await handleGetProdutoPrecato(value);
      } else if (
        name === 'Direto' ||
        name === 'Educado' ||
        name === 'Grosso' ||
        name === 'Instruído' ||
        name === 'Simples' ||
        name === 'Conversador'
      ) {
        const index = formData.profile.findIndex(profile => profile === name);

        if (index >= 0) {
          formData.profile.splice(index, 1);
        } else {
          formData.profile.push(name);
        }
        setFormData({ ...formData, [name]: value });
      } else if (name === 'negotiable_value') {
        let valor = value;
        valor = valor.replace(/[\D]+/g, '');

        setFormData({ ...formData, [name]: valor });

        //  valor = valor.replace(/([0-9]{2})$/g, ',$1');
        if (valor.length > 6) {
          valor = valor.replace(
            /([0-9]{1,3})([0-9]{3})([0-9]{3}$)/g,
            '$1.$2.$3',
          );
        } else if (valor.length > 3) {
          valor = valor.replace(/([0-9]{1,3})([0-9]{3}$)/g, '$1.$2');
        }

        valor = `R$ ${valor}`;

        event.target.value = valor;
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData, handleGetProdutoPrecato, handleLoadLastSLA, handleSetTicketDeal],
  );

  const handleSaveSLA = useCallback(async () => {
    try {
      if (formData.ticket_id === '' || isNaN(Number(formData.ticket_id))) {
        Swal.fire('Erro!', 'Ticket ID inválido!', 'error');
      } else {
        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        if (
          ticketDeal.hs_pipeline === '2945972' ||
          ticketDeal.hs_pipeline === '9536414'
        ) {
          const schema = yup.object().shape({
            ticket_id: yup.string().required('Ticket ID obrigatório!'),
            who_contacted: yup
              .string()
              .required('Com quem conversou obrigatório!'),
            valid_contact: yup.string().required('Contato válido obrigatório!'),
            connection_type: yup
              .string()
              .required('Tipo de conexão obrigatório!'),
            connection_method: yup
              .string()
              .required('Meio de conexão obrigatório!'),

            lost_category: yup
              .string()
              .required('Categoria de perda obrigatório!'),

            clarification_note: yup
              .string()
              .required('Nota de esclarecimento obrigatória'),
            observation: yup.string().required('Observação obrigatório!'),
          });

          await schema.validate(formData, {
            abortEarly: false,
          });
        } else if (
          ticketDeal.hs_pipeline === '2154049' ||
          ticketDeal.hs_pipeline === '9528351'
        ) {
          const schema = yup.object().shape({
            ticket_id: yup.string().required('Ticket ID obrigatório!'),
            who_contacted: yup
              .string()
              .required('Com quem conversou obrigatório!'),
            valid_contact: yup.string().required('Contato válido obrigatório!'),
            connection_type: yup
              .string()
              .required('Tipo de conexão obrigatório!'),
            connection_method: yup
              .string()
              .required('Meio de conexão obrigatório!'),

            lost_category: yup
              .string()
              .required('Categoria de perda obrigatório!'),
            clarification_note: yup
              .string()
              .required('Nota de esclarecimento obrigatória'),
            observation: yup.string().required('Observação obrigatório!'),
          });

          await schema.validate(formData, {
            abortEarly: false,
          });
        }

        const connectionMethodValue = connectionMethod.find(
          connection_method =>
            connection_method.label === formData.connection_method,
        );

        const lostCategory = lostReason;
        /* const lostCategory =
          ticketDeal.hs_pipeline === '2945972' ||
          ticketDeal.hs_pipeline === '9536414'
            ? lostCategoryAttendance
            : lostCategoryRecapture; */

        const lostCategoryValue = lostCategory.find(
          lost_category => lost_category.value === formData.lost_category,
        );

        const totalAmount =
          connectionMethodValue.amount + lostCategoryValue.amount;

        if (
          ticketDeal.hs_pipeline === '2945972' ||
          ticketDeal.hs_pipeline === '9536414'
        ) {
          await api.post('/schedule/hunter-sla', {
            name: 'Perdido',
            hunter_id: user.id,
            ticket_id: formData.ticket_id,
            autority: formData.autority,
            who_contacted: formData.who_contacted,
            valid_contact: formData.valid_contact,
            valid_contact_wpp_opt_in: formData.valid_contact_wpp_opt_in,
            connection_type: formData.connection_type,
            connection_method: formData.connection_method,
            closing_potencial: formData.closing_potencial,
            reaction: formData.reaction,
            plans: formData.plans,
            profile: formData.profile.toString(),
            influence: formData.influence,
            objection: formData.objection,
            lost_category: formData.lost_category,
            doubts: formData.doubts,
            clarification_note: formData.clarification_note,
            observation: formData.observation,
            process_knowledge: formData.process_knowledge,
            proposal_explanation: formData.proposal_explanation,
            note_amount: totalAmount,
            opt_in: formData.opt_in,
          });
        } else {
          await api.post('/schedule/hunter-sla', {
            name: 'Perdido',
            hunter_id: user.id,
            ticket_id: formData.ticket_id,
            who_contacted: formData.who_contacted,
            connection_method: formData.connection_method,
            connection_type: formData.connection_type,
            valid_contact: formData.valid_contact,
            valid_contact_wpp_opt_in: formData.valid_contact_wpp_opt_in,
            closing_potencial: formData.closing_potencial,
            clarification_note: formData.clarification_note,
            observation: formData.observation,
            autority: formData.autority,
            contact_with_competitor: formData.contact_with_competitor,
            customer_priority: formData.customer_priority,
            reaction: formData.reaction,
            plans: formData.plans,
            profile: formData.profile.toString(),
            doubts: formData.doubts,
            why_didnt_anticipate_before: formData.why_didnt_anticipate_before,
            decision_review: formData.decision_review,
            informed_negotiable_value: formData.informed_negotiable_value,
            negotiable_value: formData.negotiable_value,
            influence: formData.influence,
            objection: formData.objection,
            lost_category: formData.lost_category,
            note_amount: totalAmount,
          });
        }

        toast.success('Perdido salvo com sucesso!');

        handleCloseModal();
      }
    } catch (error) {
      console.log(error);
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      if (error instanceof yup.ValidationError) {
        toast.error('Preencha todos os campos corretamente.');

        return;
      }

      if (error.response.data.status === 404) {
        Swal.fire('Erro!', error.response.data.message, 'error');
        return;
      }

      toast.error('Não foi possível salvar caso perdido.');
    }
  }, [formData, ticketDeal.hs_pipeline, user.id, handleCloseModal]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={LostModalStyle}
    >
      <Title name="Perdido" />

      <Loading />

      <Main>
        <InputContainer>
          <Label>ID do ticket:</Label>

          <Input type="number" name="ticket_id" onChange={handleInputChange} />
        </InputContainer>

        {!verifyDeal ? (
          <InputContainer>
            <ButtonExit onClick={handleCloseBlockedModal}>Sair</ButtonExit>
          </InputContainer>
        ) : (
          <>
            {/* <InputContainer>
              <Label>Autoridade?</Label>
              <Select
                name="autority"
                defaultValue={lastSLA.autority || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {autority.map(found_autority => (
                  <option
                    key={found_autority.label}
                    value={found_autority.label}
                    selected={formData.autority === found_autority.label}
                  >
                    {found_autority.label}
                  </option>
                ))}
              </Select>
            </InputContainer> */}

            <InputContainer>
              <Label>Com quem foi o contato?</Label>
              <Select
                name="who_contacted"
                defaultValue={lastSLA.who_contacted || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {whoContacted.map(who_contacted => (
                  <option
                    key={who_contacted.label}
                    value={who_contacted.label}
                    selected={formData.who_contacted === who_contacted.label}
                  >
                    {who_contacted.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Contato válido (credor):</Label>

              <Input
                type="text"
                name="valid_contact"
                defaultValue={lastSLA.valid_contact || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Contato válido (WPP):</Label>
              <Input
                type="text"
                name="valid_contact_wpp_opt_in"
                defaultValue={lastSLA.valid_contact_wpp_opt_in || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Tipo de conexão:</Label>
              <Select
                name="connection_type"
                defaultValue={lastSLA.connection_type || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option
                  value="Inbound (0800)"
                  selected={lastSLA.connection_type === 'Inbound (0800)'}
                >
                  Inbound (0800)
                </option>
                <option
                  value="Inbound (Virtual)"
                  selected={lastSLA.connection_type === 'Inbound (Virtual)'}
                >
                  Inbound (Virtual)
                </option>
                <option
                  value="Outbound"
                  selected={lastSLA.connection_type === 'Outbound'}
                >
                  Outbound
                </option>
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Meio de conexão:</Label>
              <Select
                name="connection_method"
                defaultValue={lastSLA.connection_method || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {connectionMethod.map(connection_method => (
                  <option
                    key={connection_method.label}
                    value={connection_method.label}
                    selected={
                      formData.connection_method === connection_method.label
                    }
                  >
                    {connection_method.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Categoria de perda:</Label>
                <Select
                  name="lost_category"
                  defaultValue=""
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>

                  {lostReason.map(lost_category => {
                    return (
                      <option
                        key={lost_category.label}
                        value={lost_category.value}
                      >
                        {lost_category.label}
                      </option>
                    );
                  })}
                </Select>
              </InputContainer>
            ) : (
              <InputContainer>
                <Label>Categoria de perda:</Label>
                <Select
                  name="lost_category"
                  defaultValue=""
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {lostReason.map(lost_category => (
                    <option
                      key={lost_category.label}
                      value={lost_category.value}
                    >
                      {lost_category.label}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            )}

            {ticketDeal.hs_pipeline === '2945972' ||
            ticketDeal.hs_pipeline === '9536414' ? (
              <InputContainer>
                <Label>Nota de esclarecimento:</Label>
                <Textarea
                  disabled
                  name="clarification_note"
                  defaultValue={formData.clarification_note}
                />
              </InputContainer>
            ) : (
              <InputContainer>
                <Label>Nota de esclarecimento:</Label>
                <Textarea
                  disabled
                  name="clarification_note"
                  defaultValue={formData.clarification_note}
                />
              </InputContainer>
            )}

            <InputContainer>
              <Label>Observação:</Label>
              <Textarea
                name="observation"
                defaultValue=""
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <ButtonSave onClick={handleSaveSLA}>Salvar</ButtonSave>
            </InputContainer>
          </>
        )}
      </Main>
    </Modal>
  );
}
