import { useState } from 'react';
import { Table, TableContainer, TableRow, Tbody } from './style';
import { IRequestFileProps } from '../index';
import { UploaderTHead, UploaderHeadCell } from '../style';

interface IUploadedFiles {
  files: IRequestFileProps[];
}

export function UploadedFiles({ files }: IUploadedFiles): JSX.Element {
  const [download, setDownload] = useState<string>('');
  const url = 'http://localhost:4001/enrichment/input-files';

  return (
    <TableContainer>
      <Table>
        <UploaderTHead>
          <TableRow>
            <UploaderHeadCell>Arquivos</UploaderHeadCell>
          </TableRow>
        </UploaderTHead>
        <Tbody>
          {files.map((file, index) => (
            <TableRow className="tr-body" key={String(index)}>
              <div className="downloadLinkContainer">
                <a
                  href={`${url}/${download}`}
                  onClick={async () => {
                    setDownload(file.fileName);
                  }}
                  className="downloadFileLink"
                  download={file.fileName}
                >
                  {file.fileName}
                </a>
              </div>
            </TableRow>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
