import { SLABase } from '../ModalSLABase';

import { ISLABase, ISLAN5, ISLAProps } from '../interface';
import { InputButtonSave } from '../components/InputButtonSave';
import { TextAreaSLA } from '../components/TextAreaSLA';
import { Input, InputContainer, Label } from '../styles';

interface ISLAN5Props extends ISLAProps {
  SLA: ISLAN5;
  closer_name_scheduling: string;
}
export function SLAN5({
  SLA,
  data,
  handleSaveSLA,
  handleChange,
  typeModal,
  closer_name_scheduling,
  handleChangeCurrency,
}: ISLAN5Props): JSX.Element {
  return (
    <>
      {closer_name_scheduling && (
        <InputContainer>
          <Label>Closer responsável:</Label>
          <Input
            type="text"
            disabled
            style={{ border: 'none' }}
            value={closer_name_scheduling}
          />
        </InputContainer>
      )}
      <SLABase
        SLA={SLA as ISLABase}
        handleChange={handleChange}
        typemodal={typeModal}
        handleChangeCurrency={handleChangeCurrency}
        data={data}
      />

      <TextAreaSLA
        title="Observação *"
        name="observation"
        value={SLA.observation || ''}
        handleChange={handleChange}
        disable={false}
      />

      <TextAreaSLA
        title="Nota de esclarecimento:"
        name="clarification_note"
        value={SLA.clarification_note || ''}
        handleChange={handleChange}
        disable
      />

      <InputButtonSave handleSaveSLA={handleSaveSLA} />
    </>
  );
}
