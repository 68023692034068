import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Swal from 'sweetalert2';

import apiTrfsUrl from '../../../services/apiTrfsUrl';

import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';
import { ExampleFileTRF3Pje } from '../components/ExampleFileTRF3Pje';
import { ExampleFileTRF3Protocol } from '../components/ExampleFileTRF3Protocol';
import { ExampleFileTRF2TRF4 } from '../components/ExampleFileTRF2TRF4';
import { ExampleFileTRF4Cpf } from '../components/ExampleFileTRF4Cpf';
import { ExampleFileTRF3Cpf } from '../components/ExampleFileTRF3Cpf';
import { ExampleFileTRF4Movement } from '../components/ExampleFileTRF4Movement';
import { ExampleFileTRF2UpdatedValue } from '../components/ExampleFileTRF2UpdatedValue';

import {
  CourtTitleContainer,
  FileContainer,
  CourtContentContainer,
  CourtContainer,
  FilesHistoryContainer,
  TitleHistory,
  ButtonContainer,
  ClearHitoryButton,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
  ButtonZip,
  Button,
  SelectCourtContainer,
  SelectCourtOption,
  SelectCourt,
} from './styles';
import { TrfDataExtraction } from '../TrfDataExtraction';
import { ExampleFileTRF4Withdraw } from '../components/ExampleFileTRF4Withdraw';
import { ExampleFileTRF2Movement } from '../components/ExampleFileTRF2Movement';
import { ExampleFileTRF2Withdraw } from '../components/ExampleFileTRF2Withdraw';

interface IFileHistory {
  id: number;
  name: string;
  trf: string;
  lines: string;
  processed: string;
  created_at: Date;
  file_url: string;
  zip_url: string;
  zip_name: string;
}

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export const TRFS: React.FC = () => {
  const [filesHistory, setFilesHistory] = useState<IFileHistory[]>([]);
  const [court, setCourt] = useState<string>('');
  const [file, setFile] = useState<IFileProps>({} as IFileProps);

  useEffect(() => {
    const url: string =
      court === 'trf1-pje'
        ? '/files/process_files/TRF1PJE'
        : court === 'trf2-updated-value'
        ? '/trfs/TRF2UPDATED'
        : court === 'trf2-movement'
        ? '/payment/trf2-movement'
        : court === 'trf2-withdraw'
        ? '/payment/trf2-withdraw-location'
        : court === 'trf3-protocol'
        ? '/files/process_files/TRF3PROTOCOL'
        : court === 'trf3-pje'
        ? '/files/process_files/TRF3PJE'
        : court === 'trf3-cpf'
        ? '/files/process_files/TRF3CPF'
        : court === 'trf4-cpf'
        ? `/trfs/TRF4CPF`
        : court === 'trf4-movement'
        ? `/payment/trf4-movement`
        : court === 'trf4-withdraw'
        ? `/payment/trf4-withdraw-location`
        : court === 'trf3-cpf'
        ? '/cpf/'
        : `/trfs/${court.toUpperCase()}`;

    if (court) {
      const apiUrl = apiTrfsUrl(court);

      apiUrl.get<IFileHistory[]>(url).then(response => {
        setFilesHistory(response.data);
      });
    }
  }, [court, file]);

  const sendFileSucess = useCallback(async () => {
    Swal.fire('Sucesso!', 'Requisição recebida com sucesso', 'success');
  }, []);

  const sendFileError = useCallback(async () => {
    Swal.fire('Erro!', 'Falha ao enviar requisição.', 'error');
  }, []);

  const handleDownload = useCallback(
    async (url: string, type_file: string) => {
      const index = url.indexOf(type_file);

      const fileName =
        type_file === 'zips' ? url.substr(index + 5) : url.substr(index + 8);

      const apiUrl = apiTrfsUrl(court);

      const response = await apiUrl.get(url, {
        responseType: 'blob',
      });

      saveAs(response.data, fileName);
    },
    [court],
  );

  const handleZipPdfs = useCallback(
    async (id: number, url: string) => {
      try {
        if (court) {
          const apiUrl = apiTrfsUrl(court);

          await apiUrl.get(
            court === 'trf1-pje'
              ? `files/zip/${id}`
              : court === 'trf3-pje'
              ? `files/zip/${id}`
              : court === 'trf4-cpf'
              ? `/trfs/zip-trf/${id}/TRF4CPF`
              : court === 'trf2-updated-value'
              ? `/trfs/zip-trf/${id}/TRF2UPDATED`
              : `/trfs/zip-trf/${id}/${court.toUpperCase()}`,
          );

          const index = url.indexOf('zips');

          const fileName = url.substr(index + 5);

          const response = await apiUrl.get(url, {
            responseType: 'blob',
          });

          saveAs(response.data, fileName);
        }
      } catch (error) {
        sendFileError();
      }
    },
    [court, sendFileError],
  );

  const handleSelectCourt = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setCourt(value);
    },
    [],
  );

  const submitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: IFileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };
      setFile(newFile);

      event.target.value = null;
    },
    [setFile],
  );

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    try {
      data.append('file', file.file);

      const url =
        court === 'trf1-pje'
          ? '/assignment/pje'
          : court === 'trf2-updated-value'
          ? '/trfs/trf2-updated-value/create-csv'
          : court === 'trf2-movement'
          ? '/payment/trf2-movement/create-csv'
          : court === 'trf2-withdraw'
          ? '/payment/trf2-withdraw-location/create-csv'
          : court === 'trf3-pje'
          ? '/pje/create-csv'
          : court === 'trf3-protocol'
          ? '/protocol/csv'
          : court === 'trf3-cpf'
          ? '/cpf/'
          : court === 'trf3-pdf'
          ? '/extract-data'
          : court === 'trf4-cpf'
          ? '/trfs/trf4-cpf/create-csv'
          : court === 'trf4-movement'
          ? '/payment/trf4-movement/create-csv'
          : court === 'trf4-withdraw'
          ? '/payment/trf4-withdraw-location/create-csv'
          : `trfs/${court}/create-csv`;

      const apiUrl = apiTrfsUrl(court);

      await apiUrl.post(url, data);

      setFile({} as IFileProps);

      sendFileSucess();
    } catch (error) {
      sendFileError();
    }
  }, [file, sendFileError, sendFileSucess, court]);

  return (
    <Container>
      <NavigationBar past_screens="Processsamento > " active_screen="TRF's" />

      <Body>
        <Title name="Processamento de arquivos - Ofícios TRF's" />

        <SelectCourtContainer>
          <CourtContainer>
            <CourtTitleContainer>Selecione o TRF:</CourtTitleContainer>
            <CourtContentContainer>
              <SelectCourt onChange={handleSelectCourt}>
                <SelectCourtOption value="">
                  Selecione uma opção
                </SelectCourtOption>
                <SelectCourtOption value="trf1-pdf-model-1">
                  TRF 1 - PDF VF
                </SelectCourtOption>
                <SelectCourtOption value="trf1-pdf-model-2">
                  TRF 1 - PDF TJ
                </SelectCourtOption>
                <SelectCourtOption value="trf1-procedural">
                  TRF 1 - Processual
                </SelectCourtOption>
                <SelectCourtOption value="trf1-pje">
                  TRF 1 - PJE
                </SelectCourtOption>
                <SelectCourtOption value="trf2">TRF 2</SelectCourtOption>
                <SelectCourtOption value="trf2-updated-value">
                  TRF 2 - Valor Atualizado
                </SelectCourtOption>
                <SelectCourtOption value="trf2-movement">
                  TRF 2 - Pagamento
                </SelectCourtOption>
                <SelectCourtOption value="trf2-withdraw">
                  TRF 2 - Local de Saque
                </SelectCourtOption>
                <SelectCourtOption value="trf3-pdf">
                  TRF 3 - PDF
                </SelectCourtOption>
                <SelectCourtOption value="trf3-pje">
                  TRF 3 - PJE
                </SelectCourtOption>
                <SelectCourtOption value="trf3-protocol">
                  TRF 3 - Protocolo
                </SelectCourtOption>
                <SelectCourtOption value="trf3-cpf">
                  TRF 3 - CPF
                </SelectCourtOption>
                <SelectCourtOption value="trf4">TRF 4</SelectCourtOption>
                <SelectCourtOption value="trf4-cpf">
                  TRF 4 - Cpf
                </SelectCourtOption>
                <SelectCourtOption value="trf4-movement">
                  TRF 4 - Pagamento
                </SelectCourtOption>
                <SelectCourtOption value="trf4-withdraw">
                  TRF 4 - Local de Saque
                </SelectCourtOption>
                <SelectCourtOption value="trf5-pdf">
                  TRF 5 - Pdf
                </SelectCourtOption>
                <SelectCourtOption value="trf6-pdf">
                  TRF 6 - PDF
                </SelectCourtOption>
              </SelectCourt>
            </CourtContentContainer>
          </CourtContainer>
        </SelectCourtContainer>

        {court && court !== '' && (
          <>
            {court.includes('pdf') ? (
              <TrfDataExtraction court={court} />
            ) : (
              <>
                <FileContainer>
                  {court === 'trf2-updated-value' ? (
                    <ExampleFileTRF2UpdatedValue
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf2-movement' ? (
                    <ExampleFileTRF2Movement
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf2-withdraw' ? (
                    <ExampleFileTRF2Withdraw
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf3-pje' ? (
                    <ExampleFileTRF3Pje
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf3-protocol' ? (
                    <ExampleFileTRF3Protocol
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf4-cpf' ? (
                    <ExampleFileTRF4Cpf
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf4-movement' ? (
                    <ExampleFileTRF4Movement
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf4-withdraw' ? (
                    <ExampleFileTRF4Withdraw
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : court === 'trf3-cpf' ? (
                    <ExampleFileTRF3Cpf
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  ) : (
                    <ExampleFileTRF2TRF4
                      handleUpload={handleUpload}
                      submitFile={submitFile}
                      file_name={file.name}
                    />
                  )}
                </FileContainer>

                <FilesHistoryContainer>
                  <TitleHistory>Processamento anteriores</TitleHistory>
                  <ButtonContainer>
                    <ClearHitoryButton type="button">
                      Limpar histórico
                    </ClearHitoryButton>
                  </ButtonContainer>
                </FilesHistoryContainer>

                <Table>
                  <Thead>
                    <TableRowHead>
                      <CellHeader>
                        <TitleTable>Data de importação</TitleTable>
                        <Divisor />
                      </CellHeader>
                      <CellHeader>
                        <TitleTable>Nome do arquivo CSV</TitleTable>
                        <Divisor />
                      </CellHeader>
                      {court === 'trf3-protocol' ||
                      court === 'trf3-cpf' ||
                      court === 'trf2-movement' ||
                      court === 'trf2-withdraw' ||
                      court === 'trf4-movement' ||
                      court === 'trf4-withdraw' ? (
                        <></>
                      ) : (
                        <CellHeader>
                          <TitleTable>Zip PDFs</TitleTable>
                          <Divisor />
                        </CellHeader>
                      )}
                      <CellHeader>
                        <TitleTable>Situação</TitleTable>
                        <Divisor />
                      </CellHeader>
                      <CellHeader>
                        <TitleTable>Ações</TitleTable>
                      </CellHeader>
                    </TableRowHead>
                  </Thead>
                  <Tbody>
                    {filesHistory.length > 0 &&
                      filesHistory.map(fileHistory => (
                        <TableRowBody key={fileHistory.id}>
                          <CellBody>{fileHistory.created_at}</CellBody>
                          <CellBody
                            onClick={() =>
                              handleDownload(fileHistory.file_url, 'uploads')
                            }
                          >
                            {fileHistory.name}
                          </CellBody>
                          {court === 'trf3-protocol' || court === 'trf3-cpf' ? (
                            <></>
                          ) : (
                            <CellBody>
                              <ButtonZip
                                type="button"
                                onClick={() =>
                                  handleZipPdfs(
                                    fileHistory.id,
                                    fileHistory.zip_url,
                                  )
                                }
                                disabled={fileHistory.processed === '0'}
                              >
                                {fileHistory.processed !== '0'
                                  ? 'PDFs da consulta'
                                  : 'Sem PDF'}
                              </ButtonZip>
                            </CellBody>
                          )}
                          <CellBody>
                            (Processado {fileHistory.processed} de{' '}
                            {fileHistory.lines})
                          </CellBody>
                          <CellBody>
                            <Button type="button" disabled>
                              Reprocessar
                            </Button>
                          </CellBody>
                        </TableRowBody>
                      ))}
                  </Tbody>
                </Table>
              </>
            )}
          </>
        )}
      </Body>
    </Container>
  );
};
