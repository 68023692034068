import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Title';
import { Body } from '../../../components/Styles/Body';
import { Container } from '../../../components/Container';
import { BackButton } from '../../../components/BackButton';
import { InputCheckbox } from '../../../components/InputCheckbox';

import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg';

import {
  ContainerTitleSection,
  TitleSection,
  FormContainer,
  Line,
  FormGroup,
  InputContainer,
  Label,
  Input,
  LinePermissions,
  FormGroupPermission,
  ContainerLevel,
  PermissionsContainer,
  ButtonsContainerEditProfile,
  ButtonEditProfile,
  Icon,
} from './styles';

interface IProfile {
  id: string;
  name: string;
}

interface IPermission {
  id: string;
  name: string;
  checked?: boolean;
  description: string;
}

export const EditProfile: React.FC = () => {
  const [profile, setProfile] = useState<IProfile>({
    id: '',
    name: '',
  } as IProfile);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [permissionsByProfile, setPermissionsByProfile] = useState<
    IPermission[]
  >([]);

  const history = useHistory();
  const location = useLocation();
  const oldProfile = location.state as IProfile;

  useEffect(() => {
    api.get(`/users/profiles?name=${oldProfile.name}`).then(response => {
      setProfile(response.data);
    });

    api.get(`/users/permissions/profile/${oldProfile.id}`).then(response => {
      setPermissionsByProfile(response.data);
    });
  }, [oldProfile]);

  const handleCheckedContext = useCallback(
    (verifyPermissions: IPermission[], value: string) => {
      let contCheckbox = 0;

      const permissionsByContext = verifyPermissions.filter(
        permission => permission.description === value && permission,
      );

      for (const permission of permissionsByContext) {
        const element = document.getElementById(
          permission.id,
        ) as HTMLInputElement | null;

        if (element?.checked === true) {
          contCheckbox += 1;
        }
      }

      if (permissionsByContext.length === contCheckbox) {
        const input = document.getElementById(value) as HTMLInputElement;
        input.checked = true;
      }
    },
    [],
  );

  useEffect(() => {
    api.get<IPermission[]>('/users/permissions').then(response => {
      const filterPermissions = response.data.map(permission => {
        const foundPermission = permissionsByProfile.find(
          findPermission => findPermission.id === permission.id,
        );

        return foundPermission
          ? { ...permission, checked: true }
          : { ...permission, checked: false };
      });

      setPermissions(filterPermissions);

      handleCheckedContext(response.data, 'Processamento');
      handleCheckedContext(response.data, 'Negociação');
      handleCheckedContext(response.data, 'Administração');
      handleCheckedContext(response.data, 'Conexão');
      handleCheckedContext(response.data, 'Newsletter');
      handleCheckedContext(response.data, 'Vórtx');
      handleCheckedContext(response.data, 'Pagamento');
    });
  }, [handleCheckedContext, permissionsByProfile]);

  const handleInputChangePermission = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = event.target;

      const updatedStatePermissions = permissions.map(findPermission => {
        if (findPermission.id === value) {
          return {
            ...findPermission,
            checked,
          };
        }

        if (
          (value === 'Processamento' ||
            value === 'Negociação' ||
            value === 'Administração' ||
            value === 'Conexão' ||
            value === 'Newsletter' ||
            value === 'Vórtx' ||
            value === 'Pagamento') &&
          findPermission.description === value
        ) {
          return {
            ...findPermission,
            checked,
          };
        }

        return findPermission;
      });

      setPermissions(updatedStatePermissions);
    },
    [permissions],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setProfile({ ...profile, [name]: value });
    },
    [profile],
  );

  const editProfileSuccess = useCallback(() => {
    toast.success('Perfil editado com sucesso');
  }, []);

  const editProfileError = useCallback(() => {
    Swal.fire('Erro!', 'Ocorreu um erro ao editar o perfil.', 'error');
  }, []);

  const editProfile = useCallback(async () => {
    const permissionsUpdated = permissions.filter(
      permission => permission.checked === true,
    );

    const permissionsIds = permissionsUpdated.map(
      findPermission => findPermission.id,
    );

    try {
      await api.put(`/users/profiles/${profile.id}`, {
        name: profile.name,
        permissions: permissionsIds.length > 0 ? permissionsIds : null,
      });

      editProfileSuccess();
      history.push({
        pathname: '/administration/profiles',
      });
    } catch (err) {
      editProfileError();
    }
  }, [editProfileError, editProfileSuccess, permissions, profile, history]);

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Navegation>
        <H1Navigation>
          Administração {'>'} Perfis {'>'}{' '}
        </H1Navigation>
        <H1NavigationNow>Editar perfil</H1NavigationNow>
      </Navegation>

      <Body>
        <Title name="Editar perfil" />
        <BackButton
          link={{
            pathname: '/administration/profiles',
          }}
          isNotMargin
        />

        <ContainerTitleSection>
          <TitleSection>Dados de acesso</TitleSection>
        </ContainerTitleSection>

        <FormContainer>
          <Line />
          <FormGroup>
            <InputContainer>
              <Label>Nome do perfil:</Label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={handleInputChange}
                value={profile.name}
              />
            </InputContainer>
          </FormGroup>
        </FormContainer>

        <ContainerTitleSection>
          <TitleSection>Permissões</TitleSection>
        </ContainerTitleSection>

        <FormContainer>
          <LinePermissions />
          <FormGroupPermission>
            <Label>Páginas permitidas:</Label>

            <PermissionsContainer>
              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Processamento"
                  name="Processamento"
                  change={handleInputChangePermission}
                />

                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Processamento' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Negociação"
                  name="Negociação"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Negociação' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Administração"
                  name="Administração"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Administração' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Conexão"
                  name="Conexão"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Conexão' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Newsletter"
                  name="Newsletter"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Newsletter' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Vórtx"
                  name="Vórtx"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Vórtx' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              <ContainerLevel className="level-container">
                <InputCheckbox
                  id="Pagamento"
                  name="Pagamento"
                  change={handleInputChangePermission}
                />
                {permissions.length > 0 &&
                  permissions.map(
                    permission =>
                      permission.description === 'Pagamento' && (
                        <InputCheckbox
                          isPermission
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      ),
                  )}
              </ContainerLevel>

              {permissions.length > 0 &&
                permissions.map(
                  permission =>
                    permission.description === 'Nenhum' && (
                      <div className="no-description">
                        <InputCheckbox
                          key={permission.id}
                          checked={permission.checked}
                          id={permission.id}
                          name={permission.name}
                          change={handleInputChangePermission}
                        />
                      </div>
                    ),
                )}
            </PermissionsContainer>
          </FormGroupPermission>
        </FormContainer>

        <ButtonsContainerEditProfile>
          <ButtonEditProfile type="button" onClick={editProfile}>
            <Icon>
              <SaveIcon />
            </Icon>
            Salvar
          </ButtonEditProfile>
        </ButtonsContainerEditProfile>
      </Body>
    </Container>
  );
};
