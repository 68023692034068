/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import closeIcon from '../../assets/icons/cancel.svg';
import {
  CreditorInformationGrid,
  InformationTitle,
  InformationValue,
  LawyerInformationGrid,
  ModalBody,
  ModalHeader,
  ModalStyle,
  ModalTitle,
  Icon,
  ModalHeaderTitle,
  LInformationCell,
  CInformationCell,
} from './styles';
import apiTrfs from '../../services/apiTrfs';

Modal.setAppElement('#root');

interface IProcessInformationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  precatory: string;
  process_type: string;
}

interface IProcess {
  creditor: string;
  creditor_cpf: string;
  lawyer: string;
  honorary_highlighted: string;
  lawyer_cpf: string;
  loa: string;
  originary_precatory_amount: string;
  originating_process: string;
  precatory: string;
  status: string;
  total_craft_value: string;
  trf: string;
  reason: string;
}

export function ProcessInformationModal({
  isOpen,
  onRequestClose,
  precatory,
  process_type,
}: IProcessInformationModalProps) {
  const [process, setProcess] = useState<IProcess>();

  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  useEffect(() => {
    if (isOpen) {
      apiTrfs
        .get(`/process_search/process-information?precatory=${precatory}`)
        .then(response => {
          setProcess(response.data);
        });
    }
  }, [isOpen, precatory]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalStyle}>
      <ModalHeader process_type={process_type}>
        <ModalHeaderTitle>Processo detalhado</ModalHeaderTitle>

        <Icon src={closeIcon} alt="Fechar modal" onClick={closeModal} />
      </ModalHeader>
      {process ? (
        <ModalBody>
          <ModalTitle>Informações do processo</ModalTitle>
          <CreditorInformationGrid>
            <CInformationCell className="name">
              <InformationTitle>Nome do credor</InformationTitle>
              <InformationValue>{process.creditor}</InformationValue>
            </CInformationCell>
            <CInformationCell className="cpf">
              <InformationTitle>CPF do credor</InformationTitle>
              <InformationValue>{process.creditor_cpf}</InformationValue>
            </CInformationCell>
            <CInformationCell className="precatory">
              <InformationTitle>Precatório</InformationTitle>
              <InformationValue>{process.precatory}</InformationValue>
            </CInformationCell>
            <CInformationCell className="total_value">
              <InformationTitle>Valor total</InformationTitle>
              <InformationValue>{process.total_craft_value}</InformationValue>
            </CInformationCell>
            <CInformationCell className="originating_process">
              <InformationTitle>Processo originário</InformationTitle>
              <InformationValue>{process.originating_process}</InformationValue>
            </CInformationCell>
            <CInformationCell className="loa">
              <InformationTitle>LOA</InformationTitle>
              <InformationValue>{process.loa}</InformationValue>
            </CInformationCell>
            <CInformationCell className="trf">
              <InformationTitle>TRF</InformationTitle>
              <InformationValue>{process.trf}</InformationValue>
            </CInformationCell>
            <CInformationCell className="status">
              <InformationTitle>Status</InformationTitle>
              <InformationValue>{process.status}</InformationValue>
            </CInformationCell>
            <CInformationCell className="highlighted_honorary">
              <InformationTitle>Honorário destacado</InformationTitle>
              <InformationValue>
                {process.honorary_highlighted}
              </InformationValue>
            </CInformationCell>
            <CInformationCell className="precatory_per_originating">
              <InformationTitle>Precatório por originário</InformationTitle>
              <InformationValue>
                {process.originary_precatory_amount}
              </InformationValue>
            </CInformationCell>
            <CInformationCell className="in_hubspot">
              <InformationTitle>Está no Hub</InformationTitle>
              <InformationValue>
                {process.status === 'HUBSPOT' ? 'SIM' : 'NÃO'}
              </InformationValue>
            </CInformationCell>
            {process_type !== 'inHub' && (
              <CInformationCell className="motive">
                <InformationTitle>Motivo</InformationTitle>
                <InformationValue>{process.reason}</InformationValue>
              </CInformationCell>
            )}
          </CreditorInformationGrid>
          <ModalTitle>Informações do advogado</ModalTitle>
          <LawyerInformationGrid>
            <LInformationCell className="lawyer_name">
              <InformationTitle>Nome do advogado</InformationTitle>
              <InformationValue>{process.lawyer}</InformationValue>
            </LInformationCell>
            <LInformationCell className="lawyer_cpf">
              <InformationTitle>CPF do advogado</InformationTitle>
              <InformationValue>{process.lawyer_cpf}</InformationValue>
            </LInformationCell>
          </LawyerInformationGrid>
        </ModalBody>
      ) : (
        ''
      )}
    </Modal>
  );
}
