import styled from 'styled-components';

export const Title = styled.h1`
  margin: 3rem auto 0 auto;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 2.4375rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const Separator = styled.hr`
  margin: 1rem auto 0 auto;
  width: 15.4375rem;
`;
