import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../assets/icons/arrow_blue.svg';

import { BackButtonContainer, Icon } from './styles';

interface IState {
  team_id?: string;
  key_result_id?: string;
  objective_id?: string;
  objective_name?: string;
  key_result_name?: string;
  responsible_user_id?: string;
}
interface BackButtonProps {
  link: {
    pathname: string;
    state?: IState;
  };
  isNotMargin?: boolean;
}
export const BackButton: React.FC<BackButtonProps> = ({
  link,
  isNotMargin,
}) => {
  return (
    <BackButtonContainer isNotMargin={isNotMargin}>
      <Link
        to={{
          pathname: link.pathname,
          state: link.state,
        }}
      >
        <Icon>
          <BackIcon />
        </Icon>
        Voltar
      </Link>
    </BackButtonContainer>
  );
};
