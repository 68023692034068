import styled from 'styled-components';

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
`;

export const ScheduleLine = styled.div`
  display: flex;
  height: 4.5rem;
`;

export const WatchIconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.5%;
`;

export const LineContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 92.4%;
  height: 100%;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  #empty {
    width: 3.438rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p#title {
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--blue-800);
  }

  p#subtitle {
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--blue-800);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;

  padding-top: 0.95rem;
  margin-right: 0.5rem;
  height: 100%;

  gap: 0.188rem;
`;

export const VisualizationButton = styled.button`
  background: none;
  border: none;
`;
