import {
  Container,
  DateContainer,
  AmountsContainer,
  ValueContainer,
  TitleSpan,
  ValueSpan,
} from './styles';

interface IClosedDeal {
  id: string;
  deal_name: string;
  closer_name: string;
  stage_name: string;
  status: string;
  link: string;
  amount: string;
}

interface IClosedDealInfo {
  closedDeals: IClosedDeal[];
  totalAmountClosed: string;
  totalAmountConcluded: string;
  totalCoursePending: string;
  totalCourseLate: string;
  totalLockedPending: string;
  totalLockedLate: string;
  quantityClosed: number;
  quantityBatonPass: number;
  quantityCoursePending: number;
  quantityCourseLate: number;
  quantityLockedPending: number;
  quantityLockedLate: number;
}

interface TotalAmountProps {
  date: Date;
  closedDealsInfo: IClosedDealInfo;
}

export function TotalAmountPanel({
  date,
  closedDealsInfo,
}: TotalAmountProps): JSX.Element {
  return (
    <Container>
      <DateContainer>
        <p>{`${date.getDate()},`}</p>
        <p>{date.toLocaleString('default', { month: 'short' })}</p>
        <p>{`${date.getFullYear()}`}</p>
      </DateContainer>

      <AmountsContainer>
        <ValueContainer>
          <TitleSpan>Total Fechado:</TitleSpan>
          <TitleSpan>{closedDealsInfo.quantityClosed} negócios</TitleSpan>
          <ValueSpan>{closedDealsInfo.totalAmountClosed}</ValueSpan>
        </ValueContainer>

        <ValueContainer>
          <TitleSpan>Bastão Pendente Travado:</TitleSpan>
          <TitleSpan>
            {closedDealsInfo.quantityLockedPending} negócios
          </TitleSpan>
          <ValueSpan>{closedDealsInfo.totalLockedPending}</ValueSpan>
        </ValueContainer>

        <ValueContainer>
          <TitleSpan>Bastão Atrasado Travado:</TitleSpan>
          <TitleSpan>{closedDealsInfo.quantityLockedLate} negócios</TitleSpan>
          <ValueSpan>{closedDealsInfo.totalLockedLate}</ValueSpan>
        </ValueContainer>

        <ValueContainer>
          <TitleSpan>Total Bastões/Dia:</TitleSpan>
          <TitleSpan>{closedDealsInfo.quantityBatonPass} negócios</TitleSpan>
          <ValueSpan>{closedDealsInfo.totalAmountConcluded}</ValueSpan>
        </ValueContainer>

        <ValueContainer>
          <TitleSpan>Bastão Pendente Corrido:</TitleSpan>
          <TitleSpan>
            {closedDealsInfo.quantityCoursePending} negócios
          </TitleSpan>
          <ValueSpan>{closedDealsInfo.totalCoursePending}</ValueSpan>
        </ValueContainer>

        <ValueContainer>
          <TitleSpan>Bastão Atrasado Corrido:</TitleSpan>
          <TitleSpan>{closedDealsInfo.quantityCourseLate} negócios</TitleSpan>
          <ValueSpan>{closedDealsInfo.totalCourseLate}</ValueSpan>
        </ValueContainer>
      </AmountsContainer>
    </Container>
  );
}
