import styled from 'styled-components';

export const Container = {
  content: {
    width: '48.75rem',
    minHeight: '23.5rem',
    maxHeight: '37.5rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;

  width: 100%;
  height: 3.125rem;

  background: var(--blue-500);
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--white);
`;

export const CloseModalButton = styled.button`
  border: none;
  background: none;

  width: 1.25rem;
  height: 1.25rem;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem 1.5rem;
  gap: 0.875rem;
`;

export const NameActivity = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  color: var(--blue-800);
  margin-bottom: 0.125rem;
`;

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3125rem;

  input {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    background: var(--gray-300);
    border: none;
    cursor: pointer;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-800);
`;

export const AnswerText = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray-800);
`;
