import React, { ChangeEvent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import axios from 'axios';
import blacklistImg from '../../assets/icons/blacklist.svg';
import closeModalImg from '../../assets/icons/close.svg';
import {
  ModalSchedule,
  ContainerClose,
  Button,
  Image,
  ContainerContent,
  ModalHeader,
  Container,
  ContainerTitle,
  Divisor,
  ModalBody,
  Label,
  Input,
  Contrast,
  ContainerCheckbox,
  ContainerInputCheckbox,
  InputCheckbox,
  LabelCheckbox,
  DeleteButton,
} from './styles';

Modal.setAppElement('#root');

interface IModalProps {
  is_open: boolean;
  on_request_close: () => void;
}

interface IFormData {
  contactLink?: string;
  reason: string;
  phone?: string;
  email?: string;
}

export const BlacklistContactFormModal: React.FC<IModalProps> = ({
  is_open,
  on_request_close,
}) => {
  const [formData, setFormData] = useState<IFormData>({} as IFormData);
  const [optOut, setOptOut] = useState(true);

  const closeModal = useCallback(() => {
    on_request_close();
  }, [on_request_close]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const handleReasonInputCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFormData({ ...formData, reason: value });
    },
    [formData],
  );

  const handleOptOutInputCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOptOut(event.target.checked);
    },
    [],
  );

  const createContactBlacklist = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_MARKETING_URL}/redlist/form`,
        {
          contact_link: formData.contactLink,
          phone: formData.phone,
          email: formData.email,
          reason: formData.reason,
          opt_out: optOut,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      on_request_close();
      toast.success('Contato excluído com sucesso.');
    } catch (error) {
      Swal.fire(
        String(error.response.status),
        error.response.data.message,
        'error',
      );
    }
  }, [formData, on_request_close, optOut]);

  return (
    <Modal
      isOpen={is_open}
      onRequestClose={closeModal}
      style={ModalSchedule}
      closeTimeoutMS={500}
    >
      <Toaster position="top-right" reverseOrder={false} />
      <Container isClose={!is_open}>
        <ContainerClose>
          <Button onClick={closeModal}>
            <Image src={blacklistImg} alt="Fechar modal" />
          </Button>
        </ContainerClose>

        <ContainerContent>
          <ModalHeader>
            <ContainerTitle>
              Exclusão de contato <Divisor />
            </ContainerTitle>
            <Button onClick={closeModal}>
              <Image src={closeModalImg} alt="Fechar modal" />
            </Button>
          </ModalHeader>

          <ModalBody>
            <Label>Link credor - contato</Label>
            <Input
              name="contactLink"
              onChange={handleInputChange}
              className="contact-link"
            />

            <Label>
              Motivo <Contrast>*</Contrast>
            </Label>
            <ContainerCheckbox>
              <ContainerInputCheckbox>
                <InputCheckbox
                  type="radio"
                  id="unknown"
                  name="reason"
                  value="Não é o credor – desconhecido"
                  onChange={handleReasonInputCheckboxChange}
                />
                <LabelCheckbox htmlFor="unknown">
                  Não é o credor – desconhecido
                </LabelCheckbox>
              </ContainerInputCheckbox>
              <ContainerInputCheckbox>
                <InputCheckbox
                  type="radio"
                  id="beam"
                  name="reason"
                  value="Não é o credor – trave"
                  onChange={handleReasonInputCheckboxChange}
                />
                <LabelCheckbox htmlFor="beam">
                  Não é o credor – trave
                </LabelCheckbox>
              </ContainerInputCheckbox>
              <ContainerInputCheckbox>
                <InputCheckbox
                  type="radio"
                  id="non-decision-maker"
                  name="reason"
                  value="É o credor - mas não é o tomador de decisão"
                  onChange={handleReasonInputCheckboxChange}
                />
                <LabelCheckbox htmlFor="non-decision-maker">
                  É o credor - mas não é o tomador de decisão
                </LabelCheckbox>
              </ContainerInputCheckbox>

              <ContainerInputCheckbox>
                <InputCheckbox
                  type="radio"
                  id="creditor-requested"
                  name="reason"
                  value="Credor solicitou"
                  onChange={handleReasonInputCheckboxChange}
                />
                <LabelCheckbox htmlFor="creditor-requested">
                  Credor solicitou
                </LabelCheckbox>
              </ContainerInputCheckbox>

              <ContainerInputCheckbox>
                <InputCheckbox
                  type="radio"
                  id="opt_out_swearing"
                  name="reason"
                  value="OPT-OUT - Xingamentos"
                  onChange={handleReasonInputCheckboxChange}
                />
                <LabelCheckbox htmlFor="opt_out_swearing">
                  OPT-OUT - Xingamentos
                </LabelCheckbox>
              </ContainerInputCheckbox>
            </ContainerCheckbox>

            <Label>Número telefone a ser excluído</Label>
            <Input name="phone" type="text" onChange={handleInputChange} />

            <Label>E-mail a ser excluído</Label>
            <Input name="email" onChange={handleInputChange} />

            <ContainerCheckbox>
              <ContainerInputCheckbox>
                <InputCheckbox
                  type="checkbox"
                  id="opt-out"
                  name="opt-out"
                  defaultChecked
                  onChange={handleOptOutInputCheckboxChange}
                />
                <LabelCheckbox htmlFor="opt-out">
                  Excluir de todos os canais
                </LabelCheckbox>
              </ContainerInputCheckbox>
            </ContainerCheckbox>

            <DeleteButton onClick={createContactBlacklist}>
              Excluir contato
            </DeleteButton>
          </ModalBody>
        </ContainerContent>
      </Container>
    </Modal>
  );
};
