import { format } from 'date-fns';
import { useCallback } from 'react';

import {
  TableContainer,
  RowContainerProps,
  RowContainer,
  BodyContainer,
  ColumnBodyContainer,
} from './styles';
import { HubspotLink } from '../../../../HunterPanelSla/TableHunterSLA/styles';

export interface IFieldsData {
  [key: string]: any;
}

export interface IFields {
  label: string;
  field: string;
  clickable: boolean;
}

interface IRequest {
  fields: IFields[];
  datas: IFieldsData[];
  onClickList: (id: string) => void;
}

export const ListCasesComponent: React.FC<IRequest> = ({
  fields,
  datas,
  onClickList,
}) => {
  return (
    <TableContainer>
      <BodyContainer>
        {datas.map(data => (
          <RowContainerProps className="row-props-body" priority="Teste">
            <RowContainer className="body" key="1">
              {fields.map(field =>
                field.label.includes('ticket') ? (
                  <ColumnBodyContainer>
                    <HubspotLink
                      href={`https://app.hubspot.com/contacts/7857327/record/0-5/${
                        data[`${field.label}`]
                      }`}
                      target="_blank"
                    >
                      <p>{field.field}</p>
                      {data[`${field.label}`]}
                    </HubspotLink>
                  </ColumnBodyContainer>
                ) : field.clickable && !field.label.includes('_date') ? (
                  <ColumnBodyContainer
                    onClick={() => {
                      onClickList(data.id);
                    }}
                  >
                    <p>{field.field}</p>
                    {data[`${field.label}`]}
                  </ColumnBodyContainer>
                ) : field.label.includes('_date') ? (
                  <ColumnBodyContainer>
                    <p>{field.field}</p>
                    {format(new Date(data[`${field.label}`]), 'dd/MM/yyyy')}
                  </ColumnBodyContainer>
                ) : (
                  <ColumnBodyContainer>
                    <p>{field.field}</p>
                    {data[`${field.label}`]}
                  </ColumnBodyContainer>
                ),
              )}
            </RowContainer>
          </RowContainerProps>
        ))}
      </BodyContainer>
    </TableContainer>
  );
};
