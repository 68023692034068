import { useCallback, useEffect, useState } from 'react';
import {
  IFieldsData,
  ListCasesComponent,
} from '../components/ListCasesComponent';
import { Main } from '../styles';
import {
  ILastSla,
  INutritionCaseModal,
  NutritionActivityModal,
} from '../components/NutritionActivityModal';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

interface INutritionProps {
  hunterId: string;
}

export const NutriticionCases: React.FC<INutritionProps> = ({
  hunterId,
}: INutritionProps) => {
  const { user } = useAuth();

  const [nutritionCases, setNutritionCases] = useState<IFieldsData[]>([]);

  const [currentNutrition, setCurrentNutrition] = useState<INutritionCaseModal>(
    {} as INutritionCaseModal,
  );
  const [openModalNutrition, setOpenModalNutrition] = useState<boolean>(false);

  const fields = [
    { field: 'Data N', label: 'conection_date', clickable: true },
    { field: 'Valor', label: 'deal_value', clickable: true },
    { field: 'Nome', label: 'deal_name', clickable: true },
    { field: 'Etapa', label: 'stage', clickable: true },
    { field: 'Link do hubspot', label: 'hubspot_ticket_id', clickable: true },
  ];

  useEffect(() => {
    api
      .get(`schedule/create-hunter-priority?user_id=${hunterId}`)
      .then(response => {
        setNutritionCases(response.data);
      });
  }, [hunterId]);

  const handleOpenModalNutrition = useCallback(
    (id: string) => {
      const foundCurrentNutrition = nutritionCases.find(
        nutrition => nutrition.id === id,
      );

      setCurrentNutrition({
        id,
        name: foundCurrentNutrition.name,
        hunter_sla: foundCurrentNutrition.hunter_sla,
        status: foundCurrentNutrition.status,
        spoken: foundCurrentNutrition.spoken,
        try_call: foundCurrentNutrition.try_call,
        try_message: foundCurrentNutrition.try_message,
        conection_date: foundCurrentNutrition.conection_date,
        deal_value: foundCurrentNutrition.deal_value,
        hubspot_ticket_id: foundCurrentNutrition.hubspot_ticket_id,
        stage: foundCurrentNutrition.stage,
        deal_name: foundCurrentNutrition.deal_name,
        observation: foundCurrentNutrition.observation,
      });

      setOpenModalNutrition(true);
    },
    [nutritionCases],
  );

  const handleCloseModalNutrition = useCallback(() => {
    setOpenModalNutrition(false);
    api
      .get(`schedule/create-hunter-priority?user_id=${user.id}`)
      .then(response => {
        setNutritionCases(response.data);
      });
  }, [user.id]);

  return (
    <Main>
      {nutritionCases && nutritionCases.length > 0 ? (
        <ListCasesComponent
          datas={nutritionCases}
          fields={fields}
          onClickList={handleOpenModalNutrition}
        />
      ) : (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <p>Nenhuma nutrição encontrada</p>
        </div>
      )}
      <NutritionActivityModal
        isOpen={openModalNutrition}
        nutrition={currentNutrition}
        onRequestClose={handleCloseModalNutrition}
      />
    </Main>
  );
};
