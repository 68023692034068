import styled from 'styled-components';
import { shade } from 'polished';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.875rem;
`;

export const SearchContaner = styled.div`
  width: 26rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #d8d8d8;
  border-radius: 1.875rem;

  img {
    width: 1rem;
    height: 1rem;

    margin-right: 0.938rem;
  }
`;

export const InputSearch = styled.input`
  width: 24rem;
  height: 100%;

  background: #d8d8d8;
  border-radius: 1.875rem 0 0 1.875rem;
  padding-left: 1rem;
  border: none;

  font-size: 0.938rem;
  &::placeholder {
    color: #a8a8b3;
  }
`;

export const ButtonNewTeam = styled.button`
  width: 8rem;
  height: 2rem;
  color: #ffffff;
  border: none;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;

  background: #089b63;
  border-radius: 0.25rem;
`;

export const Table = styled.table`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Thead = styled.thead`
  width: 100%;
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #cfdef3 83%;

    border-radius: 0.25rem;
    margin-bottom: 0.688rem;

    height: 2.5rem;
  }

  th:nth-child(1) {
    .icon-head {
      width: 4%;
    }
    span {
      width: 96%;
      padding-right: 4%;
    }
  }

  th:nth-child(2) {
    .icon-head {
      width: 4%;
    }
    span {
      width: 96%;
      padding-right: 4%;
    }
  }

  th {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    color: #05233e;

    border-right: 0.063rem solid rgba(6, 57, 102, 0.64);

    &:nth-child(2) {
      border-right: 0;
    }
  }
`;

export const IconForm = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerTitle = styled.span`
  width: 100%;
`;

export const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  tr:nth-child(even) {
    background: #efefef;
  }

  tr:nth-child(odd) {
    background: #e5e5e5;
  }

  tr {
    display: flex;

    justify-content: center;
    align-items: center;

    cursor: pointer;
    border-radius: 0.25rem;
    height: 3.5rem;
    background: #e5e5e5;
    &:hover {
      background: ${shade(0.2, '#E5E5E5')};
    }

    td:nth-child(1) {
      width: 50%;
    }

    td:nth-child(2) {
      width: 50%;

      display: flex;
      justify-content: flex-end;

      span {
        width: 90%;
        padding-left: 10%;
        text-align: center;
      }

      .icon-body {
        width: 10%;
        display: flex;
        justify-content: center;

        gap: 0.375rem;
      }
    }
  }

  td {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Poppins';
    font-weight: 300;
    font-size: 0.938rem;
    text-align: center;

    color: #05233e;
  }
`;

export const ButtonDelete = styled.button`
  border: transparent;
  border-radius: 0.3rem;

  background-color: #777777;
  width: 1.438rem;
  height: 1.375rem;

  color: #ffffff;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.2, '#777777')};
  }
`;

export const ButtonEdit = styled.button`
  border: transparent;
  border-radius: 0.3rem;

  background-color: #063966;
  width: 1.438rem;
  height: 1.375rem;

  color: #ffffff;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.2, '#063966')};
  }
`;

export const ContainerExport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem;
  margin-bottom: 2rem;
  @media print {
    display: none;
  }
  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

export const Export = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;

  font-family: Poppins, sans-serif;
  font-size: 0.813rem;
  display: flex;
  align-items: center;
  text-align: center;

  color: #05233e;

  @media print {
    display: none;
  }
`;
