import styled from 'styled-components';
import { shade } from 'polished';

export const ModalContent = {
  content: {
    background: '#ffffff',
    width: '35.875rem',
    height: '28rem',
    border_radius: '.625rem',
    margin: 'auto',
  },
};

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 100%;
`;

export const Title = styled.h1`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  font-family: Poppins, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;

  text-align: center;

  color: var(--blue-800);
`;

export const TransferButton = styled.button`
  width: 12rem;
  height: 2.0525rem;
  color: #ffffff;
  border: none;

  padding: 0.7rem;

  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;

  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;

  background: var(--blue-400);
  border-radius: 0.5rem;

  margin-top: 1rem;

  color: #ffffff;
  transition: background-color 0.4s;
  &:hover {
    background: ${shade(0.3, '#05233e')};
  }

  @media (max-width: 930px) {
    margin-top: 1.8rem;
  }
`;

export const ButtonsConnectionContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  margin: 1.5rem 0;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1.875rem;

  width: 19.5rem;

  display: flex;
  flex-direction: column;

  background: #ffffff;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 1.875rem;

    background: #efefef;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    margin-bottom: 0.875rem;

    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    color: #05233e;
  }
`;

export const CloseModalButton = styled.button`
  background-color: transparent;
  color: #1665d8;
  border: 0;
  width: 2%;
  img {
    width: 0.938rem;
  }
`;
export const CloseModalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;
  margin-right: -1rem;
`;
export const ModalInput = styled.input`
  width: 19.8125rem;
  height: 1.7rem;

  padding-left: 1rem;

  background: #efefef;
  border-radius: 4px;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;

  color: #817f7f;

  border: none;
`;

export const ModalField = styled.div`
  display: flex;

  justify-content: space-around;

  margin-bottom: 0.875rem;

  width: 100%;

  .inputLink {
    cursor: pointer;
    color: #368eda;
    font-weight: 500;
    font-size: 0.6875rem;
  }
`;

export const ModalLabel = styled.label`
  width: 8.4rem;
  height: 1.7rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  line-height: 1.5rem;
  display: flex;
  align-items: center;

  letter-spacing: -0.015rem;

  padding: 0.2rem;
  align-items: center;
  justify-content: center;

  background: #cfdef3;
  border-radius: 4px;

  color: #05233e;
`;
