import { useState, useCallback, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { GuardSpinner } from 'react-spinners-kit';
import { Body } from '../../../components/Styles/Body';
import { Container } from '../../../components/Container';
import { InputDate } from '../../../components/InputDate';
import {
  DivSeparator,
  ImageBox,
  Separator,
  InputDateContainer,
  DivSpace,
} from './style';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import apiChatbot from '../../../services/apiChatbot';
import { Title } from '../../../components/Styles/Title';

export function ChatbotFlow(): JSX.Element {
  const [startAt, setStartAt] = useState<null | Date>(null);
  const [finishedAt, setFinishedAt] = useState<null | Date>(null);
  const [filePath, setFilePath] = useState<string>('');
  const [imageIsLoaded, setImageIsLoaded] = useState<boolean>(true);

  const setFilePathInUrl = async (fileName: string) => {
    return `http://localhost:4001/zendesk/attendance/image/${fileName}`;
  };

  useEffect(() => {
    if (!startAt || !finishedAt || filePath === '') {
      setImageIsLoaded(true);
    }
    if (filePath !== '') {
      setImageIsLoaded(false);
    }
  }, [startAt, finishedAt, filePath]);

  const getFlowchart = async () => {
    if (!startAt || !finishedAt) {
      toast.error('Data de início e a Data final são necessárias!');
    } else {
      try {
        const start_at = startAt.toISOString().slice(0, 10);
        const finished_at = finishedAt.toISOString().slice(0, 10);

        const api = apiChatbot(localStorage.getItem('@Precato:token'));
        await api
          .get(
            `/zendesk/attendance/flowChart/?start_at=${start_at}&finished_at=${finished_at}`,
          )
          .then(async response => {
            if (response.data) {
              setFilePath(await setFilePathInUrl(response.data));
              setImageIsLoaded(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Container>
        <Navegation>
          <H1Navigation>Conexão {'>'} </H1Navigation>
          <H1NavigationNow>Chatbot Flow</H1NavigationNow>
        </Navegation>
        <Body>
          <Toaster position="top-right" reverseOrder={false} />
          <Title>Chatbot Flow</Title>
          <DivSeparator>
            <Separator />
          </DivSeparator>
          <InputDateContainer>
            <DivSpace>
              <InputDate date={startAt} setDate={setStartAt} />
            </DivSpace>
            <DivSpace>
              <InputDate date={finishedAt} setDate={setFinishedAt} />
            </DivSpace>

            <button
              type="button"
              className="sendButton"
              onClick={() => {
                getFlowchart();
              }}
            >
              Gerar Relatório
            </button>
          </InputDateContainer>
          <ImageBox>
            {imageIsLoaded ? (
              <GuardSpinner
                size={50}
                backColor="#05233e"
                frontColor="#1665d8"
                loading={imageIsLoaded}
              />
            ) : (
              <img src={`${filePath}`} alt="Chatbot Flow" />
            )}
          </ImageBox>
        </Body>
      </Container>
    </>
  );
}
