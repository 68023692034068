import styled, { css } from 'styled-components';

interface TbodyProps {
  isSelected: string;
}

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const TableContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  tbody:nth-child(odd) {
    background: var(--gray-50);
  }

  tbody:nth-child(even) {
    background: var(--gray-100);
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 13.14% 24% 13.14% 16% 8.16% 11.28% 14.28%;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  min-width: 70rem;

  @media (min-width: 1500px) {
    min-width: 70vw;
  }
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  color: var(--blue-700);

  border-right: 0.01rem solid var(--blue-700);

  &:nth-child(7) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  min-width: 70rem;
  margin-top: 0.3rem;

  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }

  .tr-body {
    border-radius: 0.25rem;
  }

  @media (min-width: 1500px) {
    min-width: 70vw;
  }
`;

export const BodyCell = styled.td<TbodyProps>`
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;
  height: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 2rem;

  ${props =>
    props.isSelected === 'Calculo Pronto'
      ? css`
          background-color: #3ca64766;
        `
      : ''}
`;

export const HubspotLink = styled.a`
  color: #368eda;
  font-size: 0.875rem;
`;

export const Select = styled.select`
  width: 9.25rem;
  height: 1.5rem;
  margin-top: 2rem;
  border: none;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;

  margin-bottom: 2rem;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;

  :disabled {
    display: none;
  }
`;
