import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  width: 100%;

  padding: 1.25rem;
  height: 4.5rem;

  background: linear-gradient(
    117.57deg,
    rgba(5, 37, 69, 0.71) 0%,
    rgba(71, 151, 229, 0.788632) 78.42%,
    rgba(81, 167, 252, 0.8) 97.92%,
    rgba(81, 167, 252, 0.8) 97.92%
  );
  background-blend-mode: multiply;

  @media (max-width: 1100px) {
    position: fixed;
  }
  @media print {
    display: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 6.81rem;
  }

  @media (max-width: 1100px) {
    width: 4rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`;

export const ModalForms = styled.div`
  position: absolute;
`;

export const Notifications = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-left: 1.8rem;
    &:hover {
      cursor: pointer;
      color: ${shade(0.1, '#FFF')};
    }
  }

  img {
    margin-left: 1.8rem;

    &:hover {
      cursor: pointer;
      color: ${shade(0.1, '#FFF')};
    }
  }

  @media (max-width: 1100px) {
    svg {
      margin-left: 0.6rem;
    }
  }

  .mail {
    color: #fff;
    height: 35px;
    width: 15px;
  }

  .notification {
    color: #fff;
    height: 35px;
    width: 31px;
  }
`;

export const Divisor = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  border: solid 1px #817f7f;
  margin-left: 2.25rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.45rem;

  @media (max-width: 1100px) {
    margin-left: 1.5rem;
  }

  img {
    width: 3rem;
    height: 3rem;
  }

  span {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;

    margin-right: 2rem;
    margin-left: 1rem;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  img {
    width: 3rem;
    border-radius: 50%;
    margin-right: 2.5rem;

    cursor: pointer;

    @media (max-width: 1100px) {
      width: 1.5rem;
    }
  }

  @media (max-width: 1100px) {
    img {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`;

export const Exit = styled.div`
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: #fff;
  }

  span {
    font: 'Poppins', sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    color: #fff;
  }
`;
