import { useState } from 'react';

import { ReactComponent as BoxTimeIcon } from '../../../../assets/icons/box_time.svg';
import { ReactComponent as CalendarSelectedIcon } from '../../../../assets/icons/calendar_selected.svg';
import { ReactComponent as ListSelectedIcon } from '../../../../assets/icons/list_selected.svg';
import { ReactComponent as CalendarNotSelectedIcon } from '../../../../assets/icons/calendar_not_selected.svg';
import { ReactComponent as ListNotSelectedIcon } from '../../../../assets/icons/list_not_selected.svg';

import { VisualizationCalendar } from './VisualizationCalendar';

import {
  Content,
  ScheduleLine,
  WatchIconContent,
  LineContent,
  DateContainer,
  ButtonsContainer,
  VisualizationButton,
} from './styles';

import { VisualizationList } from './VisualizationList';
import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';

interface ScheduleComponentProps {
  date: Date;
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (activity: IActivitiesInADayDTO) => void;
}

export function ScheduleComponent({
  date,
  activitiesInADay,
  setActivitiesInADay,
}: ScheduleComponentProps): JSX.Element {
  const [selectedVisualization, setSelectedVisualization] =
    useState<string>('calendar');

  const weekdays = [
    'DOMINGO',
    'SEGUNDA',
    'TERÇA',
    'QUARTA',
    'QUINTA',
    'SEXTA',
    'SÁBADO',
  ];

  return (
    <Content>
      <ScheduleLine>
        <WatchIconContent>
          <BoxTimeIcon />
        </WatchIconContent>

        <LineContent>
          <div id="empty" />
          <DateContainer>
            <p id="title">{date.getDate()}</p>
            <p id="subtitle">{weekdays[date.getDay()]}</p>
          </DateContainer>

          <ButtonsContainer>
            <VisualizationButton
              type="button"
              onClick={() => setSelectedVisualization('calendar')}
            >
              {selectedVisualization === 'calendar' ? (
                <CalendarSelectedIcon />
              ) : (
                <CalendarNotSelectedIcon />
              )}
            </VisualizationButton>

            <VisualizationButton
              type="button"
              onClick={() => setSelectedVisualization('listing')}
            >
              {selectedVisualization === 'listing' ? (
                <ListSelectedIcon />
              ) : (
                <ListNotSelectedIcon />
              )}
            </VisualizationButton>
          </ButtonsContainer>
        </LineContent>
      </ScheduleLine>

      {selectedVisualization === 'calendar' ? (
        <VisualizationCalendar
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
        />
      ) : (
        <VisualizationList
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
        />
      )}
    </Content>
  );
}
