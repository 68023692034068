import {
  Checkbox,
  CheckboxContainer,
  LabelCheckbox,
} from '../../../../Hunter/SuccessfulModal/styles';
import { InputContainer, Label } from '../../styles';

interface ISelectSLAProps {
  title: string;
  profile: string[];
  handleChange: (e: any) => void;
  options: { label: string; amount: number }[];
}

export function CheckBoxSLA({
  title,
  profile,
  handleChange,
  options,
}: ISelectSLAProps): JSX.Element {
  return (
    <InputContainer>
      <Label>{title}</Label>

      {options.map(option => (
        <CheckboxContainer key={option.label}>
          <Checkbox
            key={option.label}
            type="checkbox"
            name={option.label}
            onChange={handleChange}
            checked={
              !!profile.find(foundProfile => foundProfile === option.label)
            }
          />
          <LabelCheckbox>{option.label}</LabelCheckbox>
        </CheckboxContainer>
      ))}
    </InputContainer>
  );
}
