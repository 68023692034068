import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';

import { Login } from '../pages/Login';
import { ForgotPassword } from '../pages/Login/ForgotPassword';
import { ResetPassword } from '../pages/Login/ResetPassword';
import { Profiles } from '../pages/Profiles';
import { EditProfile } from '../pages/Profiles/EditProfile';
import { CreateProfile } from '../pages/Profiles/CreateProfile';
import TeamList from '../pages/Team';
import CreateTeam from '../pages/Team/CreateTeam';
import EditTeam from '../pages/Team/EditTeam';
import { Users } from '../pages/Users';
import Permissions from '../pages/Permissions';
import { CreateUsers } from '../pages/Users/CreateUser';
import CreatePermissions from '../pages/Permissions/CreatePermission';
import { EditUsers } from '../pages/Users/EditUsers';
import EditPermissions from '../pages/Permissions/EditPermission';
import Dashboard from '../pages/Dashboard/index';
import Calculator from '../pages/Calculator';
import { ScheduleHunter } from '../pages/Schedule/Hunter';
import CreateSchedule from '../pages/Schedule/Hunter/CreateSchedule';
import ScheduleCloser from '../pages/Schedule/Closer';
import { ManagerResumePainel } from '../pages/Schedule/CustomerSuccess/ScheduleListing/ManagerResumePainel';
import { History } from '../pages/Schedule/Manager/History';
import { IndividualCloserHistory } from '../pages/Schedule/Manager/ClosersHistory/IndividualCloserHistory';
import { IndividualHunterHistory } from '../pages/Schedule/Manager/HuntersHistory/IndividualHunterHistory';
import { ListingClosedDeals } from '../pages/Schedule/Manager/History/ListingClosedDeals';
import { Assignors } from '../pages/Vortx/Assignors';
import { CreateLiquidation } from '../pages/Vortx/Liquidations/CreateLiquidation';
import { CreateDebitor } from '../pages/Vortx/Debitors/CreateDebitors';
import { Liquidations } from '../pages/Vortx/Liquidations';
import { Debitors } from '../pages/Vortx/Debitors';
import { EditLiquidation } from '../pages/Vortx/Liquidations/EditLiquidation';
import { Payments } from '../pages/Vortx/Payments';
import { CreatePayment } from '../pages/Vortx/Payments/CreatePayment';
import Profile from '../pages/Profile';
import { TJS } from '../pages/Leads/TJS';
import { TRFS } from '../pages/Leads/TRFS';
import ProcessesTRF from '../pages/Leads/ProcessesTRF';
import { SearchProcesses } from '../pages/Leads/SearchProcesses';
import { TakeBlip } from '../pages/Connection/TakeBlip';
import { SmsReturn } from '../pages/Connection/SmsReturn';
import { ListAttendancesByAgent } from '../pages/Connection/ListAttendancesByAgent';
import { CloserSettings } from '../pages/CloserSettings';
import { Queues } from '../pages/Queues';
import { OptIn } from '../pages/Connection/OptIn';
import { Report } from '../pages/Connection/Report';
import { CallsAudit } from '../pages/Connection/CallsAudit';
import { Performance } from '../pages/Connection/Performance';
import { LawyersProcesses } from '../pages/Connection/LawyersProcesses';
import { StateDebit } from '../pages/Connection/StateDebit';
import { PanelSla } from '../pages/PanelSla';
import { ReportCloser } from '../pages/ReportCloser';
import { CSManagerComponent } from '../pages/Schedule/CustomerSuccess/CSManagerComponent';
import { ShotWpp } from '../pages/Connection/ShotWpp';
import { TjsOc } from '../pages/Leads/TjsOc';
import { Enrichment } from '../pages/Connection/Enrichment/index';
import { SmsTrigger } from '../pages/Connection/SmsTrigger';
import { CasePriorization } from '../pages/CasePriorization';
import { HunterPanelSla } from '../pages/HunterPanelSla';
import { WorkTime } from '../pages/Connection/WorkTime';
import { WppConfig } from '../pages/Connection/WppConfig';
import ScheduleListing from '../pages/ScheduleListing';
import { EntitiesPricification } from '../pages/EntitiesPricification';
import ManagerCreateSchedule from '../pages/Schedule/Manager/CreateScheduling';
import { ChatbotFlow } from '../pages/Connection/ChatbotFlow';
import { SecondFormPage } from '../pages/EntitiesPricification/PricingForms/SecondFormPage';
import PricingForms from '../pages/EntitiesPricification/PricingForms';
import { CustomerSuccessScheduleListing } from '../pages/Schedule/CustomerSuccess/ScheduleListing';
import { CustomerSuccess } from '../pages/Schedule/CustomerSuccess';
import { Assignments } from '../pages/Assignments';
import { RCSTrigger } from '../pages/Connection/RCSTrigger';
import { useAuth } from '../hooks/auth';
import CalculatorClient from '../pages/CalculatorClient';

const Routes: React.FC = () => {
  const { user } = useAuth();

  const isManager =
    user &&
    (user.profile.includes('Administrador') ||
      user.profile.includes('Gestor') ||
      user.profile.includes('Liderança') ||
      user.profile.includes('Gestao') ||
      user.profile.includes('Líder'));

  const isCs = user && user.profile.includes('Customer');

  const isFullManager =
    user &&
    (user.profile.includes('Administrador') || user.profile.includes('Gestor'));

  const isRoot = user && user.profile.includes('Administrador');

  const isPayment =
    user &&
    (user.profile.includes('Administrador') ||
      user.profile.includes('Vortx Debitors'));

  const isBroker = user && user.profile.includes('Calculadora broker');

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/connection/opt-in" exact component={OptIn} isPrivate />
        <Route path="/connection/report" exact component={Report} isPrivate />
        <Route path="/login/reset-password" exact component={ForgotPassword} />
        <Route path="/login/new-password" exact component={ResetPassword} />
        <Route path="/dashboard" exact component={Dashboard} isPrivate />
        <Route path="/profile" exact component={Profile} isPrivate />
        <Route
          path="/administration/profiles"
          exact
          component={Profiles}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/profiles/new"
          exact
          component={CreateProfile}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/profiles/edit"
          exact
          component={EditProfile}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/teams"
          exact
          component={TeamList}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/teams/new"
          exact
          component={CreateTeam}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/teams/edit"
          exact
          component={EditTeam}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/users"
          exact
          component={Users}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/users/new"
          exact
          component={CreateUsers}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/users/edit"
          exact
          component={EditUsers}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/permissions"
          exact
          component={Permissions}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/permissions/new"
          exact
          component={CreatePermissions}
          isPrivate={isRoot}
        />
        <Route
          path="/administration/permissions/edit"
          exact
          component={EditPermissions}
          isPrivate={isRoot}
        />
        <Route
          path="/negociations/calculator"
          exact
          component={Calculator}
          isPrivate
        />
        <Route
          path="/negociations/calculator-client-broker"
          exact
          component={CalculatorClient}
          isPrivate={isBroker || isRoot}
        />
        <Route
          path="/negociations/schedule-listing"
          exact
          component={ScheduleListing}
          isPrivate
        />

        <Route
          path="/negociations/panel-sla"
          exact
          component={PanelSla}
          isPrivate
        />
        <Route
          path="/negociations/report-closer"
          exact
          component={ReportCloser}
          isPrivate
        />

        <Route
          path="/negociations/schedule/hunter"
          exact
          component={ScheduleHunter}
          isPrivate
        />
        <Route
          path="/payments/schedule/manager/customer-sucess"
          exact
          component={CSManagerComponent}
          isPrivate={isFullManager}
        />

        <Route
          path="/payments/schedule/customer-sucess/schedule-cases-listing"
          exact
          component={CustomerSuccessScheduleListing}
          isPrivate
        />
        <Route
          path="/negociations/schedule/hunter/create"
          exact
          component={CreateSchedule}
          isPrivate
        />
        <Route
          path="/negociations/schedule/manager/create-scheduling"
          exact
          component={ManagerCreateSchedule}
          isPrivate={isManager}
        />
        <Route
          path="/negociations/schedule/closer"
          exact
          component={ScheduleCloser}
          isPrivate
        />

        <Route
          path="/payments/schedule/customer-success"
          exact
          component={CustomerSuccess}
          isPrivate
        />
        <Route
          path="/payments/schedule/manager-resume-painel"
          exact
          component={ManagerResumePainel}
          isPrivate={isManager || isCs}
        />
        <Route
          path="/payments/priorization"
          exact
          component={CasePriorization}
          isPrivate
        />

        <Route
          path="/negociations/schedule/manager/history"
          exact
          component={History}
          isPrivate={isManager}
        />

        <Route
          path="/negociations/schedule/manager/hunter-history/individual"
          exact
          component={IndividualHunterHistory}
          isPrivate={isManager}
        />

        <Route
          path="/negociations/schedule/manager/closer-history/individual"
          exact
          component={IndividualCloserHistory}
          isPrivate={isManager}
        />

        <Route
          path="/negociations/schedule/manager/history/closed-deals"
          exact
          component={ListingClosedDeals}
          isPrivate={isManager}
        />

        <Route path="/leads/tjs" exact component={TJS} isPrivate />
        <Route path="/leads/tjs-oc" exact component={TjsOc} isPrivate />
        <Route path="/leads/trfs" exact component={TRFS} isPrivate />
        <Route
          path="/leads/processes"
          exact
          component={ProcessesTRF}
          isPrivate
        />
        <Route
          path="/leads/search-processes"
          exact
          component={SearchProcesses}
          isPrivate
        />
        <Route path="/vortx/assignors" exact component={Assignors} isPrivate />
        <Route
          path="/vortx/liquidations"
          exact
          component={Liquidations}
          isPrivate={isPayment}
        />
        <Route
          path="/vortx/liquidations/create"
          exact
          component={CreateLiquidation}
          isPrivate={isPayment}
        />
        <Route
          path="/vortx/debitors"
          exact
          component={Debitors}
          isPrivate={isPayment}
        />
        <Route
          path="/vortx/debitors/create"
          exact
          component={CreateDebitor}
          isPrivate={isPayment}
        />
        <Route
          path="/vortx/liquidations/edit"
          exact
          component={EditLiquidation}
          isPrivate={isPayment}
        />

        <Route
          path="/vortx/payments"
          exact
          component={Payments}
          isPrivate={isPayment}
        />
        <Route
          path="/vortx/payments/create"
          exact
          component={CreatePayment}
          isPrivate={isPayment}
        />
        <Route path="/assignments" exact component={Assignments} isPrivate />
        <Route
          path="/pricing"
          exact
          component={EntitiesPricification}
          isPrivate
        />
        <Route path="/pricing/state" exact component={PricingForms} isPrivate />
        <Route
          path="/pricing/state/page"
          exact
          component={SecondFormPage}
          isPrivate
        />

        <Route
          path="/connection/take-blip"
          exact
          component={TakeBlip}
          isPrivate
        />

        <Route
          path="/connection/take-blip/attendances"
          exact
          component={ListAttendancesByAgent}
          isPrivate
        />

        <Route
          path="/connection/attendances/chatbot-flow"
          exact
          component={ChatbotFlow}
          isPrivate
        />

        <Route
          path="/connection/sms-returns"
          exact
          component={SmsReturn}
          isPrivate
        />

        <Route
          path="/connection/performance"
          exact
          component={Performance}
          isPrivate
        />

        <Route
          path="/connection/shot-wpp"
          exact
          component={ShotWpp}
          isPrivate
        />

        <Route
          path="/connection/rcs-trigger"
          exact
          component={RCSTrigger}
          isPrivate
        />

        <Route
          path="/connection/enrichment"
          exact
          component={Enrichment}
          isPrivate
        />

        <Route
          path="/connection/calls-audit"
          exact
          component={CallsAudit}
          isPrivate
        />

        <Route
          path="/connection/panel-sla-hunter"
          exact
          component={HunterPanelSla}
          isPrivate
        />

        <Route
          path="/newsletter/lawyers-processes"
          exact
          component={LawyersProcesses}
          isPrivate
        />

        <Route
          path="/administration/closer-settings"
          exact
          component={CloserSettings}
          isPrivate
        />

        <Route
          path="/administration/closer-queues"
          exact
          component={Queues}
          isPrivate
        />

        <Route
          path="/newsletter/state-debit"
          exact
          component={StateDebit}
          isPrivate
        />

        <Route
          path="/connection/sms-trigger"
          exact
          component={SmsTrigger}
          isPrivate
        />

        <Route
          path="/connection/work-time"
          exact
          component={WorkTime}
          isPrivate
        />

        <Route
          path="/connection/wpp-config"
          exact
          component={WppConfig}
          isPrivate
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
