import styled, { css } from 'styled-components';

interface IProps {
  isPermission: boolean;
}

export const Container = styled.div<IProps>`
  display: block;
  position: relative;

  padding-bottom: 0.5rem;
  padding-left: 1rem;

  align-items: center;
  margin-bottom: 0.5rem;

  border-radius: 0.25rem;

  margin-right: 2.313rem;

  input:checked ~ .checkmark {
    background: var(--white);
    background-color: var(--blue-300);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  @media (max-width: 760px) {
    margin-bottom: 0;
    margin-top: 1rem;
    padding: 0.3rem;
    padding-bottom: 0;
  }

  ${props =>
    props.isPermission &&
    css`
      margin-left: 1rem;
      margin-right: 0;

      @media (max-width: 760px) {
        margin-top: 0;
        margin-left: 0;
      }
    `}
`;

export const Input = styled.input`
  all: unset;
  background: transparent;
  position: absolute;
  z-index: 1;
  margin-left: -0.5rem;
  margin-top: 0.3rem;
  cursor: pointer;
  width: 0.8rem;
  height: 0.8rem;

  @media (max-width: 1290px) {
    padding: 0.3rem;
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 0.938rem;
  height: 0.938rem;

  background-color: var(--white);
  border-radius: 0.25rem;
  border: 0.125rem solid var(--blue-300);
  padding: 0.1rem;

  margin-left: 0.4rem;
  margin-top: 0.2rem;

  :after {
    content: '';
    position: absolute;
    display: none;
    width: 0.249rem;
    height: 0.363rem;
    margin-top: -0.1rem;
    margin-left: 0.05rem;
    border: 0.125rem solid var(--white);
    border-width: 0 0.125rem 0.125rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Text = styled.h1<IProps>`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.938rem;

  padding-left: 0.5rem;

  color: var(--blue-800);

  ${props =>
    props.isPermission &&
    css`
      font-weight: 500;
    `}

  @media (max-width: 1100px) {
    margin-left: 1rem;
    margin-top: -0.4rem;
  }
`;
