import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CloserDTO } from '../dtos/CloserDTO';
import { CloserWorkHoursDTO } from '../dtos/CloserWorkHoursDTO';
import { FormCloserDTO } from '../dtos/FormCloserDTO';
import {
  FormGroup,
  InputLabelContainer,
  Label,
  Input,
  RowInputContainer,
  TimeInput,
} from './styles';

interface CloserFormGroupProps {
  closers: CloserDTO[];
  formCloserData: FormCloserDTO;
  closerSelected: CloserDTO;
  closerWorkHours: CloserWorkHoursDTO;

  setFormCloserData: (formCloser: FormCloserDTO) => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function CloserFormGroup({
  closers,
  formCloserData,
  closerSelected,
  closerWorkHours,
  setFormCloserData,
  handleInputChange,
}: CloserFormGroupProps): JSX.Element {
  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;

      setFormCloserData({ ...formCloserData, [name]: value });
    },
    [formCloserData, setFormCloserData],
  );

  return (
    <FormGroup>
      <InputLabelContainer>
        <Label>Closers:</Label>
        <select
          className="select"
          name="closer_id"
          id="closer_id"
          value={closerSelected.id || 'Selecione o closer'}
          onChange={handleSelectChange}
        >
          <option value="Selecione o closer" disabled>
            Selecione o closer
          </option>
          {closers &&
            closers.map(closer => (
              <option key={closer.id} id={closer.id} value={closer.id}>
                {closer.name}
              </option>
            ))}
        </select>
      </InputLabelContainer>

      <div />

      {formCloserData.closer_id && (
        <>
          <InputLabelContainer>
            <Label>Ramal:</Label>
            <Input
              id="ramal"
              name="ramal"
              type="text"
              defaultValue={closerSelected.ramal}
              onChange={handleInputChange}
            />
          </InputLabelContainer>

          <InputLabelContainer>
            <Label>Inativo:</Label>
            <select
              name="inactive"
              className="select"
              defaultValue={closerSelected.inactive === true ? 'Sim' : 'Não'}
              onChange={handleSelectChange}
            >
              <option disabled>Selecione uma opção</option>
              <option id="inactive" value="Sim">
                Sim
              </option>
              <option id="inactive" value="Não">
                Não
              </option>
            </select>
          </InputLabelContainer>

          <InputLabelContainer>
            <Label>Segunda-feira:</Label>
            <RowInputContainer>
              <TimeInput
                id="time_init_01"
                name="time_init_01"
                type="time"
                defaultValue={closerWorkHours.time_init_01}
                onChange={handleInputChange}
              />
              <TimeInput
                id="time_end_01"
                name="time_end_01"
                type="time"
                defaultValue={closerWorkHours.time_end_01}
                onChange={handleInputChange}
              />
            </RowInputContainer>
          </InputLabelContainer>

          <InputLabelContainer>
            <Label>Terça-feira:</Label>
            <RowInputContainer>
              <TimeInput
                id="time_init_02"
                name="time_init_02"
                type="time"
                defaultValue={closerWorkHours.time_init_02}
                onChange={handleInputChange}
              />
              <TimeInput
                id="time_end_02"
                name="time_end_02"
                type="time"
                defaultValue={closerWorkHours.time_end_02}
                onChange={handleInputChange}
              />
            </RowInputContainer>
          </InputLabelContainer>

          <InputLabelContainer>
            <Label>Quarta-feira:</Label>
            <RowInputContainer>
              <TimeInput
                id="time_init_03"
                name="time_init_03"
                type="time"
                defaultValue={closerWorkHours.time_init_03}
                onChange={handleInputChange}
              />
              <TimeInput
                id="time_end_03"
                name="time_end_03"
                type="time"
                defaultValue={closerWorkHours.time_end_03}
                onChange={handleInputChange}
              />
            </RowInputContainer>
          </InputLabelContainer>

          <InputLabelContainer>
            <Label>Quinta-feira:</Label>
            <RowInputContainer>
              <TimeInput
                id="time_init_04"
                name="time_init_04"
                type="time"
                defaultValue={closerWorkHours.time_init_04}
                onChange={handleInputChange}
              />
              <TimeInput
                id="time_end_04"
                name="time_end_04"
                type="time"
                defaultValue={closerWorkHours.time_end_04}
                onChange={handleInputChange}
              />
            </RowInputContainer>
          </InputLabelContainer>

          <InputLabelContainer>
            <Label>Sexta-feira:</Label>
            <RowInputContainer>
              <TimeInput
                id="time_init_05"
                name="time_init_05"
                type="time"
                defaultValue={closerWorkHours.time_init_05}
                onChange={handleInputChange}
              />
              <TimeInput
                id="time_end_05"
                name="time_end_05"
                type="time"
                defaultValue={closerWorkHours.time_end_05}
                onChange={handleInputChange}
              />
            </RowInputContainer>
          </InputLabelContainer>
          <div />
          <InputLabelContainer>
            <Label>Time:</Label>
            <Input
              id="team"
              name="team"
              type="text"
              defaultValue={closerSelected.team}
              onChange={handleInputChange}
            />
          </InputLabelContainer>
          <InputLabelContainer>
            <Label>Meta:</Label>
            <Input
              id="goal"
              name="goal"
              type="text"
              defaultValue={closerSelected.goal}
              onChange={handleInputChange}
            />
          </InputLabelContainer>
          <InputLabelContainer>
            <Label>Agendamentos para o dia:</Label>
            <Input
              id="max_schedulings_day"
              name="max_schedulings_day"
              type="text"
              defaultValue={closerSelected.max_schedulings_day}
              onChange={handleInputChange}
            />
          </InputLabelContainer>
        </>
      )}
    </FormGroup>
  );
}
