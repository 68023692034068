import styled, { css } from 'styled-components';

interface ColorPanelProps {
  quantidade?: number;
}

interface TeamsTitleContainerProps {
  isSelected?: boolean;
}

interface StatusProps {
  statusTaskProps?: string;
  situation?: string;
  isBefore?: boolean;
  talkProps?: string;
  tryTimesProps?: string;
  existTryTimesProps?: boolean;
  statusBusinessTask?: string;
}

export const GuidesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.625rem;
  margin-top: 1.688rem;
  margin-bottom: 0.938rem;
`;

export const Guide = styled.div<TeamsTitleContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 0.313rem;

  ${props =>
    props.isSelected
      ? css`
    div {
      background: #e8e8e8;

      span:first-child {
      color: #1665d8;
    }

      span:last-child {
        background: #1665d8;
      }
    }

    hr {
      border: 1px solid #1665d8;
    }


  }`
      : ''}
`;

export const TitleGuideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.25rem;
  gap: 0.25rem;
  border-radius: 0.313rem;

  :hover {
    cursor: pointer;
  }

  span:first-child {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 1.125;
    color: #9d9d9d;
  }

  span:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 3.125rem;
    background: #9d9d9d;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    color: #ffffff;
  }
`;

export const LineHorizontal = styled.hr`
  width: 100%;
  border: 1px solid #9d9d9d;
`;

export const Table = styled.table`
  margin-top: 0.763rem;

  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
  padding: 0.25rem 0;

  tr {
    height: 2.5rem;
  }

  td:nth-child(4) {
    border: none;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;
  height: 3.5rem;
  width: 100%;
`;

export const Cell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 0.25rem;

  img {
    border-radius: 50%;
    margin-left: 0.375rem;
    border: 1px solid rgba(22, 101, 216, 0.54);
    width: 2.75rem;
    height: 2.75rem;
    object-fit: cover;
  }

  &:nth-child(1) {
    min-width: 4.89%;
  }

  &:nth-child(2) {
    min-width: 32.43%;
  }

  &:nth-child(3) {
    min-width: 31.34%;
  }

  &:nth-child(4) {
    min-width: 31.34%;
  }
`;

export const ContainerTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.938rem;
  color: #05233e;
`;

export const OrderContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.175rem 0;
`;

export const ColoredCell = styled.td<ColorPanelProps>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:nth-child(1) {
    min-width: 4.89%;
  }

  &:nth-child(2) {
    min-width: 32.43%;
  }

  &:nth-child(3) {
    min-width: 31.34%;
  }

  &:nth-child(4) {
    min-width: 31.34%;
  }

  ${props =>
    props.quantidade === 0
      ? css`
          background: rgba(222, 12, 12, 0.3);
        `
      : props.quantidade >= 1 && props.quantidade <= 3
      ? css`
          background: rgba(218, 209, 18, 0.3);
        `
      : props.quantidade >= 4
      ? css`
          background: rgba(60, 166, 71, 0.3);
        `
      : css`
          background: #e5e5e5;
        `}
`;

export const Tbody = styled.tbody`
  a {
    color: inherit;
    text-decoration: none;
  }

  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.938rem;
  line-height: 1.406rem;
  color: #041524;

  tr:nth-child(odd) {
    background: #efefef;
  }

  tr:nth-child(even) {
    background: #e5e5e5;
  }

  tr {
    transition: filter 0.3s;
    :hover {
      filter: brightness(0.9);
    }
  }

  tr {
    margin-bottom: 0.5rem;
  }

  td {
    border: none;
  }

  td:nth-child(1) {
    display: flex;
    justify-content: flex-start;
  }
`;

export const Timetable = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
`;

export const IconHour = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;

  width: 4%;
`;

export const Image = styled.img``;

export const Time = styled.time`
  padding-left: 0.5rem;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.938rem;
  color: #05233e;
`;

export const LineTable = styled.div<StatusProps>`
  background: rgba(22, 101, 216, 0.08);
  border-radius: 0.25rem;
  height: 3.6rem;
  width: 95%;

  margin-left: 0.5rem;
  display: flex;
  align-items: center;

  font-family: Poppins;

  ${props =>
    props.statusTaskProps === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 #3ca647,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.statusTaskProps === 'Pendente' &&
        (!props.tryTimesProps ||
          props.tryTimesProps === '0' ||
          props.tryTimesProps === 'null')
      ? css`
          background: rgba(236, 68, 68, 0.25);
          box-shadow: inset 0.188rem 0 0 #de0c0c,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.statusTaskProps === 'Pendente' && Number(props.tryTimesProps) > 0
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 #b4ad09,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : css`
          background: rgba(22, 101, 216, 0.06);
          box-shadow: inset 0.188rem 0 0 #1665d8,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `}

  .hunter {
    min-width: 16%;
  }

  .closer {
    min-width: 18%;
  }

  .value {
    min-width: 13.8%;
  }

  .connection {
    min-width: 9%;
  }

  .contact {
    min-width: 9%;
  }

  .credor {
    min-width: 34%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.438rem;
`;

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 10rem;

  img {
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
  }
`;

export const Legend = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  color: #595959;
`;

export const Info = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #041524;

  span {
    display: none;
  }
`;

export const InfoCredor = styled.div`
  p {
    font-size: 0.813rem;
  }

  a {
    font-family: Poppins, sans-serif;
    font-weight: 300;
    color: #1665d8;
  }
`;

export const ButtonSituation = styled.button<StatusProps>`
  width: 5.125rem;
  height: 1.625rem;
  border: none;
  border-radius: 0.25rem;

  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  color: #041524;

  ${props =>
    props.statusTaskProps === 'Concluída'
      ? css`
          background: #3ca647;
        `
      : props.statusTaskProps === 'Tentou'
      ? css`
          background: #b4ad09;
        `
      : css`
          background: #ea3f3f;
        `}
`;

export const LineSeparator = styled.div`
  border-right: 0.063rem solid #9d9d9d;
  height: 3.2rem;
`;

export const SituationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 7.5rem;
`;

export const DailerRecordContainer = styled.div<StatusProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 10rem;

  p {
    ${props =>
      props.statusTaskProps === 'Não atendido'
        ? css`
            color: #b4ad09;
          `
        : props.statusTaskProps === 'Pendente'
        ? css`
            color: #d2321c;
          `
        : css`
            color: #0d901a;
          `}
  }
`;

export const VerticalDivisor = styled.div`
  height: 2rem;
  border: 0.063rem solid #817f7f;
`;

export const AttempsContainer = styled.div<StatusProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 7.5rem;

  p {
    ${props =>
      props.statusTaskProps === 'Não atendido'
        ? css`
            color: #b4ad09;
          `
        : props.statusTaskProps === 'Pendente'
        ? css`
            color: #d2321c;
          `
        : css`
            color: #0d901a;
          `}

    svg {
      margin-left: 0.375rem;
    }
  }
`;

export const ValueColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;

  width: 11rem;
`;
