import styled from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const PreparationModalStyle = {
  content: {
    width: '29.313rem',
    maxHeight: '90vh',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    overflow: 'auto',
    border: '1px solid #041524',
    borderRadius: '0.625rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const Main = styled.div`
  padding: 0 ${pxToRem(42)};
  margin-top: ${pxToRem(42)};
  margin-bottom: ${pxToRem(16)};

  /* min-height: 70vh; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: ${pxToRem(30)};
  gap: 0.125rem;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: ${pxToRem(17)};
  color: var(--blue-700);
`;

export const Select = styled.select`
  width: 100%;
  height: ${pxToRem(33)};

  border: 1px solid var(--blue-700);
  border-radius: 0.5rem;
  padding-left: 0.25rem;

  font-weight: 400;
  font-size: ${pxToRem(16)};
  color: var(--blue-700);
`;

export const Input = styled.input`
  width: 100%;
  height: ${pxToRem(33)};

  border: 1px solid var(--blue-700);
  border-radius: 0.5rem;
  padding-left: 0.25rem;

  font-weight: 400;
  font-size: ${pxToRem(16)};
  color: var(--blue-700);
`;

export const CheckboxContainer = styled.div`
  gap: 1rem;
  display: flex;

  align-items: center;
`;

export const Checkbox = styled.input`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};

  cursor: pointer;
`;

export const LabelCheckbox = styled.label`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  color: var(--blue-700);
`;

export const Textarea = styled.textarea`
  height: ${pxToRem(132)};
  width: ${pxToRem(387)};

  border-radius: 0.5rem;

  padding: 0.25rem 0 0 0.25rem;

  resize: none;

  font-weight: 400;
  font-size: ${pxToRem(16)};
  color: var(--blue-700);
`;

export const ButtonSave = styled.button`
  height: ${pxToRem(57)};
  width: ${pxToRem(225)};

  align-self: center;

  border: none;
  background: var(--blue-700);
  border-radius: 0.5rem;

  font-weight: 600;
  font-size: ${pxToRem(24)};
  color: var(--white);

  transition: 0.2s filter;

  :hover {
    filter: brightness(0.8);
  }
`;
