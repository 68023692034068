import styled from 'styled-components';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 20rem;
  @media print {
    display: none;
  }
`;

export const BackButtonContainer = styled.div`
  margin-top: -3rem;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;

  width: 100%;

  .closed-deals-container {
    & + .closed-deals-container {
      margin-top: 0.5rem;
    }
  }
`;

export const InputDateAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 1.875rem;

  margin-bottom: 2rem;
`;

export const SelectLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 0.875rem;

  width: 38rem;

  background: var(--blue-50);
  border: 0.5px solid var(--blue-700);
  border-radius: 2px;
`;

export const Span = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--blue-700);
`;

export const Select = styled.select`
  cursor: pointer;

  width: 12rem;
  height: 1.25rem;
  border: none;
  border-radius: 0.25rem;
  background: var(--gray-200);

  cursor: pointer;

  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  color: var(--blue-700);
`;
