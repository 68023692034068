import { shade } from 'polished';
import styled from 'styled-components';

export const CourtContainer = styled.div`
  margin-top: 1.875rem;
  width: 32rem;
  height: 8rem;
  border: 0.188rem solid #d7e4f5;
  box-shadow: 0.125rem 0.125rem 0.25rem -1.25rem #d7e4f5;
  border-radius: 0.625rem;
`;

export const CourtTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d7e4f5;

  height: 2rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: var(--blue-700);
`;

export const CourtContentContainer = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const SelectCourtContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FileContainer = styled.div`
  width: 100%;
  margin-top: 4.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 1.625rem;
  border-radius: 0.313rem;
  padding: 0.3rem;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  cursor: pointer;
  color: #4e4e4e;
  background: #c4c4c4;

  color: #4e4e4e;
`;

export const ButtonSendFile = styled.button`
  padding: 0.5rem;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  width: 8rem;
  height: 1.625rem;

  background: #063966;
  border: 0;
  border-radius: 0.313rem;

  color: #ffffff;
`;

export const VerticalDivisor = styled.div`
  height: 1.5rem;

  border-right: 1px solid #041524;
`;

export const FilesHistoryContainer = styled.div`
  width: calc(100% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.5rem;
  @media (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const TitleHistory = styled.h1`
  width: 58%;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;

  color: #05233e;

  @media (max-width: 1350px) {
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ClearHitoryButton = styled.button`
  border: 0;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: -0.015em;

  width: 8rem;
  height: 2rem;
  padding: 0.3rem;

  background: #676768;
  border-radius: 0.313rem;
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Thead = styled.thead`
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
`;

export const TableRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background: var(--blue-100);
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;
`;

export const CellHeader = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.015em;

  color: var(--blue-700);
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25rem;
  text-align: center;
`;

export const TableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;

  :nth-child(odd) {
    background: #efefef;
    &:hover {
      background: ${shade(0.1, '#D4E3DD')};
    }
  }

  :nth-child(even) {
    background: #e5e5e5;
    &:hover {
      background: ${shade(0.1, '#D4E3DD')};
    }
  }
`;

export const CellBody = styled.td`
  word-break: break-all;
  width: 100%;
  background: transparent;
  padding: 0.5rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  :nth-child(2) {
    cursor: pointer;
    color: #1665d8;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonZip = styled.button`
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 0.313rem;
  border: none;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;

  color: #1665d8;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  :disabled {
    cursor: initial;
    color: #05233e;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Button = styled.button`
  width: 7rem;
  height: 1.75rem;

  background: #063966;
  border-radius: 0.313rem;

  color: #fff;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;
  border: none;

  :disabled {
    background: ${shade(0.1, '#817f7f')};
    cursor: initial;
  }
`;

export const SelectCourt = styled.select`
  width: 20rem;
  height: 2.5rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;

  & + select {
    margin-left: 1.25rem;
  }
`;

export const SelectCourtOption = styled.option``;
