import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { Container } from '../../../components/Container';
import {
  FilterBox,
  FilterHeader,
  FilterBody,
  FilterOptions,
  FilterInput,
  SearchButton,
  SearchTable,
  ResultDiv,
  ResultAmount,
  SearchResultCaption,
  DivTable,
  Table,
  TableHeader,
  ContainerTitle,
  Divider,
  TableBody,
  TCellHeader,
  TableRow,
  PaginationDiv,
  PageButton,
  TableHeaderRow,
  TCellBody,
  Option,
  SearchTableImg,
  LodingImg,
} from './styles';
import { Title } from '../../../components/Title';
import { Body } from '../../../components/Styles/Body';
import loading from '../../../assets/loading.gif';
import magnifier from '../../../assets/icons/magnifier.svg';
import { ProcessInformationModal } from '../../../components/ProcessInformationModal';
import apiTrfs from '../../../services/apiTrfs';
import { NavigationBar } from '../../../components/NavigationBar';

interface ILineTypeAmount {
  total: number;
  inHub: number;
  standby: number;
  blocked: number;
}

interface IRowsAmount {
  total: number;
  inHub: number;
  standby: number;
  blocked: number;
  pages: number[];
}

interface IPrecatoryData {
  id: number;
  creditor: string;
  loa: string;
  total_craft_value: string;
  status: string;
  creditor_cpf: string;
  background: string;
  precatory: string;
}

export const SearchProcesses: React.FC = () => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [searching, setSearching] = useState('none');
  const [foundPrecatories, setFoundPrecatories] = useState<IPrecatoryData[]>(
    [],
  );
  const [selectedProcessPrecatory, setSelectedProcessPrecatory] = useState('');
  const [selectedProcessType, setSelectedProcessType] = useState('');
  const [rowAmount, setRowAmount] = useState<IRowsAmount>({
    blocked: 0,
    inHub: 0,
    standby: 0,
    total: 0,
    pages: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);

  const handleSelectFilter = useCallback(event => {
    setSelectedFilter(event.target.value);
  }, []);

  const handleInputChange = useCallback(event => {
    setFilterValue(event.target.value.trim().replaceAll(' ', '_'));
  }, []);

  const handleSearchRows = useCallback(async () => {
    if (selectedFilter.length > 0 && filterValue.length > 0) {
      setSearching('searching');

      const rowsAmountSearch = await apiTrfs.get<ILineTypeAmount>(
        `process_search/lines-amount-search?filter=${selectedFilter}&value=${filterValue}`,
      );

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(rowsAmountSearch.data.total / 10);
        index++
      ) {
        pages.push(index);
      }

      setRowAmount({
        standby: rowsAmountSearch.data.standby,
        inHub: rowsAmountSearch.data.inHub,
        total: rowsAmountSearch.data.total,
        blocked: rowsAmountSearch.data.blocked,
        pages,
      });

      const processesSearch = await apiTrfs.get(
        `process_search/search-by-filter?filter=${selectedFilter}&value=${filterValue}&offset=1`,
      );

      setFoundPrecatories(processesSearch.data);

      setSearching('found');
    } else {
      Swal.fire(
        'Erro ao realizar a pesquisa!',
        'Escolher um filtro e preencher o valor.',
        'error',
      );
    }
  }, [filterValue, selectedFilter]);

  const handleChangePagination = useCallback(
    async (toPage: number) => {
      if (toPage === -1 && page !== 1) {
        const processesSearch = await apiTrfs.get(
          `process_search/search-by-filter?filter=${selectedFilter}&value=${filterValue}&offset=${
            page - 1
          }`,
        );

        setPage(prevPage => prevPage - 1);
        setFoundPrecatories(processesSearch.data);
      } else if (toPage === 0 && page !== rowAmount.pages.length) {
        const processesSearch = await apiTrfs.get(
          `process_search/search-by-filter?filter=${selectedFilter}&value=${filterValue}&offset=${
            page + 1
          }`,
        );

        setPage(prevPage => prevPage + 1);
        setFoundPrecatories(processesSearch.data);
      } else if (page !== toPage) {
        const processesSearch = await apiTrfs.get(
          `process_search/search-by-filter?filter=${selectedFilter}&value=${filterValue}&offset=${toPage}`,
        );

        setPage(toPage);

        setFoundPrecatories(processesSearch.data);
      }
    },
    [filterValue, page, rowAmount, selectedFilter],
  );

  const handleOpenInformationModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const onRequestClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Container>
      <NavigationBar
        past_screens="Processsamento >"
        active_screen="Pesquisa de processos"
      />
      <Body>
        <Title name="Pesquisa de processos" />

        <FilterBox>
          <FilterHeader>Filtro de pesquisa:</FilterHeader>
          <FilterBody>
            <FilterOptions
              onChange={handleSelectFilter}
              defaultValue="default_value"
            >
              <Option value="default_value" disabled>
                Selecione uma opção
              </Option>

              <Option value="precatory">N° do precatório</Option>
              <Option value="originating_process">Processo originário</Option>
              <Option value="creditor_cpf">CPF do credor</Option>
              <Option value="creditor">Nome do credor</Option>
              <Option value="lawyer_cpf">CPF do advogado</Option>
              <Option value="lawyer">Nome do advogado</Option>
            </FilterOptions>
            <FilterInput type="text" onChange={handleInputChange} />
          </FilterBody>

          <SearchButton onClick={handleSearchRows}>
            <SearchTableImg src={magnifier} alt="" />
            Pesquisar
          </SearchButton>
        </FilterBox>

        {searching === 'searching' ? (
          <LodingImg src={loading} alt="" />
        ) : (
          searching === 'found' && (
            <SearchTable>
              <SearchResultCaption>
                <ResultDiv>
                  Todos os resultados:
                  <ResultAmount
                    style={{
                      backgroundColor: '#666360',
                    }}
                  >
                    {rowAmount.total}
                  </ResultAmount>
                </ResultDiv>
                <ResultDiv>
                  Está no Hub:
                  <ResultAmount
                    style={{
                      backgroundColor: '#1665D8',
                    }}
                  >
                    {rowAmount.inHub}
                  </ResultAmount>
                </ResultDiv>
                <ResultDiv>
                  Não está no Hub:
                  <ResultAmount
                    style={{
                      backgroundColor: '#B4AD09',
                    }}
                  >
                    {rowAmount.standby}
                  </ResultAmount>
                </ResultDiv>
                <ResultDiv>
                  Bloqueado:
                  <ResultAmount
                    style={{
                      backgroundColor: '#EB5757',
                    }}
                  >
                    {rowAmount.blocked}
                  </ResultAmount>
                </ResultDiv>
              </SearchResultCaption>

              <DivTable>
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      <TCellHeader>
                        <ContainerTitle>Nome credor</ContainerTitle>
                        <Divider />
                      </TCellHeader>

                      <TCellHeader>
                        <ContainerTitle>CPF credor</ContainerTitle>
                        <Divider />
                      </TCellHeader>

                      <TCellHeader>
                        <ContainerTitle>LOA</ContainerTitle>
                        <Divider />
                      </TCellHeader>

                      <TCellHeader>
                        <ContainerTitle>Valor Total</ContainerTitle>
                        <Divider />
                      </TCellHeader>

                      <TCellHeader>
                        <ContainerTitle>Status</ContainerTitle>
                      </TCellHeader>
                    </TableHeaderRow>
                  </TableHeader>

                  <TableBody>
                    {foundPrecatories.length > 0 &&
                      foundPrecatories.map(precatory => (
                        <TableRow
                          key={precatory.id}
                          background={precatory.background}
                          onClick={() => {
                            setSelectedProcessPrecatory(precatory.precatory);
                            setSelectedProcessType(precatory.background);
                            handleOpenInformationModal();
                          }}
                        >
                          <TCellBody>
                            {precatory.creditor.toUpperCase()}
                          </TCellBody>
                          <TCellBody>{precatory.creditor_cpf}</TCellBody>
                          <TCellBody>{precatory.loa}</TCellBody>
                          <TCellBody>{precatory.total_craft_value}</TCellBody>
                          <TCellBody>{precatory.status}</TCellBody>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </DivTable>
            </SearchTable>
          )
        )}

        {rowAmount.pages.length > 0 && rowAmount.pages.length <= 5 ? (
          <PaginationDiv>
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(-1);
              }}
            >
              {'<'}
            </PageButton>
            {rowAmount.pages.length > 0
              ? rowAmount.pages.map(row => (
                  <PageButton
                    active={row === page}
                    onClick={() => {
                      handleChangePagination(row);
                    }}
                  >
                    {row}
                  </PageButton>
                ))
              : ''}
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(0);
              }}
            >
              {'>'}
            </PageButton>
          </PaginationDiv>
        ) : (
          <PaginationDiv>
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(-1);
              }}
            >
              {'<'}
            </PageButton>
            {rowAmount.pages.length > 0
              ? rowAmount.pages.map(row =>
                  page <= 4 && row <= 7 ? (
                    <PageButton
                      active={row === page}
                      onClick={() => {
                        handleChangePagination(row);
                      }}
                    >
                      {row}
                    </PageButton>
                  ) : page > 4 && row >= page - 3 && row <= page + 3 ? (
                    <PageButton
                      active={row === page}
                      onClick={() => {
                        handleChangePagination(row);
                      }}
                    >
                      {row}
                    </PageButton>
                  ) : (
                    ''
                  ),
                )
              : ''}
            <PageButton
              active={false}
              onClick={() => {
                handleChangePagination(0);
              }}
            >
              {'>'}
            </PageButton>
          </PaginationDiv>
        )}

        <ProcessInformationModal
          isOpen={openModal}
          onRequestClose={onRequestClose}
          precatory={selectedProcessPrecatory}
          process_type={selectedProcessType}
        />
      </Body>
    </Container>
  );
};
