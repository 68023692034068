import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/pxToRem';

export const Table = styled.table`
  margin-top: 0.763rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
  padding: 0.25rem 0;

  tr {
    height: 2.5rem;
    box-shadow: none;
  }

  th:nth-child(6) {
    border-right: none;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;
  height: 3.5rem;
  width: 100%;

  box-shadow: inset 0.188rem 0 0 var(--blue-500);
`;

export const Cell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 0.25rem;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 2.75rem;
    height: 2.75rem;
    border: solid ${pxToRem(1.5)} var(--blue-500);
  }

  &:nth-child(1) {
    min-width: 5.6%;
  }

  &:nth-child(2) {
    min-width: 27.6%;
  }

  &:nth-child(3) {
    min-width: 16.6%;
  }

  &:nth-child(4) {
    min-width: 24.6%;
  }

  &:nth-child(5) {
    min-width: 12.6%;
  }

  &:nth-child(6) {
    min-width: 12.6%;
    border-right: none;
  }
`;

export const ContainerTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.938rem;
  color: #05233e;
`;

export const OrderContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.175rem 0;
`;

export const Tbody = styled.tbody`
  a {
    color: inherit;
    text-decoration: none;
  }

  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.938rem;
  line-height: 1.406rem;
  color: #041524;

  tr:nth-child(odd) {
    background: #efefef;
  }

  tr:nth-child(even) {
    background: #e5e5e5;
  }

  tr {
    transition: filter 0.3s;
    :hover {
      filter: brightness(0.9);
    }
  }

  tr {
    margin-bottom: 0.5rem;
  }
`;

export const BoxCasos = styled.div`
  display: flex;
  justify-content: center;

  width: ${pxToRem(137)};

  margin-bottom: ${pxToRem(-24)};
  margin-right: 16.5%;

  background: var(--blue-100);

  font-size: ${pxToRem(15)};
  font-weight: 600;

  border-radius: 4px;
`;

export const BoxValor = styled.div`
  display: flex;
  justify-content: center;

  width: ${pxToRem(137)};

  margin-bottom: ${pxToRem(-14)};
  margin-right: -24.5%;

  background: var(--blue-100);

  font-size: ${pxToRem(15)};
  font-weight: 600;

  border-radius: 4px;
`;

export const BoxTable = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;
