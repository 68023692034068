import { format, isEqual, parseISO } from 'date-fns';
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '../services/api';

import {
  IDayHoursDTO,
  IHunterActivity,
} from '../pages/Schedule/dtos/IDayHoursDTO';

interface ScheduleHunterContextData {
  dayHours: IDayHoursDTO[];
  handleCreateDayHour(created_activity: IHunterActivity): void;
  handleUpdateDayHour(
    old_timestamp: Date,
    updated_activity: IHunterActivity,
    old_activity: IHunterActivity,
  ): void;
}

export const ScheduleHunterContext = createContext<ScheduleHunterContextData>(
  {} as ScheduleHunterContextData,
);

interface ScheduleHunterProviderProps {
  date: Date;
  user_id: string;
  children: ReactElement;
}

export function ScheduleHunterProvider({
  date,
  user_id,
  children,
}: ScheduleHunterProviderProps): JSX.Element {
  const [dayHours, setDayHours] = useState<IDayHoursDTO[]>(
    [] as IDayHoursDTO[],
  );

  useEffect(() => {
    const getHunterDayHours = async () => {
      try {
        await api
          .get(
            `schedule/hunter-activities?hunter_id=${user_id}&date=${format(
              date,
              'yyyy-MM-dd',
            )}`,
          )
          .then(response => {
            setDayHours(response.data);
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.response.data.message);
      }
    };

    getHunterDayHours();
  }, [date, user_id]);

  function handleCreateDayHour(created_activity: IHunterActivity) {
    const updatedDayHours = [...dayHours];

    const indexDayHour = updatedDayHours.findIndex(day_hour =>
      isEqual(
        parseISO(day_hour.hour.toString()),
        parseISO(created_activity.timestamp.toString()),
      ),
    );

    if (indexDayHour >= 0) {
      updatedDayHours[indexDayHour].activities.push(created_activity);

      setDayHours(updatedDayHours);
    }
  }

  function handleUpdateDayHour(
    old_timestamp: Date,
    updated_activity: IHunterActivity,
    old_activity: IHunterActivity,
  ) {
    const updatedDayHours = dayHours;

    const oldTimestampIndex = updatedDayHours.findIndex(day_hour =>
      isEqual(
        parseISO(day_hour.hour.toString()),
        parseISO(old_timestamp.toString()),
      ),
    );

    if (oldTimestampIndex >= 0) {
      const activityIndex = updatedDayHours[
        oldTimestampIndex
      ].activities.findIndex(activity => activity.id === updated_activity.id);

      const newTimestampIndex = updatedDayHours.findIndex(day_hour =>
        isEqual(
          parseISO(day_hour.hour.toString()),
          parseISO(updated_activity.timestamp.toString()),
        ),
      );

      if (activityIndex >= 0 && newTimestampIndex >= 0) {
        updatedDayHours[oldTimestampIndex].activities.splice(activityIndex, 1);

        if (
          !updated_activity.deleted &&
          updated_activity.hunter_id === old_activity.hunter_id
        ) {
          updatedDayHours[newTimestampIndex].activities.push(updated_activity);
        }

        setDayHours(updatedDayHours);
      }
    }
  }

  return (
    <ScheduleHunterContext.Provider
      value={{
        dayHours,
        handleCreateDayHour,
        handleUpdateDayHour,
      }}
    >
      {children}
    </ScheduleHunterContext.Provider>
  );
}

export function useHunterSchedule(): ScheduleHunterContextData {
  const context = useContext(ScheduleHunterContext);

  if (!context) {
    throw new Error('useHunterSchedule must be used within an AuthProvider');
  }

  return context;
}
