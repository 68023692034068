interface IRequest {
  name_modal: string;
  hunter_name: string;
  who_contacted: string;
  connection_type: string;
  connection_method: string;
  valid_contact: string;
  autority?: string;
  contact_with_competitor?: string;
  customer_priority?: string;
  reaction?: string;
  plans?: string;
  profile?: string;
  doubts?: string;
  why_didnt_anticipate_before?: string;
  decision_review?: string;
  influence: string;
  objection?: string;
  lost_category?: string;
  informed_negotiable_value?: string;
  negotiable_value?: string;
  valid_email?: string;
  observation?: string;
  was_aware_precatory?: string;
  was_aware_PEC?: string;
  feel_uncomfortable?: string;
  scheduled_for_closer?: string;
  decision_maker?: string;
  contact_preferences?: string;
  valid_contact_wpp_opt_in?: string;
  schedule_date?: Date;
  day?: string;
  hours?: string;
}
export function createRetakeClarificationNote({
  name_modal,
  hunter_name,
  who_contacted,
  connection_type,
  connection_method,
  valid_contact,
  autority,
  contact_with_competitor,
  customer_priority,
  reaction,
  plans,
  profile,
  doubts,
  why_didnt_anticipate_before,
  influence,
  objection,
  decision_review,
  informed_negotiable_value,
  negotiable_value,
  lost_category,
}: IRequest): string {
  if (name_modal === 'Lost') {
    if (informed_negotiable_value === 'Sim') {
      return `RAPPORT \n\
  Hunter: ${hunter_name} \n\
  Tipo de Conexão: ${connection_type} \n\
  Meio de Conexão: ${connection_method} \n\
  Telefone Válido: ${valid_contact} \n\
  Com quem foi o Contato: ${who_contacted} \n \n\

  TOMADA DE DECISÃO \n\
  Conversamos com a autoridade? ${autority} \n\
  Mencionou alguma influência na conversa? ${influence} \n\
  Teve contato com algum concorrente? ${contact_with_competitor} \n\
  Para o cliente qual o nível de prioridade em resolver esse problema? ${customer_priority} \n \n\

  SPIN SELLING \n\
  Perfil? ${profile} \n\
  Sobre planos com o recurso? ${plans} \n\
  Demonstrou alguma dúvida na conversa? ${doubts} \n\
  Motivo de não ter tido interesse de antecipar antes? ${why_didnt_anticipate_before} \n\
  Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
  Trouxe alguma objeção para a conversa? ${objection} \n\
  Poderia rever sua decisão e antecipar com a Precato? ${decision_review} \n \n\

  NEGOCIAÇÃO \n\
  Informou o valor que negociaria? ${informed_negotiable_value} \n\
  Qual? R$ ${negotiable_value} \n \n\

  MOTIVO DA PERDA \n\
  ${lost_category} \n \n\

  OBSERVAÇÃO \n\
  `;
    }
    return `RAPPORT \n\
  Hunter: ${hunter_name} \n\
  Tipo de Conexão: ${connection_type} \n\
  Meio de Conexão: ${connection_method} \n\
  Telefone Válido: ${valid_contact} \n\
  Com quem foi o Contato: ${who_contacted} \n \n\

  TOMADA DE DECISÃO \n\
  Conversamos com a autoridade? ${autority} \n\
  Mencionou alguma influência na conversa? ${influence} \n\
  Teve contato com algum concorrente? ${contact_with_competitor} \n\
  Para o cliente qual o nível de prioridade em resolver esse problema? ${customer_priority} \n \n\

  SPIN SELLING \n\
  Perfil? ${profile} \n\
  Sobre planos com o recurso? ${plans} \n\
  Demonstrou alguma dúvida na conversa? ${doubts} \n\
  Motivo de não ter tido interesse de antecipar antes? ${why_didnt_anticipate_before} \n\
  Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
  Trouxe alguma objeção para a conversa? ${objection} \n\
  Poderia rever sua decisão e antecipar com a Precato? ${decision_review} \n \n\

  NEGOCIAÇÃO \n\
  Informou o valor que negociaria? ${informed_negotiable_value} \n \n\

  MOTIVO DA PERDA \n\
  ${lost_category} \n \n\

  OBSERVAÇÃO \n\
  `;
  }

  if (name_modal === 'Opportunity') {
    if (informed_negotiable_value === 'Sim') {
      return `RAPPORT \n\
  Hunter: ${hunter_name} \n\
  Tipo de Conexão: ${connection_type} \n\
  Meio de Conexão: ${connection_method} \n\
  Telefone Válido: ${valid_contact} \n\
  Com quem foi o Contato: ${who_contacted} \n \n\

  TOMADA DE DECISÃO \n\
  Conversamos com a autoridade? ${autority} \n\
  Mencionou alguma influência na conversa? ${influence} \n\
  Teve contato com algum concorrente? ${contact_with_competitor} \n\
  Para o cliente qual o nível de prioridade em resolver esse problema? ${customer_priority} \n \n\

  SPIN SELLING \n\
  Perfil? ${profile} \n\
  Sobre planos com o recurso? ${plans} \n\
  Demonstrou alguma dúvida na conversa? ${doubts} \n\
  Motivo de não ter tido interesse de antecipar antes? ${why_didnt_anticipate_before} \n\
  Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
  Trouxe alguma objeção para a conversa? ${objection} \n\
  Poderia rever sua decisão e antecipar com a Precato? ${decision_review} \n \n\

  NEGOCIAÇÃO \n\
  Informou o valor que negociaria? ${informed_negotiable_value} \n\
  Qual? R$ ${negotiable_value} \n \n\

  OBSERVAÇÃO \n\
  `;
    }
    return `RAPPORT \n\
  Hunter: ${hunter_name} \n\
  Tipo de Conexão: ${connection_type} \n\
  Meio de Conexão: ${connection_method} \n\
  Telefone Válido: ${valid_contact} \n\
  Com quem foi o Contato: ${who_contacted} \n \n\

  TOMADA DE DECISÃO \n\
  Conversamos com a autoridade? ${autority} \n\
  Mencionou alguma influência na conversa? ${influence} \n\
  Teve contato com algum concorrente? ${contact_with_competitor} \n\
  Para o cliente qual o nível de prioridade em resolver esse problema? ${customer_priority} \n \n\

  SPIN SELLING \n\
  Perfil? ${profile} \n\
  Sobre planos com o recurso? ${plans} \n\
  Demonstrou alguma dúvida na conversa? ${doubts} \n\
  Motivo de não ter tido interesse de antecipar antes? ${why_didnt_anticipate_before} \n\
  Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
  Trouxe alguma objeção para a conversa? ${objection} \n\
  Poderia rever sua decisão e antecipar com a Precato? ${decision_review} \n \n\

  NEGOCIAÇÃO \n\
  Informou o valor que negociaria? ${informed_negotiable_value} \n \n\

  OBSERVAÇÃO \n\
  `;
  }

  if (informed_negotiable_value === 'Sim') {
    return `RAPPORT \n\
Hunter: ${hunter_name} \n\
Tipo de Conexão: ${connection_type} \n\
Meio de Conexão: ${connection_method} \n\
Telefone Válido: ${valid_contact} \n\
Com quem foi o Contato: ${who_contacted} \n \n\

TOMADA DE DECISÃO \n\
Conversamos com a autoridade? ${autority} \n\
Mencionou alguma influência na conversa? ${influence} \n\
Teve contato com algum concorrente? ${contact_with_competitor} \n\
Para o cliente qual o nível de prioridade em resolver esse problema? ${customer_priority} \n \n\

SPIN SELLING \n\
Perfil? ${profile} \n\
Sobre planos com o recurso? ${plans} \n\
Demonstrou alguma dúvida na conversa? ${doubts} \n\
Motivo de não ter tido interesse de antecipar antes? ${why_didnt_anticipate_before} \n\
Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
Trouxe alguma objeção para a conversa? ${objection} \n\
Poderia rever sua decisão e antecipar com a Precato? ${decision_review} \n \n\

NEGOCIAÇÃO \n\
Informou o valor que negociaria? ${informed_negotiable_value} \n\
Qual? R$ ${negotiable_value} \n \n\

OBSERVAÇÃO \n\
`;
  }
  return `RAPPORT \n\
Hunter: ${hunter_name} \n\
Tipo de Conexão: ${connection_type} \n\
Meio de Conexão: ${connection_method} \n\
Telefone Válido: ${valid_contact} \n\
Com quem foi o Contato: ${who_contacted} \n \n\

TOMADA DE DECISÃO \n\
Conversamos com a autoridade? ${autority} \n\
Mencionou alguma influência na conversa? ${influence} \n\
Teve contato com algum concorrente? ${contact_with_competitor} \n\
Para o cliente qual o nível de prioridade em resolver esse problema? ${customer_priority} \n \n\

SPIN SELLING \n\
Perfil? ${profile} \n\
Sobre planos com o recurso? ${plans} \n\
Demonstrou alguma dúvida na conversa? ${doubts} \n\
Motivo de não ter tido interesse de antecipar antes? ${why_didnt_anticipate_before} \n\
Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
Trouxe alguma objeção para a conversa? ${objection} \n\
Poderia rever sua decisão e antecipar com a Precato? ${decision_review} \n \n\

NEGOCIAÇÃO \n\
Informou o valor que negociaria? ${informed_negotiable_value} \n \n\

OBSERVAÇÃO \n\
`;
}
