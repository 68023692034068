import styled from 'styled-components';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.875rem;
`;

export const SearchContainer = styled.label`
  width: 26rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gray-200);
  border-radius: 1.875rem;

  img {
    width: 1rem;
    height: 1rem;

    margin-right: 0.938rem;
  }
`;

export const InputSearch = styled.input`
  width: 24rem;
  height: 100%;

  background: var(--gray-200);

  border-radius: 1.875rem;
  padding-left: 1rem;
  border: none;

  font-size: 0.938rem;

  &::placeholder {
    color: var(--gray-400);
  }
`;

export const Table = styled.table`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Thead = styled.thead`
  width: 100%;

  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--blue-100) 83%;

    border-radius: 0.25rem;
    margin-bottom: 0.688rem;

    height: 2.5rem;
    th:nth-child(1) {
      .icon-head {
        width: 2%;
      }
      span {
        width: 98%;
        padding-right: 2%;
      }
    }

    th {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 1rem;
      text-align: center;
      color: var(--blue-700);
    }
  }
`;

export const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  tr:nth-child(even) {
    background: var(--gray-100);
  }

  tr:nth-child(odd) {
    background: var(--gray-50);
  }

  tr {
    display: flex;

    justify-content: center;
    align-items: center;

    height: 3.5rem;
    border-radius: 0.25rem;
    background: var(--gray-50);

    td:nth-child(1) {
      width: 100%;

      display: flex;
      justify-content: flex-end;

      span {
        width: 94%;
        padding-left: 6%;
        text-align: center;
      }

      .icon-body {
        width: 5%;
        display: flex;
        justify-content: center;

        gap: 0.375rem;
      }
    }

    td {
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: 'Poppins';
      font-weight: 300;
      font-size: 0.938rem;
      text-align: center;

      color: var(--blue-700);
    }
  }
`;

export const ButtonNewProfile = styled.button`
  width: 8rem;
  height: 2rem;
  color: var(--white);
  border: none;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;

  background: var(--green-600);
  border-radius: 0.25rem;
`;

export const IconForm = styled.div`
  display: flex;
  justify-content: center;

  margin-right: 0.5rem;
`;

export const ContainerTitle = styled.span`
  width: 100%;
`;

export const ButtonDelete = styled.button`
  border: none;
  border-radius: 0.3rem;

  background-color: var(--gray-900);
  width: 1.438rem;
  height: 1.375rem;

  color: var(--white);
`;

export const ButtonEdit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.3rem;

  background-color: var(--blue-600);
  width: 1.438rem;
  height: 1.375rem;

  color: var(--white);
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    stroke: #0c0b0b;
    height: 1.563rem;
    width: 1.563rem;
  }
`;

export const TextLoader = styled.h1`
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.438rem;
  text-align: center;
  color: var(--blue-700);
`;

export const ContainerExport = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.5rem 0 2rem 0;
`;

export const Export = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  gap: 0.5rem;

  font-size: 0.813rem;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--blue-700);
`;
