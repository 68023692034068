import { ChangeEvent, useCallback } from 'react';

import { IProfileDTO } from '../../dtos/IProfileDTO';
import { ITeamDTO } from '../../dtos/ITeamDTO';
import { IUserDTO } from '../../dtos/IUserDTO';
import { IUserFormDataDTO } from '../../dtos/IUserFormDataDTO';

import {
  BorderFormGroup,
  FormContainer,
  FormGroup,
  TitleHeader,
  TitleContainer,
  Label,
  InputContainer,
  Input,
} from './styles';

interface EditUserFormProps {
  user: IUserDTO;
  profiles: IProfileDTO[];
  teams: ITeamDTO[];
  formData: IUserFormDataDTO;
  setFormData: (formdata: IUserFormDataDTO) => void;
  closerProfileId: string;
  hunterProfileId: string;
}

export function EditUserForm({
  user,
  profiles,
  teams,
  formData,
  setFormData,
  closerProfileId,
  hunterProfileId,
}: EditUserFormProps): JSX.Element {
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData, setFormData],
  );

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData, setFormData],
  );

  return (
    <>
      <TitleContainer>
        <TitleHeader>Dados pessoais</TitleHeader>
      </TitleContainer>

      <FormContainer>
        <BorderFormGroup />
        <FormGroup>
          <InputContainer>
            <Label>Nome: *</Label>
            <Input
              id="name"
              name="name"
              type="text"
              defaultValue={user.name}
              onChange={handleInputChange}
            />
          </InputContainer>

          <InputContainer>
            <Label>Email: *</Label>
            <Input
              id="email"
              name="email"
              type="text"
              defaultValue={user.email}
              onChange={handleInputChange}
            />
          </InputContainer>

          <InputContainer>
            <Label>Senha:</Label>
            <Input
              id="password"
              type="password"
              name="password"
              onChange={handleInputChange}
            />
          </InputContainer>

          <InputContainer>
            <Label>Nova senha:</Label>
            <Input
              id="new_password"
              type="password"
              name="new_password"
              onChange={handleInputChange}
            />
          </InputContainer>
        </FormGroup>
      </FormContainer>

      <TitleContainer>
        <TitleHeader>Dados de acesso</TitleHeader>
      </TitleContainer>

      <FormContainer>
        <BorderFormGroup />
        <FormGroup>
          <InputContainer>
            <Label>Perfil do usuário: *</Label>
            <select
              className="select"
              name="profile_id"
              value={user.profile_id}
              onChange={handleSelectChange}
            >
              <option>Selecione uma opção</option>
              {profiles.length > 0 &&
                profiles.map(profile => (
                  <option key={profile.id} id="profile" value={profile.id}>
                    {profile.name}
                  </option>
                ))}
            </select>
          </InputContainer>

          {formData.profile_id === hunterProfileId && (
            <InputContainer>
              <Label>Time do hunter:</Label>
              <select
                name="team_id"
                className="select"
                onChange={handleSelectChange}
              >
                <option>Selecione uma opção</option>
                {teams.length > 0 &&
                  teams.map(team => (
                    <option key={team.id} id="team" value={team.id}>
                      {team.name}
                    </option>
                  ))}
              </select>
            </InputContainer>
          )}

          {formData.profile_id === closerProfileId && (
            <>
              <InputContainer>
                <Label>Ramal:</Label>
                <Input
                  min={0}
                  id="ramal"
                  name="ramal"
                  type="number"
                  placeholder="9999"
                  onChange={handleInputChange}
                />
              </InputContainer>

              <InputContainer>
                <Label>Horário de trabalho inicial: *</Label>
                <Input
                  id="work_hour_init"
                  name="work_hour_init"
                  type="time"
                  onChange={handleInputChange}
                />
              </InputContainer>

              <InputContainer>
                <Label>Horário de trabalho final: *</Label>
                <Input
                  id="work_hour_end"
                  name="work_hour_end"
                  type="time"
                  onChange={handleInputChange}
                />
              </InputContainer>
            </>
          )}
        </FormGroup>
      </FormContainer>
    </>
  );
}
