import styled from 'styled-components';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 15.5rem;
  margin-bottom: 2.875rem;
`;

export const DateAndFilterContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  @media (max-width: 1440px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: space-between;
`;

export const ClearDateButton = styled.button`
  border: none;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 0.625rem;
  color: #6d6d6d;

  width: 5.25rem;
  height: 1.875rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.938rem;
  background: #f4f4f5;
  border: 0.031rem solid #05233e;
  border-radius: 0.25rem;
  padding: 0.375rem;
`;

export const Text = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;

  color: #05233e;
`;

export const Label = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #595959;
`;

export const FilterInput = styled.input`
  width: 3rem;
  height: 1.563rem;
  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #ffffff;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const FormFilterInput = styled.input`
  width: 8rem;
  height: 1.563rem;
  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #ffffff;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
`;

export const Divisor = styled.div`
  height: 1.625rem;
  border-right: 0.063rem solid #817f7f;
`;

export const SearchButton = styled.button`
  display: flex;
  flex-direction: row;
  background: transparent;
  border: none;
  width: 0.875rem;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.313rem;

  #dragswitch-handle {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: -0.25rem;
    margin-left: -0.2rem;
  }
`;

export const TextSwitch = styled.span`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  margin-right: 0.625rem;

  color: #05233e;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3.25rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.813rem;
  gap: 1.063rem;
  width: 26rem;
  height: 9.5rem;
  background: rgba(215, 227, 245, 0.3);
  border-radius: 0.625rem;
  transition: box-shadow 0.3;

  :nth-child(2) {
    width: 13rem;
  }

  :nth-child(1):hover,
  :nth-child(3):hover {
    cursor: pointer;
    box-shadow: 0.125rem 0.125rem 2.5rem 0.125rem rgba(0, 0, 0, 0.25);
  }
`;

export const TextCard = styled.span`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  color: #3d3d3d;
`;

export const InfoCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6.625rem;
  height: 4.813rem;
  background: #f4f4f5;
  box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
`;

export const Value = styled.strong`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.275rem;
  color: #1665d8;
`;

export const Legend = styled.span`
  margin-top: 0.25rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  color: #3d3d3d;
`;

export const Table = styled.table`
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 1.813rem;
  width: 100%;

  text-align: center;
`;

export const THead = styled.thead`
  border-radius: 0.25rem;
`;

export const TRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: rgba(207, 222, 243, 0.83);

  height: 2.5rem;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  color: #05233e;
`;

export const TCellHead = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;

  width: 100%;
  height: 100%;
  gap: 0.25rem;

  border-right: 1px solid #9d9d9d;

  :last-child {
    border: none;
  }
`;

export const TBody = styled.tbody``;

export const TRowBody = styled.tr`
  margin-top: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border-radius: 0.25rem;

  min-height: 3.5rem;
  height: auto;

  word-break: break-all;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;

  color: #041524;

  :nth-child(odd) {
    background: #e5e5e5;
  }

  :nth-child(even) {
    background: #efefef;
  }
`;

export const TCell = styled.td`
  margin: 0;
  width: 100%;
  word-break: normal;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const InputCheckBox = styled.input`
  cursor: pointer;
  width: 1rem;
  height: 1rem;

  @media (max-width: 1290px) {
    padding: 0.3rem;
  }
`;
export const LoaderContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    stroke: #0c0b0b;
    height: 25px;
    width: 25px;
  }
`;

export const TextLoader = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.438rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;
