import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';
import { Body } from '../../../../components/Styles/Body';
import { Container } from '../../../../components/Container';
import { Title } from '../Title';
import { Separator } from './styles';

import { CustomerSuccessHistory } from './CustomerSuccessHistory';
import api from '../../../../services/api';

export interface ICustomerSuccessCases {
  name: string;
  avatar_url?: string;
  id: string;
  user_id: string;
  amount: string;
  count: string;
  late: number;
  schedulings: number;
  amountSum: number;
  countSum: number;
  sucesso_cliente: number;
  team: string;
}

export function CSManagerComponent(): JSX.Element {
  const [date, setDate] = useState(new Date());

  const [customerSuccessInfo, setCustomerSuccessInfo] = useState<
    ICustomerSuccessCases[]
  >([
    {
      name: ' - ',
      avatar_url: ' ',
      id: ' ',
      amount: 'R$ 0 / R$ 0',
      count: '0/0',
      late: 0,
      schedulings: 0,
      sucesso_cliente: 0,
    },
  ] as ICustomerSuccessCases[]);

  useEffect(() => {
    api
      .get(
        `/schedule-cs/all-customer-success-cases/${format(date, 'yyyy-MM-dd')}`,
      )
      .then(response => {
        setCustomerSuccessInfo(response.data);
      });
  }, [date]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Pagamento {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1NavigationNow>Gestor CS</H1NavigationNow>
      </Navegation>
      <Body>
        <Title
          name="Sucesso do Cliente"
          nameStrong=""
          isShared={false}
          separatorWidth={0}
        />
        <Separator />
        <CustomerSuccessHistory
          customerSuccessInfo={customerSuccessInfo}
          setCustomerSuccessInfo={setCustomerSuccessInfo}
        />
      </Body>
    </Container>
  );
}
