import Modal from 'react-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Main,
  ButtonsContainer,
  ApprovedButton,
  DeniedButton,
  Container,
  TitleContainer,
  DescTitle,
  ConclusionConfirmationModalStyle,
} from './styles';

interface DescriptionModalProps {
  isOpen: boolean;
  conclusionConfirmation: (variable) => void;
  onRequestClose: () => void;
  type: string;
}

export function ModalConclusionConfirmation({
  isOpen,
  conclusionConfirmation,
  onRequestClose,
  type,
}: DescriptionModalProps): JSX.Element {
  const handleCloseModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleProvidenceConclusion = useCallback(() => {
    if (type === 'Retorno credor') {
      conclusionConfirmation(false);
    } else {
      conclusionConfirmation(true);
    }
    handleCloseModal();
  }, [conclusionConfirmation, handleCloseModal, type]);

  const handleProvidenceActivityCreation = useCallback(() => {
    if (type === 'Retorno credor') {
      conclusionConfirmation(true);
    } else {
      conclusionConfirmation(false);
    }
    handleCloseModal();
  }, [conclusionConfirmation, handleCloseModal, type]);
  return (
    <Modal
      isOpen={isOpen}
      style={ConclusionConfirmationModalStyle}
      onRequestClose={handleCloseModal}
    >
      <Main>
        <Container>
          {type === 'Retorno credor' ? (
            <TitleContainer>
              <DescTitle>Deseja criar nova atividade?</DescTitle>
            </TitleContainer>
          ) : (
            <TitleContainer>
              <DescTitle>Deseja concluir a providência?</DescTitle>
            </TitleContainer>
          )}

          <ButtonsContainer>
            <DeniedButton
              onClick={() => {
                handleProvidenceActivityCreation();
              }}
            >
              Não
            </DeniedButton>{' '}
            <ApprovedButton
              onClick={() => {
                handleProvidenceConclusion();
              }}
            >
              Sim
            </ApprovedButton>
          </ButtonsContainer>
        </Container>
      </Main>
    </Modal>
  );
}
