import styled, { css } from 'styled-components';

interface StatusProps {
  statusTaskProps?: string;
  isBefore?: boolean;
  talkProps?: string;
  tryTimesProps?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 16rem;
  margin-bottom: 1rem;
  @media print {
    display: none;
  }
`;

export const BackButtonContainer = styled.div`
  margin-top: -6rem;
  margin-bottom: 3.375rem;
  margin-left: -0.8rem;

  @media (min-width: 1400px) {
    margin-left: -0.8rem;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
`;

export const BlockPriorityButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 23.75rem;
  height: 2.25rem;
  margin-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 25.313rem;
  height: 1.875rem;

  margin-bottom: 1.5rem;

  @media (min-width: 1400px) {
    margin-right: 0.2rem;
  }

  #block-today-button,
  #block-button,
  #priority-button,
  #unblock-today-button,
  #unblock-button {
    width: 10rem;
    height: 2.5rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    border: none;
    border-radius: 0.5rem;

    margin-top: 2.5rem;

    font-family: Poppins;
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;

    transition: background-color 0.2s;

    img {
      margin: 0 0.7rem 0 0.7rem;
    }
  }

  #block-button {
    background: #de0c0c;
    white-space: nowrap;
    width: 11.5rem;
  }

  #block-today-button {
    background: #de0c0c;
    white-space: nowrap;
  }

  #priority-button {
    background: #1665d8;
  }

  #unblock-today-button {
    background: #008146;

    img {
      margin: 0 0.75rem 0 1.1rem;
      margin-right: 0.75rem;
    }
  }

  #unblock-button {
    background: #008146;

    img {
      margin: 0 0.75rem 0 1.1rem;
      margin-right: 0.75rem;
    }
  }

  #block-button:hover {
    background: #931111;
  }

  #block-today-button:hover {
    background: #931111;
  }

  #unblock-today-button:hover {
    background: #005a31;
  }

  #unblock-button:hover {
    background: #005a31;
  }
`;

export const SchedulesAndActivitiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 75vw;
  max-width: 80rem;
  height: 38.75rem;

  margin-top: 2rem;
  margin-bottom: 6rem;

  p {
    font-family: Poppins;
  }
`;

export const SchedulesContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 67%;
  height: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 1.875rem;
    width: 4rem;

    background: #1665d8;

    border-radius: 0.125rem;
    margin-bottom: 1.5rem;

    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    color: #ffffff;
  }
`;

export const DaySelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviousButton = styled.button`
  margin-right: 0.563rem;
  min-width: 1.5rem;
  min-height: 1.875rem;
  border: 0.031rem solid #05233e;
  background: transparent;
  border-radius: 0.25rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div input {
    border: none;
    width: 10rem;
    color: #05233e;
  }

  &:nth-child(1) {
    margin-right: 5.05rem;
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0.031rem solid #063966;
  border-radius: 0.25rem;

  width: 12.063rem;
  height: 1.875rem;

  padding: 0.313rem;

  input {
    padding-left: 1.196rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const NextButton = styled.button`
  margin-left: 0.563rem;
  min-width: 1.5rem;
  min-height: 1.875rem;
  border: 0.031rem solid #05233e;
  background: transparent;
  border-radius: 0.25rem;
`;

export const ScheduleTasksContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 90%;

  .dateHeader {
    border-bottom: 0.063rem solid rgba(194, 194, 194, 0.5);
  }

  .scheduleContainer {
    border-top: 0.063rem solid rgba(194, 194, 194, 0.5);
  }
`;

export const ScheduleLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  min-height: 4.5rem;

  p {
    font-weight: 600;
    font-size: 0.938rem;
    color: #05233e;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.5%;
`;

export const LineTableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  width: 92.4%;
  height: 100%;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  #restrictedTime {
    align-items: flex-start;
  }
`;

export const DateHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p#title {
    font-weight: 700;
    font-size: 1.5rem;
    color: #041524;
  }

  p#subtitle {
    font-weight: 600;
    font-size: 0.75rem;
    color: #041524;
  }
`;

export const ButtonsVisualizationContainer = styled.div`
  display: flex;
  align-items: flex-start;

  padding-top: 0.95rem;
  margin-right: 0.5rem;
  height: 100%;

  gap: 0.188rem;

  button {
    background: none;
    border: none;
  }
`;

export const ActivitiesWithNotHourDefinedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const TitleNotHourDefinedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f4f4f5;
  border-radius: 0.125rem;

  margin-bottom: 1.5rem;

  height: 1.875rem;

  p {
    font-weight: 600;
    font-size: 0.875rem;
    color: #041524;
  }
`;

export const NotDefinedHourContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 35rem;

  background: rgba(22, 101, 216, 0.05);
  border-radius: 0.125rem;
`;

export const SearchByNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  margin-top: 1.25rem;
  margin-bottom: 1.875rem;

  height: 2.125rem;
  width: 88%;
  padding: 0 0.688rem;

  background: #d8d8d8;
  border-radius: 1.25rem;

  input {
    font-weight: 300;
    font-size: 0.75rem;
    color: #6d6d6d;
    border-radius: 1.25rem;
    background: #d8d8d8;
    border: none;

    height: 2.125rem;
    width: 95%;
  }
`;

export const Activities = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;

  overflow: auto;
  scroll-behavior: smooth;
`;

export const ActivityContainer = styled.div<StatusProps>`
  display: flex;
  flex-direction: column;

  cursor: pointer;

  width: 89%;

  margin-bottom: 1.438rem;

  background: rgba(61, 61, 61, 0.1);
  box-shadow: inset 0.188rem 0 0 #3d3d3d, 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);

  ${props =>
    props.statusTaskProps === 'Concluída' && props.talkProps === 'Sim'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 #3ca647,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.tryTimesProps > '0' && props.tryTimesProps !== 'null'
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 #b4ad09,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : css`
          background: rgba(61, 61, 61, 0.1);
          box-shadow: inset 0.188rem 0 0 #de0c0c,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `}

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  p {
    font-family: Poppins;
    color: #3d3d3d;
    font-weight: 600;
    margin-left: 0.375rem;
  }

  p#define,
  p#priority {
    font-size: 0.85rem;
  }

  p.credor {
    font-size: 0.65rem;
  }

  div#headerActivity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div#stage-try-times {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-top: 0.25rem;
      height: 1.125rem;
    }

    span {
      font-weight: bold;
      margin-right: 0.375rem;

      ${props =>
        props.statusTaskProps === 'Concluída'
          ? css`
              color: #3ca647;
            `
          : props.tryTimesProps > '0' && props.tryTimesProps !== 'null'
          ? css`
              color: #b4ad09;
            `
          : css`
              color: #de0c0c;
            `}
    }

    p#stage {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.125rem;

      padding: 0 0.25rem 0 0.25rem;
      border: 0.5px solid #3d3d3d;
      border-radius: 0.25rem;
      margin-right: 0.375rem;
      margin-top: 0.25rem;

      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 0.625rem;
    }
  }
`;
