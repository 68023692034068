import React, { useCallback, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_blue.svg';

import {
  Container,
  DaySelectedContainer,
  ContainerInput,
  CalendarContainer,
} from './styles';

interface IProps {
  initialDate: Date;
  finalDate: Date;
  dayClickFrom: (day: Date) => void;
  dayClickTo: (day: Date) => void;
}

export const InputInitialAndFinalDayPicker: React.FC<IProps> = ({
  initialDate,
  finalDate,
  dayClickFrom,
  dayClickTo,
}) => {
  const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  const WEEKDAYS_LONG = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  const FORMAT = 'dd/MM/yyyy';

  const [inputEndDate, setInputEndDate] = useState<DayPickerInput>(undefined);

  const formatDate = useCallback((date, format, locale) => {
    return dateFnsFormat(date, format, { locale });
  }, []);

  const modifiers = {
    start: initialDate,
    end: finalDate,
    endOfMonth:
      initialDate &&
      new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0),
    startOfMonth:
      finalDate && new Date(finalDate.getFullYear(), finalDate.getMonth(), 1),
    sunday: { daysOfWeek: [0] },
    saturday: { daysOfWeek: [6] },
  };

  return (
    <Container>
      <DaySelectedContainer>
        <ContainerInput>
          <CalendarContainer>
            <CalendarIcon />
            <DayPickerInput
              value={initialDate}
              placeholder="Início"
              formatDate={formatDate}
              format={FORMAT}
              dayPickerProps={{
                selectedDays: [
                  initialDate,
                  { from: initialDate, to: finalDate },
                ],
                disabledDays: { after: finalDate },
                month:
                  finalDate &&
                  new Date(finalDate.getFullYear(), finalDate.getMonth()),
                toMonth: finalDate,
                months: MONTHS,
                weekdaysShort: WEEKDAYS_SHORT,
                weekdaysLong: WEEKDAYS_LONG,
                modifiers,
                numberOfMonths: 1,
                onDayClick: () => inputEndDate.getInput().focus(),
              }}
              onDayChange={dayClickFrom}
            />{' '}
          </CalendarContainer>
          <span className="text-to"> até</span>
          <CalendarContainer>
            <CalendarIcon />
            <DayPickerInput
              ref={final_date => setInputEndDate(final_date)}
              value={finalDate}
              placeholder="Fim"
              formatDate={formatDate}
              format={FORMAT}
              dayPickerProps={{
                selectedDays: [
                  initialDate,
                  { from: initialDate, to: finalDate },
                ],
                disabledDays: { before: initialDate },
                fromMonth: initialDate,
                month:
                  initialDate &&
                  new Date(initialDate.getFullYear(), initialDate.getMonth()),
                months: MONTHS,
                weekdaysShort: WEEKDAYS_SHORT,
                weekdaysLong: WEEKDAYS_LONG,
                modifiers,
                numberOfMonths: 1,
              }}
              onDayChange={dayClickTo}
            />
          </CalendarContainer>
        </ContainerInput>
      </DaySelectedContainer>
    </Container>
  );
};
