import axios from 'axios';
import { ChangeEvent, useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Input,
  SaveButton,
  Select,
  SelectCell,
  SelectOption,
  SelectOptions,
  TableCell,
  TableRow,
} from './styles';

interface ICreateTableRowProps {
  enabled: boolean;
  closeCreationMode: () => void;
  getSunshinePhones: () => void;
}

export function CreateTableRow({
  closeCreationMode,
  enabled,
  getSunshinePhones,
}: ICreateTableRowProps): JSX.Element {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [integrationId, setIntegrationId] = useState('');
  const [connectionSource, setConnectionSource] = useState('');
  const [enableSelect, setEnableSelect] = useState(false);

  const editName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const editPhone = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  }, []);

  const editIntegrationId = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIntegrationId(event.target.value);
    },
    [],
  );

  const editConnectionSource = useCallback((newConnectionSource: string) => {
    setConnectionSource(newConnectionSource);

    setEnableSelect(false);
  }, []);

  const changeSelectStatus = useCallback(() => {
    setEnableSelect(!enableSelect);
  }, [enableSelect]);

  const savePhone = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_MARKETING_URL}/sunshine/phone`,
        {
          name,
          phone,
          integration_id: integrationId,
          connection_source: connectionSource,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      Swal.fire('Success!', 'Successfully created Sunshine phone!', 'success');

      getSunshinePhones();
    } catch (error) {
      Swal.fire(
        `${error.response.status}!`,
        error.response.data.message,
        'error',
      );
    }

    closeCreationMode();
  }, [
    closeCreationMode,
    connectionSource,
    getSunshinePhones,
    integrationId,
    name,
    phone,
  ]);

  return (
    <TableRow className="tr-body" enabled={enabled}>
      <TableCell width="17.1875rem">
        <Input width="14.4375rem" defaultValue={name} onChange={editName} />
      </TableCell>

      <TableCell width="15.3125rem">
        <Input width="11.4375rem" defaultValue={phone} onChange={editPhone} />
      </TableCell>

      <TableCell width="17.375rem">
        <Input width="15.25rem" onChange={editIntegrationId} />
      </TableCell>

      <SelectCell>
        <Select disabled={enableSelect} onClick={changeSelectStatus}>
          {connectionSource}
        </Select>

        <SelectOptions enabled={enableSelect}>
          <SelectOption
            onClick={() => editConnectionSource('EMAIL')}
            className="first-child"
          >
            EMAIL
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('FACEBOOK')}>
            FACEBOOK
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('GOOGLE')}>
            GOOGLE
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('INDICAÇÃO (TAG)')}>
            INDICAÇÃO (TAG)
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('SITE')}>
            SITE
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('SMS')}>
            SMS
          </SelectOption>

          <SelectOption onClick={() => editConnectionSource('WPP')}>
            WPP
          </SelectOption>

          <SelectOption
            onClick={() => editConnectionSource('RCS')}
            className="last-child"
          >
            RCS
          </SelectOption>
        </SelectOptions>
      </SelectCell>

      <TableCell width="6.5rem">
        <SaveButton onClick={savePhone}>Concluir</SaveButton>
      </TableCell>
    </TableRow>
  );
}
