import styled, { css } from 'styled-components';
import checkIcon from '../../../assets/icons/check_white.svg';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 12rem;
  margin-bottom: 2.438rem;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.25rem;
`;

export const FileInputContainer = styled.div`
  width: 26.438rem;
  height: 5rem;
  border-radius: 0.625rem;
  background: #cfdef3;

  padding-top: 1.125rem;

  display: flex;
  justify-content: space-evenly;
`;

export const FileInputSpan = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  color: #041524;
`;

export const FileSendDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
`;

export const FileSelectLabel = styled.label`
  background: #c4c4c4;
  border-radius: 0.313rem;
  border: 0;
  width: 7.938rem;
  height: 1.625rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.688rem;
  text-align: center;
  color: #4e4e4e;

  &:hover {
    cursor: pointer;
  }
`;

export const FileSelectInput = styled.input`
  display: none;
`;

export const FileSelectButtonDescription = styled.span`
  display: flex;
  justify-content: center;
  width: 7.563rem;
  margin: auto;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  text-align: center;
  color: #666666;
`;

interface IFileSendButtonProps {
  isActive: boolean;
}

export const FilterByTrigger = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 2.5rem;
  margin-bottom: -2.5rem;
`;

interface ButtonTriggerProps {
  isSelected: boolean;
}

export const ButtonTrigger = styled.button<ButtonTriggerProps>`
  width: 12rem;
  height: 2.5rem;

  border: none;
  border-radius: 0.25rem;

  font-weight: 400;
  font-size: 1.5rem;

  transition: background-color 0.2s;

  ${props =>
    props.isSelected
      ? css`
          color: var(--blue-700);
          background: var(--blue-100);

          &:hover {
            color: var(--gray-700);
            background: var(--gray-300);
          }
        `
      : css`
          color: var(--gray-700);

          &:hover {
            color: var(--blue-700);
            background: var(--blue-100);
          }
        `}
`;

export const FileSendButton = styled.button<IFileSendButtonProps>`
  width: 7.938rem;
  height: 1.625rem;
  border: 0;
  border-radius: 0.313rem;
  background: ${props => (props.isActive ? '#041524' : '#c4c4c4')};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  text-align: center;
  color: #ffffff;
`;

export const FileSendButtonDescription = styled.span`
  display: flex;
  justify-content: center;
  width: 10.188rem;
  margin-top: 0.5625rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  text-align: center;
  color: #666666;
  align-items: center;
`;

export const TableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.875rem;
`;

export const VerificationDelayCheckbox = styled.input`
  width: 0.625rem;
  height: 0.625rem;
  border: 0.0625rem solid #05233e;
  border-radius: 0.1875rem;
  background: #d9d9d9;
  appearance: none;
  margin-right: 0.4375rem;

  &:hover {
    cursor: pointer;
  }

  &:checked {
    background-color: #05233e;
    background-image: url(${checkIcon});
    background-position: center;
    background-repeat: no-repeat;
  }
`;
