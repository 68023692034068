import { shade } from 'polished';
import styled from 'styled-components';

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.875rem;
`;

export const SearchContainer = styled.label`
  width: 26rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: var(--gray-200);
  border-radius: 1.875rem;

  img {
    width: 1rem;
    height: 1rem;

    margin-right: 0.938rem;
  }
`;

export const InputSearch = styled.input`
  width: 24rem;
  height: 100%;

  background: var(--gray-200);

  border-radius: 1.875rem 0 0 1.875rem;
  padding-left: 1rem;
  border: none;

  font-size: 0.938rem;
  color: var(--blue-800);

  &::placeholder {
    color: var(--gray-400);
  }
`;

export const ButtonNewUser = styled.button`
  width: 8rem;
  height: 2rem;
  color: var(--white);
  border: none;

  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;

  background: var(--green-600);
  border-radius: 0.25rem;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.3, '#089B63')};
  }
`;

export const Table = styled.table`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableHead = styled.thead`
  width: 100%;

  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--blue-100) 83%;

    border-radius: 0.25rem;
    margin-bottom: 0.688rem;

    height: 2.5rem;

    th:nth-child(1) {
      width: 25%;

      .icon-head {
        width: 8%;
      }
      span {
        width: 92%;
        padding-right: 8%;
      }
    }

    th:nth-child(2) {
      width: 25%;

      .icon-head {
        width: 9%;
      }

      span {
        width: 91%;
        padding-right: 9%;
      }
    }

    th:nth-child(3) {
      width: 25%;

      .icon-head {
        width: 10%;
      }

      span {
        width: 90%;
        padding-right: 10%;
      }
    }

    th:nth-child(4) {
      width: 25%;

      .icon-head {
        width: 8%;
      }

      span {
        width: 92%;
        padding-right: 15%;
      }
    }

    th {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 600;
      font-size: 1rem;
      text-align: center;
      color: var(--blue-700);

      border-right: 0.063rem solid rgba(6, 57, 102, 0.64);

      &:nth-child(4) {
        border-right: 0;
      }
    }
  }
`;

export const TextTitle = styled.span`
  width: 100%;
`;

export const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  tr:nth-child(even) {
    background: var(--gray-100);
  }

  tr:nth-child(odd) {
    background: var(--gray-50);
  }

  tr {
    display: flex;

    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
    height: 3.5rem;

    td:nth-child(1) {
      width: 25%;

      img {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
      }

      .icon-body {
        width: 18%;
        display: flex;
        justify-content: center;
      }

      span {
        width: 82%;
        padding-right: 18%;
        text-align: center;
      }
    }

    td:nth-child(2) {
      width: 25%;
    }

    td:nth-child(3) {
      width: 25%;
    }

    td:nth-child(4) {
      width: 25%;

      display: flex;
      justify-content: flex-end;

      span {
        width: 80%;
        padding-left: 20%;
        text-align: center;
      }

      .icon-body {
        width: 20%;
        display: flex;
        justify-content: center;

        gap: 0.375rem;
      }
    }

    td {
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 300;
      font-size: 0.938rem;
      text-align: center;

      color: var(--blue-700);
    }
  }
`;

export const IconForm = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
`;

export const ButtonDelete = styled.button`
  border: none;
  border-radius: 0.3rem;

  background-color: var(--gray-900);
  width: 1.438rem;
  height: 1.375rem;

  color: var(--white);
  transition: background-color 0.2s;
`;

export const ButtonEdit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.3rem;

  background-color: var(--blue-600);
  width: 1.438rem;
  height: 1.375rem;

  color: var(--white);
`;

export const ContainerExport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0.5rem 0 1rem 0;
`;

export const Export = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  gap: 0.5rem;

  font-size: 0.813rem;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--blue-700);
`;
