import { ChangeEvent, useCallback, useState } from 'react';
import {
  HolidayCheckbox,
  HolidayLabel,
  TableCell,
  TableRow,
  TimeInput,
} from './styles';

interface IEditTableRowProps {
  id: number;
  startHour: string;
  changeStartHour: (id: number, start_hour: number) => void;
  startMinute: string;
  changeStartMinute: (id: number, start_minute: number) => void;
  endHour: string;
  changeEndHour: (id: number, end_hour: number) => void;
  endMinute: string;
  changeEndMinute: (id: number, end_minute: number) => void;
  holiday: boolean;
  changeHoliday: (id: number) => void;
  changeValidRow: (id: number, value: boolean) => void;
}

export function EditTableRow({
  changeStartHour,
  startHour,
  changeEndHour,
  changeEndMinute,
  changeStartMinute,
  endHour,
  endMinute,
  startMinute,
  changeHoliday,
  holiday,
  id,
  changeValidRow,
}: IEditTableRowProps): JSX.Element {
  const [validStartHour, setValidStartHour] = useState(true);
  const [validStartMinute, setValidStartMinute] = useState(true);
  const [validEndHour, setValidEndHour] = useState(true);
  const [validEndMinute, setValidEndMinute] = useState(true);

  const weekDays = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  const validateHour = useCallback((hour: string) => {
    return /^\d+$/g.test(hour) && Number(hour) >= 0 && Number(hour) <= 23;
  }, []);

  const validateMinute = useCallback((hour: string) => {
    return /^\d+$/g.test(hour) && Number(hour) >= 0 && Number(hour) <= 59;
  }, []);

  const selectStartHour = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const validHour = validateHour(event.target.value);

      changeValidRow(
        id,
        validHour && validStartMinute && validEndHour && validEndMinute,
      );

      setValidStartHour(validHour);

      if (validHour) {
        changeStartHour(id, Number(event.target.value));
      }
    },
    [
      changeStartHour,
      changeValidRow,
      id,
      validEndHour,
      validEndMinute,
      validStartMinute,
      validateHour,
    ],
  );

  const selectStartMinute = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const validMinute = validateMinute(event.target.value);

      changeValidRow(
        id,
        validMinute && validStartHour && validEndHour && validEndMinute,
      );

      setValidStartMinute(validMinute);

      if (validMinute) {
        changeStartMinute(id, Number(event.target.value));
      }
    },
    [
      changeStartMinute,
      changeValidRow,
      id,
      validEndHour,
      validEndMinute,
      validStartHour,
      validateMinute,
    ],
  );

  const selectEndHour = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const validHour = validateHour(event.target.value);

      changeValidRow(
        id,
        validHour && validStartHour && validStartMinute && validEndMinute,
      );

      setValidEndHour(validHour);

      if (validHour) {
        changeEndHour(id, Number(event.target.value));
      }
    },
    [
      changeEndHour,
      changeValidRow,
      id,
      validEndMinute,
      validStartHour,
      validStartMinute,
      validateHour,
    ],
  );

  const selectEndMinute = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const validMinute = validateMinute(event.target.value);

      changeValidRow(
        id,
        validMinute && validStartHour && validStartMinute && validEndHour,
      );

      setValidEndMinute(validMinute);

      if (validMinute) {
        changeEndMinute(id, Number(event.target.value));
      }
    },
    [
      changeEndMinute,
      changeValidRow,
      id,
      validEndHour,
      validStartHour,
      validStartMinute,
      validateMinute,
    ],
  );

  return (
    <TableRow className="tr-body">
      <TableCell
        width="13.5rem"
        font-weight={400}
        font-size="0.875rem"
        line-height="1.3125rem"
      >
        {weekDays[id]}
      </TableCell>

      <TableCell
        width="15rem"
        font-weight={500}
        font-size="1.125rem"
        line-height="1.6875rem"
      >
        <TimeInput
          disabled={holiday}
          defaultValue={holiday ? 'Folga' : startHour}
          valid={validStartHour}
          onChange={selectStartHour}
        />
        :
        <TimeInput
          disabled={holiday}
          defaultValue={holiday ? 'Folga' : startMinute}
          valid={validStartMinute}
          onChange={selectStartMinute}
        />
      </TableCell>

      <TableCell
        width="15rem"
        font-weight={500}
        font-size="1.125rem"
        line-height="1.6875rem"
      >
        <TimeInput
          disabled={holiday}
          defaultValue={holiday ? 'Folga' : endHour}
          valid={validEndHour}
          onChange={selectEndHour}
        />
        :
        <TimeInput
          disabled={holiday}
          defaultValue={holiday ? 'Folga' : endMinute}
          valid={validEndMinute}
          onChange={selectEndMinute}
        />
      </TableCell>

      <TableCell
        width="9.1875rem"
        font-weight={500}
        font-size="1.125rem"
        line-height="1.6875rem"
      >
        <HolidayLabel checked={holiday}>
          <HolidayCheckbox onClick={() => changeHoliday(id)} />
        </HolidayLabel>
      </TableCell>
    </TableRow>
  );
}
