import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';
import { apiByState } from '../../../services/apiTjs';
import {
  RegionAndProcessContainer,
  RegionSelectTitleContainer,
  RegionContentContainer,
  ProcessFileContainer,
  RegionContainer,
  SelectFileContainer,
  FilesHistoryContainer,
  TitleHistory,
  ButtonContainer,
  ClearHitoryButton,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
} from './styles';
import { locationsOC } from '../../../utils/statesOC';
import { cleanString } from '../../../utils/cleanString';
import { ExampleFileTrfDataExtraction } from '../components/ExampleFileTrfDataExtraction';
import { ExampleFileTjsOc } from '../components/ExampleFileTjsOc';

interface ILocation {
  id: number;
  name: string;
  route: string;
  initials: string;
  region: string;
  fetchEntities: boolean;
}

interface FileHistory {
  id: number;
  name: string;
  court: string;
  lines: string;
  processed: string;
  created_at: string;
  created_at_brazil: string;
  file_url: string;
  zip_url: string;
  zip_name: string;
}

interface ISearch {
  input: string;
  hinput: string;
}

interface IEntities {
  label: string;
  value: ISearch;
}

export const TjsOc: React.FC = () => {
  const [states, setStates] = useState<ILocation[]>([]);
  const [state, setState] = useState<ILocation>({} as ILocation);
  const [court, setCourt] = useState<string>('');
  const [filesHistory, setFilesHistory] = useState<FileHistory[]>([]);
  const [files, setFiles] = useState<FileList[]>([]);
  const [fileLength, setFileLength] = useState(0);
  const [options, setOptions] = useState<IEntities[]>([]);
  const [selected, setSelected] = useState<IEntities[]>([]);

  const handleSelectRegion = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      const statesByRegion = locationsOC.filter(
        location => location.region === value,
      );

      setStates(statesByRegion);
    },
    [],
  );

  const handleSelectState = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      const location = locationsOC.find(
        findLocation => findLocation.initials === value,
      );

      setState(location);

      setCourt(`TJ${location.initials}`);
    },
    [],
  );

  useEffect(() => {
    if (state.name) {
      const apiState = apiByState(cleanString(state.route));

      apiState.get(`files/process_files?court=${court}`).then(response => {
        setFilesHistory(response.data);
      });
    }
  }, [state, court]);

  useEffect(() => {
    if (state.fetchEntities) {
      const apiState = apiByState(cleanString(state.route));
      apiState.get(`tjs/${state.initials.toLowerCase()}`).then(response => {
        setOptions(response.data);
      });
    }
  }, [state, court]);

  const handleLoadFilesHistory = useCallback(async () => {
    const apiState = apiByState(cleanString(state.route));

    const response = await apiState.get<FileHistory[]>(
      `files/process_files?court=${court}`,
    );

    setFilesHistory(response.data);
  }, [court, state]);

  const handleSubmitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filesFormatted = [];

      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index];

        filesFormatted.push(file);
      }

      setFiles(filesFormatted);

      setFileLength(event.target.files.length);

      event.target.value = null;
    },
    [setFiles],
  );

  const handleUploadFile = useCallback(async () => {
    const data = new FormData();

    const apiState = apiByState(cleanString(state.route));

    try {
      if (!state.fetchEntities) {
        files.forEach(oneFile => {
          data.append('files', oneFile as any);
        });

        await apiState.post(`tjs/${state.initials.toLowerCase()}`, data);

        setFiles([]);

        setFileLength(0);

        Swal.fire('Sucesso!', 'Requisição recebida com sucesso', 'success');
      } else {
        await apiState.post(`tjs/${state.initials.toLowerCase()}`, {
          searchs: selected,
        });

        Swal.fire('Sucesso!', 'Requisição recebida com sucesso', 'success');
      }
    } catch (error) {
      Swal.fire('Erro!', 'Falha ao enviar requisição.', 'error');
    }
  }, [files, selected, state]);

  const handleDownload = useCallback(
    async (url: string, file_name: string) => {
      const apiState = apiByState(cleanString(state.route));

      const index = url.indexOf(file_name);

      const fileName = url.slice(index + 8, url.length);

      const response = await apiState.get(url, {
        responseType: 'blob',
      });

      saveAs(response.data, fileName);
    },
    [state],
  );

  return (
    <Container>
      <NavigationBar
        past_screens="Processsamento > "
        active_screen="Cronologia TJ's"
      />

      <Body>
        <Title name="Processamento de arquivos - Cronologia TJ's" />

        <RegionAndProcessContainer>
          <RegionContainer>
            <RegionSelectTitleContainer>
              Selecione a região e o estado respectivo:
            </RegionSelectTitleContainer>
            <RegionContentContainer>
              <select defaultValue="Região" onChange={handleSelectRegion}>
                <option value="Região">Região</option>
                <option value="Norte">Norte</option>
                <option value="Nordeste">Nordeste</option>
                <option value="Sudeste">Sudeste</option>
                <option value="Sul">Sul</option>
                {/* <option value="Centro-Oeste">Centro-Oeste</option> */}
              </select>
              <select defaultValue="Estado" onChange={handleSelectState}>
                <option value="Estado">Estado</option>
                {states.map(mapState => (
                  <option key={mapState.id} value={mapState.initials}>
                    {mapState.name}
                  </option>
                ))}
              </select>
            </RegionContentContainer>
          </RegionContainer>

          <ProcessFileContainer>
            <SelectFileContainer>
              {!state.fetchEntities ? (
                <ExampleFileTrfDataExtraction
                  handleUpload={handleUploadFile}
                  submitFile={handleSubmitFile}
                  files_length={fileLength}
                />
              ) : (
                <ExampleFileTjsOc
                  handleUpload={handleUploadFile}
                  optionsSearch={options}
                  setSelecte={setSelected}
                />
              )}
            </SelectFileContainer>
          </ProcessFileContainer>
        </RegionAndProcessContainer>

        <FilesHistoryContainer>
          <TitleHistory>Processamento anteriores</TitleHistory>
          <ButtonContainer>
            <ClearHitoryButton type="button" onClick={handleLoadFilesHistory}>
              Atualizar listagem
            </ClearHitoryButton>
          </ButtonContainer>
        </FilesHistoryContainer>

        <Table>
          <Thead>
            <TableRowHead>
              <CellHeader>
                <TitleTable>Data de importação</TitleTable>
                <Divisor />
              </CellHeader>
              <CellHeader>
                <TitleTable>Nome do arquivo CSV</TitleTable>
                <Divisor />
              </CellHeader>
              <CellHeader>
                <TitleTable>Situação</TitleTable>
                <Divisor />
              </CellHeader>
            </TableRowHead>
          </Thead>
          <Tbody>
            {filesHistory.length > 0 &&
              filesHistory.map(fileHistory => (
                <TableRowBody key={fileHistory.id}>
                  <CellBody>{fileHistory.created_at}</CellBody>
                  <CellBody
                    onClick={() =>
                      handleDownload(fileHistory.file_url, 'uploads')
                    }
                  >
                    {fileHistory.name}
                  </CellBody>
                  <CellBody>
                    (Processado {fileHistory.processed} de {fileHistory.lines})
                  </CellBody>
                </TableRowBody>
              ))}
          </Tbody>
        </Table>
      </Body>
    </Container>
  );
};
