import styled from 'styled-components';

interface IModalHeaderProps {
  process_type: string;
}

export const ModalHeader = styled.div<IModalHeaderProps>`
  width: 100%;
  height: 3.5rem;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${props =>
    props.process_type === 'standby'
      ? '#B4AD09'
      : props.process_type === 'blocked'
      ? '#EB5757'
      : '#1665D8'};

  padding: 1.5rem;
`;

export const ModalHeaderTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--white);
`;

export const ModalBody = styled.div`
  margin: 1.5rem 1.5rem 1.5rem;
`;

export const ModalTitle = styled.div`
  padding: 0.8rem;
  margin-top: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;

  background: var(--blue-100);
  border-radius: 0.4rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;

  color: var(--blue-700);
`;

export const InformationTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;

  color: var(--blue-800);
`;

export const InformationValue = styled.div`
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 0.9rem;
  letter-spacing: -0.015em;

  padding: 0.5rem;
  margin-top: 0.5rem;
  height: 2.25rem;
  align-items: center;

  color: #3d3d3d;

  border: 0.1rem solid var(--blue-800);
  box-sizing: border-box;
  border-radius: 0.4rem;

  p {
    margin: auto;
  }
`;

export const CreditorInformationGrid = styled.div`
  margin-top: 0.5rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0.5rem 0.5rem;
  grid-template-areas:
    'name name name name name cpf cpf'
    'precatory precatory originating_process originating_process originating_process precatory_per_originating precatory_per_originating'
    'total_value total_value trf loa highlighted_honorary highlighted_honorary highlighted_honorary'
    'status status status in_hubspot motive motive motive';

  .name {
    grid-area: name;
  }
  .cpf {
    grid-area: cpf;
  }
  .precatory {
    grid-area: precatory;
  }
  .total_value {
    grid-area: total_value;
  }
  .originating_process {
    grid-area: originating_process;
  }
  .loa {
    grid-area: loa;
  }
  .trf {
    grid-area: trf;
  }
  .status {
    grid-area: status;
  }
  .highlighted_honorary {
    grid-area: highlighted_honorary;
  }
  .precatory_per_originating {
    grid-area: precatory_per_originating;
  }
  .in_hubspot {
    grid-area: in_hubspot;
  }
  .motive {
    grid-area: motive;
  }
`;

export const CInformationCell = styled.div``;

export const LawyerInformationGrid = styled.div`
  margin-top: 0.5rem;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 1fr;
  gap: 0.5rem 0.5rem;
  grid-template-areas: 'lawyer_name lawyer_cpf';

  .lawyer_name {
    grid-area: lawyer_name;
  }
  .lawyer_cpf {
    grid-area: lawyer_cpf;
  }
`;

export const Icon = styled.img`
  width: 1.25rem;
  cursor: pointer;
`;
export const LInformationCell = styled.div``;

export const ModalStyle = {
  content: {
    width: '60rem',
    height: '37.5rem',
    margin: 'auto',
    borderRadius: '0.4rem',
    padding: '0',
  },
};
