export function crawlerFinishTimeForecast(
  doneProcesses: number,
  totalProcesses: number,
  startMs: number,
): number {
  const now = Date.now();
  const elapsed = now - startMs;
  const avgTimePerProcess = elapsed / doneProcesses;
  const remaining = (totalProcesses - doneProcesses) * avgTimePerProcess;
  return remaining;
}
