import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from '../../components/Container';
import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';
import {
  DivSeparator,
  InfoContainer,
  LineSeparator,
  Separator,
  ColumnContainer,
  TitleColumnContainer,
  IconForm,
  TextContainer,
  Box,
  BoxBody,
  BoxHeader,
  BoxRow,
  BoxItem,
} from './styles';

import {
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';

import approved_icon from '../../assets/icons/pricing_approved.svg';
import denied_icon from '../../assets/icons/pricing_denied.svg';
import pending_icon from '../../assets/icons/pricing_pending.svg';
import in_progress_icon from '../../assets/icons/pricing_in_progress.svg';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

export interface IPricingPlaces {
  id: string;
  place_name: string;
}

interface IApprovedStatus {
  approveds: number;
  unapproveds: number;
  pendents: number;
  open_places: number;
}

export function EntitiesPricification(): JSX.Element {
  const [places, setPlaces] = useState<IPricingPlaces[]>([]);
  const [cities, setCities] = useState<IPricingPlaces[]>([]);
  const [states, setStates] = useState<IPricingPlaces[]>([]);
  const [approvedStatus, setApprovedStatus] = useState<IApprovedStatus>(
    {} as IApprovedStatus,
  );
  const { user } = useAuth();

  const history = useHistory();

  const handleChangePage = useCallback(
    (place_id: string, place_name: string) => {
      history.push({
        pathname: `/pricing/state`,
        state: {
          place_name,
          place_id,
          lastPage: 'Home',
        },
      });
    },
    [history],
  );

  useEffect(() => {
    const foundCities: IPricingPlaces[] = [];
    places.forEach(place => {
      if (place.place_name.includes('-')) foundCities.push(place);
    });

    setCities(foundCities);

    const foundStates: IPricingPlaces[] = [];
    places.forEach(place => {
      if (!place.place_name.includes('-')) foundStates.push(place);
    });

    setStates(foundStates);
  }, [places]);

  useEffect(() => {
    api.get(`/pricing/places/${user.profile}`).then(response => {
      setPlaces(response.data);
    });
    api.get('/pricing/forms-info').then(response => {
      setApprovedStatus(response.data);
    });
  }, [user]);

  return (
    <Container>
      <Navegation>
        <H1NavigationNow>Precificação</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Precificação de entes</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <InfoContainer>
          <ColumnContainer>
            <TitleColumnContainer>
              <p>Precificações aprovadas</p>
            </TitleColumnContainer>

            <IconForm className="icon-head">
              <img src={approved_icon} alt="Pricing approved icon" />
              <TextContainer>{approvedStatus.approveds}</TextContainer>
            </IconForm>
          </ColumnContainer>

          <LineSeparator />

          <ColumnContainer>
            <TitleColumnContainer>
              <p>Precificações reprovadas</p>
            </TitleColumnContainer>

            <IconForm className="icon-head">
              <img src={denied_icon} alt="Pricing denied icon" />
              <TextContainer>{approvedStatus.unapproveds}</TextContainer>
            </IconForm>
          </ColumnContainer>

          <LineSeparator />

          <ColumnContainer>
            <TitleColumnContainer>
              <p>Precificações pendentes de aprovação</p>
            </TitleColumnContainer>

            <IconForm className="icon-head">
              <img src={pending_icon} alt="Pricing pending icon" />
              <TextContainer>{approvedStatus.pendents}</TextContainer>
            </IconForm>
          </ColumnContainer>

          <LineSeparator />

          <ColumnContainer>
            <TitleColumnContainer>
              <p>Precificações em andamento</p>
            </TitleColumnContainer>

            <IconForm className="icon-head">
              <img src={in_progress_icon} alt="Pricing in progress icon" />
              <TextContainer>{approvedStatus.open_places}</TextContainer>
            </IconForm>
          </ColumnContainer>
        </InfoContainer>

        <BoxRow>
          <Box>
            <BoxHeader>Municípios</BoxHeader>
            <BoxBody>
              {cities.length > 0
                ? cities.map(city => {
                    return (
                      <BoxItem
                        onClick={() =>
                          handleChangePage(city.id, city.place_name)
                        }
                      >
                        {city.place_name.toUpperCase()}
                      </BoxItem>
                    );
                  })
                : 'Não existem municípios precificados'}
            </BoxBody>
          </Box>

          <Box>
            <BoxHeader>Estados</BoxHeader>
            <BoxBody>
              {states.length > 0
                ? states.map(state => {
                    return (
                      <BoxItem
                        onClick={() =>
                          handleChangePage(state.id, state.place_name)
                        }
                      >
                        {state.place_name.toUpperCase()}
                      </BoxItem>
                    );
                  })
                : 'Não existem estados precificados'}
            </BoxBody>
          </Box>
        </BoxRow>
      </Body>
    </Container>
  );
}
