import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { gql, useMutation } from '@apollo/client';
import { CurrencyInputProps } from 'react-currency-input-field';
import api from '../../../../services/api';
import { Title } from '../../../../components/Title';
import { useAuth } from '../../../../hooks/auth';
import { Loading } from '../../../../components/Loading';
import { createFirstPassClarificationNote } from '../utils/createClarificationNote';
import {
  autority,
  whoContacted,
  connectionMethod,
  influences,
  objections,
  plans,
  profiles,
  reactions,
  reasonForScheduling,
  doubts,
  processKnowledge,
  proposalExplanation,
  connection_types,
} from '../utils/noteAmountByInput';

import {
  contactWithCompetitor,
  customerPriority,
  decisionReview,
  informedNegotiableValue,
  whyDidntAnticipateBefore,
} from '../utils/retakeNoteAmountByInput';

import {
  SuccessfulModalStyle,
  Main,
  InputContainer,
  Label,
  Select,
  Input,
  CheckboxContainer,
  Checkbox,
  LabelCheckbox,
  Textarea,
  ButtonSave,
} from './styles';
import { IHunterDTO } from '../dtos/IHunterDTO';
import { ModalSLA } from '../../components/ModalSLA';
import { ISLAN5 } from '../../components/ModalSLA/interface';
import { optIn } from '../../components/ModalSLA/utils/optionsSLA';

interface Closer {
  name: string;
  user_id: string;
  inactive: boolean;
}

interface FormData {
  closer_id?: string;
  who_contacted: string;
  valid_contact: string;
  valid_email: string;
  connection_type: string;
  connection_method: string;
  opt_in: string;
  closing_potencial: string;
  reaction: string;
  plans: string;
  profile: string[];
  influence: string;
  objection: string;
  autority: string;
  doubts: string;
  process_knowledge: string;
  proposal_explanation: string;
  observation: string;
  clarification_note: string;
  valid_contact_wpp_opt_in: string;
  value_offered_by_competition: string;
  motive_for_not_advancing: string;
  motive_of_opportunity: string;
  why_didnt_anticipate_before: string;
  customer_priority: string;
  decision_review: string;
  contact_with_competitor: string;
  informed_negotiable_value: string;
  negotiable_value: string;
  reason_for_scheduling: string;
  thermometer: string;
  was_aware_precatory: string;
  was_aware_PEC: string;
  feel_uncomfortable: string;
  scheduled_for_closer: string;
  we_schedule_a_new_contact: string;
  decision_maker: string;
  contact_preferences: string;
  schedule_date: Date;
  day: string;
  hours: string;
  ticket_id: string;
  confirmation_message_sent: string;
  aware_of_the_precatory_value: string;
  Interested_in_knowing_the_values: string;
  late_payment_delays_the_customer: string;
  urgency_to_carry_out_the_plan: string;
  money_in_hand: string;
  customer_want_to_solve_the_problem: string;
  customer_knows_that_we_can_solve_his_problem: string;
  value_to_negociate?: string;
  contact_competition: string;
  send_message_to_client: string;
  valid_contact_decision_maker: string;
  value_proposal_competition: string;
}

interface SuccessfulModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  name_sla: string;
  selectedDay: string | undefined;
  ticket_id: string;
  contact_preference: string | undefined;
  selected_time: Date | undefined;
  queue: string | undefined;
  scheduling_hub_activity: string | undefined;
  closer_name_scheduling: string | undefined;
  connection: string;
  previousConnectionMethod: string;
  hunter: IHunterDTO | undefined;
  ticket_pipeline: string | undefined;
}

interface ConnectionMethod {
  label: string;
  amount: number;
  activeInbound?: boolean;
  digitalInbound?: boolean;
  outbound?: boolean;
}

export function SuccessfulModal({
  isOpen,
  onRequestClose,
  name_sla,
  selectedDay,
  ticket_id,
  contact_preference,
  selected_time,
  queue,
  scheduling_hub_activity,
  closer_name_scheduling,
  connection,
  previousConnectionMethod,
  hunter,
  ticket_pipeline,
}: SuccessfulModalProps): JSX.Element {
  const { user } = useAuth();
  const history = useHistory();

  const [closers, setClosers] = useState<Closer[]>([] as Closer[]);
  const [formData, setFormData] = useState<FormData>({
    ...{},
    profile: [],
  } as FormData);
  const [availableHours, setAvailableHours] = useState<string[]>([]);
  const [selectedConnectionMethods, setSelectedConnectionMethods] = useState<
    ConnectionMethod[]
  >([] as ConnectionMethod[]);

  const CREATE_TRANSFER = gql`
    mutation CreateTransfer($transfer: String!) {
      createTransfer(transfer: $transfer) {
        amount
        closer_id
        connection_method
        connection_type
        contact
        contact_preference
        contacted_who
        created_at
        current_lead_owner
        dealname
        hub_activity_id
        hubspot_deal_id
        hunter_id
        hunter_name
        hunter_owner_id
        id
        loa
        observations
        queue_id
        stage_name
        status
        ticket_id
        timestamp
        updated_at
        valid_contact
        valid_email
      }
    }
  `;

  const [createTransfer] = useMutation(CREATE_TRANSFER, {
    variables: {
      transfer: JSON.stringify({
        hunter_id: user.id,
        closer_id: formData.closer_id,
        hubspot_ticket_id: ticket_id,
        queue_id: queue,
        connection,
      }),
    },
  });

  useEffect(() => {
    const connectionMethods = connectionMethod;

    setSelectedConnectionMethods(connectionMethods);
  }, [connection]);

  useEffect(() => {
    api.get<Closer[]>('/schedule/list-all-closers').then(response => {
      setClosers(response.data);
    });
  }, []);

  const handleListAvailableHours = useCallback(
    async (date: string) => {
      try {
        const { data } = await api.get<string[]>(
          `/schedule/hunter-available-hours?date=${date}&user_id=${user.id}`,
        );
        const formattedHours = data.map(hour => {
          return format(new Date(hour), 'HH:mm');
        });
        setAvailableHours(formattedHours);
      } catch (error) {
        console.warn(error);
      }
    },
    [user.id],
  );

  useEffect(() => {
    const loadLastSLA = async () => {
      if (ticket_id) {
        const day = format(new Date(selected_time), 'yyyy-MM-dd');
        const hours = format(new Date(selected_time), 'HH:mm');
        const dateFormatted = new Date(`${day} ${hours}`);
        if (contact_preference === 'Whatsapp') contact_preference = 'WPP';
        handleListAvailableHours(day);
        await api.get(`/schedule/last-deal-sla/${ticket_id}`).then(response => {
          setFormData({
            ...response.data,
            reason_for_scheduling: '',
            objection: '',
            scheduled_for_closer: 'Sim',
            day,
            hours,
            schedule_date: dateFormatted,
            contact_preferences: contact_preference,
            clarification_note: '',
            profile: response.data.profile
              ? response.data.profile.split(',')
              : [],
          });
        });
      }
    };

    loadLastSLA();
  }, [ticket_id]);

  useEffect(() => {
    if (
      formData.who_contacted &&
      formData.connection_method &&
      formData.connection_type &&
      formData.profile.length > 0
    ) {
      const clarificationNote = createFirstPassClarificationNote({
        hunter_name: hunter ? hunter.name : user.name,
        who_contacted: formData.who_contacted,
        connection_type: formData.connection_type,
        connection_method: formData.connection_method,
        valid_contact: formData.valid_contact,
        reason_for_scheduling: formData.reason_for_scheduling,
        autority: formData.autority,
        contact_with_competitor: formData.contact_with_competitor,
        reaction: formData.reaction,
        plans: formData.plans,
        doubts: formData.doubts,
        profile: formData.profile.toString(),
        influence: formData.influence,
        objection: formData.objection,
      });

      setFormData(form_data => {
        return { ...form_data, clarification_note: clarificationNote };
      });
    } else {
      setFormData(form_data => {
        return { ...form_data, clarification_note: '' };
      });
    }
  }, [
    formData.who_contacted,
    formData.connection_method,
    formData.connection_type,
    formData.reaction,
    formData.plans,
    formData.profile,
    formData.profile.length,
    formData.influence,
    formData.objection,
    ticket_pipeline,
    formData.valid_contact,
    formData.autority,
    formData.contact_with_competitor,
    formData.customer_priority,
    formData.doubts,
    formData.why_didnt_anticipate_before,
    formData.decision_review,
    formData.informed_negotiable_value,
    user.name,
    formData.negotiable_value,
    formData.reason_for_scheduling,
    formData.observation,
    hunter,
    user.profile,
    formData.valid_email,
    formData.was_aware_precatory,
    formData.was_aware_PEC,
    formData.feel_uncomfortable,
    formData.scheduled_for_closer,
    formData.decision_maker,
    formData.contact_preferences,
    formData.valid_contact_wpp_opt_in,
    formData.day,
    formData.hours,
    formData.opt_in,
    previousConnectionMethod,
  ]);

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setFormData({
      ...{},
      profile: [],
    } as FormData);
  }, [onRequestClose]);

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (
    value,
    _,
    values,
  ): void => {
    setFormData({ ...formData, [_]: values.float });
  };

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      if (
        name === 'Direto' ||
        name === 'Educado' ||
        name === 'Grosso' ||
        name === 'Instruído' ||
        name === 'Simples' ||
        name === 'Conversador'
      ) {
        const index = formData.profile.findIndex(profile => profile === name);

        if (index >= 0) {
          formData.profile.splice(index, 1);
        } else {
          formData.profile.push(name);
        }
      }

      if (name === 'day') {
        handleListAvailableHours(value);
      }

      if (name === 'hours') {
        const dateFormatted = new Date(`${formData.day} ${value}`);
        formData.schedule_date = dateFormatted;
      }

      formData[name] = value;

      if (formData.scheduled_for_closer === 'Não') {
        formData.day = '';
        formData.hours = '';
        formData.schedule_date = null;
        formData[name] = value;
      }
      setFormData({ ...formData });
    },
    [formData, handleListAvailableHours],
  );

  const validateSLAFields = useCallback(async () => {
    try {
      if (ticket_pipeline !== '37781341' && ticket_pipeline !== '66707464') {
        const schema = Yup.object().shape({
          who_contacted: Yup.string()
            .nullable()
            .required('Com quem foi o contato obrigatório!'),
          valid_contact: Yup.string()
            .nullable()
            .required('Contato válido obrigatório!'),
          connection_type: Yup.string()
            .nullable()
            .required('Tipo de conexão obrigatório!'),
          connection_method: Yup.string()
            .nullable()
            .required('Meio de conexão obrigatório!'),
          plans: Yup.string()
            .nullable()
            .required('Campo Planos do credor obrigatório!'),
          profile: Yup.array().of(Yup.string()).required('Perfil obrigatório!'),
          observation: Yup.string()
            .nullable()
            .required('Campo Observação obrigatório!'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });
      } else {
        const schemaSLA =
          previousConnectionMethod === 'Telefone' ||
          previousConnectionMethod === 'Honorários'
            ? Yup.object().shape({
                who_contacted: Yup.string()
                  .nullable()
                  .required('Com quem foi o contato obrigatório!'),
                decision_maker: Yup.string()
                  .nullable()
                  .required('Tomador de decisão obrigatório!'),
                valid_contact: Yup.string()
                  .nullable()
                  .required('Qual é o contato válido obrigatório!'),
                connection_type: Yup.string()
                  .nullable()
                  .required('Tipo de conexão obrigatório!'),
                connection_method: Yup.string()
                  .nullable()
                  .required('Meio de conexão obrigatório!'),
                was_aware_precatory: Yup.string()
                  .nullable()
                  .required('Tinha conhecimento do precatório obrigatório!'),
                was_aware_PEC: Yup.string()
                  .nullable()
                  .required(
                    'Tinha conhecimento sobre a previsão de pagamento obrigatório!',
                  ),
                scheduled_for_closer: Yup.string()
                  .nullable()
                  .required('Agendado para o closer obrigatório!'),
                confirmation_message_sent: Yup.string()
                  .nullable()
                  .required('Foi enviada mensagem de confirmação obrigatório!'),

                observation: Yup.string()
                  .nullable()
                  .required('Observação obrigatório!'),
              })
            : Yup.object().shape({
                who_contacted: Yup.string()
                  .nullable()
                  .required('Com quem foi o contato obrigatório!'),
                decision_maker: Yup.string()
                  .nullable()
                  .required('Tomador de decisão obrigatório!'),
                valid_contact: Yup.string()
                  .nullable()
                  .required('Qual é o contato válido obrigatório!'),
                contact_preferences: Yup.string()
                  .nullable()
                  .required('Como o credor prefere ser contatado obrigatório!'),
                connection_type: Yup.string()
                  .nullable()
                  .required('Tipo de conexão obrigatório!'),
                connection_method: Yup.string()
                  .nullable()
                  .required('Meio de conexão obrigatório!'),
                observation: Yup.string()
                  .nullable()
                  .required('Observação obrigatório!'),
              });
        const schemaValidateDate = Yup.object().shape({
          day: Yup.string().nullable().required('Data obrigatório!'),
          hours: Yup.string().nullable().required('Horário obrigatório!'),
        });
        await schemaSLA.validate(formData, {
          abortEarly: false,
        });

        if (formData.scheduled_for_closer === 'Sim') {
          await schemaValidateDate.validate(formData, {
            abortEarly: false,
          });
        }
      }

      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        Swal.fire('Atenção!', `${error.errors[0]}`, 'warning');
      }
      return false;
    }
  }, [formData, ticket_pipeline, previousConnectionMethod]);

  const handleSaveSLA = useCallback(async () => {
    const isValid = await validateSLAFields();
    if (isValid) {
      try {
        if (formData.closer_id === 'Falha de Transferência') {
          history.push({
            pathname: '/negociations/schedule/hunter/create',
          });
        } else if (ticket_id === '' || isNaN(Number(ticket_id))) {
          Swal.fire('Erro!', 'Ticket ID inválido!', 'error');
        } else {
          const loader = document.getElementById('loader_up');
          loader.style.display = 'flex';
          if (name_sla === 'Transferência') {
            await createTransfer();
          }

          if (
            ticket_pipeline !== '37781341' &&
            ticket_pipeline !== '66707464'
          ) {
            const autorityValue = autority.find(
              found_autority => found_autority.label === formData.autority,
            );
            const connectionMethodValue = connectionMethod.find(
              connection_method =>
                connection_method.label === formData.connection_method,
            );
            const reactionValue = reactions.find(
              reaction => reaction.label === formData.reaction,
            );
            const planValue = plans.find(plan => plan.label === formData.plans);
            const influencesValue = influences.find(
              influence => influence.label === formData.influence,
            );
            const objectionValue = objections.find(
              objection => objection.label === formData.objection,
            );
            const filterProfiles = profiles.filter(profile =>
              formData.profile.includes(profile.label),
            );
            const profileValue = filterProfiles.reduce(
              (previous, current) => previous + current.amount,
              0,
            );
            const doubtsValue = doubts.find(
              doubt => doubt.label === formData.doubts,
            );

            let reasonForSchedulingValue = 5;

            if (formData.reason_for_scheduling) {
              const reasonForSchedulingInput = reasonForScheduling.find(
                reason_for_scheduling =>
                  reason_for_scheduling.value ===
                  formData.reason_for_scheduling,
              );

              if (reasonForSchedulingInput) {
                reasonForSchedulingValue = reasonForSchedulingInput.amount;
              }
            }

            const totalAmount =
              autorityValue.amount +
              connectionMethodValue.amount +
              reactionValue.amount +
              planValue.amount +
              influencesValue.amount +
              objectionValue.amount +
              profileValue +
              reasonForSchedulingValue +
              doubtsValue.amount;

            await api.post('/schedule/hunter-sla', {
              name: 'Bem-sucedido',
              hunter_id: hunter ? hunter.id : user.id,
              ticket_id,
              closer_id:
                name_sla === 'Transferência' ? formData.closer_id : undefined,
              autority: formData.autority,
              who_contacted: formData.who_contacted,
              valid_contact: formData.valid_contact,
              valid_email: formData.valid_email,
              connection_type: formData.connection_type,
              connection_method: formData.connection_method,
              reason_for_scheduling:
                formData.reason_for_scheduling || 'Sem agendamento',
              thermometer: formData.thermometer,
              reaction: formData.reaction,
              plans: formData.plans,
              profile: formData.profile.toString(),
              influence: formData.influence,
              objection: formData.objection,
              doubts: formData.doubts,
              observation: formData.observation,
              process_knowledge: formData.process_knowledge,
              proposal_explanation: formData.proposal_explanation,
              note_amount: totalAmount,
              scheduling_hub_activity,
              was_aware_precatory: formData.was_aware_precatory,
              was_aware_PEC: formData.was_aware_PEC,
              feel_uncomfortable: formData.feel_uncomfortable,
              scheduled_for_closer: formData.scheduled_for_closer,
              decision_maker: formData.decision_maker,
              contact_preferences: formData.contact_preferences,
              clarification_note: formData.clarification_note,
              opt_in: formData.opt_in,
            });
          } else {
            const formatedSLAN5: ISLAN5 = {
              ticket_id,
              who_contacted: formData.who_contacted,
              valid_contact: formData.valid_contact,
              valid_email: formData.valid_email,
              connection_type: formData.connection_type,
              connection_method: formData.connection_method,
              influence: formData.influence,
              observation: formData.observation,
              was_aware_precatory: formData.was_aware_precatory,
              was_aware_PEC: formData.was_aware_PEC,
              feel_uncomfortable: formData.feel_uncomfortable,
              scheduled_for_closer: formData.scheduled_for_closer,
              decision_maker: formData.decision_maker,
              contact_preferences: formData.contact_preferences,
              valid_contact_wpp_opt_in: formData.valid_contact_wpp_opt_in,
              schedule_date: formData.schedule_date,
              day: formData.day,
              hours: formData.hours,
              confirmation_message_sent: formData.confirmation_message_sent,
              aware_of_the_precatory_value:
                formData.aware_of_the_precatory_value,
              Interested_in_knowing_the_values:
                formData.Interested_in_knowing_the_values,
              late_payment_delays_the_customer:
                formData.late_payment_delays_the_customer,
              plans: formData.plans,
              urgency_to_carry_out_the_plan:
                formData.urgency_to_carry_out_the_plan,
              money_in_hand: formData.money_in_hand,
              customer_want_to_solve_the_problem:
                formData.customer_want_to_solve_the_problem,
              customer_knows_that_we_can_solve_his_problem:
                formData.customer_knows_that_we_can_solve_his_problem,
              opt_in: formData.opt_in,
              clarification_note: formData.clarification_note,
              value_to_negociate: formData.value_to_negociate,
              profile: formData.profile,
              contact_competition: formData.contact_competition,
              valid_contact_decision_maker:
                formData.valid_contact_decision_maker,
              value_proposal_competition: formData.value_proposal_competition,
            };

            await api.post('/schedule/hunter-sla', {
              ...formatedSLAN5,
              name: 'N5',
              hunter_id: hunter ? hunter.id : user.id,
              ticket_id,
              closer_id:
                name_sla === 'Transferência' ? formData.closer_id : undefined,
              scheduling_hub_activity,
              clarification_note: formData.clarification_note,
              profile: formData.profile.join(';'),
            });
          }

          if (name_sla === 'Agendamento' && !hunter) {
            history.push({
              pathname: '/negociations/schedule/hunter',
              state: {
                daySelected: selectedDay || 'Hoje',
              },
            });
          }

          handleCloseModal();
          toast.success('SLA bem-sucedido criado!');
        }
      } catch (error) {
        console.log(error.message);
        const loader = document.getElementById('loader_up');
        loader.style.display = 'none';

        toast.error('Não foi possível criar SLA bem-sucedido.');
      }
    }
  }, [
    createTransfer,
    formData.Interested_in_knowing_the_values,
    formData.autority,
    formData.aware_of_the_precatory_value,
    formData.clarification_note,
    formData.closer_id,
    formData.confirmation_message_sent,
    formData.connection_method,
    formData.connection_type,
    formData.contact_competition,
    formData.contact_preferences,
    formData.customer_knows_that_we_can_solve_his_problem,
    formData.customer_want_to_solve_the_problem,
    formData.day,
    formData.decision_maker,
    formData.doubts,
    formData.feel_uncomfortable,
    formData.hours,
    formData.influence,
    formData.late_payment_delays_the_customer,
    formData.money_in_hand,
    formData.objection,
    formData.observation,
    formData.opt_in,
    formData.plans,
    formData.process_knowledge,
    formData.profile,
    formData.proposal_explanation,
    formData.reaction,
    formData.reason_for_scheduling,
    formData.schedule_date,
    formData.scheduled_for_closer,
    formData.thermometer,
    formData.urgency_to_carry_out_the_plan,
    formData.valid_contact,
    formData.valid_contact_decision_maker,
    formData.valid_contact_wpp_opt_in,
    formData.valid_email,
    formData.value_proposal_competition,
    formData.value_to_negociate,
    formData.was_aware_PEC,
    formData.was_aware_precatory,
    formData.who_contacted,
    handleCloseModal,
    history,
    hunter,
    name_sla,
    scheduling_hub_activity,
    selectedDay,
    ticket_id,
    ticket_pipeline,
    user.id,
    validateSLAFields,
  ]);

  return (
    <>
      {ticket_pipeline !== '37781341' &&
      ticket_pipeline !== '66707464' &&
      ticket_pipeline !== '66169943' ? (
        <Modal isOpen={isOpen} style={SuccessfulModalStyle}>
          <Title name="Bem sucedido" />
          <Loading loader_up />

          <Main>
            {name_sla === 'Transferência' && (
              <InputContainer>
                <Label>Closer que recebeu a transferência:</Label>
                <Select
                  name="closer_id"
                  defaultValue=""
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  <option value="Falha de Transferência">
                    Falha de Transferência
                  </option>
                  {closers.map(closer => (
                    <option key={closer.user_id} value={closer.user_id}>
                      {closer.name}
                    </option>
                  ))}
                </Select>
              </InputContainer>
            )}

            {closer_name_scheduling && (
              <InputContainer>
                <Label>Closer responsável:</Label>
                <Input
                  type="text"
                  disabled
                  style={{ border: 'none' }}
                  value={closer_name_scheduling}
                />
              </InputContainer>
            )}

            {formData.closer_id !== 'Falha de Transferência' && (
              <>
                <InputContainer>
                  <Label>Autoridade?</Label>
                  <Select
                    name="autority"
                    defaultValue={formData.autority || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    {autority.map(found_autority => (
                      <option
                        key={found_autority.label}
                        value={found_autority.label}
                        selected={formData.autority === found_autority.label}
                      >
                        {found_autority.label}
                      </option>
                    ))}
                  </Select>
                </InputContainer>

                <InputContainer>
                  <Label>Com quem foi o contato?</Label>
                  <Select
                    name="who_contacted"
                    defaultValue={formData.who_contacted || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    {whoContacted.map(who_contacted => (
                      <option
                        key={who_contacted.label}
                        value={who_contacted.label}
                        selected={
                          formData.who_contacted === who_contacted.label
                        }
                      >
                        {who_contacted.label}
                      </option>
                    ))}
                  </Select>
                </InputContainer>

                <InputContainer>
                  <Label>Contato válido (credor):</Label>
                  <Input
                    type="text"
                    name="valid_contact"
                    defaultValue={formData.valid_contact || ''}
                    onChange={handleInputChange}
                  />
                </InputContainer>

                <InputContainer>
                  <Label>E-mail válido (credor):</Label>
                  <Input
                    type="email"
                    name="valid_email"
                    value={formData.valid_email}
                    onChange={handleInputChange}
                  />
                </InputContainer>

                <InputContainer>
                  <Label>Tipo de conexão:</Label>
                  <Select
                    name="connection_type"
                    defaultValue={formData.connection_type || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    <option
                      value="Inbound (0800)"
                      selected={formData.connection_type === 'Inbound (0800)'}
                    >
                      Inbound (0800)
                    </option>
                    <option
                      value="Inbound (Virtual)"
                      selected={
                        formData.connection_type === 'Inbound (Virtual)'
                      }
                    >
                      Inbound (Virtual)
                    </option>
                    <option
                      value="Outbound"
                      selected={formData.connection_type === 'Outbound'}
                    >
                      Outbound
                    </option>
                  </Select>
                </InputContainer>

                <InputContainer>
                  <Label>Meio de conexão</Label>
                  <Select
                    name="connection_method"
                    defaultValue={formData.connection_method || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    {selectedConnectionMethods.map(connection_method => (
                      <option
                        key={connection_method.label}
                        value={connection_method.label}
                      >
                        {connection_method.label}
                      </option>
                    ))}
                  </Select>
                </InputContainer>

                {contact_preference && (
                  <InputContainer>
                    <Label>Motivo de agendamento</Label>
                    <Select
                      name="reason_for_scheduling"
                      defaultValue={formData.reason_for_scheduling || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {reasonForScheduling.map(reason_for_scheduling => (
                        <option
                          key={reason_for_scheduling.label}
                          value={reason_for_scheduling.value}
                          selected={
                            formData.reason_for_scheduling ===
                            reason_for_scheduling.value
                          }
                        >
                          {reason_for_scheduling.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                )}

                <InputContainer>
                  <Label>Termômetro</Label>
                  <Select
                    name="thermometer"
                    defaultValue={formData.thermometer || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    <option
                      value="Quente"
                      selected={formData.thermometer === 'Quente'}
                    >
                      Quente
                    </option>
                    <option
                      value="Morno"
                      selected={formData.thermometer === 'Morno'}
                    >
                      Morno
                    </option>
                    <option
                      value="Frio"
                      selected={formData.thermometer === 'Frio'}
                    >
                      Frio
                    </option>
                  </Select>
                </InputContainer>

                {ticket_pipeline === '2945972' ||
                ticket_pipeline === '9536414' ? (
                  <InputContainer>
                    <Label>Gerou incerteza no recebimento? Reação?</Label>
                    <Select
                      name="reaction"
                      defaultValue={formData.reaction || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option
                        value="Sim, mas adv contradiz"
                        selected={
                          formData.reaction === 'Sim, mas adv contradiz'
                        }
                      >
                        Sim, mas adv contradiz
                      </option>
                      <option
                        value="Sim, ficou incomodado"
                        selected={formData.reaction === 'Sim, ficou incomodado'}
                      >
                        Sim, ficou incomodado
                      </option>
                      <option
                        value="Sim, indiferente"
                        selected={formData.reaction === 'Sim, indiferente'}
                      >
                        Sim, indiferente
                      </option>
                      <option
                        value="Não"
                        selected={formData.reaction === 'Não'}
                      >
                        Não
                      </option>
                    </Select>
                  </InputContainer>
                ) : (
                  <InputContainer>
                    <Label>Gerou incerteza no recebimento? Reação?</Label>
                    <Select
                      name="reaction"
                      defaultValue={formData.reaction || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option
                        value="Gerei incerteza, mas adv disse que vai receber esse ano"
                        selected={
                          formData.reaction ===
                          'Gerei incerteza, mas adv disse que vai receber esse ano'
                        }
                      >
                        Gerei incerteza, mas adv disse que vai receber esse ano
                      </option>
                      <option
                        value="Gerei incerteza, mas o adv é contra a antecipação"
                        selected={
                          formData.reaction ===
                          'Gerei incerteza, mas o adv é contra a antecipação'
                        }
                      >
                        Gerei incerteza, mas o adv é contra a antecipação
                      </option>
                      <option
                        value="Gerei incerteza, e o credor ficou incomodado"
                        selected={
                          formData.reaction ===
                          'Gerei incerteza, e o credor ficou incomodado'
                        }
                      >
                        Gerei incerteza, e o credor ficou incomodado
                      </option>
                      <option
                        value="Gerei incerteza, e o credor ficou indiferente"
                        selected={
                          formData.reaction ===
                          'Gerei incerteza, e o credor ficou indiferente'
                        }
                      >
                        Gerei incerteza, e o credor ficou indiferente
                      </option>
                      <option
                        value="Não falei sobre incerteza"
                        selected={
                          formData.reaction === 'Não falei sobre incerteza'
                        }
                      >
                        Não falei sobre incerteza
                      </option>
                    </Select>
                  </InputContainer>
                )}

                <InputContainer>
                  <Label>Sobre planos com o recurso:</Label>
                  <Select
                    name="plans"
                    defaultValue={formData.plans || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    {plans.map(plan => (
                      <option
                        key={plan.label}
                        value={plan.label}
                        selected={formData.plans === plan.label}
                      >
                        {plan.label}
                      </option>
                    ))}
                  </Select>
                </InputContainer>

                {ticket_pipeline === '2945972' ||
                ticket_pipeline === '9536414' ? (
                  <InputContainer>
                    <Label>Influência:</Label>
                    <Select
                      name="influence"
                      defaultValue={formData.influence || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option
                        value="Advogado"
                        selected={formData.influence === 'Advogado'}
                      >
                        Advogado
                      </option>
                      <option
                        selected={formData.influence === 'Familiar'}
                        value="Familiar"
                      >
                        Familiar
                      </option>
                      <option
                        selected={formData.influence === 'Terceiros'}
                        value="Terceiros"
                      >
                        Terceiros
                      </option>
                      <option
                        selected={formData.influence === 'Concorrência'}
                        value="Concorrência"
                      >
                        Concorrência
                      </option>
                      <option
                        selected={formData.influence === 'Sem influência'}
                        value="Sem influência"
                      >
                        Sem influência
                      </option>
                      <option
                        selected={formData.influence === 'Não identificou'}
                        value="Não identificou"
                      >
                        Não identificou
                      </option>
                      <option
                        selected={formData.influence === 'Não mencionado'}
                        value="Não mencionado"
                      >
                        Não mencionado
                      </option>
                    </Select>
                  </InputContainer>
                ) : (
                  <InputContainer>
                    <Label>Influência:</Label>
                    <Select
                      name="influence"
                      defaultValue={formData.influence || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option
                        selected={
                          formData.influence ===
                          'Disse que acompanha tudo com o adv'
                        }
                        value="Disse que acompanha tudo com o adv"
                      >
                        Disse que acompanha tudo com o adv
                      </option>
                      <option
                        selected={
                          formData.influence ===
                          'Tinha um familiar perto na hora da conversa'
                        }
                        value="Tinha um familiar perto na hora da conversa"
                      >
                        Tinha um familiar perto na hora da conversa
                      </option>
                      <option
                        selected={
                          formData.influence ===
                          'Mencionou que algum familiar era contra a antecipação'
                        }
                        value="Mencionou que algum familiar era contra a antecipação"
                      >
                        Mencionou que algum familiar era contra a antecipação
                      </option>
                      <option
                        selected={
                          formData.influence ===
                          'Disse que já ouviu proposta da concorrência'
                        }
                        value="Disse que já ouviu proposta da concorrência"
                      >
                        Disse que já ouviu proposta da concorrência
                      </option>
                      <option
                        selected={formData.influence === 'Sem influência'}
                        value="Sem influência"
                      >
                        Sem influência
                      </option>
                      <option
                        selected={formData.influence === 'Não mencionado'}
                        value="Não mencionado"
                      >
                        Não mencionado
                      </option>
                    </Select>
                  </InputContainer>
                )}

                <InputContainer>
                  <Label>Objeção:</Label>
                  <Select
                    name="objection"
                    defaultValue={formData.objection || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    {objections.map(objection => (
                      <option
                        key={objection.label}
                        value={objection.label}
                        selected={formData.objection === objection.label}
                      >
                        {objection.label}
                      </option>
                    ))}
                  </Select>
                </InputContainer>

                <InputContainer>
                  <Label>Teve dúvidas sobre o serviço, qual?</Label>
                  <Select
                    name="doubts"
                    defaultValue={formData.doubts || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Selecione uma opção
                    </option>
                    {doubts.map(doubt => (
                      <option
                        key={doubt.label}
                        value={doubt.label}
                        selected={formData.doubts === doubt.label}
                      >
                        {doubt.label}
                      </option>
                    ))}
                  </Select>
                </InputContainer>

                {ticket_pipeline === '2945972' ||
                ticket_pipeline === '9536414' ? (
                  <InputContainer>
                    <Label>Conhecimento do processo:</Label>
                    <Select
                      name="process_knowledge"
                      defaultValue={formData.process_knowledge || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {processKnowledge.map(process_knowledge => (
                        <option
                          key={process_knowledge.label}
                          value={process_knowledge.label}
                          selected={
                            formData.process_knowledge ===
                            process_knowledge.label
                          }
                        >
                          {process_knowledge.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  <div />
                )}

                {ticket_pipeline === '2945972' ||
                ticket_pipeline === '9536414' ? (
                  <InputContainer>
                    <Label>Explicação da proposta:</Label>
                    <Select
                      name="proposal_explanation"
                      defaultValue={formData.proposal_explanation || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {proposalExplanation.map(proposal_explanation => (
                        <option
                          key={proposal_explanation.label}
                          value={proposal_explanation.label}
                          selected={
                            formData.proposal_explanation ===
                            proposal_explanation.label
                          }
                        >
                          {proposal_explanation.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {ticket_pipeline === '2945972' ||
                ticket_pipeline === '9536414' ? (
                  <InputContainer>
                    <Label>Opt in:</Label>
                    <Select
                      name="opt_in"
                      defaultValue={formData.opt_in || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {optIn.map(opt_in => (
                        <option
                          key={opt_in.label}
                          value={opt_in.label}
                          selected={formData.opt_in === opt_in.label}
                        >
                          {opt_in.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {ticket_pipeline === '2154049' ||
                ticket_pipeline === '9528351' ? (
                  <InputContainer>
                    <Label>
                      Motivo de não ter tido interesse de antecipar antes:
                    </Label>
                    <Select
                      name="why_didnt_anticipate_before"
                      defaultValue={formData.why_didnt_anticipate_before || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {whyDidntAnticipateBefore.map(reason => (
                        <option
                          key={reason.label}
                          value={reason.label}
                          selected={
                            formData.why_didnt_anticipate_before ===
                            reason.label
                          }
                        >
                          {reason.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {ticket_pipeline === '2154049' ||
                ticket_pipeline === '9528351' ? (
                  <InputContainer>
                    <Label>
                      Qual a prioridade do cliente em resolver esse problema?
                    </Label>
                    <Select
                      name="customer_priority"
                      defaultValue={formData.customer_priority || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {customerPriority.map(priority => (
                        <option
                          key={priority.label}
                          value={priority.label}
                          selected={
                            formData.customer_priority === priority.label
                          }
                        >
                          {priority.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {ticket_pipeline === '2154049' ||
                ticket_pipeline === '9528351' ? (
                  <InputContainer>
                    <Label>
                      Poderia rever a sua decisão e antecipar o precatório com a
                      Precato?
                    </Label>
                    <Select
                      name="decision_review"
                      defaultValue={formData.decision_review || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {decisionReview.map(decision => (
                        <option
                          key={decision.label}
                          value={decision.label}
                          selected={formData.decision_review === decision.label}
                        >
                          {decision.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {ticket_pipeline === '2154049' ||
                ticket_pipeline === '9528351' ? (
                  <InputContainer>
                    <Label>Teve contato com algum concorrente?</Label>
                    <Select
                      name="contact_with_competitor"
                      defaultValue={formData.contact_with_competitor || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {contactWithCompetitor.map(contact => (
                        <option
                          key={contact.label}
                          value={contact.label}
                          selected={
                            formData.contact_with_competitor === contact.label
                          }
                        >
                          {contact.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {ticket_pipeline === '2154049' ||
                ticket_pipeline === '9528351' ? (
                  <InputContainer>
                    <Label>Informou o valor que negociaria?</Label>
                    <Select
                      name="informed_negotiable_value"
                      defaultValue={formData.informed_negotiable_value || ''}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      {informedNegotiableValue.map(info => (
                        <option
                          key={info.label}
                          value={info.label}
                          selected={
                            formData.informed_negotiable_value === info.label
                          }
                        >
                          {info.label}
                        </option>
                      ))}
                    </Select>
                  </InputContainer>
                ) : (
                  ''
                )}

                {(ticket_pipeline === '2154049' ||
                  ticket_pipeline === '9528351') &&
                formData.informed_negotiable_value === 'Sim' ? (
                  <InputContainer>
                    <Label>Qual valor?</Label>
                    <Input
                      type="text"
                      name="negotiable_value"
                      defaultValue={formData.negotiable_value || ''}
                      onChange={handleInputChange}
                    />
                  </InputContainer>
                ) : (
                  ''
                )}

                <InputContainer>
                  <Label>Perfil:</Label>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      name="Direto"
                      onChange={handleInputChange}
                      checked={
                        !!formData.profile.find(profile => profile === 'Direto')
                      }
                    />
                    <LabelCheckbox>Direto</LabelCheckbox>
                  </CheckboxContainer>

                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      name="Educado"
                      onChange={handleInputChange}
                      checked={
                        !!formData.profile.find(
                          profile => profile === 'Educado',
                        )
                      }
                    />
                    <LabelCheckbox>Educado</LabelCheckbox>
                  </CheckboxContainer>

                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      name="Grosso"
                      onChange={handleInputChange}
                      checked={
                        !!formData.profile.find(profile => profile === 'Grosso')
                      }
                    />
                    <LabelCheckbox>Grosso</LabelCheckbox>
                  </CheckboxContainer>

                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      name="Instruído"
                      onChange={handleInputChange}
                      checked={
                        !!formData.profile.find(
                          profile => profile === 'Instruído',
                        )
                      }
                    />
                    <LabelCheckbox>Instruído</LabelCheckbox>
                  </CheckboxContainer>

                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      name="Simples"
                      onChange={handleInputChange}
                      checked={
                        !!formData.profile.find(
                          profile => profile === 'Simples',
                        )
                      }
                    />
                    <LabelCheckbox>Simples</LabelCheckbox>
                  </CheckboxContainer>

                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      name="Conversador"
                      onChange={handleInputChange}
                      checked={
                        !!formData.profile.find(
                          profile => profile === 'Conversador',
                        )
                      }
                    />
                    <LabelCheckbox>Conversador</LabelCheckbox>
                  </CheckboxContainer>
                </InputContainer>

                <InputContainer>
                  <Label>Observação:</Label>
                  <Textarea
                    name="observation"
                    defaultValue={formData.observation || ''}
                    onChange={handleInputChange}
                  />
                </InputContainer>

                <InputContainer>
                  <Label>Nota de esclarecimento:</Label>
                  <Textarea
                    disabled
                    name="clarification_note"
                    defaultValue={formData.clarification_note}
                  />
                </InputContainer>
              </>
            )}

            <InputContainer>
              <ButtonSave onClick={handleSaveSLA}>Salvar</ButtonSave>
            </InputContainer>
          </Main>
        </Modal>
      ) : (
        <ModalSLA
          isOpen={isOpen}
          onRequestClose={undefined}
          typeModal="N5"
          handleSaveSLA={handleSaveSLA}
          handleChange={handleInputChange}
          handleChangeCurrency={handleOnValueChange}
          SLAInfo={formData}
          availableHours={availableHours}
          closer_name_scheduling={closer_name_scheduling}
        />
      )}
    </>
  );
}
