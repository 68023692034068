import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { format, isBefore } from 'date-fns';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { Title } from '../../../../components/Title';
import { IHunterSLADTO } from '../dtos/IHunterSLADTO';
import { Loading } from '../../../../components/Loading';
import { useHunterSchedule } from '../../../../hooks/useHunterSchedule';
import {
  connectionMethod,
  requestedReturn,
  whoContacted,
} from '../utils/noteAmountByInput';

import {
  ReturnModalStyle,
  Main,
  InputContainer,
  Label,
  Select,
  Input,
  Textarea,
  ButtonSave,
  TimestampContainer,
} from './styles';
import verifyDealTj from './utils/verifyDealTj';
import { ButtonExit } from '../LostModal/styles';
import { optIn } from '../../components/ModalSLA/utils/optionsSLA';
import { createFirstPassClarificationNote } from '../utils/createClarificationNote';

interface ReturnModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ReturnModal({
  isOpen,
  onRequestClose,
}: ReturnModalProps): JSX.Element {
  const { user } = useAuth();
  const { handleCreateDayHour } = useHunterSchedule();

  const [formData, setFormData] = useState<IHunterSLADTO>({} as IHunterSLADTO);
  const [availableHours, setAvailableHours] = useState<string[]>(
    [] as string[],
  );
  const [verifyDeal, setVerifyDeal] = useState<boolean>(true);
  const [selectedConnection, setSelectedConnection] = useState<string>();
  const [hubspotTicketID, setHubspotTicketID] = useState<string>('');

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setFormData({} as IHunterSLADTO);
  }, [onRequestClose]);

  const handleCloseBlockedModal = useCallback(() => {
    setVerifyDeal(true);
    onRequestClose();
  }, [onRequestClose, setVerifyDeal]);

  useEffect(() => {
    if (
      formData.connection_method &&
      formData.connection_type &&
      formData.valid_contact
    ) {
      const clarificationNote = createFirstPassClarificationNote({
        hunter_name: user.name,
        connection_type: formData.connection_type,
        connection_method: formData.connection_method,
        valid_contact: formData.valid_contact,
      });

      setFormData(form_data => {
        return { ...form_data, clarification_note: clarificationNote };
      });
    } else {
      setFormData(form_data => {
        return { ...form_data, clarification_note: '' };
      });
    }
  }, [
    formData.connection_method,
    formData.connection_type,
    formData.valid_contact,
    user.name,
  ]);

  const handleLoadLastSLA = useCallback(
    async (ticket_id: string) => {
      const response = await api.get(`/schedule/last-deal-sla/${ticket_id}`);
      if (response.data) {
        setFormData(response.data);
      } else {
        setFormData({ ...formData, ticket_id });
      }

      const secondResponse = await api.get(
        `/schedule/get-hubspot-deal-info?hubspot_ticket_id=${ticket_id}&connection=${selectedConnection}`,
      );

      const isTicketValid = await verifyDealTj({
        closed_date: secondResponse.data.closed_date,
      });
      setVerifyDeal(isTicketValid);
    },
    [formData, selectedConnection],
  );

  const handleListAvailableHours = useCallback(
    async (date: string) => {
      try {
        const { data } = await api.get<string[]>(
          `/schedule/hunter-available-hours?date=${date}&user_id=${user.id}&return_type=${formData.return_type}`,
        );

        const formattedHours = data.map(hour => {
          return format(new Date(hour), 'HH:mm');
        });

        setAvailableHours(formattedHours);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }
    },
    [user.id, formData.return_type],
  );

  const handleInputChange = useCallback(
    async (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      if (name === 'ticket_id' && value.length >= 9) {
        setHubspotTicketID(value);
        await handleLoadLastSLA(value);
      } else if (name === 'date') {
        await handleListAvailableHours(value);
        setFormData({ ...formData, [name]: value });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData, handleListAvailableHours, handleLoadLastSLA],
  );

  const handleSaveSLA = useCallback(async () => {
    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      const schema = yup.object().shape({
        valid_contact: yup.string().required('Contato válido obrigatório'),
        connection_type: yup.string().required('Tipo de conexão obrigatório'),
        connection_method: yup.string().required('Meio de conexão obrigatório'),
        requested_return: yup
          .string()
          .required('Solicitação de retorno obrigatório'),
        observation: yup.string().required('Observação obrigatória'),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const timestamp = `${formData.date} ${formData.time}`;

      const timeIsBefore = isBefore(new Date(timestamp), new Date());

      if (timeIsBefore) {
        Swal.fire(
          'Atenção!',
          'Horário escolhido para atividade já passou!',
          'warning',
        );
      } else if (hubspotTicketID === '' || isNaN(Number(hubspotTicketID))) {
        Swal.fire('Erro!', 'Ticket ID inválido!', 'error');
      } else {
        const connectionMethodValue = connectionMethod.find(
          connection_method =>
            connection_method.label === formData.connection_method,
        );

        const requestedReturnValue = requestedReturn.find(
          requested_return =>
            requested_return.label === formData.requested_return,
        );
        const totalAmount =
          connectionMethodValue.amount + requestedReturnValue.amount;

        const { data } = await api.post('/schedule/hunter-sla', {
          name: 'Retorno',
          hunter_id: user.id,
          ticket_id: hubspotTicketID,
          valid_contact: formData.valid_contact,
          valid_email: formData.valid_email,
          valid_contact_return: formData.valid_contact_return,
          connection_type: formData.connection_type,
          connection_method: formData.connection_method,
          decision_maker: formData.decision_maker,
          requested_return: formData.requested_return,
          observation: formData.observation,
          timestamp,
          note_amount: totalAmount,
          return_type: formData.return_type,
          opt_in: formData.opt_in,
          clarification_note: formData.clarification_note,
        });

        if (data.return_type === 'Retorno Pré Qualificado') {
          handleCreateDayHour(data);
        }

        toast.success('Retorno criado com sucesso!');

        handleCloseModal();
      }

      loader.style.display = 'none';
    } catch (error) {
      console.log(error);
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      if (error instanceof yup.ValidationError) {
        toast.error('Preencha todos os campos corretamente.');

        return;
      }
      if (error.response.data.status === 404) {
        Swal.fire('Erro!', error.response.data.message, 'error');
        return;
      }

      toast.error('Não foi possível criar retorno.');
    }
  }, [
    formData,
    user.id,
    hubspotTicketID,
    handleCreateDayHour,
    handleCloseModal,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={ReturnModalStyle}
    >
      <Title name="Retorno" />

      <Loading />

      <Main>
        <InputContainer>
          <Label>ID do ticket:</Label>
          <Input type="number" name="ticket_id" onChange={handleInputChange} />
        </InputContainer>

        {!verifyDeal ? (
          <InputContainer>
            <ButtonExit onClick={handleCloseBlockedModal}>Sair</ButtonExit>
          </InputContainer>
        ) : (
          <>
            <InputContainer>
              <Label>Contato válido (credor):</Label>
              <Input
                type="text"
                name="valid_contact"
                defaultValue={formData.valid_contact || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Com quem foi o contato?</Label>
              <Select
                name="who_contacted"
                defaultValue={formData.who_contacted || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {whoContacted.map(who_contacted => (
                  <option
                    key={who_contacted.label}
                    value={who_contacted.label}
                    selected={formData.who_contacted === who_contacted.label}
                  >
                    {who_contacted.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Tipo de conexão</Label>
              <Select
                name="connection_type"
                defaultValue={formData.connection_type || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option
                  value="Inbound (0800)"
                  selected={formData.connection_type === 'Inbound (0800)'}
                >
                  Inbound (0800)
                </option>
                <option
                  value="Inbound (Virtual)"
                  selected={formData.connection_type === 'Inbound (Virtual)'}
                >
                  Inbound (Virtual)
                </option>
                <option
                  value="Outbound"
                  selected={formData.connection_type === 'Outbound'}
                >
                  Outbound
                </option>
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Meio de conexão:</Label>
              <Select
                name="connection_method"
                defaultValue={formData.connection_method || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {connectionMethod.map(connection_method => (
                  <option
                    key={connection_method.label}
                    value={connection_method.label}
                    selected={
                      formData.connection_method === connection_method.label
                    }
                  >
                    {connection_method.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Opt in:</Label>
              <Select
                name="opt_in"
                defaultValue={formData.opt_in || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {optIn.map(opt_in => (
                  <option
                    key={opt_in.label}
                    value={opt_in.label}
                    selected={formData.opt_in === opt_in.label}
                  >
                    {opt_in.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Credor solicitou retorno pois:</Label>
              <Select
                name="requested_return"
                defaultValue={formData.requested_return || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {requestedReturn.map(requested_return => (
                  <option
                    key={requested_return.label}
                    value={requested_return.label}
                    selected={
                      formData.requested_return === requested_return.label
                    }
                  >
                    {requested_return.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Observação:</Label>
              <Textarea
                name="observation"
                defaultValue=""
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Nota de esclarecimento:</Label>
              <Textarea
                disabled
                name="clarification_note"
                defaultValue={formData.clarification_note}
              />
            </InputContainer>

            <TimestampContainer>
              <InputContainer>
                <Label>Data:</Label>
                <Input
                  type="date"
                  name="date"
                  className="small-input"
                  onChange={handleInputChange}
                />
              </InputContainer>

              <InputContainer>
                <Label>Horário:</Label>
                <Select
                  name="time"
                  className="small-input"
                  defaultValue=""
                  onChange={handleInputChange}
                >
                  <option value="">Selecione um horário</option>
                  {availableHours &&
                    availableHours.map(availableHour => (
                      <option key={availableHour} value={availableHour}>
                        {availableHour}
                      </option>
                    ))}
                </Select>
              </InputContainer>
            </TimestampContainer>

            <InputContainer>
              <ButtonSave onClick={handleSaveSLA}>Salvar</ButtonSave>
            </InputContainer>
          </>
        )}
      </Main>
    </Modal>
  );
}
