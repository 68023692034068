import styled from 'styled-components';
import pauseIcon from '../../../../assets/icons/pause.svg';
import cancelIcon from '../../../../assets/icons/campaign_cancel.svg';

export const CampaignModalContainer = {
  content: {
    width: 'fit-content',
    'min-width': '39.625rem',
    height: 'fit-content',
    'min-height': '34.813rem',
    border: '0.094rem solid #041524',
    'border-radius': '0.5rem',
    'box-shadow': '0.25rem 0.25rem 0.25rem #00000066',
    margin: 'auto',
    padding: '1.188rem 3.247rem 1.063rem 3.247rem',

    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
  },
};

export const Title = styled.h2`
  margin-right: auto;
  margin-left: auto;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  color: #05233e;
`;

export const Separator = styled.hr`
  width: 9.5rem;
  margin: 0rem auto 1.125rem auto;
  margin-bottom: 1.125rem;
  color: #9d9d9d;
`;

export const FieldName = styled.span`
  margin-top: 1.125rem;

  display: flex;
  align-items: center;
  justify-content: left;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  color: #05233e;
`;

export const CampaignNameInput = styled.input`
  width: 33.125rem;
  height: 2.0625rem;
  border: 0.0625rem solid #05233e;
  border-radius: 0.5rem;
  padding: 0.625rem;

  display: flex;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
`;

export const MessageInput = styled.textarea`
  width: 33.125rem;
  height: 6.375rem;
  border: 0.0625rem solid #05233e;
  border-radius: 0.5rem;
  padding: 0.5625rem;
  resize: none;

  display: flex;
  justify-content: left;
  align-items: flex-start;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  color: #041524;
`;

export const ConfigContainer = styled.div`
  width: 25.625rem;
  margin: 0rem auto 0rem auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScheduleContainer = styled.div`
  width: 20.625rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SchedulingInfoInput = styled.input`
  width: 9.9375rem;
  height: 2.0625rem;
  border: 0.0625rem solid #05233e;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  text-align: center;
  color: #041524;
`;

export const CostCenterInput = styled.input`
  width: 1.375rem;
  height: 1.375rem;
  appearance: none;
  border: 0.0625rem solid #05233e;
  border-radius: 0.6875rem;
  margin-right: 1rem;

  display: grid;
  place-content: center;

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem #05233e;
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export const OperationContainer = styled.div`
  width: 3.375rem;
  margin-left: 1.8125rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlayButton = styled.button`
  width: 0.8125rem;
  height: 0.9375rem;
  border: none;
  background-image: url(${pauseIcon});
  background-color: #ffffff;
`;

export const CancelButton = styled.button`
  width: 1.125rem;
  height: 1.125rem;
  border: none;
  background-image: url(${cancelIcon});
  background-color: #ffffff;
`;

export const SaveButton = styled.button`
  width: 9.125rem;
  height: 2rem;
  background: #05233e;
  border: none;
  border-radius: 0.375rem;
  margin: 2.1875rem auto 0rem auto;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
`;

export const CharacterCount = styled.span`
  width: 3.25rem;
  height: 1.625rem;
  margin-top: -1.625rem;
  margin-left: auto;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-size: 0.625rem;
  color: #6d6d6d;
`;
