import styled from 'styled-components';
import { shade } from 'polished';

export const Separator = styled.hr`
  width: 16rem;
  margin-top: 0.5rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const ButtonsContainerEditUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem 0;
`;

export const ButtonEditUser = styled.button`
  width: 6.3rem;
  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: none;
  padding: 0 0.7rem;
  border-radius: 0.25rem;

  color: var(--white);
  font-weight: 600;
  font-size: 0.9rem;

  background: var(--green-600);
  transition: background-color 0.2ms;

  &:hover {
    background: ${shade(0.3, '#089B63')};
  }
`;
