import styled, { css } from 'styled-components';

export const Container = styled.div``;

interface ScheduleProps {
  statusTask?: boolean;
  isBefore?: boolean;
  checklist_concluded?: boolean;
}

export const TableContainer = styled.table`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 44.5rem;

  overflow: auto;
`;

export const HeadContainer = styled.thead`
  width: 100%;
  height: 2.375rem;

  border-top: 1px solid rgba(194, 194, 194, 0.5);
  border-bottom: 1px solid rgba(194, 194, 194, 0.5);
  background: rgba(22, 101, 216, 0.05);
  border-radius: 0.125rem;
`;

export const RowBody = styled.tr<ScheduleProps>`
  margin-bottom: 0.25rem;

  width: 100%;
  height: 100%;
  transition: 0.2s;
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-template-columns: 10.3% 20.2% 18.5% 16.5% 16% 18.5%;

  width: 100%;
  height: 100%;

  th:nth-child(1) {
    height: 100%;
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(2) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(3) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(4) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(5) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  .time {
    font-size: 0.938rem;
    color: var(--blue-700);

    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  ${props =>
    props.statusTask
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: -2px 0 0 var(--green-400);

          :hover {
            background: rgba(60, 166, 71, 0.4);
          }
        `
      : props.isBefore && !props.statusTask && !props.checklist_concluded
      ? css`
          background: rgba(222, 12, 12, 0.25);
          box-shadow: -2px 0 0 var(--red-700);

          :hover {
            background: rgba(222, 12, 12, 0.4);
          }
        `
      : !props.statusTask && props.checklist_concluded
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: -2px 0 0 var(--yellow-700);

          :hover {
            background: rgba(180, 173, 9, 0.4);
          }
        `
      : css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: -2px 0 0 var(--blue-500);

          :hover {
            background: rgba(1, 64, 119, 0.2);
          }
        `}
`;

export const RowContainer = styled.tr`
  display: grid;
  align-items: center;
  grid-template-columns: 10.3% 20.2% 18.5% 16.5% 16% 18.5%;

  width: 100%;
  height: 100%;

  th:nth-child(1) {
    height: 100%;
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(2) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(3) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(4) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(5) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }
`;

export const ColumnHeadContainer = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 0.813rem;
  color: var(--blue-800);

  height: 1.875rem;
`;

export const BodyContainer = styled.tbody`
  width: 100%;
  height: 3.125rem;

  border-radius: 0.125rem;

  .row-props-body {
    height: 3.063rem;
  }

  .header {
    height: 2.375rem;
  }
`;

export const ColumnBodyContainer = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;

  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--gray-800);
  height: 100%;
`;

export const Timestamp = styled.div`
  display: flex;
  flex-direction: column;

  font-weight: 600;
`;
