import { useCallback, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { AiOutlinePlus, AiFillCaretDown } from 'react-icons/ai';

import { Link, useHistory } from 'react-router-dom';

import apiCustomerSuccess from '../../../services/apiCustomerSuccess';
import { formatDate } from '../../../utils/formatDate';

import { Container } from '../../../components/Container';
import { PaginationComponent } from '../../../components/Pagination';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Styles/Title';
import { InputDate } from '../../../components/InputDate';

import { changePagination } from '../../../utils/changePagination';
import { formatAmount } from '../../../utils/formatAmount';
import { PaymentDTO } from './dtos/PaymentDTO';

import { ReactComponent as ApprovedIcon } from '../../../assets/icons/approved.svg';
import { ReactComponent as ReprovedIcon } from '../../../assets/icons/notApproved.svg';
import { ReactComponent as PendingIcon } from '../../../assets/icons/pending.svg';

import {
  DivSeparator,
  Separator,
  Main,
  Table,
  THead,
  TBody,
  TableRow,
  ButtonPaymentBox,
  ButtonNewPayment,
  ButtonPaymentType,
  ContainerButtonPayment,
  DivNameButton,
  IconCreate,
} from './styles';

interface IPaymentsResponse {
  totalDayPresentAmount: string;
  totalDayCostAmount: string;
  totalDayNominalAmount: string;
  totalDayTransactionAmount: string;
  totalDayRefoundAmount: string;
  payments: PaymentDTO[];
}

export function Payments(): JSX.Element {
  const history = useHistory();

  const [date, setDate] = useState(new Date());
  const [payments, setPayments] = useState<IPaymentsResponse>(
    {} as IPaymentsResponse,
  );

  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<PaymentDTO[]>(
    [] as PaymentDTO[],
  );
  const [choosePaymentDropdown, setChoosePaymentDropdown] = useState(false);

  useEffect(() => {
    const formattedDate = formatDate(date);

    apiCustomerSuccess.get(`/payments?date=${formattedDate}`).then(response => {
      setPayments(response.data);

      setCurrentItems(response.data.payments.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.payments.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, [date]);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: PaymentDTO[] = payments.payments;

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [page, payments, totalPages],
  );

  const handleGetStatusIcon = useCallback((status: string) => {
    if (status === 'STATUS_REMESSA_CONCLUIDA') {
      return <ApprovedIcon />;
    }
    if (
      status === 'STATUS_REMESSA_INTERROMPIDA' ||
      status === 'STATUS_REMESSA_DADOS_ERRO' ||
      status === 'FALHA_AO_ENVIAR'
    ) {
      return <ReprovedIcon />;
    }
    return <PendingIcon />;
  }, []);

  const handleUpdatePayment = useCallback(
    (payment: PaymentDTO) => {
      history.push({
        pathname: '/vortx/payments/create',
        state: {
          payment,
        },
      });
    },
    [history],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Vortx {'>'} </H1Navigation>
        <H1NavigationNow>Pagamentos</H1NavigationNow>
      </Navegation>

      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Pagamentos</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <Main>
          <ButtonPaymentBox>
            <ButtonNewPayment
              value="default_value"
              onClick={() => setChoosePaymentDropdown(!choosePaymentDropdown)}
            >
              Novo Pagamento
              <AiFillCaretDown />
            </ButtonNewPayment>

            {choosePaymentDropdown && (
              <ContainerButtonPayment>
                <Link
                  to={{
                    pathname: '/vortx/payments/create',
                    state: { type: 'fundo ii' },
                  }}
                >
                  <ButtonPaymentType value="fundo ii" className="buttonOne">
                    <IconCreate>
                      <AiOutlinePlus />
                    </IconCreate>
                    <DivNameButton>FUNDO II</DivNameButton>
                  </ButtonPaymentType>
                </Link>
                <Link
                  to={{
                    pathname: '/vortx/payments/create',
                    state: { type: 'fundo iii' },
                  }}
                >
                  <ButtonPaymentType value="fundo iii" className="buttonTwo">
                    <IconCreate>
                      <AiOutlinePlus />
                    </IconCreate>
                    <DivNameButton>FUNDO III</DivNameButton>
                  </ButtonPaymentType>
                </Link>
                <Link
                  to={{
                    pathname: '/vortx/payments/create',
                    state: { type: 'fundo iv' },
                  }}
                >
                  <ButtonPaymentType value="fundo iv" className="buttonTwo">
                    <IconCreate>
                      <AiOutlinePlus />
                    </IconCreate>
                    <DivNameButton>FUNDO IV</DivNameButton>
                  </ButtonPaymentType>
                </Link>
              </ContainerButtonPayment>
            )}
          </ButtonPaymentBox>

          <InputDate date={date} setDate={setDate} />

          <Table>
            <THead>
              <TableRow>
                <th>ID Hubspot</th>
                <th>Nome</th>
                <th>Sacado</th>
                <th>Valor nominal</th>
                <th>Valor custo</th>
                <th>Valor presente</th>
                <th>Valor transação</th>
                <th>Outros pagamentos</th>
                <th>Status</th>
              </TableRow>
            </THead>

            <TBody>
              {currentItems &&
                currentItems.map(payment => (
                  <TableRow
                    id="body-row"
                    key={payment.id}
                    onClick={() => handleUpdatePayment(payment)}
                  >
                    <td>{payment.hubspot_deal_id}</td>
                    <td>{payment.name}</td>
                    <td>
                      {payment.debitor &&
                        payment.debitor.name &&
                        payment.debitor.name}
                    </td>
                    <td>
                      {payment.nominal_value
                        ? formatAmount(Number(payment.nominal_value))
                        : ''}
                    </td>
                    <td>
                      {payment.cost_value
                        ? formatAmount(Number(payment.cost_value))
                        : ''}
                    </td>
                    <td>
                      {payment.present_value
                        ? formatAmount(Number(payment.present_value))
                        : ''}
                    </td>
                    <td>
                      {payment.transaction_value
                        ? formatAmount(Number(payment.transaction_value))
                        : ''}
                    </td>
                    <td>
                      {payment.refund_value
                        ? formatAmount(Number(payment.refund_value))
                        : ''}
                    </td>
                    <td>
                      {payment.status
                        ? handleGetStatusIcon(payment.status)
                        : ''}
                    </td>
                  </TableRow>
                ))}
            </TBody>
            <TBody>
              <TableRow key="0">
                <td />
                <td />
                <td>
                  <b>Total:</b>
                </td>
                <td>
                  <b>
                    {payments.totalDayNominalAmount
                      ? payments.totalDayNominalAmount
                      : formatAmount(0)}
                  </b>
                </td>
                <td>
                  <b>
                    {payments.totalDayCostAmount
                      ? payments.totalDayCostAmount
                      : formatAmount(0)}
                  </b>
                </td>
                <td>
                  <b>
                    {payments.totalDayPresentAmount
                      ? payments.totalDayPresentAmount
                      : formatAmount(0)}
                  </b>
                </td>
                <td>
                  <b>
                    {payments.totalDayTransactionAmount
                      ? payments.totalDayTransactionAmount
                      : formatAmount(0)}
                  </b>
                </td>
                <td>
                  <b>
                    {payments.totalDayRefoundAmount
                      ? payments.totalDayRefoundAmount
                      : formatAmount(0)}
                  </b>
                </td>
                <td />
              </TableRow>
            </TBody>
          </Table>
        </Main>

        <PaginationComponent
          totalPages={totalPages}
          page={page}
          handleChangePagination={handleChangePagination}
        />
      </Body>
    </Container>
  );
}
