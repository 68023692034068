import styled from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';

export const LostModalStyle = {
  content: {
    width: '600px',
    height: '250px',
    margin: 'auto',
    padding: '0',
    overflow: 'auto',
    border: '1px solid #CFDEF3',
    borderRadius: '5px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const LostModalStyleCEO = {
  content: {
    width: '600px',
    height: '200px',
    margin: 'auto',
    padding: '0',
    overflow: 'auto',
    border: '1px solid #CFDEF3',
    borderRadius: '5px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const Main = styled.div`
  margin-bottom: ${pxToRem(16)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 25px;
  background: #cfdef3;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DescTitle = styled.h3`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.015em;
  text-align: center;
`;

export const InputArea = styled.textarea`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 570px;
  height: 96px;
  padding: 0.25rem;
  transition: border 0.2s;
  text-align: justify;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 20px;
  &:focus {
    border: 1px solid #2e9afe;
  }
  resize: none;
`;

export const ApprovedButton = styled.button`
  width: 160px;
  height: 25px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: white;
  align-items: center;
  letter-spacing: -0.015em;
  background: #063966;
  border: 0;
  border-radius: 0.5rem;
`;

export const DeniedButton = styled.button`
  width: 160px;
  height: 25px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: white;
  align-items: center;
  letter-spacing: -0.015em;
  background: #ea3f3f;

  border: 0;
  border-radius: 0.5rem;
`;

export const UploadFileContainer = styled.div`
  width: 570px;
  height: 30px;
  margin-bottom: 20px;

  form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }

  input {
    font-family: 'Poppins';
    font-size: 16px;
    cursor: pointer;
  }
`;

export const DownloadFileContainer = styled.a`
  width: 160px;
  height: 25px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: white;
  align-items: center;
  letter-spacing: -0.015em;
  background: #063966;
  border: 0;
  border-radius: 0.5rem;

  cursor: ${props => (props.href ? 'pointer' : 'not-allowed')};
`;
