import administrationIcon from '../../../assets/icons/adm.svg';
import enrichment from '../../../assets/icons/enrichment.svg';
import connectionIcon from '../../../assets/icons/connection.svg';
import negociationIcon from '../../../assets/icons/negociation.svg';
import searchProcessesIcon from '../../../assets/searchProcesses.svg';
import vortxIcon from '../../../assets/icons/vortx.svg';
import profilesIcon from '../../../assets/icons/profile.svg';
import teamsIcon from '../../../assets/starTeams.svg';
import permissionsIcon from '../../../assets/permissions.svg';
import usersIcon from '../../../assets/icons/user.svg';
import settingsIcon from '../../../assets/settings.svg';
import takeblipIcon from '../../../assets/icons/takeblip.svg';
import smsReturnsIcon from '../../../assets/smsReturns.svg';
import optInIcon from '../../../assets/opt-in.svg';
import calculatorIcon from '../../../assets/calculator.svg';
import scheduleIcon from '../../../assets/icons/schedule_icon.svg';
import processingIcon from '../../../assets/icons/processamento.svg';
import hammer2Icon from '../../../assets/hammer2.svg';
import liquidationIcon from '../../../assets/icons/liquidation.svg';
import debitorsIcon from '../../../assets/debitors.svg';
import paymentIcon from '../../../assets/payment.svg';
import assignorIcon from '../../../assets/assignor.svg';
import okrIcon from '../../../assets/icons/okr.svg';
import reportIcon from '../../../assets/report.svg';
import callListIcon from '../../../assets/call-list.svg';
import queueIcon from '../../../assets/icons/queueIcon.svg';
import performanceIcon from '../../../assets/icons/performance.svg';
import chatIcon from '../../../assets/icons/chat.svg';
import painelSlaIcon from '../../../assets/icons/panel_icon.svg';
import newsletterIcon from '../../../assets/icons/newsletter.svg';
import reportCloserIcon from '../../../assets/icons/report_closer_icon.svg';
import clockIcon from '../../../assets/icons/clock_icon.svg';
import wppConfigIcon from '../../../assets/icons/wpp_config_icon.svg';
import chatbotFlow from '../../../assets/icons/chatbotFlow.svg';

export function handleGetPageIcon(name_page: string): string {
  let sourceIcon = '';

  switch (name_page) {
    case 'Administração':
      sourceIcon = administrationIcon;
      break;

    case 'Configurar Closer':
      sourceIcon = settingsIcon;
      break;

    case 'Perfis':
      sourceIcon = profilesIcon;
      break;

    case 'Permissões':
      sourceIcon = permissionsIcon;
      break;

    case 'Times':
      sourceIcon = teamsIcon;
      break;

    case 'Queue':
      sourceIcon = queueIcon;
      break;

    case 'Usuários':
      sourceIcon = usersIcon;
      break;

    case 'Conexão':
      sourceIcon = connectionIcon;
      break;

    case 'Take blip':
      sourceIcon = takeblipIcon;
      break;

    case 'Chatbot Flow':
      sourceIcon = chatbotFlow;
      break;

    case 'Retornos SMS':
      sourceIcon = smsReturnsIcon;
      break;

    case 'Performance':
      sourceIcon = performanceIcon;
      break;

    case 'Disparo WPP':
      sourceIcon = chatIcon;
      break;

    case 'Disparo RCS':
      sourceIcon = chatIcon;
      break;

    case 'Enriquecimento':
      sourceIcon = enrichment;
      break;

    case 'Opt in':
      sourceIcon = optInIcon;
      break;

    case 'Precificação':
      sourceIcon = searchProcessesIcon;
      break;

    case 'Relatório':
      sourceIcon = reportIcon;
      break;

    case 'Auditar Ligações':
      sourceIcon = callListIcon;
      break;

    case 'Negociação':
      sourceIcon = negociationIcon;
      break;

    case 'Painel SLA':
      sourceIcon = painelSlaIcon;
      break;

    case 'Painel SLA Hunter':
      sourceIcon = painelSlaIcon;
      break;

    case 'Relatório Closer':
      sourceIcon = reportCloserIcon;
      break;

    case 'Calculadora':
      sourceIcon = calculatorIcon;
      break;

    case 'Calculadora Cliente':
      sourceIcon = calculatorIcon;
      break;

    case 'Cálculos':
      sourceIcon = calculatorIcon;
      break;

    case 'Agenda':
      sourceIcon = scheduleIcon;
      break;

    case 'Novo agendamento':
      sourceIcon = scheduleIcon;
      break;

    case 'Agenda CS':
      sourceIcon = scheduleIcon;
      break;

    case 'Processamento':
      sourceIcon = processingIcon;
      break;

    case "Cronologia TJ's":
      sourceIcon = hammer2Icon;
      break;

    case "Ofícios TJ's":
      sourceIcon = hammer2Icon;
      break;

    case "Ofícios TRF's":
      sourceIcon = hammer2Icon;
      break;

    case 'TRF2':
      sourceIcon = hammer2Icon;
      break;

    case 'TRF4':
      sourceIcon = hammer2Icon;
      break;

    case 'Bases TRF':
      sourceIcon = hammer2Icon;
      break;

    case 'Pesquisa de Processos':
      sourceIcon = searchProcessesIcon;
      break;

    case 'Vórtx':
      sourceIcon = vortxIcon;
      break;

    case 'Cedentes':
      sourceIcon = assignorIcon;
      break;

    case 'Entes devedores':
      sourceIcon = debitorsIcon;
      break;

    case 'Liquidação':
      sourceIcon = liquidationIcon;
      break;

    case 'Pagamento':
      sourceIcon = paymentIcon;
      break;

    case "OKR's":
      sourceIcon = okrIcon;
      break;

    case 'Newsletter':
      sourceIcon = newsletterIcon;
      break;

    case 'Acompanhamentos':
      sourceIcon = searchProcessesIcon;
      break;

    case 'Representante':
      sourceIcon = searchProcessesIcon;
      break;

    case 'Disparo SMS':
      sourceIcon = chatIcon;
      break;

    case 'Priorização':
      sourceIcon = painelSlaIcon;
      break;

    case 'Horário de atendimento':
      sourceIcon = clockIcon;
      break;

    case 'Configuração WhatsApp':
      sourceIcon = wppConfigIcon;
      break;

    case 'Cessão':
      sourceIcon = hammer2Icon;
      break;

    default:
      break;
  }

  return sourceIcon;
}
