/**
 * Define os tipos de conexão dinamicamente de acordo com a pipeline do ticket
 */
export default async function handleSetConnectionsByPipelineId(
  setConnections: (value: React.SetStateAction<any[]>) => void,
  ticketPipelineId: string,
  line: string,
): Promise<void> {
  if (
    Number(ticketPipelineId) === 2154049 ||
    Number(ticketPipelineId) === 9528351
  ) {
    setConnections([
      { key: 'Telefone', value: 'Retomada' },
      { key: 'Honorários', value: 'Honorários' },
      { key: 'E-mail', value: 'Retomada Digital' },
      { key: 'Instagram', value: 'Retomada Digital' },
      { key: 'Facebook', value: 'Retomada Digital' },
      { key: 'Google', value: 'Retomada Digital' },
      { key: 'Youtube', value: 'Retomada Digital' },
      { key: 'Site', value: 'Retomada Digital' },
      { key: 'Bing', value: 'Retomada Digital' },
      { key: 'RCS', value: 'Retomada In Ativo' },
      { key: 'SMS', value: 'Retomada In Ativo' },
      { key: 'WhatsApp', value: 'Retomada In Ativo' },
      { key: 'WhatsApp Automático', value: 'Retomada In Ativo' },
    ]);
  } else if (
    Number(ticketPipelineId) === 37781341 ||
    Number(ticketPipelineId) === 66169943
  ) {
    setConnections([
      { key: 'SMS', value: 'Novo Processo Inbound' },
      { key: 'WhatsApp', value: 'Novo Processo Inbound' },
      { key: 'WhatsApp Automático', value: 'Novo Processo Inbound' },
      { key: 'RCS', value: 'Novo Processo Inbound' },
      { key: 'E-mail', value: 'Novo Processo Digital' },
      { key: 'Instagram', value: 'Novo Processo Digital' },
      { key: 'Facebook', value: 'Novo Processo Digital' },
      { key: 'Google', value: 'Novo Processo Digital' },
      { key: 'Bing', value: 'Novo Processo Digital' },
      { key: 'Youtube', value: 'Novo Processo Digital' },
      { key: 'Site', value: 'Novo Processo Digital' },
      { key: 'Telefone', value: 'Novo Processo' },
      { key: 'Honorários', value: 'Honorários' },
    ]);
  } else if (Number(ticketPipelineId) === 66707464 || line === 'LINHA 4') {
    setConnections([
      { key: 'SMS', value: 'Nutrição' },
      { key: 'WhatsApp', value: 'Nutrição' },
      { key: 'WhatsApp Automático', value: 'Nutrição' },
      { key: 'RCS', value: 'Nutrição' },
      { key: 'E-mail', value: 'Nutrição' },
      { key: 'Instagram', value: 'Nutrição' },
      { key: 'Facebook', value: 'Nutrição' },
      { key: 'Google', value: 'Nutrição' },
      { key: 'Bing', value: 'Nutrição' },
      { key: 'Youtube', value: 'Nutrição' },
      { key: 'Site', value: 'Nutrição' },
      { key: 'Telefone', value: 'Nutrição' },
      { key: 'Honorários', value: 'Honorários' },
    ]);
  } else {
    setConnections([
      { key: 'SMS', value: 'Inbound Ativo' },
      { key: 'WhatsApp', value: 'Inbound Ativo' },
      { key: 'WhatsApp Automático', value: 'Inbound Ativo' },
      { key: 'RCS', value: 'Inbound Ativo' },
      { key: 'E-mail', value: 'Inbound Digital' },
      { key: 'Instagram', value: 'Inbound Digital' },
      { key: 'Facebook', value: 'Inbound Digital' },
      { key: 'Google', value: 'Inbound Digital' },
      { key: 'Bing', value: 'Inbound Digital' },
      { key: 'Youtube', value: 'Inbound Digital' },
      { key: 'Site', value: 'Inbound Digital' },
      { key: 'Telefone', value: 'Outbound' },
      { key: 'Honorários', value: 'Honorários' },
    ]);
  }
}
