export const walletSLAFields = [
  {
    label: 'informacoes_carteira_closer',
    field: 'Informacoes carteira closer',
  },
  { label: 'motivo', field: 'Motivo da etapa' },
  {
    label: 'motivo_da_perda_carteira_closer',
    field: 'Motivo da perda carteira closer',
  },
  {
    label: 'valor_que_o_credor_venderia',
    field: 'Valor que o credor venderia',
  },
  { label: 'qual_a_dor_do_credor', field: 'Qual a dor do credor' },
  {
    label: 'qual_pergunta_de_implicacao_o_credor_fez',
    field: 'Qual pergunta de implicacao o credor fez',
  },
  {
    label: 'qual_a_frequencia_de_contato_com_o_cliente',
    field: 'Qual a frequencia de contato com o cliente',
  },
  {
    label: 'quando_credor_acredita_que_sera_o_pagamento',
    field: 'Quando credor acredita que sera o pagamento',
  },
  { label: 'objetivo_do_credor', field: 'Objetivo do credor' },
  { label: 'influencia_interferencia', field: 'Influencia interferencia' },
  { label: 'observacao_carteira_closer', field: 'Observacao carteira closer' },
  { label: 'proposta', field: 'Proposta' },
  {
    label: 'data_ultimo_contato_carteira_closer',
    field: 'Data ultimo contato carteira closer',
  },
];
