import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '../../../components/Container';
import { Title } from '../../../components/Styles/Title';
import { Body } from '../../../components/Styles/Body';
import {
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import {
  DivSeparator,
  Separator,
  Table,
  TableRow,
  Thead,
  HeadCell,
  Tbody,
  BodyCell,
  SendButtonContainer,
  ButtonsContainer,
  InputRes,
  EspecialCell,
  Select,
  Option,
  BoxTable,
  BoxHeader,
  BoxBody,
  BoxHeadCell,
  BoxRow,
  BoxBodyCell,
  BoxInput,
  ConcBoxRow,
  ConcBoxTable,
  LoaInput,
  ShowAnswer,
  BoxHeadFirstCell,
  LoaInputShowing,
  BoxInputShowing,
  AddDescriptionButton,
  ShowInputRes,
} from './styles';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import {
  ApprovedField,
  ApprovedResField,
  DeniedField,
  ReturnButtonContainer,
} from './SecondFormPage/styles';
import { DescriptionModal } from '../DescriptionModal';

interface DefaultFormData {
  lu_prospect: Date | string;
  nu_prospect: Date | string;
  lu_prospect_automatization: Date | string;
  nu_prospect_automatization: Date | string;
  lu_rcl_percent: Date | string;
  nu_rcl_percent: Date | string;
  lu_regime_time: Date | string;
  nu_regime_time: Date | string;
  lu_deposit_term: Date | string;
  nu_deposit_term: Date | string;
  lu_payment_timing: Date | string;
  nu_payment_timing: Date | string;
  lu_creditor_timing: Date | string;
  nu_creditor_timing: Date | string;
  lu_mapped: Date | string;
  nu_mapped: Date | string;
  lu_payment_term: Date | string;
  nu_payment_term: Date | string;
  lu_final_term: Date | string;
  nu_final_term: Date | string;
  lu_election_period: Date | string;
  nu_election_period: Date | string;
  lu_rating: Date | string;
  nu_rating: Date | string;
}
interface FormData {
  id: string;
  approved: string;
  place_id: string;
  prospect: string;
  lu_prospect: string;
  nu_prospect: string;

  prospect_automatization: string;
  lu_prospect_automatization: string;
  nu_prospect_automatization: string;

  rcl_percent: string;
  lu_rcl_percent: string;
  nu_rcl_percent: string;

  regime_time: string;
  lu_regime_time: string;
  nu_regime_time: string;

  deposit_term: string;
  lu_deposit_term: string;
  nu_deposit_term: string;

  payment_timing: string;
  lu_payment_timing: string;
  nu_payment_timing: string;

  creditor_timing: string;
  lu_creditor_timing: string;
  nu_creditor_timing: string;

  mapped: string;
  lu_mapped: string;
  nu_mapped: string;

  payment_term_01: string;
  payment_term_02: string;
  payment_term_03: string;
  lu_payment_term: string;
  nu_payment_term: string;

  loa_payment_term_01: string;
  loa_payment_term_02: string;
  loa_payment_term_03: string;

  final_term_01: string;
  final_term_02: string;
  final_term_03: string;
  lu_final_term: string;
  nu_final_term: string;

  loa_final_term_01: string;
  loa_final_term_02: string;
  loa_final_term_03: string;

  volume: string;
  lu_volume: string;
  nu_volume: string;

  expedition_percent: string;
  lu_expedition_percent: string;
  nu_expedition_percent: string;

  general_regime: string;
  lu_general_regime: string;
  nu_general_regime: string;

  politic: string;
  lu_politic: string;
  nu_politic: string;

  upper_change: string;
  lu_upper_change: string;
  nu_upper_change: string;

  election_period: string;
  lu_election_period: string;
  nu_election_period: string;

  rating: string;
  lu_rating: string;
  nu_rating: string;

  competidor_one: string;
  competidor_two: string;
  competidor_three: string;

  prices_table_one_01: string;
  prices_table_one_02: string;
  prices_table_one_03: string;
  prices_table_two_01: string;
  prices_table_two_02: string;
  prices_table_two_03: string;
  prices_table_three_01: string;
  prices_table_three_02: string;
  prices_table_three_03: string;

  loa_prices_table_01: string;
  loa_prices_table_02: string;
  loa_prices_table_03: string;

  observation_field: string;

  prospect_description: string;
  prospect_automatization_description: string;
  rcl_percent_description: string;
  regime_time_description: string;
  deposit_term_description: string;
  payment_timing_description: string;
  creditor_timing_description: string;
  mapped_description: string;
  volume_description: string;
  expedition_percent_description: string;
  general_regime_description: string;
  politic_description: string;
  upper_change_description: string;
  election_period_description: string;
  rating_description: string;
  payment_term_description: string;
  final_term_description: string;

  prospect_file: IFileProps;
  prospect_automatization_file: IFileProps;
  rcl_percent_file: IFileProps;
  regime_time_file: IFileProps;
  deposit_term_file: IFileProps;
  payment_timing_file: IFileProps;
  creditor_timing_file: IFileProps;
  mapped_file: IFileProps;
  volume_file: IFileProps;
  expedition_percent_file: IFileProps;
  general_regime_file: IFileProps;
  politic_file: IFileProps;
  upper_change_file: IFileProps;
  election_period_file: IFileProps;
  rating_file: IFileProps;
  payment_term_file: IFileProps;
  final_term_file: IFileProps;
}

interface IRequest {
  forms: FormData;
  description?: FormData;
}

interface IPlaceProps {
  place_name: string;
  place_id: string;
  lastPage: string;
  formDataSecondPage: FormData | undefined;
  descriptionSecondPage: FormData | undefined;
  dataDownloadSecondPage: { question: string; downloadLink: string }[];
}

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export function PricingForms(): JSX.Element {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [description, setDescription] = useState<FormData>({} as FormData);
  const [descriptionName, setDescriptionName] = useState<string>('');
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [defaultFormData, setDefaultFormData] = useState<DefaultFormData>(
    {} as DefaultFormData,
  );
  const [dataFileName, setDataFileName] = useState<string>('');
  const [dataDownload, setDataDownload] = useState<any>(null);

  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const props = location.state as IPlaceProps;

  const { place_name, place_id } = props;

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;
      let valor = value;
      setFormData({ ...formData, [name]: value });
      if (name.includes('lu') || name.includes('nu')) {
        setFormData({ ...formData, [name]: `${value} 3:00` });
      }
      if (name === 'payment_timing' || name === 'creditor_timing') {
        if (value === '1') valor = `${value} mês`;
        else valor = `${value} meses`;

        event.target.value = valor;
        setFormData({ ...formData, [name]: valor });
      }
    },
    [formData],
  );

  const handleCloseDescriptionModal = useCallback(() => {
    setOpenDescriptionModal(false);
  }, []);

  const handleModalSubmit = useCallback(
    (value: string, file: IFileProps) => {
      setOpenDescriptionModal(false);
      setFormData({
        ...formData,
        [descriptionName]: value,
        [dataFileName]: file,
      });
      setDescription({
        ...description,
        [descriptionName]: value,
        [dataFileName]: file,
      });
    },
    [formData, descriptionName, description, dataFileName],
  );

  const setModal = useCallback((name: string, fileName: string) => {
    setDataFileName(fileName);
    setDescriptionName(name);
    setOpenDescriptionModal(true);
  }, []);
  const splitLOA = useCallback((stringEntrada: string) => {
    if (stringEntrada) {
      const resultadoString = stringEntrada.split('#', 3);

      const LOA_01 = resultadoString[0].split('(');
      const LOA_02 = resultadoString[1].split('(');
      const LOA_03 = resultadoString[2].split('(');

      const array_LOA = [LOA_01[0], LOA_02[0], LOA_03[0]];

      return array_LOA;
    }
    return '';
  }, []);

  const splitResul = useCallback((stringEntrada: string) => {
    if (stringEntrada) {
      const resultadoString = stringEntrada.split('#', 3);

      const LOA_01 = resultadoString[0].split('(');
      const LOA_02 = resultadoString[1].split('(');
      const LOA_03 = resultadoString[2].split('(');
      const resul_01 = LOA_01[1].split(')');
      const resul_02 = LOA_02[1].split(')');
      const resul_03 = LOA_03[1].split(')');

      const array_resul = [resul_01[0], resul_02[0], resul_03[0]];

      return array_resul;
    }
    return '';
  }, []);

  const splitNormal = useCallback((stringEntrada: string) => {
    return stringEntrada ? stringEntrada.split('-', 3) : '';
  }, []);

  useEffect(() => {
    if (props.lastPage === 'Home') {
      api.get(`/pricing/forms/${props.place_id}`).then(response => {
        const loaFinalTerm = splitLOA(response.data.forms.final_term);

        const finalTerm = splitResul(response.data.forms.final_term);

        const loaPaymentTerm = splitLOA(response.data.forms.payment_term);

        const paymentTerm = splitResul(response.data.forms.payment_term);

        const loaPricingTable = splitLOA(response.data.forms.prices_table_one);

        const pricingTable1 = splitResul(response.data.forms.prices_table_one);

        const pricingTable2 = splitNormal(response.data.forms.prices_table_two);

        const pricingTable3 = splitNormal(
          response.data.forms.prices_table_three,
        );

        setFormData({
          ...response.data.forms,
          loa_final_term_01: loaFinalTerm[0],
          loa_final_term_02: loaFinalTerm[1],
          loa_final_term_03: loaFinalTerm[2],
          final_term_01: finalTerm[0],
          final_term_02: finalTerm[1],
          final_term_03: finalTerm[2],
          loa_payment_term_01: loaPaymentTerm[0],
          loa_payment_term_02: loaPaymentTerm[1],
          loa_payment_term_03: loaPaymentTerm[2],
          payment_term_01: paymentTerm[0],
          payment_term_02: paymentTerm[1],
          payment_term_03: paymentTerm[2],
          loa_prices_table_01: loaPricingTable[0],
          loa_prices_table_02: loaPricingTable[1],
          loa_prices_table_03: loaPricingTable[2],
          prices_table_one_01: pricingTable1[0],
          prices_table_one_02: pricingTable1[1],
          prices_table_one_03: pricingTable1[2],
          prices_table_two_01: pricingTable2[0],
          prices_table_two_02: pricingTable2[1],
          prices_table_two_03: pricingTable2[2],
          prices_table_three_01: pricingTable3[0],
          prices_table_three_02: pricingTable3[1],
          prices_table_three_03: pricingTable3[2],
        });
        setDefaultFormData(response.data.forms);

        setDescription({
          ...response.data.description,
        });

        setDataDownload({
          ...response.data.files,
        });
      });
    } else {
      setFormData(props.formDataSecondPage);
      setDescription(props.descriptionSecondPage);
      setDataDownload(props.dataDownloadSecondPage);

      setDefaultFormData({
        lu_prospect:
          props.formDataSecondPage.lu_prospect !== null
            ? new Date(`${props.formDataSecondPage.lu_prospect}`)
            : '',
        nu_prospect:
          props.formDataSecondPage.nu_prospect !== null
            ? new Date(`${props.formDataSecondPage.nu_prospect}`)
            : '',
        lu_prospect_automatization:
          props.formDataSecondPage.lu_prospect_automatization !== null
            ? new Date(`${props.formDataSecondPage.lu_prospect_automatization}`)
            : '',
        nu_prospect_automatization:
          props.formDataSecondPage.nu_prospect_automatization !== null
            ? new Date(`${props.formDataSecondPage.nu_prospect_automatization}`)
            : '',
        lu_rcl_percent:
          props.formDataSecondPage.lu_rcl_percent !== null
            ? new Date(`${props.formDataSecondPage.lu_rcl_percent}`)
            : '',
        nu_rcl_percent:
          props.formDataSecondPage.nu_rcl_percent !== null
            ? new Date(`${props.formDataSecondPage.nu_rcl_percent}`)
            : '',
        lu_regime_time:
          props.formDataSecondPage.lu_regime_time !== null
            ? new Date(`${props.formDataSecondPage.lu_regime_time}`)
            : '',
        nu_regime_time:
          props.formDataSecondPage.nu_regime_time !== null
            ? new Date(`${props.formDataSecondPage.nu_regime_time}`)
            : '',
        lu_deposit_term:
          props.formDataSecondPage.lu_deposit_term !== null
            ? new Date(`${props.formDataSecondPage.lu_deposit_term}`)
            : '',
        nu_deposit_term:
          props.formDataSecondPage.nu_deposit_term !== null
            ? new Date(`${props.formDataSecondPage.nu_deposit_term}`)
            : '',
        lu_payment_timing:
          props.formDataSecondPage.lu_payment_timing !== null
            ? new Date(`${props.formDataSecondPage.lu_payment_timing}`)
            : '',
        nu_payment_timing:
          props.formDataSecondPage.nu_payment_timing !== null
            ? new Date(`${props.formDataSecondPage.nu_payment_timing}`)
            : '',
        lu_creditor_timing:
          props.formDataSecondPage.lu_creditor_timing !== null
            ? new Date(`${props.formDataSecondPage.lu_creditor_timing}`)
            : '',
        nu_creditor_timing:
          props.formDataSecondPage.nu_creditor_timing !== null
            ? new Date(`${props.formDataSecondPage.nu_creditor_timing}`)
            : '',
        lu_mapped:
          props.formDataSecondPage.lu_mapped !== null
            ? new Date(`${props.formDataSecondPage.lu_mapped}`)
            : '',
        nu_mapped:
          props.formDataSecondPage.nu_mapped !== null
            ? new Date(`${props.formDataSecondPage.nu_mapped}`)
            : '',
        lu_payment_term:
          props.formDataSecondPage.lu_payment_term !== null
            ? new Date(`${props.formDataSecondPage.lu_payment_term}`)
            : '',
        nu_payment_term:
          props.formDataSecondPage.nu_payment_term !== null
            ? new Date(`${props.formDataSecondPage.nu_payment_term}`)
            : '',
        lu_final_term:
          props.formDataSecondPage.lu_final_term !== null
            ? new Date(`${props.formDataSecondPage.lu_final_term}`)
            : '',
        nu_final_term:
          props.formDataSecondPage.nu_final_term !== null
            ? new Date(`${props.formDataSecondPage.nu_final_term}`)
            : '',
        lu_election_period:
          props.formDataSecondPage.lu_election_period !== null
            ? new Date(`${props.formDataSecondPage.lu_election_period}`)
            : '',
        nu_election_period:
          props.formDataSecondPage.nu_election_period !== null
            ? new Date(`${props.formDataSecondPage.nu_election_period}`)
            : '',
        lu_rating:
          props.formDataSecondPage.lu_rating !== null
            ? new Date(`${props.formDataSecondPage.lu_rating}`)
            : '',
        nu_rating:
          props.formDataSecondPage.nu_rating !== null
            ? new Date(`${props.formDataSecondPage.nu_rating}`)
            : '',
      });
    }
  }, [splitLOA, splitNormal, splitResul, props]);

  const formattedTimestamp = useCallback(
    (timestamp: Date | undefined | string) => {
      return timestamp
        ? `${new Date(timestamp).getFullYear()}-${String(
            new Date(timestamp).getMonth() + 1,
          ).padStart(2, '0')}-${String(new Date(timestamp).getDate()).padStart(
            2,
            '0',
          )}`
        : '';
    },
    [],
  );
  const respFormattedTimestamp = useCallback(
    (timestamp: Date | undefined | string) => {
      const formatTimestamp = timestamp === null ? '' : timestamp;
      return formatTimestamp || formatTimestamp !== ''
        ? `${String(new Date(formatTimestamp).getDate()).padStart(
            2,
            '0',
          )}/${String(new Date(formatTimestamp).getMonth() + 1).padStart(
            2,
            '0',
          )}/${new Date(formatTimestamp).getFullYear()}`
        : '';
    },
    [],
  );

  return (
    <Container>
      <Navegation>
        <H1NavigationNow>Precificação</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>
          Precificação de entes - {place_name.toUpperCase()}{' '}
          {formData.approved === 'Aprovado' && user.profile === 'CEO' ? (
            <ButtonsContainer>
              <ApprovedField className="IDaprovado">Aprovado</ApprovedField>
            </ButtonsContainer>
          ) : formData.approved === 'Aprovado com ressalva' &&
            user.profile === 'CEO' ? (
            <ButtonsContainer>
              <ApprovedResField>Aprovado com ressalvas</ApprovedResField>
            </ButtonsContainer>
          ) : formData.approved === 'Reprovado' && user.profile === 'CEO' ? (
            <ButtonsContainer>
              <DeniedField>Reprovado</DeniedField>
            </ButtonsContainer>
          ) : (
            <></>
          )}
        </Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <Table>
          <Thead>
            <TableRow>
              <HeadCell>Perguntas</HeadCell>
              <HeadCell>Respostas</HeadCell>
              <HeadCell>Última atualização</HeadCell>
              <HeadCell>Próxima atualização</HeadCell>
            </TableRow>
          </Thead>

          <Tbody>
            <TableRow className="tr-body">
              <BodyCell>É possível fazer originação de prospects?</BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.prospect}</ShowAnswer>
                ) : (
                  <Select name="prospect" onChange={handleInputChange}>
                    <Option value="">Selecionar</Option>
                    <Option value="Sim" selected={formData.prospect === 'Sim'}>
                      Sim
                    </Option>
                    <Option value="Não" selected={formData.prospect === 'Não'}>
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_prospect"
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_prospect,
                    )}
                    disabled
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_prospect"
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_prospect,
                    )}
                    onChange={handleInputChange}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_prospect"
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_prospect,
                    )}
                    disabled
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_prospect"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_prospect,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('prospect_description', 'prospect_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                O processo para acessar os prospects é automatizado?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.prospect_automatization}</ShowAnswer>
                ) : (
                  <Select
                    name="prospect_automatization"
                    onChange={handleInputChange}
                  >
                    <Option value="">Selecionar</Option>
                    <Option
                      value="Sim"
                      selected={formData.prospect_automatization === 'Sim'}
                    >
                      Sim
                    </Option>
                    <Option
                      value="Não"
                      selected={formData.prospect_automatization === 'Não'}
                    >
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_prospect_automatization"
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_prospect_automatization,
                    )}
                    disabled
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_prospect_automatization"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_prospect_automatization,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_prospect_automatization"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_prospect_automatization,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_prospect_automatization"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_prospect_automatization,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal(
                        'prospect_automatization_description',
                        'prospect_automatization_file',
                      )
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                A expedição anual de precatório representa qual percental sobre
                a RCL do ente?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.rcl_percent}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="rcl_percent"
                    onChange={handleInputChange}
                    defaultValue={formData.rcl_percent || ''}
                    disabled={user.profile === 'CEO'}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_rcl_percent"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_rcl_percent,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_rcl_percent"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_rcl_percent,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_rcl_percent"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_rcl_percent,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_rcl_percent"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_rcl_percent,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('rcl_percent_description', 'rcl_percent_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>A quanto tempo está no Regime Geral?</BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.regime_time}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="regime_time"
                    onChange={handleInputChange}
                    defaultValue={formData.regime_time || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_regime_time"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_regime_time,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_regime_time"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_regime_time,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_regime_time"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_regime_time,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_regime_time"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_regime_time,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('regime_time_description', 'regime_time_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>O ente deposita na conta do TJ regularmente?</BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.deposit_term}</ShowAnswer>
                ) : (
                  <Select name="deposit_term" onChange={handleInputChange}>
                    <Option value="">Selecionar</Option>
                    <Option
                      value="Sim"
                      selected={formData.deposit_term === 'Sim'}
                    >
                      Sim
                    </Option>
                    <Option
                      value="Não"
                      selected={formData.deposit_term === 'Não'}
                    >
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_deposit_term"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_deposit_term,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_deposit_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_deposit_term,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_deposit_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_deposit_term,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_deposit_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_deposit_term,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('deposit_term_description', 'deposit_term_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>Qual o tempo até o prazo de depósito?</BodyCell>
              <BodyCell>
                <BoxTable>
                  <BoxHeader>
                    <BoxRow>
                      <BoxHeadCell>
                        LOA{' '}
                        {user.profile === 'CEO' ? (
                          <LoaInputShowing
                            id="text-id"
                            name="loa_payment_term_01"
                            defaultValue={formData.loa_payment_term_01 || ''}
                            disabled
                          />
                        ) : (
                          <LoaInput
                            id="text-id"
                            name="loa_payment_term_01"
                            onChange={handleInputChange}
                            defaultValue={formData.loa_payment_term_01 || ''}
                          />
                        )}
                      </BoxHeadCell>
                      <BoxHeadCell>
                        LOA{' '}
                        {user.profile === 'CEO' ? (
                          <LoaInputShowing
                            id="text-id"
                            name="loa_payment_term_02"
                            defaultValue={formData.loa_payment_term_02 || ''}
                            disabled
                          />
                        ) : (
                          <LoaInput
                            id="text-id"
                            name="loa_payment_term_02"
                            onChange={handleInputChange}
                            defaultValue={formData.loa_payment_term_02 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        )}
                      </BoxHeadCell>
                      <BoxHeadCell>
                        LOA{' '}
                        {user.profile === 'CEO' ? (
                          <LoaInputShowing
                            id="text-id"
                            name="loa_payment_term_03"
                            defaultValue={formData.loa_payment_term_03 || ''}
                            disabled
                          />
                        ) : (
                          <LoaInput
                            id="text-id"
                            name="loa_payment_term_03"
                            onChange={handleInputChange}
                            defaultValue={formData.loa_payment_term_03 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        )}
                      </BoxHeadCell>
                    </BoxRow>
                  </BoxHeader>

                  <BoxBody>
                    <BoxRow>
                      <BoxBodyCell>
                        {user.profile === 'CEO' ? (
                          <BoxInputShowing
                            id="LOA-id"
                            name="payment_term_01"
                            defaultValue={formData.payment_term_01 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        ) : (
                          <BoxInput
                            id="LOA-id"
                            name="payment_term_01"
                            onChange={handleInputChange}
                            defaultValue={formData.payment_term_01 || ''}
                          />
                        )}
                      </BoxBodyCell>
                      <BoxBodyCell>
                        {user.profile === 'CEO' ? (
                          <BoxInputShowing
                            id="LOA-id"
                            name="payment_term_02"
                            defaultValue={formData.payment_term_02 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        ) : (
                          <BoxInput
                            id="LOA-id"
                            name="payment_term_02"
                            onChange={handleInputChange}
                            defaultValue={formData.payment_term_02 || ''}
                          />
                        )}
                      </BoxBodyCell>
                      <BoxBodyCell>
                        {user.profile === 'CEO' ? (
                          <BoxInputShowing
                            id="LOA-id"
                            name="payment_term_03"
                            defaultValue={formData.payment_term_03 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        ) : (
                          <BoxInput
                            id="LOA-id"
                            name="payment_term_03"
                            onChange={handleInputChange}
                            defaultValue={formData.payment_term_03 || ''}
                          />
                        )}
                      </BoxBodyCell>
                    </BoxRow>
                  </BoxBody>
                </BoxTable>
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_payment_term"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_payment_term,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_payment_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_payment_term,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_payment_term"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_payment_term,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_payment_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_payment_term,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('payment_term_description', 'payment_term_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                Qual o tempo de pagamento do TJ ao credor após o prazo
                estabelicido?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.payment_timing}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="payment_timing"
                    onChange={handleInputChange}
                    defaultValue={formData.payment_timing || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_payment_timing"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_payment_timing,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_payment_timing"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_payment_timing,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_payment_timing"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_payment_timing,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_payment_timing"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_payment_timing,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal(
                        'payment_timing_description',
                        'payment_timing_file',
                      )
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                Qual o tempo de levantamento do credor após pagamento do TJ?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.creditor_timing}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="creditor_timing"
                    onChange={handleInputChange}
                    defaultValue={formData.creditor_timing || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_creditor_timing"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_creditor_timing,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_creditor_timing"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_creditor_timing,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_creditor_timing"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_creditor_timing,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_creditor_timing"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_creditor_timing,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal(
                        'creditor_timing_description',
                        'creditor_timing_file',
                      )
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>

            <TableRow className="tr-body">
              <BodyCell>Qual o prazo final de recebimento?</BodyCell>
              <BodyCell>
                <BoxTable>
                  <BoxHeader>
                    <BoxRow>
                      <BoxHeadCell>
                        LOA{' '}
                        {user.profile === 'CEO' ? (
                          <LoaInputShowing
                            id="text-id"
                            name="loa_final_term_01"
                            defaultValue={formData.loa_final_term_01 || ''}
                            disabled
                          />
                        ) : (
                          <LoaInput
                            id="LOA-id"
                            name="loa_final_term_01"
                            onChange={handleInputChange}
                            defaultValue={formData.loa_final_term_01 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        )}
                      </BoxHeadCell>
                      <BoxHeadCell>
                        LOA{' '}
                        {user.profile === 'CEO' ? (
                          <LoaInputShowing
                            id="text-id"
                            name="loa_final_term_02"
                            defaultValue={formData.loa_final_term_02 || ''}
                            disabled
                          />
                        ) : (
                          <LoaInput
                            id="LOA-id"
                            name="loa_final_term_02"
                            onChange={handleInputChange}
                            defaultValue={formData.loa_final_term_02 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        )}
                      </BoxHeadCell>
                      <BoxHeadCell>
                        LOA{' '}
                        {user.profile === 'CEO' ? (
                          <LoaInputShowing
                            id="text-id"
                            name="loa_final_term_03"
                            defaultValue={formData.loa_final_term_03 || ''}
                            disabled
                          />
                        ) : (
                          <LoaInput
                            id="LOA-id"
                            name="loa_final_term_03"
                            onChange={handleInputChange}
                            defaultValue={formData.loa_final_term_03 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        )}
                      </BoxHeadCell>
                    </BoxRow>
                  </BoxHeader>

                  <BoxBody>
                    <BoxRow>
                      <BoxBodyCell>
                        {user.profile === 'CEO' ? (
                          <BoxInputShowing
                            id="LOA-id"
                            name="final_term_01"
                            defaultValue={formData.final_term_01 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        ) : (
                          <BoxInput
                            id="text-id"
                            name="final_term_01"
                            onChange={handleInputChange}
                            defaultValue={formData.final_term_01 || ''}
                          />
                        )}
                      </BoxBodyCell>
                      <BoxBodyCell>
                        {user.profile === 'CEO' ? (
                          <BoxInputShowing
                            id="LOA-id"
                            name="final_term_02"
                            defaultValue={formData.final_term_02 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        ) : (
                          <BoxInput
                            id="text-id"
                            name="final_term_02"
                            onChange={handleInputChange}
                            defaultValue={formData.final_term_02 || ''}
                          />
                        )}
                      </BoxBodyCell>
                      <BoxBodyCell>
                        {user.profile === 'CEO' ? (
                          <BoxInputShowing
                            id="LOA-id"
                            name="final_term_03"
                            defaultValue={formData.final_term_03 || ''}
                            disabled={user.profile === 'CEO'}
                          />
                        ) : (
                          <BoxInput
                            id="text-id"
                            name="final_term_03"
                            onChange={handleInputChange}
                            defaultValue={formData.final_term_03 || ''}
                          />
                        )}
                      </BoxBodyCell>
                    </BoxRow>
                  </BoxBody>
                </BoxTable>
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_final_term"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_final_term,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_final_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_final_term,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_final_term"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_final_term,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_final_term"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_final_term,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('final_term_description', 'final_term_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                O processo para levantar os valores está 100% mapeado?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.mapped}</ShowAnswer>
                ) : (
                  <Select name="mapped" onChange={handleInputChange}>
                    <Option value="">Selecionar</Option>
                    <Option value="Sim" selected={formData.mapped === 'Sim'}>
                      Sim
                    </Option>
                    <Option value="Não" selected={formData.mapped === 'Não'}>
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_mapped"
                    disabled
                    defaultValue={formattedTimestamp(defaultFormData.lu_mapped)}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_mapped"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(defaultFormData.lu_mapped)}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_mapped"
                    disabled
                    defaultValue={formattedTimestamp(defaultFormData.nu_mapped)}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_mapped"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(defaultFormData.nu_mapped)}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('mapped_description', 'mapped_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <EspecialCell>
              <ConcBoxTable>
                <BoxHeader>
                  <ConcBoxRow>
                    <BoxHeadFirstCell>Concorrentes</BoxHeadFirstCell>
                    <BoxHeadCell>
                      LOA{' '}
                      {user.profile === 'CEO' ? (
                        <LoaInputShowing
                          id="text-id"
                          name="loa_prices_table_01"
                          defaultValue={formData.loa_prices_table_01 || ''}
                          disabled
                        />
                      ) : (
                        <LoaInput
                          id="text-id"
                          name="loa_prices_table_01"
                          onChange={handleInputChange}
                          defaultValue={formData.loa_prices_table_01 || ''}
                          disabled={user.profile === 'CEO'}
                        />
                      )}
                    </BoxHeadCell>
                    <BoxHeadCell>
                      LOA{' '}
                      {user.profile === 'CEO' ? (
                        <LoaInputShowing
                          id="text-id"
                          name="loa_prices_table_02"
                          defaultValue={formData.loa_prices_table_02 || ''}
                          disabled
                        />
                      ) : (
                        <LoaInput
                          id="text-id"
                          name="loa_prices_table_02"
                          onChange={handleInputChange}
                          defaultValue={formData.loa_prices_table_02 || ''}
                          disabled={user.profile === 'CEO'}
                        />
                      )}
                    </BoxHeadCell>
                    <BoxHeadCell>
                      LOA{' '}
                      {user.profile === 'CEO' ? (
                        <LoaInputShowing
                          id="text-id"
                          name="loa_prices_table_03"
                          defaultValue={formData.loa_prices_table_03 || ''}
                          disabled
                        />
                      ) : (
                        <LoaInput
                          id="text-id"
                          name="loa_prices_table_03"
                          onChange={handleInputChange}
                          defaultValue={formData.loa_prices_table_03 || ''}
                          disabled={user.profile === 'CEO'}
                        />
                      )}
                    </BoxHeadCell>
                  </ConcBoxRow>
                </BoxHeader>

                <ConcBoxRow>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="competidor_one"
                        defaultValue={formData.competidor_one || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="competidor_one"
                        onChange={handleInputChange}
                        defaultValue={formData.competidor_one || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_one_01"
                        defaultValue={formData.prices_table_one_01 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_one_01"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_one_01 || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_one_02"
                        defaultValue={formData.prices_table_one_02 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_one_02"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_one_02 || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_one_03"
                        defaultValue={formData.prices_table_one_03 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_one_03"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_one_03 || ''}
                      />
                    )}
                  </BoxBodyCell>
                </ConcBoxRow>

                <ConcBoxRow>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="competidor_two"
                        defaultValue={formData.competidor_two || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="competidor_two"
                        onChange={handleInputChange}
                        defaultValue={formData.competidor_two || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_two_01"
                        defaultValue={formData.prices_table_two_01 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_two_01"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_two_01 || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_two_02"
                        defaultValue={formData.prices_table_two_02 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_two_02"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_two_02 || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_two_03"
                        defaultValue={formData.prices_table_two_03 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_two_03"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_two_03 || ''}
                      />
                    )}
                  </BoxBodyCell>
                </ConcBoxRow>

                <ConcBoxRow>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="competidor_three"
                        defaultValue={formData.competidor_three || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="competidor_three"
                        onChange={handleInputChange}
                        defaultValue={formData.competidor_three || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_three_01"
                        defaultValue={formData.prices_table_three_01 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_three_01"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_three_01 || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_three_02"
                        defaultValue={formData.prices_table_three_02 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_three_02"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_three_02 || ''}
                      />
                    )}
                  </BoxBodyCell>
                  <BoxBodyCell>
                    {user.profile === 'CEO' ? (
                      <BoxInputShowing
                        id="LOA-id"
                        name="prices_table_three_03"
                        defaultValue={formData.prices_table_three_03 || ''}
                        disabled
                      />
                    ) : (
                      <BoxInput
                        id="text-id"
                        name="prices_table_three_03"
                        onChange={handleInputChange}
                        defaultValue={formData.prices_table_three_03 || ''}
                        disabled={user.profile === 'CEO'}
                      />
                    )}
                  </BoxBodyCell>
                </ConcBoxRow>
              </ConcBoxTable>
            </EspecialCell>
          </Tbody>
        </Table>
        <ButtonsContainer>
          <ReturnButtonContainer>
            <button
              type="button"
              onClick={() =>
                history.push({
                  pathname: `/pricing`,
                })
              }
            >
              Cancelar
            </button>
          </ReturnButtonContainer>
          <SendButtonContainer>
            <button
              type="button"
              onClick={() =>
                history.push({
                  pathname: `/pricing/state/page`,
                  state: {
                    state: { id: place_id, place_name },
                    formData,
                    description,
                    dataDownload,
                  },
                })
              }
            >
              Seguinte
            </button>
          </SendButtonContainer>
        </ButtonsContainer>
      </Body>
      {openDescriptionModal && (
        <DescriptionModal
          isOpen={openDescriptionModal}
          onRequestClose={handleCloseDescriptionModal}
          description={description}
          descriptionName={descriptionName}
          handleModalSubmit={handleModalSubmit}
          dataFileName={dataFileName}
          dataDownload={dataDownload}
        />
      )}
    </Container>
  );
}

export default PricingForms;
