/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from 'react-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

// import { MultiSelect } from 'react-multi-select-component';

import { format } from 'date-fns';

import Swal from 'sweetalert2';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar_blue.svg';

import editIcon from '../../../assets/icons/edit.svg';
import closeIcon from '../../../assets/icons/cancel.svg';

import { confirmModal } from '../../../components/ModalConfirm';

import {
  Container,
  Header,
  ButtonsHeaderContainer,
  EditButton,
  CloseButton,
  Main,
  LabelInputContainer,
  Label,
  Input,
  Textarea,
  ContainerNote,
  ContainerCheckbox,
  ContainerInputCheckbox,
  LabelCheckbox,
  InputCheckbox,
  ButtonDeprioritize,
  ContainerInputDate,
  ContainerDayPicker,
  ButtonAddDateLastContact,
} from './styles';
import { ICasePriorizationDTO } from '../dtos/ICasePriorizationDTO';
import { ContainerStep, StepItem } from '../TableCasePriorization/styles';
import { formatAmount } from '../../../utils/formatAmount';
import { DayPicker } from '../../../components/DayPicker';
import api from '../../../services/api';

Modal.setAppElement('#root');

interface FormData {
  observation_providences_partner_fund: string;
  providence_partner?: SelectProvidences[];
}

interface IRequestBody {
  observacao_providencias_fundo_parceiro: string;
  ultimo_contato_fundo_parceiro?: Date | string;
}

interface SelectProvidences {
  label: string;
  value: string;
}

interface Checklist {
  cartorio: string;
  finalizacao: string;
  providencias: string;
  analise_juridica: string;
  aguardando_transferencia: string;
}

interface ModalActivityProps {
  isOpen: boolean;
  onRequestClose: () => void;
  activity: ICasePriorizationDTO;
  allCases: ICasePriorizationDTO[];
  setAllCases: (cases: ICasePriorizationDTO[]) => void;
  currentItems: ICasePriorizationDTO[];
  setCurrentItems: (cases: ICasePriorizationDTO[]) => void;
}

export function ModalUpdateActivity({
  isOpen,
  onRequestClose,
  activity,
  allCases,
  setAllCases,
  currentItems,
  setCurrentItems,
}: ModalActivityProps): JSX.Element {
  const [editFields, setEditFields] = useState(false);
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [selectedChecklist, setSelectedChecklist] = useState<Checklist>(
    {} as Checklist,
  );
  // const [stepSelected, setStepSelected] = useState<string>('');
  const [date, setDate] = useState<Date>();
  // const [allProvidences, setAllProvidences] = useState<SelectProvidences[]>();
  // const [selectedProvidence, setSelectedProvidence] =
  //   useState<SelectProvidences[]>();
  const [selectedActivity, setSelectedActivity] =
    useState<ICasePriorizationDTO>({} as ICasePriorizationDTO);

  // const caseStep = [
  //   'Cartório',
  //   'Finalização',
  //   'Providências',
  //   'Análise Jurídica',
  //   'Aguardando Transferência',
  // ];

  useEffect(() => {
    if (!isOpen) {
      setEditFields(false);
      setFormData({} as FormData);
      setSelectedChecklist({} as Checklist);
      setDate(null);
    }

    if (isOpen && activity.date_contact) {
      setDate(new Date(activity.date_contact));
    }
  }, [isOpen, onRequestClose, activity]);

  useEffect(() => {
    if (activity.date_contact) {
      setDate(new Date(activity.date_contact));
    }
    setSelectedActivity(activity);

    // setSelectedProvidence([
    //   // {
    //   //   value: activity.providence_partner,
    //   //   label: activity.providence_partner,
    //   // },
    // ]);
  }, [activity]);

  // useEffect(() => {
  //   api
  //     .get<SelectProvidences[]>('/cases_priorization/providence-partners')
  //     .then(response => {
  //       setAllProvidences(response.data);
  //     })
  //     .catch(() => {
  //       setAllProvidences([
  //         {
  //           label: 'Erro ao buscar opções de providências',
  //           value: 'Erro ao buscar opções de providências',
  //         },
  //       ]);
  //     });
  // }, []);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  // const handleInputCheckboxChange = useCallback(
  //   (event: ChangeEvent<HTMLInputElement>) => {
  //     const { value, name } = event.target;
  //     setSelectedChecklist({ ...selectedChecklist, [name]: value });
  //   },
  //   [selectedChecklist],
  // );

  const handleDepriorize = useCallback(async () => {
    const confirm = await confirmModal(
      'Tem certeza que deseja retirar caso da priorização?',
    );

    if (confirm) {
      await api
        .patch(
          `/cases_priorization/update-ticket-hubspot/${activity.hubspot_ticket_id} `,
          {
            fora_da_priorizacao_fundo_parceiro: true,
          },
        )
        .then(response => {
          const findIndexActivity = allCases.findIndex(
            foundActivity => foundActivity === selectedActivity,
          );

          const allCasesAux = allCases;
          allCasesAux.splice(findIndexActivity, 1);
          setAllCases(allCasesAux);

          const findIndexActivityCurrentItems = currentItems.findIndex(
            foundActivity => foundActivity === selectedActivity,
          );

          const currentItemsAux = currentItems;
          currentItemsAux.splice(findIndexActivityCurrentItems, 1);
          setCurrentItems(currentItemsAux);

          Swal.fire('Sucesso!', 'Caso retirado da priorização', 'success');

          onRequestClose();
        })
        .catch(error => {
          Swal.fire(
            'Erro ao tirar caso da priorização!',
            error.response.data.message,
            'error',
          );
        });
    }
  }, [
    selectedActivity,
    allCases,
    onRequestClose,
    setAllCases,
    setCurrentItems,
    currentItems,
    activity,
  ]);

  const handleSaveChanges = useCallback(async () => {
    const confirm = await confirmModal(
      'Tem certeza que deseja salvar as alterações?',
    );
    if (confirm) {
      let bodyRequest: IRequestBody = {
        observacao_providencias_fundo_parceiro:
          formData.observation_providences_partner_fund ||
          formData.observation_providences_partner_fund === ''
            ? formData.observation_providences_partner_fund
            : selectedActivity.observation_providences_partner_fund,
      };

      if (date) {
        bodyRequest = {
          observacao_providencias_fundo_parceiro:
            formData.observation_providences_partner_fund ||
            formData.observation_providences_partner_fund === ''
              ? formData.observation_providences_partner_fund
              : selectedActivity.observation_providences_partner_fund,
          ultimo_contato_fundo_parceiro: format(date, 'yyyy-MM-dd'),
        };
      }

      api
        .patch(
          `/cases_priorization/update-ticket-hubspot/${activity.hubspot_ticket_id} `,
          bodyRequest,
        )
        .then(response => {
          const observationProvidencesPartnerFunds =
            formData.observation_providences_partner_fund ||
            formData.observation_providences_partner_fund === ''
              ? formData.observation_providences_partner_fund
              : selectedActivity.observation_providences_partner_fund;

          // const providencePartner =
          //   formData.providence_partner || formData.providence_partner.length === 0
          //     ? formData.providence_partner
          //     : selectedActivity.providence_partner;

          const findIndexUpdatedActivity = allCases.findIndex(
            foundActivity =>
              foundActivity.hubspot_ticket_id ===
              selectedActivity.hubspot_ticket_id,
          );

          const findIndexUpdatedActivityInCurrentItems = currentItems.findIndex(
            foundActivity =>
              foundActivity.hubspot_ticket_id ===
              selectedActivity.hubspot_ticket_id,
          );

          let bodyNewActivity = {
            ...allCases[findIndexUpdatedActivity],
            observation_providences_partner_fund:
              observationProvidencesPartnerFunds,
            // providence_partner: providencePartner.toString(),
          } as ICasePriorizationDTO;

          if (date) {
            bodyNewActivity = {
              ...allCases[findIndexUpdatedActivity],
              observation_providences_partner_fund:
                observationProvidencesPartnerFunds,
              // providence_partner: providencePartner.toString(),
              date_contact: date,
            } as ICasePriorizationDTO;
          }

          const newActivity: ICasePriorizationDTO = bodyNewActivity;

          setSelectedActivity(newActivity);

          allCases.splice(findIndexUpdatedActivity, 1, newActivity);
          currentItems.splice(
            findIndexUpdatedActivityInCurrentItems,
            1,
            newActivity,
          );

          setCurrentItems(currentItems);
          setAllCases(allCases);

          setEditFields(false);

          Swal.fire('Sucesso!', 'Caso editado com sucesso', 'success');
        })
        .catch(error => {
          Swal.fire(
            'Erro ao alterar informações do caso',
            error.response.data.message,
            'error',
          );
        });
    }
  }, [
    selectedActivity,
    formData,
    allCases,
    setAllCases,
    date,
    setCurrentItems,
    currentItems,
    activity,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={Container}>
      <Header>
        <span>Priorização de casos</span>

        <ButtonsHeaderContainer>
          {editFields ? (
            <EditButton editFields={editFields} onClick={handleSaveChanges}>
              Salvar alterações
            </EditButton>
          ) : (
            <EditButton
              onClick={() => {
                setEditFields(true);
              }}
            >
              <img src={editIcon} alt="Editar informações" />
            </EditButton>
          )}
          <CloseButton onClick={onRequestClose}>
            <img src={closeIcon} alt="Fechar modal" />
          </CloseButton>
        </ButtonsHeaderContainer>
      </Header>

      <Main>
        <>
          {selectedActivity.priority === 8 && (
            <ButtonDeprioritize onClick={handleDepriorize}>
              Retirar da priorização
            </ButtonDeprioritize>
          )}
          <LabelInputContainer>
            <Label>ID do Ticket:</Label>
            <Input
              type="text"
              value={selectedActivity.hubspot_ticket_id}
              disabled
            />
          </LabelInputContainer>

          <LabelInputContainer>
            <Label>Prioridade:</Label>
            <Input type="text" value={selectedActivity.priority} disabled />
          </LabelInputContainer>

          <LabelInputContainer>
            <Label>Ultimo contato:</Label>
            {editFields && (date || selectedActivity.date_contact) ? (
              <ContainerDayPicker>
                <ContainerInputDate>
                  <CalendarIcon />
                  <DayPicker value={date} handleChange={day => setDate(day)} />
                </ContainerInputDate>
              </ContainerDayPicker>
            ) : editFields && !date && !selectedActivity.date_contact ? (
              <ButtonAddDateLastContact onClick={() => setDate(new Date())}>
                Registrar data
              </ButtonAddDateLastContact>
            ) : selectedActivity.date_contact ? (
              <Input
                readOnly
                value={date ? format(new Date(date), 'dd/MM/yyyy') : null}
              />
            ) : (
              <Input type="text" disabled defaultValue="" />
            )}
          </LabelInputContainer>

          <LabelInputContainer editFields={editFields} selectField>
            <Label>Providências Parceiro:</Label>
            {/* {editFields ? (
              <MultiSelect
                options={allProvidences}
                value={selectedProvidence}
                onChange={setSelectedProvidence}
                labelledBy="Select"
              />
            ) : (
              <Select
                name="providence_partner"
                defaultValue=""
                onChange={handleInputChange}
              >
                <option disabled value="">
                  {selectedProvidence || 'Selecione providência'}
                </option>
                {allProvidences &&
                  allProvidences.map(providenceItem => (
                    <option value={providenceItem.value}>
                      {providenceItem.value}
                    </option>
                  ))}
              </Select>
              <Textarea
                defaultValue={selectedActivity.providence_partner || ''}
                disabled
              />
            )} */}
            <Textarea
              defaultValue={selectedActivity.providence_partner || ''}
              disabled
            />
          </LabelInputContainer>

          <LabelInputContainer editFields={editFields} textAreaField>
            <Label>Observações sobre Providências:</Label>
            {editFields ? (
              <Textarea
                name="observation_providences_partner_fund"
                defaultValue={
                  selectedActivity.observation_providences_partner_fund || ''
                }
                onChange={handleInputChange}
              />
            ) : (
              <Textarea
                name="observation_providences_partner_fund"
                defaultValue={
                  selectedActivity.observation_providences_partner_fund || ''
                }
                disabled
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <Label>Nome do Ticket:</Label>
            <Input type="text" value={selectedActivity.subject} disabled />
          </LabelInputContainer>

          <LabelInputContainer>
            <Label>Valor:</Label>
            <Input
              type="text"
              value={formatAmount(selectedActivity.amount)}
              disabled
            />
          </LabelInputContainer>

          <LabelInputContainer>
            <Label>Fundo Parceiro:</Label>
            <Input type="text" value={selectedActivity.partner_fund} disabled />
          </LabelInputContainer>

          {/* <ContainerStep>
            {caseStep &&
              caseStep.map(step => (
                <StepItem
                  key={step}
                  onClick={() => setStepSelected(step)}
                  active={step === stepSelected}
                >
                  {step}
                </StepItem>
              ))}
          </ContainerStep>
          {stepSelected !== '' && (
            <ContainerNote>
              Nota do hubspot:
              <ContainerCheckbox>
                {stepSelected === 'Cartório' && (
                  <>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op1"
                        disabled={!editFields}
                        name="cartorio"
                        value="CARTÓRIO - Cartorio Presencial - Cartório presencial agendado, na cidade de residencia do credor."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op1">
                        Cartorio Presencial
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op2"
                        disabled={!editFields}
                        name="cartorio"
                        value="CARTÓRIO - Cartório Presencial de Outra Cidade - Cartório presencial agendado em cidade próxima ao credor."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op2">
                        Cartório Presencial de outra cidade
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op3"
                        disabled={!editFields}
                        name="cartorio"
                        value="CARTÓRIO - Cartorio E-Notariado - Cartório agendado via e-notariado."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op3">
                        Cartorio E-Notariado
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                  </>
                )}

                {stepSelected === 'Finalização' && (
                  <>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op1"
                        disabled={!editFields}
                        name="finalizacao"
                        value="FINALIZAÇÃO - Minutas não encaminhadas ainda - Aguardando encaminhamento de minutas pelo Fundo Parceiro, aguardar validação e agendamento de cartório."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op1">
                        Minutas não encaminhadas ainda
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op2"
                        disabled={!editFields}
                        name="finalizacao"
                        value="FINALIZAÇÃO - Minutas já encaminhadas - Minutas encaminhadas por Fundo Parceiro, validar e aguardar agendamento de cartório."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op2">
                        Minutas já encaminhadas
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                  </>
                )}

                {stepSelected === 'Providências' && (
                  <>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op1"
                        disabled={!editFields}
                        name="providencias"
                        value="PROVIDÊNCIAS - Providencias Resolvidas - Providencias concluídas."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op1">
                        Providencias Resolvidas
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op2"
                        disabled={!editFields}
                        name="providencias"
                        value="PROVIDÊNCIAS - Providencias Solicitadas - Providencias solicitadas por Fundo Parceiro."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op2">
                        Providencias Solicitadas
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op3"
                        disabled={!editFields}
                        name="providencias"
                        value="PROVIDÊNCIAS - Providencias não resolvidas - Não foi possível concluir as providencias."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op3">
                        Providencias não resolvidas
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                  </>
                )}

                {stepSelected === 'Análise Jurídica' && (
                  <>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op1"
                        disabled={!editFields}
                        name="analise_juridica"
                        value="ANÁLISE JURÍDICA - Caso encaminhado – Caso encaminhado para análise jurídica de Fundo Parceiro."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op1">
                        Caso encaminhado
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op2"
                        disabled={!editFields}
                        name="analise_juridica"
                        value="ANÁLISE JURÍDICA - Analise finalizada (Com providencias) - Análise jurídica finalizada e com solicitação de providencias."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op2">
                        Analise finalizada (Com providencias)
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op3"
                        disabled={!editFields}
                        name="analise_juridica"
                        value="ANÁLISE JURÍDICA - Analise finalizada (Sem providencias) - Análise Juridica finalizada e sem solicitação de providencias."
                        onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op3">
                        Analise finalizada (Sem providencias)
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                  </>
                )}

                {stepSelected === 'Aguardando Transferência' && (
                  <>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op1"
                        disabled={!editFields}
                        name="aguardando_transferencia"
                        value="AGUARDANDO TRANSFERÊNCIA - Transferencia ótima – Cliente bem alinhado e tranquilo."
                        // onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op1">
                        Transferencia ótima
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op2"
                        disabled={!editFields}
                        name="aguardando_transferencia"
                        value="AGUARDANDO TRANSFERÊNCIA - Transferencia OK - Cliente alinhado"
                        // onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op2">
                        Transferencia OK
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                    <ContainerInputCheckbox>
                      <InputCheckbox
                        type="radio"
                        id="op3"
                        disabled={!editFields}
                        name="aguardando_transferencia"
                        value="AGUARDANDO TRANSFERÊNCIA - Transferencia regular - Cliente alinhado com ressalvas/impaciente"
                        // onChange={handleInputCheckboxChange}
                      />
                      <LabelCheckbox htmlFor="op3">
                        Transferencia regular
                      </LabelCheckbox>
                    </ContainerInputCheckbox>
                  </>
                )}
              </ContainerCheckbox>
            </ContainerNote> */}
          {/* )} */}
        </>
      </Main>
    </Modal>
  );
}
