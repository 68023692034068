import styled from 'styled-components';

const day_week = new Date().getDay();

export const Container = styled.div`
  width: calc(100% - 12rem);
  height: 100%;
`;

export const DaySelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  .text-to {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 0.688rem;
    color: #3d3d3d;
  }

  input {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    color: #05233e;
    border: none;

    width: 6.5rem;
    height: 1.875rem;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    margin-left: -1.5rem;
  }

  .DayPicker-Caption {
    position: absolute;
    margin-top: -4.4rem;
    margin-left: -1rem;
    width: 19.813rem;
    height: 2.438rem;
    background: #dfdfdf;
    border-radius: 0.625rem 0.625rem 0 0;

    div {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: flex-start;
      padding-left: 0.563rem;

      height: 2.438rem;
      width: 19.5rem;
      margin-left: -0.5rem;

      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 0.938rem;
      color: #041524;
    }
  }

  .DayPicker-NavButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.438rem;
    margin-top: -0.9rem;

    position: absolute;
    z-index: 2;
  }

  .DayPicker-NavButton--prev {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAALCAYAAACtWacbAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACkSURBVHgBjVDLDcIwDLUTnzhFlAGKBIgrE9BNaCeAEWADNghswDp8Dr0DUu8JNU4ugCDAk2zJes9+1gP4AzpFUG8wVZ3uTmpPb6wZ50TeAkIRZ5aFB5kbIpoz+kWYXq5Gz2w0A9WuA4mfrHU2tKjaEr5A3a7HCkGVCFgnRaG582HrnJvIk8ukKKKpG385rUhTX65unkWYspCcCkS0zFzBLxiJ5g4/RSVQXABJ5AAAAABJRU5ErkJggg==')
      no-repeat center;
    width: 0.5rem;
  }

  .DayPicker-NavButton--next {
    background: url('data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAkAAAALCAYAAACtWacbAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACiSURBVHgBnY6xDcIwEEX/2ZeOIhKkJsj0jACbYEZgAhgBJoBRvAEMAEqoKKBwiYSRcSIhpbCFlFecdKevdx/Iyxx/EMw8k4WqeDSdJ0PNIFAZhpFDdUA+GUdDP0iQ5kzWwbrp1hBRP2ErM3kK5mWzMhK0FQSOWaEWAmkswev347qKmSw8dgPn9tbWNvbOMH/0617dbOfYhsjj7IG1e16MQ0++WhMmYTuwYAUAAAAASUVORK5CYII=')
      no-repeat center;
  }

  .DayPicker-Week {
    :nth-child(1) {
      position: relative;
      bottom: -0.625rem;
    }
    :nth-child(2) {
      position: relative;
      bottom: -1.25rem;
    }
    :nth-child(3) {
      position: relative;
      bottom: -1.875rem;
    }
    :nth-child(4) {
      position: relative;
      bottom: -2.5rem;
    }
    :nth-child(5) {
      position: relative;
      bottom: -3.125rem;
    }
    :nth-child(6) {
      position: relative;
      bottom: -3.75rem;
    }
  }

  .DayPicker-Weekday {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: #3d3d3d;
    background: #ffffff;

    :nth-child(${day_week + 1}) {
      color: #1665d8;
    }
  }

  .DayPicker-Day--sunday {
    background: transparent;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start) {
    color: #05233e;
    border-top-left-radius: 0.313rem !important;
    border-bottom-left-radius: 0.313rem !important;
  }

  .DayPicker-Day--saturday:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    border-bottom-right-radius: 0.313rem !important;
    border-top-right-radius: 0.313rem !important;
  }

  .DayPicker-Day--endOfMonth {
    border-bottom-right-radius: 0.313rem !important;
    border-top-right-radius: 0.313rem !important;
  }

  .DayPicker-Day--startOfMonth {
    border-top-left-radius: 0.313rem !important;
    border-bottom-left-radius: 0.313rem !important;
  }

  .DayPicker-Body {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: #05233e;

    .DayPicker-Day {
      border-radius: 0;
    }
  }

  .DayPicker-Months {
    width: 19.813rem;
    height: 24rem;
    background: #ffffff;
    border-radius: 0.625rem;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .DayPicker-Month {
    width: 19.813rem;
    height: 17.25rem;
    margin-top: 2.5rem;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 0.313rem !important;
    border-bottom-left-radius: 0.313rem !important;
  }

  .DayPicker-Day--end {
    border-bottom-right-radius: 0.313rem !important;
    border-top-right-radius: 0.313rem !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    color: #05233e;
    font-weight: 500;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background: #1665d8 !important;
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.031rem solid #05233e;
  border-radius: 0.125rem;

  svg {
    margin-left: 0.438rem;
    width: 0.75rem;
    height: 0.75rem;
  }
`;
