import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';

import { useCallback, useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import {
  SLAQuestions,
  ModalHeader,
  Main,
  ColumnContainer,
  LabelInputContainer,
  ButtonsContainer,
  ButtonSaveSLAContainer,
  ButtonSend,
  Icon,
} from './styles';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';
import { IActivityDTO } from '../../dtos/IActivityDTO';
import { INextActivityDTO } from '../../dtos/INextActivityDTO';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

Modal.setAppElement('#root');
interface IProposalObjection {
  pss: string;
  gross_value: string;
  honorary: string;
  ir: string;
  liquid_value: string;
  proposal_value: string;
}
interface ICreditorPlans {
  debts: string;
  health_problems: string;
  buy_house: string;
  help_family: string;
  open_business: string;
  buy_car: string;
  travel: string;
  resource_security: string;
  investment: string;
  home_reform: string;
}
interface IProceures {
  time_money_fall_account: string;
  how_works_procedure: string;
  declare_tax: string;
  money_fall_at_hour: string;
}
interface IExpectationValue {
  value_reality_out: string;
  value_negociation: string;
}
interface ILawyer {
  make_contact_lawyer: string;
  make_contact_lawyer_us: string;
  dont_want_involve_lawyer: string;
  didnt_mention_lawyer: string;
}
interface IConcorrence {
  proposal_analysis: string;
  receive_calls: string;
  dint_mention_concorrence: string;
  dont_contact_concorrence: string;
  dint_accept_proposal: string;
}

interface IPec {
  didnt_mention_pec: string;
  dont_worry_late: string;
  indignant_about_late: string;
  conformed_about_late: string;
}
interface ISelectedItem {
  id: string;
  value: string;
}
interface IModalSLAInformation {
  isOpen: boolean;
  onRequestClose: () => void;
  activity: IActivityDTO | INextActivityDTO;
}

export function ModalSLAInformation({
  isOpen,
  onRequestClose,
  activity,
}: IModalSLAInformation): JSX.Element {
  const arrayButtonOptions = ['Sim', 'Não'];

  const [objectionOption, setObjectionOption] = useState<string>('');
  const [objectionOptionValue, setObjectionOptionValue] =
    useState<IProposalObjection>({} as IProposalObjection);

  const [plansOption, setPlansOption] = useState<string>('');
  const [plansOptionValue, setPlansOptionValue] = useState<ICreditorPlans>(
    {} as ICreditorPlans,
  );

  const [lawyerOption, setLawyerOption] = useState<string>('');
  const [lawyerOptionValue, setLawyerOptionValue] = useState<ILawyer>(
    {} as ILawyer,
  );

  const [pecOption, setPecOption] = useState<string>('');
  const [pecOptionValue, setPecOptionValue] = useState<IPec>({} as IPec);

  const [expectationValueOption, setExpectationValueOption] =
    useState<string>('');
  const [expectationValueOptionValue, setExpectationValueOptionValue] =
    useState<IExpectationValue>({} as IExpectationValue);

  const [proceduresOption, setProceduresOption] = useState<string>('');

  const [proceduresKnowledgeOption, setProceduresKnowledgeOption] =
    useState<string>('');

  const [precatoInformationOption, setPrecatoInformationOption] =
    useState<string>('');

  const [concorrenceOption, setConcorrenceOption] = useState<string>('');
  const [concorrenceOptioValue, setConcorrenceOptionValue] =
    useState<IConcorrence>({} as IConcorrence);

  const [proceduresOptionValue, setProceduresOptionValue] =
    useState<IProceures>({} as IProceures);

  const { user } = useAuth();

  useEffect(() => {
    api.get(`/schedule/sla-information/${activity.hubspot_deal_id}`);
  }, [activity.hubspot_deal_id]);

  const closeModal = useCallback(() => {
    onRequestClose();
    setObjectionOption('');
    setPlansOption('');
    setProceduresOption('');
    setLawyerOption('');
    setExpectationValueOption('');
    setProceduresKnowledgeOption('');
    setPrecatoInformationOption('');
    setConcorrenceOption('');
    setPecOption('');
    setPecOptionValue({} as IPec);
    setConcorrenceOptionValue({} as IConcorrence);
    setLawyerOptionValue({} as ILawyer);
    setExpectationValueOptionValue({} as IExpectationValue);
    setPlansOptionValue({} as ICreditorPlans);
    setObjectionOptionValue({} as IProposalObjection);
    setProceduresOptionValue({} as IProceures);
  }, [onRequestClose]);

  const onSelect = useCallback(
    (selectedList, selectedItem: ISelectedItem, question: string) => {
      if (question === 'plans') {
        setPlansOptionValue({
          ...plansOptionValue,
          [selectedItem.id]: selectedItem.value,
        });
      } else if (question === 'objection') {
        setObjectionOptionValue({
          ...objectionOptionValue,
          [selectedItem.id]: selectedItem.value,
        });
      } else if (question === 'procedures') {
        setProceduresOptionValue({
          ...proceduresOptionValue,
          [selectedItem.id]: selectedItem.value,
        });
      } else if (question === 'expectation_value') {
        setExpectationValueOptionValue({
          ...expectationValueOptionValue,
          [selectedItem.id]: selectedItem.value,
        });
      } else if (question === 'lawyer') {
        setLawyerOptionValue({
          ...lawyerOptionValue,
          [selectedItem.id]: selectedItem.value,
        });
      } else if (question === 'pec') {
        setPecOptionValue({
          ...pecOptionValue,
          [selectedItem.id]: selectedItem.value,
        });
      } else {
        setConcorrenceOptionValue({
          ...concorrenceOptioValue,
          [selectedItem.id]: selectedItem.value,
        });
      }
    },
    [
      pecOptionValue,
      concorrenceOptioValue,
      expectationValueOptionValue,
      lawyerOptionValue,
      objectionOptionValue,
      plansOptionValue,
      proceduresOptionValue,
    ],
  );

  const handleSaveSLASuccess = useCallback(() => {
    toast.success('Informações salvas com sucesso!');
  }, []);

  const handleSaveSLAError = useCallback((message: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  const handleWarning = useCallback(() => {
    Swal.fire('Atenção!', 'Preencha todos os campos!', 'warning');
  }, []);

  const onRemove = useCallback(
    (selectedList, removedItem: ISelectedItem, question: string) => {
      if (question === 'plans') {
        const items = plansOptionValue;

        delete items[removedItem.id];

        setPlansOptionValue(items);
      } else if (question === 'objection') {
        const items = objectionOptionValue;

        delete items[removedItem.id];
        setObjectionOptionValue(items);
      } else if (question === 'procedures') {
        const items = proceduresOptionValue;

        delete items[removedItem.id];

        setProceduresOptionValue(items);
      } else if (question === 'expectation_value') {
        const items = expectationValueOptionValue;

        delete items[removedItem.id];

        setExpectationValueOptionValue(items);
      } else if (question === 'lawyer') {
        const items = lawyerOptionValue;

        delete items[removedItem.id];

        setLawyerOptionValue(items);
      } else if (question === 'pec') {
        const items = pecOptionValue;

        delete items[removedItem.id];

        setPecOptionValue(items);
      } else {
        const items = concorrenceOptioValue;

        delete items[removedItem.id];

        setConcorrenceOptionValue(items);
      }
    },
    [
      pecOptionValue,
      concorrenceOptioValue,
      expectationValueOptionValue,
      lawyerOptionValue,
      objectionOptionValue,
      plansOptionValue,
      proceduresOptionValue,
    ],
  );

  const handleSubmit = useCallback(async () => {
    if (
      plansOptionValue &&
      objectionOptionValue &&
      proceduresOptionValue &&
      expectationValueOptionValue &&
      concorrenceOptioValue &&
      lawyerOptionValue &&
      pecOptionValue
    ) {
      try {
        const formattedplansOptionValue = JSON.stringify(plansOptionValue);

        const formattedobjectionOptionValue =
          JSON.stringify(objectionOptionValue);

        const formattedexpectationValueOptionValue = JSON.stringify(
          expectationValueOptionValue,
        );
        const formattedlawyerOptionValue = JSON.stringify(lawyerOptionValue);

        const formattedconcorrenceOptioValue = JSON.stringify(
          concorrenceOptioValue,
        );
        const formattedproceduresOptionValue = JSON.stringify(
          proceduresOptionValue,
        );
        const formattedpecOptionValue = JSON.stringify(pecOptionValue);

        await api.post('/schedule/create-sla-informations', {
          hubspot_deal_id: activity.hubspot_deal_id,
          user_id: user.id,
          creditor_plans: formattedplansOptionValue,
          plans_option: plansOption === 'Sim',
          creditor_objection: formattedobjectionOptionValue,
          objection_option: objectionOption === 'Sim',
          creditor_procedures: formattedproceduresOptionValue,
          procedures_option: proceduresOption === 'Sim',
          creditor_laywer: formattedlawyerOptionValue,
          creditor_concorrence: formattedconcorrenceOptioValue,
          creditor_expectation_value: formattedexpectationValueOptionValue,
          creditor_pec_knowledge: formattedpecOptionValue,
          expectation_value_option: expectationValueOption === 'Sim',
          precato_information: precatoInformationOption === 'Sim',
          procedure_knowledge: proceduresKnowledgeOption === 'Sim',
          /*  authority: authorityOption === 'Sim',
          has_follow: flowOption === 'Sim', */
        });

        closeModal();

        handleSaveSLASuccess();
      } catch (error) {
        handleSaveSLAError(error.response.data.message);
      }
    } else {
      handleWarning();
    }
  }, [
    plansOptionValue,
    objectionOptionValue,
    proceduresOptionValue,
    expectationValueOptionValue,
    concorrenceOptioValue,
    lawyerOptionValue,
    pecOptionValue,
    activity.hubspot_deal_id,
    user.id,
    plansOption,
    objectionOption,
    proceduresOption,
    expectationValueOption,
    precatoInformationOption,
    proceduresKnowledgeOption,
    closeModal,
    handleSaveSLASuccess,
    handleSaveSLAError,
    handleWarning,
  ]);

  return (
    <Modal isOpen={isOpen} style={SLAQuestions}>
      <ModalHeader>SLA Proposta</ModalHeader>

      <Main>
        <ColumnContainer>
          <LabelInputContainer>
            <label>
              Após apresentar proposta houve objeções relacionada ao valor
              apresentado?
            </label>
            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={objectionOption === option}
                  fillHubspot
                  onClick={() =>
                    setObjectionOption(objectionOption === option ? '' : option)
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>

            {objectionOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'PSS',
                    id: 'pss',
                  },
                  {
                    value: 'Valor Bruto',
                    id: 'gross_value',
                  },
                  {
                    value: 'Honorário',
                    id: 'honorary',
                  },
                  {
                    value: 'Ir',
                    id: 'ir',
                  },
                  {
                    value: 'Valor Líquido',
                    id: 'liquid_value',
                  },
                  {
                    value: 'Valor Proposta',
                    id: 'proposal_value',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'objection')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'objection')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <label>
              Para fechar o negócio, você foi questionado a respeito dos
              procedimentos?
            </label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={proceduresOption === option}
                  fillHubspot
                  onClick={() =>
                    setProceduresOption(
                      proceduresOption === option ? '' : option,
                    )
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>

            {proceduresOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Quanto tempo o dinheiro cai na conta?',
                    id: 'time_money_fall_account',
                  },
                  {
                    value: 'Como funciona o procedimento?',
                    id: 'how_works_procedure',
                  },
                  {
                    value: 'Para declarar o imposto, como faço?',
                    id: 'declare_tax',
                  },
                  {
                    value: 'O dinheiro cai na hora que eu assinar?',
                    id: 'money_fall_at_hour',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'procedures')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'procedures')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
                style={{
                  option: {
                    width: '90%',
                  },
                }}
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <label>
              O credor mencionou valor de expectativa ou fez contra proposta?
            </label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={expectationValueOption === option}
                  fillHubspot
                  onClick={() =>
                    setExpectationValueOption(
                      expectationValueOption === option ? '' : option,
                    )
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>

            {expectationValueOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Expectativa fora da realidade',
                    id: 'value_reality_out',
                  },
                  {
                    value: 'Contra proposta abrindo margem de negociação',
                    id: 'value_negociation',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'expectation_value')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'expectation_value')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <label>Credor quer a participação do advogado?</label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={lawyerOption === option}
                  fillHubspot
                  onClick={() =>
                    setLawyerOption(lawyerOption === option ? '' : option)
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>
            {lawyerOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Ele fará o contato',
                    id: 'make_contact_lawyer',
                  },
                  {
                    value: 'Quer que a gente faça contato',
                    id: 'make_contact_lawyer_us',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'lawyer')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'lawyer')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
            {lawyerOption === 'Não' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Não quer envolver o advogado',
                    id: 'dont_want_involve_lawyer',
                  },
                  {
                    value: 'Não mencionou o advogado',
                    id: 'didnt_mention_lawyer',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'lawyer')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'lawyer')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <label>
              O credor mencionou algum grau de insatisfação em relação aos
              atrasos provocados pela PEC?
            </label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={pecOption === option}
                  fillHubspot
                  onClick={() =>
                    setPecOption(pecOption === option ? '' : option)
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>
            {pecOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value:
                      'Insatisfeito em relação aos atrasos, mas conformado. ',
                    id: 'conformed_about_late',
                  },
                  {
                    value: 'Insatisfeito em relação aos atrasos e indignado.',
                    id: 'indignant_about_late',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'pec')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'pec')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
            {pecOption === 'Não' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Não liga para os atrasos.',
                    id: 'dont_worry_late',
                  },
                  {
                    value: 'Não mencionou',
                    id: 'didnt_mention_pec',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'pec')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'pec')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
          </LabelInputContainer>
        </ColumnContainer>

        <ColumnContainer>
          <LabelInputContainer>
            <label>Credor tem planos/necessidades para o recurso?</label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={plansOption === option}
                  fillHubspot
                  onClick={() =>
                    setPlansOption(plansOption === option ? '' : option)
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>

            {plansOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Dívida',
                    id: 'debts',
                  },
                  {
                    value: 'Problemas de saúde',
                    id: 'health_problems',
                  },
                  {
                    value: 'Comprar casa',
                    id: 'buy_house',
                  },
                  {
                    value: 'Ajudar familiares',
                    id: 'help_family',
                  },
                  {
                    value: 'Abrir um negócio',
                    id: 'open_business',
                  },
                  {
                    value: 'Comprar carro',
                    id: 'buy_car',
                  },
                  {
                    value: 'Viagem',
                    id: 'travel',
                  },
                  {
                    value: 'Segurança em ter o recurso',
                    id: 'resource_security',
                  },
                  {
                    value: 'Investimento',
                    id: 'investment',
                  },
                  { value: 'Reforma da casa', id: 'home_reform' },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'plans')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'plans')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <label>O credor mencionou algo relacionado a concorrência?</label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={concorrenceOption === option}
                  fillHubspot
                  onClick={() =>
                    setConcorrenceOption(
                      concorrenceOption === option ? '' : option,
                    )
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>

            {concorrenceOption === 'Sim' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Está analisando propostas',
                    id: 'proposal_analysis',
                  },
                  {
                    value: 'Recebe ligações mas não se interessa em ouvir',
                    id: 'receive_calls',
                  },
                  {
                    value: 'Não aceitou a proposta da concorrente',
                    id: 'dint_accept_proposal',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'concorrence')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'concorrence')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
            {concorrenceOption === 'Não' && (
              <Multiselect
                displayValue="value"
                options={[
                  {
                    value: 'Nunca teve contato com a concorrência',
                    id: 'dont_contact_concorrence',
                  },
                  {
                    value: 'Não mencionou',
                    id: 'dint_mention_concorrence',
                  },
                ]}
                onSelect={(selectedList, selectedItem) =>
                  onSelect(selectedList, selectedItem, 'concorrence')
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemove(selectedList, selectedItem, 'concorrence')
                }
                showArrow
                placeholder="Selecione a(s) opções"
                showCheckbox
              />
            )}
          </LabelInputContainer>

          <LabelInputContainer>
            <label>
              Credor mencionou que já conhece o procedimento de antecipação?
            </label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={proceduresKnowledgeOption === option}
                  fillHubspot
                  onClick={() =>
                    setProceduresKnowledgeOption(
                      proceduresKnowledgeOption === option ? '' : option,
                    )
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>
          </LabelInputContainer>

          <LabelInputContainer>
            <label>
              O credor pediu informações sobre a PRECATO? (CNPJ, Endereço etc)
            </label>

            <ButtonsContainer>
              {arrayButtonOptions.map(option => (
                <ScheduleOptionsButton
                  key={option}
                  type="button"
                  selectedValue={precatoInformationOption === option}
                  fillHubspot
                  onClick={() =>
                    setPrecatoInformationOption(
                      precatoInformationOption === option ? '' : option,
                    )
                  }
                >
                  {option}
                </ScheduleOptionsButton>
              ))}
            </ButtonsContainer>
          </LabelInputContainer>
        </ColumnContainer>
      </Main>

      <ButtonSaveSLAContainer>
        <ButtonSend onClick={handleSubmit}>
          <Icon>
            <AiOutlineCheck />
          </Icon>
          Salvar
        </ButtonSend>
      </ButtonSaveSLAContainer>
    </Modal>
  );
}
