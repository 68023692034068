import React, { ReactNode } from 'react';

import { ContainerPage } from './styles';

interface ContentProps {
  children?: ReactNode;
}

export const Container: React.FC<ContentProps> = ({ children }) => {
  return <ContainerPage>{children}</ContainerPage>;
};
