import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Link, useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { addDays, format } from 'date-fns';
import { gql, useMutation } from '@apollo/client';
import { AiOutlineArrowLeft, AiOutlineCheck } from 'react-icons/ai';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import { SuccessfulModal } from '../SuccessfulModal';
import { Body } from '../../../../components/Styles/Body';
import { Title } from '../../../../components/Styles/Title';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';
import { Container } from '../../../../components/Container';
import { Loading } from '../../../../components/Loading';

import {
  Separator,
  DivSeparator,
  TitleBackButtonContainer,
  TitleContainer,
  BackButtonContainer,
  Icon,
  Main,
  ScheduleInfoContainer,
  FieldContainer,
  ButtonsWeekdayContainer,
  HoursContainer,
  PeriodContainer,
  Hour,
  CredorInfos,
  InfoLineContainer,
  ButtonsContainerSchedule,
  ButtonSchedule,
} from './styles';
import verifyDealTj from './utils/verifyDealTj';
import handleSetConnectionsByPipelineId from './utils/handleSetConnectionsByPipelineId';
import { Select } from '../SuccessfulModal/styles';

interface IHubspotDeal {
  deal: {
    hubspot_deal_id: string;
    dealname: string;
    dealstage: string;
    loa: string;
    contato_valido: string;
    trf: string;
    tj: string;
    produto_precato: string;
    valor_liquido_prospect: string;
    valor_oficio_expedido: string;
  };
  line: string;
  ticket_pipeline_id: string;
}

interface IPeriods {
  afternoon: Date[];
  evening: Date[];
  morning: Date[];
  queue: string;
  recommendedHour: Date;
}

interface IConnection {
  key: string;
  value: string;
}

const CreateScheduleHunter: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const [connections, setConnections] = useState<IConnection[]>([]);
  const [selectedConnectionText, setSelectedConnectionText] =
    useState<string>();

  const contacts = ['Telefone', 'Whatsapp', 'E-mail'];
  const [ticketId, setTicketId] = useState('');
  const [selectedContact, setSelectedContact] = useState<string>();
  const [selectedConnection, setSelectedConnection] = useState<string>();
  const [weekdays, setWeekdays] = useState<string[]>([]);
  const [isFriday, setIsFriday] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [hoursAvailable, setHoursAvailable] = useState<IPeriods>(
    {} as IPeriods,
  );
  const [hubspotDeal, setHubspotDeal] = useState<IHubspotDeal>(
    {} as IHubspotDeal,
  );
  const [selectedTime, setSelectedTime] = useState<Date>({} as Date);
  const [schedulingHubActivity, setSchedulingHubActivity] = useState('');
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [closerNameScheduling, setCloserNameScheduling] = useState(undefined);

  const handleCloseSuccessfulModal = useCallback(() => {
    setOpenSuccessfulModal(false);
  }, []);

  const SEND_NOTIFICATION = gql`
    mutation CreateScheduling($deal: String!, $info: String!) {
      createScheduling(deal: $deal, info: $info) {
        id
        updated_at
        type
        hubspot_deal_id
        dealname
        amount
        stage_name
        observations
        closer_id
        hunter_id
        hunter_name
        try_times
        talk
        contact
        contact_preference
        contato_valido
        status
        loa
        connection_method
        deleted_by
        current_lead_owner
        ticket_id
        timestamp
        deleted
        business_situation
        queue
        hub_activity_id
        created_at
      }
    }
  `;

  const [createScheduling] = useMutation(SEND_NOTIFICATION, {
    variables: {
      deal: JSON.stringify({
        hubspot_deal_id: hubspotDeal.deal
          ? hubspotDeal.deal.hubspot_deal_id
          : '',
        dealname: hubspotDeal.deal ? hubspotDeal.deal.dealname : '',
        amount: hubspotDeal.deal
          ? hubspotDeal.deal.valor_liquido_prospect ||
            String(0.67 * Number(hubspotDeal.deal.valor_oficio_expedido))
          : '',
        dealstage: hubspotDeal.deal ? hubspotDeal.deal.dealstage : '',
        loa: hubspotDeal.deal ? hubspotDeal.deal.loa : '',
        contato_valido: hubspotDeal.deal ? hubspotDeal.deal.contato_valido : '',
        trf: hubspotDeal.deal
          ? hubspotDeal.deal.trf
            ? hubspotDeal.deal.tj
            : ''
          : '',
        ticket_id: ticketId,
      }),
      info: JSON.stringify({
        date: selectedTime,
        hunter_id: user.id,
        contact_preference: selectedContact || 'Telefone',
        observations: '',
        connection_method: selectedConnection,
        queue: hoursAvailable.queue,
      }),
    },
  });

  useEffect(() => {
    const newDate = new Date();

    const friday = newDate.getDay() === 5;

    if (friday) {
      setWeekdays(['Hoje', 'Segunda-feira']);
    } else {
      setWeekdays(['Hoje', 'Amanhã']);
    }

    setIsFriday(friday);
  }, []);

  const handleSetHubspotDeal = useCallback(
    async (hubspotTicketId: string) => {
      /*
      Seta todas as marcações vazias para induzir o usuario a marcar de novo caso mude o ticket
      */

      setSelectedConnection(undefined);

      setSelectedDay('');

      setHubspotDeal({} as IHubspotDeal);

      setSelectedContact(undefined);

      setConnections([]);

      setTicketId(hubspotTicketId);
      try {
        const response = await api.get(
          `schedule/get-hubspot-deal-info?hubspot_ticket_id=${hubspotTicketId}&connection=${selectedConnection}`,
        );

        setHubspotDeal(response.data);

        const isDealValidForScheduling = await verifyDealTj({
          tj: response.data.deal.tj,
          ticket_pipeline_id: response.data.ticket_pipeline_id,
          possibleBuy: response.data.deal.passivel_de_compra,
          closed_date: response.data.closed_date,
          product: response.data.deal.produto_precato,
          profile: user.profile,
        });

        if (isDealValidForScheduling) {
          handleSetConnectionsByPipelineId(
            setConnections,
            response.data.ticket_pipeline_id,
            response.data.line,
          );
        } else {
          setConnections([]);
        }
      } catch (error) {
        // Empty
      }
    },
    [selectedConnection, user.profile],
  );

  const formattedAmount = useMemo(() => {
    if (
      hubspotDeal.deal &&
      (hubspotDeal.deal.valor_liquido_prospect ||
        hubspotDeal.deal.valor_oficio_expedido)
    ) {
      const amount = Number(
        hubspotDeal.deal.valor_liquido_prospect ??
          String(0.67 * Number(hubspotDeal.deal.valor_oficio_expedido)),
      ).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });

      return amount;
    }

    return '';
  }, [hubspotDeal]);

  const createScheduleSuccess = useCallback(() => {
    toast.success('Agendamento criado com sucesso');
  }, []);

  const handleSelectConnection = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { selectedIndex } = event.target;
      const selectedOptionText = event.target.options[selectedIndex].text;

      setSelectedDay('');
      setHoursAvailable({} as IPeriods);
      setSelectedConnection(event.target.value);

      setSelectedConnectionText(selectedOptionText);
    },
    [],
  );

  const createScheduleError = useCallback((message: string) => {
    Swal.fire('Atenção!', `${message}`, 'warning');
  }, []);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  const handleSubmitDate = useCallback(
    (day: string) => {
      setHoursAvailable({} as IPeriods);

      setSelectedDay(day);

      const newDate =
        day === 'Hoje'
          ? new Date()
          : isFriday
          ? addDays(new Date(), 3)
          : addDays(new Date(), 1);

      const formattedDate = format(newDate, 'yyyy-MM-dd');
      if (hubspotDeal.deal.produto_precato) {
        api
          .get(
            `schedule/list-possible-hours?date=${formattedDate}&amount=${
              hubspotDeal.deal.valor_liquido_prospect ??
              0.67 * Number(hubspotDeal.deal.valor_oficio_expedido)
            }&hunter_id=${user.id}&loa=${
              hubspotDeal.deal.loa
            }&contact_preference=${selectedContact}&connection=${selectedConnection}&trf_tj=${
              hubspotDeal.deal.trf || hubspotDeal.deal.tj
            }&ticket_id=${ticketId}`,
          )
          .then(response => {
            setHoursAvailable(response.data);
          })
          .catch(error => {
            createScheduleError(error.response.data.message);
          });
      } else {
        createScheduleError(
          'Tipo do precatório (Federal, Estadual, Municipal) não encontrado!',
        );
      }
    },
    [
      isFriday,
      hubspotDeal,
      user.id,
      selectedContact,
      selectedConnection,
      createScheduleError,
      ticketId,
    ],
  );

  const getCloserById = useCallback(async (closer_id: string) => {
    try {
      const { data } = await api.get(`users/closer/${closer_id}`);

      setCloserNameScheduling(data.user.name);
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const loader = document.getElementById('loader');
      loader.style.display = 'flex';

      if (
        (hubspotDeal.deal && !hubspotDeal.deal?.hubspot_deal_id) ||
        !selectedConnection
      ) {
        createScheduleError('Preencha todos os campos!');
      } else {
        const { data } = await createScheduling();

        setSchedulingHubActivity(data.createScheduling.hub_activity_id);

        createScheduleSuccess();

        if (
          user.profile === 'Hunter New' ||
          user.profile === 'Hunter Líder' ||
          user.profile === 'Hunter Teste' ||
          user.profile === 'Gestores Hunter New'
        ) {
          await getCloserById(data.createScheduling.closer_id);
          setOpenSuccessfulModal(true);
        } else {
          history.push({
            pathname: '/negociations/schedule/hunter',
            state: {
              daySelected: selectedDay || 'Hoje',
            },
          });
        }
      }
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      createScheduleError(
        error.message
          .split('Unexpected error value: ')[1]
          .split('{ message: ')[1]
          .split(',')[0]
          .split('"')[1],
      );
    }
  }, [
    hubspotDeal.deal,
    selectedConnection,
    createScheduleError,
    createScheduling,
    createScheduleSuccess,
    user.profile,
    getCloserById,
    history,
    selectedDay,
  ]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1Navigation>Hunter {'>'} </H1Navigation>
        <H1NavigationNow>Novo agendamento</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />

        <Loading />

        <TitleBackButtonContainer>
          <BackButtonContainer>
            <Link to="/negociations/schedule/hunter">
              <Icon>
                <AiOutlineArrowLeft />
              </Icon>
              Voltar
            </Link>
          </BackButtonContainer>

          <TitleContainer>
            <Title>Novo agendamento</Title>
            <DivSeparator>
              <Separator />
            </DivSeparator>
          </TitleContainer>
        </TitleBackButtonContainer>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Main
            displayDatetime={
              hubspotDeal.deal &&
              !!hubspotDeal.deal.hubspot_deal_id &&
              !!selectedConnection
            }
            displayHoursAvailable={!!selectedDay}
          >
            <CredorInfos
              displayConnection
              displayCredorInfos={
                !!selectedConnection && user.loa === 'LOA 2022'
              }
            >
              <p className="title">Informações do negócio:</p>

              <InfoLineContainer className="credorInfos">
                <p className="infoTagP">ID do ticket:</p>

                <input
                  type="text"
                  name="ticket_id"
                  id="ticket_id"
                  placeholder="Digite o id correspondente"
                  onChange={e => handleSetHubspotDeal(e.target.value)}
                />
              </InfoLineContainer>

              <InfoLineContainer className="credorInfos">
                <p className="infoTagP">Valor:</p>

                <input
                  type="text"
                  name="amount"
                  id="amount"
                  value={formattedAmount || ''}
                  disabled
                />
              </InfoLineContainer>

              <InfoLineContainer className="credorInfos">
                <p className="infoTagP">Nome do credor:</p>
                <input
                  type="text"
                  name="credor_name"
                  id="credor_name"
                  value={(hubspotDeal.deal && hubspotDeal.deal.dealname) || ''}
                  disabled
                />
              </InfoLineContainer>

              <FieldContainer className="connection">
                <p className="infoTagP">Meio de Conexão</p>

                <Select
                  name="connection_method"
                  defaultValue=""
                  onChange={handleSelectConnection}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {connections.map(connection => (
                    <option key={connection.value} value={connection.value}>
                      {connection.key}
                    </option>
                  ))}
                </Select>
              </FieldContainer>
            </CredorInfos>

            <ScheduleInfoContainer className="schedule_info">
              <p className="title">Informações do agendamento:</p>

              <FieldContainer>
                <p>Meio de contato:</p>
                <ButtonsWeekdayContainer>
                  {contacts.map(contact => (
                    <ScheduleOptionsButton
                      key={contact}
                      selectedValue={selectedContact === contact}
                      createScheduleOption
                      type="button"
                      onClick={() => {
                        setSelectedContact(contact);
                      }}
                    >
                      {contact}
                    </ScheduleOptionsButton>
                  ))}
                </ButtonsWeekdayContainer>
              </FieldContainer>

              {selectedContact ? (
                <FieldContainer>
                  <p id="month">Data:</p>
                  <ButtonsWeekdayContainer>
                    {weekdays.map(day => (
                      <ScheduleOptionsButton
                        key={day}
                        selectedValue={selectedDay === day}
                        createScheduleOption
                        type="button"
                        onClick={() => {
                          handleSubmitDate(day);
                        }}
                      >
                        {day}
                      </ScheduleOptionsButton>
                    ))}
                  </ButtonsWeekdayContainer>
                </FieldContainer>
              ) : (
                ''
              )}
            </ScheduleInfoContainer>

            <HoursContainer className="datetime">
              <p className="title">Escolha um horário:</p>
              <PeriodContainer>
                {hoursAvailable.recommendedHour && (
                  <p className="recommendedHour">
                    Melhor horário:{' '}
                    {formattedTime(hoursAvailable.recommendedHour)}
                  </p>
                )}
              </PeriodContainer>
              <PeriodContainer>
                <p>Manhã</p>
                <Hour>
                  {hoursAvailable.morning
                    ? hoursAvailable.morning.map(morning => (
                        <ScheduleOptionsButton
                          key={String(morning)}
                          selectedValue={
                            formattedTime(selectedTime) ===
                            formattedTime(morning)
                          }
                          scheduleHour
                          type="button"
                          onClick={() => {
                            setSelectedTime(morning);
                          }}
                        >
                          {formattedTime(morning)}
                        </ScheduleOptionsButton>
                      ))
                    : ''}
                </Hour>
              </PeriodContainer>

              <PeriodContainer>
                <p>Tarde</p>
                <Hour>
                  {hoursAvailable.afternoon
                    ? hoursAvailable.afternoon.map(afternoon => (
                        <ScheduleOptionsButton
                          key={String(afternoon)}
                          selectedValue={
                            formattedTime(selectedTime) ===
                            formattedTime(afternoon)
                          }
                          scheduleHour
                          type="button"
                          onClick={() => {
                            setSelectedTime(afternoon);
                          }}
                        >
                          {formattedTime(afternoon)}
                        </ScheduleOptionsButton>
                      ))
                    : ''}
                </Hour>
              </PeriodContainer>

              <PeriodContainer>
                <p>Noite</p>
                <Hour>
                  {hoursAvailable.evening
                    ? hoursAvailable.evening.map(evening => (
                        <ScheduleOptionsButton
                          key={String(evening)}
                          selectedValue={
                            formattedTime(selectedTime) ===
                            formattedTime(evening)
                          }
                          scheduleHour
                          type="button"
                          onClick={() => {
                            setSelectedTime(evening);
                          }}
                        >
                          {formattedTime(evening)}
                        </ScheduleOptionsButton>
                      ))
                    : ''}
                </Hour>
              </PeriodContainer>
            </HoursContainer>
          </Main>

          <ButtonsContainerSchedule>
            <ButtonSchedule type="submit">
              <Icon>
                <AiOutlineCheck />
              </Icon>
              Agendar
            </ButtonSchedule>
          </ButtonsContainerSchedule>
        </Form>
      </Body>

      {openSuccessfulModal && (
        <SuccessfulModal
          isOpen={openSuccessfulModal}
          onRequestClose={handleCloseSuccessfulModal}
          name_sla="Agendamento"
          selectedDay={selectedDay}
          ticket_id={ticketId}
          contact_preference={selectedContact}
          selected_time={selectedTime}
          queue={undefined}
          scheduling_hub_activity={schedulingHubActivity}
          closer_name_scheduling={closerNameScheduling}
          connection={selectedConnection}
          previousConnectionMethod={selectedConnectionText}
          hunter={undefined}
          ticket_pipeline={hubspotDeal.ticket_pipeline_id}
        />
      )}
    </Container>
  );
};

export default CreateScheduleHunter;
