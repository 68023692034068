/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Modal from 'react-modal';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import priority_icon from '../../../../../../assets/icons/priority.svg';

import api from '../../../../../../services/api';

import {
  PriorityCloserModal,
  ModalBody,
  TitleContainer,
  QueuePriorityContainer,
  LabelInputContainer,
} from './styles';

Modal.setAppElement('#root');

interface ICloserInfosQueue {
  queue_id: string;
  queue_name: string;
  position: number;
  type: string;
  valor_min: number;
}

interface IInfoQueue {
  queue_id: string;
  position: number;
}

interface IRequest {
  infoQueue: IInfoQueue[];
}

interface IModalPriorityCloserProps {
  isOpen: boolean;
  onRequestClose: () => void;
  closer_id: string;
  closerInfosQueue: ICloserInfosQueue[];
}

export function ModalPriorityCloser({
  isOpen,
  onRequestClose,
  closer_id,
  closerInfosQueue,
}: IModalPriorityCloserProps) {
  const [updatedQueue, setUpdatedQueue] = useState<IRequest>({} as IRequest);

  const closeModal = useCallback(() => {
    setUpdatedQueue({
      infoQueue: [],
    });
    onRequestClose();
  }, [onRequestClose]);

  const updateQueueSuccess = useCallback(() => {
    toast.success('Closer priorizado com sucesso');
  }, []);

  const updateQueueError = useCallback((message: string) => {
    Swal.fire('Erro!', `${message}`, 'error');
  }, []);

  const handleInputChange = useCallback(
    (queue_id: string, position: number) => {
      const updatedQueues = closerInfosQueue;

      const index = updatedQueues.findIndex(
        queue => queue.queue_id === queue_id,
      );

      updatedQueues[index].position = position;

      setUpdatedQueue({
        ...updatedQueue,
        infoQueue: updatedQueues,
      });
    },
    [closerInfosQueue, updatedQueue],
  );

  const handleChangeLabel = useCallback((valor_min: number) => {
    if (valor_min === 0) return '< 120K';
    if (valor_min === 120) return '120K - 250K';

    return '> 250K';
  }, []);

  const handleUpdateQueue = useCallback(async () => {
    try {
      await api.patch('/schedule/priority-closer', {
        queue_infos: updatedQueue,
        user_id: closer_id,
      });

      setUpdatedQueue({
        infoQueue: [],
      });

      updateQueueSuccess();
      closeModal();
    } catch (error) {
      updateQueueError(error.response.data.message);
    }
  }, [
    updatedQueue,
    closer_id,
    updateQueueSuccess,
    closeModal,
    updateQueueError,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={PriorityCloserModal}
    >
      <ModalBody>
        <TitleContainer>
          <h1>Priorizar closer</h1>
        </TitleContainer>

        <QueuePriorityContainer>
          <span id="priority-queue">Filas de priorização:</span>

          {closerInfosQueue &&
            closerInfosQueue.map(queue => (
              <LabelInputContainer>
                <p>
                  {queue.queue_name} | {queue.type} |{' '}
                  {handleChangeLabel(queue.valor_min)}
                </p>
                <input
                  min="1"
                  defaultValue={queue.position}
                  name={queue.queue_id}
                  type="number"
                  onChange={event =>
                    handleInputChange(
                      queue.queue_id,
                      Number(event.target.value) <= 0 ||
                        !Number(event.target.value)
                        ? queue.position
                        : Number(event.target.value),
                    )
                  }
                />
              </LabelInputContainer>
            ))}
        </QueuePriorityContainer>

        <button id="priority-button" type="button" onClick={handleUpdateQueue}>
          <img src={priority_icon} alt="Priorizar" />
          <span>Priorizar</span>
        </button>
      </ModalBody>
    </Modal>
  );
}
