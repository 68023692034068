import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

interface ProvidenceProps {
  providenceStatus: string;
}

interface ICellProps {
  hasActivities?: boolean;
}

export const Table = styled.table`
  margin-top: 0.763rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Separator = styled.div`
  margin: 4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 7%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8125rem;
  margin-top: 1rem;
  cursor: pointer;

  a {
    display: flex;
    color: var(--blue-400);
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
  padding: 0.25rem 0;

  tr {
    height: 2.5rem;
    box-shadow: none;
  }

  th:nth-child(6) {
    border-right: none;
  }
`;

export const TableRow = styled.tr<ProvidenceProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;
  height: 3.5rem;
  width: 100%;
  box-shadow: inset 0.188rem 0 0 var(--blue-500);

  ${props =>
    props.providenceStatus === '0'
      ? css`
          box-shadow: inset 0.188rem 0 0 var(--blue-500);
        `
      : props.providenceStatus === 'Atuação imediata'
      ? css`
          box-shadow: -4px 0px 0px 0px #721eff;
        `
      : props.providenceStatus === '3'
      ? css`
          box-shadow: -4px 0px 0px 0px #ff0000;
        `
      : props.providenceStatus === '2'
      ? css`
          box-shadow: -4px 0px 0px 0px #ff650e;
        `
      : props.providenceStatus === '1'
      ? css`
          box-shadow: -4px 0px 0px 0px #fff500;
        `
      : css`
          box-shadow: inset 0.188rem 0 0 var(--blue-500);
        `}
`;

export const Cell = styled.td<ICellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 0.25rem;

  &:nth-child(1) {
    min-width: 28.6%;
    font-size: 13px;
  }

  &:nth-child(2) {
    min-width: 12.6%;
  }

  &:nth-child(3) {
    min-width: 12.6%;
  }

  &:nth-child(4) {
    min-width: 20.6%;
  }

  &:nth-child(5) {
    min-width: 12.6%;
  }

  &:nth-child(6) {
    min-width: 12.6%;
  }

  ${props =>
    !props.hasActivities
      ? css`
          background: rgba(222, 12, 12, 0.3);
        `
      : css``}
`;

export const ContainerTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.938rem;
  color: #05233e;
`;

export const OrderContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.175rem 0;
`;

export const Tbody = styled.tbody`
  a {
    color: inherit;
    text-decoration: none;
  }

  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.938rem;
  line-height: 1.406rem;
  color: #041524;

  tr:nth-child(odd) {
    background: #efefef;
  }

  tr:nth-child(even) {
    background: #e5e5e5;
  }

  tr {
    transition: filter 0.3s;
    :hover {
      filter: brightness(0.9);
    }
  }

  tr {
    margin-bottom: 0.5rem;
  }
`;

export const BoxCasos = styled.div`
  display: flex;
  justify-content: center;

  width: ${pxToRem(137)};

  margin-bottom: ${pxToRem(-22)};
  margin-right: 16.5%;

  background: var(--blue-100);

  font-size: ${pxToRem(15)};
  font-weight: 600;

  border-radius: 4px;
`;

export const BoxValor = styled.div`
  display: flex;
  justify-content: center;

  width: ${pxToRem(137)};

  margin-bottom: ${pxToRem(-15)};
  margin-right: -24.5%;

  background: var(--blue-100);

  font-size: ${pxToRem(15)};
  font-weight: 600;

  border-radius: 4px;
`;

export const BoxTable = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export const FilterContent = styled.div`
  width: 13.75rem;
  height: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--gray-200);
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(5, 35, 62, 0.25);
  border-radius: 0.25rem;

  margin-top: 1.125rem;

  span {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--blue-800);
    line-height: 1.25rem;
  }
`;

export const Select = styled.select`
  width: 9.75rem;
  height: 1.125rem;

  background: var(--white);
  border-radius: 0.25rem;
  cursor: pointer;
  border: none;

  font-weight: 400;
  font-size: 0.75;
  color: var(--blue-800);
`;

export const CategorySelect = styled.select`
  width: 9.75rem;
  height: 1.125rem;

  background: var(--white);
  cursor: pointer;
  border: none;

  font-weight: 400;
  font-size: 0.75;
  color: var(--blue-800);
`;

export const BottomBarThead = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  padding: 0.25rem 0;
  min-width: 610px;
  min-height: 74px;

  tr {
    height: 2.5rem;
    box-shadow: none;
  }

  th:nth-child(6) {
    border-right: none;
  }
`;

export const BottomCell = styled.td`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 0.25rem;
`;

export const BottomContainerTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #05233e;
`;

export const AddDescriptionButton = styled.div`
  button {
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #014077;
    border: 0;
    border-radius: 1rem;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;
