import styled, { css } from 'styled-components';

interface ScheduleProps {
  statusTask?: boolean;
  isBefore?: boolean;
  tryTimes?: string;
}

export const TableContainer = styled.table`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 32rem;

  overflow: auto;

  font-family: Poppins;
`;

export const HeadContainer = styled.thead`
  width: 100%;
  height: 2.375rem;

  border-bottom: 1px solid rgba(194, 194, 194, 0.5);
  background: rgba(22, 101, 216, 0.05);
  border-radius: 0.125rem;
`;

export const RowPrepareContainerProps = styled.div<ScheduleProps>`
  width: 100%;
  margin-bottom: 0.25rem;

  .body {
    height: 100%;
    transition: 0.2s;
    cursor: pointer;

    background: rgba(1, 64, 119, 0.1);
    box-shadow: -2px 0 0 #014077;

    :hover {
      background: rgba(1, 64, 119, 0.2);
    }
  }

  .time {
    font-size: 0.938rem;
    color: #05233e;

    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }
`;

export const RowContainerProps = styled.div<ScheduleProps>`
  width: 100%;
  margin-bottom: 0.25rem;

  .body {
    height: 100%;
    transition: 0.2s;
    cursor: pointer;

    background: rgba(1, 64, 119, 0.1);
    box-shadow: -2px 0 0 #014077;

    :hover {
      background: rgba(1, 64, 119, 0.2);
    }
  }

  .time {
    font-size: 0.938rem;
    color: #05233e;

    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  ${props =>
    props.statusTask
      ? css`
          .body {
            background: rgba(60, 166, 71, 0.25);
            box-shadow: -2px 0 0 #3ca647;

            :hover {
              background: rgba(60, 166, 71, 0.4);
            }
          }
        `
      : props.isBefore &&
        !props.statusTask &&
        (!props.tryTimes || props.tryTimes === '0' || props.tryTimes === 'null')
      ? css`
          .body {
            background: rgba(222, 12, 12, 0.25);
            box-shadow: -2px 0 0 #de0c0c;

            :hover {
              background: rgba(222, 12, 12, 0.4);
            }
          }
        `
      : props.tryTimes > '0' && props.tryTimes !== 'null' && !props.statusTask
      ? css`
          .body {
            background: rgba(180, 173, 9, 0.25);
            box-shadow: -2px 0 0 #b4ad09;

            :hover {
              background: rgba(180, 173, 9, 0.4);
            }
          }
        `
      : css`
          .body {
            background: rgba(1, 64, 119, 0.1);
            box-shadow: -2px 0 0 #014077;

            :hover {
              background: rgba(1, 64, 119, 0.2);
            }
          }
        `}
`;

export const RowContainer = styled.tr`
  display: grid;
  align-items: center;
  grid-template-columns: 10.3% 20.2% 18.5% 16.5% 16% 18.5%;

  width: 100%;
  height: 100%;

  th:nth-child(1) {
    height: 100%;
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(2) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(3) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(4) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }

  th:nth-child(5) {
    border-right: 1px solid rgba(194, 194, 194, 0.5);
  }
`;

export const ColumnHeadContainer = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 0.813rem;
  color: #041524;

  height: 1.875rem;
`;

export const BodyContainer = styled.tbody`
  width: 100%;
  height: 2.375rem;

  border-radius: 0.125rem;

  .row-props-body {
    height: 3.063rem;
  }

  .header {
    height: 2.375rem;
  }
`;

export const ColumnBodyContainer = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: #3d3d3d;
  height: 100%;
`;
