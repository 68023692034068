import styled from 'styled-components';

export const DivContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 13rem);
  justify-content: center;

  width: 100%;
  margin-top: 4rem;
  column-gap: 3.375rem;
`;

export const SituationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 1.5rem;
  color: var(--blue-700);
  background: var(--blue-100);
  height: 4.9375rem;
  width: 13rem;

  border: none;
  border-radius: 0.25rem;
  margin-bottom: 1.8125rem;

  cursor: pointer;

  transition: background-color 0.2s ease;

  &:hover {
    background: var(--blue-400);
    color: var(--gray-100);
  }
`;
