/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useState } from 'react';

import { IoMdExit } from 'react-icons/io';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { gql, useSubscription } from '@apollo/client';
import newNotificationIcon from '../../assets/icons/notification_white.svg';
import notificationIcon from '../../assets/icons/notification_v3.svg';
import pNotificationIcon from '../../assets/images/P-notificação-quadrado.png';
import emailIcon from '../../assets/icons/email_icon.svg';
import LogoPrecato from '../../assets/images/logo-branca.png';
import {
  Container,
  Divisor,
  Logo,
  ContentContainer,
  Notifications,
  Profile,
  Exit,
} from './styles';
import { useAuth } from '../../hooks/auth';

import { NotificationModal } from '../NotificationModal';
import api from '../../services/api';
import { FeedbackFormsModal } from '../FeedbackFormsModal';

interface IScheduleNotification {
  id: string;
  hunter_name?: string;
  time_ago: string;
  read: boolean;
  user_id: string;
  activity_name?: string;
  type?: string;
  activity_id?: string;
}

interface INotification {
  id: string;
  user_id: string;
  activity_name?: string;
  type?: string;
  activity_id?: string;
  dealname?: string;
  timestamp?: Date;
  notes_next_activity_date?: string;
  time_init?: Date;
}

interface IUserInfo {
  name: string;
  avatar_url: string;
}

const Header: React.FC = () => {
  const { user } = useAuth();
  const { signOut, verifyTokenExpiration } = useAuth();
  const [notificationInfo, setNotificationInfo] = useState({} as INotification);
  const [notify, setNotify] = useState(false);
  const [openForms, setOpenForms] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [userNotifications, setUserNotifications] = useState<
    IScheduleNotification[]
  >([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo>(user);

  const UPDATE_USER = gql`
    subscription Subscription($args: String!) {
      updateUser(args: $args) {
        id
        profile_id
        name
        email
        avatar
        team_id
        avatar_url
        hubspot_user_id
        hubspot_owner_id
      }
    }
  `;
  const WARNING = gql`
    subscription Subscription {
      warningFormsSub
    }
  `;

  const { data: updateUserData } = useSubscription(UPDATE_USER, {
    variables: {
      args: user.id,
    },
  });

  const warninigFormsData = useSubscription(WARNING, {
    variables: {
      args: null,
    },
  });

  const NOTIFICATIONS_CREATED = gql`
    subscription ShowNotification($args: String!) {
      showNotification(args: $args) {
        id
        closer_id
        user_id
        hunter_name
        activity_name
        type
        activity_id
        read
        changed
        deleted
        created_at
        updated_at
      }
    }
  `;
  const { data } = useSubscription(NOTIFICATIONS_CREATED, {
    variables: {
      args: user.id,
    },
  });

  const NOTIFICATIONS_NEXT_ACTIVITY = gql`
    subscription ShowNextActivityNotification($args: String!) {
      showNextActivityNotification(args: $args) {
        time_init
        dealname
        notes_next_activity_date
        timestamp
        id
        closer_id
        user_id
        hunter_name
        activity_name
        type
        activity_id
        read
        deleted
        created_at
        updated_at
      }
    }
  `;

  const { data: nextActivityData, loading: nextActivityLoading } =
    useSubscription(NOTIFICATIONS_NEXT_ACTIVITY, {
      variables: {
        args: user.id,
      },
    });

  const NOTIFICATIONS_PROPOSAL_FORM = gql`
    subscription Subscription($args: String!) {
      proposalFormNotification(args: $args)
    }
  `;

  const { data: proposalFormData, loading: proposalFormLoading } =
    useSubscription(NOTIFICATIONS_PROPOSAL_FORM, {
      variables: {
        args: user.id,
      },
    });

  const formattedTime = useCallback((date: Date) => {
    const hour = date.getHours();
    const minutes = `${String(new Date(date).getMinutes()).padStart(2, '0')}`;

    return `${hour}:${minutes}`;
  }, []);

  useEffect(() => {
    if (updateUserData) {
      setUserInfo(JSON.parse(localStorage.getItem('@Precato:user')));
    }
  }, [updateUserData]);

  useEffect(() => {
    if (
      warninigFormsData.data &&
      warninigFormsData.data.warningFormsSub === true
    ) {
      setOpenForms(true);
    }
  }, [warninigFormsData]);

  useEffect(() => {
    if (nextActivityData) {
      setOpenNotification(true);
      setNotificationInfo(nextActivityData.showNextActivityNotification);
    } else {
      setOpenNotification(false);
    }
  }, [nextActivityData, nextActivityLoading]);

  verifyTokenExpiration();

  const handleOpenNotificationModal = useCallback(() => {
    api
      .post<IScheduleNotification[]>('/schedule/notifications', {
        user_id: user.id,
      })
      .then(receivedNotifications => {
        setUserNotifications(receivedNotifications.data);
        setIsNotificationModalOpen(true);
        setNotify(false);
      });
  }, [user]);

  const handleCloseSolicitationModal = useCallback(() => {
    setIsNotificationModalOpen(false);
  }, []);

  const handleCloseFormsModal = useCallback(() => {
    setOpenForms(false);
  }, []);

  const handleUserNotifications = useCallback(() => {
    async function readingNotifications() {
      const readNotifications: IScheduleNotification[] = [];

      userNotifications.forEach(notification => {
        if (!notification.read)
          readNotifications.push({ ...notification, read: true });
      });

      if (readNotifications.length > 0) {
        await api.patch('schedule/notifications/update', {
          notifications: readNotifications,
        });
      }
    }

    readingNotifications();
  }, [userNotifications]);

  const handleToast = useCallback(() => {
    toast.success('Um novo agendamento foi marcado para você.');
  }, []);

  const showNotification = useCallback(() => {
    setOpenNotification(false);
    if (Notification.permission === 'granted') {
      new Notification('5 minutos para sua próxima atividade.', {
        body: notificationInfo.notes_next_activity_date
          ? `${notificationInfo.activity_name} às ${formattedTime(
              new Date(notificationInfo.notes_next_activity_date),
            )}`
          : notificationInfo.timestamp
          ? `${notificationInfo.activity_name} às ${formattedTime(
              new Date(notificationInfo.timestamp),
            )}`
          : `${notificationInfo.activity_name} às ${formattedTime(
              new Date(notificationInfo.time_init),
            )}`,
        icon: pNotificationIcon,
        silent: true,
      });
    }
  }, [formattedTime, notificationInfo]);

  const showProposalFormNotification = useCallback(() => {
    setOpenNotification(false);
    if (Notification.permission === 'granted') {
      new Notification('', {
        body: 'Nova solicitação de proposta.',
        icon: pNotificationIcon,
        silent: true,
      });
    }
  }, []);

  useEffect(() => {
    if (proposalFormData) {
      showProposalFormNotification();
    }
  }, [proposalFormData, proposalFormLoading, showProposalFormNotification]);

  useEffect(() => {
    async function handleNotifications() {
      const notifications = await api.post<IScheduleNotification[]>(
        '/schedule/notifications',
        {
          user_id: user.id,
        },
      );
      const newNotifications = notifications.data.filter(
        notification => !notification.read,
      );

      setNotify(newNotifications.length > 0);
    }

    if (user.profile === 'Closers' || user.profile === 'Closer Linha 1') {
      handleNotifications();
    }
  }, [user]);

  useEffect(() => {
    if (
      data &&
      data.showNotification.user_id === user.id &&
      data.showNotification.type === 'Scheduling'
    ) {
      handleToast();
      setNotify(true);
    }
  }, [user, data, handleToast]);

  useEffect(() => {
    if (openNotification === true) {
      showNotification();
      setNotify(true);
    }
  }, [openNotification, showNotification]);

  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Logo>
        <Link to="/dashboard">
          <img src={LogoPrecato} alt="Logo Precato" />
        </Link>
      </Logo>

      <ContentContainer>
        <Notifications>
          <img className="mail" src={emailIcon} alt="Email" />

          {notify ? (
            <img
              className="notification"
              src={newNotificationIcon}
              alt="Nova notificação"
              onClick={async () => handleOpenNotificationModal()}
            />
          ) : (
            <img
              className="notification"
              src={notificationIcon}
              alt="Notificações"
              onClick={handleOpenNotificationModal}
            />
          )}

          <NotificationModal
            isOpen={isNotificationModalOpen}
            onRequestClose={handleCloseSolicitationModal}
            userNotifications={userNotifications}
            handleUserNotifications={handleUserNotifications}
            setUserNotifications={notifications =>
              setUserNotifications(notifications)
            }
          />
        </Notifications>

        <Divisor />

        <Link to="/profile">
          <Profile>
            <span>{userInfo.name}</span>
            <img src={userInfo.avatar_url} alt="User Avatar" />
          </Profile>
        </Link>
        <Exit>
          <Link to="/" onClick={signOut}>
            <IoMdExit />
            <span>SAIR</span>
          </Link>
        </Exit>
      </ContentContainer>
      <FeedbackFormsModal
        isOpen={openForms}
        onRequestClose={handleCloseFormsModal}
      />
    </Container>
  );
};

export default Header;
