import styled from 'styled-components';

interface IContainer {
  isActive: boolean;
}

export const Container = styled.div<IContainer>`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: 7.875rem 29.875rem 26rem;
  align-items: center;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.625rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  margin-top: 0.563rem;
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-800);

  border-right: 0.09rem solid var(--blue-600);

  &:nth-child(3) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  margin-top: 0.25rem;
  border: 0.125rem solid var(--blue-100);
  border-radius: 0.25rem;

  font-family: Poppins;
  font-weight: 300;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-800);

  .body-tr {
    height: 1.438rem;
    border-top: 0.063rem solid var(--blue-100);

    &.first-child {
      border: none;
    }
  }
`;

export const BodyCell = styled.td`
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
`;

export const BodyCellResult = styled.td`
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-left: 4px;
    }
  }
`;
