import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react';

import { Form } from '@unform/web';
import { AiOutlineArrowLeft, AiOutlineCheck } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { Container } from '../../../components/Container';
import {
  Line,
  Icon,
  FormContainer,
  BackButtonContainer,
  FormGroup,
  Label,
  TitleHeader,
  TitleContainer,
  Input,
  InputContainer,
  ButtonsContainerEditTeam,
  ButtonEditTeam,
  H1,
  DivSeparator,
  Separator,
} from './styles';
import api from '../../../services/api';
import { Title } from '../../../components/Styles/Title';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';

interface Permission {
  id: string;
  name: string;
  description: string;
}

const EditPermission: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [findPermissions, setFindPermissions] = useState<Permission>();

  const location = useLocation();
  const permission = location.state as Permission;

  useEffect(() => {
    api.get(`/users/permissions/${permission.id}`).then(response => {
      setFindPermissions(response.data);
    });
  }, [permission]);

  const [formData, setFormData] = useState({
    namePermission: '',
    descriptionPermission: '',
    permission_delete: '',
    permission_name: '',
    permission_id: '',
  });

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function permissionError() {
    Swal.fire(
      'Erro!',
      'Verifique os dados preenchidos e tente novamente.',
      'error',
    );
  }

  function editPermissionSuccess() {
    toast.success('Permissão editada com sucesso');
  }

  async function editPermission() {
    try {
      const { data } = await api.put(`/users/permissions/${permission.id}`, {
        name: formData.permission_name,
        description: formData.descriptionPermission,
      });

      const updatedPermission = permissions.filter(
        permissionId => permissionId.id !== permission.id,
      );

      editPermissionSuccess();
      setPermissions([...updatedPermission, data]);
    } catch (err) {
      permissionError();
    }
  }

  document
    .getElementById('permission_name')
    ?.setAttribute('value', `${findPermissions?.name}`);

  document
    .getElementById('descriptionPermission')
    ?.setAttribute('value', `${findPermissions?.description}`);

  return (
    <Container>
      <Navegation>
        <H1Navigation>
          Administração {'>'} Permissões {'>'}{' '}
        </H1Navigation>
        <H1NavigationNow>Editar permissão</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Editar permissão</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <BackButtonContainer>
          <Link to="/administration/permissions">
            <Icon>
              <AiOutlineArrowLeft />
            </Icon>
            Voltar
          </Link>
        </BackButtonContainer>

        <TitleContainer>
          <H1>* Preencha somente os dados que deseja alterar</H1>
        </TitleContainer>

        <TitleContainer>
          <TitleHeader>Dados de acesso</TitleHeader>
        </TitleContainer>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={() => {
              // eslint-disable-next-line no-console
              console.log('Teste');
            }}
          >
            <Line />
            <FormGroup>
              <InputContainer>
                <Label>Informe o nome da permissão</Label>
                <Input
                  id="permission_name"
                  name="permission_name"
                  type="text"
                  onChange={handleInputChange}
                />
              </InputContainer>
              <InputContainer>
                <Label>Contexto</Label>
                <select
                  className="select"
                  name="descriptionPermission"
                  onChange={handleSelectChange}
                >
                  <option>Selecione uma opção</option>
                  <option
                    value="Nenhum"
                    selected={findPermissions?.description === 'Nenhum' && true}
                  >
                    Nenhum
                  </option>
                  <option
                    value="Processamento"
                    selected={
                      findPermissions?.description === 'Processamento' && true
                    }
                  >
                    Processamento
                  </option>
                  <option
                    value="Negociação"
                    selected={
                      findPermissions?.description === 'Negociação' && true
                    }
                  >
                    Negociação
                  </option>
                  <option
                    value="Administração"
                    selected={
                      findPermissions?.description === 'Administração' && true
                    }
                  >
                    Administração
                  </option>
                </select>
              </InputContainer>
            </FormGroup>
          </Form>
        </FormContainer>
        <ButtonsContainerEditTeam>
          <Link to="/administration/permissions">
            <ButtonEditTeam type="button" onClick={editPermission}>
              <Icon>
                <AiOutlineCheck />
              </Icon>
              Salvar
            </ButtonEditTeam>
          </Link>
        </ButtonsContainerEditTeam>
      </Body>
    </Container>
  );
};

export default EditPermission;
