import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 16rem;
  @media print {
    display: none;
  }
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 5%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;

    color: #1665d8;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FilterManagerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 5.625rem;
  margin-bottom: 2.25rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.875rem;
`;

export const PreviousButton = styled.button`
  margin-right: 0.563rem;
  border: 0.031rem solid #05233e;
  background: transparent;
  border-radius: 0.25rem;

  height: 1.875rem;
  width: 1.563rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div input {
    border: none;
    width: 10rem;
    height: 1.875rem;
    color: #05233e;
  }

  &:nth-child(1) {
    margin-right: 5.05rem;
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0.031rem solid #063966;
  border-radius: 0.25rem;

  width: 12.063rem;

  input {
    padding-left: 1.196rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const NextButton = styled.button`
  margin-left: 0.563rem;
  border: 0.031rem solid #05233e;
  background: transparent;
  border-radius: 0.25rem;

  height: 1.875rem;
  width: 1.563rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 42.375rem;
  height: 1.875rem;

  background: #f4f4f5;
  border-radius: 0.125rem;
  border: 1px solid #05233e;

  padding: 0 0.813rem;
`;

export const Text = styled.p`
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #05233e;
`;

export const FilterSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const Label = styled.label`
  padding-right: 0.375rem;

  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #595959;
`;

export const Select = styled.select`
  width: 9.375rem;
  height: 1.25rem;

  padding-left: 0.125rem;

  border: none;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;

  cursor: pointer;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
`;

export const SearchButton = styled.button`
  display: flex;
  flex-direction: row;

  background: transparent;
  border: none;
`;

export const VerticalDivisor = styled.div`
  height: 1.625rem;
  border: 0.063rem solid #817f7f;
`;

export const ActivitiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
