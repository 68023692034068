import styled from 'styled-components';

export const InputsContainer = styled.div`
  margin-left: 1.688rem;

  .checkbox-1 {
    height: 2.5rem;
  }
  .checkbox-2 {
    height: 3.15rem;
  }
  .checkbox-3 {
    height: 4.64rem;
  }
`;

export const Checkbox = styled.div`
  width: 10.813rem;

  background: var(--gray-200);
  margin-bottom: 0.75rem;
  padding: 0.313rem 0;

  border-radius: 0.25rem;
  box-shadow: 4px 4px 4px 0 #05233e40;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputDiv = styled.div`
  width: 10.813rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const InputDiv2 = styled.div`
  width: 10.813rem;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const InputSpan = styled.span`
  width: 8.438rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--blue-800);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;

interface LabelCheckboxProps {
  isActive: boolean;
}

export const LabelCheckbox = styled.label<LabelCheckboxProps>`
  height: 1.125rem;
  width: 1.125rem;
  background: ${props => (props.isActive ? 'var(--gray-50)' : 'var(--white)')};
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    color: black;
  }
`;

export const InputCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const SelectSpan = styled.span`
  width: 10.813rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--blue-800);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;

export const SelectBox = styled.select`
  width: 9.688rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 0.188rem;
`;

export const OptionBox = styled.option`
  color: var(--blue-800);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 18px;
`;
