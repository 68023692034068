import { useQuery } from 'react-query';

import { isAfter, format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { formatAmount } from '../../../../utils/formatAmount';
import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';
import { IActivityCSDTO } from '../dtos/IActivityCSDTO';

import { IDayHoursDTO } from '../dtos/IDayHoursDTO';
import { ModalUpdateActivity } from '../ModalUpdateActivity';

import {
  Container,
  DateContainer,
  ResumeFields,
  FieldColumn,
  RegistriesContainer,
  RegitryActivity,
  InfoButton,
} from './styles';

interface IHubspotDeal {
  dealname?: string;
  pipeline?: string;
  name?: string;
  id?: string;
  operacao?: string;
  etapa?: string;
  amount: string;
  pass_date?: string;
  providence_status?: string;
  category?: string;
  hubspot_deal_id: string;
  observation?: string;
  numero_da_operacao?: string;
  deal_stage_name?: string;
  ticket_stage_name?: string;
}

interface ResumePanelProps {
  selectedCalendarPeriod: string;
  quantity_activities: number;
  quantity_finished_activities: number;
  activities: IDayHoursDTO[];
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (activity: IActivitiesInADayDTO) => void;
  hubspotDeals: IHubspotDeal[];
  user_id: string;
}

export function ResumePanel({
  selectedCalendarPeriod,
  quantity_activities,
  quantity_finished_activities,
  activities,
  activitiesInADay,
  setActivitiesInADay,
  hubspotDeals,
  user_id,
}: ResumePanelProps): JSX.Element {
  const [openActivityModal, setOpenActivityModal] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState<IActivityCSDTO>(
    {} as IActivityCSDTO,
  );
  const [pendentActivities, setPendentActivities] = useState<IActivityCSDTO[]>(
    [] as IActivityCSDTO[],
  );
  const [nextRegistries, setNextRegistries] = useState<IActivityCSDTO[]>(
    [] as IActivityCSDTO[],
  );

  const updatePanelResume = async () => {
    const response = await api.get(
      `/schedule-cs?start_date=${format(
        new Date(),
        'yyyy-MM-dd',
      )}&end_date&user_id=${user_id}`,
    );

    setActivitiesInADay(response.data);

    return response;
  };

  useQuery('infos', updatePanelResume, {
    refetchInterval: 900000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (activities) {
      const updatedPendentActivities: IActivityCSDTO[] = [];

      const verifyNextRegistries: IActivityCSDTO[] = [];

      activities.forEach(day_hour => {
        day_hour.activities.forEach(activity => {
          if (activity.situation !== 'Concluída') {
            updatedPendentActivities.push(activity);
          }

          const timestamp = activity.time_init
            ? activity.time_init
            : activity.timestamp
            ? activity.timestamp
            : undefined;

          if (
            timestamp &&
            activity.type === 'Cartório' &&
            isAfter(new Date(timestamp), new Date())
          ) {
            verifyNextRegistries.push(activity);
          }
        });
      });

      setPendentActivities(updatedPendentActivities);

      if (verifyNextRegistries && verifyNextRegistries.length > 0) {
        setNextRegistries(verifyNextRegistries.slice(0, 2));
      }
    }
  }, [activities]);

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback((activity: IActivityCSDTO) => {
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  }, []);

  const getOpenedCases = useMemo(() => {
    return hubspotDeals.reduce((total: number, hubspotDeal: IHubspotDeal) => {
      return total + Number(hubspotDeal.amount);
    }, 0);
  }, [hubspotDeals]);

  return (
    <Container>
      <DateContainer>
        <p>{new Date().getDate()}</p>
        <p>{new Date().toLocaleString('default', { month: 'short' })}</p>
        <p>{new Date().getFullYear()}</p>
      </DateContainer>

      <ResumeFields>
        <FieldColumn>
          <span className="title">
            Atividades{' '}
            {selectedCalendarPeriod === 'Dia' ? 'de hoje' : 'da semana'}:
          </span>
          <span className="value">{quantity_activities}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">Atividades pendentes:</span>
          <span className="value">{0 || pendentActivities.length}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">Atividades concluídas:</span>
          <span className="value">{0 || quantity_finished_activities}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">Casos:</span>
          <span className="value">{hubspotDeals.length}</span>

          <span className="title">Valor em aberto:</span>
          <span className="value">{formatAmount(getOpenedCases)}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">Próximos cartórios:</span>

          <RegistriesContainer>
            {nextRegistries.length > 0 ? (
              nextRegistries.map(next_registry => (
                <RegitryActivity key={next_registry.id}>
                  <div>
                    <span className="title-registry">Nome:</span>
                    <span className="value-registry">
                      {next_registry.dealname}
                    </span>
                  </div>
                  <div>
                    <span className="title-registry">Op:</span>
                    <span className="value-registry">
                      {next_registry.operation_number}
                    </span>
                  </div>
                  <div>
                    <span className="title-registry">Valor</span>
                    <span className="value-registry">
                      {formatAmount(Number(next_registry.amount))}
                    </span>
                  </div>
                  <div>
                    <span className="title-registry">Horário</span>
                    <span className="value-registry">
                      {format(new Date(next_registry.time_init), 'HH:mm')}
                    </span>
                  </div>
                  <InfoButton
                    onClick={() => handleOpenActivityModal(next_registry)}
                  >
                    + INFORMAÇÕES
                  </InfoButton>
                </RegitryActivity>
              ))
            ) : (
              <RegitryActivity>
                <span className="title-registry">
                  Não há cartórios agendados.
                </span>
              </RegitryActivity>
            )}
          </RegistriesContainer>
        </FieldColumn>
      </ResumeFields>

      {Object.keys(selectedActivity).length > 0 && (
        <ModalUpdateActivity
          isOpen={openActivityModal}
          isProvidence={false}
          onRequestClose={handleCloseActivityModal}
          activity={selectedActivity}
          activitiesInADay={activitiesInADay}
          setActivitiesInADay={setActivitiesInADay}
          updateListAcitivity={updatePanelResume}
        />
      )}
    </Container>
  );
}
