import styled from 'styled-components';

export const DaySelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 1.875rem;
`;

export const PreviousButton = styled.button`
  margin-right: 0.563rem;
  border: 1px solid #05233e;

  width: 1.5rem;
  height: 1.875rem;

  background: rgba(244, 244, 245, 0.83);
  border-radius: 0.25rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(244, 244, 245, 1);

  div input {
    background: rgba(244, 244, 245, 1);
    border: none;

    width: 10rem;
    height: 1.875rem;

    color: #05233e;
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.031rem solid #063966;
  border-radius: 0.25rem;

  width: 12.4rem;

  input {
    padding-left: 1.196rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const NextButton = styled.button`
  margin-left: 0.563rem;
  border: 1px solid #05233e;

  width: 1.5rem;
  height: 1.875rem;

  background: rgba(244, 244, 245, 0.83);
  border-radius: 0.25rem;
`;
