interface formatLOA {
  term_01: string;
  term_02: string;
  term_03: string;
}

export function formatStringNormal({
  term_01,
  term_02,
  term_03,
}: formatLOA): string {
  const term01 = term_01 || ' ';
  const term02 = term_02 || ' ';
  const term03 = term_03 || ' ';

  return `${term01}-${term02}-${term03}-`;
}
