import styled from 'styled-components';

export const ContainerPerformance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;
`;

export const ContainerTableAndInputs = styled.div`
  width: 100%;
  display: flex;
`;
