import { format, isBefore } from 'date-fns';
import { useCallback, useState } from 'react';

import { formatAmount } from '../../../../../utils/formatAmount';
import { IActivityCSDTO } from '../../dtos/IActivityCSDTO';
import { ModalUpdateActivity } from '../../ModalUpdateActivity';

import {
  Container,
  TableContainer,
  HeadContainer,
  RowBody,
  RowContainer,
  ColumnHeadContainer,
  BodyContainer,
  ColumnBodyContainer,
  Timestamp,
} from './styles';

interface ManagerVisualizationListProps {
  activities: IActivityCSDTO[];
}

export function ManagerVisualizationList({
  activities,
}: ManagerVisualizationListProps): JSX.Element {
  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<IActivityCSDTO>(
    {} as IActivityCSDTO,
  );

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback((activity: IActivityCSDTO) => {
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  }, []);

  return (
    <Container>
      <TableContainer>
        <HeadContainer>
          <RowContainer className="header">
            <ColumnHeadContainer>DATA/HORA</ColumnHeadContainer>
            <ColumnHeadContainer>ATIVIDADE</ColumnHeadContainer>
            <ColumnHeadContainer>CREDOR</ColumnHeadContainer>
            <ColumnHeadContainer>VALOR</ColumnHeadContainer>
            <ColumnHeadContainer>ETAPA</ColumnHeadContainer>
            <ColumnHeadContainer>STATUS</ColumnHeadContainer>
          </RowContainer>
        </HeadContainer>

        <BodyContainer>
          {activities &&
            activities.map(activity => (
              <RowBody
                key={activity.id}
                className="row-props-body"
                isBefore={
                  activity.type !== 'Reunião'
                    ? activity.time_init
                      ? verifyIsBeforeNow(activity.time_init)
                      : verifyIsBeforeNow(activity.timestamp)
                    : false
                }
                statusTask={
                  activity.type !== 'Reunião' &&
                  activity.situation === 'Concluída'
                }
                checklist_concluded={
                  activity.checklist_concluded &&
                  Number(activity.checklist_concluded) > 0
                }
              >
                <ColumnBodyContainer
                  className="time"
                  onClick={() => handleOpenActivityModal(activity)}
                >
                  {activity.time_init ? (
                    <Timestamp>
                      <span>
                        {format(new Date(activity.time_init), 'dd/MM')}
                      </span>
                      <span>
                        {format(new Date(activity.time_init), 'HH:mm')}
                      </span>
                    </Timestamp>
                  ) : (
                    <Timestamp>
                      <span>
                        {format(new Date(activity.timestamp), 'dd/MM')}
                      </span>
                      <span>
                        {format(new Date(activity.timestamp), 'HH:mm')}
                      </span>
                    </Timestamp>
                  )}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => handleOpenActivityModal(activity)}
                >
                  {activity.title || activity.name}
                </ColumnBodyContainer>

                <ColumnBodyContainer
                  onClick={() => handleOpenActivityModal(activity)}
                >
                  {activity.dealname}
                </ColumnBodyContainer>

                {activity.type !== 'Reunião' ? (
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(activity)}
                  >
                    {formatAmount(Number(activity.amount))}
                  </ColumnBodyContainer>
                ) : (
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(activity)}
                  />
                )}

                <ColumnBodyContainer
                  onClick={() => handleOpenActivityModal(activity)}
                >
                  {activity.stage_deal}
                </ColumnBodyContainer>

                {activity.type !== 'Reunião' ? (
                  <ColumnBodyContainer>
                    {activity.situation}
                  </ColumnBodyContainer>
                ) : (
                  <ColumnBodyContainer
                    onClick={() => handleOpenActivityModal(activity)}
                  />
                )}
              </RowBody>
            ))}
        </BodyContainer>
      </TableContainer>

      {Object.keys(selectedActivity).length > 0 && (
        <ModalUpdateActivity
          isOpen={openActivityModal}
          isProvidence={false}
          onRequestClose={handleCloseActivityModal}
          activity={selectedActivity}
          activitiesInADay={undefined}
          setActivitiesInADay={undefined}
          updateListAcitivity={undefined}
        />
      )}
    </Container>
  );
}
