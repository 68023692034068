import styled, { css } from 'styled-components';

interface ICloseModal {
  isClose: boolean;
}

export const ModalSchedule = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  content: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    height: '100%',
    margin: 'auto',
    padding: '0',
    background: 'transparent',
    border: 'none',
  },
};

export const Container = styled.div<ICloseModal>`
  display: flex;
  flex-direction: row;

  margin-top: 8.15rem;
  margin-right: 3.3rem;
  width: 39.001rem;
  height: 43rem;

  animation: modal 0.5s;

  @keyframes modal {
    0% {
      transform: translateX(36.938rem);
    }
    100% {
      transform: translateX(0rem);
    }
  }

  ${props =>
    props.isClose &&
    css`
      animation: modal-close 0.5s reverse;

      @keyframes modal-close {
        0% {
          transform: translateX(36.938rem);
        }
        100% {
          transform: translateX(0rem);
        }
      }
    `}

  .contact-link {
    text-decoration: underline;
    color: #1665d8;
  }

  @media (max-width: 1400px) {
    margin-right: 2.8rem;
  }
`;

export const ContainerClose = styled.div`
  background: #ffffff;
  height: 2.063rem;
  width: 2.063rem;
  border-bottom-left-radius: 0.313rem;
  border-top-left-radius: 0.313rem;
`;

export const Image = styled.img``;

export const Button = styled.button`
  background: transparent;
  border: none;

  padding: 0.375rem;
`;

export const ContainerContent = styled.div`
  background: #ffffff;
  width: 100%;

  border-radius: 0.625rem;
  border-top-left-radius: 0;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;

  color: #05233e;

  margin-top: 0.625rem;
  margin-right: 0.625rem;
`;

export const ContainerTitle = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.625rem;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 0.063rem;
`;

export const Divisor = styled.hr`
  width: 12.25rem;

  border: 1px solid #9d9d9d;

  margin-bottom: 1.5rem;
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  padding: 0.625rem;

  width: 33.188rem;
  height: 2.438rem;
  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;

  margin-bottom: 0.5rem;

  color: #3d3d3d;
`;

export const Contrast = styled.label`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  color: #ee1717;
`;

export const Input = styled.input`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;

  padding: 0.5rem;

  width: 33.188rem;
  height: 1.875rem;
  background: #efefef;
  border: 0.5px solid #063966;
  box-sizing: border-box;
  border-radius: 0.25rem;

  margin-bottom: 1.25rem;
`;

export const ContainerCheckbox = styled.div`
  margin-bottom: 1.875rem;

  width: 33.188rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input + label {
    position: relative;
    cursor: pointer;
    padding-left: 1.875rem;
  }

  input[type='radio'] + label::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0.1rem;
    width: 0.938rem;
    height: 0.938rem;
    border-radius: 50%;
    border: 1px solid #1665d8;
  }

  input[type='radio']:checked + label::after {
    position: absolute;
    content: '';
    background-color: #1665d8;
    width: 0.55rem;
    height: 0.55rem;
    border-radius: 50%;
    left: 0.25rem;
    bottom: 0.35rem;
  }

  input[type='checkbox'] + label::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0.1rem;
    width: 0.938rem;
    height: 0.938rem;
    border-radius: 50%;
    border: 1px solid #1665d8;
  }

  input[type='checkbox']:checked + label::after {
    position: absolute;
    content: '';
    background-color: #1665d8;
    width: 0.55rem;
    height: 0.55rem;
    border-radius: 50%;
    left: 0.25rem;
    bottom: 0.35rem;
  }
`;

export const ContainerInputCheckbox = styled.div``;

export const InputCheckbox = styled.input`
  margin-right: 0.625rem;
`;

export const LabelCheckbox = styled.label`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  color: #3d3d3d;
`;

export const DeleteButton = styled.button`
  width: 9.688rem;
  height: 2.313rem;

  background: #063966;
  border-radius: 0.5rem;
  border: none;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.813rem;

  color: #ffffff;

  margin-top: 0.625rem;

  &:hover {
    background: #ee1717;
  }
`;
