import axios from 'axios';

const url =
  process.env.REACT_APP_API_URL === 'dev'
    ? 'http://localhost:3003/'
    : process.env.REACT_APP_API_URL === 'hom'
    ? 'https://api-customer-success-hom.precato.com.br/'
    : 'https://api-customer-success.precato.com.br/';

const apiCustomerSuccess = axios.create({
  baseURL: url,
});

export default apiCustomerSuccess;
