import { shade } from 'polished';
import styled from 'styled-components';

export const ContainerCall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  width: 25rem;
  height: 15rem;

  box-shadow: 0.125rem 0.125rem 2.5rem 0.125rem rgba(0, 0, 0, 0.25);

  padding: 1rem;

  border-radius: 0.5rem;

  margin-top: 4rem;
`;

export const Select = styled.select`
  width: 10.5rem;
  height: 1.563rem;

  border: none;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;

  margin-bottom: 2rem;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;

  :disabled {
    display: none;
  }
`;

export const TextInfo = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  color: #05233e;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const Button = styled.button`
  border: none;

  width: 15rem;
  height: 4rem;

  background: #1665d8;
  border-radius: 1rem;

  transition: background 0.2s;

  &:hover {
    background: ${shade(0.2, '#1665d8')};
  }
`;

export const Icon = styled.img`
  width: 1.1rem;
  height: 1.1rem;

  margin-right: 1rem;
`;

export const TextButton = styled.span`
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const ContainerTextInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
`;
