import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import closeIcon from '../../../assets/icons/cancel.svg';
import apiChatbot from '../../../services/apiChatbot';
import {
  ModalSchedule,
  ContainerLoader,
  Container,
  ContainerClose,
  Button,
  Image,
  ContainerText,
  Text,
  ContentCreditor,
  Link,
} from './styles';

Modal.setAppElement('#root');

interface IModalProps {
  is_open: boolean;
  cost_center: string;
  on_request_close: () => void;
}

interface INextOptIn {
  name: string;
  message: string;
  link_contact: string;
  link_deal: string;
  link_ticket: string;
  mobile_phone: string;
}

export const OptInModal: React.FC<IModalProps> = ({
  is_open,
  cost_center,
  on_request_close,
}) => {
  const [loading, setLoading] = useState(true);
  const [nextOptIn, setNextOptIn] = useState<INextOptIn>({} as INextOptIn);

  const closeModal = useCallback(() => {
    on_request_close();
  }, [on_request_close]);

  useEffect(() => {
    if (is_open) {
      const chatbot = apiChatbot(localStorage.getItem('@Precato:token'));

      chatbot.get(`/sms/next-sms-call/${cost_center}`).then(response => {
        setNextOptIn(response.data);

        window.open(`tel:${response.data.mobile_phone}`, '_blank');

        setLoading(false);
      });
    }
  }, [cost_center, is_open]);

  return (
    <Modal
      isOpen={is_open}
      onRequestClose={closeModal}
      style={ModalSchedule}
      closeTimeoutMS={500}
    >
      {loading ? (
        <ContainerLoader>
          <Loader type="Oval" color="#05233e" height={50} width={50} />
        </ContainerLoader>
      ) : (
        <>
          <ContainerClose>
            Informações do credor
            <Button onClick={closeModal}>
              <Image src={closeIcon} alt="Fechar modal" />
            </Button>
          </ContainerClose>
          <Container>
            <ContainerText>
              <Text>Nome:</Text>
              <ContentCreditor>{nextOptIn.name}</ContentCreditor>
            </ContainerText>
            <ContainerText>
              <Text>Mensagem:</Text>
              <ContentCreditor>{nextOptIn.message}</ContentCreditor>
            </ContainerText>
            <ContainerText>
              <Text>Link do contato:</Text>
              <Link href={nextOptIn.link_contact} target="_blank">
                {nextOptIn.link_contact}
              </Link>
            </ContainerText>
            <ContainerText>
              <Text>Link do negócio:</Text>
              <Link href={nextOptIn.link_deal} target="_blank">
                {nextOptIn.link_deal}
              </Link>
            </ContainerText>
            <ContainerText>
              <Text>Link do ticket:</Text>
              <Link href={nextOptIn.link_ticket} target="_blank">
                {nextOptIn.link_ticket}
              </Link>
            </ContainerText>
          </Container>
        </>
      )}
    </Modal>
  );
};
