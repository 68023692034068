import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { ISLAComplet } from '../interface';

export const schemaSLABase = Yup.object().shape({
  ticket_id: Yup.number().nullable().required('Id do Ticket obrigatório!'),
  who_contacted: Yup.string()
    .nullable()
    .required('Com quem foi o contato obrigatório!'),
  decision_maker: Yup.string()
    .nullable()
    .required('Tomador de decisão/influência obrigatório!'),
  valid_contact: Yup.string()
    .nullable()
    .required('Qual é o contato válido inválido ou não preenchido!')
    .matches(
      /^55[1-9]\d9?[1-9]\d{7}$/g,
      'Contato válido deve estar no padrão: 5531999998888',
    ),
  connection_type: Yup.string()
    .nullable()
    .required('Tipo de conexão obrigatório!'),
  opt_in: Yup.string().nullable().required('Opt in obrigatório!'),
  connection_method: Yup.string()
    .nullable()
    .required('Meio de conexão obrigatório!'),
  reason_for_scheduling: Yup.string()
    .nullable()
    .required('Motivo do caso estar na etapa obrigatório!'),
  objection: Yup.string()
    .nullable()
    .required('Objeções levantadas obrigatório!'),
  send_message_to_client: Yup.string()
    .nullable()
    .required('Foi enviado mensagem ao cliente obrigatório!'),
  was_aware_precatory: Yup.string()
    .nullable()
    .required('Tinha conhecimento do precatório!'),
  we_schedule_a_new_contact: Yup.string()
    .nullable()
    .required('Agendamos um novo contato obrigatório!'),
  was_aware_PEC: Yup.string()
    .nullable()
    .required('Tinha conhecimento sobre a previsão de pagamento obrigatório!'),
  Interested_in_knowing_the_values: Yup.string()
    .nullable()
    .required('Teve interesse em saber os valores obrigatório!'),
  late_payment_delays_the_customer: Yup.string()
    .nullable()
    .required('O atraso do pagamento atrasa a vida do cliente obrigatório!'),
  observation: Yup.string().nullable().required('Observação obrigatório!'),
});

export const schemaValidateDate = Yup.object().shape({
  day: Yup.string().nullable().required('Data obrigatório!'),
  hours: Yup.string().nullable().required('Horário obrigatório!'),
});

export const schemaSLAN5 = Yup.object().shape({
  ticket_id: Yup.number().nullable().required('Id do Ticket obrigatório!'),
  who_contacted: Yup.string()
    .nullable()
    .required('Com quem foi o contato obrigatório!'),
  decision_maker: Yup.string()
    .nullable()
    .required('Tomador de decisão/influência obrigatório!'),
  valid_contact: Yup.string()
    .nullable()
    .required('Qual é o contato válido inválido ou não preenchido!')
    .matches(
      /^55[1-9]\d9?[1-9]\d{7}$/g,
      'Contato válido deve estar no padrão: 5531999998888',
    ),
  connection_type: Yup.string()
    .nullable()
    .required('Tipo de conexão obrigatório!'),
  connection_method: Yup.string()
    .nullable()
    .required('Meio de conexão obrigatório!'),
  confirmation_message_sent: Yup.string()
    .nullable()
    .required('Foi enviada mensagem de confirmação obrigatório!'),
  was_aware_precatory: Yup.string()
    .nullable()
    .required('Tinha conhecimento do precatório!'),
  was_aware_PEC: Yup.string()
    .nullable()
    .required('Tinha conhecimento sobre a previsão de pagamento obrigatório!'),
  Interested_in_knowing_the_values: Yup.string()
    .nullable()
    .required('Teve interesse em saber os valores obrigatório!'),
  late_payment_delays_the_customer: Yup.string()
    .nullable()
    .required('O atraso do pagamento atrasa a vida do cliente obrigatório!'),
  observation: Yup.string().nullable().required('Observação obrigatório!'),
});

export async function validateFields(
  sla: ISLAComplet,
  type: string,
): Promise<boolean> {
  try {
    if (type !== 'N5') {
      await schemaSLABase.validate(sla, {
        abortEarly: false,
      });
    }

    if (type === 'N5') {
      await schemaSLAN5.validate(sla, {
        abortEarly: false,
      });
    }

    if (
      sla.scheduled_for_closer === 'Sim' ||
      sla.we_schedule_a_new_contact === 'Sim'
    ) {
      await schemaValidateDate.validate(sla, {
        abortEarly: false,
      });
    }
    return true;
  } catch (error) {
    Swal.fire('Atenção!', `${error.errors[0]}`, 'warning');
    return false;
  }
}
