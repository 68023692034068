import styled from 'styled-components';

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  /* margin-bottom: 0.238rem; */
  @media print {
    display: none;
  }
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const InputSearch = styled.input`
  width: 20rem;

  background: #d8d8d8;
  border-radius: 1.875rem;

  height: 3.25rem;
  padding: 0 1.5rem;
  border: 0.125rem solid #fff;
  font-size: 1.115rem;
  &::placeholder {
    color: #a8a8b3;
  }
  @media (max-width: 1290px) {
    width: 18rem;
  }
  @media print {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 2rem; */
  margin-bottom: 0.5rem;

  width: 100%;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100vw;
    gap: 1rem;
  }
`;

export const CreateButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #019158;
    border: 0;
    border-radius: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const H1 = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1.625rem 0 1rem 0;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.015em;

  color: #3d3d3d;

  @media (max-width: 1100px) {
    width: 96vw;
  }
`;

export const Table = styled.table`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FooterTable = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Thead = styled.div`
  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
  width: 100%;
  display: flex;

  tr {
    display: flex;

    width: 80vw;

    th:first-child {
      width: 42%;
    }
    th:not(:first-child) {
      width: 14.5%;
    }
  }

  th {
    word-break: break-all;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.015em;
    width: 100%;

    color: #05233e;

    @media (max-width: 1100px) {
      width: 10rem;
    }
  }
`;

export const Tbody = styled.div`
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  tr {
    background: #e5e5e5;
    width: 100%;
    display: flex;

    width: 80vw;

    td:first-child {
      width: 42%;
    }
    td:not(:first-child) {
      width: 14.5%;
    }
    & + tr {
      margin-top: 5px;
    }
  }

  td {
    word-break: break-all;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.015em;
    padding: 0.5rem;
    width: 14.3rem;
    //width: fit-content;

    color: #05233e;
  }
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Celula = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
