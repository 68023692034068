import styled, { css } from 'styled-components';

interface ButtonProps {
  selectedValue: boolean;
  scheduleHour?: boolean;
  weekdaySchedule?: boolean;
  createScheduleOption?: boolean;
  createActivity?: boolean;
  selectCloser?: boolean;
  statusDisponibility?: boolean;
  fillHubspot?: boolean;
  transfer?: boolean;
  managerSchedulingContact?: boolean;
}

export const Container = styled.button<ButtonProps>`
  border: none;
  border-radius: 0.25rem;

  width: 5.125rem;
  height: 1.875rem;

  background: #efefef;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.75rem;
  color: #3d3d3d;

  border: 0.031rem solid #063966;

  cursor: pointer;
  transition: 0.5s;

  text-align: center;

  .scheduleHour {
    border: none;
  }

  ${props =>
    props.selectedValue
      ? css`
          background-color: var(--blue-400);
          color: var(--white);
          border: none;
        `
      : css`
          :hover {
            background-color: #dfdfdf;
          }
        `}

  ${props =>
    props.scheduleHour
      ? css`
          border: none;
          width: 4rem;
          height: 1.875rem;
          font-weight: 500;
          font-size: 1rem;
        `
      : css``}

${props =>
    props.weekdaySchedule
      ? css`
          width: 8rem;
          font-size: 0.875rem;
        `
      : css``}

  ${props =>
    props.createScheduleOption
      ? css`
          width: 8.625rem;
          height: 1.875rem;
          border: none;
          font-weight: 500;
          font-size: 0.875rem;
        `
      : css``}

  ${props =>
    props.createActivity
      ? css`
          width: 8.5rem;
          height: 1.875rem;
          border: none;
          font-weight: 500;
        `
      : css``}

${props =>
    props.selectCloser
      ? css`
          width: 100%;
          height: 1.875rem;
          border: none;
          font-weight: 600;
          font-size: 1rem;

          text-align: left;
          padding-left: 0.625rem;
          margin-bottom: 0.375rem;
        `
      : css``}

${props =>
    props.statusDisponibility
      ? css`
          width: 6.125rem;
          height: 1.875rem;

          font-weight: 600;
          font-size: 0.875rem;
        `
      : css``}


${props =>
    props.fillHubspot
      ? css`
          width: 12.5rem;
          height: 1.875rem;
          border: none;
          font-weight: 500;
          font-size: 0.875rem;
          text-align: center;
          margin-bottom: 0.375rem;
        `
      : css``}

${props =>
    props.transfer &&
    css`
      width: 6.5rem;
      height: 2.5rem;

      border: none;

      font-size: 0.875rem;
      font-weight: 500;

      :hover {
        color: var(--white);
        background-color: var(--blue-400);
      }
    `}

    ${props =>
    props.managerSchedulingContact
      ? css`
          width: 5.625rem;
          height: 1.875rem;

          font-weight: 500;
          font-size: 0.875rem;
        `
      : css``}
`;
