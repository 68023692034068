import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ButtonsContainerProps {
  canSendData: boolean;
}

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 18rem;
  margin-bottom: 2.875rem;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin: 2rem 0;
`;

export const TitleBackButtonContainer = styled.div`
  display: flex;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: translate(-2.5%, 0);
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 5%;
  margin-top: 5rem;

  a {
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;

    color: var(--blue-400);
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const ContainerLoader = styled.div`
  width: 100vw;
  height: 100vh;

  display: none;
  position: fixed;

  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.25);
`;

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: space-between;

  margin-top: 1.875rem;
  width: 26rem;

  ${props =>
    props.canSendData
      ? css`
          #save-button {
            cursor: pointer;
          }
        `
      : css`
          #save-button {
            cursor: not-allowed;
            opacity: 0.7;
          }
        `}

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 7.5rem;
    height: 2.25rem;

    border: none;
    border-radius: 0.5rem;

    font-weight: 600;
    font-size: 0.813rem;
    color: var(--white);
  }
`;

export const ButtonCancel = styled.button`
  background: var(--gray-900);

  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.3, '#777777')};
  }
`;

export const ButtonSave = styled.button`
  background: var(--green-600);

  &:hover {
    background: ${shade(0.3, '#089b63')};
  }
`;

export const ButtonSend = styled.button`
  background: var(--blue-600);

  &:hover {
    background: ${shade(0.3, '#063966')};
  }
`;
