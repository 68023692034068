import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../utils/pxToRem';

export const Content = styled.div`
  height: 40rem;

  display: flex;
  flex-direction: column;

  overflow: auto;

  padding-left: 0.05rem;
`;

interface ScheduleLineContainerProps {
  moreThanOne: boolean;
}

export const ScheduleLineContainer = styled.div<ScheduleLineContainerProps>`
  display: flex;
  align-items: center;

  border-top: 1px solid rgba(194, 194, 194, 0.5);

  p {
    font-weight: 600;
    font-size: 0.938rem;
    color: var(--blue-700);
  }

  ${props =>
    props.moreThanOne
      ? css`
          height: ${pxToRem(188)};
        `
      : css`
          height: 6.25rem;
        `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.5%;
`;

interface LineTableContentProps {
  moreThanOne: boolean;
}

export const LineTableContent = styled.div<LineTableContentProps>`
  display: flex;
  align-items: center;

  overflow: auto;

  padding: 0 2.5rem;

  width: 92.4%;
  height: 6.25rem;
  gap: 0.5rem;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  ${props =>
    props.moreThanOne
      ? css`
          height: ${pxToRem(188)};
          justify-content: space-between;
        `
      : css`
          height: 6.25rem;
          flex-direction: column;
          justify-content: center;
        `}
`;

export const ScheduleLineReminderContainer = styled.div<ScheduleLineContainerProps>`
  display: flex;
  align-items: center;

  border-top: 1px solid rgba(194, 194, 194, 0.5);

  p {
    font-weight: 600;
    font-size: 0.938rem;
    color: var(--blue-700);
  }

  ${props =>
    props.moreThanOne
      ? css`
          height: 6.25rem;
        `
      : css`
          height: 6.25rem;
        `}
`;

export const LineTableReminderContent = styled.div<LineTableContentProps>`
  display: flex;
  align-items: center;

  overflow: auto;

  padding: 0 2.5rem;

  width: 92.4%;
  height: 6.25rem;
  gap: 0.5rem;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  ${props =>
    props.moreThanOne
      ? css`
          height: 6.25rem;
          flex-direction: column;
          justify-content: center;
        `
      : css`
          height: 6.25rem;
          flex-direction: column;
          justify-content: center;
        `}
`;

interface InfosTasksContainerProps {
  statusTask?: string;
  isBefore?: boolean;
  checklist_concluded?: boolean;
  user_profile?: string;
  moreThanOne?: boolean;
}

export const InfosTasksContainer = styled.div<InfosTasksContainerProps>`
  height: 5rem;

  display: flex;
  justify-content: space-between;

  margin: 0;

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--green-400),
            0 0.25rem 0.25rem var(--overlay);
        `
      : props.isBefore &&
        props.statusTask === 'Pendente' &&
        !props.checklist_concluded
      ? css`
          background: rgba(236, 68, 68, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--red-700),
            0 0.25rem 0.25rem var(--overlay);
        `
      : props.statusTask === 'Pendente' && props.checklist_concluded
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--yellow-500),
            0 0.25rem 0.25rem var(--overlay);
        `
      : css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: inset 0.188rem 0 0 var(--blue-500),
            0 0.25rem 0.25rem var(--overlay);
        `}

  ${props =>
    props.moreThanOne
      ? css`
          height: ${pxToRem(158)};
          width: ${pxToRem(290)};
          flex-direction: column;
        `
      : css`
          height: 5rem;
          width: 100%;
        `}
`;

export const InfosReminderContainer = styled.div<InfosTasksContainerProps>`
  height: 5rem;

  display: flex;
  justify-content: space-between;

  margin: 0;

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 var(--green-400),
            0 0.25rem 0.25rem var(--overlay);
        `
      : css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: inset 0.188rem 0 0 var(--blue-500),
            0 0.25rem 0.25rem var(--overlay);
        `}

  ${props =>
    props.moreThanOne
      ? css`
          height: 5rem;
          width: 100%;
        `
      : css`
          height: 5rem;
          width: 100%;
        `}
`;

interface HourTypeCloserContainerProps {
  statusTask?: string;
  isBefore?: boolean;
  checklist_concluded?: boolean;
}

export const HourTypeCloserContainer = styled.div<HourTypeCloserContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.25rem 0 0.25rem 0.625rem;

  cursor: pointer;

  p {
    font-family: 500;
    line-height: 1.125rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          p {
            color: var(--green-400);
          }
        `
      : props.isBefore &&
        props.statusTask === 'Pendente' &&
        !props.checklist_concluded
      ? css`
          p {
            color: var(--red-700);
          }
        `
      : props.statusTask === 'Pendente' && props.checklist_concluded
      ? css`
          p {
            color: var(--yellow-500);
          }
        `
      : css`
          p {
            color: var(--blue-500);
          }
        `}

  p:nth-child(1) {
    display: flex;
    flex-direction: row;
  }

  p.credor {
    font-size: 0.65rem;
    color: var(--gray-800);
  }

  img {
    margin-left: 0.23rem;
  }
`;

export const HourTypeCSReminderContainer = styled.div<HourTypeCloserContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.25rem 0 0.25rem 0.625rem;

  cursor: pointer;

  p {
    font-family: 500;
    line-height: 1.125rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  p:nth-child(1) {
    display: flex;
    flex-direction: row;
  }

  p.credor {
    font-size: 0.65rem;
    color: var(--gray-800);
  }

  img {
    margin-left: 0.23rem;
  }

  justify-content: center;
`;

export const HourNameActivity = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IntervalTime = styled.p`
  display: flex;
  gap: 0.25rem;
`;

export const StatusTaskStageContainer = styled.div<InfosTasksContainerProps>`
  margin-right: 0.938rem;
  margin-left: 0.625rem;

  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 0.3rem 0;

  p {
    font-weight: 600;
    font-size: 0.75rem;

    width: 5.125rem;
    height: 1.625rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
  }

  p#stage {
    background: rgba(60, 166, 71, 0.08);
    border: 0.125rem solid var(--gray-800);
    width: fit-content;
    padding: 0 0.25rem 0 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
  }

  #status {
    border: none;

    ${props =>
      props.user_profile === 'Customer Success' ||
      props.user_profile === 'Customer Success Calculator'
        ? css`
            cursor: pointer;
          `
        : css`
            cursor: not-allowed;
          `};

    ${props =>
      props.statusTask === 'Concluída'
        ? css`
            background: var(--green-400);
          `
        : props.statusTask === 'Pendente'
        ? css`
            background: var(--red-500);
          `
        : css`
            background: var(--yellow-500);
          `}
  }

  ${props =>
    props.moreThanOne &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

export const StatusReminderStageContainer = styled.div<InfosTasksContainerProps>`
  margin-right: 0.938rem;
  margin-left: 0.625rem;

  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 0.3rem 0;

  p {
    font-weight: 600;
    font-size: 0.75rem;

    width: 5.125rem;
    height: 1.625rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
  }

  p#stage {
    background: rgba(60, 166, 71, 0.08);
    border: 0.125rem solid var(--gray-800);
    width: fit-content;
    padding: 0 0.25rem 0 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
  }

  #status {
    border: none;

    ${props =>
      props.user_profile === 'Customer Success' ||
      props.user_profile === 'Customer Success Calculator'
        ? css`
            cursor: pointer;
          `
        : css`
            cursor: not-allowed;
          `};

    ${props =>
      props.statusTask === 'Concluída'
        ? css`
            background: var(--green-400);
          `
        : props.statusTask === 'Pendente'
        ? css`
            background: var(--red-500);
          `
        : css`
            background: var(--yellow-500);
          `}
  }
`;

export const StatusConcludeTask = styled.div`
  position: relative;
  display: inline-block;
`;

export const StatusButton = styled.button`
  font-weight: 600;
  font-size: 0.75rem;

  width: 5.125rem;
  height: 1.625rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
`;

export const ButtonConcludeTask = styled.button`
  display: block;
  z-index: 1;

  border: 1px solid var(--blue-700);
  border-radius: 0.25rem;
  background: var(--blue-50);

  width: ${pxToRem(82)};
  height: ${pxToRem(26)};

  margin-top: 0.5rem;

  font-weight: 600;
  font-size: ${pxToRem(12)};
  color: var(--blue-600);

  cursor: pointer;

  transition: filter 0.2s;

  :hover {
    filter: brightness(0.9);
  }
`;
