import React from 'react';
import { format as formattedDate } from 'date-fns';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Container } from './styles';
import 'react-day-picker/lib/style.css';

interface IDayPicker {
  handleChange: (day: Date) => void;
  value: Date;
  isEdit?: boolean;
}

export const DayPicker: React.FC<IDayPicker> = ({
  handleChange,
  value,
  isEdit,
}) => {
  return (
    <Container isEdit={isEdit}>
      <DayPickerInput
        onDayChange={handleChange}
        value={value ? formattedDate(new Date(value), 'dd/MM/yyyy') : null}
        format="dd/MM/yyyy"
        dayPickerProps={{
          weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
          months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
        }}
      />
    </Container>
  );
};
