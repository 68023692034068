import { Dispatch, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import {
  FileContainer,
  FileInputContainer,
  SendButtonContainer,
  ButtonSendFile,
  TextFileButton,
  DivSelect,
} from './styles';

export interface ISearch {
  input: string;
  hinput: string;
}

export interface IEntities {
  label: string;
  value: ISearch;
}

interface IExampleFileTRF2TRF4 {
  handleUpload: () => Promise<void>;
  optionsSearch: IEntities[];
  setSelecte: (data: Array<IEntities>) => void;
}

export const ExampleFileTjsOc: React.FC<IExampleFileTRF2TRF4> = ({
  handleUpload,
  optionsSearch,
  setSelecte,
}: IExampleFileTRF2TRF4) => {
  const [selected, setSelected] = useState<IEntities[]>([]);

  useEffect(() => {
    setSelecte(selected);
  }, [setSelecte, selected]);
  return (
    <FileContainer>
      <FileInputContainer>
        <DivSelect>
          <MultiSelect
            className="teste"
            options={optionsSearch}
            value={selected}
            onChange={setSelected}
            labelledBy="Selecionar Entes"
          />
        </DivSelect>
        <SendButtonContainer>
          <ButtonSendFile type="button" onClick={() => handleUpload()}>
            Enviar
          </ButtonSendFile>
          <TextFileButton>
            Arquivos serão processados automaticamente
          </TextFileButton>
        </SendButtonContainer>
      </FileInputContainer>
    </FileContainer>
  );
};
