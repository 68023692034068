import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { format, addDays, isBefore, add } from 'date-fns';
import { gql, useSubscription } from '@apollo/client';

import faq from '../../../assets/icons/FAQ.svg';
import to_define from '../../../assets/icons/to_define.svg';
import box_time from '../../../assets/icons/box_time.svg';
import { ReactComponent as CalendarSelectedIcon } from '../../../assets/icons/calendar_selected.svg';
import { ReactComponent as ListSelectedIcon } from '../../../assets/icons/list_selected.svg';
import { ReactComponent as CalendarNotSelectedIcon } from '../../../assets/icons/calendar_not_selected.svg';
import { ReactComponent as ListNotSelectedIcon } from '../../../assets/icons/list_not_selected.svg';

import { Title } from '../../../components/Styles/Title';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';

import { InfosWorkImpactPanel } from '../components/InfosWorkImpactPanel';
import { ScheduleCloserContainer } from '../components/ScheduleCloserContainer';
import { ListingActivitiesCloserContainer } from '../components/ListingActivitiesCloserContainer';
import { ModalCreateActivity } from './ModalCreateActivity';
import { ModalRestrictedTime } from '../components/ModalRestrictedTime';
import { ModalSchedulePriority } from '../components/ModalSchedulePriority';
import { Container } from '../../../components/Container';

import {
  Separator,
  DivSeparator,
  ButtonCreate,
  Main,
  IconCreate,
  ButtonsDateContainer,
  Buttons,
  SchedulesAndActivitiesContainer,
  SchedulesContainer,
  ScheduleTasksContainer,
  ScheduleLineContainer,
  Icon,
  LineTableContent,
  DateHeaderContainer,
  ButtonsContainer,
  ActivitiesWithNotHourDefinedContainer,
  TitleNotHourDefinedContainer,
  SearchByNameContainer,
  NotDefinedHourContainer,
  Activities,
  ActivityContainer,
  FAQButton,
  ContainerInputDate,
  ContainerInput,
  Select,
  FilterContent,
} from './styles';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import { IActivityDTO } from '../dtos/IActivityDTO';
import { ICloserRestrictedTimeDTO } from '../dtos/ICloserRestrictedTimeDTO';
import { IActivitiesCloserInADayDTO } from '../dtos/IActivitiesCloserInADayDTO';
import { INextActivityDTO } from '../dtos/INextActivityDTO';
import { IModalInfoDTO } from './dtos/IModalInfoDTO';
import { ScheduleOptionsButton } from '../../../components/ScheduleOptionsButton';
import { ModalSLAInformation } from '../components/ModalSLAInformation';
import {
  ICadence,
  ListingCadencesCloserContainer,
} from '../components/ListingCadencesCloserContainer';

import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow_right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow_left.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar_blue.svg';
import { DayPicker } from '../../../components/DayPicker';
import {
  DaySelectedContainer,
  ForwardButton,
  PreviousButton,
} from '../Manager/History/styles';
import { CloserWalletCases } from '../components/CloserWalletCases';

const ScheduleCloser: React.FC = () => {
  const [scheduleDate, setScheduleDate] = useState(new Date());

  const [formattedDate, setFormattedDate] = useState(
    format(scheduleDate, 'yyyy-MM-dd'),
  );

  const weekdays = [
    'DOMINGO',
    'SEGUNDA',
    'TERÇA',
    'QUARTA',
    'QUINTA',
    'SEXTA',
    'SÁBADO',
  ];

  const [selectedVisualization, setSelectedVisualization] =
    useState<string>('calendar');

  const { user } = useAuth();

  const [openCreateActivityModal, setOpenCreateActivityModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);

  const [openCreateSLAModal, setOpenCreateSLAModal] = useState(false);

  const [openRestrictedTimeModal, setOpenRestrictedTimeModal] = useState(false);
  const [modalActivityType, setModalActivityType] = useState('');
  const [activityData, setActivityData] = useState<IActivityDTO>(
    {} as IActivityDTO,
  );

  const [closerActivityInADay, setCloserActivityInADay] =
    useState<IActivitiesCloserInADayDTO>({} as IActivitiesCloserInADayDTO);
  const [days, setDays] = useState<string[]>([]);
  const [selectedDay, setSelectedDay] = useState<string>('Hoje');
  const [restrictedTimeData, setRestrictedTimeData] = useState<
    ICloserRestrictedTimeDTO | INextActivityDTO
  >({} as ICloserRestrictedTimeDTO);
  const [nextActivity, setNextActivity] = useState<INextActivityDTO>(
    {} as INextActivityDTO,
  );
  const [spokenTime, setSpokenTime] = useState('00:00:00');

  const NOTIFICATIONS_CREATED = gql`
    subscription ShowNotification($args: String!) {
      showNotification(args: $args) {
        id
        closer_id
        user_id
        hunter_name
        activity_name
        type
        activity_id
        read
        changed
        deleted
        created_at
        updated_at
      }
    }
  `;

  const TRANSFER_CREATED = gql`
    subscription ShowTransfer($args: String!) {
      showTransfer(args: $args) {
        amount
        closer_id
        connection_method
        connection_type
        contact
        contact_preference
        contacted_who
        created_at
        current_lead_owner
        dealname
        hub_activity_id
        hubspot_deal_id
        hunter_id
        hunter_name
        hunter_owner_id
        id
        loa
        observations
        queue_id
        stage_name
        status
        ticket_id
        timestamp
        updated_at
        user_id
        valid_contact
        valid_email
      }
    }
  `;

  const refreshActivities = useSubscription(NOTIFICATIONS_CREATED, {
    variables: {
      args: user.id,
    },
  });
  const refreshActivitiesTransfer = useSubscription(TRANSFER_CREATED, {
    variables: {
      args: user.id,
    },
  });

  const REFRESH = gql`
    subscription Subscription($args: String!) {
      refreshSchedule(args: $args)
    }
  `;

  const [cadences, setCadences] = useState([] as ICadence[]);

  const getCadences = useCallback(() => {
    api.get(`/schedule/list-cadences-cases/${user.id}`).then(response => {
      setCadences(response.data);
    });
  }, [user]);

  const refreshActivitiesTake = useSubscription(REFRESH, {
    variables: {
      args: user.id,
    },
  });

  const CALCULATIONCOMPLETED = gql`
    subscription Subscription($userId: String!) {
      verifyCalculationCompleted(userId: $userId)
    }
  `;

  const refreshActivitiesCalculateReady = useSubscription(
    CALCULATIONCOMPLETED,
    {
      variables: {
        userId: user.id,
      },
    },
  );

  useEffect(() => {
    api
      .get(
        `/schedule/list-closer-activity?closer_id=${user.id}&date=${formattedDate}`,
      )
      .then(response => {
        setCloserActivityInADay(response.data);
      });

    api.get(`/schedule/time-spoken?user_id=${user.id}`).then(response => {
      setSpokenTime(response.data);
    });

    api.get(`/schedule/list-next-activity/${user.id}`).then(response => {
      setNextActivity(response.data);
    });

    api.get(`/schedule/list-cadences-cases/${user.id}`).then(response => {
      setCadences(response.data);
    });
  }, [formattedDate, user]);

  useEffect(() => {
    if (
      (refreshActivities.data &&
        refreshActivities.data.showNotification.user_id === user.id) ||
      (refreshActivitiesTransfer.data &&
        refreshActivitiesTransfer.data.showTransfer.user_id === user.id) ||
      (refreshActivitiesTake.data &&
        refreshActivitiesTake.data.refreshSchedule === user.id) ||
      (refreshActivitiesCalculateReady.data &&
        refreshActivitiesCalculateReady.data.verifyCalculationCompleted ===
          user.id)
    ) {
      api
        .get(
          `/schedule/list-closer-activity?closer_id=${user.id}&date=${formattedDate}`,
        )
        .then(response => {
          setCloserActivityInADay(response.data);
        });
    }
  }, [
    user,
    formattedDate,
    refreshActivities,
    refreshActivitiesTake,
    refreshActivitiesTransfer,
    refreshActivitiesCalculateReady,
  ]);

  useEffect(() => {
    const newDate = new Date();

    const friday = newDate.getDay() === 5;

    if (friday) {
      setDays(['Hoje', 'Segunda-feira']);
    } else {
      setDays(['Hoje', 'Amanhã']);
    }
  }, []);

  useEffect(() => {
    const schedulesInDay =
      selectedDay === 'Hoje'
        ? setFormattedDate(format(new Date(), 'yyyy-MM-dd'))
        : selectedDay === 'Amanhã'
        ? setFormattedDate(format(addDays(new Date(), 1), 'yyyy-MM-dd'))
        : setFormattedDate(format(addDays(new Date(), 3), 'yyyy-MM-dd'));

    return schedulesInDay;
  }, [selectedDay]);

  const handleOpenCreateActivityModal = useCallback(() => {
    setOpenCreateActivityModal(true);
  }, []);

  const handleCloseCreateActivityModal = useCallback(() => {
    setOpenCreateActivityModal(false);
  }, []);

  const handleOpenSLAModal = useCallback(() => {
    setOpenCreateSLAModal(true);
  }, []);

  const handleCloseCreateSLAModal = useCallback(() => {
    setOpenCreateSLAModal(false);
  }, []);

  const handleOpenActivityModal = useCallback(
    (activity: IActivityDTO | INextActivityDTO) => {
      setOpenActivityModal(true);

      setActivityData(activity);
      if (
        activity.notes_next_activity_date &&
        activity.notes_next_activity_date !== 'A Definir'
      ) {
        setModalActivityType('Priority');
      } else if (activity.notes_next_activity_date === 'A Definir') {
        setModalActivityType('ToDefine');
      } else if (Object.keys(activity).includes('deleted')) {
        setModalActivityType('Scheduling');
      } else {
        setModalActivityType('Transfer');
      }
    },
    [],
  );

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenRestrictedTimeModal = useCallback(
    (activity: ICloserRestrictedTimeDTO | INextActivityDTO) => {
      setOpenRestrictedTimeModal(true);
      setRestrictedTimeData(activity);
    },
    [],
  );

  const handleCloseRestrictedTimeModal = useCallback(() => {
    setOpenRestrictedTimeModal(false);
  }, []);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  const handleCreateActivity = useCallback(
    (activity, typeActivity) => {
      const activityHour = activity.timestamp
        ? activity.timestamp
        : activity.time_init
        ? activity.time_init
        : activity[0].notes_next_activity_date;

      const index = closerActivityInADay.defined.dayHours.findIndex(
        dayHour => formattedTime(dayHour.hour) === formattedTime(activityHour),
      );

      if (index >= 0) {
        const updatedActivities = closerActivityInADay.defined.dayHours;

        if (typeActivity === 'Scheduling' || typeActivity === 'Transfer') {
          updatedActivities[index].activity.scheduling.push(activity);
        } else if (typeActivity === 'Priority') {
          updatedActivities[index].activity.priority.push(activity[0]);
        } else {
          updatedActivities[index].activity.restrictedTime.push(activity);
        }

        setCloserActivityInADay({
          ...closerActivityInADay,
          defined: {
            ...closerActivityInADay.defined,
            dayHours: updatedActivities,
          },
        });
      }

      if (
        activity.id === nextActivity.id &&
        nextActivity.timestamp === activityHour
      ) {
        setNextActivity(activity);
      } else {
        api.get(`/schedule/list-next-activity/${user.id}`).then(response => {
          setNextActivity(response.data);
        });
      }
    },
    [closerActivityInADay, nextActivity, formattedTime, user],
  );

  const handleUpdateActivity = useCallback(
    (activity, typeActivity, oldHour?: string) => {
      const activityHour = activity.timestamp
        ? activity.timestamp
        : activity.time_init
        ? activity.time_init
        : activity.notes_next_activity_date;

      let indexActivity: number;
      const updatedActivity = closerActivityInADay.defined.dayHours;
      const indexOldActivity = updatedActivity.findIndex(
        foundIndex =>
          formattedTime(foundIndex.hour) === formattedTime(new Date(oldHour)),
      );

      if (typeActivity === 'Scheduling') {
        updatedActivity[indexOldActivity].activity.scheduling.splice(
          indexActivity,
          1,
        );

        const newIndexActivity = updatedActivity.findIndex(
          foundIndex =>
            formattedTime(foundIndex.hour) === formattedTime(activityHour),
        );

        updatedActivity[newIndexActivity].activity.scheduling.push(activity);
      } else if (typeActivity === 'Priority') {
        const indexActivityHourNotDefined =
          closerActivityInADay.notDefined.findIndex(
            notDefined => notDefined.id === activity.id,
          );

        if (indexActivityHourNotDefined > -1) {
          if (activityHour === 'A Definir') {
            closerActivityInADay.notDefined.splice(
              indexActivityHourNotDefined,
              1,
              activity,
            );
          } else {
            closerActivityInADay.notDefined.splice(
              indexActivityHourNotDefined,
              1,
            );

            const newIndexActivity = updatedActivity.findIndex(
              foundIndex =>
                formattedTime(foundIndex.hour) === formattedTime(activityHour),
            );

            updatedActivity[newIndexActivity].activity.priority.push(activity);
          }
        } else {
          const indexOldHourActivity = updatedActivity.findIndex(
            foundIndex =>
              formattedTime(foundIndex.hour) ===
              formattedTime(new Date(oldHour)),
          );

          const indexOldPriority = updatedActivity[
            indexOldHourActivity
          ].activity.priority.findIndex(
            priority => priority.id === activity.id,
          );

          updatedActivity[indexOldHourActivity].activity.priority.splice(
            indexOldPriority,
            1,
          );

          const newIndexActivity = updatedActivity.findIndex(
            foundIndex =>
              formattedTime(foundIndex.hour) === formattedTime(activityHour),
          );

          updatedActivity[newIndexActivity].activity.priority.push(activity);
        }
      } else if (typeActivity === 'Transfer') {
        updatedActivity[indexOldActivity].activity.transfer.splice(
          indexActivity,
          1,
        );

        const newIndexActivity = updatedActivity.findIndex(
          foundIndex =>
            formattedTime(foundIndex.hour) === formattedTime(activityHour),
        );

        updatedActivity[newIndexActivity].activity.transfer.push(activity);
      } else {
        updatedActivity[indexOldActivity].activity.restrictedTime.splice(
          indexActivity,
          1,
        );

        const newIndexActivity = updatedActivity.findIndex(
          foundIndex =>
            formattedTime(foundIndex.hour) === formattedTime(activityHour),
        );

        updatedActivity[newIndexActivity].activity.restrictedTime.push(
          activity,
        );
      }

      setCloserActivityInADay({
        ...closerActivityInADay,
        defined: {
          ...closerActivityInADay.defined,
          dayHours: updatedActivity,
        },
      });

      if (
        activity.id === nextActivity.id &&
        nextActivity.timestamp === activityHour
      ) {
        setNextActivity(activity);
      } else {
        api.get(`/schedule/list-next-activity/${user.id}`).then(response => {
          setNextActivity(response.data);
        });
      }
    },
    [closerActivityInADay, formattedTime, nextActivity, user],
  );

  const handleSetInfoTask = useCallback(
    (data: IModalInfoDTO) => {
      setCloserActivityInADay(state => {
        const newState: IActivitiesCloserInADayDTO = {
          ...state,
        };

        const indexHour = closerActivityInADay.defined.dayHours.findIndex(
          dayHour =>
            data.timestamp
              ? formattedTime(dayHour.hour) === formattedTime(data.timestamp)
              : formattedTime(dayHour.hour) ===
                formattedTime(new Date(data.notes_next_activity_date)),
        );

        const allActivities = data.timestamp
          ? newState.defined.dayHours[indexHour].activity.scheduling
          : data.notes_next_activity_date === 'A Definir'
          ? newState.notDefined
          : newState.defined.dayHours[indexHour].activity.priority;

        const activity = allActivities.filter(foundActivity => {
          return foundActivity.id === data.activity_id;
        });

        newState.info.try_times =
          activity[0].try_times > data.try_times
            ? state.info.try_times - (activity[0].try_times - data.try_times)
            : state.info.try_times + (data.try_times - activity[0].try_times);

        newState.info.talk =
          activity[0].talk === 'Sim'
            ? data.spoken === 'Sim'
              ? newState.info.talk
              : newState.info.talk - 1
            : data.spoken === 'Sim'
            ? newState.info.talk + 1
            : newState.info.talk;

        return newState;
      });
    },
    [closerActivityInADay, formattedTime],
  );

  const updateNextActivity = async () => {
    if (
      (nextActivity.notes_next_activity_date &&
        isBefore(
          new Date(nextActivity.notes_next_activity_date),
          new Date(),
        )) ||
      (nextActivity.timestamp &&
        isBefore(nextActivity.timestamp, new Date())) ||
      !nextActivity.timestamp ||
      !nextActivity.notes_next_activity_date
    ) {
      const response = await api.get(`/schedule/list-next-activity/${user.id}`);

      setNextActivity(response.data);

      return response;
    }

    return null;
  };

  useQuery('infos', updateNextActivity, {
    refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });

  const getCloserActivityInADay = useCallback(() => {
    api
      .get(
        `/schedule/list-closer-activity?closer_id=${user.id}&date=${formattedDate}`,
      )
      .then(response => {
        setCloserActivityInADay(response.data);
      });
  }, [user, formattedDate]);

  const searchNotDefinedActivity = useCallback(
    (value: string) => {
      const filterActivity = closerActivityInADay.notDefined.filter(
        activity =>
          activity.dealname.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setCloserActivityInADay({
        ...closerActivityInADay,
        notDefined: filterActivity,
      });
    },
    [closerActivityInADay],
  );

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === ''
        ? getCloserActivityInADay()
        : searchNotDefinedActivity(value);
    },
    [getCloserActivityInADay, searchNotDefinedActivity],
  );

  const handleSelectChangeView = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setSelectedDay('activity');
      } else {
        setSelectedDay(value);
      }

      if (value === 'cadence') {
        getCadences();
      }
    },
    [getCadences],
  );

  const previousDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: -1,
    });

    const stringDate = newDate.toISOString();
    setFormattedDate(stringDate.split('T')[0]);
    setScheduleDate(newDate);
    getCloserActivityInADay();
  }, [scheduleDate, getCloserActivityInADay]);

  const nextDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: 1,
    });

    const stringDate = newDate.toISOString();
    setFormattedDate(stringDate.split('T')[0]);
    setScheduleDate(newDate);
    getCloserActivityInADay();
  }, [getCloserActivityInADay, scheduleDate]);

  const handleDateInputChange = useCallback(
    (day: Date) => {
      setScheduleDate(day);
      const stringDate = day.toISOString();
      setFormattedDate(stringDate.split('T')[0]);
      getCloserActivityInADay();
    },
    [getCloserActivityInADay],
  );
  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1NavigationNow>Closer</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Agenda</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <FAQButton>
          <a
            href="https://habitual-suit-ab3.notion.site/Manual-de-Uso-Agenda-5528d809f9bf4850825dbb639b348f41"
            rel="noreferrer"
            target="_blank"
          >
            Dúvidas frequentes
          </a>
          <img src={faq} alt="" />
        </FAQButton>
        <Main>
          <InfosWorkImpactPanel
            schedulesDate={scheduleDate}
            closerInfos={closerActivityInADay.info}
            spokenTime={spokenTime}
            nextActivity={nextActivity}
            formattedTime={formattedTime}
            handleOpenModal={
              nextActivity.notes_next_activity_date
                ? handleOpenActivityModal
                : nextActivity.every_day !== undefined
                ? handleOpenRestrictedTimeModal
                : handleOpenActivityModal
            }
          />
          <ButtonCreate onClick={handleOpenCreateActivityModal}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            Criar atividade
          </ButtonCreate>

          <SchedulesAndActivitiesContainer>
            <SchedulesContainer>
              <ButtonsDateContainer>
                <DaySelectedContainer>
                  <PreviousButton onClick={previousDate}>
                    <ArrowLeftIcon />
                  </PreviousButton>
                  <ContainerInput>
                    <ContainerInputDate>
                      <CalendarIcon />
                      <DayPicker
                        handleChange={day => handleDateInputChange(day)}
                        value={scheduleDate}
                      />
                    </ContainerInputDate>
                  </ContainerInput>
                  <ForwardButton onClick={nextDate}>
                    <ArrowRightIcon />
                  </ForwardButton>
                </DaySelectedContainer>
                <FilterContent>
                  <Select
                    defaultValue="acitivity"
                    onChange={handleSelectChangeView}
                  >
                    <option value="">Selecione a opção</option>
                    <option value="activity">Atividades</option>
                    <option value="cadence">Cadencia</option>
                    {user.profile === 'Closer Linha 1' ||
                    user.profile === 'Gestor Linha 1' ? (
                      <option value="wallet">Carteira</option>
                    ) : (
                      ''
                    )}
                  </Select>
                </FilterContent>
              </ButtonsDateContainer>

              {useCallback(
                () =>
                  selectedDay !== 'cadence' && selectedDay !== 'wallet' ? (
                    <ScheduleTasksContainer>
                      <ScheduleLineContainer className="dateHeader">
                        <Icon>
                          <img src={box_time} alt="Ícone relógio" />
                        </Icon>

                        <LineTableContent>
                          <div id="empty" />
                          <DateHeaderContainer>
                            <p id="title">{scheduleDate.getDate()}</p>
                            <p id="subtitle">
                              {weekdays[scheduleDate.getDay()]}
                            </p>
                          </DateHeaderContainer>

                          <ButtonsContainer>
                            <button
                              type="button"
                              onClick={() =>
                                setSelectedVisualization('calendar')
                              }
                            >
                              {selectedVisualization === 'calendar' ? (
                                <CalendarSelectedIcon />
                              ) : (
                                <CalendarNotSelectedIcon />
                              )}
                            </button>

                            <button
                              type="button"
                              onClick={() =>
                                setSelectedVisualization('listing')
                              }
                            >
                              {selectedVisualization === 'listing' ? (
                                <ListSelectedIcon />
                              ) : (
                                <ListNotSelectedIcon />
                              )}
                            </button>
                          </ButtonsContainer>
                        </LineTableContent>
                      </ScheduleLineContainer>

                      {selectedVisualization === 'calendar' ? (
                        <ScheduleCloserContainer
                          schedulesDate={scheduleDate}
                          closerActivityInADay={closerActivityInADay}
                          formattedTime={formattedTime}
                          handleOpenRestrictedTimeModal={
                            handleOpenRestrictedTimeModal
                          }
                          handleOpenActivityModal={handleOpenActivityModal}
                        />
                      ) : (
                        <ListingActivitiesCloserContainer
                          schedulesDate={scheduleDate}
                          closerActivityInADay={closerActivityInADay}
                          formattedTime={formattedTime}
                          handleOpenRestrictedTimeModal={
                            handleOpenRestrictedTimeModal
                          }
                          handleOpenActivityModal={handleOpenActivityModal}
                        />
                      )}
                    </ScheduleTasksContainer>
                  ) : selectedDay === 'wallet' ? (
                    <ScheduleTasksContainer>
                      <CloserWalletCases closerId={undefined} />
                    </ScheduleTasksContainer>
                  ) : cadences.length > 0 ? (
                    <ScheduleTasksContainer>
                      <ListingCadencesCloserContainer
                        userId={user.id}
                        cadences={cadences}
                        setCadences={setCadences}
                      />
                    </ScheduleTasksContainer>
                  ) : (
                    ''
                  ),
                [
                  cadences,
                  closerActivityInADay,
                  formattedTime,
                  handleOpenActivityModal,
                  handleOpenRestrictedTimeModal,
                  selectedDay,
                  selectedVisualization,
                  user.id,
                  weekdays,
                  scheduleDate,
                ],
              )()}
            </SchedulesContainer>

            {useCallback(
              () =>
                selectedDay !== 'cadence' && selectedDay !== 'wallet' ? (
                  <ActivitiesWithNotHourDefinedContainer>
                    <TitleNotHourDefinedContainer>
                      <p>Atividades com horários a definir</p>
                    </TitleNotHourDefinedContainer>

                    <NotDefinedHourContainer>
                      <SearchByNameContainer>
                        <input
                          type="text"
                          placeholder="Digite o nome do credor"
                          onChange={handleSearchInputChange}
                        />
                        <AiOutlineSearch />
                      </SearchByNameContainer>

                      <Activities>
                        {closerActivityInADay.notDefined &&
                          closerActivityInADay.notDefined.map(
                            priorityHourNotDefined => (
                              <ActivityContainer
                                key={priorityHourNotDefined.id}
                                talkProps={priorityHourNotDefined.talk}
                                statusTaskProps={priorityHourNotDefined.status}
                                tryTimesProps={String(
                                  priorityHourNotDefined.try_times,
                                )}
                                onClick={() =>
                                  handleOpenActivityModal(
                                    priorityHourNotDefined,
                                  )
                                }
                              >
                                <div id="headerActivity">
                                  <div id="titleActivity">
                                    <p id="define">
                                      A definir{' '}
                                      <img src={to_define} alt="A definir" />
                                    </p>
                                    <p id="priority">Priorização</p>
                                  </div>

                                  <div id="stage-try-times">
                                    <p id="stage">
                                      {priorityHourNotDefined.stage_name}
                                    </p>
                                    <span id="try_times">
                                      {priorityHourNotDefined.try_times
                                        ? priorityHourNotDefined.try_times
                                        : 0}
                                    </span>
                                  </div>
                                </div>

                                <p className="credor">
                                  Credor: {priorityHourNotDefined.dealname}
                                </p>
                                <p className="credor">
                                  Valor: {priorityHourNotDefined.amount}
                                </p>
                              </ActivityContainer>
                            ),
                          )}
                      </Activities>
                    </NotDefinedHourContainer>
                  </ActivitiesWithNotHourDefinedContainer>
                ) : (
                  ''
                ),
              [
                closerActivityInADay.notDefined,
                handleOpenActivityModal,
                handleSearchInputChange,
                selectedDay,
              ],
            )()}
          </SchedulesAndActivitiesContainer>

          <ModalCreateActivity
            isOpen={openCreateActivityModal}
            onRequestClose={handleCloseCreateActivityModal}
            handleCreateActivity={handleCreateActivity}
            setSelectedDay={setSelectedDay}
          />

          <ModalSLAInformation
            isOpen={openCreateSLAModal}
            onRequestClose={handleCloseCreateSLAModal}
            activity={activityData}
          />

          <ModalSchedulePriority
            isOpen={openActivityModal}
            onRequestClose={handleCloseActivityModal}
            handleUpdateActivity={handleUpdateActivity}
            activity={activityData}
            type={modalActivityType}
            handleInfo={handleSetInfoTask}
            closerHistory={false}
            handleOpenSLAModal={handleOpenSLAModal}
          />

          <ModalRestrictedTime
            isOpen={openRestrictedTimeModal}
            onRequestClose={handleCloseRestrictedTimeModal}
            handleUpdateActivity={handleUpdateActivity}
            restrictedTime={restrictedTimeData}
            closerHistory={false}
            dateActivity={formattedDate}
          />
        </Main>
      </Body>
    </Container>
  );
};

export default ScheduleCloser;
