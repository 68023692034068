import Swal from 'sweetalert2';

export default async function verifyProposal(
  proposal: string,
  table_range: string,
): Promise<boolean> {
  const proposalNumber = proposal.split('%')[0];

  const minumumTable = table_range.split('%')[0];

  if (proposalNumber < minumumTable) {
    Swal.fire(
      'Atenção!',
      `Não é possível calcular abaixo do piso da tabela, tente outro valor!`,
      'warning',
    );

    return false;
  }

  return true;
}
