interface IRequest {
  name_modal?: string;
  who_contacted?: string;
  connection_type?: string;
  connection_method: string;
  reaction?: string;
  plans?: string;
  profile?: string;
  influence?: string;
  objection?: string;
  reason_for_scheduling?: string;
  contact_preference?: string;
  selected_time?: string;
  lostCategory?: string;
  hunter_name?: string;
  valid_contact?: string;
  autority?: string;
  contact_with_competitor?: string;
  doubts?: string;
  lost_category?: string;
  motive_of_opportunity?: string;
  motive_for_not_advancing?: string;
  value_to_negociate?: string;
  contact_preferences?: string;
  was_aware_precatory?: string;
  was_aware_PEC?: string;
  feel_uncomfortable?: string;
}

export function createFirstPassClarificationNote({
  who_contacted,
  connection_type,
  connection_method,
  reaction,
  plans,
  profile,
  influence,
  objection,
  hunter_name,
  valid_contact,
  autority,
  doubts,
  value_to_negociate,
  contact_preferences,
  was_aware_precatory,
  was_aware_PEC,
  feel_uncomfortable,
}: IRequest): string {
  /*  if (name_modal === 'Lost') {
    return `RAPPORT \n\
    Hunter: ${hunter_name || ''} \n\
    Tipo de Conexão: ${connection_type} \n\
    Meio de Conexão: ${connection_method} \n\
    Telefone Válido: ${valid_contact} \n\
    Com quem foi o Contato: ${who_contacted} \n \n\

    TOMADA DE DECISÃO \n\
    Conversamos com a autoridade? ${autority} \n\
    Mencionou alguma influência na conversa? ${influence} \n\
    Teve contato com algum concorrente? ${contact_with_competitor} \n \n\

    SPIN SELLING \n\
    Perfil? ${profile} \n\
    Sobre planos com o recurso? ${plans} \n\
    Demonstrou alguma dúvida na conversa? ${doubts} \n\
    Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
    Trouxe alguma objeção para a conversa? ${objection} \n \n\

    MOTIVO DA PERDA \n\
    ${lost_category} \n \n\

    OBSERVAÇÃO \n\
    `;
  }
  if (name_modal === 'Successful') {
    return `RAPPORT \n\
    Hunter: ${hunter_name} \n\
    Tipo de Conexão: ${connection_type} \n\
    Meio de Conexão: ${connection_method} \n\
    Telefone Válido: ${valid_contact} \n\
    Com quem foi o Contato: ${who_contacted} \n\
    Motivo do Agendamento: ${reason_for_scheduling} \n \n\

    TOMADA DE DECISÃO \n\
    Conversamos com a autoridade? ${autority} \n\
    Mencionou alguma influência na conversa? ${influence} \n\
    Teve contato com algum concorrente? ${contact_with_competitor} \n \n\

    SPIN SELLING \n\
    Perfil? ${profile} \n\
    Sobre planos com o recurso? ${plans} \n\
    Demonstrou alguma dúvida na conversa? ${doubts} \n\
    Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
    Trouxe alguma objeção para a conversa? ${objection} \n \n\

    OBSERVAÇÃO \n\
    `;
  }
  return `RAPPORT
    Hunter: ${hunter_name} \n\
    Tipo de Conexão: ${connection_type} \n\
    Meio de Conexão: ${connection_method} \n\
    Telefone Válido: ${valid_contact} \n\
    Com quem foi o Contato: ${who_contacted} \n\
    Motivo de Oportunidade: ${motive_of_opportunity} \n\
    Motivo de não ter avançado: ${motive_for_not_advancing} \n \n\

    TOMADA DE DECISÃO \n\
    Conversamos com a autoridade? ${autority} \n\
    Mencionou alguma influência na conversa? ${influence} \n\
    Teve contato com algum concorrente? ${contact_with_competitor} \n \n\

    SPIN SELLING \n\
    Perfil? ${profile} \n\
    Sobre planos com o recurso? ${plans} \n\
    Demonstrou alguma dúvida na conversa? ${doubts} \n\
    Gerou incerteza do recebimento? Qual foi sua reação? ${reaction} \n\
    Trouxe alguma objeção para a conversa? ${objection} \n \n\

    OBSERVAÇÃO \n\
    `; */

  return `RAPPORT \n\
    Hunter: ${hunter_name || ''} \n\
    Tipo de Conexão: ${connection_type || ''} \n\
    Meio de Conexão: ${connection_method || ''} \n\
    Telefone Válido: ${valid_contact || ''} \n\
    Com quem foi o Contato: ${who_contacted || ''} \n\
    Como o credor prefere receber o contato: ${contact_preferences || ''} \n \n\

    TOMADA DE DECISÃO \n\
    Conversamos com a autoridade? ${autority || ''} \n\
    Mencionou alguma influência na conversa? ${influence || ''} \n\
    Tinha conhecimento do precatório? ${was_aware_precatory || ''} \n\
    Tinha conhecimento da previsão de pagamento? ${was_aware_PEC || ''} \n\
    Se sente incomodado? ${feel_uncomfortable} \n \n\

    SPIN SELLING \n\
    Perfil? ${profile || ''} \n\
    Sobre planos com o recurso? ${plans || ''} \n\
    O cliente disse que quer resolver o problema? ${doubts || ''} \n\
    Ficou claro para o cliente como podemos resolver o problema dele? ${
      reaction || ''
    } \n\
    Trouxe alguma objeção para a conversa?  ${objection || ''} \n \n\

    NEGOCIAÇÃO\n\
    Informou o valor que negociaria? ${value_to_negociate || ''}\n \n\

    OBSERVAÇÃO \n\
    `;
}

export function createClarificationNote({
  name_modal,
  who_contacted,
  connection_type,
  connection_method,
  reaction,
  plans,
  profile,
  influence,
  objection,
  reason_for_scheduling,
  contact_preference,
  selected_time,
  lostCategory,
}: IRequest): string {
  const connectionType =
    connection_type === 'Inbound (0800)'
      ? 'ligação no 0800 da Precato'
      : connection_type === 'Inbound (Virtual)'
      ? 'atendimento virtual da Precato'
      : connection_type === 'Outbound'
      ? 'ligação ativa procurando o cliente'
      : 'meio de uma indicação de um terceiro';

  let connectionMethod = '';

  if (connection_method === 'SMS' || connection_method === 'WPP') {
    connectionMethod = `no meio ativo de busca, por causa de um ${connection_method} que enviamos`;
  } else if (connection_method === 'E-mail') {
    connectionMethod = `no meio passivo, onde o cliente abriu um ${connection_method}`;
  } else if (
    connection_method === 'Facebook' ||
    connection_method === 'Instagram'
  ) {
    connectionMethod = `no meio passivo, onde o cliente entrou em contanto por meio de um anúncio do ${connection_method}`;
  } else if (connection_method === 'Google') {
    connectionMethod = `no meio passivo, onde o cliente entrou em contanto pesquisando algo no ${connection_method}`;
  } else if (connection_method === 'Site') {
    connectionMethod = `no meio passivo, onde o cliente entrou em contato entrando no ${connection_method}`;
  } else if (connection_method === 'Indicação') {
    connectionMethod = `no meio ativo, onde o cliente entrou em contato por conta de ${connection_method}`;
  } else if (connection_method === 'Outros') {
    connectionMethod = `no meio passivo, onde o cliente entrou em contato por ${connection_method} motivos`;
  } else {
    connectionMethod = `por meio ativo de busca, por causa de uma tentativa de ligação ${connection_method}`;
  }

  let creditorReaction = '';

  if (reaction === 'Não' || reaction === 'Não falei sobre incerteza') {
    creditorReaction = 'Durante a conversa o mesmo não ficou inseguro';
  } else {
    creditorReaction =
      'Durante a conversa o mesmo ficou inseguro do recebimento, ';
    if (reaction === 'Sim, mas adv contradiz') {
      creditorReaction += 'mas o advogado diz o contrário';
    } else if (reaction === 'Sim, ficou incomodado') {
      creditorReaction += 'e ficou incomodado com a fila';
    } else if (reaction === 'Sim, indiferente') {
      creditorReaction += 'mas é indiferente sobre a fila';
    } else if (
      reaction === 'Gerei incerteza, mas adv disse que vai receber esse ano'
    ) {
      creditorReaction += 'mas advogado disse que vai receber esse ano';
    } else if (
      reaction === 'Gerei incerteza, mas o adv é contra a antecipação'
    ) {
      creditorReaction += 'mas advogado é contra a antecipação';
    } else if (reaction === 'Gerei incerteza, e o credor ficou incomodado') {
      creditorReaction += 'e o credor ficou incomodado';
    } else if (reaction === 'Gerei incerteza, e o credor ficou indiferente') {
      creditorReaction += 'mas o credor ficou indiferente';
    }
  }

  const creditorPlans =
    plans === 'Não perguntei'
      ? 'Não consegui observar quais planos tem'
      : plans === 'Sim, dívida'
      ? 'Tem planos para quitar dívida'
      : plans === 'Sim, investir'
      ? 'Tem planos para investir'
      : plans === 'Sim, saúde'
      ? 'Tem planos para resolver questões de saúde'
      : plans === 'Sim, comprar coisas'
      ? 'Tem planos para adquirir bens materiais'
      : plans === 'Não quis dividir o plano'
      ? 'Não quis dividir o plano'
      : 'Não tem planos com a antecipação';

  const creditorInfluence =
    influence === 'Advogado' ||
    influence === 'Disse que acompanha tudo com o adv'
      ? 'É influenciado pelo advogado do caso'
      : influence === 'Familiar' ||
        influence === 'Tinha um familiar perto na hora da conversa' ||
        influence === 'Mencionou que algum familiar era contra a antecipação'
      ? 'A família influencia no caso'
      : influence === 'Terceiros'
      ? 'Existem terceiros que influenciam no caso'
      : influence === 'Concorrência' ||
        influence === 'Disse que já ouviu proposta da concorrência'
      ? 'Tem concorrência gerando influência sobre o caso'
      : influence === 'Sem influência'
      ? 'Não teve influência'
      : 'Não mencionou influência';

  const creditorObjection =
    objection === 'Não teve objeção'
      ? objection
      : `Levantou a seguinte objeção: ${objection}`;

  let reasonForScheduling = '';

  if (reason_for_scheduling) {
    if (
      reason_for_scheduling ===
        'Trabalhando (Não consegue conversar no momento)' ||
      reason_for_scheduling === 'Médico' ||
      reason_for_scheduling === 'Dirigindo'
    ) {
      reasonForScheduling = `onde pediu para falar por ${contact_preference} às ${selected_time} por estar ${reason_for_scheduling} no momento do contato`;
    } else if (
      reason_for_scheduling === 'Está na rua - não consegue conversar agora'
    ) {
      reasonForScheduling = `onde pediu para falar por ${contact_preference} às ${selected_time} por estar na rua no momento do contato`;
    } else if (reason_for_scheduling === 'Falha de Transferência') {
      reasonForScheduling = `mas precisou ser agendado às ${selected_time} por ter ${reason_for_scheduling} e preferiu contato por ${contact_preference}`;
    } else if (
      reason_for_scheduling === 'Mandar por escritório para ter tudo relatado'
    ) {
      reasonForScheduling = `e quis receber tudo por escrito (o motivo que entramos em contanto e a proposta), prefere contato por ${contact_preference} e marcou para às ${selected_time}`;
    } else if (reason_for_scheduling === 'Inbound Virtual') {
      reasonForScheduling = `no qual foi direcionado para o agendamento às ${selected_time} no atendimento virtual e prefere contato por ${contact_preference}`;
    } else if (
      reason_for_scheduling ===
      'Familiar ajuda - ligar no horário que o mesmo está em casa'
    ) {
      reasonForScheduling = `mas precisou ser agendado às ${selected_time} para a família ajudar e prefere o contato por ${contact_preference}`;
    }
  }

  if (name_modal === 'Lost') {
    return `Primeiramente fiz contato com ${who_contacted}. O mesmo chegou por ${connectionType}, ${connectionMethod}. ${creditorReaction}. ${creditorPlans}. Tem o perfil: ${profile}. ${creditorInfluence}. ${creditorObjection}. Caso perdido pelo motivo: ${lostCategory}`;
  }

  if (name_modal === 'Opportunity') {
    return `Primeiramente fiz contato com ${who_contacted}. O mesmo chegou por ${connectionType}, ${connectionMethod}. ${creditorReaction}. ${creditorPlans}. Tem o perfil: ${profile}. ${creditorInfluence}. ${creditorObjection}.`;
  }

  if (contact_preference) {
    return `Primeiramente fiz contato com ${who_contacted}. O mesmo chegou por ${connectionType}, ${connectionMethod}, ${reasonForScheduling}. ${creditorReaction}. ${creditorPlans}. Tem o perfil: ${profile}. ${creditorInfluence}. ${creditorObjection}.`;
  }

  return `Primeiramente fiz contato com ${who_contacted}. O mesmo chegou por ${connectionType}, ${connectionMethod}. ${creditorReaction}. ${creditorPlans}. Tem o perfil: ${profile}. ${creditorInfluence}. ${creditorObjection}.`;
}
