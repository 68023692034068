import React from 'react';

import { Container, Text, TextActiveScreen } from './styles';

interface NavigationBarProps {
  past_screens: string;
  active_screen: string;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  past_screens,
  active_screen,
}) => {
  return (
    <Container>
      <Text>{past_screens}</Text>
      <TextActiveScreen>{active_screen}</TextActiveScreen>
    </Container>
  );
};
