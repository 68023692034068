import { shade } from 'polished';
import styled from 'styled-components';

export const LoanModalStyle = {
  content: {
    width: '80rem',
    height: 'fit-content',
    minHeight: '16.401rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
    border: 'none',
    boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.25)',
  },
};

export const ModalHeader = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-100);

  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;

  font-weight: 400;
  font-size: 1.5rem;
  font-family: 'Poppins';
  color: var(--blue-800);
`;

export const Table = styled.table`
  width: 100%;
  word-break: break-all;
  padding: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1350px) {
    width: 90vw;
  }
`;

export const Thead = styled.thead`
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
`;

export const TableRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  padding: 0.2rem;
  background: var(--blue-100);
`;

export const CellHeader = styled.th`
  /* word-break: break-all; */
  display: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.7rem;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  /* text-align: center; */
  letter-spacing: -0.015em;

  color: #05233e;
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25rem;
  text-align: center;
`;

export const TableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;

  :nth-child(odd) {
    background: #efefef;
  }

  :nth-child(even) {
    background: #e5e5e5;
  }
`;

export const TotalTableRowBody = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;

  background: #c4c4c4;
  margin-top: 1rem;
`;

export const CellBody = styled.td`
  word-break: break-all;
  width: 100%;
  background: transparent;
  padding: 0.7rem;

  color: #05233e;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: -0.015em;
`;
