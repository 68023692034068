import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Styles/Title';
import {
  DivSeparator,
  Separator,
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  SendDefaultFileContainer,
  SendButtonContainer,
  SpanInput,
} from './style';
import apiChatbot from '../../../services/apiChatbot';
import { UploadedFiles } from './UploadedFiles/index';
import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export interface IRequestFileProps {
  fileName: string;
  filePath: string;
  fileSize: number;
}

export function Enrichment(): JSX.Element {
  const [file, setFile] = useState<IFileProps>({} as IFileProps);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [enrichmentFiles, setEnrichmentFiles] = useState(
    [] as IRequestFileProps[],
  );

  const itemsPerPage = 8;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState([] as IRequestFileProps[]);

  const [download, setDownload] = useState('');

  const url = 'http://localhost:4001/enrichment/input-files';
  const defaultFile = 'data_pattern.xlsx';

  useEffect(() => {
    const fetchData = async () => {
      const api = apiChatbot('secret');
      await api.get('/enrichment').then(response => {
        setEnrichmentFiles(response.data);

        const enrichmentFilesOrder = response.data as IRequestFileProps[];
        const reverseEnrichmentFileOrder = enrichmentFilesOrder.reverse();

        setCurrentItems(reverseEnrichmentFileOrder.slice(0, itemsPerPage));
      });
    };

    fetchData();
  }, [file, isActive]);

  useEffect(() => {
    const pages: number[] = [];

    for (
      let index = 1;
      index <= Math.ceil(enrichmentFiles.length / itemsPerPage);
      index++
    ) {
      pages.push(index);
    }

    setTotalPages(pages);
  }, [currentItems]);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: IRequestFileProps[] = enrichmentFiles;

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        setCurrentItems,
        items,
        setPage,
        toPage,
      });
    },
    [page, totalPages, currentItems, setCurrentItems],
  );

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    try {
      data.append('file', file.file);

      const api = apiChatbot(localStorage.getItem('@Precato:token'));
      await api.post('/enrichment', data).then(async response => {
        setFile({} as IFileProps);
        setIsActive(false);

        toast.success('Informações cadastradas com sucesso!');
      });
    } catch (error) {
      console.log(error);
    }
  }, [file]);

  const submitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: IFileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };

      setFile(newFile);
      setIsActive(true);

      event.target.value = null;
    },
    [setFile],
  );

  return (
    <>
      <Container>
        <Navegation>
          <H1Navigation>Conexão {'>'} </H1Navigation>
          <H1NavigationNow>Enriquecimento</H1NavigationNow>
        </Navegation>
        <Body>
          <Toaster position="top-right" reverseOrder={false} />
          <Title>Enriquecimento</Title>
          <DivSeparator>
            <Separator />
          </DivSeparator>
          <FileContainer>
            <FileInputContainer>
              <SpanInput>Modelo de Arquivo:</SpanInput>
              <SendDefaultFileContainer>
                <div>
                  <a
                    href={download}
                    download="data_pattern.xlsx"
                    className="downloadDefaultFile"
                    onClick={() => {
                      setDownload(`${url}/${defaultFile}`);
                    }}
                  >
                    Dowload
                  </a>
                  <span>Realizar dowload de arquivo padrão para envio</span>
                </div>
              </SendDefaultFileContainer>
              <SpanInput>Envio de Arquivo:</SpanInput>
              <SendFileContainer>
                <div>
                  <label htmlFor="file">Selecione um arquivo</label>
                  <input
                    id="file"
                    type="file"
                    name="file"
                    onChange={event => submitFile(event)}
                  />
                  {file && file.name ? (
                    <span>{file.name}</span>
                  ) : (
                    <span>Nenhum arquivo selecionado</span>
                  )}
                </div>
              </SendFileContainer>

              <SendButtonContainer isActive={isActive}>
                <button type="button" onClick={handleUpload}>
                  Enviar
                </button>
                <span>Arquivos serão processados automaticamente</span>
              </SendButtonContainer>
            </FileInputContainer>
          </FileContainer>
          <UploadedFiles files={currentItems} />
        </Body>
        <PaginationComponent
          page={page}
          totalPages={totalPages}
          handleChangePagination={handleChangePagination}
        />
      </Container>
    </>
  );
}
