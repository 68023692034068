import axios, { AxiosInstance } from 'axios';

export function apiByState(state: string): AxiosInstance {
  return process.env.REACT_APP_API_URL === 'dev'
    ? axios.create({
        baseURL: 'http://localhost:10008',
        headers: {
          Authorization: 'Basic cHJlY2F0bzpwcmVjYXRvLWF1dGhAdGpzcA==',
        },
      })
    : axios.create({
        baseURL: `https://api-${state}.precato.com.br`,
        headers: {
          Authorization: 'Basic cHJlY2F0bzpwcmVjYXRvLWF1dGhAdGpzcA==',
        },
      });
}
