import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;

  margin-top: 2rem;
`;

export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 1.5rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 1rem 0;
`;

export const TitleHeader = styled.h1`
  width: 16rem;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--blue-100);
  border-radius: 0.25rem;

  font-size: 1.25rem;
  font-weight: 400;

  color: var(--blue-700);
`;

export const Label = styled.label`
  font-weight: 300;
  font-size: 0.813rem;

  color: var(--blue-700);
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 3rem;
  margin-bottom: 0.625rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .select {
    width: 20rem;
    height: 2rem;

    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid var(--blue-300);

    &:focus {
      border: 2px solid var(--blue-400);
    }
  }
`;

export const Input = styled.input`
  width: 20rem;
  height: 2rem;

  padding-left: 0.25rem;
  border-radius: 0.25rem;

  transition: border 0.2ms;
  margin-bottom: 0.5rem;
  border: 1px solid var(--blue-300);

  font-weight: 400;
  font-size: 0.75rem;

  &:focus {
    border: 2px solid var(--blue-400);
  }
`;

export const BorderFormGroup = styled.div`
  width: 0.313rem;
  height: auto;
  max-height: 8rem;

  background: var(--gradient);
  border-radius: 5.938rem;
`;
