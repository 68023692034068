import { useCallback } from 'react';
import Modal from 'react-modal';
import {
  Button,
  ButtonContainer,
  Container,
  StyleModal,
  TextContainer,
} from './styles';

interface INotificationProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const FeedbackFormsModal: React.FC<INotificationProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleOpenForms = useCallback(() => {
    window.open('https://forms.gle/R1W5FfJD4kWAUt5p7');
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={StyleModal}>
      <Container>
        <TextContainer>
          Já respondeu a nova pesquisa de feedback do App Precato? Sua resposta
          é de grande importancia para a melhoria da plataforma e não leva nem
          10 minutos!
        </TextContainer>
        <ButtonContainer>
          <Button onClick={handleOpenForms}>Responder</Button>
        </ButtonContainer>
        <TextContainer>
          <b> Caso já tenha repondido, ignore esta mensagem.</b>
        </TextContainer>
      </Container>
    </Modal>
  );
};

export { FeedbackFormsModal };
