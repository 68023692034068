import styled, { css } from 'styled-components';

interface ProvidenceProps {
  providenceStatus: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const HeaderWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 90%;
  height: 3.438rem;
  background: rgba(22, 101, 216, 0.05);

  border-bottom: 0.063rem solid rgba(194, 194, 194, 0.5);

  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: -0.015em;
  text-align: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.5rem;
  min-width: 100%;
`;

export const Cell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:nth-child(1) {
    min-width: 50%;
    margin-left: -3.5rem;
  }

  &:nth-child(2) {
    min-width: 15%;
  }

  &:nth-child(3) {
    // min-width: 30%;
    margin-left: 3.5rem;
  }

  &:nth-child(4) {
    min-width: 5%;
    margin-right: 1rem;
    justify-content: right;
  }

  img {
    cursor: pointer;
  }
`;

export const CellAct = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  text-align: center;

  &:nth-child(1) {
    min-width: 14%;
  }

  &:nth-child(2) {
    min-width: 8%;
  }

  &:nth-child(3) {
    min-width: 43%;
  }
  &:nth-child(4) {
    min-width: 16%;
  }
  &:nth-child(5) {
    min-width: 14%;
  }
  &:nth-child(6) {
    margin-right: 1.5rem;
    justify-content: right;
    min-width: 5%;
  }
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(22, 101, 216, 0.05);
  border-radius: 0.25rem;

  padding: 0.25rem 0;

  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: center;
`;

export const Tbody = styled.tbody<ProvidenceProps>`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.015em;
  text-align: center;

  ${props =>
    props.providenceStatus === 'Concluída'
      ? css`
          background: #3ca64740;
          margin-bottom: 0.2rem;
        `
      : props.providenceStatus === 'Atrasada'
      ? css`
          background: #de0c0c40;
          margin-bottom: 0.2rem;
        `
      : props.providenceStatus === 'Pendente'
      ? css`
          background: #0140771a;
          margin-bottom: 0.2rem;
        `
      : css`
          background: #efefef;
          margin-bottom: 0.2rem;
        `}
`;
