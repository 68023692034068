import { format, isBefore } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useAuth } from '../../../../../hooks/auth';
import { ScheduleOptionsButton } from '../../../../../components/ScheduleOptionsButton';
import { Loading } from '../../../../../components/Loading';

import api from '../../../../../services/api';

import closeIcon from '../../../../../assets/icons/cancel.svg';
import editIcon from '../../../../../assets/icons/edit.svg';

import {
  Container,
  Header,
  ButtonsHeaderContainer,
  EditDeleteButton,
  CloseButton,
  Main,
  LabelInputContainer,
  Label,
  Input,
  Link,
  LineSeparator,
  Textarea,
} from './styles';
import { ISLAComplet } from '../../../components/ModalSLA/interface';
import { ISlaFields, getSlaFields } from '../../utils/slaFields';

Modal.setAppElement('#root');

export interface ILastSla extends ISLAComplet {
  name?: string;
}

export interface INutritionCaseModal {
  id: string;
  hubspot_ticket_id: string;
  name: string;
  deal_name: string;
  conection_date: Date;
  deal_value: string;
  stage: string;
  try_message?: string;
  try_call?: string;
  spoken?: string;
  status: string;
  hunter_sla: ILastSla;
  observation: string;
}

interface ModalActivityProps {
  isOpen: boolean;
  onRequestClose: () => void;
  nutrition: INutritionCaseModal | undefined;
}

export function NutritionActivityModal({
  isOpen,
  onRequestClose,
  nutrition,
}: ModalActivityProps): JSX.Element {
  const { user } = useAuth();

  const [editFields, setEditFields] = useState(false);
  const [slaFields, setSLAFields] = useState<ISlaFields[]>([]);

  const talkArray = ['Sim', 'Não'];
  const tryArray = ['Sim', 'Não'];
  const activitySituation = ['Pendente', 'Concluída'];

  const [talkOption, setTalkOption] = useState<string>('Não');
  const [tryMessage, setTryMessage] = useState<string>('Não');
  const [tryCall, setTryCall] = useState<string>('Não');
  const [observation, setObservation] = useState<string>('');
  const [activitySituationOption, setActivitySituationOption] =
    useState<string>('');

  useEffect(() => {
    if (isOpen) {
      /*  if (nutrition.lastSlA) {
        foundSlaFields = getSlaFields(nutrition.lastSlA.name || 'N1');
      } else { */
      const foundSlaFields = getSlaFields('N1');
      // }

      setSLAFields(foundSlaFields);
      setTalkOption(nutrition.spoken);
      setActivitySituationOption(nutrition.status);
    }
  }, [isOpen, nutrition.spoken, nutrition.status]);

  const closeModal = useCallback(() => {
    onRequestClose();
    setEditFields(false);

    setTalkOption(nutrition.spoken);
    setActivitySituationOption(nutrition.status);
  }, [nutrition, onRequestClose]);

  const handleSetTryOption = useCallback(
    async (name: string, tryOp: string) => {
      if (name === 'Ligação') {
        setTryCall(tryOp);
      } else {
        setTryMessage(tryOp);
      }
    },
    [],
  );

  const handleUpdateActivity = useCallback(async () => {
    try {
      if (
        nutrition.name === 'Ligação' &&
        tryCall === 'Não' &&
        activitySituationOption === 'Concluída'
      ) {
        Swal.fire('Atenção!', 'Não é possivel concluir sem tentar', 'warning');
      } else if (
        nutrition.name === 'Mensagem' &&
        tryMessage === 'Não' &&
        activitySituationOption === 'Concluída'
      ) {
        Swal.fire('Atenção!', 'Não é possivel concluir sem tentar', 'warning');
      } else {
        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        await api.patch('schedule/create-hunter-priority', {
          id: nutrition.id,
          status: activitySituationOption || nutrition.status,
          spoken: talkOption || nutrition.spoken,
          tryCall: tryCall || nutrition.try_call,
          tryMessage: tryMessage || nutrition.try_message,
          observation: observation || nutrition.observation,
        });

        toast.success('Atividade atualizada com sucesso!');

        setTalkOption(talkOption || nutrition.spoken);
        setActivitySituationOption(activitySituationOption || nutrition.status);
        setTryCall(tryCall || String(nutrition.try_call));
        setTryMessage(tryMessage || String(nutrition.try_message));

        closeModal();
      }
    } catch (error) {
      if (error.response.data.status === 404) {
        Swal.fire('Atenção!', error.response.data.message, 'warning');
      } else {
        Swal.fire(
          'Atenção!',
          'Não foi possísvel atualizar atividade.',
          'warning',
        );
      }

      const loader = document.getElementById('loader');
      loader.style.display = 'none';
    }
  }, [
    activitySituationOption,
    closeModal,
    nutrition.id,
    nutrition.name,
    nutrition.spoken,
    nutrition.status,
    nutrition.try_call,
    nutrition.try_message,
    nutrition.observation,
    observation,
    talkOption,
    tryCall,
    tryMessage,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={Container}>
      <Loading />
      <Header
        isBefore={isBefore(new Date(), new Date())}
        status="Pendente"
        try_times={0}
      >
        <span>
          {format(new Date(), 'dd/MM/yyyy')} - {nutrition.name}
        </span>

        <ButtonsHeaderContainer>
          {(user.profile === 'Hunter New' ||
            user.profile === 'Hunter Teste' ||
            user.profile === 'Hunter Líder' ||
            user.profile === 'Gestores Hunter New') &&
            (editFields ? (
              <EditDeleteButton
                editFields={editFields}
                onClick={handleUpdateActivity}
              >
                Salvar alterações
              </EditDeleteButton>
            ) : (
              <>
                <EditDeleteButton onClick={() => setEditFields(true)}>
                  <img src={editIcon} alt="Editar informações" />
                </EditDeleteButton>
              </>
            ))}
          <CloseButton onClick={closeModal}>
            <img src={closeIcon} alt="Fechar modal" />
          </CloseButton>
        </ButtonsHeaderContainer>
      </Header>

      <Main>
        <>
          <Label>{nutrition.name ? nutrition.name.toUpperCase() : ''}</Label>
          <LineSeparator />
          {nutrition.hunter_sla && slaFields
            ? slaFields.map(field => (
                <LabelInputContainer>
                  <Label>{field.field}</Label>
                  <Input
                    type="text"
                    disabled
                    value={nutrition.hunter_sla[`${field.label}`] || ''}
                  />
                </LabelInputContainer>
              ))
            : 'Nenhum SLA preenchido na agenda'}

          <LabelInputContainer>
            <Label>Link Hubspot:</Label>
            <Link
              href={`https://app.hubspot.com/contacts/7857327/record/0-5/${nutrition.hubspot_ticket_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {`https://app.hubspot.com/contacts/7857327/record/0-5/${nutrition.hubspot_ticket_id}`}
            </Link>
          </LabelInputContainer>

          {editFields && (
            <>
              <LineSeparator />

              <LabelInputContainer className="editInputsContainer">
                <Label htmlFor="try_times">Falou:</Label>
                {talkArray.map(talk => (
                  <ScheduleOptionsButton
                    key={talk}
                    selectedValue={talkOption === talk}
                    type="button"
                    onClick={() => setTalkOption(talk)}
                  >
                    {talk}
                  </ScheduleOptionsButton>
                ))}
              </LabelInputContainer>

              <LabelInputContainer className="editInputsContainer">
                <Label htmlFor="spoken">
                  Tentou {nutrition.name.toLowerCase()}:
                </Label>
                {tryArray.map(tryOp => (
                  <ScheduleOptionsButton
                    key={tryOp}
                    selectedValue={
                      nutrition.name === 'Ligação'
                        ? tryCall === tryOp
                        : tryMessage === tryOp
                    }
                    type="button"
                    onClick={() => handleSetTryOption(nutrition.name, tryOp)}
                  >
                    {tryOp}
                  </ScheduleOptionsButton>
                ))}
              </LabelInputContainer>

              <LabelInputContainer className="editInputsContainer situationActivity">
                <Label htmlFor="activity_situation">Situação:</Label>
                {activitySituation.map(situation => (
                  <ScheduleOptionsButton
                    key={situation}
                    selectedValue={activitySituationOption === situation}
                    type="button"
                    onClick={() => setActivitySituationOption(situation)}
                  >
                    {situation}
                  </ScheduleOptionsButton>
                ))}
              </LabelInputContainer>

              <LabelInputContainer className="observation-container">
                <Label>Observações:</Label>
                <Textarea
                  name="observation"
                  defaultValue={nutrition.observation || ''}
                  onChange={e => setObservation(e.target.value)}
                />
              </LabelInputContainer>

              <LineSeparator />
            </>
          )}
        </>
      </Main>
    </Modal>
  );
}
