import { ChangeEvent } from 'react';
import {
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  TitleFile,
  Label,
  InputFile,
  TextFile,
  SendButtonContainer,
  ButtonSendFile,
  TextFileButton,
  SelectFileContainer,
} from './styles';

interface IExampleFileTRF2TRF4 {
  handleUpload: () => Promise<void>;
  submitFile: (event: ChangeEvent<HTMLInputElement>) => void;
  files_length: number;
}

export const ExampleFileTrfDataExtraction: React.FC<IExampleFileTRF2TRF4> = ({
  submitFile,
  handleUpload,
  files_length,
}: IExampleFileTRF2TRF4) => {
  return (
    <FileContainer>
      <FileInputContainer>
        <TitleFile>Arquivo:</TitleFile>
        <SendFileContainer>
          <SelectFileContainer>
            <Label htmlFor="file">Selecione os arquivos</Label>
            <InputFile
              onChange={event => submitFile(event)}
              id="file"
              type="file"
              name="file"
              multiple
            />
            <TextFile>{files_length} Pdf's selecionados.</TextFile>
          </SelectFileContainer>
        </SendFileContainer>

        <SendButtonContainer>
          <ButtonSendFile type="button" onClick={() => handleUpload()}>
            Enviar
          </ButtonSendFile>
          <TextFileButton>
            Arquivos serão processados automaticamente
          </TextFileButton>
        </SendButtonContainer>
      </FileInputContainer>
    </FileContainer>
  );
};
