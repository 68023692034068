import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { IWorkDayDTO } from '../dtos/IWorkDayDTO';
import { EditTableRow } from '../EditTableRow';
import {
  SaveButton,
  Table,
  TableHead,
  TableHeader,
  VerticalSeparator,
} from './styles';

interface IEditorTableProps {
  goToViewMode: () => void;
  getWorkTime: () => void;
  receivedWorkTime: IWorkDayDTO[];
}

export function EditorTable({
  goToViewMode,
  getWorkTime,
  receivedWorkTime,
}: IEditorTableProps): JSX.Element {
  const [workTime, setWorkTime] = useState<IWorkDayDTO[]>([]);
  const [validRows, setValidRows] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const changeStartHour = useCallback(
    (id: number, start_hour: number) => {
      const newWorkTime = [...workTime];

      newWorkTime[id].start_hour = start_hour;

      setWorkTime(newWorkTime);
    },
    [workTime],
  );

  const changeStartMinute = useCallback(
    (id: number, start_minute: number) => {
      const newWorkTime = [...workTime];

      newWorkTime[id].start_minute = start_minute;

      setWorkTime(newWorkTime);
    },
    [workTime],
  );

  const changeEndHour = useCallback(
    (id: number, end_hour: number) => {
      const newWorkTime = [...workTime];

      newWorkTime[id].finish_hour = end_hour;

      setWorkTime(newWorkTime);
    },
    [workTime],
  );

  const changeEndMinute = useCallback(
    (id: number, end_minute: number) => {
      const newWorkTime = [...workTime];

      newWorkTime[id].finish_minute = end_minute;

      setWorkTime(newWorkTime);
    },
    [workTime],
  );

  const changeHoliday = useCallback(
    (id: number) => {
      const newWorkTime = [...workTime];

      newWorkTime[id].is_work_day = !newWorkTime[id].is_work_day;

      setWorkTime(newWorkTime);
    },
    [workTime],
  );

  const changeValidRows = useCallback(
    (id: number, valid: boolean) => {
      const newValidRows = [...validRows];

      newValidRows[id] = valid;

      setValidRows(newValidRows);
    },
    [validRows],
  );

  const saveWorkTime = useCallback(async () => {
    const invalidRow = validRows.find(
      (validRow, position) => !validRow && workTime[position].is_work_day,
    );

    if (invalidRow === false) {
      Swal.fire('Error', 'Invalid work time!', 'error');
    } else {
      await axios.put(
        `${process.env.REACT_APP_MARKETING_URL}/zendesk/work-time`,
        { work_days: workTime },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      getWorkTime();
    }

    goToViewMode();
  }, [getWorkTime, goToViewMode, validRows, workTime]);

  useEffect(() => {
    if (workTime.length === 0) {
      setWorkTime(receivedWorkTime);
    }
  }, [receivedWorkTime, workTime.length]);

  return (
    <>
      <Table>
        <TableHead>
          <TableHeader width="13.5rem">Dia da semana</TableHeader>
          <VerticalSeparator />
          <TableHeader width="15rem">Entrada</TableHeader>
          <VerticalSeparator />
          <TableHeader width="15rem">Saída</TableHeader>
          <VerticalSeparator />
          <TableHeader width="9.1875rem">Folga</TableHeader>
        </TableHead>

        {workTime.map(workDay => (
          <EditTableRow
            changeEndHour={changeEndHour}
            changeEndMinute={changeEndMinute}
            changeHoliday={changeHoliday}
            changeStartHour={changeStartHour}
            changeStartMinute={changeStartMinute}
            endHour={
              workDay.finish_hour < 10
                ? `0${workDay.finish_hour}`
                : `${workDay.finish_hour}`
            }
            endMinute={
              workDay.finish_minute < 10
                ? `0${workDay.finish_minute}`
                : `${workDay.finish_minute}`
            }
            holiday={!workDay.is_work_day}
            id={workDay.week_day}
            startHour={
              workDay.start_hour < 10
                ? `0${workDay.start_hour}`
                : `${workDay.start_hour}`
            }
            startMinute={
              workDay.start_minute < 10
                ? `0${workDay.start_minute}`
                : `${workDay.start_minute}`
            }
            changeValidRow={changeValidRows}
          />
        ))}
      </Table>

      <SaveButton onClick={saveWorkTime}>Salvar</SaveButton>
    </>
  );
}
