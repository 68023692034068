import Swal from 'sweetalert2';

interface IRequest {
  tj: string | null;
  ticket_pipeline_id: string;
  possibleBuy: string;
  closed_date: Date;
  product: string;
  profile: string;
}

const allowedPipelinesForScheduling: string[] = [
  '2945972' /* 1ª Passagem */,
  '9536414' /* 1ª PASSAGEM (ESTADUAL-MUNICIPAL) */,
  '2154049' /* Retomada */,
  '9528351' /* RETOMADA (ESTADUAL-MUNICIPAL) */,
  '37781341' /* Qualificação - Novo Processo */,
];
/**
 * Verifica se pode ser realizado um agendamento com determinado deal.
 * Retorna true se o agendamento pode ser feito e false caso contrário
 */
export default async function verifyDealTj(data: IRequest): Promise<boolean> {
  if (
    data.tj &&
    data.tj !== 'TJSP' &&
    data.tj !== 'TJSC' &&
    data.tj !== 'TJRJ' &&
    data.tj !== 'TJTO' &&
    data.tj !== 'TJRR' &&
    data.tj !== 'TJAP' &&
    data.tj !== 'TJAL' &&
    data.product !== 'FEDERAL'
  ) {
    Swal.fire(
      'Atenção!',
      `Caso ESTADUAL fora dos estados possíveis, procure a liderança para entender o processo`,
      'warning',
    );

    return false;
  }

  if (!allowedPipelinesForScheduling.includes(data.ticket_pipeline_id)) {
    if (data.profile !== 'Hunter Líder') {
      Swal.fire(
        'Atenção!',
        `Ticket fora das pipelines de agendamento!`,
        'warning',
      );
      return false;
    }
  }

  if (data.possibleBuy === 'Não') {
    Swal.fire(
      'Atenção!',
      `CAMPO PASSÍVEL DE COMPRA PREENCHIDO COMO NÃO. CONFERIR NO HUBSPOT - NÃO É POSSIVEL A COMPRA!`,
      'warning',
    );

    return false;
  }

  if (data.closed_date !== null) {
    Swal.fire(
      'Atenção!',
      `TICKET FECHADO. CONFERIR NO HUBSPOT - NÃO É POSSÍVEL REALIZAR REGISTROS OU ALTERAÇÕES`,
      'warning',
    );

    return false;
  }
  return true;
}
