import styled from 'styled-components';

export const StyleModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },

  content: {
    height: '24rem',
    width: '40rem',
    margin: 'auto',
    // padding: '0 0 1rem 0',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },
};
export const Tbody = styled.tbody`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  width: 100%;
  gap: 0.125rem;
  background: var(--gray-100);

  .body-row {
    height: 1.563rem;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;

  width: 100%;

  .buttons-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Helvetica', sans-serif;
`;
