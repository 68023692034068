import styled, { css } from 'styled-components';

interface Props {
  separatorWidth?: number;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media print {
    display: none;
  }
`;

export const Divisor = styled.hr<Props>`
  ${props =>
    props.separatorWidth
      ? css`
          width: ${props.separatorWidth}rem;
        `
      : css`
          width: 16rem;
        `}
  @media print {
    display: none;
  }
`;
