import { IAttendanceReportDTO } from '../dtos/IAttendanceReportDTO';

export function removeRepeatedWithHoursDatesInString(
  dates: IAttendanceReportDTO[],
  date_comparate: string,
): IAttendanceReportDTO[] {
  return dates.filter(
    date =>
      date.transferred_at.getHours() === new Date(date_comparate).getHours() &&
      date.transferred_at.getDate() === new Date(date_comparate).getDate() &&
      date.transferred_at.getMonth() === new Date(date_comparate).getMonth() &&
      date.transferred_at.getFullYear() ===
        new Date(date_comparate).getFullYear(),
  );
}
