import styled, { css } from 'styled-components';

interface ProvidenceProps {
  providenceStatus: string;
}

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const ButtonCreateActivity = styled.button`
  width: 9.75rem;
  height: 2.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  border: none;
  border-radius: 0.5rem;
  background: var(--green-500);

  font-weight: 600;
  font-size: 0.813rem;
  color: var(--white);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CalendarPeriodsButtons = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const ActivitiesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;

  width: 100%;
`;

export const TitleBackButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  padding-right: 7%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 7%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 0.5rem;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8125rem;

  cursor: pointer;

  a {
    display: flex;
    color: var(--blue-400);
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const CalendarAndFilters = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 1rem;
`;

export const LabelInput = styled.div`
  display: flex;
  flex-direction: column;

  .larger,
  .smaller {
    padding-left: 0.25rem;

    height: 1.875rem;
    font-size: 0.875rem;

    border-radius: 0.25rem;
    background: var(--gray-100);
    filter: drop-shadow(0 3px 10px rgba(114, 30, 255, 0.1));
  }

  .larger {
    width: 15.875rem;
  }

  .smaller {
    width: 10rem;
  }

  input {
    border: none;
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray-800);
`;

export const Select = styled.select`
  cursor: pointer;

  border: 1px solid var(--blue-300);
`;

export const InfoObsField = styled.input`
  min-width: 400px;
  height: 62px;
  padding: 8px 10px 8px 10px;
  border-radius: 10px;
  gap: 8px;
  background: #e5e5e5;
  border: none;

  font-family: Poppins;
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: -0.015em;

  &:hover {
    border: 1px solid #041524;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 2rem 0;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.3rem;
`;

export const InfoTitle = styled.span`
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.015em;
  text-align: left;
`;

export const InfoAnswer = styled.span`
  font-family: Poppins;
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: -0.015em;
  text-align: left;
`;

export const GoBackLink = styled.a``;

export const PageSelector = styled.button<ProvidenceProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 192px;
  height: 39px;

  font-family: Poppins;
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: -0.015em;
  text-align: center;
  background: #fff;
  border-left: none;
  border-top: none;
  border-right: none;

  border-radius: 8px;

  ${props =>
    props.providenceStatus === 'Providências'
      ? css`
          background: rgba(207, 222, 243, 0.83);

          color: #05233e;
        `
      : css`
          background: #fff;
          margin-bottom: 0.2rem;
        `}
`;

export const PageSelectorAct = styled.button<ProvidenceProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 192px;
  height: 39px;

  font-family: Poppins;
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: -0.015em;
  text-align: center;
  background: #fff;

  border-left: none;
  border-top: none;
  border-right: none;

  border-radius: 8px;

  ${props =>
    props.providenceStatus === 'Atividades'
      ? css`
          background: rgba(207, 222, 243, 0.83);

          color: #05233e;
        `
      : css`
          background: #fff;
        `}
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 0.5rem;
  width: 71px;
  height: 22px;
  padding: 10px 16px 10px 16px;
  border-radius: 5px;
  gap: 10px;
  border: none;
  background: #014077;
  padding: 0.5rem;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  color: #fff;

  :disabled {
    opacity: 0;
  }
`;
