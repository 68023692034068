import styled, { css } from 'styled-components';

interface StatusProps {
  statusTaskProps?: string;
  situation?: string;
  isBefore?: boolean;
  talkProps?: string;
  tryTimesProps?: string;
  existTryTimesProps?: boolean;
  statusBusinessTask?: string;
  isColored?: boolean;
}

export const Timetable = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
`;

export const IconHour = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;

  width: 4%;
`;

export const Image = styled.img``;

export const Time = styled.time`
  padding-left: 0.5rem;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.938rem;
  color: #05233e;
`;

export const LineTable = styled.div<StatusProps>`
  background: rgba(22, 101, 216, 0.08);
  border-radius: 0.25rem;
  height: 3.6rem;
  width: 95%;

  margin-left: 0.5rem;
  display: flex;
  align-items: center;

  font-family: Poppins;

  ${props =>
    props.statusTaskProps === 'Concluída' && props.isColored
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: inset 0.188rem 0 0 #3ca647,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.statusTaskProps === 'Pendente' &&
        (!props.tryTimesProps ||
          props.tryTimesProps === '0' ||
          props.tryTimesProps === 'null') &&
        props.isColored
      ? css`
          background: rgba(236, 68, 68, 0.25);
          box-shadow: inset 0.188rem 0 0 #de0c0c,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : props.statusTaskProps === 'Pendente' && Number(props.tryTimesProps) > 0
      ? css`
          background: rgba(180, 173, 9, 0.25);
          box-shadow: inset 0.188rem 0 0 #b4ad09,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `
      : css`
          background: rgba(22, 101, 216, 0.06);
          box-shadow: inset 0.188rem 0 0 #1665d8,
            0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        `}

  .closerAvatar {
    min-width: 6%;
  }

  .closer {
    min-width: 18%;
  }

  .value {
    min-width: 14%;
  }

  .connection {
    min-width: 14%;
  }

  .contact {
    min-width: 10%;
  }

  .credor {
    min-width: 22%;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.438rem;
`;

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 10rem;

  img {
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
  }
`;

export const Legend = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  color: #595959;
`;

export const Info = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #041524;

  span {
    display: none;
  }
`;

export const InfoCredor = styled.div`
  p {
    font-size: 0.813rem;
  }

  a {
    font-family: Poppins, sans-serif;
    font-weight: 300;
    color: #1665d8;
  }
`;

export const ButtonSituation = styled.button<StatusProps>`
  width: 5.125rem;
  height: 1.625rem;
  border: none;
  border-radius: 0.25rem;

  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  color: #041524;

  ${props =>
    props.statusTaskProps === 'Concluída'
      ? css`
          background: #3ca647;
        `
      : props.statusTaskProps === 'Tentou'
      ? css`
          background: #b4ad09;
        `
      : css`
          background: #ea3f3f;
        `}
`;

export const LineSeparator = styled.div`
  border-right: 0.063rem solid #9d9d9d;
  height: 3.2rem;
`;

export const SituationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 7.5rem;
`;

export const DailerRecordContainer = styled.div<StatusProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 10rem;

  p {
    ${props =>
      props.statusTaskProps === 'Não atendido'
        ? css`
            color: #b4ad09;
          `
        : props.statusTaskProps === 'Pendente'
        ? css`
            color: #d2321c;
          `
        : css`
            color: #0d901a;
          `}
  }
`;

export const VerticalDivisor = styled.div`
  height: 2rem;
  border: 0.063rem solid #817f7f;
`;

export const AttempsContainer = styled.div<StatusProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  min-width: 7.5rem;

  p {
    ${props =>
      props.statusTaskProps === 'Não atendido'
        ? css`
            color: #b4ad09;
          `
        : props.statusTaskProps === 'Pendente'
        ? css`
            color: #d2321c;
          `
        : css`
            color: #0d901a;
          `}

    svg {
      margin-left: 0.375rem;
    }
  }
`;

export const ValueColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;

  width: 11rem;
`;
