import { useCallback, useEffect, useState } from 'react';
import { addDays, format, isBefore, subDays } from 'date-fns';

import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/schedule_left_arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/icons/schedule_right_arrow.svg';

import {
  Content,
  ChangeDateButton,
  ScheduleContainer,
  WeekdayContainer,
  HorarysContainer,
  Weekday,
  Hour,
  CardActivity,
} from './styles';

import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';
import { IDayHoursDTO } from '../dtos/IDayHoursDTO';
import { IActivityCSDTO } from '../dtos/IActivityCSDTO';
import { ModalUpdateActivity } from '../ModalUpdateActivity';

interface WeeklyScheduleProps {
  weekdays: Date[];
  setWeekdays: (weekdays: Date[]) => void;
  activitiesInAWeek: IActivitiesInADayDTO;
  setActivitiesInAWeek: (activityInAWeek: IActivitiesInADayDTO) => void;
}

export function WeeklySchedule({
  weekdays,
  setWeekdays,
  activitiesInAWeek,
  setActivitiesInAWeek,
}: WeeklyScheduleProps): JSX.Element {
  const [mondayActivities, setMondayActivities] = useState<IDayHoursDTO[]>(
    [] as IDayHoursDTO[],
  );
  const [tuesdayActivities, setTuesdayActivities] = useState<IDayHoursDTO[]>(
    [] as IDayHoursDTO[],
  );
  const [wednesdayActivities, setWednesdayActivities] = useState<
    IDayHoursDTO[]
  >([] as IDayHoursDTO[]);
  const [thursdayActivities, setThursdayActivities] = useState<IDayHoursDTO[]>(
    [] as IDayHoursDTO[],
  );
  const [fridayActivities, setFridayActivities] = useState<IDayHoursDTO[]>(
    [] as IDayHoursDTO[],
  );

  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<IActivityCSDTO>(
    {} as IActivityCSDTO,
  );

  useEffect(() => {
    if (activitiesInAWeek.activities) {
      let activities = activitiesInAWeek.activities.filter(
        activity => new Date(activity.hour).getDate() === weekdays[0].getDate(),
      );
      setMondayActivities(activities);

      activities = activitiesInAWeek.activities.filter(
        activity => new Date(activity.hour).getDate() === weekdays[1].getDate(),
      );
      setTuesdayActivities(activities);

      activities = activitiesInAWeek.activities.filter(
        activity => new Date(activity.hour).getDate() === weekdays[2].getDate(),
      );
      setWednesdayActivities(activities);

      activities = activitiesInAWeek.activities.filter(
        activity => new Date(activity.hour).getDate() === weekdays[3].getDate(),
      );
      setThursdayActivities(activities);

      activities = activitiesInAWeek.activities.filter(
        activity => new Date(activity.hour).getDate() === weekdays[4].getDate(),
      );
      setFridayActivities(activities);
    }
  }, [activitiesInAWeek.activities, weekdays]);

  const getLocaleStringWeekday = useCallback((weekday: Date) => {
    return (
      weekday
        .toLocaleString('default', { weekday: 'long' })
        .charAt(0)
        .toUpperCase() +
      weekday.toLocaleString('default', { weekday: 'long' }).slice(1)
    );
  }, []);

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  const handleChangeWeek = useCallback(
    (period: string) => {
      const week: Date[] = [];

      if (period === 'before') {
        weekdays.forEach(weekday => {
          week.push(subDays(weekday, 7));
        });
      } else {
        weekdays.forEach(weekday => {
          week.push(addDays(weekday, 7));
        });
      }

      setWeekdays(week);
    },
    [setWeekdays, weekdays],
  );

  const handleCloseActivityModal = useCallback(() => {
    setOpenActivityModal(false);
  }, []);

  const handleOpenActivityModal = useCallback((activity: IActivityCSDTO) => {
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  }, []);

  return (
    <Content>
      <ChangeDateButton onClick={() => handleChangeWeek('before')}>
        <LeftArrowIcon />
      </ChangeDateButton>

      <ScheduleContainer>
        <WeekdayContainer>
          {weekdays.length > 0 &&
            weekdays.map(weekday => (
              <Weekday
                key={String(weekday)}
                selectedDay={new Date().getDate() === weekday.getDate()}
              >
                <span>{getLocaleStringWeekday(weekday)}</span>
                <span>{weekday.getDate()}</span>
              </Weekday>
            ))}
        </WeekdayContainer>

        <HorarysContainer>
          <div>
            {mondayActivities &&
              mondayActivities.map(monday => (
                <Hour key={String(monday.hour)}>
                  {format(new Date(monday.hour), 'HH:mm')}
                </Hour>
              ))}
          </div>

          <div>
            {mondayActivities &&
              mondayActivities.map(monday =>
                monday.activities.length > 0 ? (
                  <CardActivity
                    key={`${String(monday.hour)}monday`}
                    isBefore={
                      monday.activities[0].time_init
                        ? verifyIsBeforeNow(monday.activities[0].time_init)
                        : verifyIsBeforeNow(monday.activities[0].timestamp)
                    }
                    statusTask={monday.activities[0].situation || 'Pendente'}
                    onClick={() =>
                      handleOpenActivityModal(monday.activities[0])
                    }
                  >
                    {monday.activities[0].type ||
                      monday.activities[0].name.charAt(0).toUpperCase() +
                        monday.activities[0].name.slice(1).toLowerCase()}
                  </CardActivity>
                ) : (
                  <CardActivity />
                ),
              )}
          </div>

          <div>
            {tuesdayActivities &&
              tuesdayActivities.map(tuesday =>
                tuesday.activities.length > 0 ? (
                  <CardActivity
                    key={`${String(tuesday.hour)}tuesday`}
                    isBefore={
                      tuesday.activities[0].time_init
                        ? verifyIsBeforeNow(tuesday.activities[0].time_init)
                        : verifyIsBeforeNow(tuesday.activities[0].timestamp)
                    }
                    statusTask={tuesday.activities[0].situation || 'Pendente'}
                    onClick={() =>
                      handleOpenActivityModal(tuesday.activities[0])
                    }
                  >
                    {tuesday.activities[0].type ||
                      tuesday.activities[0].name.charAt(0) +
                        tuesday.activities[0].name.slice(1).toLowerCase()}
                  </CardActivity>
                ) : (
                  <CardActivity />
                ),
              )}
          </div>

          <div>
            {wednesdayActivities &&
              wednesdayActivities.map(wednesday =>
                wednesday.activities.length > 0 ? (
                  <CardActivity
                    key={`${String(wednesday.hour)}wednesday`}
                    isBefore={
                      wednesday.activities[0].time_init
                        ? verifyIsBeforeNow(wednesday.activities[0].time_init)
                        : verifyIsBeforeNow(wednesday.activities[0].timestamp)
                    }
                    statusTask={wednesday.activities[0].situation || 'Pendente'}
                    onClick={() =>
                      handleOpenActivityModal(wednesday.activities[0])
                    }
                  >
                    {wednesday.activities[0].type ||
                      wednesday.activities[0].name.charAt(0) +
                        wednesday.activities[0].name.slice(1).toLowerCase()}
                  </CardActivity>
                ) : (
                  <CardActivity />
                ),
              )}
          </div>

          <div>
            {thursdayActivities &&
              thursdayActivities.map(thursday =>
                thursday.activities.length > 0 ? (
                  <CardActivity
                    key={`${String(thursday.hour)}thursday`}
                    isBefore={
                      thursday.activities[0].time_init
                        ? verifyIsBeforeNow(thursday.activities[0].time_init)
                        : verifyIsBeforeNow(thursday.activities[0].timestamp)
                    }
                    statusTask={thursday.activities[0].situation || 'Pendente'}
                    onClick={() =>
                      handleOpenActivityModal(thursday.activities[0])
                    }
                  >
                    {thursday.activities[0].type ||
                      thursday.activities[0].name.charAt(0) +
                        thursday.activities[0].name.slice(1).toLowerCase()}
                  </CardActivity>
                ) : (
                  <CardActivity />
                ),
              )}
          </div>

          <div>
            {fridayActivities &&
              fridayActivities.map(friday =>
                friday.activities.length > 0 ? (
                  <CardActivity
                    key={`${String(friday.hour)}friday`}
                    isBefore={
                      friday.activities[0].time_init
                        ? verifyIsBeforeNow(friday.activities[0].time_init)
                        : verifyIsBeforeNow(friday.activities[0].timestamp)
                    }
                    statusTask={friday.activities[0].situation || 'Pendente'}
                    onClick={() =>
                      handleOpenActivityModal(friday.activities[0])
                    }
                  >
                    {friday.activities[0].type ||
                      friday.activities[0].name.charAt(0) +
                        friday.activities[0].name.slice(1).toLowerCase()}
                  </CardActivity>
                ) : (
                  <CardActivity />
                ),
              )}
          </div>
        </HorarysContainer>
      </ScheduleContainer>

      <ChangeDateButton onClick={() => handleChangeWeek('after')}>
        <RightArrowIcon />
      </ChangeDateButton>

      {Object.keys(selectedActivity).length > 0 && (
        <ModalUpdateActivity
          isOpen={openActivityModal}
          isProvidence={false}
          onRequestClose={handleCloseActivityModal}
          activity={selectedActivity}
          activitiesInADay={activitiesInAWeek}
          setActivitiesInADay={setActivitiesInAWeek}
          updateListAcitivity={undefined}
        />
      )}
    </Content>
  );
}
