import styled, { css } from 'styled-components';

interface ColorPanelProps {
  quantidade?: number;
}

interface ICloserAvailable {
  closerAvailable?: boolean;
}
interface TeamsTitleContainerProps {
  isSelected?: boolean;
}

export const GuidesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.625rem;
  margin-top: 1.688rem;
  margin-bottom: 0.938rem;
`;

export const Guide = styled.div<TeamsTitleContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 0.313rem;

  ${props =>
    props.isSelected
      ? css`
    div {
      background: #e8e8e8;

      span:first-child {
      color: #1665d8;
    }

      span:last-child {
        background: #1665d8;
      }
    }

    hr {
      border: 1px solid #1665d8;
    }


  }`
      : ''}
`;

export const TitleGuideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.25rem;
  gap: 0.25rem;
  border-radius: 0.313rem;

  :hover {
    cursor: pointer;
  }
`;

export const Table = styled.table`
  margin-top: 0.763rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(207, 222, 243, 0.83);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;
  padding: 0.25rem 0;

  tr {
    height: 2.5rem;
    box-shadow: none;
  }

  td:nth-child(8) {
    border: none;
  }
`;

export const TableRow = styled.tr<ICloserAvailable>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;
  height: 3.5rem;
  width: 100%;

  ${props =>
    props.closerAvailable
      ? css`
          box-shadow: -2.5px 0 0 #3ca647;
        `
      : css`
          box-shadow: -2.5px 0 0 #de0c0c;
        `}
`;

export const Cell = styled.td<ICloserAvailable>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 0.25rem;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 2.75rem;
    height: 2.75rem;
  }

  ${props =>
    props.closerAvailable
      ? css`
          img {
            border: 0.2rem solid #3ca647;
          }
        `
      : css`
          img {
            border: 0.2rem solid #de0c0c;
          }
        `}

  &:nth-child(1) {
    min-width: 4.8%;
  }

  &:nth-child(2) {
    min-width: 16.4%;
  }

  &:nth-child(3) {
    min-width: 11.5%;
  }

  &:nth-child(4) {
    min-width: 12.2%;
  }

  &:nth-child(5) {
    min-width: 13.2%;
  }

  &:nth-child(6) {
    min-width: 14.2%;
  }

  &:nth-child(7) {
    min-width: 7.9%;
  }

  &:nth-child(8) {
    min-width: 8%;
  }

  &:nth-child(9) {
    min-width: 11.2%;
  }
`;

export const ColoredCell = styled.td<ColorPanelProps>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.25rem 0.25rem 0;

  &:nth-child(1) {
    min-width: 4.8%;
  }

  &:nth-child(2) {
    min-width: 16.4%;
  }

  &:nth-child(3) {
    min-width: 11.5%;
  }

  &:nth-child(4) {
    min-width: 12.2%;
  }

  &:nth-child(5) {
    min-width: 13.2%;
  }

  &:nth-child(6) {
    min-width: 14.2%;
  }

  &:nth-child(7) {
    min-width: 7.9%;
  }

  &:nth-child(8) {
    min-width: 8%;
  }

  &:nth-child(9) {
    min-width: 11.2%;
  }

  ${props =>
    props.quantidade === 0
      ? css`
          background: rgba(222, 12, 12, 0.3);
        `
      : props.quantidade >= 1 && props.quantidade <= 3
      ? css`
          background: rgba(218, 209, 18, 0.3);
        `
      : props.quantidade >= 4
      ? css`
          background: rgba(60, 166, 71, 0.3);
        `
      : css`
          background: #e5e5e5;
        `}
`;

export const ContainerTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.938rem;
  color: #05233e;
`;

export const OrderContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.175rem 0;
`;

export const Tbody = styled.tbody`
  a {
    color: inherit;
    text-decoration: none;
  }

  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 0.938rem;
  line-height: 1.406rem;
  color: #041524;

  tr:nth-child(odd) {
    background: #efefef;
  }

  tr:nth-child(even) {
    background: #e5e5e5;
  }

  tr {
    transition: filter 0.3s;
    :hover {
      filter: brightness(0.9);
    }
  }

  tr {
    margin-bottom: 0.5rem;
  }
`;
