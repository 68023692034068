import { ICloserHistory } from '..';

import {
  ButtonToogle,
  ContainerToogleButtons,
  ContentTable,
  ContentTableData,
  ContentTableItem,
  ContentTableRow,
} from './styles';

interface TableAccompanimentHistoryCloserProps {
  accompanimentButtonSelected: string;
  toogleButtonSelected: string;
  monthlyCloserHistoryMonthOne: ICloserHistory;
  monthlyCloserHistoryMonthTwo: ICloserHistory;
  monthlyCloserHistoryMonthThree: ICloserHistory;
  dailyCloserHistoryDay: ICloserHistory;
  dailyCloserHistoryWeek: ICloserHistory;
  currentFirstTableData: ICloserHistory;
  setToogleButtonSelected: (toogleButton: string) => void;
  setCurrentFirstTableData: (current: ICloserHistory) => void;
}

export function TableAccompanimentHistoryCloser({
  accompanimentButtonSelected,
  currentFirstTableData,
  monthlyCloserHistoryMonthOne,
  monthlyCloserHistoryMonthTwo,
  monthlyCloserHistoryMonthThree,
  dailyCloserHistoryDay,
  dailyCloserHistoryWeek,
  toogleButtonSelected,
  setCurrentFirstTableData,
  setToogleButtonSelected,
}: TableAccompanimentHistoryCloserProps): JSX.Element {
  return (
    <ContentTableData>
      {accompanimentButtonSelected === 'Mensal' ? (
        <ContainerToogleButtons toogleButtonSelected={toogleButtonSelected}>
          <ButtonToogle
            className="first-toogle"
            onClick={() => {
              setToogleButtonSelected('1');
              setCurrentFirstTableData(monthlyCloserHistoryMonthOne);
            }}
          >
            1º
          </ButtonToogle>
          <ButtonToogle
            className="second-toogle"
            onClick={() => {
              setToogleButtonSelected('2');
              setCurrentFirstTableData(monthlyCloserHistoryMonthTwo);
            }}
          >
            2º
          </ButtonToogle>
          <ButtonToogle
            className="third-toogle"
            onClick={() => {
              setToogleButtonSelected('3');
              setCurrentFirstTableData(monthlyCloserHistoryMonthThree);
            }}
          >
            3º
          </ButtonToogle>
        </ContainerToogleButtons>
      ) : (
        <ContainerToogleButtons toogleButtonSelected={toogleButtonSelected}>
          <ButtonToogle
            className="day-toogle"
            onClick={() => {
              setToogleButtonSelected('DIA');
              setCurrentFirstTableData(dailyCloserHistoryDay);
            }}
          >
            DIA
          </ButtonToogle>
          <ButtonToogle
            className="week-toogle"
            onClick={() => {
              setToogleButtonSelected('SEMANA');
              setCurrentFirstTableData(dailyCloserHistoryWeek);
            }}
          >
            SEMANA
          </ButtonToogle>
        </ContainerToogleButtons>
      )}

      <ContentTable>
        <ContentTableRow>
          <ContentTableItem className="first-table-item">
            CNX. LÍQ.
          </ContentTableItem>
          <ContentTableItem className="second-table-item">
            {currentFirstTableData.liquid_conection}
          </ContentTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentTableItem className="first-table-item">
            PROP.
          </ContentTableItem>
          <ContentTableItem className="second-table-item">
            {currentFirstTableData.proposal}
          </ContentTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentTableItem className="first-table-item">NEG.</ContentTableItem>
          <ContentTableItem className="second-table-item">
            {currentFirstTableData.negociation}
          </ContentTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentTableItem className="first-table-item">FEC.</ContentTableItem>
          <ContentTableItem className="second-table-item">
            {currentFirstTableData.closed}
          </ContentTableItem>
        </ContentTableRow>
        <ContentTableRow>
          <ContentTableItem className="first-table-item">
            BAST.
          </ContentTableItem>
          <ContentTableItem className="last-second-table-item">
            {currentFirstTableData.baston}
          </ContentTableItem>
        </ContentTableRow>
      </ContentTable>
      <ContentTable className="tkc-row">
        <ContentTableRow>
          <ContentTableItem className="first-solo-item">
            TKC MÉDIO.
          </ContentTableItem>
          <ContentTableItem className="second-solo-item">
            {currentFirstTableData.ticket}
          </ContentTableItem>
        </ContentTableRow>
      </ContentTable>
    </ContentTableData>
  );
}
