import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const ButtonCreateActivity = styled.button`
  width: 9.75rem;
  height: 2.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  border: none;
  border-radius: 0.5rem;
  background: var(--green-500);

  font-weight: 600;
  font-size: 0.813rem;
  color: var(--white);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CalendarPeriodsButtons = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const ActivitiesContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

export const TitleBackButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  padding-right: 7%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 7%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 0.5rem;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8125rem;

  cursor: pointer;

  a {
    display: flex;
    color: var(--blue-400);
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const CalendarAndFilters = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 1rem;
`;

export const LabelInput = styled.div`
  display: flex;
  flex-direction: column;

  .larger,
  .smaller {
    padding-left: 0.25rem;

    height: 1.875rem;
    font-size: 0.875rem;

    border-radius: 0.25rem;
    background: var(--gray-100);
    filter: drop-shadow(0 3px 10px rgba(114, 30, 255, 0.1));
  }

  .larger {
    width: 15.875rem;
  }

  .smaller {
    width: 10rem;
  }

  input {
    border: none;
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray-800);
`;

export const Select = styled.select`
  cursor: pointer;

  border: 1px solid var(--blue-300);
`;

export const Input = styled.input``;

export const GoBackLink = styled.a``;
