import styled from 'styled-components';
import { pxToRem } from '../../../../utils/pxToRem';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(10)};

  width: 28%;
  height: 44.5rem;
`;

export const Title = styled.span`
  width: 100%;
  height: ${pxToRem(30)};

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--gray-100);
  border-radius: ${pxToRem(2)};

  font-weight: 600;
  font-size: ${pxToRem(14)};
  color: var(--blue-800);
`;

export const ActivitiesContainer = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;

  background: rgba(22, 101, 216, 0.05);
  border-radius: 0.125rem;
  padding: 1rem;
`;

export const SearchByNameContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  width: 100%;
  height: 2.125rem;
  padding: 0 0.688rem;
  margin-bottom: ${pxToRem(5)};

  background: var(--gray-200);
  border-radius: 1.25rem;
`;

export const InputSearch = styled.input`
  font-weight: 300;
  font-size: 0.75rem;
  color: var(--gray-600);
  border-radius: 1.25rem;
  background: var(--gray-200);
  border: none;

  height: 2.125rem;
  width: 95%;
`;

export const ActivityCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: ${pxToRem(75)};

  background: rgba(61, 61, 61, 0.1);
  box-shadow: inset 0.188rem 0 0 var(--gray-800),
    0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0 0.25rem 0.25rem 0;
  padding: ${pxToRem(6)};
  margin-top: ${pxToRem(23)};

  .bigger {
    font-weight: 500;
    font-size: ${pxToRem(12)};
  }

  .smaller {
    font-weight: 600;
    font-size: ${pxToRem(8)};
  }
`;

export const InfosActivity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: ${pxToRem(55)};
    height: ${pxToRem(55)};
  }
`;
