import styled, { css } from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';

export const Container = {
  content: {
    width: '49rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
    alignItems: 'center',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const Header = styled.div`
  width: 100%;
  height: 3.125rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.625rem 0.625rem 0 0;
  padding: 0 1.5rem;

  font-weight: 600;
  font-size: 1.25rem;
  color: var(--white);

  background-color: var(--blue-500);
`;

export const ButtonsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

interface EditButtonProps {
  editFields?: boolean;
}

export const EditButton = styled.button<EditButtonProps>`
  display: flex;

  border: none;
  background: none;

  ${props =>
    props.editFields &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      width: 10.375rem;
      height: 1.75rem;

      border-radius: 0.25rem;
      background: var(--white);

      font-weight: 600;
      font-size: 1rem;
      color: var(--blue-400);
    `}
`;

export const CloseButton = styled.button`
  display: flex;
  border: none;
  background: none;
`;

export const Main = styled.div`
  display: flex;
  height: fit-content;
  max-height: ${pxToRem(550)};

  overflow: auto;

  flex-direction: column;

  padding: 1rem 1.5rem;

  .observation-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .hour-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .checklist {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ProvidenceAvailableHours = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.5rem;
`;

interface TypeActivityProps {
  isEditable: boolean;
}

export const TypeActivity = styled.input<TypeActivityProps>`
  width: fit-content;
  font-weight: 700;
  font-size: 1rem;
  color: var(--blue-800);

  ${props =>
    props.isEditable
      ? css`
          border: 0.5px solid var(--blue-600);
          background: var(--gray-100);
          border-radius: 0.25rem;
          padding-left: 0.25rem;
        `
      : css`
          border: none;
          background: none;
        `}
`;

interface LabelInputContainerProps {
  status?: string;
  editFields?: boolean;
  stateChecklist?: boolean;
  selectField?: boolean;
  textAreaField?: boolean;
}

export const LabelInputContainer = styled.div<LabelInputContainerProps>`
  display: flex;
  align-items: center;

  margin-top: 1rem;
  gap: 0.125rem;

  img {
    cursor: pointer;

    ${props =>
      props.stateChecklist
        ? css`
            transform: rotate(90deg);
          `
        : css`
            transform: rotate(0);
          `}
  }

  .stage-input {
    width: auto;
    min-width: min-content;
    max-width: max-content;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    background: var(--gray-200);
  }

  #status,
  #interested-selling {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 5.125rem;
    height: 1.625rem;
    border-radius: 0.25rem;

    margin-left: 0.8rem;

    color: var(--white);
    font-size: 0.75rem;
    font-weight: 400;
  }

  #intervalText {
    font-weight: 500;
    font-size: 0.75rem;
    color: var(--gray-800);

    margin: 0 0.375rem;
  }

  ${props =>
    props.editFields && !props.selectField
      ? css`
          margin: 0;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 1rem;

          textarea {
            border: 0.5px solid var(--blue-600);
            resize: none;

            max-height: 10rem;
          }
        `
      : // : props.editFields && props.selectField
      // ? css`
      //     margin: 0;
      //     flex-direction: row;
      //     align-items: center;
      //     margin-top: 1rem;
      //   `
      props.selectField || props.textAreaField
      ? css`
          flex-direction: column;
          align-items: flex-start;
          textarea {
            resize: none;
            text-align: start;

            max-height: 10rem;
          }
        `
      : css`
          textarea {
            resize: none;

            max-height: 10rem;
          }
        `}
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-800);
  margin-right: 0.2rem;
`;

export const LabelChecklist = styled.label`
  font-weight: 600;
  font-size: ${pxToRem(16)};

  color: #368eda;

  cursor: pointer;

  padding-right: ${pxToRem(5)};
`;

export const Input = styled.input`
  height: 1.5rem;
  flex: 1;
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray-800);

  border: none;
  background: none;
`;

export const Span = styled.span`
  height: 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: var(--gray-800);
  margin-left: 0.5rem;
`;

export const Link = styled.a`
  font-weight: 500;
  font-size: 1rem;
  color: var(--blue-300);
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 4.5rem;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-800);
  text-align: justify;

  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: var(--gray-100);
`;

export const LineSeparator = styled.div`
  border: 1px solid var(--gray-400);
  width: 100%;
  margin-top: 0.85rem;
  margin-bottom: 0.5rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(244, 244, 245, 1);

  div input {
    background: rgba(244, 244, 245, 1);
    border: none;

    width: 10rem;
    height: 1rem;

    color: var(--blue-700);
  }
`;

export const ContainerInputDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.031rem solid var(--blue-600);
  border-radius: 0.25rem;
  padding-left: 0.5rem;

  input {
    padding-left: 1.196rem;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const Select = styled.select`
  width: 20rem;
  height: 2.5rem;

  background: var(--gray-100);
  border: 0.031rem solid var(--blue-600);
  box-sizing: border-box;
  border-radius: 0.25rem;

  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--gray-800);
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-weight: 600;
  font-size: ${pxToRem(14)};

  margin-top: 0.5rem;
`;

export const CheckboxInput = styled.input`
  width: 0.875rem;
  height: 0.875rem;

  cursor: pointer;
`;

export const ContainerNote = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 1rem;

  font-size: 1rem;
  font-weight: 600;

  color: var(--blue-800);
`;

export const ContainerCheckbox = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  width: 33.188rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input + label {
    position: relative;
    cursor: pointer;
    padding-left: 1.875rem;
  }

  input[type='radio'] + label::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0.1rem;
    width: 0.938rem;
    height: 0.938rem;
    border-radius: 50%;
    border: 1px solid #1665d8;
  }

  input[type='radio']:checked + label::after {
    position: absolute;
    content: '';
    background-color: #1665d8;
    width: 0.55rem;
    height: 0.55rem;
    border-radius: 50%;
    left: 0.25rem;
    bottom: 0.35rem;
  }
`;

export const ContainerInputCheckbox = styled.div``;

export const InputCheckbox = styled.input`
  margin-right: 0.625rem;
`;

export const LabelCheckbox = styled.label`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  color: var(--gray-800);
`;

export const ContainerDayPicker = styled.div`
  display: flex;
  flex-direction: column;

  width: 18.125rem;

  background: var(--gray-100);

  div input {
    background: var(--gray-100);
    border: none;

    width: 15.5rem;
    height: 1.875rem;

    color: var(--blue-700);
  }
`;

export const ButtonDeprioritize = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  border: none;
  border-radius: ${pxToRem(4)};

  height: ${pxToRem(50)};
  width: ${pxToRem(200)};

  color: var(--white);
  background: var(--red-500);

  font-size: 1rem;
`;

export const ButtonAddDateLastContact = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  border: none;
  border-radius: ${pxToRem(4)};

  height: ${pxToRem(40)};
  width: ${pxToRem(150)};

  color: var(--white);
  background: var(--blue-800);

  font-size: 1rem;
`;
