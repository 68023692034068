import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Container } from '../../../../components/Container';
import { Title } from '../../../../components/Styles/Title';
import { Body } from '../../../../components/Styles/Body';
import {
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';

import {
  BackButtonContainer,
  ApprovedField,
  ApprovedResField,
  DeniedField,
  ReturnButtonContainer,
} from './styles';
import { IPricingPlaces } from '../..';
import { useAuth } from '../../../../hooks/auth';
import { ConclusionModal } from '../../ConclusionModal';
import { formatStringNormal } from '../../utils/formatStringNormal';
import api from '../../../../services/api';
import { formatStringLOA } from '../../utils/formatStringLOA';
import {
  DivSeparator,
  Separator,
  Table,
  TableRow,
  Thead,
  HeadCell,
  Tbody,
  BodyCell,
  SendButtonContainer,
  ButtonsContainer,
  InputRes,
  Select,
  Option,
  ShowAnswer,
  AddDescriptionButton,
  ShowInputRes,
} from '../styles';
import { DescriptionModal } from '../../DescriptionModal';

interface DefaultFormData {
  lu_volume: Date | string;
  nu_volume: Date | string;
  lu_expedition_percent: Date | string;
  nu_expedition_percent: Date | string;
  lu_general_regime: Date | string;
  nu_general_regime: Date | string;
  lu_politic: Date | string;
  nu_politic: Date | string;
  lu_upper_change: Date | string;
  nu_upper_change: Date | string;
  lu_election_period: Date | string;
  nu_election_period: Date | string;
  lu_rating: Date | string;
  nu_rating: Date | string;
}
interface FormData {
  id: string;
  approved: string;
  place_id: string;
  prospect: string;
  lu_prospect: string;
  nu_prospect: string;

  prospect_automatization: string;
  lu_prospect_automatization: string;
  nu_prospect_automatization: string;

  rcl_percent: string;
  lu_rcl_percent: string;
  nu_rcl_percent: string;

  regime_time: string;
  lu_regime_time: string;
  nu_regime_time: string;

  deposit_term: string;
  lu_deposit_term: string;
  nu_deposit_term: string;

  payment_timing: string;
  lu_payment_timing: string;
  nu_payment_timing: string;

  creditor_timing: string;
  lu_creditor_timing: string;
  nu_creditor_timing: string;

  mapped: string;
  lu_mapped: string;
  nu_mapped: string;

  payment_term_01: string;
  payment_term_02: string;
  payment_term_03: string;
  lu_payment_term: string;
  nu_payment_term: string;

  loa_payment_term_01: string;
  loa_payment_term_02: string;
  loa_payment_term_03: string;

  final_term_01: string;
  final_term_02: string;
  final_term_03: string;
  lu_final_term: string;
  nu_final_term: string;

  loa_final_term_01: string;
  loa_final_term_02: string;
  loa_final_term_03: string;

  volume: string;
  lu_volume: string;
  nu_volume: string;

  expedition_percent: string;
  lu_expedition_percent: string;
  nu_expedition_percent: string;

  general_regime: string;
  lu_general_regime: string;
  nu_general_regime: string;

  politic: string;
  lu_politic: string;
  nu_politic: string;

  upper_change: string;
  lu_upper_change: string;
  nu_upper_change: string;

  election_period: string;
  lu_election_period: string;
  nu_election_period: string;

  rating: string;
  lu_rating: string;
  nu_rating: string;

  competidor_one: string;
  competidor_two: string;
  competidor_three: string;

  prices_table_one_01: string;
  prices_table_one_02: string;
  prices_table_one_03: string;
  prices_table_two_01: string;
  prices_table_two_02: string;
  prices_table_two_03: string;
  prices_table_three_01: string;
  prices_table_three_02: string;
  prices_table_three_03: string;

  loa_prices_table_01: string;
  loa_prices_table_02: string;
  loa_prices_table_03: string;

  observation_field: string;

  prospect_description: string;
  prospect_automatization_description: string;
  rcl_percent_description: string;
  regime_time_description: string;
  deposit_term_description: string;
  payment_timing_description: string;
  creditor_timing_description: string;
  mapped_description: string;
  volume_description: string;
  expedition_percent_description: string;
  general_regime_description: string;
  politic_description: string;
  upper_change_description: string;
  election_period_description: string;
  rating_description: string;
  payment_term_description: string;
  final_term_description: string;

  prospect_file: IFileProps;
  prospect_automatization_file: IFileProps;
  rcl_percent_file: IFileProps;
  regime_time_file: IFileProps;
  deposit_term_file: IFileProps;
  payment_timing_file: IFileProps;
  creditor_timing_file: IFileProps;
  mapped_file: IFileProps;
  volume_file: IFileProps;
  expedition_percent_file: IFileProps;
  general_regime_file: IFileProps;
  politic_file: IFileProps;
  upper_change_file: IFileProps;
  election_period_file: IFileProps;
  rating_file: IFileProps;
  payment_term_file: IFileProps;
  final_term_file: IFileProps;
}
interface SecondFormPageProps {
  state: IPricingPlaces;
  formData: FormData;
  description: FormData | undefined;
  dataDownload: any;
}

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export function SecondFormPage(): JSX.Element {
  const [openConclusionModal, setOpenConclusionModal] = useState(false);
  const [defaultFormData, setDefaultFormData] = useState<DefaultFormData>(
    {} as DefaultFormData,
  );
  const [inputFormData, setInputFormData] = useState<FormData>({} as FormData);
  const [inputDescription, setInputDescription] = useState<FormData>(
    {} as FormData,
  );

  const [descriptionName, setDescriptionName] = useState<string>('');
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [dataFileName, setDataFileName] = useState<string>('');

  const { user } = useAuth();

  const history = useHistory();
  const location = useLocation();
  const props = location.state as SecondFormPageProps;

  const { state, formData, dataDownload } = props;

  const setModal = useCallback((name: string, fileName: string) => {
    setDataFileName(fileName);
    setDescriptionName(name);
    setOpenDescriptionModal(true);
  }, []);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;
      setInputFormData({ ...inputFormData, [name]: value });
      if (name.includes('lu') || name.includes('nu')) {
        setInputFormData({ ...inputFormData, [name]: `${value} 3:00` });
      }
    },
    [inputFormData],
  );

  const handleSubmit = useCallback(async () => {
    try {
      await api.post('/pricing/forms', {
        place_id: state.id,
        prospect: inputFormData.prospect,
        lu_prospect: inputFormData.lu_prospect,
        nu_prospect: inputFormData.nu_prospect,

        prospect_automatization: inputFormData.prospect_automatization,
        lu_prospect_automatization: inputFormData.lu_prospect_automatization,
        nu_prospect_automatization: inputFormData.nu_prospect_automatization,

        rcl_percent: inputFormData.rcl_percent,
        lu_rcl_percent: inputFormData.lu_rcl_percent,
        nu_rcl_percent: inputFormData.nu_rcl_percent,

        regime_time: inputFormData.regime_time,
        lu_regime_time: inputFormData.lu_regime_time,
        nu_regime_time: inputFormData.nu_regime_time,

        deposit_term: inputFormData.deposit_term,
        lu_deposit_term: inputFormData.lu_deposit_term,
        nu_deposit_term: inputFormData.nu_deposit_term,

        payment_timing: inputFormData.payment_timing,
        lu_payment_timing: inputFormData.lu_payment_timing,
        nu_payment_timing: inputFormData.nu_payment_timing,

        creditor_timing: inputFormData.creditor_timing,
        lu_creditor_timing: inputFormData.lu_creditor_timing,
        nu_creditor_timing: inputFormData.nu_creditor_timing,

        mapped: inputFormData.mapped,
        lu_mapped: inputFormData.lu_mapped,
        nu_mapped: inputFormData.nu_mapped,

        volume: inputFormData.volume,
        lu_volume: inputFormData.lu_volume,
        nu_volume: inputFormData.nu_volume,

        expedition_percent: inputFormData.expedition_percent,
        lu_expedition_percent: inputFormData.lu_expedition_percent,
        nu_expedition_percent: inputFormData.nu_expedition_percent,

        general_regime: inputFormData.general_regime,
        lu_general_regime: inputFormData.lu_general_regime,
        nu_general_regime: inputFormData.nu_general_regime,

        politic: inputFormData.politic,
        lu_politic: inputFormData.lu_politic,
        nu_politic: inputFormData.nu_politic,

        upper_change: inputFormData.upper_change,
        lu_upper_change: inputFormData.lu_upper_change,
        nu_upper_change: inputFormData.nu_upper_change,

        election_period: inputFormData.election_period,
        lu_election_period: inputFormData.lu_election_period,
        nu_election_period: inputFormData.nu_election_period,

        rating: inputFormData.rating,
        lu_rating: inputFormData.lu_rating,
        nu_rating: inputFormData.nu_rating,
        prospect_description: inputDescription.prospect_description,
        prospect_automatization_description:
          inputDescription.prospect_automatization_description,
        rcl_percent_description: inputDescription.rcl_percent_description,
        regime_time_description: inputDescription.regime_time_description,
        deposit_term_description: inputDescription.deposit_term_description,
        payment_timing_description: inputDescription.payment_timing_description,
        creditor_timing_description:
          inputDescription.creditor_timing_description,
        mapped_description: inputDescription.mapped_description,
        volume_description: inputDescription.volume_description,
        expedition_percent_description:
          inputDescription.expedition_percent_description,
        general_regime_description: inputDescription.general_regime_description,
        politic_description: inputDescription.politic_description,
        upper_change_description: inputDescription.upper_change_description,
        election_period_description:
          inputDescription.election_period_description,
        rating_description: inputDescription.rating_description,
        payment_term_description: inputDescription.payment_term_description,
        final_term_description: inputDescription.final_term_description,

        prospect_file: inputDescription.prospect_file,
        prospect_automatization_file:
          inputDescription.prospect_automatization_file,
        rcl_percent_file: inputDescription.rcl_percent_file,
        regime_time_file: inputDescription.regime_time_file,
        deposit_term_file: inputDescription.deposit_term_file,
        payment_timing_file: inputDescription.payment_timing_file,
        creditor_timing_file: inputDescription.creditor_timing_file,
        mapped_file: inputDescription.mapped_file,
        volume_file: inputDescription.volume_file,
        expedition_percent_file: inputDescription.expedition_percent_file,
        general_regime_file: inputDescription.general_regime_file,
        politic_file: inputDescription.politic_file,
        upper_change_file: inputDescription.upper_change_file,
        election_period_file: inputDescription.election_period_file,
        rating_file: inputDescription.rating_file,
        payment_term_file: inputDescription.payment_term_file,
        final_term_file: inputDescription.final_term_file,

        payment_term: formatStringLOA({
          loa_term_01: inputFormData.loa_payment_term_01,
          loa_term_02: inputFormData.loa_payment_term_02,
          loa_term_03: inputFormData.loa_payment_term_03,
          term_01: inputFormData.payment_term_01,
          term_02: inputFormData.payment_term_02,
          term_03: inputFormData.payment_term_03,
        }),
        lu_payment_term: inputFormData.lu_payment_term,
        nu_payment_term: inputFormData.nu_payment_term,

        final_term: formatStringLOA({
          loa_term_01: inputFormData.loa_final_term_01,
          loa_term_02: inputFormData.loa_final_term_02,
          loa_term_03: inputFormData.loa_final_term_03,
          term_01: inputFormData.final_term_01,
          term_02: inputFormData.final_term_02,
          term_03: inputFormData.final_term_03,
        }),
        lu_final_term: inputFormData.lu_final_term,
        nu_final_term: inputFormData.nu_final_term,

        competidor_one: inputFormData.competidor_one,
        competidor_two: inputFormData.competidor_two,
        competidor_three: inputFormData.competidor_three,

        prices_table_one: formatStringLOA({
          loa_term_01: inputFormData.loa_prices_table_01,
          loa_term_02: inputFormData.loa_prices_table_02,
          loa_term_03: inputFormData.loa_prices_table_03,
          term_01: inputFormData.prices_table_one_01,
          term_02: inputFormData.prices_table_one_02,
          term_03: inputFormData.prices_table_one_03,
        }),
        prices_table_two: formatStringNormal({
          term_01: inputFormData.prices_table_two_01,
          term_02: inputFormData.prices_table_two_02,
          term_03: inputFormData.prices_table_two_03,
        }),
        prices_table_three: formatStringNormal({
          term_01: inputFormData.prices_table_three_01,
          term_02: inputFormData.prices_table_three_02,
          term_03: inputFormData.prices_table_three_03,
        }),
      });

      toast.success('Formulário criado com sucesso!');
      history.push({
        pathname: `/pricing`,
      });
    } catch (error) {
      if (error.response.data.status === 400) {
        Swal.fire('Erro!', error.response.data.message, 'error');
        return;
      }

      toast.error(
        'Não foi possível criar o formulário. Preencha todos os campos!',
      );
    }
  }, [state.id, inputFormData, history, inputDescription]);

  const handleCloseConclusionModal = useCallback(() => {
    setOpenConclusionModal(false);
  }, []);

  const handleCloseDescriptionModal = useCallback(() => {
    setOpenDescriptionModal(false);
  }, []);

  const handleModalSubmit = useCallback(
    (value: string, file: IFileProps) => {
      setOpenDescriptionModal(false);
      setInputFormData({
        ...inputFormData,
        [descriptionName]: value,
        [dataFileName]: file,
      });

      setInputDescription({
        ...inputDescription,
        [descriptionName]: value,
        [dataFileName]: file,
      });
    },
    [inputFormData, descriptionName, inputDescription, dataFileName],
  );

  useEffect(() => {
    setDefaultFormData({
      lu_volume:
        props.formData.lu_volume !== null
          ? new Date(`${props.formData.lu_volume}`)
          : '',
      nu_volume:
        props.formData.nu_volume !== null
          ? new Date(`${props.formData.nu_volume}`)
          : '',
      lu_expedition_percent:
        props.formData.lu_expedition_percent !== null
          ? new Date(`${props.formData.lu_expedition_percent}`)
          : '',
      nu_expedition_percent:
        props.formData.nu_expedition_percent !== null
          ? new Date(`${props.formData.nu_expedition_percent}`)
          : '',
      lu_general_regime:
        props.formData.lu_general_regime !== null
          ? new Date(`${props.formData.lu_general_regime}`)
          : '',
      nu_general_regime:
        props.formData.nu_general_regime !== null
          ? new Date(`${props.formData.nu_general_regime}`)
          : '',
      lu_politic:
        props.formData.lu_politic !== null
          ? new Date(`${props.formData.lu_politic}`)
          : '',
      nu_politic:
        props.formData.nu_politic !== null
          ? new Date(`${props.formData.nu_politic}`)
          : '',
      lu_upper_change:
        props.formData.lu_upper_change !== null
          ? new Date(`${props.formData.lu_upper_change}`)
          : '',
      nu_upper_change:
        props.formData.nu_upper_change !== null
          ? new Date(`${props.formData.nu_upper_change}`)
          : '',
      lu_election_period:
        props.formData.lu_election_period !== null
          ? new Date(`${props.formData.lu_election_period}`)
          : '',
      nu_election_period:
        props.formData.nu_election_period !== null
          ? new Date(`${props.formData.nu_election_period}`)
          : '',
      lu_rating:
        props.formData.lu_rating !== null
          ? new Date(`${props.formData.lu_rating}`)
          : '',
      nu_rating:
        props.formData.nu_rating !== null
          ? new Date(`${props.formData.nu_rating}`)
          : '',
    });
    setInputFormData(formData);
    setInputDescription(props.description);
  }, [props, formData, descriptionName]);

  const formattedTimestamp = useCallback(
    (timestamp: Date | undefined | string) => {
      return timestamp
        ? `${new Date(timestamp).getFullYear()}-${String(
            new Date(timestamp).getMonth() + 1,
          ).padStart(2, '0')}-${String(new Date(timestamp).getDate()).padStart(
            2,
            '0',
          )}`
        : '';
    },
    [],
  );

  const respFormattedTimestamp = useCallback(
    (timestamp: Date | undefined | string) => {
      const formatTimestamp = timestamp === null ? '' : timestamp;
      return formatTimestamp || formatTimestamp !== ''
        ? `${String(new Date(formatTimestamp).getDate()).padStart(
            2,
            '0',
          )}/${String(new Date(formatTimestamp).getMonth() + 1).padStart(
            2,
            '0',
          )}/${new Date(formatTimestamp).getFullYear()}`
        : '';
    },
    [],
  );

  return (
    <Container>
      <Navegation>
        <H1NavigationNow>Precificação</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>
          Precificação de entes - {state.place_name.toUpperCase()}{' '}
          {formData.approved === 'Aprovado' && user.profile === 'CEO' ? (
            <ButtonsContainer>
              <ApprovedField className="IDaprovado">Aprovado</ApprovedField>
            </ButtonsContainer>
          ) : formData.approved === 'Aprovado com ressalva' &&
            user.profile === 'CEO' ? (
            <ButtonsContainer>
              <ApprovedResField>Aprovado com ressalvas</ApprovedResField>
            </ButtonsContainer>
          ) : formData.approved === 'Reprovado' && user.profile === 'CEO' ? (
            <ButtonsContainer>
              <DeniedField>Reprovado</DeniedField>
            </ButtonsContainer>
          ) : (
            <></>
          )}
        </Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <Table>
          <Thead>
            <TableRow>
              <HeadCell>Perguntas</HeadCell>
              <HeadCell>Respostas</HeadCell>
              <HeadCell>Última atualização</HeadCell>
              <HeadCell>Próxima atualização</HeadCell>
            </TableRow>
          </Thead>

          <Tbody>
            <TableRow className="tr-body">
              <BodyCell>Qual o volume de expedição por LOA?</BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.volume}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="volume"
                    onChange={handleInputChange}
                    defaultValue={formData.volume || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_volume"
                    disabled
                    defaultValue={formattedTimestamp(defaultFormData.lu_volume)}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_volume"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(defaultFormData.lu_volume)}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_volume"
                    disabled
                    defaultValue={formattedTimestamp(defaultFormData.nu_volume)}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_volume"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(defaultFormData.nu_volume)}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('volume_description', 'volume_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                Qual o percentual da expedição do ente sobre tudo que foi
                expedido no EST-MUN?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.expedition_percent}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="expedition_percent"
                    onChange={handleInputChange}
                    defaultValue={formData.expedition_percent || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_expedition_percent"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_expedition_percent,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_expedition_percent"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_expedition_percent,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_expedition_percent"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_expedition_percent,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_expedition_percent"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_expedition_percent,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal(
                        'expedition_percent_description',
                        'expedition_percent_file',
                      )
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                Existe projeção para o ente sair do Regime Geral?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.general_regime}</ShowAnswer>
                ) : (
                  <Select name="general_regime" onChange={handleInputChange}>
                    <Option value="">Selecionar</Option>
                    <Option
                      value="Sim"
                      selected={formData.general_regime === 'Sim'}
                    >
                      Sim
                    </Option>
                    <Option
                      value="Não"
                      selected={formData.general_regime === 'Não'}
                    >
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_general_regime"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_general_regime,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_general_regime"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_general_regime,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_general_regime"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_general_regime,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_general_regime"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_general_regime,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal(
                        'general_regime_description',
                        'general_regime_file',
                      )
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>Qual o partido político no controlo do Ente?</BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.politic}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="politic"
                    onChange={handleInputChange}
                    defaultValue={formData.politic || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_politic"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_politic,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_politic"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_politic,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_politic"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_politic,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_politic"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_politic,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('politic_description', 'politic_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                Teve mudança na postura do governo em relação à pagamento de
                precatórios?
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.upper_change}</ShowAnswer>
                ) : (
                  <Select name="upper_change" onChange={handleInputChange}>
                    <Option value="">Selecionar</Option>
                    <Option
                      value="Sim"
                      selected={formData.upper_change === 'Sim'}
                    >
                      Sim
                    </Option>
                    <Option
                      value="Não"
                      selected={formData.upper_change === 'Não'}
                    >
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_upper_change"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_upper_change,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_upper_change"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_upper_change,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_upper_change"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_upper_change,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_upper_change"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_upper_change,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('upper_change_description', 'upper_change_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>
                Terá período eleitoral entre hoje e o prazo de recebimento?
              </BodyCell>

              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.election_period}</ShowAnswer>
                ) : (
                  <Select name="election_period" onChange={handleInputChange}>
                    <Option value="">Selecionar</Option>
                    <Option
                      value="Sim"
                      selected={formData.election_period === 'Sim'}
                    >
                      Sim
                    </Option>
                    <Option
                      value="Não"
                      selected={formData.election_period === 'Não'}
                    >
                      Não
                    </Option>
                  </Select>
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_election_period"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_election_period,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_election_period"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.lu_election_period,
                    )}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_election_period"
                    disabled
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_election_period,
                    )}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_election_period"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(
                      defaultFormData.nu_election_period,
                    )}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal(
                        'election_period_description',
                        'election_period_file',
                      )
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
            <TableRow className="tr-body">
              <BodyCell>Qual o rating do ente?</BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowAnswer>{formData.rating}</ShowAnswer>
                ) : (
                  <InputRes
                    id="text-id"
                    name="rating"
                    onChange={handleInputChange}
                    defaultValue={formData.rating || ''}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="lu_rating"
                    disabled
                    defaultValue={formattedTimestamp(defaultFormData.lu_rating)}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="lu_rating"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(defaultFormData.lu_rating)}
                  />
                )}
              </BodyCell>
              <BodyCell>
                {user.profile === 'CEO' ? (
                  <ShowInputRes
                    type="date"
                    id="date-id"
                    name="nu_rating"
                    disabled
                    defaultValue={formattedTimestamp(defaultFormData.nu_rating)}
                  />
                ) : (
                  <InputRes
                    type="date"
                    id="date-id"
                    name="nu_rating"
                    onChange={handleInputChange}
                    defaultValue={formattedTimestamp(defaultFormData.nu_rating)}
                  />
                )}
                <AddDescriptionButton>
                  <button
                    type="button"
                    onClick={() =>
                      setModal('rating_description', 'rating_file')
                    }
                  >
                    +
                  </button>
                </AddDescriptionButton>
              </BodyCell>
            </TableRow>
          </Tbody>
        </Table>

        {(formData.approved === 'Aprovado' ||
          formData.approved === 'Aprovado com ressalva' ||
          formData.approved === 'Desaprovado') &&
        user.profile === 'CEO' ? (
          <ButtonsContainer>
            <ReturnButtonContainer>
              <button
                type="button"
                onClick={() =>
                  history.push({
                    pathname: `/pricing/state`,
                    state: {
                      place_name: state.place_name,
                      place_id: state.id,
                      lastPage: 'Second',
                      formDataSecondPage: inputFormData,
                    },
                  })
                }
              >
                Anterior
              </button>
            </ReturnButtonContainer>
            <SendButtonContainer>
              <button
                type="button"
                onClick={() => setOpenConclusionModal(true)}
              >
                Observações
              </button>
            </SendButtonContainer>
            <BackButtonContainer>
              <button
                type="button"
                onClick={() =>
                  history.push({
                    pathname: `/pricing`,
                    state: {
                      place_name: state.place_name,
                      place_id: state.id,
                      lastPage: 'Second',
                      formDataSecondPage: inputFormData,
                    },
                  })
                }
              >
                Sair
              </button>
            </BackButtonContainer>
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <ReturnButtonContainer>
              <button
                type="button"
                onClick={() =>
                  history.push({
                    pathname: `/pricing/state`,
                    state: {
                      place_name: state.place_name,
                      place_id: state.id,
                      lastPage: 'Second',
                      formDataSecondPage: inputFormData,
                      descriptionSecondPage: inputDescription,
                    },
                  })
                }
              >
                Anterior
              </button>
            </ReturnButtonContainer>
            {user.profile === 'CEO' ? (
              <SendButtonContainer>
                <button
                  type="button"
                  onClick={() => setOpenConclusionModal(true)}
                >
                  Aprovar
                </button>
              </SendButtonContainer>
            ) : (
              <SendButtonContainer>
                <button type="button" onClick={handleSubmit}>
                  Enviar
                </button>
              </SendButtonContainer>
            )}
          </ButtonsContainer>
        )}
      </Body>

      {openConclusionModal && (
        <ConclusionModal
          isOpen={openConclusionModal}
          onRequestClose={handleCloseConclusionModal}
          formData={formData}
        />
      )}

      {openDescriptionModal && (
        <DescriptionModal
          isOpen={openDescriptionModal}
          onRequestClose={handleCloseDescriptionModal}
          description={inputDescription}
          descriptionName={descriptionName}
          handleModalSubmit={handleModalSubmit}
          dataFileName={dataFileName}
          dataDownload={dataDownload}
        />
      )}
    </Container>
  );
}
