import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
`;

export const ScheduleLine = styled.div`
  display: flex;
  height: 4.5rem;
`;

export const WatchIconContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 10.5%;
  padding: 0.75rem;

  .icon-calendar,
  .icon-time {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .icon-calendar {
    justify-content: flex-start;
  }

  .icon-time {
    justify-content: flex-end;
  }
`;

export const LineContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 92.4%;
  height: 100%;

  background: rgba(22, 101, 216, 0.05);

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;

  border-left: 0.063rem solid rgba(194, 194, 194, 0.5);

  #empty {
    width: 3.438rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p#title {
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--blue-800);
  }

  p#subtitle {
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--blue-800);
  }
`;
