import styled from 'styled-components';

export const Table = styled.table`
  width: 52.75rem;
  margin: 4.6875rem auto 0 auto;

  .tr-body:nth-child(odd) {
    background: #e5e5e5;
  }

  .tr-body:nth-child(even) {
    background: #efefef;
  }
`;

export const TableHead = styled.tr`
  width: 52.75rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #cfdef3;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface ITableHeaderProps {
  width: string;
}

export const TableHeader = styled.th<ITableHeaderProps>`
  width: ${props => props.width};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #05233e;
`;

export const VerticalSeparator = styled.div`
  width: 0rem;
  height: 2rem;
  border-left: 0.0625rem solid #9d9d9d;
`;

export const SaveButton = styled.button`
  width: 9.6875rem;
  height: 2.3125rem;
  margin: 61px auto 0 auto;
  border: none;
  border-radius: 0.5rem;
  background: #014077;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-align: center;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: #ffffff;

  &:hover {
    background: #05233e;
  }
`;
