import styled from 'styled-components';

export const ImportPriorityStyle = {
  content: {
    width: '31.25rem',
    height: '19.75rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
};

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 27.875rem;
    height: 2.5rem;

    background: rgba(207, 222, 243, 0.83);
    border-radius: 0.25rem;

    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    color: #05233e;

    margin-bottom: 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: Poppins;
    font-size: 1.125rem;
    font-weight: 400;
    color: #05233e;

    text-align: center;
    margin-top: 1.125rem;
  }
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    width: 100%;
  }
  @media print {
    display: none;
  }
`;

export const Separator = styled.hr`
  width: 8.125rem;
  margin-bottom: 1.313rem;
  @media print {
    display: none;
  }
`;

export const SelectTypeImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.25rem;
`;

export const CheckpointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 15rem;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    margin-right: 0.375rem;
    border: 1px solid #1665d8;
    cursor: pointer;
  }

  label {
    font-family: Poppins;
    font-size: 0.75rem;
    font-weight: 500;
    color: #3d3d3d;
  }
`;

export const ImportFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  width: 20.063rem;
`;

export const ButtonLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    display: none;
  }

  label#file,
  button {
    width: 9.375rem;
    height: 1.625rem;

    border: none;
    border-radius: 0.313rem;

    font-family: Poppins;
    font-size: 0.688rem;
    font-weight: 600;

    margin-bottom: 0.438rem;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  label#file {
    background: #c4c4c4;
    box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
    color: #4e4e4e;
  }

  button#send {
    background: rgba(6, 57, 102, 0.72);
    box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
    color: #fff;

    transition: 0.5s;
  }

  button#send:hover {
    background: #063966;
    box-shadow: 0.25rem 0.25rem 5rem rgba(0, 0, 0, 0.25);
    color: #fff;
  }

  label {
    font-size: 0.625rem;
    text-align: center;
  }
`;
