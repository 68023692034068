import styled from 'styled-components';
import { shade } from 'polished';

export const ModalSchedule = {
  content: {
    width: '42.188rem',
    height: '29.813rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.hr`
  width: 16rem;
  margin-bottom: 1.438rem;
  @media print {
    display: none;
  }
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    width: 100%;
  }
  @media print {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 2.063rem;

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(207, 222, 243, 0.83);
    border-radius: 0.25rem;

    width: 18.188rem;
    height: 2.5rem;

    font-size: 0.75rem;

    font-family: Poppins;
    font-weight: 500;
    color: #8e9398;
  }

  .subtitleModal {
    margin-bottom: 0.438rem;
    font-weight: 600;
    font-size: 1rem;
    color: #05233e;
  }

  input {
    background: #efefef;
    border: none;
    border-radius: 0.25rem;
    padding-left: 0.25rem;

    height: 1.875rem;
    width: 18.188rem;

    font-weight: normal;
    font-size: 0.75rem;
    color: #3d3d3d;

    ::placeholder {
      color: #8e9398;
    }
  }

  input#business_id {
    border: 0.5px solid #063966;
  }

  span {
    font-family: Poppins;
    font-weight: 500;
    color: #3d3d3d;
    font-size: 0.75rem;
  }
`;

export const DateActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 18.188rem;
`;

export const ButtonsWeekdayContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 8.625rem);
  gap: 0.625rem;
`;

export const Calendar = styled.div`
  width: 18.188rem;

  display: flex;
  flex-direction: column;

  background: #ffffff;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 1.875rem;

    background: #efefef;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem;

    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    color: #05233e;
  }

  div#buttonsWeekday {
    display: grid;
    grid-template-columns: repeat(2, 8.5rem);
    gap: 0.438rem 1.3rem;

    margin-bottom: 2rem;
  }
`;

export const ActivityContainer = styled.div`
  display: flex;
  width: 18.188rem;
`;

export const DetailsActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 18.188rem;

  div#selectDate {
    margin-bottom: 0.8rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  div#selectCloser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;

    select {
      width: 18.188rem;
      height: 1.875rem;
      cursor: pointer;

      border-radius: 0.25rem;
      border: 1px solid var(--blue-300);

      &:focus {
        border: 2px solid var(--blue-400);
      }

      background: #efefef;
      border: 0.031rem solid #063966;
      box-sizing: border-box;
      border-radius: 0.25rem;

      font-family: Poppins;
      font-weight: 400;
      font-size: 0.75rem;
      color: #3d3d3d;
    }
  }

  div#selectHour {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;

    select {
      width: 100%;
      height: 1.875rem;

      background: #efefef;
      border: 0.031rem solid #063966;
      box-sizing: border-box;
      border-radius: 0.25rem;

      font-family: Poppins;
      font-weight: 400;
      font-size: 0.75rem;
      color: #3d3d3d;
    }
  }

  div#selectActivity {
    display: grid;
    grid-template-columns: repeat(2, 8.5rem);
    gap: 0.438rem 1.3rem;
  }
`;

export const FrequencyActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.188rem;
  width: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9rem 0;

  input#hubspot_deal_id {
    border: 0.5px solid #063966;
  }
`;

export const InputsRadioContainer = styled.div`
  display: flex;
  margin-top: 0.45rem;
  align-items: center;

  width: 100%;

  input {
    width: 0.85rem;
    height: 0.85rem;

    cursor: pointer;
    color: #1665d8;
    margin-right: 0.438rem;
  }

  label {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.75rem;
    color: #3d3d3d;
  }
`;

export const ButtonsContainerSchedule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 930px) {
    justify-content: center;
    border: none;
  }
`;

export const ButtonSchedule = styled.button`
  width: 9.688rem;
  height: 2rem;
  border: none;

  // padding: 1rem;
  //margin-top: 0.01rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.9rem;

  background: #063966;
  border-radius: 0.5rem;

  color: #ffffff;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.3, '#063966')};
  }
`;
