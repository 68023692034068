import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChangeDateButton = styled.button`
  width: fit-content;
  height: fit-content;

  border: none;
  background: none;
  margin-top: 6.25rem;
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
  margin: 0 0.625rem;
`;

export const WeekdayContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

interface WeekdayButtonProps {
  selectedDay: boolean;
}

export const Weekday = styled.span<WeekdayButtonProps>`
  width: 10rem;
  height: 6rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  font-weight: 600;

  span:nth-child(1) {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  span:nth-child(2) {
    font-size: 3em;
    line-height: 2.6rem;
  }

  ${props =>
    props.selectedDay
      ? css`
          color: var(--white);

          background: var(--blue-400);
        `
      : css`
          color: var(--blue-700);

          background: var(--white);
          border: 2px solid var(--blue-700);
        `}
`;

export const HorarysContainer = styled.div`
  width: 57rem;
  height: 40rem;

  display: flex;
  justify-content: space-between;

  border: 1px solid var(--blue-700);
  border-radius: 0.375rem;

  overflow: auto;
`;

export const Hour = styled.span`
  height: 1.75rem;
  width: 5.938rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 0.938rem;
  color: var(--blue-700);

  & + span {
    border-top: 1px solid rgba(157, 157, 157, 0.5);
  }
`;

interface CardActivityProps {
  statusTask?: string;
  isBefore?: boolean;
}

export const CardActivity = styled.div<CardActivityProps>`
  & + div {
    border-top: 1px solid rgba(157, 157, 157, 0.5);
  }

  width: 10.125rem;
  height: 1.75rem;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--blue-700);
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.statusTask === 'Concluída'
      ? css`
          background: rgba(60, 166, 71, 0.25);
          box-shadow: -2px 0 0 var(--green-400);
          border-radius: 0.5rem;
          cursor: pointer;
        `
      : props.isBefore && props.statusTask === 'Pendente'
      ? css`
          background: rgba(222, 12, 12, 0.25);
          box-shadow: -2px 0 0 var(--red-700);
          border-radius: 0.5rem;
          cursor: pointer;
        `
      : !props.isBefore &&
        props.statusTask === 'Pendente' &&
        css`
          background: rgba(1, 64, 119, 0.1);
          box-shadow: -2px 0 0 var(--blue-500);
          border-radius: 0.5rem;
          cursor: pointer;
        `}
`;
