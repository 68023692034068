import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

export const Content = styled.div`
  width: calc(100% - 12rem);
  height: calc(100% - 4.5rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4.5rem;
  margin-left: 12rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 1.375rem;
  letter-spacing: -0.015em;

  @media (max-width: 1200px) {
    margin-top: 7rem;
    margin-left: 0.5rem;
  }
`;

export const LiquidationDataContainer = styled.div`
  width: 100%;
`;

export const LiquidationDataTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 12rem);
  height: 2.5rem;
  background: #f8f5f5;
  box-shadow: 0 0.25rem 0.25rem #ccc8c8;
  border-radius: 0.25rem;
  margin: 0 auto;

  svg {
    margin-right: 0.5rem;
  }

  input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    background: transparent;
    border: 0;
    color: #6d6d6d;
    ::-webkit-calendar-picker-indicator {
      -webkit-appearance: none;
    }
  }

  @media (max-width: 1100px) {
    width: 96vw;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;

  div {
    margin-right: 2rem;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100vw;
    gap: 1rem;
  }
`;
export const SendButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #014077;
    border: 0;
    border-radius: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;
export const CreateButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #019158;
    border: 0;
    border-radius: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const DeleteLiquidationButton = styled.button`
  display: flex;
  margin-right: 4rem;
  align-items: center;
  justify-content: center;
  background: #063966;
  border-radius: 0.313rem;
  border: 0;
  background: #063966;
  color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: -0.015em;
  padding: 0.25rem;
  transition: background-color 0.3s;

  &:hover {
    background: red;
  }

  @media (max-width: 800px) {
    justify-content: center;
    margin-top: 1.8rem;
    margin-left: -1rem;
  }
`;
export const DivTable = styled.div`
  @media (max-width: 1100px) {
    margin-right: 1.8rem;
    margin-top: 0.5rem;
    overflow-x: scroll;
    width: 100vw;
  }
`;

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Table = styled.table`
  padding: 0.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 12rem);
`;

export const Divisor = styled.div`
  height: 2rem;

  border-right: 1px solid #9d9d9d;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Celula = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Thead = styled.div`
  background: rgba(209, 235, 225, 0.83);
  border-radius: 0.25rem;
  margin-bottom: 0.7rem;

  th {
    word-break: break-all;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.015em;

    color: #05233e;
    :last-child {
      visibility: hidden;
    }

    @media (max-width: 1100px) {
      width: 10rem;
    }
  }
`;

export const Tbody = styled.div`
  border-radius: 0.25rem;
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  tr {
    background: #e5e5e5;

    display: flex;
    flex-direction: row;
    &:hover {
      background: ${shade(0.2, '#E5E5E5')};
    }
  }

  td {
    word-break: break-all;
    width: 100vw;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.015em;
    padding: 0.5rem;

    color: #05233e;
  }
`;

export const Separator = styled.hr`
  margin-top: 1rem;
  width: 16rem;
  margin-bottom: 2.438rem;
  @media print {
    display: none;
  }
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media print {
    display: none;
  }
`;

export const H1 = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3.625rem;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.015em;

  color: #3d3d3d;

  @media (max-width: 1100px) {
    width: 96vw;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

export const FileInputContainer = styled.div`
  display: flex;
  align-items: center;

  left: 38.26%;
  right: 30.83%;
  top: 22.9%;
  bottom: 70.65%;

  height: 5.5rem;
  width: 28rem;
  left: 34.438rem;
  top: 17.75rem;

  background: linear-gradient(
    0deg,
    rgba(217, 238, 230, 0.74),
    rgba(217, 238, 230, 0.74)
  );
  border-radius: 0.625rem;

  div {
    margin-right: 1rem;
  }

  @media (max-width: 1200px) {
    margin-left: 4.5rem;
    justify-content: center;
    margin-right: 0;
  }
`;

export const ExampleFileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3.5rem;

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 1rem;

    hr {
      width: 14rem;
    }

    span {
      margin: 0.5rem;
    }
  }
`;

export const SendFileContainer = styled.div`
  display: flex;
  align-items: center;

  strong {
    margin: 0 1rem 0 1rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8rem;
      height: 1.625rem;
      left: 41.438rem;
      top: 18.875rem;
      border-radius: 0.313rem;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      cursor: pointer;
      color: #4e4e4e;
      background: #c4c4c4;
    }
    span {
      margin-top: 0.5rem;
      color: #666666;
      height: 1.313rem;
      width: 7.438rem;
      left: 41.688rem;
      top: 20.625rem;

      font-family: 'Poppins';
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.938rem;
      letter-spacing: -0.015em;
      text-align: center;
    }

    input {
      display: none;
    }
  }
`;
