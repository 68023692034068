import { format, isBefore } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import { Loading } from '../../../../components/Loading';
import { Title } from '../../../../components/Title';
import { useHunterSchedule } from '../../../../hooks/useHunterSchedule';
import { IHunterSLADTO } from '../dtos/IHunterSLADTO';
import {
  whoContactedBeam,
  decisionMaker,
  connectionMethod,
  whoContacted,
} from '../utils/noteAmountByInput';

import {
  SuccessfulModalStyle,
  Main,
  InputContainer,
  Label,
  Select,
  Input,
  Textarea,
  ButtonSave,
  TimestampContainer,
} from './styles';
import verifyDealTj from './utils/verifyDealTj';
import { ButtonExit } from '../LostModal/styles';
import { createFirstPassClarificationNote } from '../utils/createClarificationNote';

interface BeamModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function BeamModal({
  isOpen,
  onRequestClose,
}: BeamModalProps): JSX.Element {
  const { user } = useAuth();
  const { handleCreateDayHour } = useHunterSchedule();

  const [formData, setFormData] = useState<IHunterSLADTO>({} as IHunterSLADTO);
  const [availableHours, setAvailableHours] = useState<string[]>(
    [] as string[],
  );
  const [selectedConnection, setSelectedConnection] = useState<string>();
  const [verifyDeal, setVerifyDeal] = useState<boolean>(true);
  const [HubspotTicketID, setHubspotTicketID] = useState<string>('');

  const handleCloseModal = useCallback(() => {
    onRequestClose();
    setFormData({} as IHunterSLADTO);
  }, [onRequestClose]);

  const handleCloseBlockedModal = useCallback(() => {
    setVerifyDeal(true);
    onRequestClose();
  }, [onRequestClose, setVerifyDeal]);

  useEffect(() => {
    if (
      formData.who_contacted &&
      formData.connection_method &&
      formData.connection_type &&
      formData.valid_contact
    ) {
      const clarificationNote = createFirstPassClarificationNote({
        hunter_name: user.name,
        who_contacted: formData.who_contacted,
        connection_type: formData.connection_type,
        connection_method: formData.connection_method,
        valid_contact: formData.valid_contact,
      });
      setFormData(form_data => {
        return { ...form_data, clarification_note: clarificationNote };
      });
    } else {
      setFormData(form_data => {
        return { ...form_data, clarification_note: '' };
      });
    }
  }, [
    formData.connection_method,
    formData.connection_type,
    formData.valid_contact,
    formData.who_contacted,
    user.name,
  ]);

  const handleLoadLastSLA = useCallback(
    async (ticket_id: string) => {
      setSelectedConnection(undefined);

      const response = await api.get(`/schedule/last-deal-sla/${ticket_id}`);
      if (response.data) {
        setFormData(response.data);
      } else {
        setFormData({ ...formData, ticket_id });
      }

      const secondResponse = await api.get(
        `/schedule/get-hubspot-deal-info?hubspot_ticket_id=${ticket_id}&connection=${selectedConnection}`,
      );

      const isTicketValid = await verifyDealTj({
        closed_date: secondResponse.data.closed_date,
      });
      setVerifyDeal(isTicketValid);
    },
    [formData, selectedConnection],
  );

  const handleListAvailableHours = useCallback(
    async (date: string) => {
      try {
        const { data } = await api.get<string[]>(
          `/schedule/hunter-available-hours?date=${date}&user_id=${user.id}`,
        );

        const formattedHours = data.map(hour => {
          return format(new Date(hour), 'HH:mm');
        });

        setAvailableHours(formattedHours);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }
    },
    [user.id],
  );

  const handleInputChange = useCallback(
    async (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;

      if (name === 'ticket_id' && value.length >= 9) {
        setHubspotTicketID(value);
        await handleLoadLastSLA(value);
      } else if (name === 'date') {
        await handleListAvailableHours(value);

        setFormData({ ...formData, [name]: value });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData, handleListAvailableHours, handleLoadLastSLA],
  );

  const handleSaveSLA = useCallback(async () => {
    try {
      if (formData.ticket_id === '' || isNaN(Number(formData.ticket_id))) {
        Swal.fire('Erro!', 'Ticket ID inválido!', 'error');
      } else {
        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        const schema = yup.object().shape({
          who_contacted: yup
            .string()
            .required('Com quem foi o contato obrigatória'),
          contact_name: yup.string().required('Nome do contato obrigatório'),
          valid_contact: yup.number().required('Contato válido obrigatório'),
          connection_type: yup.string().required('Tipo de conexão obrigatório'),
          connection_method: yup
            .string()
            .required('Meio de conexão obrigatório'),
          decision_maker: yup
            .string()
            .required('Tomador de decisões obrigatório'),
          observation: yup.string().required('Observação obrigatória'),
          date: yup.string().required('Data obrigatória'),
          time: yup.string().required('Horário obrigatório'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const timestamp = `${formData.date} ${formData.time}`;

        const timeIsBefore = isBefore(new Date(timestamp), new Date());

        if (timeIsBefore) {
          Swal.fire(
            'Atenção!',
            'Horário escolhido para atividade já passou!',
            'warning',
          );
        } else {
          const whoContactedValue = whoContactedBeam.find(
            who_contacted => who_contacted.label === formData.who_contacted,
          );
          const possibleValidContactValue = formData.possible_valid_contact
            ? 5
            : 0;
          const decisionMakerValue = decisionMaker.find(
            decision_maker => decision_maker.label === formData.decision_maker,
          );

          const totalAmount =
            whoContactedValue.amount +
            possibleValidContactValue +
            decisionMakerValue.amount;

          const { data } = await api.post('/schedule/hunter-sla', {
            name: 'Trave',
            hunter_id: user.id,
            ticket_id: HubspotTicketID,
            who_contacted: formData.who_contacted,
            contact_name: formData.contact_name,
            valid_contact: formData.valid_contact,
            connection_type: formData.connection_type,
            connection_method: formData.connection_method,
            possible_valid_contact: formData.possible_valid_contact,
            decision_maker: formData.decision_maker,
            observation: formData.observation,
            timestamp,
            note_amount: totalAmount,
            opt_in: formData.opt_in,
            clarification_note: formData.clarification_note,
          });

          handleCreateDayHour(data);

          toast.success('Trave criada com sucesso!');

          handleCloseModal();
        }
      }
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      if (error instanceof yup.ValidationError) {
        toast.error('Preencha todos os campos corretamente.');

        return;
      }

      if (error.response.data.status === 404) {
        Swal.fire('Erro!', error.response.data.message, 'error');
        return;
      }

      toast.error('Não foi possível criar trave.');
    }
  }, [
    formData,
    user.id,
    HubspotTicketID,
    handleCreateDayHour,
    handleCloseModal,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={SuccessfulModalStyle}
    >
      <Title name="Trave" />

      <Loading />

      <Main>
        <InputContainer>
          <Label>ID do ticket:</Label>
          <Input type="number" name="ticket_id" onChange={handleInputChange} />
        </InputContainer>

        {!verifyDeal ? (
          <InputContainer>
            <ButtonExit onClick={handleCloseBlockedModal}>Sair</ButtonExit>
          </InputContainer>
        ) : (
          <>
            <InputContainer>
              <Label>Com quem foi o contato?</Label>
              <Select
                name="who_contacted"
                defaultValue={formData.who_contacted || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>

                {whoContactedBeam.map(who_contacted => (
                  <option
                    key={who_contacted.label}
                    value={who_contacted.label}
                    selected={formData.who_contacted === who_contacted.label}
                  >
                    {who_contacted.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Nome do contato:</Label>
              <Input
                type="text"
                name="contact_name"
                defaultValue={formData.contact_name || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Contato válido (terceiros):</Label>
              <Input
                type="number"
                name="valid_contact"
                defaultValue={formData.valid_contact || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Tipo de conexão</Label>
              <Select
                name="connection_type"
                defaultValue={formData.connection_type || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option
                  value="Inbound (0800)"
                  selected={formData.connection_type === 'Inbound (0800)'}
                >
                  Inbound (0800)
                </option>
                <option
                  value="Inbound (Virtual)"
                  selected={formData.connection_type === 'Inbound (Virtual)'}
                >
                  Inbound (Virtual)
                </option>
                <option
                  value="Outbound"
                  selected={formData.connection_type === 'Outbound'}
                >
                  Outbound
                </option>
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Meio de conexão:</Label>
              <Select
                name="connection_method"
                defaultValue={formData.connection_method || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {connectionMethod.map(connection_method => (
                  <option
                    key={connection_method.label}
                    value={connection_method.label}
                    selected={
                      formData.connection_method === connection_method.label
                    }
                  >
                    {connection_method.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Possível contato válido (credor):</Label>
              <Input
                type="number"
                name="possible_valid_contact"
                defaultValue={formData.possible_valid_contact || ''}
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Tomador de decisão/influência:</Label>
              <Select
                name="decision_maker"
                defaultValue={formData.decision_maker || ''}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {decisionMaker.map(decision_maker => (
                  <option
                    key={decision_maker.label}
                    value={decision_maker.label}
                    selected={formData.decision_maker === decision_maker.label}
                  >
                    {decision_maker.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label>Observação:</Label>
              <Textarea
                name="observation"
                defaultValue=""
                onChange={handleInputChange}
              />
            </InputContainer>

            <InputContainer>
              <Label>Nota de esclarecimento:</Label>
              <Textarea
                disabled
                name="clarification_note"
                defaultValue={formData.clarification_note}
              />
            </InputContainer>

            <TimestampContainer>
              <InputContainer>
                <Label>Data:</Label>
                <Input
                  type="date"
                  name="date"
                  className="small-input"
                  onChange={handleInputChange}
                />
              </InputContainer>

              <InputContainer>
                <Label>Horário:</Label>
                <Select
                  name="time"
                  className="small-input"
                  defaultValue=""
                  onChange={handleInputChange}
                >
                  <option value="">Selecione um horário</option>
                  {availableHours &&
                    availableHours.map(availableHour => (
                      <option key={availableHour} value={availableHour}>
                        {availableHour}
                      </option>
                    ))}
                </Select>
              </InputContainer>
            </TimestampContainer>

            <InputContainer>
              <ButtonSave onClick={handleSaveSLA}>Salvar</ButtonSave>
            </InputContainer>
          </>
        )}
      </Main>
    </Modal>
  );
}
