import { useCallback, useState } from 'react';
import { format, isBefore } from 'date-fns';
import {
  Content,
  ScheduleLineContainer,
  IconContainer,
  LineTableContent,
  InfosTasksContainer,
  HourTypeCloserContainer,
  HourNameActivity,
  IntervalTime,
  StatusTaskStageContainer,
  StatusConcludeTask,
  ButtonSituation,
  TryTimes,
} from './styles';

import { IHunterActivity } from '../../../dtos/IDayHoursDTO';
import { formatAmount } from '../../../../../utils/formatAmount';
import { ActivityHunterModal } from '../../../Hunter/ActivityHunterModal';
import { useHunterSchedule } from '../../../../../hooks/useHunterSchedule';

type VisualizationCalendarProps = {
  schedule_status?: boolean;
};

export function VisualizationCalendar({
  schedule_status,
}: VisualizationCalendarProps): JSX.Element {
  const { dayHours } = useHunterSchedule();

  const [openActivityHunterModal, setOpenActivityHunterModal] = useState(false);
  const [activityHunter, setActivityHunter] = useState<IHunterActivity>(
    {} as IHunterActivity,
  );

  const handleOpenActivityHunterModal = useCallback(
    (activity: IHunterActivity) => {
      setActivityHunter(activity);
      setOpenActivityHunterModal(true);
    },
    [],
  );

  const handleCloseActivityHunterModal = useCallback(() => {
    setOpenActivityHunterModal(false);
  }, []);

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  return (
    <Content>
      {dayHours.length > 0 &&
        dayHours.map(day_hour => (
          <ScheduleLineContainer
            key={String(day_hour.hour)}
            className="scheduleContainer"
            moreThanOne={day_hour.activities.length > 1}
          >
            <IconContainer>
              <p>{format(new Date(day_hour.hour), 'HH:mm')}</p>
            </IconContainer>

            <LineTableContent moreThanOne={day_hour.activities.length > 1}>
              {day_hour.activities.length > 0 &&
                day_hour.activities.map(activity => {
                  return (
                    !activity.deleted && (
                      <InfosTasksContainer
                        key={activity.id}
                        isBefore={verifyIsBeforeNow(activity.timestamp)}
                        statusTask={activity.status}
                        try_times={activity.try_times}
                        moreThanOne={day_hour.activities.length > 1}
                        onClick={() => handleOpenActivityHunterModal(activity)}
                      >
                        <HourTypeCloserContainer
                          isBefore={verifyIsBeforeNow(activity.timestamp)}
                          statusTask={activity.status}
                          try_times={activity.try_times}
                        >
                          <HourNameActivity>
                            {activity.timestamp ? (
                              <IntervalTime>
                                {format(new Date(activity.timestamp), 'HH:mm')}
                              </IntervalTime>
                            ) : (
                              <IntervalTime>
                                {format(new Date(activity.timestamp), 'HH:mm')}
                              </IntervalTime>
                            )}

                            <p>{activity.name}</p>
                          </HourNameActivity>

                          <p className="credor">Credor: {activity.dealname}</p>
                          <p className="credor">
                            Valor: {formatAmount(Number(activity.amount))}
                          </p>
                        </HourTypeCloserContainer>

                        <StatusTaskStageContainer
                          statusTask={activity.status}
                          isBefore={verifyIsBeforeNow(activity.timestamp)}
                          moreThanOne={day_hour.activities.length > 1}
                        >
                          <StatusConcludeTask>
                            <p id="status">{activity.status}</p>
                          </StatusConcludeTask>
                          {schedule_status &&
                            activity.name === 'Agendamento' && (
                              <ButtonSituation
                                statusTask={
                                  activity.try_times > 0 &&
                                  activity.status === 'Pendente'
                                    ? 'Tentou'
                                    : activity.status
                                }
                              >
                                {activity.try_times > 0 &&
                                activity.status === 'Pendente'
                                  ? 'Tentou'
                                  : activity.status}
                              </ButtonSituation>
                            )}

                          {activity.name === 'Agendamento' && (
                            <TryTimes
                              tryTimes={String(activity.try_times)}
                              statusTask={
                                activity.try_times > 0 &&
                                activity.status === 'Pendente'
                                  ? 'Tentou'
                                  : activity.status
                              }
                            >
                              {activity.try_times || 0}
                            </TryTimes>
                          )}
                        </StatusTaskStageContainer>
                      </InfosTasksContainer>
                    )
                  );
                })}
            </LineTableContent>
          </ScheduleLineContainer>
        ))}

      {activityHunter && (
        <ActivityHunterModal
          isOpen={openActivityHunterModal}
          onRequestClose={handleCloseActivityHunterModal}
          activity={activityHunter}
        />
      )}
    </Content>
  );
}

VisualizationCalendar.defaultProps = {
  schedule_status: false,
};
