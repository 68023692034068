import { format, isBefore } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useAuth } from '../../../../../hooks/auth';
import { ScheduleOptionsButton } from '../../../../../components/ScheduleOptionsButton';
import { Loading } from '../../../../../components/Loading';

import api from '../../../../../services/api';

import closeIcon from '../../../../../assets/icons/cancel.svg';
import editIcon from '../../../../../assets/icons/edit.svg';

import {
  Container,
  Header,
  ButtonsHeaderContainer,
  EditDeleteButton,
  CloseButton,
  Main,
  LabelInputContainer,
  Label,
  Input,
  Link,
  LineSeparator,
  Textarea,
} from './styles';
import { walletSLAFields } from '../../../Closer/utils/walletSLAFiels';

Modal.setAppElement('#root');

interface ITicketWalletSLA {
  informacoes_carteira_closer: string;
  motivo_da_etapa_semanalmente: string;
  motivo_da_etapa_quinzenalmente: string;
  motivo_da_etapa_mensalmente: string;
  motivo_da_perda_carteira_closer: string;
  valor_que_o_credor_venderia: string;
  qual_a_dor_do_credor: string;
  qual_pergunta_de_implicacao_o_credor_fez: string;
  qual_a_frequencia_de_contato_com_o_cliente: string;
  quando_credor_acredita_que_sera_o_pagamento: string;
  objetivo_do_credor: string;
  influencia_interferencia: string;
  observacao_carteira_closer: string;
  proposta: string;
  data_ultimo_contato_carteira_closer: Date;
}

export interface IWalletCaseModal {
  deal_name: string;
  deal_value: string;
  hs_pipeline_stage: string;
  hubspot_owner_id: string;
  hubspot_ticket_id: string;
  id: string;
  last_stage_date: Date;
  name: string;
  observation: string;
  spoken: string;
  status: string;
  try: number;
  ticket_sla: ITicketWalletSLA;
}

interface ModalActivityProps {
  isOpen: boolean;
  onRequestClose: () => void;
  walletCase: IWalletCaseModal | undefined;
}

export function WalletCaseModal({
  isOpen,
  onRequestClose,
  walletCase,
}: ModalActivityProps): JSX.Element {
  const { user } = useAuth();

  const [editFields, setEditFields] = useState(false);

  const talkArray = ['Sim', 'Não'];
  const activitySituation = ['Pendente', 'Concluída'];

  const [talkOption, setTalkOption] = useState<string>('Não');
  const [tryCount, setTryCount] = useState<string>('Não');
  const [observation, setObservation] = useState<string>('');
  const [activitySituationOption, setActivitySituationOption] =
    useState<string>('');

  const closeModal = useCallback(() => {
    onRequestClose();
    setEditFields(false);

    setTalkOption(walletCase.spoken);
    setActivitySituationOption(walletCase.status);
  }, [walletCase, onRequestClose]);

  const handleUpdateActivity = useCallback(async () => {
    try {
      if (tryCount === 'Não' && activitySituationOption === 'Concluída') {
        Swal.fire('Atenção!', 'Não é possivel concluir sem tentar', 'warning');
      } else {
        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        await api.patch('schedule/closer-wallet', {
          wallet_id: walletCase.id,
          status: activitySituationOption || walletCase.status,
          spoken: talkOption || walletCase.spoken,
          tryCount: tryCount || walletCase.try,
          observation: observation || walletCase.observation,
        });

        toast.success('Atividade atualizada com sucesso!');

        closeModal();
      }
    } catch (error) {
      if (error.response.data.status === 404) {
        Swal.fire('Atenção!', error.response.data.message, 'warning');
      } else {
        Swal.fire(
          'Atenção!',
          'Não foi possísvel atualizar atividade.',
          'warning',
        );
      }

      const loader = document.getElementById('loader');
      loader.style.display = 'none';
    }
  }, [
    activitySituationOption,
    closeModal,
    observation,
    talkOption,
    tryCount,
    walletCase.id,
    walletCase.observation,
    walletCase.spoken,
    walletCase.status,
    walletCase.try,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={Container}>
      <Loading />
      <Header
        isBefore={isBefore(new Date(), new Date())}
        status="Pendente"
        try_times={0}
      >
        <span>
          {format(new Date(), 'dd/MM/yyyy')} - {walletCase.deal_name}
        </span>

        <ButtonsHeaderContainer>
          {user.profile === 'Closer Linha 1' &&
            (editFields ? (
              <EditDeleteButton
                editFields={editFields}
                onClick={handleUpdateActivity}
              >
                Salvar alterações
              </EditDeleteButton>
            ) : (
              <>
                <EditDeleteButton onClick={() => setEditFields(true)}>
                  <img src={editIcon} alt="Editar informações" />
                </EditDeleteButton>
              </>
            ))}
          <CloseButton onClick={closeModal}>
            <img src={closeIcon} alt="Fechar modal" />
          </CloseButton>
        </ButtonsHeaderContainer>
      </Header>

      <Main>
        <Label>{walletCase.name ? walletCase.name.toUpperCase() : ''}</Label>
        <LineSeparator />
        {walletCase.ticket_sla
          ? walletSLAFields.map(field => (
              <LabelInputContainer>
                <Label>{field.field}: </Label>
                {walletCase.ticket_sla[`${field.label}`] &&
                walletCase.ticket_sla[`${field.label}`].length > 42 ? (
                  <Textarea
                    defaultValue={walletCase.ticket_sla[`${field.label}`] || ''}
                    disabled
                    style={{
                      color: 'var(--gray-800)',
                      border: 'none',
                      background: 'none',
                    }}
                  />
                ) : (
                  <Input
                    type="text"
                    disabled
                    value={walletCase.ticket_sla[`${field.label}`] || ''}
                  />
                )}
              </LabelInputContainer>
            ))
          : 'Nenhum SLA preenchido na agenda'}

        <LabelInputContainer>
          <Label>Link Hubspot:</Label>
          <Link
            href={`https://app.hubspot.com/contacts/7857327/record/0-5/${walletCase.hubspot_ticket_id}`}
            target="_blank"
            rel="noreferrer"
          >
            {`https://app.hubspot.com/contacts/7857327/record/0-5/${walletCase.hubspot_ticket_id}`}
          </Link>
        </LabelInputContainer>

        {editFields && (
          <>
            <LineSeparator />

            <LabelInputContainer className="editInputsContainer">
              <Label htmlFor="try">Tentou:</Label>
              {talkArray.map(tryOp => (
                <ScheduleOptionsButton
                  key={tryOp}
                  selectedValue={talkOption === tryOp}
                  type="button"
                  name="try"
                  onClick={() => setTalkOption(tryOp)}
                >
                  {tryOp}
                </ScheduleOptionsButton>
              ))}
            </LabelInputContainer>

            <LabelInputContainer className="editInputsContainer">
              <Label htmlFor="spoken">Falou:</Label>
              {talkArray.map(tryOp => (
                <ScheduleOptionsButton
                  key={tryOp}
                  selectedValue={tryCount === tryOp}
                  type="button"
                  name="spoken"
                  onClick={() => setTryCount(tryOp)}
                >
                  {tryOp}
                </ScheduleOptionsButton>
              ))}
            </LabelInputContainer>

            <LabelInputContainer className="editInputsContainer">
              <Label htmlFor="activity_situation">Situação:</Label>
              {activitySituation.map(situation => (
                <ScheduleOptionsButton
                  key={situation}
                  selectedValue={activitySituationOption === situation}
                  type="button"
                  onClick={() => setActivitySituationOption(situation)}
                >
                  {situation}
                </ScheduleOptionsButton>
              ))}
            </LabelInputContainer>

            <LabelInputContainer className="observation-container">
              <Label>Observações:</Label>
              <Textarea
                name="observation"
                defaultValue={walletCase.observation || ''}
                onChange={e => setObservation(e.target.value)}
              />
            </LabelInputContainer>

            <LineSeparator />
          </>
        )}
      </Main>
    </Modal>
  );
}
