import React, { useCallback } from 'react';
import { Container, Button } from './styles';
import { ReactComponent as OrderCrescent } from '../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../assets/icons/polygon_down.svg';
import convertHoursInSeconds from '../../../../utils/convertHoursInSeconds';
import convertSecondsInHours from '../../../../utils/convertSecondsInHours';

interface IAttendance {
  opened_at: Date;
  finished_at: Date;
  agent_id: string;
}

interface IAgentMetrics {
  id: string;
  name: string;
  metrics: Array<{
    created_at: Date;
    average_attendance_time: string;
    average_response_time: string;
  }>;
}
interface IAttendanceTags {
  tag: string;
  closed: number;
  attendance_time: number;
}

interface ButtonsOrderTakeBlipProps {
  setAgentMetrics: ((agent_metrics: IAgentMetrics[]) => void) | undefined;
  setAttendanceTags: ((attendance_tags: IAttendanceTags[]) => void) | undefined;
  agentMetrics: IAgentMetrics[];
  attendanceTags: IAttendanceTags[];
  attendanceOpened: IAttendance[];
  attendanceClosed: IAttendance[];
  type: string;
}

export const ButtonsOrderTakeBlip: React.FC<ButtonsOrderTakeBlipProps> = ({
  setAgentMetrics,
  setAttendanceTags,
  agentMetrics,
  attendanceTags,
  attendanceOpened,
  attendanceClosed,
  type,
}: ButtonsOrderTakeBlipProps) => {
  const orderAttendancesByAttendance = useCallback(
    (order: string) => {
      const array = agentMetrics.sort(
        (previous: IAgentMetrics, next: IAgentMetrics) => {
          return order === 'ASC'
            ? previous.name.toUpperCase() === next.name.toUpperCase()
              ? 0
              : previous.name.toUpperCase() > next.name.toUpperCase()
              ? 1
              : -1
            : previous.name.toUpperCase() === next.name.toUpperCase()
            ? 0
            : previous.name.toUpperCase() < next.name.toUpperCase()
            ? 1
            : -1;
        },
      );

      setAgentMetrics([...array]);
    },
    [agentMetrics, setAgentMetrics],
  );

  const orderAttendancesByCreatedAt = useCallback(
    (order: string) => {
      const array = agentMetrics.sort(
        (previous: IAgentMetrics, next: IAgentMetrics) => {
          const indexPrevious =
            previous.metrics.length > 0 ? previous.metrics.length - 1 : -1;

          const indexNext =
            next.metrics.length > 0 ? next.metrics.length - 1 : -1;

          return order === 'ASC'
            ? (indexPrevious !== -1
                ? new Date(previous.metrics[indexPrevious].created_at).getTime()
                : -1) -
                (indexNext !== -1
                  ? new Date(next.metrics[indexNext].created_at).getTime()
                  : -1)
            : (indexNext !== -1
                ? new Date(next.metrics[indexNext].created_at).getTime()
                : -1) -
                (indexPrevious !== -1
                  ? new Date(
                      previous.metrics[indexPrevious].created_at,
                    ).getTime()
                  : 0);
        },
      );

      setAgentMetrics([...array]);
    },
    [agentMetrics, setAgentMetrics],
  );

  const handleOpenedTicket = useCallback(
    (agent: IAgentMetrics) => {
      return attendanceOpened.filter(
        attendance => attendance.agent_id === agent.id,
      ).length;
    },
    [attendanceOpened],
  );

  const handleClosedTicket = useCallback(
    (agent: IAgentMetrics) => {
      return attendanceClosed.filter(
        attendance => attendance.agent_id === agent.id,
      ).length;
    },
    [attendanceClosed],
  );

  const orderAttendancesByTicket = useCallback(
    (order: string, object_type: string) => {
      const array = agentMetrics.sort(
        (previous: IAgentMetrics, next: IAgentMetrics) => {
          return object_type === 'open'
            ? order === 'ASC'
              ? handleOpenedTicket(previous) - handleOpenedTicket(next)
              : handleOpenedTicket(next) - handleOpenedTicket(previous)
            : order === 'ASC'
            ? handleClosedTicket(previous) - handleClosedTicket(next)
            : handleClosedTicket(next) - handleClosedTicket(previous);
        },
      );

      setAgentMetrics([...array]);
    },
    [agentMetrics, setAgentMetrics, handleOpenedTicket, handleClosedTicket],
  );

  const handleTimeMediumAttendance = useCallback((average: string[]) => {
    if (average.length === 0) {
      return ' - ';
    }

    const arrayMetrics = average.map(metric => convertHoursInSeconds(metric));

    const sumMetrics = arrayMetrics.reduce(
      (previous, current) => previous + current,
      0,
    );

    const avgMetrics = Math.round(sumMetrics / average.length);

    return convertSecondsInHours(avgMetrics);
  }, []);

  const orderAttendancesByTime = useCallback(
    (order: string, object_type: string) => {
      const array = agentMetrics.sort(
        (previous: IAgentMetrics, next: IAgentMetrics) => {
          const orderPrevious = handleTimeMediumAttendance(
            previous.metrics.map(metric => metric[object_type]),
          );

          const orderNext = handleTimeMediumAttendance(
            next.metrics.map(metric => metric[object_type]),
          );

          return order === 'ASC'
            ? (orderPrevious === ' - '
                ? -1
                : convertHoursInSeconds(orderPrevious)) -
                (orderNext === ' - ' ? -1 : convertHoursInSeconds(orderNext))
            : (orderNext === ' - ' ? -1 : convertHoursInSeconds(orderNext)) -
                (orderPrevious === ' - '
                  ? -1
                  : convertHoursInSeconds(orderPrevious));
        },
      );

      setAgentMetrics([...array]);
    },
    [agentMetrics, setAgentMetrics, handleTimeMediumAttendance],
  );

  const orderTagsByName = useCallback(
    (order: string) => {
      const array =
        order === 'DESC'
          ? attendanceTags.sort(
              (previous: IAttendanceTags, next: IAttendanceTags) => {
                return previous.tag.toUpperCase() === next.tag.toUpperCase()
                  ? 0
                  : previous.tag.toUpperCase() < next.tag.toUpperCase()
                  ? 1
                  : -1;
              },
            )
          : attendanceTags.sort(
              (previous: IAttendanceTags, next: IAttendanceTags) => {
                return previous.tag.toUpperCase() === next.tag.toUpperCase()
                  ? 0
                  : previous.tag.toUpperCase() > next.tag.toUpperCase()
                  ? 1
                  : -1;
              },
            );

      setAttendanceTags([...array]);
    },
    [attendanceTags, setAttendanceTags],
  );

  const orderTags = useCallback(
    (object_type: string, order: string) => {
      const array =
        order === 'DESC'
          ? attendanceTags.sort(
              (previous: IAttendanceTags, next: IAttendanceTags) => {
                return next[object_type] - previous[object_type];
              },
            )
          : attendanceTags.sort(
              (previous: IAttendanceTags, next: IAttendanceTags) => {
                return previous[object_type] - next[object_type];
              },
            );

      setAttendanceTags([...array]);
    },
    [attendanceTags, setAttendanceTags],
  );

  const handleOrderTable = useCallback(
    (order: string) => {
      switch (type) {
        case 'attendance':
          orderAttendancesByAttendance(order);
          break;
        case 'created_at':
          orderAttendancesByCreatedAt(order);
          break;
        case 'open' || 'close':
          orderAttendancesByTicket(order, type);
          break;
        case 'average_response_time':
          orderAttendancesByTime(order, type);
          break;
        case 'average_attendance_time':
          orderAttendancesByTime(order, type);
          break;
        case 'tag':
          orderTagsByName(order);
          break;
        case 'closed':
          orderTags(type, order);
          break;
        case 'attendance_time':
          orderTags(type, order);
          break;
        default:
          break;
      }
    },
    [
      type,
      orderAttendancesByAttendance,
      orderAttendancesByCreatedAt,
      orderAttendancesByTicket,
      orderAttendancesByTime,
      orderTagsByName,
      orderTags,
    ],
  );

  return (
    <Container>
      <Button>
        <OrderCrescent onClick={() => handleOrderTable('ASC')} />
      </Button>
      <Button>
        <OrderDecrescent onClick={() => handleOrderTable('DESC')} />
      </Button>
    </Container>
  );
};
