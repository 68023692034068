import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Swal from 'sweetalert2';

import { Link } from 'react-router-dom';
import apiChatbot from '../../../services/apiChatbot';

import { Container } from '../../../components/Container';
import { NavigationBar } from '../../../components/NavigationBar';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Title';

import {
  FileContainer,
  FilesHistoryContainer,
  TitleHistory,
  ButtonContainer,
  ClearHitoryButton,
  Table,
  Thead,
  TableRowHead,
  Divisor,
  CellHeader,
  TitleTable,
  Tbody,
  TableRowBody,
  CellBody,
  Button,
  SelectFileContainer,
  TitleFile,
  FileInputContainer,
  Option,
  SendFileContainer,
  Label,
  InputFile,
  TextFile,
  SendButtonContainer,
  ButtonSendFile,
  TextFileButton,
  NameCellHeader,
  NameCellBody,
  StateSelect,
} from './styles';
import formatDateWithHours from '../../../utils/formatDateWithHours';
import { PaginationComponent } from '../../../components/Pagination';
import { changePagination } from '../../../utils/changePagination';
import { IStateDebit } from '../StateDebit';
import { confirmModal } from '../../../components/ModalConfirm';

interface IFileHistory {
  id: number;
  created_at: Date;
  zip_file_path: string;
  xlsx_file_path: string;
}

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export const LawyersProcesses: React.FC = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [filesHistory, setFilesHistory] = useState<IFileHistory[]>([]);
  const [statesDatas, setStatesDatas] = useState<IStateDebit[]>([]);
  const [state, setState] = useState<string>(undefined);

  const [file, setFile] = useState<IFileProps>({} as IFileProps);

  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number[]>([] as number[]);
  const [currentItems, setCurrentItems] = useState<IFileHistory[]>(
    [] as IFileHistory[],
  );

  useEffect(() => {
    if (file.name && state) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [file, state]);

  useEffect(() => {
    const api = apiChatbot(localStorage.getItem('@Precato:token'));

    api.get('/lawyer_processes/state-debit').then(response => {
      setStatesDatas(response.data);
    });
  }, []);

  useEffect(() => {
    const api = apiChatbot(localStorage.getItem('@Precato:token'));

    api.get('/lawyer_processes').then(response => {
      setFilesHistory(response.data);

      setCurrentItems(response.data.slice(0, itemsPerPage));

      const pages: number[] = [];

      for (
        let index = 1;
        index <= Math.ceil(response.data.length / itemsPerPage);
        index++
      ) {
        pages.push(index);
      }
      setTotalPages(pages);
    });
  }, [file]);

  const sendFileSucess = useCallback(async () => {
    Swal.fire('Sucesso!', 'Requisição recebida com sucesso', 'success').then();
  }, []);

  const sendFileError = useCallback(async () => {
    Swal.fire('Erro!', 'Falha ao enviar requisição.', 'error');
  }, []);

  const handleDownload = useCallback(async (url: string) => {
    const [, filePath] = url.split('processes');

    const api = apiChatbot(localStorage.getItem('@Precato:token'));

    const response = await api.get(`/processes${filePath}`, {
      responseType: 'blob',
    });

    const fileName = filePath.split(/\\|\//g).pop();

    saveAs(response.data, fileName);
  }, []);

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const submitFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const getFile =
        event.target.files === null ? ({} as File) : event.target.files[0];

      const newFile: IFileProps = {
        file: getFile,
        name: getFile.name,
        readableSize: getFile.size.toString(),
      };

      setFile(newFile);

      event.target.value = null;
    },
    [setFile],
  );

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    try {
      data.append('file', file.file);

      const api = apiChatbot(localStorage.getItem('@Precato:token'));

      await api
        .post(`/lawyer_processes/upload/${state}`, data)
        .then(async response => {
          if (response.status === 202) {
            await delay(1000);

            setFile({} as IFileProps);
          }
        });

      sendFileSucess();
    } catch (error) {
      sendFileError();
    }
  }, [file, sendFileError, sendFileSucess, state]);

  const handleDelete = useCallback(async () => {
    const confirm = await confirmModal('Tem certeza que deseja excluir?');
    if (confirm) {
      const api = apiChatbot(localStorage.getItem('@Precato:token'));

      await api.delete(`/lawyer_processes/`).then(() => {
        setFile({} as IFileProps);
      });
    }
  }, []);

  const handleChangePagination = useCallback(
    (toPage: number) => {
      const items: IFileHistory[] = filesHistory;

      changePagination({
        page,
        totalPages,
        itemsPerPage,
        items,
        setCurrentItems,
        setPage,
        toPage,
      });
    },
    [page, totalPages, filesHistory],
  );

  return (
    <Container>
      <NavigationBar
        past_screens="Conexão > "
        active_screen="Newsletter Advogados"
      />

      <Body>
        <Title name="Newsletter Advogados" />

        <>
          <FileContainer>
            <FileInputContainer>
              <TitleFile>Arquivo:</TitleFile>
              <SendFileContainer>
                <SelectFileContainer>
                  <StateSelect
                    name="Estado"
                    id="Estado"
                    onChange={e => setState(e.target.value)}
                  >
                    <Option value="Selecione um estado" selected disabled>
                      Selecione um estado
                    </Option>
                    {statesDatas.map(stateItem => (
                      <Option key={stateItem.id} value={stateItem.state}>
                        {stateItem.state}
                      </Option>
                    ))}
                  </StateSelect>
                  {state ? (
                    <TextFile>{state}</TextFile>
                  ) : (
                    <TextFile>Nenhum estado selecionado</TextFile>
                  )}
                </SelectFileContainer>
              </SendFileContainer>

              <SendFileContainer>
                <SelectFileContainer>
                  <Label htmlFor="file">Selecione um arquivo</Label>
                  <InputFile
                    onChange={event => submitFile(event)}
                    id="file"
                    type="file"
                    name="file"
                  />
                  {file.name ? (
                    <TextFile>{file.name}</TextFile>
                  ) : (
                    <TextFile>Nenhum arquivo selecionado</TextFile>
                  )}
                </SelectFileContainer>
              </SendFileContainer>

              <SendButtonContainer>
                <ButtonSendFile
                  isEnabled={isEnabled}
                  type="button"
                  onClick={() => handleUpload()}
                >
                  Enviar
                </ButtonSendFile>
                <TextFileButton>
                  Arquivos serão processados automaticamente
                </TextFileButton>
              </SendButtonContainer>
            </FileInputContainer>
          </FileContainer>

          <ButtonContainer>
            <Link to="/newsletter/state-debit">
              <ClearHitoryButton type="button">
                Adicionar Estado
              </ClearHitoryButton>
            </Link>
            <ClearHitoryButton onClick={handleDelete} type="button">
              Limpar histórico
            </ClearHitoryButton>
          </ButtonContainer>

          <FilesHistoryContainer>
            <TitleHistory>Acompanhamentos anteriores</TitleHistory>
          </FilesHistoryContainer>

          <Table>
            <Thead>
              <TableRowHead>
                <CellHeader>
                  <TitleTable>Data de importação</TitleTable>
                  <Divisor />
                </CellHeader>
                <NameCellHeader>
                  <TitleTable>Nome do arquivo</TitleTable>
                  <Divisor />
                </NameCellHeader>
                <CellHeader>
                  <TitleTable>Download</TitleTable>
                </CellHeader>
              </TableRowHead>
            </Thead>
            <Tbody>
              {currentItems.length > 0 &&
                currentItems.map(fileHistory => (
                  <TableRowBody key={fileHistory.id}>
                    <CellBody>
                      {formatDateWithHours(fileHistory.created_at)}
                    </CellBody>
                    <NameCellBody
                      onClick={() => {
                        handleDownload(fileHistory.xlsx_file_path);
                      }}
                    >
                      {fileHistory.xlsx_file_path.split(/\\|\//g).pop()}
                    </NameCellBody>

                    <CellBody>
                      {fileHistory.zip_file_path === 'Processando' ? (
                        'Processando...'
                      ) : (
                        <Button
                          onClick={() => {
                            handleDownload(fileHistory.zip_file_path);
                          }}
                        >
                          PDFs
                        </Button>
                      )}
                    </CellBody>
                  </TableRowBody>
                ))}
            </Tbody>
          </Table>
          <PaginationComponent
            totalPages={totalPages}
            page={page}
            handleChangePagination={handleChangePagination}
          />
        </>
      </Body>
    </Container>
  );
};
