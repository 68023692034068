import { useCallback, useState, ChangeEvent, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { addMinutes, format, isBefore } from 'date-fns';

import edit_icon from '../../../../assets/icons/edit.svg';
import edit_scheduling_manager from '../../../../assets/icons/edit_scheduling_manager.svg';
import cancel_icon from '../../../../assets/icons/cancel.svg';
import delete_icon from '../../../../assets/icons/trash.svg';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import {
  ModalSchedule,
  ModalMain,
  ModalHeader,
  ButtonsContainer,
  ModalBody,
  InputsContainer,
  LineSeparator,
  EditSchedulingByManagerContainer,
  ButtonContact,
  ButtonContainer,
} from './styles';

import { IActivityDTO } from '../../dtos/IActivityDTO';
import { INextActivityDTO } from '../../dtos/INextActivityDTO';
import { IModalInfoDTO } from '../../Closer/dtos/IModalInfoDTO';
import { SchedulingContactButton } from '../../../../components/SchedulingContactButton';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';
import { confirmModal } from '../../../../components/ModalConfirm';
import { ModalContacts } from '../ModalContacts';
import { PriorityDurationButton } from '../../../../components/PriorityDurationButton';

Modal.setAppElement('#root');

interface ISchedulePriorityModalProps {
  type: string;
  isOpen: boolean;
  activity: IActivityDTO | INextActivityDTO;
  onRequestClose: () => void;
  handleInfo: ((data: IModalInfoDTO) => void) | null;
  handleUpdateActivity: (
    activity: IActivityDTO | INextActivityDTO,
    typeActivity?: string,
    oldHour?: string,
  ) => void;
  closerHistory: boolean;
  handleOpenSLAModal: () => void | null;
}
interface IIntervals {
  intervalInit: Date[];
  intervalEnd: Date[];
}

interface IScheduleFormData {
  observations?: string;
  try_times?: number;
}

interface IPossibleHours {
  hour: Date;
  period: string;
  closers: string[];
}

interface IClosersAvailable {
  morning: IPossibleHours[];
  afternoon: IPossibleHours[];
  evening: IPossibleHours[];
}

interface IClosers {
  id: string;
  name: string;
}

export function ModalSchedulePriority({
  type,
  isOpen,
  activity,
  handleInfo,
  onRequestClose,
  handleUpdateActivity,
  closerHistory,
  handleOpenSLAModal,
}: ISchedulePriorityModalProps): JSX.Element {
  const [editFields, setEditFields] = useState<boolean>(false);
  const [editSchedulingByManager, setEditSchedulingByManager] = useState(false);
  const [formData, setFormData] = useState<IScheduleFormData>();
  const [hoursAvailable, setHoursAvailable] = useState<IIntervals>(
    {} as IIntervals,
  );
  const { user } = useAuth();

  const [closersAvailable, setClosersAvailable] = useState<IClosersAvailable>(
    {} as IClosersAvailable,
  );
  const [filterCloserAvailable, setFilterCloserAvailable] = useState<
    IClosers[]
  >([] as IClosers[]);

  const talkArray = ['Sim', 'Não'];
  const businessSituation = ['Em aberto', 'Perdido'];
  const contactArray = ['Telefone', 'Whatsapp', 'Advogado', 'E-mail'];
  const durationArray = [5, 10, 15, 30];
  const activitySituation = ['Pendente', 'Concluída'];

  const [talkOption, setTalkOption] = useState<string>('');
  const [contactOption, setContactOption] = useState<string>('');
  const [durationOption, setDurationOption] = useState<number>(15);
  const [activitySituationOption, setActivitySituationOption] =
    useState<string>('');
  const [businessSituationOption, setBusinessSituationOption] =
    useState<string>('');

  const [hourOption, setHourOption] = useState<string>('');
  const [closerID, setCloserID] = useState<string>('');

  const [openContactsModal, setOpenContactsModel] = useState<boolean>(false);

  const handleOpenContactsModal = useCallback(() => {
    setOpenContactsModel(true);
  }, []);

  const handleCloseContactsModal = useCallback(() => {
    setOpenContactsModel(false);
  }, []);

  const closeModal = useCallback(() => {
    onRequestClose();
    setEditFields(false);
    setTalkOption('');
    setContactOption('');
    setActivitySituationOption('');
    setBusinessSituationOption('');
    setHourOption('');
    setFormData({
      observations: '',
      try_times: 0,
    });
    setEditSchedulingByManager(false);
  }, [onRequestClose]);

  const editScheduleSuccess = useCallback(() => {
    toast.success('Agendamento editado com sucesso');
  }, []);

  const editActivitySuccess = useCallback(() => {
    toast.success('Atividade editada com sucesso');
  }, []);

  const deleteActivitySuccess = useCallback(() => {
    toast.success('Atividade deletada com sucesso');
  }, []);

  const actionActivityError = useCallback((message: string) => {
    Swal.fire('Erro!', `${message}`, 'error');
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const formattedTimestamp = useCallback((timestamp: Date | undefined) => {
    return timestamp
      ? `${String(new Date(timestamp).getDate()).padStart(2, '0')}/${String(
          new Date(timestamp).getMonth() + 1,
        ).padStart(2, '0')}/${new Date(timestamp).getFullYear()} - ${new Date(
          timestamp,
        ).getHours()}:${String(new Date(timestamp).getMinutes()).padStart(
          2,
          '0',
        )}`
      : '';
  }, []);
  const formattedTimestampHour = useCallback((timestamp: Date | undefined) => {
    return timestamp
      ? `${new Date(timestamp).getFullYear()}-${String(
          new Date(timestamp).getMonth() + 1,
        ).padStart(2, '0')}-${String(new Date(timestamp).getDate()).padStart(
          2,
          '0',
        )}`
      : '';
  }, []);

  const formattedDate = useCallback(() => {
    const formatted = format(new Date(), 'yyyy-MM-dd');

    return formatted;
  }, []);

  const formattedTime = useCallback((time: Date | string) => {
    return time
      ? `${new Date(time).getHours()}:${String(
          new Date(time).getMinutes(),
        ).padStart(2, '0')}`
      : '';
  }, []);

  const formattedEndTime = useCallback((timestamp: Date | undefined) => {
    return timestamp
      ? `${String(new Date(timestamp).getHours())}:${String(
          new Date(timestamp).getMinutes(),
        ).padStart(2, '0')}`
      : '';
  }, []);

  const formattedMinutes = useCallback(
    (date: Date, minutes: number) => {
      const newDate = new Date(date);
      const dateWithMinutes = addMinutes(newDate, minutes);
      const formatDate = formattedEndTime(dateWithMinutes);
      return formatDate;
    },
    [formattedEndTime],
  );

  const verifyIsBeforeNow = useCallback((timestamp: Date | string) => {
    return isBefore(new Date(timestamp), new Date());
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTalkOption(activity.talk);
      setFormData({
        try_times: Number(activity.try_times),
        observations: activity.observations,
      });
      setContactOption(activity.contact || '');
      setActivitySituationOption(
        activity.status ? activity.status : 'Pendente',
      );
      setBusinessSituationOption(
        activity.business_situation ? activity.business_situation : 'Em aberto',
      );
    }
  }, [activity, isOpen]);

  const unformatAmount = useCallback(() => {
    return activity.amount
      ? Number(activity.amount.substring(3).replace('.', '').replace(',', '.'))
      : 0;
  }, [activity]);

  useMemo(async () => {
    if (
      isOpen &&
      (user.profile === 'Gestores' ||
        user.profile === 'Gestor Linha 1' ||
        user.profile === 'Administrador' ||
        user.profile === 'Gestor Operação Comercial' ||
        user.profile === 'Gestao Conexao')
    ) {
      await api
        .get(
          `schedule/list-possible-hours?date=${formattedTimestampHour(
            activity.timestamp,
          )}&amount=${String(unformatAmount())}&hunter_id=${
            activity.hunter_id
          }&loa=${activity.loa}&contact_preference=${
            activity.contact_preference
          }&connection=${activity.connection_method}&dono_do_lead___atual=${
            activity.current_lead_owner
          }`,
        )
        .then(response => {
          setClosersAvailable(response.data);
        });
    }
  }, [activity, unformatAmount, isOpen, user, formattedTimestampHour]);

  useEffect(() => {
    if (
      isOpen &&
      (user.profile === 'Closers' || user.profile === 'Closer Linha 1')
    ) {
      api
        .get(
          `schedule/list-closer-possible-hours?closer_id=${
            user.id
          }&date=${formattedDate()}&activity_id=${activity.id}`,
        )
        .then(response => {
          setHoursAvailable(response.data);
        });
    }
  }, [user, formattedDate, isOpen, activity]);

  const handleFilterClosersAvailable = useCallback(async () => {
    try {
      setEditSchedulingByManager(true);

      const availableClosers = await api
        .get(`/schedule//list-closers-line?scheduling_id=${activity.id}`)
        .then(response => {
          return response.data;
        });

      setFilterCloserAvailable(availableClosers);
    } catch (error) {
      actionActivityError(error.response.data.message);
    }
  }, [activity, actionActivityError]);

  const handleDeleteActivity = useCallback(async () => {
    const confirm = await confirmModal('Tem certeza que deseja excluir?');

    if (confirm) {
      try {
        if (type === 'Scheduling') {
          const response = await api.patch('schedule/update-scheduling', {
            id: activity.id,
            deleted: true,
            deleted_by: user.id,
          });

          const oldHour = String(activity.timestamp);

          handleUpdateActivity(response.data, 'Scheduling', oldHour);
        } else {
          const response = await api.patch('schedule/update-hubspot-priority', {
            id: activity.id,
            deleted: true,
          });

          const oldHour = activity.notes_next_activity_date;

          handleUpdateActivity(response.data, 'Priority', oldHour);
        }

        deleteActivitySuccess();
        closeModal();
      } catch (error) {
        actionActivityError(error.response.data.message);
      }
    }
  }, [
    user,
    type,
    activity,
    deleteActivitySuccess,
    actionActivityError,
    handleUpdateActivity,
    closeModal,
  ]);

  const handleSubmit = useCallback(
    async (is_manager?: boolean) => {
      try {
        if (type === 'Scheduling' && is_manager) {
          const oldHour = new Date(activity.timestamp);

          const response = await api.patch('schedule/update-scheduling', {
            id: activity.id,
            closer_id: closerID,
          });

          handleUpdateActivity(response.data, 'Scheduling', String(oldHour));
          editScheduleSuccess();
        } else if (type === 'Scheduling' && !is_manager) {
          const oldHour = new Date(activity.timestamp);

          const response = await api.patch('schedule/update-scheduling', {
            id: activity.id,
            observations: formData.observations,
            try_times: formData.try_times,
            talk: talkOption,
            contact: contactOption,
            status: activitySituationOption,
            business_situation: businessSituationOption,
            timestamp: hourOption
              ? new Date(`${formattedDate()} ${hourOption}`)
              : new Date(activity.timestamp),
          });

          handleInfo({
            try_times: formData.try_times ? Number(formData.try_times) : 0,
            spoken: talkOption,
            activity_id: activity.id,
            timestamp: activity.timestamp,
          });

          handleUpdateActivity(response.data, 'Scheduling', String(oldHour));
          editScheduleSuccess();

          api
            .get(
              `schedule/list-closer-possible-hours?closer_id=${
                user.id
              }&date=${formattedDate()}`,
            )
            .then(response_hour_available => {
              setHoursAvailable(response_hour_available.data);
            });

          if (talkOption === 'Sim') {
            handleOpenSLAModal();
          }
        } else if (type === 'Transfer') {
          const oldHour = new Date(activity.timestamp);

          const response = await api.post('/schedule/update-transfer', {
            id: activity.id,
            business_situation: businessSituationOption,
            observations: formData.observations,
            status: activitySituationOption,
          });

          handleUpdateActivity(response.data, 'Transfer', String(oldHour));

          if (activitySituationOption === 'Concluída') {
            handleOpenSLAModal();
          }

          editActivitySuccess();
        } else {
          const oldHour = activity.notes_next_activity_date;

          const response = await api.patch('schedule/update-hubspot-priority', {
            id: activity.id,
            observations: formData.observations,
            try_times: formData.try_times,
            talk: talkOption,
            contact: contactOption,
            duration: durationOption,
            status: activitySituationOption,
            business_situation: businessSituationOption,
            notes_next_activity_date: hourOption
              ? `${formattedDate()} ${hourOption}`
              : activity.notes_next_activity_date,
          });

          handleInfo({
            try_times: formData.try_times ? Number(formData.try_times) : 0,
            spoken: talkOption,
            activity_id: activity.id,
            notes_next_activity_date: activity.notes_next_activity_date,
          });

          handleUpdateActivity(response.data, 'Priority', oldHour);
          editActivitySuccess();

          api
            .get(
              `schedule/list-closer-possible-hours?closer_id=${
                user.id
              }&date=${formattedDate()}`,
            )
            .then(response_hour_available => {
              setHoursAvailable(response_hour_available.data);
            });
        }

        if (talkOption === 'Sim') {
          handleOpenSLAModal();
        }

        closeModal();
      } catch (error) {
        actionActivityError(error.response.data.message);
      }
    },
    [
      type,
      closeModal,
      activity,
      closerID,
      handleUpdateActivity,
      editScheduleSuccess,
      formData,
      talkOption,
      contactOption,
      activitySituationOption,
      businessSituationOption,
      hourOption,
      formattedDate,
      handleInfo,
      user.id,
      handleOpenSLAModal,
      editActivitySuccess,
      actionActivityError,
      durationOption,
    ],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={ModalSchedule}
      data={activity}
    >
      <ModalMain>
        <ModalHeader
          isBefore={verifyIsBeforeNow(
            activity.timestamp
              ? activity.timestamp
              : activity.notes_next_activity_date,
          )}
          talkProps={activity.talk}
          statusTaskProps={activity.status ? activity.status : 'Pendente'}
          tryTimesProps={String(activity.try_times)}
        >
          {type === 'Scheduling' ? (
            <p>
              {formattedTimestamp(activity.timestamp)} às{' '}
              {formattedMinutes(activity.timestamp, 30)}
            </p>
          ) : type === 'Transfer' ? (
            <p>
              {formattedTimestamp(activity.timestamp)} às{' '}
              {formattedMinutes(activity.timestamp, 30)}
            </p>
          ) : type === 'Priority' && activity.notes_next_activity_date ? (
            <p>
              {formattedTimestamp(new Date(activity.notes_next_activity_date))}{' '}
              às{' '}
              {formattedMinutes(
                new Date(activity.notes_next_activity_date),
                activity.duration || 15,
              )}
            </p>
          ) : type === 'ToDefine' && activity.notes_next_activity_date ? (
            <p>{activity.notes_next_activity_date}</p>
          ) : (
            ''
          )}

          <ButtonsContainer>
            {((type === 'Scheduling' || type === 'Transfer') &&
              !activity.hunter_name &&
              !closerHistory) ||
            (type === 'Priority' && activity.create_by && !closerHistory) ? (
              <button
                id="deleteActivity"
                type="button"
                onClick={handleDeleteActivity}
              >
                <img src={delete_icon} alt="Excluir atividade" />
              </button>
            ) : (type === 'Scheduling' || type === 'Transfer') &&
              activity.hunter_name &&
              closerHistory ? (
              <button
                id="deleteActivity"
                type="button"
                onClick={handleDeleteActivity}
              >
                <img src={delete_icon} alt="Excluir atividade" />
              </button>
            ) : (
              ''
            )}
            {!editFields && editSchedulingByManager ? (
              <button
                id="saveChanges"
                type="button"
                onClick={() => handleSubmit(true)}
              >
                Salvar alterações
              </button>
            ) : editFields && !editSchedulingByManager ? (
              <button
                id="saveChanges"
                type="button"
                onClick={() => handleSubmit(false)}
              >
                Salvar alterações
              </button>
            ) : !editFields && !closerHistory ? (
              <button type="button" onClick={() => setEditFields(true)}>
                <img src={edit_icon} alt="Editar informações" />
              </button>
            ) : (type === 'Scheduling' || type === 'Transfer') &&
              activity.hunter_name &&
              closerHistory ? (
              <button type="button" onClick={handleFilterClosersAvailable}>
                <img src={edit_scheduling_manager} alt="Editar informações" />
              </button>
            ) : (
              ''
            )}
            <button id="closeModal" type="button" onClick={closeModal}>
              <img src={cancel_icon} alt="Fechar modal" />
            </button>
          </ButtonsContainer>
        </ModalHeader>

        <ModalBody>
          {!editSchedulingByManager ? (
            <>
              {type === 'Scheduling' ? (
                <>
                  <span>Agendamento</span>
                  <InputsContainer>
                    <label htmlFor="name_hunter">Hunter:</label>
                    <input
                      type="text"
                      name="name_hunter"
                      className="fieldsName"
                      value={activity.hunter_name ? activity.hunter_name : ''}
                      disabled
                    />
                  </InputsContainer>
                </>
              ) : type === 'Transfer' ? (
                <>
                  <span>Transferência</span>
                  <InputsContainer>
                    <label htmlFor="name_hunter">Hunter:</label>
                    <input
                      type="text"
                      name="name_hunter"
                      className="fieldsName"
                      value={activity.hunter_name ? activity.hunter_name : ''}
                      disabled
                    />
                  </InputsContainer>
                </>
              ) : (
                <span>Priorização</span>
              )}

              <InputsContainer>
                <label htmlFor="negocio_id">ID do negócio:</label>
                <input
                  type="text"
                  name="negocio_id"
                  value={
                    activity.hubspot_deal_id ? activity.hubspot_deal_id : ''
                  }
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="credor_name">Nome do credor:</label>
                <input
                  type="text"
                  name="credor_name"
                  className="fieldsName"
                  value={activity.dealname ? activity.dealname : ''}
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="amount">Valor:</label>
                <input
                  type="text"
                  name="amount"
                  value={activity.amount ? activity.amount : ''}
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="stage">Etapa:</label>
                <p id="stage">{activity.stage_name}</p>
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="contact">Contato:</label>
                <input
                  type="text"
                  name="contact"
                  value={
                    activity.contact_preference
                      ? activity.contact_preference
                      : ''
                  }
                  disabled
                />
              </InputsContainer>
              {type === 'Priority' ? (
                <InputsContainer>
                  <label htmlFor="duration">Duração (minutos):</label>
                  <input
                    type="text"
                    name="contact"
                    value={activity.duration ? activity.duration : 15}
                    disabled
                  />
                </InputsContainer>
              ) : (
                ''
              )}

              <InputsContainer>
                <label htmlFor="valid_contact">Contato válido:</label>
                <input
                  type="text"
                  name="valid_contact"
                  value={activity.contato_valido ? activity.contato_valido : ''}
                  disabled
                />
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="link_hubspot">Link Hubspot:</label>
                <a
                  href={`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {`https://app.hubspot.com/contacts/7857327/deal/${activity.hubspot_deal_id}`}
                </a>
              </InputsContainer>

              <InputsContainer>
                <label htmlFor="connection_method">Conexão:</label>
                <input
                  type="text"
                  name="connection_method"
                  value={
                    activity.connection_method ? activity.connection_method : ''
                  }
                  disabled
                />
              </InputsContainer>

              {editFields ? (
                <>
                  <LineSeparator />

                  <InputsContainer id="observations">
                    <label htmlFor="observations">Observações:</label>
                    <textarea
                      rows={1}
                      id="observationsTextarea"
                      name="observations"
                      defaultValue={activity.observations}
                      onChange={handleInputChange}
                    />
                  </InputsContainer>

                  {type !== 'Transfer' ? (
                    <>
                      <InputsContainer className="editInputsContainer">
                        <label htmlFor="try_times">Tentou:</label>
                        <input
                          min="0"
                          type="number"
                          name="try_times"
                          id="tryTimesInput"
                          value={formData.try_times ? formData.try_times : ''}
                          onChange={handleInputChange}
                        />
                      </InputsContainer>

                      <InputsContainer className="editInputsContainer">
                        <label htmlFor="spoken">Falado:</label>
                        {talkArray.map(talk => (
                          <ScheduleOptionsButton
                            key={talk}
                            selectedValue={talkOption === talk}
                            type="button"
                            onClick={() =>
                              setTalkOption(talkOption === talk ? '' : talk)
                            }
                          >
                            {talk}
                          </ScheduleOptionsButton>
                        ))}
                      </InputsContainer>

                      <InputsContainer className="editInputsContainer">
                        <label htmlFor="contact">Contato:</label>
                        {contactArray.map(contact => (
                          <SchedulingContactButton
                            key={contact}
                            type="button"
                            contactValue={contactOption}
                            selected={contact}
                            onClick={() => {
                              if (contactOption.includes(contact))
                                setContactOption(option => {
                                  return option.replace(`${contact};`, '');
                                });
                              else
                                setContactOption(option => {
                                  return option.concat(contact, ';');
                                });
                            }}
                          >
                            {contact}
                          </SchedulingContactButton>
                        ))}
                      </InputsContainer>
                    </>
                  ) : (
                    <></>
                  )}
                  <InputsContainer className="editInputsContainer">
                    <label htmlFor="duration">Duração (minutos):</label>
                    {durationArray.map(duration => (
                      <PriorityDurationButton
                        key={duration}
                        type="button"
                        durationValue={durationOption}
                        selected={duration}
                        onClick={() => {
                          setDurationOption(duration);
                        }}
                      >
                        {duration}
                      </PriorityDurationButton>
                    ))}
                  </InputsContainer>

                  <InputsContainer className="editInputsContainer situationActivity">
                    <label htmlFor="business_situation">
                      Situação do negócio:
                    </label>
                    {businessSituation.map(situation => (
                      <ScheduleOptionsButton
                        key={situation}
                        selectedValue={businessSituationOption === situation}
                        type="button"
                        onClick={() => setBusinessSituationOption(situation)}
                      >
                        {situation}
                      </ScheduleOptionsButton>
                    ))}
                  </InputsContainer>

                  <InputsContainer className="editInputsContainer situationActivity">
                    <label htmlFor="activity_situation">
                      Situação da atividade:
                    </label>
                    {activitySituation.map(situation => (
                      <ScheduleOptionsButton
                        key={situation}
                        selectedValue={activitySituationOption === situation}
                        type="button"
                        onClick={() => setActivitySituationOption(situation)}
                      >
                        {situation}
                      </ScheduleOptionsButton>
                    ))}
                  </InputsContainer>

                  {(type !== 'Scheduling' && type !== 'Transfer') ||
                  !activity.hunter_name ? (
                    <>
                      <LineSeparator />
                      <InputsContainer className="hours">
                        <label htmlFor="hour">
                          Selecione um horário livre:
                        </label>
                        <div>
                          {hoursAvailable &&
                            hoursAvailable.intervalInit.map(possibleHour => (
                              <ScheduleOptionsButton
                                key={String(possibleHour)}
                                selectedValue={
                                  `${hourOption.split(':', 2)[0]}:${
                                    hourOption.split(':', 2)[1]
                                  }` === formattedTime(possibleHour)
                                }
                                type="button"
                                onClick={() =>
                                  setHourOption(
                                    hourOption === formattedTime(possibleHour)
                                      ? ''
                                      : formattedTime(possibleHour),
                                  )
                                }
                              >
                                {formattedTime(possibleHour)}
                              </ScheduleOptionsButton>
                            ))}
                        </div>
                      </InputsContainer>
                    </>
                  ) : (
                    ' '
                  )}
                </>
              ) : (
                <>
                  {activity.calculation_status ? (
                    <InputsContainer>
                      <label htmlFor="calculation_status">
                        Situação do cálculo <b>(ESTADUAL)</b>:
                      </label>
                      <input
                        type="text"
                        name="calculation_status"
                        value={
                          activity.calculation_status
                            ? activity.calculation_status
                            : 'Pendente'
                        }
                        disabled
                      />
                    </InputsContainer>
                  ) : (
                    ''
                  )}

                  <InputsContainer id="observations">
                    <label htmlFor="observations">Observações:</label>
                    <textarea
                      name="observations"
                      value={activity.observations ? activity.observations : ''}
                      disabled
                    />
                  </InputsContainer>

                  <InputsContainer>
                    <label htmlFor="">Tentou:</label>
                    <input
                      type="number"
                      name="try_times"
                      value={activity.try_times ? activity.try_times : 0}
                      disabled
                    />
                  </InputsContainer>

                  <InputsContainer>
                    <label htmlFor="spoken">Falado:</label>
                    <input
                      type="text"
                      name="spoken"
                      value={activity.talk ? activity.talk : ''}
                      disabled
                    />
                  </InputsContainer>

                  <InputsContainer>
                    <label htmlFor="contact">Contato: </label>
                    <input
                      type="text"
                      name="contact"
                      className="fieldsName"
                      value={
                        activity.contact ? activity.contact.split(';') : ''
                      }
                      disabled
                    />
                  </InputsContainer>

                  <InputsContainer
                    statusBusinessTask={
                      activity.business_situation
                        ? activity.business_situation
                        : 'Em aberto'
                    }
                  >
                    <label htmlFor="business_situation">
                      Situação do negócio:
                    </label>
                    <input
                      type="text"
                      name="business_situation"
                      id="business_situation"
                      value={
                        activity.business_situation
                          ? activity.business_situation
                          : 'Em aberto'
                      }
                      disabled
                    />
                  </InputsContainer>

                  <InputsContainer
                    statusTaskProps={
                      activity.status ? activity.status : 'Pendente'
                    }
                  >
                    <label htmlFor="activity_situation">
                      Situação da atividade:
                    </label>
                    <input
                      type="text"
                      name="activity_situation"
                      id="activity_situation"
                      value={activity.status ? activity.status : 'Pendente'}
                      disabled
                    />
                  </InputsContainer>
                </>
              )}
            </>
          ) : (
            <EditSchedulingByManagerContainer>
              <span id="title-choose-closer">
                Selecione um closer disponível:
              </span>

              {filterCloserAvailable && filterCloserAvailable.length > 0 ? (
                filterCloserAvailable.map(closer => (
                  <ScheduleOptionsButton
                    key={closer.id}
                    selectCloser
                    selectedValue={closerID === closer.id}
                    type="button"
                    onClick={() => {
                      setCloserID(closer.id);
                    }}
                  >
                    {closer.name}
                  </ScheduleOptionsButton>
                ))
              ) : (
                <span>Não há closer disponível!</span>
              )}
            </EditSchedulingByManagerContainer>
          )}
          {user.id === '68ffa623-0c7e-4d70-b24b-d063c48d9e66' ? (
            <>
              <ButtonContainer>
                <ButtonContact onClick={handleOpenContactsModal}>
                  Entrar em contato
                </ButtonContact>
              </ButtonContainer>
              <ModalContacts
                isOpen={openContactsModal}
                onRequestClose={handleCloseContactsModal}
                hubspot_deal_id={activity.hubspot_deal_id}
                dealname={activity.dealname}
                task_id={activity.id}
                task_type={type}
              />
            </>
          ) : (
            ''
          )}
        </ModalBody>
      </ModalMain>
    </Modal>
  );
}
