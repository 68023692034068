import { useCallback, useState } from 'react';
import { formatAmount } from '../../../utils/formatAmount';
import { ICasePriorizationDTO } from '../dtos/ICasePriorizationDTO';

import {
  Container,
  DateContainer,
  ResumeFields,
  FieldColumn,
  RegistriesContainer,
  RegitryActivity,
  InfoButton,
} from './styles';
import { IQuantityCasesDTO } from '../dtos/IQuantityCasesDTO';
import { ModalUpdateActivity } from '../ModalUpdateActivity';

interface ResumePanelProps {
  quantityCases: IQuantityCasesDTO;
  casePriorizations: ICasePriorizationDTO[];
  setCasePriorizations: (cases: ICasePriorizationDTO[]) => void;
  nameTicket: string;
  firstCase: ICasePriorizationDTO;
}

export function ResumePanel({
  quantityCases,
  casePriorizations,
  nameTicket,
  firstCase,
  setCasePriorizations,
}: ResumePanelProps): JSX.Element {
  const [openCaseModal, setOpenCaseModal] = useState(false);

  const handleCloseActivityModal = useCallback(() => {
    setOpenCaseModal(false);
  }, []);

  const handleOpenActivityModal = useCallback(() => {
    setOpenCaseModal(true);
  }, []);

  return (
    <Container>
      <DateContainer>
        <p>{new Date().getDate()}</p>
        <p>{new Date().toLocaleString('default', { month: 'short' })}</p>
        <p>{new Date().getFullYear()}</p>
      </DateContainer>

      <ResumeFields>
        <FieldColumn>
          <span className="title">
            Casos <br />
            Prioridade 1:
          </span>
          <span className="value">{quantityCases.quantityPriority1}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">
            Casos <br /> Prioridade 2:
          </span>
          <span className="value">{quantityCases.quantityPriority2}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">
            Casos <br /> Prioridade 3:
          </span>
          <span className="value">{quantityCases.quantityPriority3}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">
            Casos <br /> Prioridade 4:
          </span>
          <span className="value">{quantityCases.quantityPriority4}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">
            Casos <br /> Prioridade 5:
          </span>
          <span className="value">{quantityCases.quantityPriority5}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">
            Casos <br /> Prioridade 6:
          </span>
          <span className="value">{quantityCases.quantityPriority6}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">
            Casos <br /> Prioridade 7:
          </span>
          <span className="value">{quantityCases.quantityPriority7}</span>
        </FieldColumn>

        <FieldColumn>
          <span className="title">Próximo caso:</span>

          <RegistriesContainer>
            <RegitryActivity key={firstCase.hubspot_ticket_id}>
              <div>
                <span className="title-registry">ID do Ticket:</span>
                <span className="value-registry">
                  {firstCase.hubspot_ticket_id}
                </span>
              </div>
              <div>
                <span className="title-registry">Nome do Credor:</span>
                <span className="value-registry">{nameTicket}</span>
              </div>
              <div>
                <span className="title-registry">Valor:</span>
                <span className="value-registry">
                  {formatAmount(Number(firstCase.amount))}
                </span>
              </div>
              <div>
                <span className="title-registry">Prioridade:</span>
                <span className="value-registry">{firstCase.priority}</span>
              </div>
              <InfoButton onClick={() => handleOpenActivityModal()}>
                + INFORMAÇÕES
              </InfoButton>
            </RegitryActivity>
          </RegistriesContainer>
        </FieldColumn>
      </ResumeFields>

      {Object.keys(firstCase).length > 0 && (
        <ModalUpdateActivity
          isOpen={openCaseModal}
          onRequestClose={handleCloseActivityModal}
          activity={firstCase}
          allCases={casePriorizations}
          setAllCases={setCasePriorizations}
          currentItems={casePriorizations}
          setCurrentItems={setCasePriorizations}
        />
      )}
    </Container>
  );
}
