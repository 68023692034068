export function formatTelephoneNumber(telephone: string): string {
  return telephone
    .replace(/\D/g, '')

    .replace(/(\d{2})(\d)/, '($1) $2')

    .replace(/(\d{4})(\d)/, '$1-$2')

    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')

    .replace(/(-\d{4})\d+?$/, '$1');
}

export function unFormatTelephoneNumber(telephone: string): string {
  return telephone.replace(/[\s()-]/g, '');
}
