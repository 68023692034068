import { ButtonSave, InputContainer } from '../../styles';

interface IInputSLAProps {
  handleSaveSLA: () => void;
}

export function InputButtonSave({
  handleSaveSLA,
}: IInputSLAProps): JSX.Element {
  return (
    <InputContainer>
      <ButtonSave onClick={handleSaveSLA}>Salvar</ButtonSave>
    </InputContainer>
  );
}
