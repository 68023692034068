import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { DefaultTable } from './DefaultTable';
import { IWorkDayDTO } from './dtos/IWorkDayDTO';
import { EditorTable } from './EditorTable';
import { Separator, Title } from './styles';

export function WorkTime(): JSX.Element {
  const [editor, setEditor] = useState(false);
  const [workTime, setWorkTime] = useState<IWorkDayDTO[]>([]);

  const changeEditorMode = useCallback(() => {
    setEditor(!editor);
  }, [editor]);

  const getWorkTime = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_MARKETING_URL}/zendesk/work-time`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
        },
      },
    );

    setWorkTime(
      data.work_schedule.sort(
        (firstDay: IWorkDayDTO, secondDay: IWorkDayDTO) =>
          firstDay.week_day - secondDay.week_day,
      ),
    );
  }, []);

  useEffect(() => {
    getWorkTime();
  }, [getWorkTime]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Conexão {'>'}</H1Navigation>
        <H1NavigationNow>Horário de Atendimento</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Horário de Atendimento</Title>

        <Separator />

        {editor ? (
          <EditorTable
            goToViewMode={changeEditorMode}
            getWorkTime={getWorkTime}
            receivedWorkTime={workTime}
          />
        ) : (
          <DefaultTable goToEditorMode={changeEditorMode} workTime={workTime} />
        )}
      </Body>
    </Container>
  );
}
