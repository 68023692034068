import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.175rem;
`;

export const Button = styled.button`
  border: none;
  height: 0.5rem;
  background: transparent;

  margin: auto;

  svg {
    margin-bottom: 0.55rem;
  }
`;
