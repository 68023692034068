import styled from 'styled-components';

export const ModalClosers = {
  content: {
    width: '18rem',
    // minHeight: '3.75rem',
    height: '15.35rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
  },
};

export const ModalHeader = styled.div`
  width: 100%;
  height: 2.313rem;
  text-align: center;

  padding: 0 0.688rem;

  position: sticky;
  top: 0;

  background: #cfdef3;
  border-radius: 0.625rem 0.625rem 0 0;

  display: flex;
  align-items: center;

  p {
    display: flex;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    color: #05233e;
    width: 95%;
    padding-left: 2%;
  }
`;

export const CloseModalButton = styled.button`
  background-color: transparent;
  color: #1665d8;
  border: 0;
  width: 2%;

  img {
    width: 0.938rem;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.938rem 0.688rem 0.688rem 0.938rem;

  span {
    width: 16.625rem;
    height: 1.875rem;

    background: #efefef;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 0.25rem;

    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    color: #3d3d3d;
  }
`;
