/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IChangePagination {
  page: number;
  totalPages: number[];
  itemsPerPage: number;
  setCurrentItems: (items: any) => void;
  items: any[];
  setPage: (page: any) => void;
  toPage: number;
}

export function changePagination({
  page,
  totalPages,
  itemsPerPage,
  setCurrentItems,
  items,
  setPage,
  toPage,
}: IChangePagination) {
  let pageIndex: number;

  let newOffset: number;

  if (toPage === -1) {
    if (page !== 1) {
      pageIndex = totalPages.findIndex(foundpage => foundpage === page - 1);

      newOffset = pageIndex * itemsPerPage;

      const endOffset = newOffset + itemsPerPage;

      setCurrentItems(items.slice(newOffset, endOffset));

      setPage(prevPage => prevPage - 1);
    }
  } else if (toPage === 0) {
    if (page !== totalPages.length) {
      pageIndex = totalPages.findIndex(foundPage => foundPage === page + 1);

      newOffset = pageIndex * itemsPerPage;

      const endOffset = newOffset + itemsPerPage;

      setCurrentItems(items.slice(newOffset, endOffset));

      setPage(prevPage => prevPage + 1);
    }
  } else if (page !== toPage) {
    pageIndex = totalPages.findIndex(foundPage => foundPage === toPage);

    newOffset = pageIndex * itemsPerPage;

    const endOffset = newOffset + itemsPerPage;

    setCurrentItems(items.slice(newOffset, endOffset));

    setPage(toPage);
  }
}
