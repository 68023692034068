import styled from 'styled-components';

export const ModalCampaignsContainer = {
  content: {
    width: '34.375rem',
    minHeight: '24.875rem',
    height: 'fit-content',
    margin: 'auto',
    padding: '0',
    border: '0.1rem solid var(--blue-800)',
    borderRadius: '0.625rem',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
  },
};

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  margin-top: 2rem;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2.25rem;
  color: var(--blue-800);
`;

export const Separator = styled.hr`
  width: 8.25rem;
  margin-bottom: 2.438rem;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NameDiv = styled.div`
  display: flex;
  flex-direction: column;

  font-family: Poppins;
  font-weight: 400;
  font-size: 1.25rem;

  input {
    padding-left: 0.5rem;
    width: 30rem;
    border-radius: 0.5rem;
    border: 1px solid var(--blue-700);
    margin-top: 0.5rem;

    height: 2.063rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding-left: 1.25rem;

    font-family: Poppins;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  p {
    align-items: left;
  }
`;
interface IProps {
  concluded: boolean;
}

export const InfoContainer = styled.div<IProps>`
  width: 30rem;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 6.5rem 10.938rem 9.938rem;
  align-items: center;
  justify-content: space-between;

  font-family: Poppins;
  font-weight: 400;
  font-size: 1.15rem;

  text-align: center;

  input {
    width: 100%;
    height: 2.063rem;
    border-radius: 0.5rem;
    border: 1px solid
      ${props => (props.concluded ? 'var(--green-400)' : 'var(--blue-700)')};
    margin-top: 0.5rem;
    padding-left: 0.5rem;

    text-align: center;
    font-family: Poppins;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${props =>
      props.concluded ? 'var(--gray-500)' : 'var(--blue-700)'};
  }
`;

export const InputDiv = styled.p<IProps>`
  width: 100%;
  height: 2.063rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${props => (props.concluded ? 'var(--green-400)' : 'var(--blue-700)')};
  margin-top: 0.5rem;
  padding-left: 0.5rem;

  text-align: center;
  text-align: center;
  font-family: Poppins;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${props => (props.concluded ? 'var(--gray-500)' : 'var(--blue-700)')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonAdd = styled.button`
  margin-top: 1.589rem;
  border: none;
  background-color: var(--white);
`;

export const ButtonSalved = styled.button`
  width: 146px;
  height: 32px;
  border-radius: 6px;
  margin: 1.8rem 0 1rem 0;
  border: none;
  background-color: var(--blue-700);
  color: var(--white);

  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
`;
