export const reasonForScheduling = [
  {
    label: 'Dirigindo',
    value: 'Dirigindo',
    amount: 0,
  },
  {
    label: 'Está na rua',
    value: 'Está na rua - não consegue conversar agora',
    amount: 0,
  },
  {
    label: 'Falha de transferência',
    value: 'Falha de Transferência',
    amount: 0,
  },
  {
    label: 'Familiar ajuda',
    value: 'Familiar ajuda - ligar no horário que o mesmo está em casa',
    amount: 0,
  },
  {
    label: 'Inbound virtual',
    value: 'Inbound Virtual',
    amount: 0,
  },
  {
    label: 'Mandar por escrito para ter tudo relatado',
    value: 'Mandar por escrito para ter tudo relatado',
    amount: 0,
  },
  {
    label: 'Médico',
    value: 'Médico',
    amount: 0,
  },
  {
    label: 'Trabalhando',
    value: 'Trabalhando (não consegue conversar no momento)',
    amount: 0,
  },
  {
    label: 'Estadual',
    value: 'Estadual',
    amount: 0,
  },
  {
    label: 'Caso de valor alto',
    value: 'Caso de valor alto',
    amount: 0,
  },
  {
    label: 'Procedimento interno',
    value: 'Procedimento interno',
    amount: 0,
  },
];

export const motivesForNotAdvancing = [
  {
    label: 'Não tem interesse em antecipar no momento',
    amount: 2,
  },
  {
    label: 'Não precisa do dinheiro',
    amount: 0,
  },
  {
    label: 'Pode esperar pelo tempo que for necessário',
    amount: 0,
  },
  {
    label: 'Acredita que receberá no próximo ano e quer esperar para ver',
    amount: 3,
  },
  {
    label: 'Deseja apenas saber os valores (curiosidade)',
    amount: 1,
  },
  {
    label: 'Falha de transferência',
    amount: 5,
  },
  {
    label: 'Proposta pelo Whatsapp',
    amount: 3,
  },
  {
    label: 'Proposta pelo E-mail',
    amount: 3,
  },
];

export const motivesOfOpportunity = [
  {
    label: 'Já ouviu proposta de outras empresas e quer comparar',
    amount: 2,
  },
  {
    label: 'Quer apenas conhecer a oportunidade',
    amount: 1,
  },
  {
    label: 'Caso o cenário não mude, considera a opção de antecipar',
    amount: 4,
  },
  {
    label: 'Já sabe de tudo, quer ir direto para a proposta',
    amount: 3,
  },
];

export const whyDidntAnticipateBefore = [
  {
    label: 'Não fecha pelo valor oferecido',
    amount: 0,
  },
  {
    label: 'Desconfiança na empresa',
    amount: 0,
  },
  {
    label: 'Interferência Familiar',
    amount: 0,
  },
  {
    label: 'Interferência Advogado',
    amount: 0,
  },
  {
    label: 'Deságio',
    amount: 0,
  },
];

export const customerPriority = [
  {
    label: 'Não perguntei',
    amount: 0,
  },
  {
    label: 'É sua maior prioridade',
    amount: 0,
  },
  {
    label: 'Existem outras prioridades em sua vida',
    amount: 0,
  },
  {
    label: 'Não é uma prioridade',
    amount: 0,
  },
  {
    label: 'É algo que ele quer resolver',
    amount: 0,
  },
];

export const decisionReview = [
  {
    label: 'Sim, se surgir alguma necessidade',
    amount: 0,
  },
  {
    label: 'Sim, depende do valor oferecido',
    amount: 0,
  },
  {
    label: 'Não, de jeito nenhum',
    amount: 0,
  },
];

export const contactWithCompetitor = [
  {
    label: 'Não perguntei',
    amount: 0,
  },
  {
    label: 'Sim',
    amount: 0,
  },
  {
    label: 'Não',
    amount: 0,
  },
];

export const informedNegotiableValue = [
  {
    label: 'Não perguntei',
    amount: 0,
  },
  {
    label: 'Sim',
    amount: 0,
  },
  {
    label: 'Não',
    amount: 0,
  },
];
