import React from 'react';

import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import logo from '../../assets/icons/precato_logo_azul.svg';
import { LogoContainer } from './styles';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Body>
        <LogoContainer>
          <img src={logo} alt="Logo precato" />
        </LogoContainer>
      </Body>
    </Container>
  );
};

export default Dashboard;
