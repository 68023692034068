/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import Loader from 'react-loader-spinner';
import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import { Title } from './Title';
import { ContainerLoader } from './styles';
import { TableCasePriorization } from './TableCasePriorization';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { ICasePriorizationDTO } from './dtos/ICasePriorizationDTO';
import { IQuantityCasesDTO } from './dtos/IQuantityCasesDTO';
import { ResumePanel } from './ResumePanel';

export function CasePriorization(): JSX.Element {
  const [casePriorizations, setCasePriorizations] = useState<
    ICasePriorizationDTO[]
  >([] as ICasePriorizationDTO[]);

  const [quantityCases, setQuantityCases] = useState<IQuantityCasesDTO>(
    {} as IQuantityCasesDTO,
  );

  const [nameTicket, setNameTicket] = useState<string>();

  const [firstCase, setFirstCase] = useState<ICasePriorizationDTO>(
    {} as ICasePriorizationDTO,
  );

  const { user } = useAuth();

  const quantityCasesAux: IQuantityCasesDTO = {
    quantityPriority1: 0,
    quantityPriority2: 0,
    quantityPriority3: 0,
    quantityPriority4: 0,
    quantityPriority5: 0,
    quantityPriority6: 0,
    quantityPriority7: 0,
  };

  useEffect(() => {
    const loader = document.getElementById('loader');
    loader.style.display = 'flex';

    api
      .get<ICasePriorizationDTO[]>(`/cases_priorization/list/${user.id}`)
      .then(response => {
        setCasePriorizations(response.data);
        loader.style.display = 'none';

        let nameTicketSplit = response.data[0].subject;
        [, nameTicketSplit] = nameTicketSplit.split('FUNDO PARCEIRO - ');

        setNameTicket(nameTicketSplit);
        setFirstCase(response.data[0]);

        for (const caseItem of response.data) {
          if (caseItem.priority === 1) {
            quantityCasesAux.quantityPriority1++;
          } else if (caseItem.priority === 2) {
            quantityCasesAux.quantityPriority2++;
          } else if (caseItem.priority === 3) {
            quantityCasesAux.quantityPriority3++;
          } else if (caseItem.priority === 4) {
            quantityCasesAux.quantityPriority4++;
          } else if (caseItem.priority === 5) {
            quantityCasesAux.quantityPriority5++;
          } else if (caseItem.priority === 6) {
            quantityCasesAux.quantityPriority6++;
          } else {
            quantityCasesAux.quantityPriority7++;
          }
        }

        setQuantityCases(quantityCasesAux);
      })
      .catch(() => {
        loader.style.display = 'none';
      });
  }, [user.id]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Pagamento {'>'} </H1Navigation>
        <H1NavigationNow>Priorização de Casos</H1NavigationNow>
      </Navegation>

      <Body>
        <ContainerLoader id="loader">
          <Loader type="Oval" color="#05233e" height={50} width={50} />
        </ContainerLoader>
        <Title
          name="Priorização de Casos"
          nameStrong=""
          isShared={false}
          separatorWidth={0}
        />

        <ResumePanel
          casePriorizations={casePriorizations}
          quantityCases={quantityCases}
          setCasePriorizations={setCasePriorizations}
          firstCase={firstCase}
          nameTicket={nameTicket}
        />

        <TableCasePriorization
          casePriorizations={casePriorizations}
          setCasePriorizations={setCasePriorizations}
        />
      </Body>
    </Container>
  );
}
