import styled, { css } from 'styled-components';

interface StatusProps {
  isSelected?: boolean;
}

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 2rem;
`;

export const Text = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  color: #1665d8;

  background: rgba(215, 227, 245, 0.3);
  border-radius: 0.313rem;

  width: 16.313rem;
  height: 1.875rem;
`;

export const ButtonsTableContainer = styled.div`
  margin-top: 2.563rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Button = styled.button<StatusProps>`
  width: 6.688rem;
  height: 1.875rem;
  border: none;
  border-radius: 0.125rem;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;

  transition: background-color 0.3s;

  ${props =>
    props.isSelected
      ? css`
          background: #1665d8;
          color: #ffffff;
          border: transparent;
        `
      : css`
          background: #f4f4f5;
          color: #041524;
          border: 0.031rem solid #05233e;

          :hover {
            background: #dfdfdf;
          }
        `}
`;

export const Table = styled.table`
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 1.813rem;
  width: 100%;

  text-align: center;
`;

export const THead = styled.thead`
  border-radius: 0.25rem;
`;

export const TRowHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: rgba(207, 222, 243, 0.83);

  height: 2.5rem;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  color: #05233e;

  :last-child {
    border-top: 1px solid #9d9d9d;
  }

  .data-creditor {
    width: 66.7%;
  }
`;

export const TCellHead = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;

  width: 100%;
  height: 100%;
  gap: 0.25rem;

  border-right: 1px solid #9d9d9d;

  :last-child {
    border: none;
  }
`;

export const TBody = styled.tbody``;

export const TRowBody = styled.tr`
  margin-top: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border-radius: 0.25rem;

  min-height: 3.5rem;
  height: auto;

  word-break: break-all;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;

  color: #041524;

  cursor: pointer;

  :nth-child(odd) {
    background: #e5e5e5;
  }

  :nth-child(even) {
    background: #efefef;
  }
`;

export const TCell = styled.td`
  width: 100%;
`;
