import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';

import { AiOutlineDownload } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Container } from '../../components/Container';
import {
  DivSeparator,
  Separator,
  ContainerHeader,
  SearchContainer,
  ButtonNewPermission,
  InputSearch,
  Table,
  Thead,
  Tbody,
  ButtonDelete,
  ButtonEdit,
  Export,
  ContainerExport,
  IconForm,
  ContainerTitle,
} from './styles';
import api from '../../services/api';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../components/Styles/Navigation';

import star_icon from '../../assets/icons/star.svg';
import user_icon from '../../assets/icons/user.svg';
import search_icon from '../../assets/icons/search.svg';

interface Permission {
  id: string;
  name: string;
  description: string;
}

const PermissionList: React.FC = () => {
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    api.get('/users/permissions').then(response => {
      setPermissions(response.data);
    });
  }, []);

  const getPermissions = useCallback(() => {
    api.get('/users/permissions').then(response => {
      setPermissions(response.data);
    });
  }, []);

  const handleFilterPermissions = useCallback(
    (value: string) => {
      const filterPermissions = permissions.filter(
        permission =>
          permission.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );

      setPermissions(filterPermissions);
    },
    [permissions],
  );

  const handleSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return value === '' ? getPermissions() : handleFilterPermissions(value);
    },
    [getPermissions, handleFilterPermissions],
  );

  function deletePermissionSuccess() {
    toast.success('Permisão deletado com sucesso');
  }

  function deletePermissionError() {
    Swal.fire('Erro!', 'Selecione uma opção.', 'error');
  }

  async function deletePermission(id: string) {
    try {
      await api.delete(`/users/permissions/${id}`);
      const updatedPermission = permissions.filter(
        permission => permission.id !== id,
      );

      setPermissions(updatedPermission);
      deletePermissionSuccess();
    } catch (err) {
      deletePermissionError();
    }
  }

  function pdf() {
    window.print();
  }

  return (
    <Container>
      <Navegation>
        <H1Navigation>Administração {'>'} </H1Navigation>
        <H1NavigationNow>Permissões</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Permissões</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <ContainerHeader>
          <SearchContainer>
            <InputSearch
              type="text"
              placeholder="Pesquise por permissão"
              onChange={handleSearchInputChange}
            />
            <img src={search_icon} alt="Search icon" />
          </SearchContainer>

          <Link to="/administration/permissions/new">
            <ButtonNewPermission type="submit">
              Nova permissão
            </ButtonNewPermission>
          </Link>
        </ContainerHeader>

        <Table>
          <Thead>
            <tr>
              <th>
                <IconForm className="icon-head">
                  <img src={user_icon} alt="User icon" />
                </IconForm>
                <ContainerTitle>Nome da permissão</ContainerTitle>
              </th>
              <th>
                <IconForm className="icon-head">
                  <img src={star_icon} alt="Team icon" />
                </IconForm>
                <ContainerTitle>Contexto</ContainerTitle>
              </th>
            </tr>
          </Thead>
          <Tbody>
            {permissions.length > 0 &&
              permissions.map(permission => (
                <tr key={permission.id}>
                  <td>{permission.name}</td>
                  <td>
                    <span>{permission.description}</span>
                    <IconForm className="icon-body">
                      <ButtonDelete
                        type="button"
                        onClick={() => deletePermission(permission.id)}
                      >
                        <DeleteIcon />
                      </ButtonDelete>

                      <Link
                        to={{
                          pathname: '/administration/permissions/edit',
                          state: {
                            id: permission.id,
                          },
                        }}
                      >
                        <ButtonEdit type="button">
                          <EditIcon />
                        </ButtonEdit>
                      </Link>
                    </IconForm>
                  </td>
                </tr>
              ))}
          </Tbody>
        </Table>

        <ContainerExport>
          <Export type="button" onClick={pdf}>
            <AiOutlineDownload />
            Exportar
          </Export>
        </ContainerExport>
      </Body>
    </Container>
  );
};

export default PermissionList;
