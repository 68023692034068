import * as yup from 'yup';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import React, { useEffect, useState, useCallback } from 'react';

import { AiOutlineCheck } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidateErrors';

import { EditUserForm } from './EditUserForm';
import { Body } from '../../../components/Styles/Body';
import { Title } from '../../../components/Styles/Title';
import { Container } from '../../../components/Container';
import { BackButton } from '../../../components/BackButton';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';

import {
  Icon,
  ButtonEditUser,
  ButtonsContainerEditUser,
  DivSeparator,
  Separator,
} from './styles';

import { IUserFormDataDTO } from '../dtos/IUserFormDataDTO';
import { IUserDTO } from '../dtos/IUserDTO';
import { ITeamDTO } from '../dtos/ITeamDTO';
import { IProfileDTO } from '../dtos/IProfileDTO';
import { WeekdayWorkingHourDTO } from '../../CloserSettings/dtos/WeekdayWorkingHourDTO';

export const EditUsers: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const user = location.state as IUserDTO;

  const [teams, setTeams] = useState<ITeamDTO[]>([]);
  const [profiles, setProfiles] = useState<IProfileDTO[]>([]);
  const [formData, setFormData] = useState<IUserFormDataDTO>({
    name: user.name,
    email: user.email,
    profile_id: user.profile_id,
  } as IUserFormDataDTO);

  const [closerProfileId, setCloserProfileId] = useState('');
  const [hunterProfileId, setHunterProfileId] = useState('');

  useEffect(() => {
    api.get('/users/hunter-teams').then(response => {
      setTeams(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/users/profiles/').then(response => {
      setProfiles(response.data);

      const closerProfile = response.data.find(
        (profile: { name: string }) => profile.name === 'Closers',
      );

      const hunterProfile = response.data.find(
        (profile: { name: string }) =>
          profile.name === 'Hunter New' ||
          profile.name === 'Hunter Teste' ||
          profile.name === 'Gestores Hunter New',
      );

      setCloserProfileId(closerProfile.id);
      setHunterProfileId(hunterProfile.id);
    });
  }, []);

  const handleEditUserSuccess = useCallback(() => {
    toast.success('Usuário editado com sucesso');
  }, []);

  const handleEditUserError = useCallback((message: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  const handleEditUser = useCallback(async () => {
    try {
      const editUserFormSchema = yup.object().shape({
        name: yup.string().required('Nome obrigatório!'),
        email: yup
          .string()
          .required('E-mail obrigatório!')
          .email('E-mail inválido!'),
        password: yup.string(),
        password_confirm: yup
          .string()
          .oneOf([null, yup.ref('password')], 'As senhas não coincidem!'),
        profile_id: yup.string().required('Perfil obrigatório!'),
      });

      await editUserFormSchema.validate(formData, {
        abortEarly: false,
      });

      if (formData.profile_id === 'Closers') {
        const weekdayWorkingHourArray: WeekdayWorkingHourDTO[] = [
          {
            work_day: 1,
            time_init: formData.work_hour_init_1,
            time_end: formData.work_hour_end_1,
          },
          {
            work_day: 2,
            time_init: formData.work_hour_init_2,
            time_end: formData.work_hour_end_2,
          },
          {
            work_day: 3,
            time_init: formData.work_hour_init_3,
            time_end: formData.work_hour_end_3,
          },
          {
            work_day: 4,
            time_init: formData.work_hour_init_4,
            time_end: formData.work_hour_end_4,
          },
          {
            work_day: 5,
            time_init: formData.work_hour_init_5,
            time_end: formData.work_hour_end_5,
          },
        ];

        await api.put(`/users/${user.id}`, {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          profile_id: formData.profile_id,
          team_id: formData.team_id,
        });

        await api.put(`/users/closer/${user.id}`, {
          ramal: formData.ramal,
          work_hour: weekdayWorkingHourArray,
        });
      } else {
        await api.put(`/users/${user.id}`, {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          profile_id: formData.profile_id,
          team_id: formData.team_id,
        });
      }

      handleEditUserSuccess();
      history.push('/administration/users');
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = getValidationErrors(error);

        handleEditUserError(errors[Object.keys(errors)[0]]);

        return;
      }
      handleEditUserError(error.response.data.message);
    }
  }, [formData, handleEditUserError, handleEditUserSuccess, history, user.id]);

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Navegation>
        <H1Navigation>
          Administração {'>'} Usuários {'>'}{' '}
        </H1Navigation>
        <H1NavigationNow>Editar usuário</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Editar usuário</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <BackButton
          link={{
            pathname: '/administration/users',
          }}
          isNotMargin
        />

        <EditUserForm
          user={user}
          teams={teams}
          profiles={profiles}
          formData={formData}
          setFormData={setFormData}
          closerProfileId={closerProfileId}
          hunterProfileId={hunterProfileId}
        />

        <ButtonsContainerEditUser>
          <ButtonEditUser type="button" onClick={handleEditUser}>
            <Icon>
              <AiOutlineCheck />
            </Icon>
            Salvar
          </ButtonEditUser>
        </ButtonsContainerEditUser>
      </Body>
    </Container>
  );
};
