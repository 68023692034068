import { Link, useHistory } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { TiCancel } from 'react-icons/ti';
import { FiPlus } from 'react-icons/fi';
import { ChangeEvent, useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { Container } from '../../../../components/Container';

import { Body } from '../../../../components/Styles/Body';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';
import { Title } from '../../../../components/Styles/Title';
import {
  ButtonsContainer,
  CancelButtonContainer,
  CreateButtonContainer,
  CustomerContainer,
  CustomerTitleContainer,
  Input,
  Label,
  ReturnContainer,
} from './styles';
import { DivSeparator, Separator } from '../styles';
import apiCustomerSuccess from '../../../../services/apiCustomerSuccess';

interface IDebitor {
  active_type: string;
  name: string;
  cnpj: string;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  uf: string;
}

const CreateDebitor: React.FC = () => {
  const [debitor, setDebitor] = useState<IDebitor>({} as IDebitor);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setDebitor({ ...debitor, [name]: value });
  };

  const history = useHistory();

  const createActivityError = useCallback((message: string) => {
    Swal.fire('Atenção!', `${message}`, 'warning');
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await apiCustomerSuccess.post('/debitors', debitor);

      setDebitor({
        active_type: ' ',
        name: ' ',
        cnpj: ' ',
        cep: ' ',
        street: ' ',
        number: ' ',
        complement: ' ',
        neighborhood: ' ',
        city: ' ',
        uf: ' ',
      });

      history.goBack();
    } catch (error) {
      // eslint-disable-next-line no-alert
      createActivityError(error.response.data.message);
    }
  }, [createActivityError, debitor, history]);

  const handleCancel = useCallback(async () => {
    setDebitor({
      active_type: ' ',
      name: ' ',
      cnpj: ' ',
      cep: ' ',
      street: ' ',
      number: ' ',
      complement: ' ',
      neighborhood: ' ',
      city: ' ',
      uf: ' ',
    });

    history.goBack();
  }, [history]);
  return (
    <Container>
      <Navegation>
        <H1Navigation>
          Vórtx {'>'} Liquidação {'>'}
        </H1Navigation>
        <H1NavigationNow>Novo ente devedor</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Novo ente devedor</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <ReturnContainer>
          <Link to="/vortx/debitors">
            <AiOutlineArrowLeft size={18} color="#1665D8" />
            Voltar
          </Link>
        </ReturnContainer>
        <div>
          <CustomerTitleContainer>Dados gerais</CustomerTitleContainer>

          <CustomerContainer>
            <div>
              <Label htmlFor="name">
                Nome:
                <Input type="text" name="name" onChange={handleInputChange} />
              </Label>
            </div>

            <div>
              <Label htmlFor="cnpj">CNPJ:</Label>
              <Input type="text" name="cnpj" onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="active_type">Número do tipo:</Label>
              <Input
                type="text"
                name="active_type"
                onChange={handleInputChange}
              />
            </div>
          </CustomerContainer>
        </div>

        <div>
          <CustomerTitleContainer>Dados de localização</CustomerTitleContainer>

          <CustomerContainer>
            <div>
              <Label htmlFor="cep">
                CEP:
                <Input type="text" name="cep" onChange={handleInputChange} />
              </Label>
            </div>

            <div>
              <Label htmlFor="street">Rua:</Label>
              <Input type="text" name="street" onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="number">Numero:</Label>
              <Input type="text" name="number" onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="complement">Complemento:</Label>
              <Input
                type="text"
                name="complement"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="neighborhood">Bairro:</Label>
              <Input
                type="text"
                name="neighborhood"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Label htmlFor="city">Cidade:</Label>
              <Input type="text" name="city" onChange={handleInputChange} />
            </div>
            <div>
              <Label htmlFor="uf">Estado:</Label>
              <Input type="text" name="uf" onChange={handleInputChange} />
            </div>
          </CustomerContainer>
        </div>

        <ButtonsContainer>
          <CancelButtonContainer>
            <button type="button" onClick={handleCancel}>
              <TiCancel size={20} />
              Cancelar
            </button>
          </CancelButtonContainer>

          <CreateButtonContainer>
            <button type="button" onClick={handleSubmit}>
              <FiPlus size={20} />
              Criar ente devedor
            </button>
          </CreateButtonContainer>
        </ButtonsContainer>
      </Body>
    </Container>
  );
};

export { CreateDebitor };
