import React, { useCallback, useEffect, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';

import { format } from 'date-fns';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

import { Body } from '../../../../components/Styles/Body';
import { Container } from '../../../../components/Container';
import { Title } from '../../../../components/Styles/Title';
import { IHunterScheduleDTO } from '../../dtos/IHunterScheduleDTO';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';

import {
  DivSeparator,
  Separator,
  ButtonCreate,
  Main,
  IconCreate,
  ButtonsDateContainer,
} from './styles';
import { ManagerSchedulingsContainer } from '../../components/ManagerSchedulingsContainer';
import { InputDate } from '../../../../components/InputDate';
import { ModalCreateScheduling } from './ModalCreateScheduling';
import { CloserDTO } from '../../../CloserSettings/dtos/CloserDTO';
import { IHunterDTO } from '../../Hunter/dtos/IHunterDTO';

const ManagerCreateSchedule: React.FC = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [formattedDate, setFormattedDate] = useState<string>(
    format(new Date(), 'yyyy-MM-dd'),
  );
  const { user } = useAuth();
  const [hoursAvailable, setHoursAvailable] = useState<Date[]>();

  const [closers, setClosers] = useState<CloserDTO[]>([] as CloserDTO[]);
  const [hunters, setHunters] = useState<IHunterDTO[]>([] as IHunterDTO[]);

  const [openModalNewScheduling, setOpenModalNewScheduling] =
    useState<boolean>(false);

  const [schedulesByDay, setSchedulesByDay] = useState<IHunterScheduleDTO[]>(
    [] as IHunterScheduleDTO[],
  );

  const handleCloseNewSchedulingModal = useCallback(() => {
    setOpenModalNewScheduling(false);
    api
      .get<IHunterScheduleDTO[]>(
        `schedule/list-all-manager-schedulings-today/${formattedDate}`,
      )
      .then(response => {
        setSchedulesByDay(response.data);
      });
  }, [formattedDate]);

  const handleOpenNewSchedulingModal = useCallback(() => {
    setOpenModalNewScheduling(true);
  }, []);

  useEffect(() => {
    api.get('/schedule/list-all-closers').then(response => {
      setClosers(response.data);
    });
    api.get('/schedule/list-all-hunters').then(response => {
      setHunters(response.data);
    });
  }, []);

  useEffect(() => {
    setSchedulesByDay([] as IHunterScheduleDTO[]);
    api
      .get<IHunterScheduleDTO[]>(
        `schedule/list-all-manager-schedulings-today/${formattedDate}`,
      )
      .then(response => {
        setSchedulesByDay(response.data);
      });

    api
      .get(`schedule/list-all-date-possible-hours/${formattedDate}`)
      .then(response => {
        setHoursAvailable(response.data);
      });
  }, [user, formattedDate]);

  useEffect(() => {
    const schedulesInDay = date
      ? setFormattedDate(format(date, 'yyyy-MM-dd'))
      : setFormattedDate(format(new Date(), 'yyyy-MM-dd'));

    return schedulesInDay;
  }, [date]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1NavigationNow>Agendamento gestor</H1NavigationNow>
      </Navegation>
      <Body>
        <Title>Agendamento</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <Main>
          <ButtonCreate onClick={handleOpenNewSchedulingModal}>
            <IconCreate>
              <AiOutlinePlus />
            </IconCreate>
            Criar Agendamento
          </ButtonCreate>

          <ButtonsDateContainer>
            <InputDate date={date} setDate={setDate} />
          </ButtonsDateContainer>
          <ManagerSchedulingsContainer schedules={schedulesByDay} />
          <ModalCreateScheduling
            isOpen={openModalNewScheduling}
            onRequestClose={handleCloseNewSchedulingModal}
            closers={closers}
            hunters={hunters}
            hours={hoursAvailable}
          />
        </Main>
      </Body>
    </Container>
  );
};

export default ManagerCreateSchedule;
