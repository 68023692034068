import styled from 'styled-components';
import xIcon from '../../../../assets/icons/x_icon.svg';
import editIcon from '../../../../assets/icons/edit_icon.svg';
import selectArrow from '../../../../assets/icons/select_arrow.svg';

export const TableRow = styled.tr`
  width: 71.5rem;
  height: 3.5rem;
  margin-top: 0.3125rem;
  border-radius: 0.25rem;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface ITableCellProps {
  width: string;
}

export const TableCell = styled.td<ITableCellProps>`
  width: ${props => props.width};

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  display: flex;
  text-align: center;
  letter-spacing: -0.015em;
  color: #041524;
`;

export const DeleteButton = styled.button`
  width: 1.375rem;
  height: 1.4375rem;
  border: none;
  border-radius: 0.3125rem;
  background-color: #777777;
  background-image: url(${xIcon});
  background-position: center;
  background-repeat: no-repeat;

  :hover {
    cursor: pointer;
  }
`;

export const EditButton = styled.button`
  width: 1.375rem;
  height: 1.4375rem;
  margin-left: 0.375rem;
  border: none;
  border-radius: 0.3125rem;
  background-color: #063966;
  background-image: url(${editIcon});
  background-position: center;
  background-repeat: no-repeat;

  :hover {
    cursor: pointer;
  }
`;

interface IInputProps {
  width: string;
}

export const Input = styled.input<IInputProps>`
  width: ${props => props.width};
  height: 1.75rem;
  border: 0.0625rem solid #51a7fc;
  border-radius: 0.3125rem;

  display: flex;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #041524;
`;

export const SaveButton = styled.button`
  width: 3.6875rem;
  height: 1.4375rem;
  border: none;
  border-radius: 0.3125rem;
  background-color: #3ca647;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;

  :hover {
    cursor: pointer;
  }
`;

export const SelectCell = styled.td`
  width: 15rem;
  height: 1.75rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Select = styled.button`
  width: 13rem;
  height: 1.75rem;
  border: 0.0625rem solid #51a7fc;
  border-radius: 0.3125rem;
  background-color: #ffffff;
  background-image: url(${selectArrow});
  background-position: 11.75rem;
  background-repeat: no-repeat;

  display: ${props => (props.disabled ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #041524;

  :hover {
    cursor: pointer;
  }
`;

interface ISelectOptionProps {
  enabled: boolean;
}

export const SelectOptions = styled.div<ISelectOptionProps>`
  border: 0.0625rem solid #51a7fc;
  border-radius: 5px;

  position: absolute;

  display: ${props => (props.enabled ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }

  .last-child {
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
`;

export const SelectOption = styled.button`
  width: 13rem;
  height: 1.75rem;
  border: none;
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #041524;

  :hover {
    background-color: #1665d8;
    color: #ffffff;
  }
`;
