import styled from 'styled-components';
import { shade } from 'polished';

export const ContainerTitleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  margin-bottom: 1.875rem;
  margin-top: 1rem;
`;

export const TitleSection = styled.h1`
  width: 15.938rem;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  padding: 0.25rem;

  text-align: center;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;

  color: var(--blue-700);
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 2rem;

  @media (max-width: 800px) {
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Line = styled.div`
  position: absolute;
  width: 0.313rem;
  height: 5rem;

  background: var(--gradient);
  border-radius: 5.938rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  margin-left: 1.5rem;

  @media (max-width: 800px) {
    margin-left: 0rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media (max-width: 400px) {
    padding: 0rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;

  .select {
    width: 21rem;
    padding: 0.2rem;
    border: 0.063rem solid var(--blue-500);

    @media (max-width: 930px) {
      width: 18rem;
    }

    @media (max-width: 400px) {
      width: 12rem;
    }
  }

  @media (max-width: 400px) {
    margin-right: 0rem;
  }
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  color: var(--blue-700);
`;

export const Input = styled.input`
  border-radius: 0.25rem;
  border: 0.063rem solid var(--blue-300);
  width: 21rem;
  padding-left: 0.25rem;

  font-style: normal;
  font-weight: normal;
  font-size: 0.813rem;
  display: flex;
  align-items: center;

  color: var(--blue);
  box-shadow: 0rem 0.188rem 0.625rem rgba(114, 30, 255, 0.1);
  height: 2.25rem;

  @media (max-width: 930px) {
    width: 18rem;
  }

  @media (max-width: 400px) {
    width: 12rem;
  }
`;

export const LinePermissions = styled.div`
  width: 0.313rem;
  height: 25rem;

  background: var(--gradient);
  border-radius: 5.938rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const FormGroupPermission = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;

  @media (max-width: 800px) {
    margin-left: 0;
    width: 80vw;
    flex-direction: column;
  }
`;

export const PermissionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .level-container {
    margin-left: 1.5rem;
  }
`;

export const ContainerLevel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ButtonsContainerCreateProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
  padding-bottom: 1rem;

  @media (max-width: 930px) {
    border: none;
  }
`;

export const ButtonCreate = styled.button`
  width: 9.688rem;
  height: 2.313rem;
  color: var(--white);
  border: none;

  padding: 0.7rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;

  background: var(--green-600);
  border-radius: 0.5rem;

  color: var(--white);
  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.3, '#089B63')};
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
