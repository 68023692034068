import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import {
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  SendButtonContainer,
  Separator,
  DivSeparator,
  SpanInput,
} from './styles';
import { Title } from '../../../components/Styles/Title';
import { RegisteredCampaigns } from '../components/RegisteredCampaigns';
import apiChatbot from '../../../services/apiChatbot';
import { ICampaigns } from '../dtos/ICampaigns';
import { RequestTemplateNameModal } from './RequestTemplateNameModal/RequestTemplateNameModal';

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export function RCSTrigger(): JSX.Element {
  const [file, setFile] = useState<IFileProps>({} as IFileProps);
  const [isActive, setIsActive] = useState(false);
  const [openTemplateNameModal, setOpenTemplateNameModal] = useState(false);
  const [campaigns, setCampaigns] = useState<ICampaigns[]>([]);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState<number[]>([1, 2, 3]);

  const handleRequestCampaigns = useCallback(
    async (pageNumber: number) => {
      await axios
        .get(
          `${process.env.REACT_APP_MARKETING_URL}/rcs/campaigns/${pageNumber}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
            },
          },
        )
        .then(response => {
          setCampaigns(response.data.campaigns);
          setPage(pageNumber);
          if (
            allPages[allPages.length - 1] === pageNumber &&
            response.data.campaigns.length >= 3
          ) {
            setAllPages([...allPages, pageNumber + 1]);
          }
        });
    },
    [allPages],
  );

  const handleCloseTemplateNameModal = useCallback(() => {
    setOpenTemplateNameModal(false);
  }, []);

  const submitFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const getFile =
      event.target.files === null ? ({} as File) : event.target.files[0];

    const newFile: IFileProps = {
      file: getFile,
      name: getFile.name,
      readableSize: getFile.size.toString(),
    };

    setFile(newFile);
    setIsActive(true);

    event.target.value = null;
  }, []);

  const handleUpload = useCallback(
    async (template_name: string) => {
      const data = new FormData();

      try {
        data.append('file', file.file);
        data.append('template_name', template_name);

        await axios
          .post(
            `${process.env.REACT_APP_MARKETING_URL}/rcs/campaigns/file`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  '@Precato:token',
                )}`,
              },
            },
          )
          .then(async () => {
            setFile({} as IFileProps);
            setIsActive(false);
            handleRequestCampaigns(1);
            handleCloseTemplateNameModal();
            Swal.fire(
              'Sucesso!',
              'Campanha cadastrada com sucesso!',
              'success',
            );
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [file.file, handleRequestCampaigns, handleCloseTemplateNameModal],
  );

  useEffect(() => {
    handleRequestCampaigns(1);
  }, []);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Conexão {'>'} </H1Navigation>
        <H1NavigationNow>Disparo RCS</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Disparo RCS</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <FileContainer>
          <FileInputContainer>
            <SpanInput>Arquivo:</SpanInput>
            <SendFileContainer>
              <div>
                <label htmlFor="file"> Selecione um arquivo</label>
                <input
                  id="file"
                  type="file"
                  name="file"
                  onChange={submitFile}
                />
                {file && file.name ? (
                  <span>{file.name}</span>
                ) : (
                  <span>Nenhum arquivo selecionado</span>
                )}
              </div>
            </SendFileContainer>
            <SendButtonContainer isActive={isActive}>
              <button
                type="button"
                onClick={() => setOpenTemplateNameModal(true)}
              >
                Enviar
              </button>
              <span>Arquivos serão processados automaticamente</span>
            </SendButtonContainer>
          </FileInputContainer>
        </FileContainer>
        <RegisteredCampaigns
          campaigns={campaigns}
          handleRequestCampaigns={handleRequestCampaigns}
          page={page}
          allPages={allPages}
          type="RCS"
        />
        <RequestTemplateNameModal
          handleUpload={handleUpload}
          isOpen={openTemplateNameModal}
          onRequestClose={handleCloseTemplateNameModal}
        />
      </Body>
    </Container>
  );
}
