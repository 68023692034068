import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.hr`
  width: 11.5rem;
  margin-bottom: 2.875rem;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 70vw;

  tbody:nth-child(odd) {
    background: var(--gray-50);
  }

  tbody:nth-child(even) {
    background: var(--gray-100);
  }

  @media (min-width: 1500px) {
    min-width: 80vw;
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 32% 30% 19% 19%;
`;

export const Thead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background: var(--blue-100);
  border-radius: 0.25rem;
  width: 100%;
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 550;
  font-size: 0.875rem;
  text-align: center;

  color: var(--blue-700);

  border-right: 0.01rem solid var(--blue-700);

  &:nth-child(4) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  width: 100%;
  margin-top: 0.3rem;

  .tr-body:nth-child(odd) {
    background: var(--gray-100);
  }

  .tr-body:nth-child(even) {
    background: var(--gray-50);
  }

  .tr-body {
    border-radius: 0.25rem;
  }
`;

export const BodyCell = styled.td`
  font-weight: 450;
  font-size: 0.8rem;
  text-align: center;
  height: 4.3rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 2rem;
`;

export const EspecialCell = styled.td`
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;
  height: 9rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-top: 3rem;

  div {
    margin-right: 2rem;
  }
`;
export const SendButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #014077;
    border: 0;
    border-radius: 0.5rem;
  }
`;

export const ApprovedField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.688rem;
  height: 2.313rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.015em;

  color: #ffffff;
  border: 0;
  border-radius: 0.2rem;
  margin-bottom: 1.5rem;
  margin-top: -2rem;
  background-color: #3ca647;
`;

export const ApprovedResField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.688rem;
  height: 2.313rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.015em;

  color: #ffffff;
  border: 0;
  border-radius: 0.2rem;
  margin-bottom: 1.5rem;
  margin-top: -2rem;
  background-color: #b4ad09;
`;

export const DeniedField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.688rem;
  height: 2.313rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.015em;

  color: #ffffff;
  border: 0;
  border-radius: 0.2rem;
  margin-bottom: 1.5rem;
  margin-top: -2rem;
  background-color: #ea3f3f;
`;

export const BackButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
    background: #ea3f3f;
    border: 0;
    border-radius: 0.5rem;
  }
`;

export const ReturnButtonContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.688rem;
    height: 2.313rem;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #1665d8;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    background: none;
    border: 1;
    border-radius: 0.5rem;
    border-style: solid;
    border-color: #1665d8;
  }
`;

export const InputRes = styled.input`
  border-radius: 0.25rem;
  border: 1px solid #05233e;
  width: 100%;
  padding: 0.25rem;
  transition: border 0.2s;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  &:focus {
    border: 1px solid #2e9afe;
  }
`;

export const Select = styled.select`
  width: 9.25rem;
  height: 1.5rem;
  margin-top: 2rem;
  border: none;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  background: #d5d5d5;

  font-family: Poppins;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6d6d6d;

  margin-bottom: 2rem;
`;

export const Option = styled.option`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  background: #f4f4f5;
  text-align: center;
`;

export const ShowAnswer = styled.div`
  font-weight: 450;
  font-size: 1.1rem;
  text-align: center;
`;
