import { ChangeEvent } from 'react';
import { InlineSeparator } from '../ExampleFileTRF3Pje/styles';
import {
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  TitleFile,
  Label,
  InputFile,
  TextFile,
  SendButtonContainer,
  ButtonSendFile,
  TextFileButton,
  ExampleFileContainer,
  ExampleTitle,
  Separator,
  FileProcessExampleContainer,
  ExampleTextContainer,
  SelectFileContainer,
  OriginatingExampleText,
  RequisiteExampleText,
  OriginatinExampleTextValue,
  RequisiteExampleTextValue,
} from './styles';

interface IExampleFileTRF4Movement {
  handleUpload: () => Promise<void>;
  submitFile: (event: ChangeEvent<HTMLInputElement>) => void;
  file_name: string;
}

export const ExampleFileTRF4Movement: React.FC<IExampleFileTRF4Movement> = ({
  submitFile,
  handleUpload,
  file_name,
}: IExampleFileTRF4Movement) => {
  const templateFileTrf = '0000000-00.0000.0.00.0000';
  const templateFileTrfRequisite = '000.000.000-00';

  return (
    <FileContainer>
      <FileInputContainer>
        <TitleFile>Arquivo:</TitleFile>
        <SendFileContainer>
          <SelectFileContainer>
            <Label htmlFor="file">Selecione um arquivo</Label>
            <InputFile
              onChange={event => submitFile(event)}
              id="file"
              type="file"
              name="file"
            />
            {file_name ? (
              <TextFile>{file_name}</TextFile>
            ) : (
              <TextFile>Nenhum arquivo selecionado</TextFile>
            )}
          </SelectFileContainer>
        </SendFileContainer>

        <SendButtonContainer>
          <ButtonSendFile type="button" onClick={() => handleUpload()}>
            Enviar
          </ButtonSendFile>
          <TextFileButton>
            Arquivos serão processados automaticamente
          </TextFileButton>
        </SendButtonContainer>
      </FileInputContainer>

      <ExampleFileContainer>
        <ExampleTitle>Modelo de arquivo:</ExampleTitle>

        <FileProcessExampleContainer>
          <ExampleTextContainer>
            <OriginatingExampleText>Numero do Processo</OriginatingExampleText>
            <InlineSeparator>|</InlineSeparator>
            <RequisiteExampleText>CPF</RequisiteExampleText>
            <Separator />
            <OriginatinExampleTextValue>
              {templateFileTrf}
            </OriginatinExampleTextValue>
            <InlineSeparator>|</InlineSeparator>
            <RequisiteExampleTextValue>
              {templateFileTrfRequisite}
            </RequisiteExampleTextValue>
          </ExampleTextContainer>
        </FileProcessExampleContainer>
      </ExampleFileContainer>
    </FileContainer>
  );
};
