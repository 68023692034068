import styled, { css } from 'styled-components';

interface AnnexButtonProps {
  hasAnnex: boolean;
}

interface NoteSpanProps {
  final_percent: string;
}

export const ApprovalRequestContainer = {
  content: {
    width: '42.5rem',
    height: '38.5rem',
    margin: 'auto',
    padding: '0',
    borderRadius: '0.625rem',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
  },
};

export const NoteSpan = styled.span<NoteSpanProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 3.125rem;

  font-weight: 600;
  font-size: 1.25rem;
  color: var(--white);

  ${props =>
    props.final_percent < '31'
      ? css`
          background: var(--red-500);
        `
      : props.final_percent > '30' && props.final_percent < '61'
      ? css`
          background: var(--yellow-500);
        `
      : css`
          background: var(--green-500);
        `}
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;

  padding: 0.938rem 0.688rem;
  gap: 0.5rem;

  #description {
    display: flex;
    justify-content: center;
  }

  #manager-textarea {
    border: 0.5px solid var(--blue-600);
  }
`;

export const LabelInputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 1rem;
  width: 100%;
`;

export const Label = styled.label`
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  background: rgba(207, 222, 243, 0.83);

  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-700);
`;

export const Input = styled.input`
  background: var(--gray-100);
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  border: none;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-800);

  flex: 1;
`;

export const InputSpanContainer = styled.div`
  display: flex;

  flex: 1;

  input {
    border-radius: 0.25rem 0 0 0.25rem;

    border-right: 2px solid var(--gray-800);
  }
`;

export const PercentageSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.375rem;
  background: var(--gray-100);

  border-radius: 0 0.25rem 0.25rem 0;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-800);
`;

export const AnnexButton = styled.button<AnnexButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 0.5rem;

  font-weight: 600;
  font-size: 1rem;

  transition: 0.2s;

  ${props =>
    props.hasAnnex
      ? css`
          background: var(--blue-600);
          color: var(--white);

          :hover {
            background: var(--blue-700);
          }
        `
      : css`
          border: 1px solid var(--gray-800);
          background: var(--gray-100);
          color: var(--gray-800);

          cursor: default;
        `}
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 4.375rem;

  border: none;
  border-radius: 0.25rem;
  background: var(--gray-100);
  padding-left: 0.5rem;
  padding-top: 0.5rem;

  resize: none;

  font-weight: 400;
  font-size: 0.75rem;
  color: var(--gray-700);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 1.875rem;

  margin-top: 0.25rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 9.75rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    transition: 0.2s;

    font-weight: 600;
    font-size: 1rem;
  }
`;

export const RefuseButton = styled.button`
  background: var(--gray-100);
  border: 1px solid var(--blue-600);

  color: var(--blue-600);

  :hover {
    filter: brightness(0.9);
  }
`;

export const ApproveButton = styled.button`
  background: var(--blue-600);
  border: none;

  color: var(--white);

  :hover {
    background: var(--blue-700);
  }
`;
