import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: 46rem;
  height: 10rem;

  margin-bottom: 2rem;
  border-radius: 0.313rem;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 3rem;

  background: var(--gray-50);

  font-weight: 600;
  font-size: 1rem;
  color: var(--blue-400);

  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

export const AmountsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  height: 100%;
  width: 100%;

  div:nth-child(4) {
    border: none;
  }

  div:nth-child(2),
  div:nth-child(5) {
    border-left: 1px solid var(--gray-600);
    border-right: 1px solid var(--gray-600);
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0.25rem 0;
`;

export const TitleSpan = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--gray-800);
`;

export const ValueSpan = styled.span`
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--blue-800);
`;
