import { TableBodyRow } from '../TableBodyRow';
import { SmsBatch } from '../types/SmsBatch';
import {
  ComponentBody,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableTitle,
  VerticalSeparator,
} from './styles';

interface ICampaignTableProps {
  batches: SmsBatch[];
  onEditCampaign: () => void;
}

export function CampaignTable({
  batches,
  onEditCampaign,
}: ICampaignTableProps): JSX.Element {
  return (
    <ComponentBody>
      <TableTitle>Campanhas registradas</TableTitle>

      <Table>
        <TableHeader>
          <TableHead key="0">Nome</TableHead>
          <VerticalSeparator />
          <TableHead key="1">Data de agendamento</TableHead>
          <VerticalSeparator />
          <TableHead key="2">Quantidade de disparos</TableHead>
          <VerticalSeparator />
          <TableHead key="3">Resultado do disparo</TableHead>
        </TableHeader>

        <TableBody>
          {batches.map((batch, position) => (
            <TableBodyRow
              key={String(position)}
              id={batch.id}
              name={batch.batch}
              scheduled_date={batch.scheduling_date}
              triggers={batch.triggers}
              onEditCampaign={onEditCampaign}
            />
          ))}
        </TableBody>
      </Table>
    </ComponentBody>
  );
}
