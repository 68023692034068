import { useEffect, useState } from 'react';
import {
  Container,
  TableContainer,
  Table,
  Thead,
  TableRow,
  HeadCell,
  Tbody,
  BodyCell,
  BodyCellResult,
} from './style';
import failure from '../../../../assets/icons/failure.svg';
import sent from '../../../../assets/icons/sent.svg';
import received from '../../../../assets/icons/received.svg';
import viewer from '../../../../assets/icons/viewer.svg';
import { ICampaigns } from '../../dtos/ICampaigns';

interface CampaignInformationProps {
  isActive: boolean;
  campaign: ICampaigns;
  type: string;
}

interface IResults {
  batche: string;
  scheduled_date: string;
  failure: number;
  sent: number;
  delivered: number;
  read: number;
}

export function CampaignResult({
  isActive,
  campaign,
  type,
}: CampaignInformationProps): JSX.Element {
  const [batches, setBatches] = useState<IResults[]>([]);

  useEffect(() => {
    const allBatches: IResults[] = [];

    for (const batche of campaign.batches) {
      const data: IResults = {
        batche: type === 'RCS' ? batche.position + 1 : batche.batch + 1,
        scheduled_date: null,
        failure: 0,
        sent: 0,
        delivered: 0,
        read: 0,
      };

      data.batche = batche.batch ? batche.batch + 1 : batche.position + 1;

      data.scheduled_date =
        type === 'RCS' || type === 'UNIPIX'
          ? batche.scheduling_date
          : batche.scheduled_date;

      for (const trigger of batche.triggers) {
        if (trigger.status === 'FAILURE' || trigger.status === 'EXCEPTION') {
          data.failure += 1;
        } else if (trigger.status === 'SENT') {
          data.sent += 1;
        } else if (trigger.status === 'DELIVERED') {
          data.delivered += 1;
        } else if (trigger.status === 'READ') {
          data.read += 1;
        }
      }

      allBatches.push(data);
    }

    allBatches.sort((a, b) => {
      if (
        new Date(a.scheduled_date).getDate() <
        new Date(b.scheduled_date).getDate()
      ) {
        return -1;
      }
      if (
        new Date(a.scheduled_date).getDate() >
        new Date(b.scheduled_date).getDate()
      ) {
        return 1;
      }
      return 0;
    });

    setBatches(allBatches);
  }, [campaign.batches, type]);

  return (
    <Container isActive={isActive}>
      <TableContainer>
        <Table>
          <Thead>
            <TableRow>
              <HeadCell>Número do lote</HeadCell>
              <HeadCell>Data do disparo</HeadCell>
              <HeadCell>Resultado do disparo</HeadCell>
            </TableRow>
          </Thead>
          <Tbody>
            {batches.map((data, index) => (
              <TableRow className="body-tr" key={String(index)}>
                <BodyCell>{index + 1}</BodyCell>
                <BodyCell>
                  {data.scheduled_date
                    ? new Intl.DateTimeFormat('pt-BR', {
                        month: '2-digit',
                        day: '2-digit',
                        year: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      }).format(new Date(data.scheduled_date))
                    : '--/--/-- --:--'}
                </BodyCell>
                <BodyCellResult>
                  <span>
                    <img src={failure} alt="falha" />
                    <span>{data.failure}</span>
                  </span>
                  <span>
                    <img src={sent} alt="falha" />
                    <span>{data.sent}</span>
                  </span>
                  <span>
                    <img src={received} alt="falha" />
                    <span>{data.delivered}</span>
                  </span>
                  <span>
                    <img src={viewer} alt="falha" />
                    <span>{data.read}</span>
                  </span>
                </BodyCellResult>
              </TableRow>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
