import { ChangeEvent } from 'react';
import { InputContainer, Input, Label } from './styles';

interface InputFieldModalProps {
  name: string;
  label: string;
  placeholder: string;

  onChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
}
export function InputFieldModal({
  name,
  label,
  placeholder,
  onChange,
}: InputFieldModalProps): JSX.Element {
  return (
    <>
      {name === 'date' && (
        <InputContainer>
          <Label htmlFor={name}>{label}</Label>
          <Input
            type="date"
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
          />
        </InputContainer>
      )}

      {name !== 'date' && !name.includes('time') && (
        <InputContainer>
          <Label htmlFor={name}>{label}</Label>
          <Input
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
          />
        </InputContainer>
      )}
    </>
  );
}
